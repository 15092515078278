import React, { useState } from 'react';
import { Button, Modal, Toast } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { useCancelMutation } from '../../hooks/queries/useCancelMutation';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Show from '../show/show';

const ModalCancelReservation = ({ reservationId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalCancelReservation' });
  const { id } = useParams();
  const [openOnlyCancel, setOpenOnlyCancel] = useState(false);

  const cancelMutation = useCancelMutation();
  const queryClient = useQueryClient();

  const handleModal = () => {
    setOpenOnlyCancel((prevState) => !prevState);
  };
  const handleMutation = (data) => {
    cancelMutation.mutate({
      reservationId: reservationId,
      amount: data.amount,
    });
  };
  const handleRefetch = () => {
    queryClient.refetchQueries({ queryKey: ['reservation', id] });
  };
  const handleToast = () => {
    cancelMutation.reset();
    handleRefetch();
    handleModal();
  };

  return (
    <>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
        onClick={handleModal}
        size={'custom'}
        text={t('button')}
      />

      <Show.When isTrue={openOnlyCancel}>
        <Modal
          open={openOnlyCancel}
          title={t('title')}
          children={<div className='text-left mt-2'>{t('ask')}</div>}
          maxWidth={'450px'}
          customButtons={
            <>
              <div className='row px-2 text-center'>
                <Button
                  text={t('cancel')}
                  disabled={cancelMutation.isLoading || cancelMutation.isSuccess}
                  className={'col btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                <Button
                  text={t('submit')}
                  disabled={cancelMutation.isLoading || cancelMutation.isSuccess}
                  className={'col ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={() => handleMutation({ amount: 0 })}
                />
              </div>
            </>
          }
        />
      </Show.When>
      <Show.When isTrue={cancelMutation.isSuccess}>
        <Toast
          message={
            cancelMutation?.variables?.amount > 0
              ? t('toast.success.cancelSuccess')
              : t('toast.success.cancelOnlySuccess')
          }
          type='SUCCESS'
          onCallback={handleToast}
        />
      </Show.When>
      <Show.When isTrue={cancelMutation.isError}>
        <Toast message={t('toast.error')} type='ERROR' onCallback={handleToast} />
      </Show.When>
    </>
  );
};

export default ModalCancelReservation;
