import React from 'react';

const LawIcon = ({ size, color = 'currentColor' }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2150 4386 c-96 -27 -176 -82 -260 -178 -69 -79 -73 -103 -31 -150
l28 -32 -380 -415 c-209 -229 -394 -431 -412 -450 l-33 -34 -30 22 c-54 38
-81 30 -158 -52 -78 -82 -116 -146 -139 -232 -27 -105 -15 -207 35 -305 36
-71 56 -93 199 -224 75 -68 196 -178 270 -245 149 -136 213 -175 321 -193 145
-25 267 18 392 139 112 108 125 145 72 203 l-23 25 97 106 c93 103 97 106 117
90 12 -9 389 -353 839 -766 451 -412 837 -762 859 -777 188 -126 459 17 484
255 7 72 -17 150 -64 209 -21 26 -413 390 -871 809 l-834 762 39 41 c21 22 66
70 100 106 l61 66 26 -23 c50 -42 81 -32 158 50 181 192 203 432 57 614 -61
75 -549 513 -603 540 -102 52 -218 66 -316 39z m235 -177 c62 -33 550 -482
580 -533 58 -100 54 -215 -13 -305 -20 -28 -41 -51 -45 -51 -5 0 -32 22 -60
49 -29 26 -191 176 -362 331 -170 156 -343 315 -384 353 l-75 70 48 44 c26 24
69 50 94 58 65 21 161 14 217 -16z m-7 -628 l332 -304 -38 -41 c-564 -618
-785 -856 -793 -856 -5 0 -164 142 -354 316 l-345 316 23 28 c12 15 197 219
412 453 381 416 390 425 410 409 12 -10 171 -154 353 -321z m-1245 -743 c80
-73 276 -252 435 -398 l289 -265 -24 -32 c-14 -18 -50 -45 -81 -60 -75 -37
-174 -39 -242 -6 -26 13 -159 128 -311 267 -305 280 -314 291 -314 416 0 59 6
85 24 120 23 45 59 90 71 90 4 0 73 -60 153 -132z m2239 -783 c929 -850 886
-806 859 -895 -29 -99 -142 -159 -218 -117 -33 18 -1704 1549 -1701 1558 4 10
202 228 208 228 3 1 387 -348 852 -774z'
        />
        <path
          d='M957 1348 c-21 -16 -237 -521 -237 -555 0 -13 11 -35 25 -48 l24 -25
911 0 911 0 24 25 c14 13 25 35 25 48 0 34 -216 539 -237 555 -13 9 -174 12
-723 12 -549 0 -710 -3 -723 -12z m1414 -306 l66 -157 -379 -3 c-208 -1 -548
-1 -756 0 l-379 3 66 157 66 158 625 0 625 0 66 -158z'
        />
      </g>
    </svg>
  );
};

export default LawIcon;
