import { NoPhotoIcon } from '../../uiComponents/icons';
import Show from '../show/show';
import './itemDropdown.css';

const ItemDropdown = ({
  item,
  show,
  selectOrganization,
  setActive,
  idx,
  isOrganization = false,
}) => {
  return (
    <div className='position-relative'>
      <div
        className={`dropdown-item text-general w-100 ${show ? 'is-focus' : ''}`}
        onClick={() => {
          selectOrganization(item, item?.id);
          setActive(idx);
        }}
      >
        <div className='d-flex align-items-center justify-content-start w-100'>
          <Show.When isTrue={isOrganization}>
            {item.logo ? (
              <img
                className={'mr-2'}
                style={{
                  height: '24px',
                  width: '24px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                src={item.logo}
                alt={item.name}
              />
            ) : (
              <NoPhotoIcon size={'24px'} className={''} />
            )}
          </Show.When>

          <div>{item?.name}</div>
        </div>
      </div>
    </div>
  );
};

export default ItemDropdown;
