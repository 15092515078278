export const canAccessOrView = (userRoles, allowedRoles, itemTitle) => {
  if (itemTitle === 'areas' || itemTitle === 'groups') {
    const hasProductRoles =
      userRoles.includes('productpriceloader') || userRoles.includes('productloader');
    const hasSpecificRole = userRoles.includes('restaurantvip') || userRoles.includes('group');

    if (hasProductRoles && !hasSpecificRole) {
      return true;
    }

    if (hasProductRoles && hasSpecificRole) {
      if (itemTitle === 'areas' && userRoles.includes('restaurantvip')) {
        return true;
      }
      if (itemTitle === 'groups' && userRoles.includes('group')) {
        return true;
      }
    }

    if (!hasProductRoles && hasSpecificRole) {
      return false;
    }

    if (!hasProductRoles && !hasSpecificRole) {
      // Verificación de los demás roles
      return userRoles.some((role) => allowedRoles.includes(role));
    }

    return false;
  }

  // Verificación de los roles generales
  return userRoles.some((role) => allowedRoles.includes(role));
};
