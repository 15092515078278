import React, { useState, useRef, useEffect } from 'react';
import './dropdown.css';

export const DropdownItem = ({ onClick, text, icon = <></> }) => (
  <li className='dropdown-item align-items-center justify-content-start' onClick={onClick}>
    {icon}
    <span className='ml-2'>{text}</span>
  </li>
);

const Dropdown = ({
  btn,
  id,
  isHover = false,
  customDropdwon,
  isMouseOver = true,
  callBack = null,
  isClickable = false,
  renderList,
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const handleOpen = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className='dropdown' ref={ref} id={id} style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className='dropdown-toggle'
        role='button'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded={show}
        {...(isHover
          ? {
              onMouseEnter: () => {
                setShow(true);
              },

              onClick: undefined,
            }
          : {
              onClick: (e) => {
                handleOpen(e);
              },
            })}
      >
        {btn}
      </div>
      <div
        className={`${
          show && isMouseOver ? 'show' : ''
        } text-general dropdown-menu ${customDropdwon} mt-2 p-0`}
        aria-labelledby={id}
        onClick={(e) => {
          if (isClickable) {
            e.stopPropagation();
          } else {
            handleOpen(e);
          }
          callBack && callBack();
        }}
      >
        {renderList({ handleClose, open: show })}
      </div>
    </div>
  );
};

export default Dropdown;
