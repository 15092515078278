import React from 'react';
import SideBar from '../sideBar/sideBar';
import { SidebarContextProvider } from '../../context/sidebarContext';
import { Header } from '../index';

import { Content } from '..';

function Page({ children, overflow }) {
  return (
    <SidebarContextProvider>
      <div className='page'>
        <SideBar />
        <div className='page-content'>
          <Header />

          <Content overflow={overflow}>{children}</Content>
        </div>
      </div>
    </SidebarContextProvider>
  );
}

export default Page;
