import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CloudIcon, FwTrashIcon, ImgIcon } from '../../icons';
import IconButton from '../iconButton/iconButton';
import { useTranslation } from 'react-i18next';

const InputLogo = ({
  id,
  name,
  label,
  className = '',
  isRequired = true,
  maxSize = 1048576,
  placeholder = '',
  maxWidth = 800,
  maxHeight = 400,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.inputLogo' });
  /* ---------- Form hooks ---------- */
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  /* ---------- Local hooks ---------- */
  const fileInputRef = useRef(null);

  /* ---------- Files types ---------- */
  let acceptGeneralTypes = 'image/gif, image/png, image/jpeg';
  let allowedExtensions = /\.(gif|png|jpe?g)$/i;

  /* ---------- Functions ---------- */
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const validateImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width <= maxWidth && img.height <= maxHeight) {
          previewFile(file).then(resolve).catch(reject);
        } else {
          setError(name, {
            type: 'custom',
            message: t('errors.dimensions', { width: maxWidth, height: maxHeight }),
          });
          reject(new Error(t('errors.dimensions', { width: maxWidth, height: maxHeight })));
        }
      };
      img.onerror = reject;
    });
  };
  const previewFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (allowedExtensions.test(file.name)) {
        if (file.size <= maxSize) {
          clearErrors(name);
          validateImage(file)
            .then((base64String) => {
              setValue(name, { base64: base64String, name: file.name });
            })
            .catch((error) => {
              console.error(t('errors.convert'), error);
            });
        } else {
          setError(name, {
            type: 'custom',
            message: t('errors.size'),
          });
        }
      } else {
        setError(name, {
          type: 'custom',
          message: t('errors.type'),
        });
      }
    }
  };
  const deleteImage = () => {
    setValue(name, null);
  };
  const renderPreview = (value) => {
    if (!value || !value?.base64) {
      return (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{
            width: '72px',
            minWidth: '72px',
            height: '72px',
            borderRadius: '50%',
            background: '#F9FAFB ',
            border: '1px solid #d0d5dd',
          }}
        >
          <ImgIcon />
        </div>
      );
    }

    return (
      <div>
        <div className='logo-image position-relative '>
          <img src={value.base64} alt={value.name} />
          <div className='logo-image-icon'>
            <IconButton
              onClick={deleteImage}
              classNameIcon={'hoverIconConatiner ml-0'}
              icon={<FwTrashIcon color='var(--hover)' size={20} classNameIcon={'hoverIcon'} />}
            />
          </div>
        </div>
      </div>
    );
  };

  /* ---------- Basic validations ---------- */
  const validationRules = {};
  if (isRequired) {
    validationRules.required = t('errors.general');
  }
  useEffect(() => {
    clearErrors();
  }, [isRequired]);

  return (
    <div className={`input-file-container ${className}`}>
      {label && (
        <label className='mb-1' style={{ color: 'var(--color-text)' }}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={validationRules}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <div className='d-flex'>
                {renderPreview({ base64: value?.base64, name: value?.name })}
                <div
                  className='input-file-drag-drop mb-2 ml-3'
                  onClick={handleFileInputClick}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const droppedFile = e.dataTransfer.files[0];
                    if (droppedFile) {
                      handleFileSelection({ target: { files: [droppedFile] } });
                    }
                  }}
                >
                  {' '}
                  <span className='mb-1 iconContainer'>
                    <CloudIcon size='20px' color='#344054' />
                  </span>
                  <div className='dark-grey'>
                    <span className='text-primary-blue font-semibold'>{t('title')} </span>
                    {t('drag')}
                  </div>
                  <span className='dark-grey fs-12'>{placeholder ? placeholder : t('text')}</span>
                </div>
                <input
                  type='file'
                  id={id}
                  name={name}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileSelection}
                  accept={acceptGeneralTypes}
                />
              </div>
              {errors[name] && (
                <div className='d-flex align-items-center error-content'>
                  <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                  <p className='text-danger p-0 m-0'>{errors[name].message}</p>
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
};
export default InputLogo;
