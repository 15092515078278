import React, { useState } from 'react';
import { CloseIcon, TickIcon, WarningModernIcon } from '../../../uiComponents/icons';
import styles from './warning-dispute.module.css';
import { Button, Divider, Money } from '../../../uiComponents/common';
import { formatDateTranslation } from '../../../helpers/dates';
import ModalAcceptDispute from './modals/aceptModalDispute';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getReasons } from '../../../helpers/reasons';
import { ModalLostDispute, ModalPreviewFile, ModalWinDispute } from './modals';
import ModalSendToStripe from './modals/modalSendStripe';
import { hasAllowedRoles } from '../../../helpers/roles';
import { useUser } from '../../../hooks';

const DisputeWarning = ({ paymentId, paymentInfoQuery, paymentAffected }) => {
  const currencySymbol = useSelector((state) => state.app.selectedOrganization);
  const [openAccept, setOpenAccept] = useState(false);
  const [openWon, setOpenWon] = useState(false);
  const [openLost, setOpenLost] = useState(false);
  const [openStripe, setOpenStripe] = useState(false);
  const [url, setUrl] = useState('');
  const language = useSelector((state) => state.app.language);
  const handleOpenAccept = () => setOpenAccept((open) => !open);
  const { t } = useTranslation('translation', { keyPrefix: 'components.disputeWarning' });
  const { t: translateRefuteType } = useTranslation('translation', {
    keyPrefix: 'screens.refute',
  });
  const { roles } = useUser();
  const { t: translateReasons } = useTranslation();
  const navigate = useNavigate();
  const hasDueDate = paymentInfoQuery?.data?.data?.dispute_Due_by;
  const hasCustomerEmail = paymentInfoQuery?.data?.data?.customerEmail;

  const handleRefecth = () => {
    paymentInfoQuery.refetch();
    paymentAffected.refetch();
  };

  const isWelletFinance = hasAllowedRoles(roles, ['wellet_finance']);

  const renderTextContainer = (status, amount, currency) => {
    switch (status) {
      case 'DISPUTED':
        return (
          <>
            <div className='fw-500 mb-1'>{t('disputePrimary')}</div>
            <div className='dark-grey'>{t('disputeSecondary')}</div>
          </>
        );
      case 'DISPUTED_LOST':
        return (
          <>
            <div className='fw-500 mt-2'>
              {t('disputeLostText', {
                amount: `${currencySymbol.currencySymbol} ${amount.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })} ${currency ? currency : ''}`,
              })}
            </div>
          </>
        );
      case 'DEADLINE_MISSIED':
        return (
          <>
            <div className='fw-500 mt-2'>
              {t('disputeLostText', {
                amount: `${currencySymbol.currencySymbol} ${amount.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })} ${currency ? currency : ''}`,
              })}
            </div>
          </>
        );
      case 'DISPUTED_WINNED':
        return (
          <>
            <div className='fw-500 mt-2'>
              {t('disputeWonText', {
                amount: `${currencySymbol.currency} ${amount.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })} ${currency ? currency : ''}`,
              })}
            </div>
          </>
        );
      case 'DISPUTED_NOT_ACCEPTED':
        return <div className='fw-500 mt-2'>{t('disputeNotAccepted')}</div>;
      case 'STRIPE_REVIEW':
        return (
          <div className='fw-500 mt-2'>
            {isWelletFinance ? t('disputeStripeReview') : t('disputeNotAccepted')}
          </div>
        );
      default:
        return '';
    }
  };

  const renderHeader = (status) => {
    switch (status) {
      case 'DISPUTED':
        return (
          <div className='d-flex align-items-center'>
            <WarningModernIcon color={'#000'} />
            <div className='fw-600 fs-18 ml-2'>{t('paymentDisputed')}</div>
          </div>
        );
      case 'DISPUTED_NOT_ACCEPTED':
        return (
          <div className='d-flex align-items-center'>
            <div className='fw-600 fs-18'>{t('paymentDisputedNotAccepted')}</div>
          </div>
        );
      case 'DISPUTED_LOST':
        return (
          <div className='d-flex align-items-center'>
            <CloseIcon size='20px' color={'#b42318'} />
            <div className='fw-600 fs-18 ml-2'>{t('paymentDisputedLost')}</div>
          </div>
        );
      case 'DEADLINE_MISSIED':
        return (
          <div className='d-flex align-items-center'>
            <CloseIcon size='20px' color={'#b42318'} />
            <div className='fw-600 fs-18 ml-2'>{t('paymentDisputedLost')}</div>
          </div>
        );
      case 'DISPUTED_WINNED':
        return (
          <div className='d-flex align-items-center'>
            <TickIcon size='20px' color='#067647' />
            <div className='fw-600 fs-18 ml-2'>{t('paymentDisputedWinned')}</div>
          </div>
        );
      case 'STRIPE_REVIEW':
        return (
          <div className='d-flex align-items-center'>
            <div className='fw-600 fs-18 ml-2'>
              {isWelletFinance ? t('paymentDisputedStripe') : t('paymentDisputedNotAccepted')}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const renderBodyDisputePending = () => (
    <>
      {' '}
      <div className='fw-500'>{t('howToSolve')}</div>
      <div
        className={`fw-500 d-flex align-items-center ${styles.itemList} ${
          hasDueDate ? '' : styles.noBorder
        }`}
      >
        <div className={styles.numberContainer}>1</div>
        {hasCustomerEmail ? (
          <a className={styles.customerEmail} href={`mailto:${hasCustomerEmail}`}>{t('actions.customer')}</a>
        ) : (
          t('actions.customer')
        )}
      </div>
      {hasDueDate ? (
        <div className={`fw-500 d-flex align-items-center ${styles.itemList} ${styles.noBorder}`}>
          <div className={styles.numberContainer}>2</div>
          {t('actions.date')}
          {
            formatDateTranslation(
              new Date(paymentInfoQuery?.data?.data?.dispute_Due_by),
              language,
              'small',
            ).date
          }
        </div>
      ) : null}
    </>
  );

  const renderDetailDispute = () => (
    <>
      {' '}
      <div className='fw-500'>{t('detail')}</div>
      <div className='row mt-2'>
        <div className='col-auto dark-grey'>
          <div className='mb-1'>{t('amount')}</div>
          <div className='mb-1'>{t('idDispute')}</div>
          <div className='mb-1'>{t('reason')}</div>
        </div>
        <div className='col'>
          <div className='mb-1'>
            <Money value={paymentInfoQuery?.data?.data?.disputeAmount} />
          </div>
          <div className='mb-1'>{paymentInfoQuery?.data?.data?.disputeId ?? '-'}</div>
          <div className='mb-1'>
            {getReasons(paymentInfoQuery?.data?.data?.disputeReason, translateReasons)}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {' '}
      <div className={styles.disputeWarning}>
        {/* HEADER */}
        <div className={`d-flex justify-content-between ${styles.disputeWarningHeader}`}>
          {renderHeader(paymentInfoQuery?.data?.data?.status)}
        </div>
        {/* BODY */}
        <div className={`d-flex flex-column ${styles.disputeWarningBody}`}>
          <div className={styles.disputeTextContainer}>
            {renderTextContainer(
              paymentInfoQuery?.data?.data?.status,
              paymentInfoQuery?.data?.data?.disputeAmount,
              paymentInfoQuery?.data?.data?.currency,
            )}
          </div>
          <div className='pl-3 '>
            <div className='row'>
              {paymentInfoQuery?.data?.data?.status === 'DISPUTED' ? (
                <div className='col-sm-12 col-md-6 mt-3'>{renderBodyDisputePending()}</div>
              ) : null}
              {paymentInfoQuery?.data?.data?.status === 'DISPUTED' ? (
                <div className='col-sm-12 col-md-6 mt-3 pl-3'>{renderDetailDispute()}</div>
              ) : null}
            </div>
          </div>
          {paymentInfoQuery?.data?.data?.status !== 'DISPUTED' ? (
            <>
              <div className='my-3'>
                <Divider />
              </div>
              {renderDetailDispute()}
            </>
          ) : null}
          {paymentInfoQuery?.data?.data?.status !== 'DISPUTED' ? (
            <div className='mt-3'>
              <Divider />
              {paymentInfoQuery?.data?.data?.status !== 'DISPUTED_ACCEPTED' ? (
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-6'>
                    <div className='mt-2'>{t('why')}</div>
                    <div className='mt-1 fw-500'>
                      -{' '}
                      {paymentInfoQuery?.data?.data?.refutation?.refutationType === 'OTHER'
                        ? paymentInfoQuery?.data?.data?.refutation?.refutationDescription
                        : paymentInfoQuery?.data?.data?.refutation?.refutationType
                        ? translateRefuteType(
                            paymentInfoQuery?.data?.data?.refutation?.refutationType,
                          )
                        : ''}
                    </div>

                    <div className='mt-2'>{t('additionalInfo')}</div>
                    <div className='mt-1 fw-500'>
                      {paymentInfoQuery?.data?.data?.refutation?.refutationAdditionalInformation
                        ? paymentInfoQuery?.data?.data?.refutation?.refutationAdditionalInformation
                        : '-'}
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-6'>
                    <div className='mt-2'>{t('evidence')}</div>
                    <div className='mt-1 fw-500'>
                      {paymentInfoQuery?.data?.data?.refutation?.documents?.length > 0
                        ? paymentInfoQuery?.data?.data?.refutation?.documents?.map((doc, index) => (
                            <div key={index}>
                              <span
                                className='custom-link-file-dispute'
                                onClick={() => setUrl(doc.documentUrl)}
                              >
                                {doc.fileName ?? '-'}
                              </span>
                            </div>
                          ))
                        : '-'}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {/* Footer */}
        <div className={`d-flex justify-content-end ${styles.disputeWarningFooter}`}>
          {paymentInfoQuery?.data?.data?.status === 'DISPUTED' && !isWelletFinance ? (
            <>
              <Button
                className={'btn-wellet-secondary white bg-custom-light text-truncate mr-2'}
                text={t('buttons.accept')}
                onClick={handleOpenAccept}
                size={'medium'}
              />
              <Button
                className={'btn-wellet-primary text-truncate'}
                text={t('buttons.refute')}
                onClick={() => navigate(`/disputes/refute/${paymentId}`)}
                size={'medium'}
              />
            </>
          ) : paymentInfoQuery?.data?.data?.status === 'DISPUTED_NOT_ACCEPTED' &&
            isWelletFinance ? (
            <>
              {/* Logica es wellet y no se q mas */}
              <Button
                className={'btn-wellet-secondary white text-truncate mr-2'}
                text={t('sendStripe')}
                onClick={() => setOpenStripe(true)}
                size={'medium'}
              />
            </>
          ) : paymentInfoQuery?.data?.data?.status === 'STRIPE_REVIEW' && isWelletFinance ? (
            <>
              <Button
                className={'btn-wellet-secondary white text-truncate mr-2'}
                text={t('lostDispute')}
                onClick={() => setOpenLost(true)}
                size={'medium'}
              />
              <Button
                className={'btn-wellet-primary text-truncate'}
                text={t('winDispute')}
                onClick={() => setOpenWon(true)}
                size={'medium'}
              />
            </>
          ) : null}
        </div>
      </div>
      <ModalSendToStripe
        callback={handleRefecth}
        open={openStripe}
        setOpen={setOpenStripe}
        paymentId={paymentId}
      />
      <ModalAcceptDispute
        callback={handleRefecth}
        open={openAccept}
        setOpen={setOpenAccept}
        paymentId={paymentId}
      />
      <ModalWinDispute
        callback={handleRefecth}
        open={openWon}
        setOpen={setOpenWon}
        paymentId={paymentId}
      />
      <ModalLostDispute
        callback={handleRefecth}
        open={openLost}
        setOpen={setOpenLost}
        paymentId={paymentId}
      />
      <ModalPreviewFile open={url} setOpen={setUrl} paymentId={paymentId} />
    </>
  );
};

export default DisputeWarning;
