import axios from "axios";

export const setTenantId = (tenantId) => ({
  type: 'SET_TENANT_ID',
  payload: tenantId,
});
export const setCurrentOrg = (currentOrg) => {
  const currentOrgJSON = JSON.stringify(currentOrg);
  localStorage.setItem('currentOrg', currentOrgJSON);
  return {
    type: 'SET_CURRENT_ORG',
    payload: currentOrg,
  };
};
export const setSelectedOrg = (selectedOrg) => {
  const selectedOrgJSON = JSON.stringify(selectedOrg);
  localStorage.setItem('selectedOrg', selectedOrgJSON);
  return {
    type: 'SET_SELECTED_ORG',
    payload: selectedOrg,
  };
};
export const setSelectedUser = (selectedUser) => ({
  type: 'SET_SELECTED_USER',
  payload: selectedUser,
});
export const setSelectedDate = (selectedDate) => ({
  type: 'SET_SELECTED_DATE',
  payload: selectedDate,
});
export const setSelectedDateType = (dateType) => ({
  type: 'SET_SELECTED_DATETYPE',
  payload: dateType,
});
export const clearSelectedDate = (selectedDate) => ({
  type: 'CLEAR_SELECTED_DATE',
  payload: selectedDate,
});
export const setLanguage = (language) => {
  localStorage.setItem('language', JSON.stringify(language));
  return {
    type: 'SET_LANGUAGE',
    payload: language,
  };
};
export const setLanguageForms = (languages) => {
  return {
    type: 'SET_LANGUAGE_FROMS',
    payload: languages,
  };
};

const setAppData = (data) => ({
  type: 'SET_APP_DATA',
  payload: data,
});

export const fetchAppData = (tenantId) => async (dispatch) => {
  if (tenantId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SALES_API_URL}/admin/organization/entity/${tenantId}`,
      );
      dispatch(setAppData(response.data));
    } catch (error) {
      console.error(error);
    }
  }
};

export const setPaymentMethods = (methods) => {
  return {
    type: 'SET_PAYMENT_METHODS',
    payload: methods,
  };
};
