import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../components';
import { FormButton, FormHeader, Loading, Toast } from '../../../uiComponents/common';
import styles from './loyalty.module.css';
import { useMutation, useQuery } from 'react-query';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { changesCategory, getSalesCategorysNew, getUserSalesCategorys } from '../../../services';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';
import FormRow from '../../../components/forms/formRow';

const Loyalty = ({ id }) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'screens.profile.loyalty',
  });
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);

  // Estado para la categoría seleccionada
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // Fetch all available categories
  const {
    data: allCategoriesData,
    refetch: refetchAllCategories,
    ...allCategories
  } = useQuery({
    queryKey: ['allCategories', organization.marketId, i18n.language],
    queryFn: () => getSalesCategorysNew(welletContext, organization.marketId),
    ...defaultQueryOptions,
  });

  // Fetch all available categories by user
  const {
    data: allCategoriesByUserData,
    refetch: refetchUserCategories,
    ...allCategoriesByUser
  } = useQuery({
    queryKey: ['usersLoyalty', organization.marketId, i18n.language],
    queryFn: () => getUserSalesCategorys(welletContext, organization.marketId, id),
    ...defaultQueryOptions,
    onSuccess: (data) => {
      const userCategoryId = data?.data?.categoryId;
      setSelectedCategoryId(userCategoryId);
    },
  });

  // Mutations
  const changesCategoryQuery = useMutation({
    mutationFn: (body) => {
      const response = changesCategory(welletContext, organization.marketId, body);
      return response;
    },
    onSuccess: () => {
      // callback();
      refetchAllCategories();
      refetchUserCategories();
    },
  });

  const onSubmit = () => {
    if (selectedCategoryId && selectedCategoryId !== allCategoriesByUserData?.data?.categoryId) {
      const body = {
        userId: id,
        saleCategoryId: selectedCategoryId,
      };
      changesCategoryQuery.mutate(body);
    }
  };

  // Map categories to HTML
  const timelineRows = allCategoriesData?.data?.map((category) => (
    <div key={category.id} className={styles.timelineRow}>
      <div className={styles.pointInfo}>
        <div className={styles.pointsValue}>{category.targetPoints}</div>
      </div>
      <input
        type='radio'
        name='category'
        id={`category${category.id}`}
        className={styles.radioInput}
        checked={category.id === selectedCategoryId}
        onChange={() => setSelectedCategoryId(category.id)}
      />
      <label
        htmlFor={`category${category.id}`}
        className={`${styles.card} ${
          category.id === selectedCategoryId ? styles.cardSelected : ''
        }`}
      >
        <div className={styles.cardContent}>
          {category.name}
          {category.id === allCategoriesByUserData?.data?.categoryId && (
            <div className={styles.currentBadge}>{t('currentClass')}</div>
          )}
        </div>
      </label>
    </div>
  ));

  return (
    <>
      <Show>
        <Show.When isTrue={allCategories.isLoading || allCategoriesByUser.isLoading}>
          <Loading global={true} />
        </Show.When>

        <Show.Else>
          <div className='mt-3'></div>
          <FormHeader
            title={t('title')}
            subtitle={t('text')}
            border={true}
            endComponent={
              <FormButton
                handleRedirect={onSubmit}
                clearForm={() => setSelectedCategoryId(allCategoriesByUserData?.data?.categoryId)}
                all={false}
                nextStep={true}
                isDisabled={changesCategoryQuery.isLoading || changesCategoryQuery.isFetching}
              />
            }
          />
          <FormRow
            text={t('subHeader')}
            description={t('subHeadDesc')}
            endComponent={
              <div className={styles.wrapper}>
                <div className={styles.containerHeader}>
                  <div className={styles.content}>
                    <div className={styles.status}>
                      <p className='fs-lg fw-400'>
                        {t('status')}
                        {': '}
                        <span className={styles.statusLoyalty}>
                          {allCategoriesByUserData?.data?.category}
                        </span>
                      </p>
                    </div>
                    <div className={styles.points}>
                      <p className='fs-lg fw-400'>
                        {t('points')}
                        {': '}
                        <span className=' fw-600'>
                          {allCategoriesByUserData?.data?.points || 0}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.timeline}>{timelineRows}</div>
              </div>
            }
          />
        </Show.Else>
      </Show>

      <Show.When isTrue={changesCategoryQuery.isSuccess}>
        <Toast message={t('toast.success')} type={`SUCCESS`} onCallback={() => null} />
      </Show.When>
      <Show.When isTrue={changesCategoryQuery.isError}>
        <Toast message={t('toast.errors')} type={`ERROR`} onCallback={() => null} />
      </Show.When>
    </>
  );
};

export default Loyalty;
