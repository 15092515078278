export default function ClipboardIcon({ size = '14px', color = '#E1E1E1' }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14 14' fill='none'>
      <path
        d='M13.16 0H4.2C3.97722 0 3.76356 0.0884998 3.60603 0.24603C3.4485 0.403561 3.36 0.617218 3.36 0.84V3.36H0.84C0.617218 3.36 0.403561 3.4485 0.24603 3.60603C0.0884998 3.76356 0 3.97722 0 4.2V13.16C0 13.3828 0.0884998 13.5964 0.24603 13.754C0.403561 13.9115 0.617218 14 0.84 14H9.8C10.0228 14 10.2364 13.9115 10.394 13.754C10.5515 13.5964 10.64 13.3828 10.64 13.16V10.64H13.16C13.3828 10.64 13.5964 10.5515 13.754 10.394C13.9115 10.2364 14 10.0228 14 9.8V0.84C14 0.617218 13.9115 0.403561 13.754 0.24603C13.5964 0.0884998 13.3828 0 13.16 0ZM8.96 12.32H1.68V5.04H8.96V12.32ZM12.32 8.96H10.64V4.2C10.64 3.97722 10.5515 3.76356 10.394 3.60603C10.2364 3.4485 10.0228 3.36 9.8 3.36H5.04V1.68H12.32V8.96Z'
        fill={color}
      />
    </svg>
  );
}
