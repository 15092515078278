import React from 'react';

const RadioButton = ({ text, value, checked, onChange }) => {
    return (
      <div className='form-check'>
        <input
          className='form-check-input'
          type='radio'
          name='flexRadioDefault'
          id={value}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <label className='form-check-label text-nowrap' htmlFor={value}>
          {text}
        </label>
      </div>
    );
  };
  
export default RadioButton;
