import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Page } from '../components';

export default function AuthRoute({ overflow = true }) {
  const user = useSelector((state) => state.user);
  const authenticated = user.isAuthenticated;
  const isAuthorized= authenticated && user.user && user.roles.length > 0;

  if (user.roles === 'unauthorized') {
    return <Navigate to={{ pathname: '/error-page' }} replace />;
  }

  if (!isAuthorized) {
    return <Navigate to={{ pathname: '/login' }} replace />;
  }
  return <Page overflow={overflow} ><Outlet /></Page>;
}
