import React from 'react';
import './table.css';

const TableCell = ({
  children,
  className,
  align,
  type,
  style,
  onClick,
  levelPadding = 'normal',
}) => {
  return (
    <td
      className={`${className ?? ''} padding-${levelPadding}`}
      align={align}
      style={style}
      onClick={onClick}
    >
      {children}
    </td>
  );
};

export default TableCell;
