import React from 'react';

export default function CashIcon({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M1460 5104 c-36 -24 -1096 -1082 -1131 -1128 -19 -26 -29 -51 -29
-75 0 -34 43 -79 978 -1013 l977 -978 45 0 45 0 520 520 520 520 86 0 c138 0
267 44 393 134 63 45 179 160 210 209 l18 27 474 0 c508 0 513 1 540 51 21 42
21 1316 0 1358 -26 49 -42 51 -391 51 l-324 0 -28 -24 c-24 -21 -29 -33 -33
-93 l-5 -68 -162 -3 -162 -2 -25 -31 c-16 -18 -26 -43 -26 -61 l0 -31 -57 6
c-90 10 -158 38 -345 143 -226 127 -534 278 -628 308 -111 36 -247 49 -356 36
-92 -11 -129 -23 -449 -139 l-190 -69 -175 173 c-96 95 -185 178 -197 184 -31
16 -65 14 -93 -5z m162 -341 c60 -59 108 -111 108 -114 0 -4 -24 -17 -52 -30
-46 -21 -68 -24 -168 -24 -100 0 -122 3 -167 24 -29 13 -53 26 -53 30 0 7 212
221 220 221 2 0 53 -48 112 -107z m1206 -20 c68 -17 434 -193 602 -290 215
-124 299 -158 435 -178 l80 -11 3 -399 2 -399 -37 -54 c-57 -83 -156 -168
-251 -215 -80 -39 -91 -42 -187 -45 -187 -7 -326 48 -460 184 l-80 80 -210 71
c-605 204 -634 215 -681 256 -50 44 -69 75 -79 128 -14 76 40 175 112 205 66
28 109 19 580 -131 252 -80 470 -145 486 -145 45 0 87 48 87 100 0 65 -23 80
-226 145 -98 31 -262 83 -364 116 l-185 59 -188 188 -188 188 53 18 c29 11
124 45 211 77 87 32 186 63 220 68 75 12 180 5 265 -16z m2092 -693 l0 -530
-195 0 -195 0 0 530 0 530 195 0 195 0 0 -530z m-3723 421 c82 -51 166 -73
288 -79 131 -5 230 17 334 75 l63 35 104 -103 c98 -98 103 -105 82 -113 -135
-54 -181 -86 -235 -163 -26 -38 -44 -54 -67 -58 -74 -16 -195 -85 -258 -149
-111 -110 -168 -248 -168 -404 0 -151 48 -276 148 -383 230 -248 600 -249 835
-1 52 55 127 184 127 219 0 20 12 17 182 -39 l158 -52 -6 -96 c-8 -125 12
-218 69 -332 l44 -86 -111 -111 -111 -110 -90 45 c-193 96 -396 94 -584 -7
l-75 -40 -508 508 -507 508 29 48 c17 26 42 82 57 125 24 67 27 93 28 197 0
137 -21 221 -80 316 l-33 54 112 113 c61 61 114 112 118 112 4 0 28 -13 55
-29z m3133 -516 l0 -435 -90 0 -90 0 0 435 0 435 90 0 90 0 0 -435z m-3520 77
c32 -102 21 -233 -26 -324 l-16 -31 -114 114 -114 114 112 113 c128 128 123
127 158 14z m963 -219 c15 -94 103 -210 202 -269 27 -16 104 -48 170 -70 135
-47 132 -42 87 -132 -34 -70 -116 -144 -190 -173 -79 -31 -195 -31 -274 0 -75
29 -146 95 -189 173 -33 60 -34 67 -34 168 0 125 17 171 91 251 41 43 103 87
125 89 4 0 9 -17 12 -37z m1266 -753 c26 -17 63 -35 80 -41 17 -6 31 -14 31
-18 0 -4 -24 -30 -53 -59 l-53 -52 -18 37 c-29 59 -55 163 -41 163 3 0 27 -14
54 -30z m-591 -650 c31 -11 60 -24 66 -30 7 -7 -25 -46 -102 -123 l-112 -112
-110 110 c-60 60 -110 113 -110 117 0 11 67 39 130 54 68 17 164 10 238 -16z'
        />
        <path
          d='M3425 3856 c-32 -32 -40 -69 -25 -107 28 -66 109 -81 161 -30 38 39
39 87 3 130 -22 26 -33 31 -70 31 -34 0 -51 -6 -69 -24z'
        />
        <path
          d='M1502 2001 c-151 -42 -308 -146 -417 -279 l-56 -67 -485 -5 -486 -5
-29 -33 -29 -33 2 -670 3 -671 28 -24 28 -24 338 0 338 0 27 26 c23 23 26 35
26 90 l0 64 150 0 c193 0 230 16 230 100 l0 32 53 -7 c96 -13 171 -44 332
-136 206 -117 561 -291 646 -317 162 -50 338 -53 485 -8 43 13 306 105 584
205 278 100 631 227 785 282 310 111 355 132 431 202 152 138 184 320 88 496
-74 138 -239 215 -407 191 -34 -5 -233 -57 -441 -115 -209 -58 -381 -105 -382
-105 -2 0 -17 27 -35 60 -37 69 -126 157 -191 188 -46 23 -282 105 -615 216
l-202 67 -75 72 c-151 146 -314 214 -536 223 -94 3 -129 1 -188 -15z m293
-196 c39 -8 107 -33 152 -55 65 -32 95 -55 153 -117 l72 -77 422 -143 c263
-90 436 -154 461 -171 54 -38 94 -98 101 -154 9 -67 -31 -148 -89 -182 -85
-50 -76 -52 -600 114 -337 107 -486 150 -503 146 -44 -11 -74 -50 -74 -95 0
-78 -4 -77 520 -243 448 -143 479 -151 555 -151 171 0 316 103 370 262 l15 46
343 94 c548 152 514 144 575 132 72 -14 110 -44 139 -109 45 -96 14 -186 -87
-258 -33 -23 -259 -108 -820 -309 -426 -153 -806 -289 -845 -303 -60 -22 -87
-26 -185 -26 -79 0 -135 5 -180 17 -78 22 -446 202 -655 321 -165 94 -258 130
-382 147 l-83 12 0 401 0 401 51 68 c55 73 143 149 219 191 100 55 223 69 355
41z m-1205 -885 l0 -530 -195 0 -195 0 0 530 0 530 195 0 195 0 0 -530z m380
90 l0 -441 -87 3 -88 3 -3 425 c-1 234 0 431 3 438 3 8 31 12 90 12 l85 0 0
-440z'
        />
        <path
          d='M1559 1251 c-68 -68 -8 -184 85 -167 48 9 81 48 81 98 0 33 -6 45
-33 69 -44 40 -93 40 -133 0z'
        />
      </g>
    </svg>
  );
}
