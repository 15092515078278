import React from 'react';
import InfoRenderer from '../infoRenderer/infoRenderer';
import './userComments.css';

const UserComments = ({ text }) => {
  return (
    <div className='comments-container'>
      <div className='comments'>
        <p>
          <InfoRenderer content={text} />
        </p>
        <div className='comments-arrow'></div> {/* Piquito del globo */}
      </div>
    </div>
  );
};

export default UserComments;
