import React from 'react';

export default function WireInterIcon({ size = '16px', color = 'currentColor'}) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M3183 4860 c-705 -58 -1284 -588 -1403 -1285 -23 -132 -27 -344 -9
-472 92 -673 612 -1210 1284 -1325 102 -17 147 -19 305 -15 252 8 411 45 625
147 448 215 765 631 861 1130 27 144 25 409 -4 558 -64 319 -205 586 -427 807
-276 275 -598 423 -989 455 -71 5 -134 9 -140 9 -6 -1 -52 -5 -103 -9z m233
-205 c76 -44 168 -169 225 -304 41 -98 21 -104 -367 -105 -354 -1 -346 -6
-274 142 126 262 269 353 416 267z m-512 -44 c3 -5 -16 -51 -43 -102 -28 -52
-59 -120 -71 -152 -12 -31 -30 -62 -40 -67 -22 -12 -249 42 -262 62 -14 23 -8
54 15 75 36 34 171 110 270 153 94 40 122 47 131 31z m924 -22 c83 -32 181
-84 250 -131 38 -26 43 -34 40 -61 -3 -31 -7 -33 -98 -64 -52 -18 -111 -31
-130 -30 -29 2 -37 7 -50 36 -8 19 -41 86 -72 150 -31 63 -55 118 -52 123 9
14 33 9 112 -23z m519 -366 c122 -132 240 -357 292 -556 31 -118 45 -235 30
-253 -9 -12 -66 -14 -322 -14 -363 0 -347 -4 -347 94 0 89 -26 301 -56 453
-30 153 -29 175 6 185 134 38 235 73 285 97 33 16 64 30 69 30 4 1 24 -16 43
-36z m-1979 -17 c73 -31 205 -72 259 -81 78 -13 79 -16 55 -141 -31 -161 -51
-305 -58 -434 -4 -63 -11 -123 -16 -132 -8 -17 -34 -18 -327 -18 -266 0 -321
2 -331 14 -26 32 31 288 99 446 68 157 210 370 247 370 8 0 40 -11 72 -24z
m1356 -129 c47 -35 123 -626 84 -665 -9 -9 -135 -12 -505 -12 l-492 0 -12 21
c-26 49 48 608 85 647 11 11 48 11 206 1 151 -9 236 -9 389 0 108 7 198 15
200 17 8 8 27 4 45 -9z m-1120 -869 c8 -10 16 -66 19 -132 9 -142 20 -232 54
-409 22 -118 23 -141 12 -152 -8 -7 -53 -23 -101 -35 -48 -11 -127 -37 -175
-56 -48 -19 -97 -34 -109 -34 -53 0 -208 235 -279 425 -48 127 -93 344 -80
386 3 10 14 20 23 22 9 3 153 4 320 4 275 -2 305 -4 316 -19z m1197 12 c16 -9
19 -22 19 -93 0 -158 -56 -534 -85 -574 -11 -15 -25 -15 -152 -5 -164 14 -379
15 -553 2 -82 -6 -129 -5 -137 1 -27 23 -80 335 -90 531 l-6 128 24 9 c34 14
954 14 980 1z m859 -8 c21 -17 22 -21 11 -92 -25 -162 -72 -313 -140 -449 -40
-78 -142 -229 -181 -266 l-31 -30 -42 17 c-143 56 -190 72 -256 88 -107 27
-106 26 -82 156 27 145 48 313 57 452 6 98 10 115 28 127 17 12 74 15 317 15
271 0 299 -2 319 -18z m-1056 -840 c21 -4 44 -14 49 -24 15 -23 -7 -85 -66
-183 -185 -309 -408 -297 -567 29 -83 171 -82 172 75 185 112 10 438 5 509 -7z
m-816 -104 c14 -35 48 -106 74 -158 30 -60 44 -98 38 -104 -30 -30 -398 159
-415 214 -8 25 9 48 45 62 49 19 165 47 198 47 32 1 35 -2 60 -61z m1252 25
c80 -27 85 -30 88 -59 3 -28 -3 -34 -66 -76 -80 -53 -203 -116 -280 -143 -79
-28 -84 -14 -29 87 25 45 57 112 72 148 15 36 32 69 39 73 17 12 82 1 176 -30z'
        />
        <path
          d='M1275 4454 c-47 -51 -29 -116 43 -153 12 -6 22 -20 22 -30 0 -14 -13
-20 -62 -29 -148 -28 -270 -122 -336 -259 l-37 -78 -3 -546 -3 -547 23 -25
c32 -36 80 -39 119 -8 l24 19 5 534 5 533 27 51 c15 29 46 68 70 88 42 36 138
73 158 61 19 -12 10 -34 -25 -55 -62 -38 -72 -88 -29 -139 43 -51 64 -45 293
90 113 67 216 131 229 142 25 23 30 82 9 111 -21 29 -449 266 -480 266 -17 0
-37 -10 -52 -26z'
        />
        <path
          d='M352 2543 c-18 -9 -45 -32 -60 -51 l-27 -35 0 -1048 c0 -1042 0
-1048 21 -1075 11 -15 33 -37 48 -48 27 -21 37 -21 650 -21 l623 0 35 27 c73
55 68 -20 68 1118 0 1137 5 1063 -68 1118 l-35 27 -611 2 c-551 3 -614 1 -644
-14z m1168 -173 c15 -24 13 -1913 -2 -1928 -17 -17 -1049 -17 -1066 0 -9 9
-12 238 -12 970 0 901 1 958 18 967 11 7 192 10 535 8 448 -2 519 -4 527 -17z'
        />
        <path
          d='M738 2229 c-13 -7 -18 -23 -18 -55 0 -81 -48 -159 -114 -185 -57 -23
-66 -37 -66 -103 0 -81 7 -90 63 -84 94 11 207 93 252 183 36 73 40 75 130 75
90 0 93 -2 129 -73 35 -68 84 -115 160 -152 54 -27 83 -36 112 -33 l39 3 3 77
3 78 -53 24 c-86 38 -128 101 -128 190 0 35 -5 48 -19 56 -26 13 -470 13 -493
-1z'
        />
        <path
          d='M907 1740 c-94 -24 -176 -90 -224 -182 -25 -47 -28 -63 -28 -148 0
-85 3 -101 28 -148 37 -70 97 -128 166 -160 80 -38 192 -38 273 0 69 32 136
96 169 163 20 42 24 64 24 145 0 107 -16 153 -80 228 -70 81 -221 128 -328
102z m172 -197 c102 -76 94 -226 -15 -278 -46 -22 -124 -16 -162 12 -15 10
-37 39 -50 64 -40 77 -9 168 71 209 50 25 115 22 156 -7z'
        />
        <path
          d='M552 1008 c-13 -13 -16 -126 -4 -144 4 -7 25 -19 47 -28 78 -33 124
-102 125 -188 0 -36 4 -50 19 -58 30 -15 486 -14 498 3 6 6 13 38 17 70 10 95
43 139 129 176 l48 21 -3 77 -3 78 -38 3 c-85 7 -224 -92 -278 -200 l-29 -58
-95 0 -95 0 -29 58 c-16 32 -49 77 -73 101 -73 74 -202 123 -236 89z'
        />
        <path
          d='M3994 1686 c-18 -8 -36 -20 -40 -28 -5 -7 -11 -101 -14 -208 -5 -175
-8 -199 -27 -236 -34 -64 -71 -103 -127 -132 l-51 -27 -629 -3 c-653 -3 -681
-1 -627 36 57 40 81 71 81 102 0 40 -47 90 -86 90 -25 0 -378 -193 -451 -247
-39 -28 -50 -63 -33 -103 18 -43 427 -283 484 -284 48 -1 86 38 86 89 0 34 -3
37 -80 94 -21 16 -22 21 -11 35 12 14 76 16 641 16 604 0 630 1 694 20 88 28
179 97 233 178 66 98 75 139 80 358 l5 191 -28 31 c-36 38 -60 45 -100 28z'
        />
      </g>
    </svg>
  );
}
