export const getAllTickets = async (
  welletContext,
  categoryIds,
  limit = 20,
  skip = 0,
  includeInactive = false,
  activityId = null,
  orgId,
) => {
  let url = 'api/Ticket/all';

  return await welletContext.apis.admin.post(url, {
    limit,
    skip,
    categoryIds,
    includeInactive,
    activityId,
    organizationId: orgId,
  });
};

export const postTicket = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/Ticket`, body);
};

export const getTicketById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/Ticket/${id}`);
};
export const putTicket = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`/api/Ticket`, body);
};
export const orderTicketByPriority = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/Ticket/priority`, body);
};
