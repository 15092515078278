import { ArrowIcon, CheckIcon } from '../../icons';
import './badge.css';

const iconType = {
  check: <CheckIcon />,
  arrowup: <ArrowIcon rotate={'90'} size='10px' />,
};

const Badge = ({ text, type, icon, className, iconColor }) => {
  const iconClass = iconColor ? `badge-wellet-${iconColor}-icon` : 'badge-icon';

  return (
    <div className={`badge-wellet text-nowrap ${type} ${className} `}>
      {icon ? <div className={`d-inline-flex mr-1 ${iconClass}`}>{iconType[icon]}</div> : null}
      {text}
    </div>
  );
};

export default Badge;
