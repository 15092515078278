import React from 'react';
import './tabFilter.css';

const TabFilter = ({ tabs = [], onClick = () => {} }) => {
  return (
    <div className='d-flex tab-filter-container'>
      {tabs?.map((t, i) => (
        <div
          key={i}
          onClick={() => onClick(t.type)}
          className={`${i === 0 ? 'first' : 'normal'} tab-filter ${
            i === tabs.length - 1 ? 'last' : ''
          } ${t.isActive ? 'active' : ''}`}
        >
          {t.title}
        </div>
      ))}
    </div>
  );
};

export default TabFilter;
