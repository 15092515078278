import React from 'react';
import styles from './cardCheck.module.css';
import { TrashIcon } from '../../../uiComponents/icons';

const CardCheck = ({
  title,
  subtitle,
  check = false,
  className = '',
  onClick,
  showDelete = false,
  onDeleteClick,
}) => {
  const handleOnClickDelete = (e) => {
    e.stopPropagation();
    onDeleteClick();
  };

  return (
    <div className={`${styles.cardCheck} ${className}`} onClick={onClick}>
      <div className='d-flex justify-content-between'>
        <div>
          <div className={styles.titleCardCheck}>{title}</div>
          <div className='dark-grey'>{subtitle}</div>
        </div>
        <div className={`${styles.checkboxContainer}`}>
          {showDelete && (
            <div className={styles.deleteIcon} onClick={handleOnClickDelete}>
              <TrashIcon color='#344054' size='17px' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardCheck;
