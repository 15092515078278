import React from 'react';

export default function WireIcon({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2424 5110 c-257 -47 -480 -202 -608 -422 l-28 -48 -254 0 -254 0 0
105 c0 119 -12 155 -60 180 -44 23 -92 19 -136 -11 -96 -65 -661 -499 -681
-522 -17 -20 -23 -40 -23 -75 0 -42 5 -53 37 -86 44 -45 651 -506 695 -529 45
-22 97 -10 136 33 l32 35 0 110 0 110 233 0 234 0 13 -41 c14 -40 13 -42 -11
-63 -14 -11 -280 -232 -591 -489 -396 -328 -571 -479 -582 -502 -24 -50 -20
-121 9 -171 35 -60 89 -84 188 -84 l77 0 0 -157 c0 -140 2 -162 20 -191 26
-42 76 -72 123 -72 l37 0 0 -614 0 -614 -38 -7 c-49 -8 -96 -36 -119 -72 -15
-22 -19 -55 -23 -188 l-5 -160 -100 -5 c-111 -6 -137 -16 -165 -63 -18 -29
-20 -51 -20 -215 0 -207 5 -224 70 -261 37 -20 50 -21 449 -21 l412 0 24 25
c32 31 32 69 0 100 l-24 25 -391 0 -390 0 0 130 0 130 1850 0 1850 0 0 -130 0
-130 -1310 0 -1311 0 -24 -25 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50
l24 -25 1331 0 c1461 0 1359 -4 1414 60 26 32 26 32 26 216 0 202 -5 225 -57
260 -21 14 -53 20 -128 24 l-100 5 -2 145 c-3 173 -5 180 -44 224 -22 26 -43
38 -84 47 l-54 12 -3 344 -3 345 -28 24 c-34 29 -56 30 -91 3 l-26 -20 0 -350
0 -349 -190 0 -190 0 0 615 0 615 188 -2 187 -3 5 -128 c4 -112 7 -130 24
-143 28 -20 69 -17 97 5 23 18 24 27 27 145 l4 126 36 0 c46 0 96 30 122 72
18 29 20 51 20 191 l0 157 77 0 c57 0 88 5 120 21 82 40 117 148 76 234 -15
31 -482 426 -1177 996 -19 15 -19 20 -6 58 l13 41 234 0 233 0 0 -110 0 -110
33 -35 c38 -43 90 -55 135 -33 45 23 680 507 708 540 35 43 34 115 -3 155 -30
31 -662 512 -700 531 -30 16 -78 15 -113 -3 -48 -25 -60 -61 -60 -180 l0 -105
-254 0 -255 0 -30 53 c-45 79 -118 165 -196 230 -79 67 -235 146 -335 172 -77
19 -274 28 -346 15z m352 -175 c190 -64 333 -188 420 -364 50 -102 67 -181 68
-306 1 -133 -12 -192 -69 -310 -72 -149 -176 -255 -320 -326 -112 -55 -190
-73 -315 -73 -125 0 -203 18 -315 74 -142 70 -244 172 -316 315 -49 99 -67
165 -75 275 -23 302 169 595 461 705 94 36 155 45 276 40 81 -3 125 -10 185
-30z m-1622 -383 c30 -57 55 -62 332 -62 l246 0 -5 -22 c-18 -71 -29 -183 -24
-250 l5 -78 -241 0 c-319 0 -326 -4 -335 -176 l-5 -92 -186 141 c-102 78 -234
178 -293 223 l-107 81 292 221 292 222 6 -88 c3 -53 12 -100 23 -120z m3143
-22 l281 -213 -106 -81 c-59 -45 -191 -145 -293 -223 l-186 -142 -5 93 c-6
103 -15 129 -61 156 -29 18 -52 20 -274 20 l-241 0 5 78 c5 67 -6 179 -24 250
l-5 22 247 0 c208 0 252 3 281 16 50 24 67 64 72 167 3 63 8 85 16 79 6 -5
138 -105 293 -222z m-2403 -810 c45 -60 129 -134 210 -185 243 -155 573 -173
831 -45 106 53 203 127 272 208 31 37 60 67 66 67 5 0 263 -209 573 -465 432
-356 563 -470 561 -485 -2 -20 -9 -20 -1847 -20 -1838 0 -1845 0 -1847 20 -2
14 22 40 84 90 47 39 302 249 567 468 264 218 482 397 486 397 3 0 23 -22 44
-50z m2226 -1215 l0 -135 -1560 0 -1560 0 0 135 0 135 1560 0 1560 0 0 -135z
m-2560 -900 l0 -615 -190 0 -190 0 0 608 c0 335 3 612 7 615 3 4 89 7 190 7
l183 0 0 -615z m660 0 l0 -615 -255 0 -255 0 0 608 c0 335 3 612 7 615 3 4
118 7 255 7 l248 0 0 -615z m530 0 l0 -615 -190 0 -190 0 0 615 0 615 190 0
190 0 0 -615z m658 -2 l2 -613 -255 0 -255 0 0 615 0 615 253 -2 252 -3 3
-612z m712 -903 l0 -140 -1560 0 -1560 0 0 140 0 140 1560 0 1560 0 0 -140z'
        />
        <path
          d='M2513 4775 c-14 -10 -23 -30 -28 -65 -6 -46 -10 -52 -43 -66 -141
-59 -179 -250 -70 -351 12 -12 84 -46 158 -77 74 -31 143 -65 153 -77 54 -67
-31 -150 -139 -135 -52 7 -97 28 -142 67 -39 34 -70 37 -106 8 -19 -15 -26
-30 -26 -54 0 -27 9 -41 51 -79 27 -25 73 -54 102 -64 50 -19 51 -21 63 -73
13 -58 33 -79 76 -79 39 0 65 30 73 83 6 41 10 45 47 58 52 17 126 83 149 134
40 87 14 194 -61 258 -21 18 -98 57 -171 87 l-134 55 -3 36 c-5 54 24 74 107
74 56 0 74 -5 123 -33 63 -36 97 -36 123 1 23 33 18 70 -12 99 -27 26 -127 78
-150 78 -6 0 -14 23 -18 50 -8 55 -31 80 -75 80 -14 0 -35 -7 -47 -15z'
        />
      </g>
    </svg>
  );
}
