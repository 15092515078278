import React, { useContext, useState } from 'react';
import {
  ButtonFilter,
  Input,
  Loading,
  Money,
  OrganizationCard,
  PageError,
  Slider,
} from '../../uiComponents/common';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  DateFilter,
  FilterCard,
  InfoRenderer,
  PageHeader,
  Show,
  Table,
  TableCell,
  TableRow,
} from '../../components';
import { SearchIcon, Stripe } from '../../uiComponents/icons';
import {
  useCreateBreadCrumbs,
  useDebounce,
  useGetRestaurants,
  useLocalStorage,
  usePagination,
} from '../../hooks';
import { getGateways, getPaymentLiveFeed } from '../../services';
import { filtersFormat, getErrorCode } from '../../helpers/format';
import { paymentLiveFeedBadge } from '../../helpers/status';
import { useTranslation } from 'react-i18next';
import { formatDateTranslation } from '../../helpers/dates';
import MercadoPago from '../../uiComponents/icons/mercadoPago';
import Fiserv from '../../uiComponents/icons/fiserv';
import MinMaxFilter from '../../uiComponents/common/amountFilter/minMax';
import { brandsStatus } from '../../data';
import { Link, useNavigate } from 'react-router-dom';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import PaymentDetail from '../paymentDetail/paymentDetail';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Payments = () => {
  const { t: translate } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.paymentsLiveFeed' });
  const language = useSelector((state) => state.app.language);
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-payments-livefeed', {
    concierge: [],
    location: [],
    citiesIds: [],
    statusType: [],
    filterField: null,
    amount: {
      min: null,
      max: null,
    },
    page: 1,
    brands: brandsStatus(),
    gateway: [],
    livefeed: false,
  });

  const welletContext = useContext(WelletContext);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const navigate = useNavigate();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const { getClientTranslation } = useGenericTranslation();
  // filterLocalStorage.page,
  const isMarket = organization.id === organization.marketId;

  const [livefeed, setLivefeed] = useState(filterLocalStorage?.livefeed);
  const [filterRestaurante, setFilterRestaurante] = useState(filterLocalStorage?.location);
  const [filterField, setFilterField] = useState(filterLocalStorage?.filterField);
  const [filterBrands, setFilterBrands] = useState(
    filterLocalStorage?.brands ? filterLocalStorage.brands : brandsStatus(),
  );
  const [paymentInfo, setPaymentInfo] = useState({
    chargeId: null,
    id: null,
    destinationPaymentId: null,
  });
  const [filterGateway, setFilterGateway] = useState(
    filterLocalStorage?.gateway ? filterLocalStorage?.gateway : [],
  );
  const [filterAmount, setFilterAmount] = useState(
    filterLocalStorage?.amount ? filterLocalStorage?.amount : { min: null, max: null },
  );

  const [filter, setFilter] = useState(filterLocalStorage?.statusType);

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const handleFilter = (filter, adjustment) => {
    if (filter === null) {
      setFilter([]);
    } else if (adjustment === null) {
      setFilter(filter);
    }
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, page: 1 });
  };

  //Data filter
  const organizationId = organization.id === organization.marketId ? null : Number(organization.id);
  const debouncedSearch = useDebounce(filterField, 500);
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };

  // Main API Query
  const paymentsQuery = useQuery({
    queryKey: [
      'payments-livefeed',
      organizationId,
      organization.marketId,
      filtersFormat(filterRestaurante),
      debouncedSearch, // input,
      selectedFormattedDates,
      limit,
      skip,
      filter,
      filtersFormat(filterBrands),
      filtersFormat(filterGateway),
      filterAmount,
      livefeed,
    ],
    queryFn: () =>
      getPaymentLiveFeed(
        welletContext, // wellet context
        organization.marketId,
        organizationId, // organization id
        livefeed ? undefined : selectedFormattedDates,
        filter ? filter : [],
        limit, // limit
        skip, // skip
        debouncedSearch, // input,
        null,
        [], // id
        filtersFormat(filterRestaurante),
        [], // cities
        filtersFormat(filterBrands),
        filtersFormat(filterGateway),
        filterAmount,
      ),

    onSuccess: (data) => {
      setTotalAndReset(data?.data?.customerPayments?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        page: currentPage,
        filterField,
        location: filterRestaurante,
        concierge: [],
        statusType: filter,
        amount: { min: filterAmount?.min, max: filterAmount?.max },
        brands: filterBrands,
        gateway: filterGateway,
        livefeed: livefeed,
      });
    },
    ...defaultQueryOptions,
    refetchInterval: livefeed && paymentInfo.id === null ? 10000 : false,
    keepPreviousData: true,
  });
  // Hooks Query Filters
  const restaurantesQuery = useGetRestaurants(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((org) => ({
        label: org.name,
        value: org.id,
        isActive:
          filterLocalStorage.location.length > 0
            ? filtersFormat(filterLocalStorage.location).includes(org.id)
            : false,
      }));
      setFilterRestaurante(response);
    },
    keepPreviousData: true,
  });
  const gatewaysQuery = useQuery({
    queryKey: ['gateways', organization.marketId],
    queryFn: () => getGateways(welletContext, organization.marketId),
    onSuccess: (data) => {
      let response = data?.data?.map((g) => ({
        label: translate(`paymentMethods.${g.toLocaleUpperCase()}`),
        value: g,
        isActive:
          filterLocalStorage.gateway.length > 0
            ? filtersFormat(filterLocalStorage.gateway).includes(g)
            : false,
      }));
      setFilterGateway(response);
    },
    ...defaultQueryOptions,
  });

  const payments = paymentsQuery?.data?.data || [];

  const headerAdmin = [
    { title: t('table.date'), value: '', filterable: false },
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.amount'), value: '', filterable: false },
    { title: t('table.brand'), value: '', filterable: false },
    { title: 'Charge ID', value: '', filterable: false },
    { title: '3DS', value: '', filterable: false },
    { title: '', value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  const onModalClose = () => {
    setPaymentInfo({
      chargeId: null,
      id: null,
      destinationPaymentId: null,
    });
  };

  useCreateBreadCrumbs([{ name: t('title'), path: '/payments-livefeed' }]);

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={
          !livefeed ? (
            <div className='mt-1'>
              <DateFilter />
            </div>
          ) : null
        }
      />
      <Show>
        <Show.When
          isTrue={paymentsQuery.isLoading || restaurantesQuery.isLoading || gatewaysQuery.isLoading}
        >
          <Loading />
        </Show.When>
        <Show.When isTrue={paymentsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('cardFilter.total')}
              quantity={payments?.totalsCount}
              onClick={() => handleFilter([], null)}
              className='reservations col'
              isSelected={filter?.length === 0 || filter === undefined}
            />
            <FilterCard
              category={t('cardFilter.ok')}
              quantity={payments?.okRegisters}
              onClick={() => handleFilter(['OK'], null)}
              className='reservations col'
              isSelected={filter && filter[0] === 'OK'}
            />
            <FilterCard
              category={t('cardFilter.error')}
              quantity={payments?.errorRegisters}
              onClick={() => handleFilter(['ERROR'], null)}
              className='reservations col'
              isSelected={filter && filter[0] === 'ERROR'}
            />
            <FilterCard
              category={t('cardFilter.refund')}
              quantity={payments?.refundRegisters + payments?.partiallyRefundedRegister}
              onClick={() => handleFilter(['REFUNDED', 'PARTIALLY_REFUNDED'], null)}
              className='reservations col'
              isSelected={filter && filter[0] === 'REFUNDED'}
            />
            <FilterCard
              category={t('cardFilter.pending')}
              quantity={payments?.pendingRegisters}
              onClick={() => handleFilter(['PENDING'], null)}
              className='reservations col'
              isSelected={filter && filter[0] === 'PENDING'}
            />
          </div>
          <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
            <div className='col col-sm-auto align-items-center justify-content-center'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                customClass='mb-0'
                name={'search'}
                value={filterField}
                onChangeValue={(e) => setFilterField(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <Slider
              className={'col-auto pr-3'}
              label={t('slider')}
              onClick={() => {
                setLivefeed(!livefeed);
                setFilterLocalStorage({ ...filterLocalStorage, livefeed: !livefeed });
              }}
              defaultChecked={livefeed}
            />

            <div className='row mt-1'>
              <Show.When isTrue={isMarket}>
                <div className='col-auto mb-1  pl-1 justify-content-center'>
                  <ButtonFilter
                    isSearchable={true}
                    filterName={getClientTranslation('title.plural')}
                    filterOptions={filterRestaurante}
                    callback={setFilterRestaurante}
                    error={restaurantesQuery.isError}
                  />
                </div>
              </Show.When>

              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.brands')}
                  filterOptions={filterBrands}
                  callback={(data) => {
                    setFilterBrands(data);
                  }}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.gateway')}
                  filterOptions={filterGateway}
                  callback={(data) => {
                    setFilterGateway(data);
                  }}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <MinMaxFilter
                  filterName={t('buttonsFilter.consumption')}
                  initialValue={filterAmount}
                  filterOptions={filterAmount}
                  callback={(data) => {
                    setFilterAmount(data);
                  }}
                />
              </div>
            </div>
          </div>
          <Show>
            <Show.When isTrue={paymentsQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <Table
                data={payments?.customerPayments?.items}
                currentPage={currentPage}
                header='grey'
                hasPagination={false}
                onPageChange={(page) =>
                  handlePageChange(page, setFilterLocalStorage({ ...filterLocalStorage, page }))
                }
                totalP={totalItems}
                columns={headerAdmin}
                renderRow={(payment, index) => {
                  const { date, time } = formatDateTranslation(
                    payment.localDateTime,
                    language,
                    'small',
                  );

                  return (
                    <>
                      <>
                        <TableCell style={{ width: '100px' }}>
                          <div className='sentences-capitalize text-center'>{date}</div>
                          <div className='text-center'>{time}</div>
                        </TableCell>
                        <TableCell>
                          <OrganizationCard
                            width='auto'
                            name={payment?.restaurant?.name}
                            date={payment?.restaurant?.cityName}
                            size={40}
                            imageUrl={payment?.restaurant?.logo}
                            hightLightName={true}
                          />
                        </TableCell>

                        <TableCell style={{ width: 'auto' }} type={'primary'}>
                          <div className='fw-600'>
                            {payment.transactionAmount ? (
                              <Money value={payment.transactionAmount} />
                            ) : (
                              '-'
                            )}
                          </div>
                        </TableCell>

                        <TableCell style={{ width: 'auto' }}>
                          <div className='fw-600'>
                            {payment.pax} {payment.pax > 1 ? t('people') : t('person')}
                            {' - '}
                            {payment.referenceCode ? payment.referenceCode : '-'}
                          </div>
                          <div className='fw-500 text-capitalize'>{payment.customerName}</div>
                        </TableCell>
                        <TableCell className={'fw-600'}>
                          {payment.gatewayName?.includes('stripe') &&
                          (payment.status === 'OK' || payment.status === 'REFUNDED') ? (
                            <Link
                              className='custom-link-file'
                              onClick={(e) => {
                                e.stopPropagation();
                                setPaymentInfo({
                                  chargeId: payment.chargeId,
                                  id: payment.id,
                                  destinationPaymentId: payment.destinationPaymentId,
                                });
                              }}
                            >
                              {payment.chargeId}
                            </Link>
                          ) : (
                            <InfoRenderer content={payment.chargeId} />
                          )}
                        </TableCell>
                        <TableCell className={'fw-500'}>
                          <div className='fw-500 text-capitalize mt-1'>
                            {payment?.threeDsStatus ? payment?.threeDsStatus : '-'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className='d-flex align-items-center'>
                            <div className='img-table no-filter d-flex align-items-center rounded justify-content-center overflow-hidden'>
                              <Show.When isTrue={payment.gatewayName?.includes('mercadopago')}>
                                <MercadoPago size={'30px'} />
                              </Show.When>
                              <Show.When isTrue={payment.gatewayName?.includes('fiserv')}>
                                <Fiserv size={'30px'} />
                              </Show.When>
                              <Show.When isTrue={payment.gatewayName?.includes('stripe')}>
                                <Stripe size={'40px'} />
                              </Show.When>
                            </div>
                            <Show.When isTrue={payment.brand}>
                              <img
                                style={{ height: '25px' }}
                                src={
                                  'https://cdn1.wellet.fun/images/credit-card/' +
                                  payment.brand?.toLocaleLowerCase() +
                                  '-logo.png'
                                }
                                className='ml-2'
                                alt='card'
                              />
                            </Show.When>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 'auto' }}>
                          {paymentLiveFeedBadge(payment?.status, translate)}
                          <Show.When isTrue={payment?.errorCode}>
                            <div className='fw-500 mt-1'>
                              {getErrorCode(payment.errorCode, translate)}
                            </div>
                          </Show.When>
                        </TableCell>
                      </>
                    </>
                  );
                }}
                onRowClick={(item) => {
                  if (item.chargeId) navigate(`/transactions/${item.chargeId}`);
                }}
              />
              <PaymentDetail
                id={paymentInfo.id}
                destinationPaymentId={paymentInfo.destinationPaymentId}
                chargeId={paymentInfo.chargeId}
                onClose={onModalClose}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </>
  );
};

export default Payments;
