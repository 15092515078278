import React, { useState } from 'react';

const ToggleableMenu = ({ title, initialView = false, children, secondaryTitle }) => {
  const [viewSettings, setViewSettings] = useState(initialView);

  const toggleView = () => {
    setViewSettings(!viewSettings);
  };

  return (
    <div className='d-flex flex-column'>
      <div className='row' onClick={toggleView}>
        <div className='col align-items-center'>{!viewSettings ? title : secondaryTitle}</div>
      </div>
      <div className={`dropdown-container ${viewSettings ? 'd-block' : 'd-none'}`}>{children}</div>
    </div>
  );
};

export default ToggleableMenu;
