import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getCities, getCitiesByMarket } from '../../services';
import { defaultQueryOptionsFilter } from '../../utils/defaultQueryOptions';

export const useGetCities = (value, options, isFromState = false) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptionsFilter,
    ...options,
  };

  return useQuery(
    ['cities-market', value],
    () => isFromState ? getCities(welletContext, value) : getCitiesByMarket(welletContext, value.marketId),
    queryOptions,
  );
};
