export const getAllUsers = async (
  welletContext,
  limit = 20,
  skip = 0,
  marketId = null,
  orgId = null,
  fieldFilter = null,
  includeInactive = false,
  kycFilter,
  isAdvantageNetwork,
  userStatus,
  RPId,
  paymentTypes = null,
  bossIds = null,
  includeSalesCategory = true,
) => {
  let url = 'api/user/list';
  if (marketId === orgId) {
    url = `api/user/${marketId}/list`;
  } else if (orgId) {
    url = `api/user/${marketId}/${orgId}/list`;
  }
  const body = {
    limit: limit,
    skip: skip,
    includeInactive: includeInactive,
    userStatus,
    fieldFilter: fieldFilter,
    KYCFilter: kycFilter,
    isAdvantageNetwork,
    includeSalesCategory,
    RPIds: RPId ? RPId : [],
    withdrawalMethod: paymentTypes !== null && paymentTypes.length > 0 ? paymentTypes : null,
    bossIds: bossIds ? bossIds : [],
  };

  return await welletContext.apis.admin.post(url, body);
};
export const getAllUsersFiltered = async (
  welletContext,
  limit = 20,
  skip = 0,
  marketId = null,
  orgId = null,
  fieldFilter = null,
  includeInactive = false,
  kycFilter,
  isAdvantageNetwork,
  userStatus,
  RPId,
  paymentTypes = null,
  bossIds = null,
  includeSalesCategory = true,
) => {
  let url = `api/user/${marketId}/request-affiliation`;

  function findAndRemoveDeleted(array) {
    const deletedItem = array.find((item) => item === 'DELETED');
    const originalArray = array.filter((item) => item !== 'DELETED');
    return { deletedItem: deletedItem ? [deletedItem] : null, originalArray };
  }

  const arrayStatus = findAndRemoveDeleted(kycFilter);

  const body = {
    limit: limit,
    skip: skip,
    includeInactive: includeInactive,
    userStatus: arrayStatus.deletedItem,
    fieldFilter: fieldFilter,
    KYCFilter: arrayStatus.originalArray,
    isAdvantageNetwork,
    includeSalesCategory,
    RPIds: RPId ? RPId : [],
    withdrawalMethod: paymentTypes !== null && paymentTypes.length > 0 ? paymentTypes : null,
    bossIds: bossIds,
  };
  return await welletContext.apis.admin.post(url, body);
};
export const getUserById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/User/${id}`);
};
export const postUser = async (welletContext, body) => {
  return await welletContext.apis.tickets.post(`admin/user`, body);
};
export const putUser = async (welletContext, body) => {
  return await welletContext.apis.tickets.put(`/admin/user`, body);
};
export const getAllRoles = async (welletContext) => {
  return await welletContext.apis.admin.get(`api/user/GetRoles`);
};
export const getAllSalesTeamLead = async (welletContext, orgId) => {
  return await welletContext.apis.admin.get(`api/user/${orgId}/reportToUser`);
};
export const getProfilePic = async (welletContext, url) => {
  return await welletContext.apis.tickets.get(url, {
    responseType: 'arraybuffer',
  });
};
export const getImgsKyc = async (welletContext, id = null) => {
  let url = 'admin/user/kyc';
  const newUrl = `${url}/${id}/photos`;
  const response = await welletContext.apis.tickets.get(newUrl);
  return response;
};
export const getSalesCategorys = async (welletContext, orgId) => {
  const response = await welletContext.apis.admin.get(`api/SalesCategory/organization/${orgId}`);
  return response;
};
export const resetKyc = async (welletContext, body) => {
  const response = await welletContext.apis.admin.put(`/api/user/kyc`, body);
  return response;
};
export const changesCategory = async (welletContext, marketId, body) => {
  const response = await welletContext.apis.admin.put(
    `/api/user/${marketId}/SetSaleCategory`,
    body,
  );
  return response;
};
export const getConciergeSimple = async (welletContext, marketId) => {
  const body = {
    limit: null,
    skip: null,
  };
  return await welletContext.apis.admin.post(`api/user/${marketId}/filterConcierge`, body);
};
export const getAccountsSimple = async (welletContext, marketId, batchId) => {
  return await welletContext.apis.admin.get(`/api/user/${marketId}/batch/${batchId}`);
};
export const getBossRpSimple = async (welletContext, marketId, includeInactive = false) => {
  return await welletContext.apis.admin.get(
    `/api/user/${marketId}/bosses-rps?IncludeInactive=${includeInactive}`,
  );
};
export const getRPs = async (welletContext, marketId, includeInactive = false) => {
  return await welletContext.apis.admin.get(
    `/api/user/${marketId}/rps?IncludeInactive=${includeInactive}`,
  );
};
export const getBosses = async (welletContext, marketId, includeInactive = false) => {
  return await welletContext.apis.admin.get(
    `/api/user/${marketId}/bosses?IncludeInactive=${includeInactive}`,
  );
};

export const postLngUser = async (welletContext, lng) => {
  const body = { lang: lng };
  return await welletContext.apis.tickets.post(`/associates/saveconfig`, body);
};

export const getAdvantageNetwork = async (welletContext, marketId) => {
  return await welletContext.apis.admin.get(`api/user/${marketId}/advantagenetwork`);
};

export const getSalesCategorysNew = async (welletContext, marketId) => {
  const response = await welletContext.apis.admin.get(
    `/api/SalesCategory/market/${marketId}?sort=DESC`,
  );
  return response;
};

//Obtiene la categoria (loyalties) actual por usuario
export const getUserSalesCategorys = async (welletContext, marketId, id) => {
  const response = await welletContext.apis.admin.get(
    `/api/SalesCategory/market/${marketId}/userSalesCategory/${id}`,
  );
  return response;
};

export const putConciergeStatus = async (welletContext, marketId = null, body) => {
  const url = marketId ? `/admin/user/${marketId}/concierge/status` : `/admin/concierge/status`;

  return await welletContext.apis.tickets.put(url, body);
};


export const getQrLinkget = async (welletContext, url) => {
  return await welletContext.apis.tickets.get(url, 
    {responseType: 'arraybuffer',
    });
};

export const getQrLink = async (welletContext, id) => {
  return await welletContext.apis.tickets.post(`admin/user/qr/pdf/${id}`);
};