import React, { useContext } from 'react';
import { InputNumber, InputText, Modal, Select } from '../../uiComponents/common';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getAllUsers } from '../../services';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { useTranslation } from 'react-i18next';

const ModalAdjustment = ({ open, onClose, handleSubmit, showUsers = false, isLoading, canAddAdjustment }) => {
  const methods = useForm();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalAdjustment' });

  const sellersQuery = useQuery({
    queryKey: ['sellers', organization.id],
    queryFn: () =>
      getAllUsers(
        welletContext, // welletContext
        null, // limit
        null, // skip
        organization.marketId, // marketID
        organization.id, // Org ID
        null, //fieldFilter
        false, // includeInactive
        ['APPROVED'], // kyc filter
        true, // onlySellers
      ),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: canAddAdjustment
  });
  const sellers = sellersQuery?.data?.data?.items?.map((s) => ({
    label: `${s.name} (${s.email})`,
    value: s.sid,
  }));

  const onSubmit = (data) => {
    handleSubmit(data);
  };

  const handleClose = () => {
    methods.reset({
      user: null,
      amount: null,
      comment: null,
    });
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <form key={'adjustment'}>
        <Modal
          title={t('title')}
          open={open}
          onClose={handleClose}
          textCancel={t('cancel')}
          textSubmit={t('submit')}
          maxWidth='650px'
          isLoading={isLoading}
          handleSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='modal-background '>
            {showUsers ? (
              <Select
                name={'user'}
                label={t('form.inputAffiliate.label')}
                isDisabled={isLoading}
                placeholder={t('form.inputAffiliate.placeholder')}
                isRequired={true}
                options={sellers}
                className={'mb-2'}
              />
            ) : null}
            <InputNumber
              name={'amount'}
              label={t('form.inputAmount.label')}
              placeholder={t('form.inputAmount.label')}
              isRequired={true}
              isDisabled={isLoading}
              customValidation={{}}
              className='mb-2'
            />
            <InputText
              maxLength={512}
              label={t('form.inputComment.label')}
              placeholder={t('form.inputComment.label')}
              name={'comment'}
              isDisabled={isLoading}
              isRequired={true}
            />
          </div>
        </Modal>
      </form>
    </FormProvider>
  );
};

export default ModalAdjustment;
