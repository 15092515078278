import React, { useState } from 'react';
import { Tabs } from '../../uiComponents/common';
import { useLocation, useParams } from 'react-router-dom';

import { Show } from '../../components';
import { useSelector } from 'react-redux';

import { useCreateBreadCrumbs, useUser } from '../../hooks';
import { hasAllowedRoles } from '../../helpers/roles';
import { useTranslation } from 'react-i18next';

import Loyalty from './components/loyalty';

import KycDoc from './components/kycDoc';
import Profile from './components/userProfileNew';

const tabsTypes = {
  PROFILE: 'PROFILE',
  LOYALTY: 'LOYALTY',
  DOCUMENTATION: 'DOCUMENTATION',
};

const UserProfile = () => {
  const { id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'screens' });

  const location = useLocation();

  const adminUser = useSelector((state) => state.user.user.sid);
  const { roles } = useUser();

  const [activeTab, setActiveTab] = useState(tabsTypes.PROFILE);
  const [kycStatus, setkycStatus] = useState('');
  const isFromSeller = location.pathname.split('/')[1] === 'sellers';
  const isFromSellerkYC = location.pathname.split('/')[1] === 'pendingAssociates';
  const isFromUser = location.pathname.split('/')[2] === 'team-and-security';
  const isFromNewUser = location.pathname.split('/')[3] === 'new-user';
  const currentId = isFromNewUser ? false : id ? id : adminUser;

  // Main Api Querys

  // Const Api Querys

  // Api Querys

  const currentCrumbs = isFromSeller
    ? [
        { name: t('concierges.title'), path: '/sellers' },
        { name: t('profile.breadcrumbsConcierges') },
      ]
    : isFromUser
    ? [
        { name: t('users.breadcrumbs.prev'), path: '/settings' },
        { name: t('users.breadcrumbs.current'), path: '/settings/team-and-security' },
        {
          name: id ? t('profile.breadcrumbsEditUser') : t('profile.breadcrumbsNewUser'),
          path: id ? `/users/edit-users/${id}` : '/new-user',
        },
      ]
    : isFromSellerkYC
    ? [
        { name: t('profile.breadcrumbsSellersKyc'), path: location.state },
        { name: t('profile.breadcrumbsConcierges') },
      ]
    : [
        {
          name: t('profile.breadcrumbs'),
        },
      ];

  const canSeeDocumentation =
    (isFromSeller || isFromSellerkYC) &&
    hasAllowedRoles(roles, [
      'admin',
      'superadministrator',
      'affiliatecenter',
      'affiliatecenter-onlyview',
      'marketadmin',
      'bosssales',
      'approvekyc',
    ]);

  const tabs = [
    {
      isActive: activeTab === tabsTypes.PROFILE,
      title: t('profile.breadcrumbs'),
      type: tabsTypes.PROFILE,
    },
    canSeeDocumentation
      ? {
          isActive: activeTab === tabsTypes.DOCUMENTATION,
          title: t('profile.documentationKyc.title'),
          type: tabsTypes.DOCUMENTATION,
          show: kycStatus === 'SEND',
        }
      : {},
    {
      isActive: activeTab === tabsTypes.LOYALTY,
      title: 'Loyalty',
      type: tabsTypes.LOYALTY,
    },
  ];

  const handleTabs = (type) => {
    setActiveTab(type);
  };

  useCreateBreadCrumbs(currentCrumbs);

  return (
    <>
      {isFromSeller ? <Tabs className='mb-4' onClick={handleTabs} options={tabs} /> : null}
      <Show>
        <Show.When isTrue={activeTab === tabsTypes.PROFILE}>
          <Profile
            id={currentId}
            mode={isFromSeller ? 'CONCIERGE' : isFromUser ? 'USER' : 'PROFILE'}
            setkycStatus={setkycStatus}
          />
        </Show.When>
        <Show.When isTrue={activeTab === tabsTypes.DOCUMENTATION}>
          {(isFromSeller || isFromSellerkYC) &&
            hasAllowedRoles(roles, [
              'admin',
              'superadministrator',
              'affiliatecenter',
              'affiliatecenter-onlyview',
              'marketadmin',
              'bosssales',
              'approvekyc',
            ]) && <KycDoc id={id} setkycStatus={setkycStatus}/>}
        </Show.When>
        <Show.When isTrue={activeTab === tabsTypes.LOYALTY}>
          <Loyalty id={id} />
        </Show.When>
      </Show>
    </>
  );
};

export default UserProfile;
