import React, { useState } from 'react';
import { Tabs } from '../../uiComponents/common';
import { PageHeader, Show } from '../../components';
import { useTranslation } from 'react-i18next';
import Loyalty from '../loyalty/loyalty';
import MembershipForm from './components/memberShipForm';
import { useCreateBreadCrumbs } from '../../hooks';

const Memberships = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.memberships' });
  const [activeTab, setActiveTab] = useState('DATA');

  const tabs = [
    {
      isActive: activeTab === 'DATA',
      title: t('tab.data'),
      type: 'DATA',
    },
    {
      isActive: activeTab === 'LEVEL',
      title: t('tab.level'),
      type: 'LEVEL',
    },
  ];

  const handleTabs = (type) => {
    setActiveTab(type);
  };

  useCreateBreadCrumbs([
    { name: t('breadcrumbs.prev'), path: '/settings' },
    { name: t('breadcrumbs.current') },
  ]);

  return (
    <>
      <PageHeader border={false} title={t('title')} />
      <Tabs className='mb-4' onClick={handleTabs} options={tabs} />
      <Show>
        <Show.When isTrue={activeTab === 'DATA'}>
          <MembershipForm />
        </Show.When>
        <Show.When isTrue={activeTab === 'LEVEL'}>
          <Loyalty />
        </Show.When>
      </Show>
    </>
  );
};

export default Memberships;
