import React from 'react';
import { ZoomImg } from '../zoomImg/zoomImg';
import styles from './kycImage.module.css';
import { Show } from '../../../components';
import { NoImage } from '../../../screens/users/components'

const kycStatusType = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  SEND: 'SEND',
  REVALIDATION: 'REVALIDATION',
};

const kycImage = ({ front, back, face, status, textNoImage }) => {
  const isRevalidate = status === kycStatusType.REVALIDATION;
  const isPending = status === kycStatusType.PENDING;

  return (
    <Show>
      <Show.When isTrue={isPending}>
        <div className='row'>
          <div className='col-12'>
            <NoImage text={textNoImage} />
          </div>
        </div>
      </Show.When>
      <Show.Else>
        <div className='row no-gutters aling-items-center my-2 my-md-0' style={{ gap: '20px' }}>
          <div className='col-12 col-sm-6 col-md'>
            <ZoomImg img={face} className={isRevalidate && styles.imgOpacity} text={textNoImage} />
          </div>
          <div className='col-12 col-sm-6 col-md'>
            <ZoomImg img={front} className={isRevalidate && styles.imgOpacity} text={textNoImage} />
          </div>
          <div className='col-12 col-sm-6 col-md'>
            <ZoomImg img={back} className={isRevalidate && styles.imgOpacity} text={textNoImage} />
          </div>
        </div>
      </Show.Else>
    </Show>
  );
};

export default kycImage;
