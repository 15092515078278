import React from 'react';

export default function Arrow({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.58298 11.0067V0.993225M6.58298 0.993225L1.57623 5.99997M6.58298 0.993225L11.5897 5.99997'
        stroke={color}
        strokeWidth='1.7166'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}