export const SideBarLiveFeedData = [
  // {
  //   title: 'Payments',
  //   path: '/',
  //   subpath: '/paymentDetails',
  //   rol: ['superadministrator', 'admin', 'associate'],
  //   icon: <PaymentIcon size='20px' />,
  //   market: true,
  //   organization: true
  // },
];

// ---------- Dashboard ----------
export const SidebarDashboard = {
  title: 'dashboard',
  path: '/',
  rol: ['superadministrator', 'admin', 'marketadmin', 'dashboard', 'bosssales', 'salesteamlead'],
  // icon: <img src={dashboard} />,
  market: true,
  organization: true,
};
export const LiveFeedPayment = {
  title: 'paymentsLiveFeed',
  path: '/transactions',
  rol: ['superadministrator', 'marketadmin', 'wellet_finance'],
  // icon: <img src={dashboard} />,
  market: true,
  organization: false,
};

// ---------- First group ----------
export const BookingCenter = {
  title: 'request',
  path: '/reservations-pending',
  subpath: '/reservations-pending/',
  rol: [
    'superadministrator',
    'admin',
    'marketadmin',
    'reservationcenter',
    'callcenterlead',
    'reservationcenter-onlyview',
  ],
  // icon: <img src={bellIcon} />,
  market: true,
  organization: true,
};
export const AffiliationCenter = {
  title: 'Solicitud Afiliación',
  path: '/pendingAssociates',
  subpath: '/pendingAssociates',
  rol: [
    'superadministrator',
    'marketadmin',
    'affiliatecenter',
    'affiliatecenter-onlyview',
    'bosssales',
  ],
  // icon: <img src={userCheckIcon} />,
  disabled: false,
  market: true,
  organization: false,
};
export const SoldOut = {
  title: 'soldout',
  path: '/sold-out',
  rol: ['superadministrator', 'marketadmin', 'soldout'],
  // icon: <img src={calendarIcon} />,
  disabled: false,
  market: true,
  organization: true,
};
export const NotificationCenter = {
  title: 'Centro Notificaciones',
  path: '/pendingAssociates',
  subpath: '/pendingAssociates',
  rol: [
    'superadministrator',
    'marketadmin',
    'affiliatecenter',
    'affiliatecenter-onlyview',
    'bosssales',
  ],
  // icon: <UsersPendingsIcon size='20px' />,
  disabled: false,
  market: true,
  organization: false,
};
// ---------- Second Group ----------
export const AdvantageNetwork = {
  title: 'concierge',
  path: '/sellers',
  subpath: '/sellers',
  rol: [
    'superadministrator',
    'marketadmin',
    'affiliatecenter',
    'affiliatecenter-onlyview',
    'bosssales',
    'salesteamlead',
  ],
  // icon: <img src={diamondIcon} />,
  disabled: false,
  market: true,
  organization: false,
  notification: 'countAdvantageNetwork',
};
export const Booking = {
  title: 'reservation',
  path: '/reservation-history',
  subpath: '/reservation-history',
  rol: [
    'superadministrator',
    'admin',
    'marketadmin',
    'reservationcenter',
    'reservationcenter-onlyview',
    'callcenterlead',
    'bosssales',
    'salesteamlead',
    'finance',
    'auditor',
    'auditor-onlyview',
  ],
  // icon: <img src={reservationIcon} />,
  disabled: false,
  market: true,
};
export const Commissions = {
  title: 'commission',
  path: '/commissions',
  subpath: '/commissions',
  rol: [
    'superadministrator',
    'admin',
    'marketadmin',
    'auditor',
    'auditor-onlyview',
    'finance',
    'bosssales',
    'salesteamlead',
  ],
  // icon: <img src={commissionIcon} width={'24px'} />,
  disabled: false,
  market: true,
};
export const PaymentsPendingApprove = {
  title: 'Auditar Reservas',
  path: '/reservations-pending-approve',
  subpath: '/reservations-pending-approve',
  rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
  // icon: <ReservationPendingApproveIcon size='20px' />,
  market: true,
  organization: false,
};
export const Checkin = {
  title: 'checkin',
  path: '/check-ins',
  subpath: '/check-ins',
  rol: ['superadministrator', 'marketadmin', 'checker'],
  // icon: <CheckInIcon />,
  market: true,
  organization: true,
};
export const Payments = [
  {
    title: 'nationalTransfer',
    path: '/wire-payments',
    subpath: '/wire-payments',
    rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
    // icon: <img src={paymentsIcon} />,
    market: true,
    organization: false,
  },
  {
    title: 'nationalInternational',
    path: '/international-wire-payments',
    subpath: '/international-wire-payments',
    rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
    // icon: <img src={paymentsIcon} />,
    market: true,
    organization: false,
  },
  {
    title: 'cash',
    path: '/cash-payments',
    subpath: '/cash-payments',
    rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
    // icon: <img src={paymentsIcon} />,
    market: true,
    organization: false,
  },
  {
    title: 'mp',
    path: '/mercadopago-payments',
    subpath: '/mercadopago-payments',
    rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
    // icon: <img src={paymentsIcon} />,
    market: true,
    organization: false,
  },
  {
    title: 'certificates',
    path: '/certificate-payments',
    subpath: '/certificate-payments',
    rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
    // icon: <img src={paymentsIcon} />,
    market: true,
    organization: false,
  },
];
export const PaymentCentral = {
  title: 'paymentCentral',
  path: '/payments',
  subpath: '/payment-summary',
  rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],

  market: true,
  organization: false,
};

export const Billing = {
  title: 'billing',
  path: '/billing',
  subpath: '/billing',
  rol: ['superadministrator', 'marketadmin', 'finance'],
  market: true,
  organization: false,
  disabled: false,
};
export const Disputes = {
  title: 'revision',
  path: '/under-review',
  subpath: '/under-review',
  rol: [
    'superadministrator',
    'admin',
    'marketadmin',
    'auditor',
    'auditor-onlyview',
    'finance',
    'bosssales',
    'salesteamlead',
  ],
  // icon: <PaymentErrorIcon size='20px' />,
  market: true,
  organization: false,
};

export const Reports = {
  title: 'report',
  path: '/reports',
  subpath: '/reports',
  rol: ['superadministrator', 'admin', 'marketadmin', 'dashboard', 'bosssales', 'salesteamlead'],
  // icon: <img src={reportsIcons} />,
  market: true,
  organization: false,
};
export const PaymentSettings = {
  title: 'payments',
  path: '#',
  subpath: '$',
  rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
  // icon: <img src={settingsIcon} />,
  market: true,
  organization: true,
};
export const PaymentLessSettings = {
  title: 'paymentstLessSettings',
  path: '#',
  subpath: '$',
  rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
  // icon: <img src={settingsIcon} />,
  market: true,
  organization: true,
};
export const ProcessorMoreSettings = {
  title: 'processorMoreSettings',
  path: '#',
  subpath: '$',
  rol: ['superadministrator', 'marketadmin', 'wellet_finance', 'finance'],
  // icon: <img src={settingsIcon} />,
  market: true,
  organization: true,
};
export const ProcessorLessSettings = {
  title: 'processorLessSettings',
  path: '#',
  subpath: '$',
  rol: ['superadministrator', 'marketadmin', 'wellet_finance', 'finance'],
  // icon: <img src={settingsIcon} />,
  market: true,
  organization: true,
};
export const MoreSettings = {
  title: 'more',
  path: '#',
  subpath: '$',
  rol: [
    'superadministrator',
    'admin',
    'marketadmin',
    'auditor',
    'finance',
    'productloader',
    'productpriceloader',
    'auditor-onlyview',
    'marketing'
  ],
  // icon: <img src={settingsIcon} />,
  market: true,
  organization: true,
};
export const LessSettings = {
  title: 'moreLess',
  path: '#',
  subpath: '$',
  rol: [
    'superadministrator',
    'admin',
    'marketadmin',
    'auditor',
    'finance',
    'productloader',
    'productpriceloader',
    'auditor-onlyview',
    'marketing'
  ],
  // icon: <img src={settingsIcon} />,
  market: true,
  organization: true,
};
export const SidebarSettingsData = (showCampaigns = false) => [
  {
    title: 'organization',
    path: '/organizations',
    subpath: 'organization',
    rol: ['superadministrator'],
    icon: null,
    disabled: false,
    market: true,
    organization: false,
  },
  {
    title: 'title',
    path: '/restaurantes',
    subpath: '/restaurantes/',
    rol: ['superadministrator', 'marketadmin'],
    icon: null,
    disabled: false,
    market: false,
    organization: true,
    isGeneric: true,
  },
  {
    title: 'areas',
    path: '/areas',
    subpath: '-areas',
    rol: [
      'superadministrator',
      'marketadmin',
      'admin',
      'productloader',
      'productpriceloader',
      'restaurantvip',
    ],
    disabled: false,
    market: true,
    organization: true,
  },
  {
    title: 'groups',
    path: '/groups',
    subpath: '-group',
    rol: [
      'superadministrator',
      'marketadmin',
      'admin',
      'productloader',
      'productpriceloader',
      'group',
    ], // VER BIEN
    disabled: false,
    market: true,
    organization: true,
    hidden: false,
  },
  {
    title: 'campaignsList',
    path: '/campaigns-list',
    subpath: 'campaign',
    rol: ['superadministrator', 'marketadmin', 'marketing'],
    market: true,
    organization: true,
    hidden: !showCampaigns ? true : false,
  },
];

export const ProcessorData = [
  {
    title: 'paymentsLiveFeed',
    path: '/transactions',
    rol: ['superadministrator', 'marketadmin', 'wellet_finance', 'finance'],
    market: true,
    organization: false,
  },
  {
    title: 'refunds',
    path: '/refunds',
    rol: ['superadministrator', 'marketadmin', 'wellet_finance', 'finance'],
    subpath: '/refunds/',
    market: true,
    organization: false,
  },
  {
    title: 'disputes',
    path: '/disputes',
    subpath: '/disputes',
    rol: ['superadministrator', 'wellet_finance', 'marketadmin', 'finance'],
    market: true,
    organization: false,
  },
  {
    title: 'accounts',
    path: '/accounts',
    subpath: '/accounts',
    rol: ['superadministrator', 'marketadmin', 'wellet_finance', 'finance'],
    market: true,
    organization: false,
  },
];
