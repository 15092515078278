import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { deleteFAQ } from '../../../services';
import { Loading, Modal, Toast } from '../../../uiComponents/common';

const DeleteFAQ = ({ faqID, open, setOpen, refetchQuery }) => {
  const methods = useForm();
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.deleteFAQ' });

  const deleteMutation = useMutation({
    mutationFn: () => {
      return deleteFAQ(welletContext, faqID);
    },
    onSuccess: () => {
      refetchQuery();
    },
  });

  const handleClose = () => {
    setOpen('');
    deleteMutation.reset();
  };

  const onSubmit = () => {
    deleteMutation.mutate();
  };

  return (
    <>
      <FormProvider {...methods}>
        <Modal
          textCancel={t('cancel')}
          textSubmit={t('delete')}
          idModal={'faqDelete'}
          title={t('title')}
          btnType='submit'
          maxWidth='450px'
          onClose={() => {
            setOpen('');
          }}
          open={open}
          isLoading={deleteMutation.isLoading || deleteMutation.isSuccess}
          handleSubmit={methods.handleSubmit(onSubmit)}
        >
          {deleteMutation.isLoading || deleteMutation.isSuccess ? (
            <div className='p-4'>
              <Loading />
            </div>
          ) : (
            <div className='text-left mt-2 mb-3'>{t('ask')}</div>
          )}
        </Modal>
      </FormProvider>
      {deleteMutation.isError && (
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
      {deleteMutation.isSuccess && (
        <Toast
          message={t('toast.deleteSuccess')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default DeleteFAQ;
