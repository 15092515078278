import React from 'react';

export default function Stripe({ size = '16px', color = '#635BFF' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 225.000000 225.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,225.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M0 1125 l0 -1125 1125 0 1125 0 0 1125 0 1125 -1125 0 -1125 0 0
-1125z m1270 615 c41 -6 108 -20 148 -31 l72 -22 0 -163 0 -163 -58 24 c-92
37 -181 57 -262 58 -85 1 -117 -11 -126 -50 -13 -50 30 -84 167 -132 188 -66
268 -122 316 -219 25 -50 28 -68 28 -152 0 -82 -4 -103 -26 -151 -36 -75 -86
-129 -157 -167 -153 -82 -361 -90 -570 -24 l-87 28 -3 167 -2 168 47 -25 c78
-39 207 -75 290 -81 64 -5 84 -3 113 12 47 24 61 64 34 99 -25 34 -65 55 -197
105 -232 88 -319 193 -304 371 21 252 250 390 577 348z'
        />
      </g>
    </svg>
  );
}
