import React from 'react';
import { PageHeader, SchedulesForm } from '../../components';
import { useCreateBreadCrumbs } from '../../hooks';
import { useTranslation } from 'react-i18next';

const ReservationsSchedule = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.callCenter' });
  useCreateBreadCrumbs([{ name: t('settings'), path: '/settings' }, { name: t('title') }]);
  return (
    <>
      <PageHeader title={t('title')} marginBottom={false} />
      {/* Formulario */}
      <SchedulesForm sidebar callcenter />
    </>
  );
};

export default ReservationsSchedule;
