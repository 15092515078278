import React from 'react';

const PlusIcon = ({ size = '14px', color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.0013 1.1665V12.8332M1.16797 6.99984H12.8346'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PlusIcon;
