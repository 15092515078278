import { useContext } from 'react';
import styles from './menuItem.module.css';
import { Link } from 'react-router-dom';
import SidebarContext from '../../../context/sidebarContext';

export const MenuHeader = ({ title }) => (
  <div className={styles.menuHeaderContainer + ' nav-item-header text-12'}>
    <div className='icon-menu d-none'></div>
    <span className='sidebar-main-title text-sidebar-link'>{title}</span>
  </div>
);

export const MenuItem = ({
  icon,
  title,
  isSelected = false,
  path,
  disabled,
  disabledHover = false,
  notification
}) => {
  const { isMouseOver, isSidebarResized, toggleIsSidebarOpen } = useContext(SidebarContext);
  const disabledClass = disabled ? styles.disabledItem : '';
  const hoverClass = disabledHover ? styles.noHover : '';

  return (
    <Link to={disabled ? window.location.pathname : path} onClick={toggleIsSidebarOpen}>
      <div
        className={`${styles.menuItemContainer} nav-item ${
          isSelected ? styles.menuSelected : ''
        } justify-content-${
          isMouseOver || !isSidebarResized ? 'between' : 'center'
        } text-sidebar-link ${disabledClass} ${hoverClass}`}
      >
        <div className='d-flex align-items-center'>
          <div>{icon}</div>
          <span>{title}</span>
          {notification ? (
            <div
              className={`${styles.notification}  d-flex align-items-center justify-content-center`}
            >
              {notification}
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  );
};
