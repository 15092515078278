import React from 'react';

const BellIcon = ({ size = '16px', color = 'currentColor', customClass = null }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.79642 17.0001C7.38403 17.5187 8.15591 17.8334 9.00128 17.8334C9.84665 17.8334 10.6185 17.5187 11.2061 17.0001M14.0013 6.16675C14.0013 4.84067 13.4745 3.5689 12.5368 2.63121C11.5991 1.69353 10.3274 1.16675 9.00128 1.16675C7.6752 1.16675 6.40343 1.69353 5.46574 2.63121C4.52806 3.5689 4.00128 4.84067 4.00128 6.16675C4.00128 8.7419 3.35167 10.505 2.626 11.6713C2.01388 12.655 1.70783 13.1468 1.71905 13.284C1.73147 13.436 1.76366 13.4939 1.88609 13.5847C1.99666 13.6667 2.4951 13.6667 3.49199 13.6667H14.5106C15.5075 13.6667 16.0059 13.6667 16.1165 13.5847C16.2389 13.4939 16.2711 13.436 16.2835 13.284C16.2947 13.1468 15.9887 12.655 15.3766 11.6713C14.6509 10.505 14.0013 8.7419 14.0013 6.16675Z'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BellIcon;
