import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['en', 'es'];
const options = {
  order: ['navigator', 'path', 'subdomain'],
};

const initTranslations = () => {
  return i18next
    .use(HttpApi)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      load: 'languageOnly',
      detection: options,
      debug: false,
      fallbackLng: 'en',
      whitelist: languages,
      backend: {
        loadPath: '/translations/{{lng}}/{{ns}}.json',
        allowMultiLoading: false,
        crossDomain: true,
        withCredentials: false,
        queryStringParams: { v: process.env.REACT_APP_TIMESTAMP },
      },
    });
};

export default initTranslations;
