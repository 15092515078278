import { useTranslation } from 'react-i18next';
import { commissionBadge } from '../../helpers/status';
import { hasAllowedRoles } from '../../helpers/roles';
import { useUser } from '../../hooks';
import Show from '../show/show';
import { ModalCancelReservation, ModalConsumption } from '../modals';

const ReservationHeader = ({
  referenceCode,
  customer,
  status,
  reservation,
  mode
}) => {
  const { t: translate } = useTranslation();
  const { roles } = useUser();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.details.reservation.header' });

  return (
    <div className='container-header with-border'>
      <div className='row align-items-end'>
        <div className='col-auto'>
          <div className='light-grey container-small-title'>{mode === 'dispute' ? t('titleDispute') : t('title')}</div>
          <div className='container-title'>
            {referenceCode}: {customer}
            <span className='ml-3'>{commissionBadge(status, translate)}</span>
          </div>
        </div>
        <div className='col my-auto'></div>
        <div className='col-auto'>
          <div className='row'>
            <Show.When isTrue={reservation.canAddCheck}>
              <div className='col-auto'>
                <ModalConsumption
                  showComment={true}
                  maxWidth={'650px'}
                  reservationId={reservation.id}
                />
              </div>
            </Show.When>
            <Show.When
              isTrue={
                reservation.canCancel &&
                hasAllowedRoles(roles, ['finance', 'admin', 'superadministrator', 'marketadmin'])
              }
            >
              <div className='col-auto'>
                <ModalCancelReservation reservationId={reservation.id} />
              </div>
            </Show.When>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationHeader;
