export const getOrderCommission = async (welletContext, order) => {
  try {
    const response = await welletContext.apis.tickets.get(
      '/wallet/orderCommissionsByUser?orderId=',
      order,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
export const refundOrder = async (welletContext, order, companyId) => {
  try {
    const response = await welletContext.apis.tickets.post(
      `superadmin/refundOrder?orderId=${order}`,
      {},
      {
        headers: {
          'x-wellet-scope': companyId,
        },
      },
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};


export const getAllIndividualPayments = async (welletContext, id, limit, skip) => {
  return await welletContext.apis.admin.post(`api/payment/${id}`, { limit, skip });
};

export const getPaymentsMethods = async (welletContext) => {
  return await welletContext.apis.admin.get('/api/user/withdrawalMethods');
};

export const getPaymentLiveFeed = async (
  welletContext,
  marketSetupId = null,
  orgId = null,
  dates = {
    from: null,
    to: null,
  },
  status,
  limit = null,
  skip = null,
  fieldFilter = null,
  dateType = null,
  userId,
  showId = [],
  citiesIds = [],
  brands,
  gateway,
  amount,
) => {
  const url = `/api/payment/${marketSetupId}/LiveFeed`;
  let showIdArray = showId.length === 0 ? null : showId;

  const body = {
    from: dates.from,
    to: dates.to,
    organizationId: orgId,
    filterField: fieldFilter,
    showId: showIdArray,
    limit,
    skip,
    status: status ? status : [],
    userId: [],
    sortBy: null,
    columnFilter: null,
    dateType,
    citiesIds,
    cardType: brands,
    typeGateway: gateway ? gateway : null,
    minAmount: amount?.min ? amount.min : null,
    maxAmount: amount?.max ? amount.max : null,
  };

  return await welletContext.apis.admin.post(url, body);
};

export const getClientSecret = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/stripe/payment-detail/${id}`);
};
export const getPublickKey = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/stripe/key/${id}`);
};
export const getGateways = async (welletContext, marketId) => {
  return await welletContext.apis.admin.get(`/gateway/${marketId}`);
};