import React from 'react';

export default function CurrencyIcon({
  width = '13px',
  height = '13px',
  color = 'currentColor',
  rotation = 0,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1764 41.25C9.52708 41.25 8.98319 41.03 8.54472 40.59C8.10625 40.15 7.88625 39.6061 7.88472 38.9583V36.3229C6.16597 35.941 4.65729 35.2726 3.35868 34.3177C2.06007 33.3629 1.00972 32.0261 0.20764 30.3073C-0.0597211 29.7726 -0.0688879 29.2096 0.18014 28.6183C0.429168 28.0271 0.87757 27.597 1.52535 27.3281C2.06007 27.099 2.61389 27.1089 3.18681 27.3579C3.75972 27.607 4.19896 28.0172 4.50451 28.5886C5.15382 29.7344 5.975 30.6037 6.96806 31.1965C7.96111 31.7892 9.18333 32.0849 10.6347 32.0833C12.2007 32.0833 13.5276 31.7304 14.6153 31.0246C15.7031 30.3188 16.2478 29.2203 16.2493 27.7292C16.2493 26.3924 15.8292 25.3329 14.9889 24.5506C14.1486 23.7684 12.2007 22.88 9.14514 21.8854C5.86042 20.8542 3.60695 19.6228 2.38472 18.1913C1.1625 16.7597 0.551389 15.012 0.551389 12.9479C0.551389 10.4653 1.35347 8.53647 2.95764 7.16147C4.56181 5.78647 6.20417 5.00348 7.88472 4.81251V2.29167C7.88472 1.64237 8.10472 1.09772 8.54472 0.657716C8.98472 0.217716 9.52861 -0.00151985 10.1764 7.92965e-06C10.8242 0.00153571 11.3688 0.221536 11.8103 0.660008C12.2519 1.09848 12.4711 1.64237 12.4681 2.29167V4.81251C13.9194 5.04167 15.1799 5.50994 16.2493 6.2173C17.3188 6.92466 18.1972 7.7932 18.8847 8.82292C19.2285 9.31945 19.2957 9.87327 19.0864 10.4844C18.8771 11.0955 18.447 11.5347 17.7962 11.8021C17.2615 12.0313 16.7076 12.0412 16.1347 11.8319C15.5618 11.6226 15.0271 11.2498 14.5306 10.7136C14.034 10.1773 13.4512 9.76709 12.782 9.48292C12.1128 9.19876 11.2825 9.05515 10.291 9.05209C8.61042 9.05209 7.3309 9.42487 6.45243 10.1704C5.57396 10.916 5.13472 11.8418 5.13472 12.9479C5.13472 14.2083 5.70764 15.2014 6.85347 15.9271C7.99931 16.6528 9.98542 17.4167 12.8118 18.2188C15.4472 18.9826 17.4433 20.1957 18.7999 21.8579C20.1566 23.5201 20.8342 25.439 20.8326 27.6146C20.8326 30.3264 20.0306 32.3889 18.4264 33.8021C16.8222 35.2153 14.8361 36.0938 12.4681 36.4375V38.9583C12.4681 39.6077 12.2481 40.1523 11.8081 40.5923C11.3681 41.0323 10.8242 41.2515 10.1764 41.25Z'
        fill={color}
      />
    </svg>
  );
}