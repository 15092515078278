import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputDescription,
  InputNumber,
  InputText,
  Select,
  TextEditor,
  Toast,
  ButtonGallery,
  IconButton,
  ModernSwitcher,
  InputMedia,
  FormButton,
  InputClock,
  Loading,
  InputCheckbox,
  Tabs,
  ErrorAlert,
  InputHashtags,
  InputTime,
  InputList,
} from '../../../uiComponents/common';
import './restaurant.css';
import GalleryForm from '../gallery/galleryForm';
import { WelletContext } from '../../../context/wellet/welletContext';
import {
  getTimeZones,
  getActivity,
  putActivity,
  postActivity,
  validateNameId,
} from '../../../services/activityServices';
import { useSelector } from 'react-redux';
import useUser from '../../../hooks/useUser';
import { useQuery, useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  FwCalendarIcon,
  FwEdit,
  FwClockIcon,
  FwGlobeIcon,
  FwPersonIcon,
  FwTrashIcon,
  CountryIcon,
  StateIcon,
  CityIcon,
  TimeZoneIcon,
  CloudIcon,
  FwLocationIcon,
  ZipCodeIcon,
} from '../../../uiComponents/icons';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import { getCities, getCountries, getSocialNetworks, getStates } from '../../../services';
import EditGalleryForm from '../gallery/editGalleryForm';
import FormRow from '../formRow';
import { useTranslation } from 'react-i18next';
import {
  formatTimeFromMinutes,
  formatTimeToMinutes,
  getContactList,
} from '../../../helpers/format';
import Weekdays from '../../../data/weekdays.json';
import InputListRepeat from '../../../uiComponents/common/inputs/newInputList';
import { useDebounce, usePatchActivityStatusMutation } from '../../../hooks';
// import TabPanel from '../../tabPanel/tabPanel';
// import useTabPanel from '../../../hooks/useTabPanel';
import { GalleryVideo } from '../gallery';
import VideoGallery from './videoGallery';
import Show from '../../show/show';
import TabPanel from '../../tabPanel/tabPanel';
import useGenericTranslation from '../../../hooks/useGenericTranslation';
import CopyClipboard from '../../../uiComponents/common/clipboard/clipboard';

const RestaurantForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'forms.restaurants' });
  const { id } = useParams();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const { getClientTranslation } = useGenericTranslation();
  const { isSuperAdmin } = useUser();
  const methods = useForm();
  const lastClickedButton = useRef(null);
  const [showError, setShowError] = useState('');
  const [currentId, setCurrentId] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState({
    state: true,
    city: true,
    category: true,
  });
  const [showToast, setShowToast] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isRedirect, setIsRedirect] = useState(null);
  const [openVideo, setOpenVideo] = useState(false);
  const location = useLocation();
  const tabTypes = {
    MAIN: 'MAIN',
    GENERAL: 'GENERAL',
    MULTIMEDIA: 'MULTIMEDIA',
    AREAS: 'AREAS',
    GROUPS: 'GROUPS',
    SOCIALMEDIA: 'SOCIALMEDIA',
  };
  const [activeTab, setActiveTab] = useState(tabTypes.MAIN);

  const tabs = [
    {
      title: t('mainInformation.title'),
      isActive: activeTab === tabTypes.MAIN,
      type: tabTypes.MAIN,
    },
    {
      title: t('generalInformation.title'),
      isActive: activeTab === tabTypes.GENERAL,
      type: tabTypes.GENERAL,
    },
    {
      title: 'Multimedia',
      isActive: activeTab === tabTypes.MULTIMEDIA,
      type: tabTypes.MULTIMEDIA,
    },
    {
      title: 'Areas VIP',
      isActive: activeTab === tabTypes.AREAS,
      type: tabTypes.AREAS,
    },
    {
      title: t('groups.title'),
      isActive: activeTab === tabTypes.GROUPS,
      type: tabTypes.GROUPS,
    },
    {
      title: t('socialMedia'),
      isActive: activeTab === tabTypes.SOCIALMEDIA,
      type: tabTypes.SOCIALMEDIA,
      //disabled: !!id ? false : true,
    },
  ];

  const validateFields = {
    name: {
      rule: (values) => {
        if (!values.name && !values.shortDescription) {
          return true;
        } else if (values.name && values.name.trim() !== '') {
          if (values.name.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Name" field is required and must have a minimum of 3 characters.',
    },
    shortDescription: {
      rule: (values) => {
        if (!values.name && !values.shortDescription) {
          return true;
        } else if (values.shortDescription && values.shortDescription.trim() !== '') {
          if (values.shortDescription.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg:
        'the "Short Description" field is required and must have a minimum of 3 characters.',
    },
  };

  const { languages, lngErrors } = useLanguageSwitcher(validateFields, methods);

  // Functions Api Query
  const timezoneQuery = useQuery({
    queryKey: ['timezones'],
    queryFn: () => getTimeZones(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const timeZoneFormated = timezoneQuery?.data?.map((obj) => ({
    label: `${obj.name} (UTC ${obj.shift})`,
    value: obj.id,
  }));

  const postActivityMutation = useMutation({
    mutationFn: (body) => {
      if (!!id) {
        return putActivity(welletContext, body, organization.id);
      }
      return postActivity(welletContext, organization.id, body);
    },
    onSuccess: (data) => {
      setCurrentId(data.data);
      setShowToast(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const countriesQuery = useQuery({
    queryKey: ['countries-edit', organization.id],
    queryFn: () => getCountries(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const statesQuery = useQuery({
    queryKey: ['states-edit', organization.id, methods?.watch('country')?.value],
    queryFn: () => getStates(welletContext, methods?.watch('country')?.value),
    onSuccess: () => {
      if (!isFirstRender.state) {
        methods.setValue('state', '');
        methods.setValue('city', '');
      }
      setIsFirstRender({
        city: isFirstRender.city,
        state: false,
        category: isFirstRender.category,
      });
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!methods?.watch('country')?.value,
  });
  const citiesQuery = useQuery({
    queryKey: ['cities-edit', organization.id, methods?.watch('state')?.value],
    queryFn: () => getCities(welletContext, methods?.watch('state')?.value),
    onSuccess: () => {
      if (!isFirstRender.city) {
        methods.setValue('city', '');
      }
      setIsFirstRender({
        state: isFirstRender.state,
        city: false,
        category: isFirstRender.category,
      });
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!methods?.watch('state')?.value,
  });

  const activityQuery = useQuery({
    queryKey: ['activity', organization.id, id],
    queryFn: () => getActivity(welletContext, id),
    onSuccess: (data) => methods.reset(formatActivityData(data)),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
    enabled: !!id,
  });

  const socailNetworkQuery = useQuery({
    queryKey: ['social'],
    queryFn: () => getSocialNetworks(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const socialNetworkFormatted = socailNetworkQuery?.data?.data?.map((ow) => ({
    label: ow,
    value: ow,
  }));


  const socialInitialState = methods.getValues('socialNetworks') ?? [];

  const debouncedNameId = useDebounce(methods.watch('nameId'), 500);

  const nameIdQuery = useQuery(
    ['validate-nameId', debouncedNameId, id],
    () => validateNameId(welletContext, debouncedNameId, id),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      staleTime: 300,
      enabled: !!debouncedNameId,
      retry: false,
    },
  );

  const { mutate } = usePatchActivityStatusMutation(id);

  const countries = countriesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const state = statesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const city = citiesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const urlSite = activityQuery?.data?.siteUrl;
  const urlBooking = `${activityQuery?.data?.bookingUrl}/`;

  const buildPolicies = (data) => {
    const policies = [];
    const reservationTypes = [
      { key: 'policy', type: 'AREA' },
      { key: 'policyGroup', type: 'GROUP' },
    ];

    reservationTypes.forEach(({ key, type }) => {
      const langPolicies = [];
      languages.forEach((lang) => {
        const description = data[`${key}_${lang?.shortForm}`];
        if (description) {
          langPolicies.push({
            language: lang.shortForm,
            description: description,
          });
        }
      });

      if (langPolicies.length > 0) {
        policies.push({
          defaultLanguage: langPolicies[0]?.language,
          reservationType: type,
          langPolicies: langPolicies,
        });
      }
    });

    return policies;
  };

  const formatHashtags = (hashtags) => {
    if (!Array.isArray(hashtags)) {
      return null;
    }
    return hashtags.map((hashtag) => `#${hashtag.value}`).join(' ');
  };
  const parseHashtags = (hashtagsString) => {
    if (typeof hashtagsString !== 'string') {
      return null;
    }

    return hashtagsString.split(' ').map((hashtag) => {
      const cleanedHashtag = hashtag.startsWith('#') ? hashtag.slice(1) : hashtag;
      return {
        label: cleanedHashtag,
        value: cleanedHashtag,
      };
    });
  };
  const onSubmit = async (data) => {
    if (!nameIdQuery?.data?.data) {
      return;
    }
    const initialLangShows = [{ shortForm: 'es' }, { shortForm: 'en' }].map((lng) => {
      return {
        language: lng.shortForm,
        name: data[`name_${lng.shortForm}`],
        description: data[`description_${lng.shortForm}`],
        shortDescription: data[`shortDescription_${lng.shortForm}`],
        showId: data.showId,
        supportWeekday: data[`supportWeekday_${lng.shortForm}`],
        suggestedText: data[`suggestedText_${lng.shortForm}`],
        hashTags: formatHashtags(data[`hashTags_${lng.shortForm}`]),
      };
    });
    const handelLangShowsEmptys = removeObjectsWithEmptyFields(initialLangShows, [
      'name',
      'description',
      'shortDescription',
      'suggestedText',
      'hashTags',
    ]);

    let socialNetworks = [];

    for (const key in data) {
      if (key.startsWith('nameSocialNetwork_')) {
        const socialNetworkName = key.substring('nameSocialNetwork_'.length);
        const socialNetworkValue = data[key];

        socialNetworks.push({
          socialNetwork: socialNetworkName,
          value: socialNetworkValue,
        });
      }
    }

    let showContacts = [];
    for (const key in data) {
      if (key.startsWith('nameContactInfo_')) {
        const organizationContact = key.substring('nameContactInfo_'.length);
        let value = data[key];

        const lastUnderscoreIndex = organizationContact.lastIndexOf('_');

        const type = organizationContact.substring(0, lastUnderscoreIndex);

        if (type === 'EMAIL' && data[key]?.com) {
          value = data[key].com;
        }

        showContacts.push({
          type: type,
          value: value,
        });
      }
    }

    if (Object.keys(lngErrors).length === 0) {
      const policies = buildPolicies(data);
      const body = {
        id: !!id ? data.id : 0,
        name: handelLangShowsEmptys.some(
          (s) => s.language === (data?.defaultLanguage?.value ?? 'es'),
        )
          ? handelLangShowsEmptys.find((s) => s.language === (data?.defaultLanguage?.value ?? 'es'))
              ?.name
          : handelLangShowsEmptys.find((l) => !!l.name).name,
        organizationId: organization.id,
        zoneTime: data.timezone?.value,
        priority: data.priority,
        imageUrl: data.image?.base64,
        isActive: !!id ? (data.isActive ? data.isActive : false) : true,
        // capacity: data.capacity,
        nameId: data.nameId,
        siteUrl: data.siteUrl,
        bookingUrl: data.bookingUrl,
        logoImageUrl: data.logo?.base64,
        mainAppImageUrl: data.mainApp?.base64,
        defaultLanguage: data?.defaultLanguage?.value ? data?.defaultLanguage?.value : 'es',
        purchaseTemplate: 'RESTAURANT',
        cityId: data.city.value,
        bannerImageUrl: data.banner?.base64,
        seatDistributionImgUrl: data?.seatDistributionImgUrl?.base64,
        videoUrl: data.video,
        showImages: images,
        langShows: handelLangShowsEmptys,
        showLimitTimeConfigs: Weekdays.map((w) => ({
          weekday: w.label,
          untilTimeInMinutes:
            data[`limit_${w.label.toLowerCase()}`] &&
            typeof data[`limit_${w.label.toLowerCase()}`] === 'string'
              ? formatTimeToMinutes(data[`limit_${w.label.toLowerCase()}`])
              : null,
        })),
        showAnticipationConfigs: [
          {
            reservationType: 'AREA',
            timeInMinutes: formatTimeToMinutes(data.areaAnticipation ? data.areaAnticipation : 0),
          },
          {
            reservationType: 'GROUP',
            timeInMinutes: formatTimeToMinutes(data.groupAnticipation ? data.groupAnticipation : 0),
          },
          {
            reservationType: 'RESTAURANT',
            timeInMinutes: formatTimeToMinutes(
              data.restaurantAnticipation ? data.restaurantAnticipation : 0,
            ),
          },
        ],
        showContacts,
        hasControlStock: data.hasControlStock || false,
        policies: policies,
        latitude: data?.lat ? data?.lat : null,
        longitude: data?.lng ? data.lng : null,
        address: data?.address,
        postalCode: data?.postalCode,
        socialNetworks: socialNetworks,
      };
      if (isSuperAdmin) {
        body['utcOffset'] = data.timeZoneChange ? data.timeZoneChange : null;
        body['calendarAdjustment'] = data.calendarAdjustment ? data.calendarAdjustment : null;
      }
      if (!!id) {
        body.minutesForNoShow = formatTimeToMinutes(
          data?.minutesForNoShow ? data?.minutesForNoShow : 0,
        );
      }

      postActivityMutation.mutate(body);
    }
  };
  const formatActivityData = (data) => {
    if (Number(organization.id) !== data?.organizationId) {
      navigate('/restaurantes');
    }
    const formattedData = {
      id: data.id,
      timezone: {
        label: timeZoneFormated?.find((t) => t.value === data?.zoneTime)?.label,
        value: data?.zoneTime,
      },
      defaultLanguage: {
        value: data?.defaultLanguage ? data?.defaultLanguage : 'es',
        label: languages?.find((l) => l.shortForm === data?.defaultLanguage)?.title
          ? languages?.find((l) => l.shortForm === data?.defaultLanguage).title
          : 'Español',
      },
      // capacity: String(data?.capacity),
      priority: String(data?.priority),
      nameId: data?.nameId,
      siteUrl: data?.siteUrl,
      bookingUrl: data.bookingUrl,
      purchaseTemplate: {
        value: data?.purchaseTemplate,
        label: data?.purchaseTemplate,
      },
      image: { base64: data?.imageUrl, name: data?.imageUrl?.split('/')[3] ?? '' },
      banner: { base64: data?.bannerImageUrl, name: data?.bannerImageUrl?.split('/')[3] ?? '' },
      mainApp: {
        base64: data?.mainAppImageUrl,
        name: data?.mainAppImageUrl?.split('/')[3] ?? '',
      },
      seatDistributionImgUrl: data?.seatDistributionImgUrl
        ? {
            base64: data?.seatDistributionImgUrl,
            name: data?.seatDistributionImgUrl?.split('/')[3] ?? '',
          }
        : null,
      logo: { base64: data?.logoImageUrl, name: data?.logoImageUrl?.split('/')[3] ?? '' },
      video: data?.videoUrl || '',
      minutesForNoShow: data?.minutesForNoShow
        ? formatTimeFromMinutes(data.minutesForNoShow)
        : formatTimeToMinutes(0),
      isActive: data?.isActive,
      calendarAdjustment: data?.calendarAdjustment,
      city: { label: data?.city?.name, value: data?.city?.id },
      state: { label: data?.city?.state?.name, value: data?.city?.state?.id },
      country: { label: data?.city?.state?.country?.name, value: data?.city?.state?.country?.id },
      timeZoneChange: data?.utcOffset,
      socialNetworks: data?.socialNetworks,
      showContacts: data?.showContacts
        ? data?.showContacts.map((t) => ({ label: t.type, type: t.type, value: t.value }))
        : [],
      hasControlStock: data.hasControlStock || false,
      videos: data.showVideos,
      // shortName: data?.shortName,
      lat: data?.latitude,
      lng: data?.longitude,
      address: data?.address,
      postalCode: data?.postalCode,
    };
    if (data.showLimitTimeConfigs) {
      data.showLimitTimeConfigs.forEach((s) => {
        formattedData[`limit_${s.weekday.toLowerCase()}`] =
          s.untilTimeInMinutes !== null ? formatTimeFromMinutes(s.untilTimeInMinutes) : '';
      });
    }
    if (data.showAnticipationConfigs) {
      data.showAnticipationConfigs.forEach((s) => {
        formattedData[`${s.reservationType.toLowerCase()}Anticipation`] = formatTimeFromMinutes(
          s.timeInMinutes,
        );
      });
    }
    data.langShows.forEach((langShow) => {
      const lang = langShow.language;
      formattedData[`name_${lang}`] = langShow.name;
      formattedData[`shortDescription_${lang}`] = langShow.shortDescription;
      formattedData[`description_${lang}`] = langShow.description;
      formattedData[`showId`] = langShow.showId;
      formattedData[`supportWeekday_${lang}`] = langShow.supportWeekday;
      formattedData[`suggestedText_${lang}`] = langShow.suggestedText;
      formattedData[`hashTags_${lang}`] = parseHashtags(langShow.hashTags);
    });

    if (data?.policies) {
      data?.policies?.forEach((policy) => {
        policy?.langPolicies?.forEach((langPolicy) => {
          const lang = langPolicy.language;
          if (policy.reservationType === 'AREA') {
            formattedData[`policy_${lang}`] = langPolicy.description;
          } else if (policy.reservationType === 'GROUP') {
            formattedData[`policyGroup_${lang}`] = langPolicy.description;
          }
        });
      });
    }
    setImages(data.showImages);
    return formattedData;
  };

  const handleRemoveImg = (idx) => {
    const imagesCopy = images.map((image, index) => {
      if (index === idx) {
        return { ...image, isActive: false };
      }
      return image;
    });
    setImages(imagesCopy);
  };
  const handleEditImg = (i, idx) => {
    setSelectedImage({
      data: i,
      id: idx,
    });
    setEditOpen(true);
  };
  const clearForm = () => {
    navigate(`/restaurantes`);
  };
  const handleToastCallback = () => {
    if (isRedirect) {
      setShowToast(false);
      if (!!id) {
        navigate(`/restaurantes`);
      } else {
        navigate(`/restaurantes/schedule/${currentId}`, { replace: true });
      }
    } else {
      setShowToast(false);
    }
    postActivityMutation.reset();
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };
  const handleTabs = (type) => {
    setActiveTab(type);
  };

  return (
    <>
      {showToast && (
        <Toast
          message={
            !!id
              ? t('toast.success.edit', { title: getClientTranslation('title.singular') })
              : t('toast.success.new', { title: getClientTranslation('title.singular') })
          }
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {showError && <Toast message={showError} type='ERROR' onCallback={() => setShowError('')} />}

      <Tabs options={tabs} onClick={handleTabs} />

      <FormProvider {...methods}>
        <form
          key={1}
          onSubmit={methods.handleSubmit(onSubmit, () => setShowError(t('error.errorForm')))}
        >
          {activityQuery.isLoading ? (
            <Loading />
          ) : (
            <>
              <TabPanel isActive={activeTab === 'MAIN'}>
                <FormRow
                  text={t('mainInformation.rowName.title')}
                  endComponent={
                    <div className='col-6 row pr-0'>
                      <div className='col-12 pr-0 mb-2 d-flex'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <InputText
                          name={'name_es'}
                          label={''}
                          className={'mb-0 w-100'}
                          placeholder={t('mainInformation.rowName.inputName.placeholder')}
                          isRequired={true}
                          maxLength={510}
                        />
                      </div>
                      <div className='col-12 pr-0 d-flex'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <InputText
                          name={'name_en'}
                          label={''}
                          className={'mb-0 w-100'}
                          placeholder={t('mainInformation.rowName.inputName.placeholder')}
                          isRequired={true}
                          maxLength={510}
                        />
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('mainInformation.rowShortDescription.title')}
                  endComponent={
                    <div className='col-6 row pr-0'>
                      <div className='col-12 pr-0 mb-2 d-flex'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <InputDescription
                          name={'shortDescription_es'}
                          className='w-100'
                          placeholder={t(
                            'mainInformation.rowShortDescription.inputShortDescription.placeholder',
                          )}
                          isRequired={true}
                        />
                      </div>
                      <div className='col-12 pr-0 d-flex'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <InputDescription
                          name={'shortDescription_en'}
                          className='w-100'
                          placeholder={t(
                            'mainInformation.rowShortDescription.inputShortDescription.placeholder',
                          )}
                          isRequired={true}
                        />
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('mainInformation.rowNameId.title')}
                  endComponent={
                    <div className='col-6 row pr-0'>
                      <div className='col-12 pr-0 mb-2 d-flex'>
                        <InputText
                          name={'nameId'}
                          label={''}
                          className={'mb-0 w-100'}
                          placeholder={t('mainInformation.rowNameId.placeholder')}
                          isRequired={true}
                          minLength={1}
                          maxLength={510}
                        />
                      </div>
                      <div className='col-12'>
                        {!nameIdQuery?.data?.data && nameIdQuery?.isSuccess ? (
                          <div className='mt-2 d-flex align-items-center'>
                            <ErrorAlert
                              text={`${debouncedNameId} ${t('mainInformation.rowNameId.error')}`}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  }
                />
                {!!id ? (
                  <FormRow
                    text={t('mainInformation.urlDomain.title')}
                    endComponent={
                      <div className='col-6 row pr-0'>
                        <div className='col-12 pr-0 mb-2 d-flex'>
                          <InputText
                            name={'siteUrl'}
                            className={'mb-0 w-100'}
                            placeholder={t('mainInformation.urlDomain.placeholder')}
                            isRequired={true}
                            minLength={1}
                            maxLength={510}
                            border={true}
                            icon={<span style={{ fontSize: '14px' }}>{urlBooking}</span>}
                            copy={
                              <span style={{ fontSize: '16px' }}>
                                <CopyClipboard text={`${urlBooking}${urlSite}`} showCopy={true} />
                              </span>
                            }
                          />
                        </div>
                      </div>
                    }
                  />
                ) : null}
                <FormRow
                  text={t('mainInformation.rowDescription.title')}
                  endComponent={
                    <div className='col-6 pr-0 row'>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <TextEditor
                          className='w-100'
                          options={['inline', 'link', 'list', 'remove']}
                          name={`description_es`}
                          customErrors={lngErrors}
                        />
                      </div>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <TextEditor
                          className='w-100'
                          options={['inline', 'link', 'list', 'remove']}
                          name={`description_en`}
                          customErrors={lngErrors}
                        />
                      </div>
                    </div>
                  }
                />

                <FormRow
                  text={t('mainInformation.rowSupportPhone.title')}
                  endComponent={
                    <div className='col-6 pr-0 w-100'>
                      <InputListRepeat
                        {...methods}
                        className={'col-12'}
                        data={getContactList(t)}
                        initialState={methods.getValues('showContacts') ?? []}
                        nameSelect={'currentContactInfo'}
                        nameInput={'currentContactInfoName'}
                        baseNameInput={'nameContactInfo_'}
                        labelSelect={t('mainInformation.rowSupportPhone.labelSelect')}
                        placeholder={t('mainInformation.rowSupportPhone.placeholder')}
                        placeholderSelect={t('mainInformation.rowSupportPhone.labelSelect')}
                      />
                    </div>
                  }
                  withBorder={false}
                />
                <FormRow
                  text={t('mainInformation.rowSupportWeekday.title')}
                  endComponent={
                    <div className='col-6 pr-0 row'>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <TextEditor
                          options={['inline', 'link', 'list', 'remove']}
                          className='w-100'
                          name={`supportWeekday_es`}
                          customErrors={lngErrors}
                        />
                      </div>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <TextEditor
                          options={['inline', 'link', 'list', 'remove']}
                          className='w-100'
                          name={`supportWeekday_en`}
                          customErrors={lngErrors}
                        />
                      </div>
                    </div>
                  }
                />
                {!!id ? (
                  <FormRow
                    text={t('generalInformation.rowPublish.title')}
                    endComponent={
                      <div className='col'>
                        <InputCheckbox
                          name={'isActive'}
                          label={t('generalInformation.rowPublish.label')}
                          onClick={(status) => mutate(status)}
                        />
                      </div>
                    }
                  />
                ) : null}
              </TabPanel>
              <TabPanel isActive={activeTab === tabTypes.GENERAL}>
                <FormRow
                  text={t('generalInformation.rowLocation.title')}
                  endComponent={
                    <div className='col-6 row pr-0'>
                      <Select
                        options={countries}
                        label={t('generalInformation.rowLocation.inputCountry.label')}
                        placeholder={t('generalInformation.rowLocation.inputCountry.placeholder')}
                        className={'mb-2 col-6 pr-0'}
                        icon={<CountryIcon />}
                        isRequired={true}
                        name={'country'}
                      />
                      <Select
                        options={state}
                        label={t('generalInformation.rowLocation.inputState.label')}
                        placeholder={t('generalInformation.rowLocation.inputState.placeholder')}
                        className={'mb-2 col-6 pr-0'}
                        icon={<StateIcon />}
                        name={'state'}
                        isRequired={true}
                        isDisabled={!!!methods.watch('country')?.label}
                      />
                      <Select
                        options={city}
                        label={t('generalInformation.rowLocation.inputCity.label')}
                        placeholder={t('generalInformation.rowLocation.inputCity.placeholder')}
                        isDisabled={!!!methods.watch('state')?.label}
                        className={'mb-2 col-6 pr-0'}
                        icon={<CityIcon />}
                        name={'city'}
                        isRequired={true}
                      />
                    </div>
                  }
                />
                <FormRow
                  text={t('generalInformation.rowAddress.title')}
                  endComponent={
                    <InputText
                      className={'col-6'}
                      icon={<FwLocationIcon />}
                      name={'address'}
                      placeholder={t('generalInformation.rowAddress.placeholder')}
                      isRequired={true}
                    />
                  }
                />
                <FormRow
                  text={t('generalInformation.rowZip.title')}
                  endComponent={
                    <InputText
                      className={'col-6'}
                      icon={<ZipCodeIcon />}
                      name={'postalCode'}
                      placeholder={t('generalInformation.rowZip.placeholder')}
                      isRequired={true}
                    />
                  }
                />
                <FormRow
                  text={t('generalInformation.rowLatitude.title')}
                  endComponent={
                    <div className='col-6 row'>
                      <InputNumber
                        name={'lat'}
                        className={'col-6'}
                        icon={<FwGlobeIcon />}
                        label={t('generalInformation.rowLatitude.latitude.title')}
                        placeholder={t('generalInformation.rowLatitude.latitude.placeholder')}
                        isRequired={false}
                        acceptNegative={false}
                        maxNumber={90}
                        minNumber={-90}
                      />
                      <InputNumber
                        name={'lng'}
                        className={'col-6'}
                        icon={<FwGlobeIcon />}
                        label={t('generalInformation.rowLatitude.longitude.title')}
                        placeholder={t('generalInformation.rowLatitude.longitude.placeholder')}
                        isRequired={false}
                        acceptNegative={false}
                        maxNumber={180}
                        minNumber={-180}
                      />
                    </div>
                  }
                />
                <FormRow
                  text={t('generalInformation.rowTimeZone.title')}
                  endComponent={
                    <Select
                      options={timeZoneFormated}
                      label={''}
                      placeholder={t('generalInformation.rowTimeZone.inputTimeZone.placeholder')}
                      className={'col-6'}
                      icon={<TimeZoneIcon />}
                      name={'timezone'}
                      isRequired={true}
                    />
                  }
                />
                {/* <FormRow
                  text={t('generalInformation.rowCapacity.title')}
                  endComponent={
                    <InputNumber
                      label={''}
                      name={'capacity'}
                      className={'col-6'}
                      icon={<FwPersonIcon />}
                      placeholder={t('generalInformation.rowCapacity.inputCapacity.placeholder')}
                      isRequired={true}
                    />
                  }
                /> */}

                {isSuperAdmin && (
                  <FormRow
                    text={t('generalInformation.rowAdjustment.title')}
                    endComponent={
                      <div className='col-6 row'>
                        <InputNumber
                          name={'calendarAdjustment'}
                          label={t(
                            'generalInformation.rowAdjustment.inputAdjustmentCalendar.label',
                          )}
                          placeholder={t(
                            'generalInformation.rowAdjustment.inputAdjustmentCalendar.placeholder',
                          )}
                          className={'col-6'}
                          customValidation={{}}
                          icon={<FwCalendarIcon />}
                        />
                        <InputNumber
                          label={t('generalInformation.rowAdjustment.inputHandleTimeZone.label')}
                          placeholder={t(
                            'generalInformation.rowAdjustment.inputHandleTimeZone.placeholder',
                          )}
                          className={'col-6'}
                          name={'timeZoneChange'}
                          icon={<FwClockIcon />}
                          customValidation={{}}
                        />
                      </div>
                    }
                  />
                )}

                <FormRow
                  text={
                    <div className='mb-2'>
                      <ModernSwitcher
                        text={t('generalInformation.rowStock.title')}
                        name={'hasControlStock'}
                      />
                    </div>
                  }
                />
                {
                  <FormRow
                    text={t('timeAnticipation')}
                    endComponent={
                      <div className='col-6'>
                        <InputTime
                          className={'mb-2'}
                          name={'restaurantAnticipation'}
                          required={false}
                        />
                      </div>
                    }
                  />
                }
                {!!id ? (
                  <FormRow
                    text={t('generalInformation.expirationTimeShow.title')}
                    endComponent={
                      <div className='col-6'>
                        <InputTime
                          label={t('generalInformation.expirationTimeShow.label')}
                          tooltip={t('generalInformation.expirationTimeShow.tooltip')}
                          className={'mb-2'}
                          name={'minutesForNoShow'}
                          required={false}
                        />
                      </div>
                    }
                  />
                ) : null}
              </TabPanel>
              <TabPanel isActive={activeTab === tabTypes.MULTIMEDIA}>
                <FormRow
                  text={t('generalInformation.rowMultimedia.title')}
                  tooltip={t('generalInformation.rowMultimedia.tooltip', {
                    title: getClientTranslation('title.singular').toLocaleLowerCase(),
                  })}
                  endComponent={
                    <div className='row col-6'>
                      {/* <InputMedia
                        id={'mainImage'}
                        name={'image'}
                        className='col-12 col-sm-6 col-xl-4 my-1'
                        label={t('generalInformation.rowMultimedia.mainImage')}
                        isRequired={true}
                        maxSize={409600}
                        titleModal='Imagen'
                        tooltip={'texto'}
                      /> */}

                      {/* <InputMedia
                        id={'bannerImage'}
                        name={'banner'}
                        className='col-12 col-sm-6 col-xl-4 my-1'
                        label={t('generalInformation.rowMultimedia.banner')}
                        isRequired={true}
                        maxSize={409600}
                        titleModal='Banner'
                        tooltip={'texto'}
                      /> */}

                      <InputMedia
                        id={'logoFile'}
                        name={'logo'}
                        className='col-12 col-sm-6 col-xl-4 my-1'
                        //  label={t('generalInformation.rowMultimedia.logo')}
                        isRequired={true}
                        maxSize={409600}
                      />

                      {/* <InputMedia
                        id={'mainAppFile'}
                        name={'mainApp'}
                        className='col-12 col-sm-6 col-xl-4 my-1'
                        label={t('generalInformation.rowMultimedia.appImage')}
                        isRequired={true}
                        maxSize={409600}
                        titleModal='Imagen App'
                        tooltip={'texto'}
                      /> */}
                    </div>
                  }
                />
                <FormRow
                  text={t('generalInformation.rowGallery.title')}
                  endComponent={
                    <div className='col-6 row'>
                      <div className='col row  justify-content-center justify-content-sm-start'>
                        <div className='col-auto m-1'>
                          <ButtonGallery
                            height='150px'
                            width='auto'
                            icon={<CloudIcon size='20px' color='var(--color-text-light)' />}
                            text={
                              <div>
                                <span style={{ color: '#6941C6' }} className='fw-600 '>
                                  {t(
                                    'generalInformation.rowGallery.inputVideo.placeholder.clickOrDrag',
                                  )}
                                </span>{' '}
                                {t(
                                  'generalInformation.rowGallery.inputVideo.placeholder.toUploadFile',
                                )}
                              </div>
                            }
                            msg={'GIF, PNG, JPG, JPEG o BMP'}
                            onClick={() => {
                              setOpen(true);
                              setSelectedImage(null);
                            }}
                          />
                        </div>
                        {images.length > 0
                          ? images.map((i, idx) =>
                              i?.isActive ? (
                                <div className='col-auto p-0 m-1 gallery-item' key={i.src}>
                                  <div className='card-gallery card-gallery-size'>
                                    <div className='card-img-actions p-1'>
                                      <div className='image-container' style={{ height: '140px' }}>
                                        <img
                                          src={i.imageUrl}
                                          className='card-img img-fluid'
                                          alt='galeria'
                                        />
                                      </div>
                                      <h6 className='m-0 mt-2 font-weight-bold text-truncate'>
                                        {i.langShowImage[0]?.title}
                                      </h6>
                                    </div>
                                    <div className='image-overlay d-flex justify-content-end'>
                                      <IconButton
                                        onClick={() => handleEditImg(i, idx)}
                                        classNameIcon={'hoverIconConatiner'}
                                        icon={
                                          <FwEdit
                                            color='var(--hover)'
                                            size={20}
                                            classNameIcon={'hoverIcon'}
                                          />
                                        }
                                      />
                                      <IconButton
                                        onClick={() => handleRemoveImg(idx)}
                                        classNameIcon={'hoverIconConatiner'}
                                        icon={
                                          <FwTrashIcon
                                            color='var(--hover)'
                                            size={20}
                                            classNameIcon={'hoverIcon'}
                                          />
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null,
                            )
                          : null}
                      </div>
                    </div>
                  }
                />
              </TabPanel>
              <TabPanel isActive={activeTab === tabTypes.AREAS}>
                <FormRow
                  text={t('mainInformation.rowTerms&Condition.title')}
                  endComponent={
                    <div className='col-6 pr-0 row'>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <TextEditor
                          options={['inline', 'link', 'list', 'remove']}
                          className='w-100'
                          name={`policy_es`}
                          customErrors={lngErrors}
                        />
                      </div>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <TextEditor
                          options={['inline', 'link', 'list', 'remove']}
                          className='w-100'
                          name={`policy_en`}
                          customErrors={lngErrors}
                        />
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('generalInformation.rowAreaVip.title')}
                  endComponent={
                    <div className='row col-6'>
                      <InputMedia
                        id={'areaVipImage'}
                        name={'seatDistributionImgUrl'}
                        className='col-12 col-sm-6 col-xl-4 my-1'
                        label={t('generalInformation.rowAreaVip.label')}
                        isRequired={false}
                        maxSize={2097152}
                        titleModal={t('generalInformation.rowAreaVip.label')}
                      />
                    </div>
                  }
                />
                <FormRow
                  text={t('generalInformation.rowLimit.title')}
                  endComponent={
                    <div className='col-6'>
                      <InputClock
                        useCustomMaxTime
                        customMaxTime='23:59'
                        format='HH:mm'
                        className={'mb-1'}
                        label={t('weekdays.monday')}
                        name={'limit_monday'}
                        required={false}
                      />
                      <InputClock
                        useCustomMaxTime
                        customMaxTime='23:59'
                        format='HH:mm'
                        className={'mb-1'}
                        label={t('weekdays.tuesday')}
                        name={'limit_tuesday'}
                        required={false}
                      />
                      <InputClock
                        useCustomMaxTime
                        customMaxTime='23:59'
                        format='HH:mm'
                        className={'mb-1'}
                        label={t('weekdays.wednesday')}
                        name={'limit_wednesday'}
                        required={false}
                      />
                      <InputClock
                        useCustomMaxTime
                        customMaxTime='23:59'
                        format='HH:mm'
                        className={'mb-1'}
                        label={t('weekdays.thursday')}
                        name={'limit_thursday'}
                        required={false}
                      />
                      <InputClock
                        useCustomMaxTime
                        customMaxTime='23:59'
                        format='HH:mm'
                        className={'mb-1'}
                        label={t('weekdays.friday')}
                        name={'limit_friday'}
                        required={false}
                      />
                      <InputClock
                        useCustomMaxTime
                        customMaxTime='23:59'
                        format='HH:mm'
                        className={'mb-1'}
                        label={t('weekdays.saturday')}
                        name={'limit_saturday'}
                        required={false}
                      />
                      <InputClock
                        useCustomMaxTime
                        customMaxTime='23:59'
                        format='HH:mm'
                        className={'mb-1'}
                        label={t('weekdays.sunday')}
                        name={'limit_sunday'}
                        required={false}
                      />
                    </div>
                  }
                />
                <FormRow
                  text={t('timeAnticipation')}
                  endComponent={
                    <div className='col-6'>
                      <InputTime className={'mb-2'} name={'areaAnticipation'} required={false} />
                    </div>
                  }
                />
              </TabPanel>
              <TabPanel isActive={activeTab === tabTypes.GROUPS}>
                <FormRow
                  text={t('mainInformation.rowTerms&Condition.title')}
                  endComponent={
                    <div className='col-6 pr-0 row'>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <TextEditor
                          options={['inline', 'link', 'list', 'remove']}
                          className='w-100'
                          name={`policyGroup_es`}
                          customErrors={lngErrors}
                        />
                      </div>
                      <div className='col-12 d-flex pr-0'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <TextEditor
                          options={['inline', 'link', 'list', 'remove']}
                          className='w-100'
                          name={`policyGroup_en`}
                          customErrors={lngErrors}
                        />
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('timeAnticipation')}
                  endComponent={
                    <div className='col-6'>
                      <InputTime className={'mb-2'} name={'groupAnticipation'} required={false} />
                    </div>
                  }
                />
              </TabPanel>
              <TabPanel isActive={activeTab === 'SOCIALMEDIA'}>
                <FormRow
                  text={t('generalInformation.rowSuggestedText.title')}
                  endComponent={
                    <div className='col-6 row pr-0'>
                      <div className='col-12 pr-0 mb-2 d-flex'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <InputText
                          name={'suggestedText_es'}
                          label={''}
                          className={'mb-0 w-100'}
                          placeholder={t(
                            'generalInformation.rowSuggestedText.inputSuggested.placeholder',
                          )}
                        />
                      </div>
                      <div className='col-12 pr-0 d-flex'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <InputText
                          name={'suggestedText_en'}
                          label={''}
                          className={'mb-0 w-100'}
                          placeholder={t(
                            'generalInformation.rowSuggestedText.inputSuggested.placeholder',
                          )}
                        />
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('generalInformation.rowSocialMedia.title')}
                  endComponent={
                    <div className='col-6 row pr-0'>
                      <div className='col-12 pr-0 d-flex align-items-center'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <InputList
                          className={'col-xl-12 col-12'}
                          {...methods}
                          data={socialNetworkFormatted}
                          initalStae={socialInitialState}
                          nameSelect={'currentSocialNetwork'}
                          nameInput={'currentSocialNetworkName'}
                          baseNameInput={'nameSocialNetwork_'}
                          placeholder={t('generalInformation.rowSocialMedia.inputSuggested.placeholder')}
                          placeholderSelect={t('generalInformation.rowSocialMedia.inputSuggested.placeholderSelect')}
                        />
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('generalInformation.rowHashtags.title')}
                  endComponent={
                    <div className='col-6 row pr-0'>
                      <div className='col-12 pr-0 d-flex align-items-center'>
                        <div
                          className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                        ></div>
                        <InputHashtags
                          name='hashTags_es'
                          className={'w-100'}
                          placeholder={t(
                            'generalInformation.rowHashtags.inputHashtags.placeholder',
                          )}
                          isRequired={false}
                        />
                      </div>
                      <div className='col-12 pr-0 d-flex align-items-center'>
                        <div
                          className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                        ></div>
                        <InputHashtags
                          name='hashTags_en'
                          className={'w-100'}
                          placeholder={t(
                            'generalInformation.rowHashtags.inputHashtags.placeholder',
                          )}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  }
                />
                <Show.When isTrue={!!id}>
                  <FormRow
                    text={t('generalInformation.rowGallery.inputVideo.label')}
                    endComponent={
                      <div className='col-6 row'>
                        <div className='col row  justify-content-center justify-content-sm-start'>
                          <div className='col-auto m-1'>
                            <ButtonGallery
                              height='150px'
                              width='auto'
                              icon={<CloudIcon size='20px' color='var(--color-text-light)' />}
                              text={
                                <div>
                                  <span style={{ color: '#6941C6' }} className='fw-600 '>
                                    {t(
                                      'generalInformation.rowGallery.inputVideo.placeholder.clickOrDrag',
                                    )}
                                  </span>{' '}
                                  {t(
                                    'generalInformation.rowGallery.inputVideo.placeholder.toUploadFile',
                                  )}
                                </div>
                              }
                              msg={'MP4'}
                              onClick={() => {
                                setOpenVideo(true);
                              }}
                            />
                          </div>
                          <VideoGallery
                            refetchQuery={activityQuery.refetch}
                            videos={methods.getValues('videos') ? methods.getValues('videos') : []}
                          />
                        </div>
                      </div>
                    }
                  />
                </Show.When>
              </TabPanel>

              <div className='d-flex justify-content-end mt-2'>
                <FormButton
                  mutation={postActivityMutation}
                  reff={lastClickedButton}
                  handleRedirect={handleRedirect}
                  clearForm={clearForm}
                  all={false}
                  isDisabled={postActivityMutation.isLoading || postActivityMutation.isSuccess}
                  nextStep={true}
                />
              </div>
            </>
          )}
        </form>
      </FormProvider>
      <EditGalleryForm
        open={editOpen}
        setOpen={setEditOpen}
        images={images}
        setImages={setImages}
        editForm={selectedImage}
        defaultLanguage={activityQuery.data?.defaultLanguage}
      />
      <GalleryVideo
        open={openVideo}
        setOpen={setOpenVideo}
        showdId={id}
        refetchQuery={activityQuery.refetch}
      />
      <GalleryForm
        open={open}
        setOpen={setOpen}
        images={images}
        setImages={setImages}
        editForm={selectedImage}
      />
      {postActivityMutation?.error &&
        (postActivityMutation?.error?.response?.data?.message === 'INVALID_IMAGE_FORMAT' ? (
          <Toast
            message={t('toast.error.msgImage')}
            type='ERROR'
            onCallback={() => postActivityMutation.reset()}
          />
        ) : (
          <Toast
            message={t('toast.error.msg')}
            type='ERROR'
            onCallback={() => postActivityMutation.reset()}
          />
        ))}
    </>
  );
};

export default RestaurantForm;
