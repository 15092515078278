import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { SignInHeader } from '../../components';
import { Input, Button, Card, CardBody, Loading, Toast } from '../../uiComponents/common';
import WarningIcon from '../../uiComponents/icons/warningIcon';
import { WelletContext } from '../../context/wellet/welletContext';
import useUser from '../../hooks/useUser';
import { useMutation } from 'react-query';
import { changePassword } from '../../services';
import { useSelector } from 'react-redux';
import TitleTab from '../../components/titleTab/titleTab';

const ChangePassword = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const welletContext = useContext(WelletContext);
  const { jwt } = useUser();
  const { logoURL } = useSelector((state) => state.app);

  const changePasswordMutation = useMutation({
    mutationFn: () => changePassword(welletContext, values),
    onError: (error) => {
      setError(error.response.data);
    },
  });

  const sendForm = async () => {
    setError('');

    if (jwt) {
      changePasswordMutation.mutate();
    } else {
      setError('Por favor, inicia sesión para cambiar tu contraseña.');
    }
  };

  const validate = () => {
    setError('');
    let errors = {};

    if (!values.new_password) {
      errors.new_password = 'Se requiere una nueva contraseña.';
    }
    if (!values.confirm_password) {
      errors.confirm_password = 'Se requiere confirmar la contraseña.';
    }

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  if (changePasswordMutation.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <TitleTab />
      <div className='d-flex flex-column justify-content-beetween align-items-strech vh-100 w-100'>
        <SignInHeader />
        {changePasswordMutation.isSuccess && (
          <Toast
            message='Contraseña cambiada exitosamente.'
            type='SUCCESS'
            onCallback={() => navigate(`/`, { replace: true })}
          />
        )}
        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
          <form onSubmit={handleSubmit} className='login-form'>
            <Card>
              <div className='row py-3 justify-content-center'>
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{
                    height: '100px',
                    width: '100px',
                    borderRadius: '50%',
                    backgroundImage: `url(${logoURL})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                  }}
                ></div>
              </div>
              <div className='row'>
                <h5 className='col-12 text-center' style={{ color: 'var(--color-subtitle)' }}>
                  Cambiar tu constraseña
                </h5>
              </div>
              <CardBody>
                <Input
                  placeholder={'Nueva contraseña'}
                  name={'new_password'}
                  type={'password'}
                  value={values.new_password}
                  error={errors.new_password}
                  onChangeValue={handleChange}
                />
                <Input
                  placeholder={'Confirmar nueva contraseña'}
                  name={'confirm_password'}
                  type={'password'}
                  value={values.confirm_password}
                  error={errors.confirm_password}
                  onChangeValue={handleChange}
                />
                {error ? (
                  <div className='mt-2 d-flex align-items-center'>
                    <div className='mr-1'>
                      <WarningIcon color={'#CD3D64'} />
                    </div>
                    <div className='error-text'>{error}</div>
                  </div>
                ) : (
                  <></>
                )}
                <Button
                  type='submit'
                  size={'small'}
                  className={'mt-2 btn-custom-black'}
                  width={'100%'}
                  text={'Cambiar contraseña'}
                />
                <div className='py-2 mt-2 content-divider text-muted'>
                  <span className='px-3'></span>
                </div>
                <div className='row'>
                  <div className='col'>
                    <Link className='custom-link' to={'/'}>
                      Volver a Inicio
                    </Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
