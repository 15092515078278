import React from 'react'
import { ProductMode } from '../../components'

const Products = () => {
  return (
    <div>
      <ProductMode mode="areas" />
    </div>
  )
}

export default Products