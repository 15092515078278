import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { getMediaRestaurantById } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import Show from '../show/show';
import { Loading } from '../../uiComponents/common';
import styles from './mediaSelector.module.css';
import { useTranslation } from 'react-i18next';
import { MediaFileIcon } from '../../uiComponents/icons';

const MediaSelector = ({ restaurantId = null, currentMedia, setCurrentMedia, show, error }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.mediaSelector' });
  const welletContext = useContext(WelletContext);

  const [medias, setMediaFiles] = useState(null);
  const [preview, setPreview] = useState(null);

  const isLaptop = window.innerWidth < 1400;

  // Main API querys
  const mediaRestaurantQuery = useQuery({
    queryKey: ['media-restaurant', restaurantId],
    queryFn: () => getMediaRestaurantById(welletContext, restaurantId),
    onSuccess: (data) => {
      const videos = data?.data?.showVideos.map((video) => ({
        id: video.id,
        showId: video.showId,
        type: 'VIDEO',
        url: video.videoUrl,
        priority: video.priority,
        langData: video.langShowVideos,
        isSelected: false,
      }));

      const images = data?.data?.showImages.map((image) => ({
        id: image.id,
        showId: image.showId,
        type: 'PHOTO',
        url: image.imageUrl,
        priority: image.priority,
        isActive: image.isActive,
        langData: image.langShowImage,
        isSelected: false,
      }));

      if (videos.length === 0 && images.length === 0) {
        setMediaFiles(null);
        setPreview(null);
        return;
      }
      let currentArray = [...videos, ...images];
      currentArray = currentArray.map((item) => {
        if (item?.id === currentMedia?.id) {
          setPreview({ ...item, isSelected: true });
          return { ...item, isSelected: true };
        }
        return item;
      });

      setMediaFiles(currentArray);
    },
    ...defaultQueryOptions,
    enabled: show && restaurantId !== null,
    refetchOnMount: true,
  });

  // Functions
  const selectPreview = (media) => {
    setPreview(media);
  };
  const selectMedia = (mediaSelected) => {
    const updatedMedias = medias.map((media) => {
      if (media?.id === mediaSelected?.id) {
        return {
          ...media,
          isSelected: !media?.isSelected,
        };
      }
      return {
        ...media,
        isSelected: false,
      };
    });

    const updatedPreview = { ...mediaSelected, isSelected: !mediaSelected?.isSelected };
    const updateCurrenMedia = {
      id: mediaSelected.id,
      isSelected: !mediaSelected?.isSelected,
      type: mediaSelected?.type,
    };

    mediaSelected.id === currentMedia?.id
      ? setCurrentMedia(null)
      : setCurrentMedia(updateCurrenMedia);
    setMediaFiles(updatedMedias);
    setPreview(updatedPreview);
  };

  // Render columns base on screen
  let reproductorColumns = isLaptop ? 'col-12' : 'col-7';
  let selectorColumn = isLaptop ? 'col-12' : 'col-auto';

  return (
    <Show>
      <Show.When isTrue={mediaRestaurantQuery.isLoading || mediaRestaurantQuery.isFetching}>
        <Loading global={false} />
      </Show.When>
      <Show.Else>
        <Show>
          <Show.When isTrue={medias === null}>
            <div>{t('emptyData')}</div>
          </Show.When>
          <Show.Else>
            <div className='row'>
              <div className={reproductorColumns}>
                <Show>
                  <Show.When isTrue={preview}>
                    <div
                      className={`${styles.containerMedia}  ${
                        preview?.isSelected && styles.selectedFile
                      } ${error && currentMedia === null && styles.error}`}
                    >
                      <div className={styles.title}>
                        {preview?.langData[0]?.title ?? t('emptyTitle')}
                      </div>
                      <div className={styles.checkPreview}>
                        <div
                          className={`${styles.circle} ${
                            preview?.id === currentMedia?.id && currentMedia?.isSelected
                              ? styles.checked
                              : ''
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            selectMedia(preview);
                          }}
                        >
                          {preview?.id === currentMedia?.id && currentMedia?.isSelected && (
                            <span className={styles.checkmark}></span>
                          )}
                        </div>
                      </div>
                      <div className={`${styles.containerFile} d-flex justify-content-center`}>
                        <Show>
                          <Show.When isTrue={preview?.type === 'VIDEO'}>
                            <video src={preview?.url} controls></video>
                          </Show.When>
                          <Show.Else>
                            <img
                              src={preview?.url}
                              alt={preview?.langData[0]?.title ?? t('emptyTitle')}
                            />
                          </Show.Else>
                        </Show>
                      </div>
                    </div>
                  </Show.When>
                  <Show.Else>
                    <div
                      className={`d-flex flex-column align-items-center justify-content-center p-4 ${
                        styles.notSelected
                      } ${error && currentMedia === null && styles.error}`}
                      style={{ height: '100%', width: '100%' }}
                    >
                      <MediaFileIcon size='34px' />

                      <span className='mt-2'> {t('empty')}</span>
                    </div>
                  </Show.Else>
                </Show>
              </div>
              <div className={selectorColumn}>
                <div className={`${styles.containerList} ${!isLaptop && 'border-left'}`}>
                  {medias?.map((media) => (
                    <div
                      key={media.id}
                      className={`${styles.mediaItem} ${
                        media.id === preview?.id ? styles.selected : ''
                      } ${
                        currentMedia?.isSelected &&
                        currentMedia.id !== media.id &&
                        styles.notAvailable
                      } d-flex flex-row justify-content-between align-items-center`}
                      onClick={() => selectPreview(media)}
                    >
                      <div className='d-flex flex-row justify-content-start align-items-center'>
                        <Show>
                          <Show.When isTrue={media.type === 'VIDEO'}>
                            <video src={media.url}></video>
                          </Show.When>
                          <Show.Else>
                            <img src={media.url} alt='media' />
                          </Show.Else>
                        </Show>

                        <div className='ml-2 text-truncate text-left'>
                          <div className='text-truncate fw-600 fs-14' style={{ maxWidth: '150px' }}>
                            {media?.langData[0]?.title ?? t('emptyTitle')}
                          </div>
                          <div className='fw-400 fs-10'>
                            {media.type === 'VIDEO' ? t('fileVideo') : t('imageFile')}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${styles.circle} ${media?.isSelected ? styles.checked : ''}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          selectMedia(media);
                        }}
                      >
                        {media?.isSelected && <span className={styles.checkmark}></span>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <Show.When isTrue={error && currentMedia === null}>
                <div className='col-12'>
                  <div className='d-flex align-items-center error-content'>
                    <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                    <p className='text-danger p-0 m-0'>{t('error')}</p>
                  </div>
                </div>
              </Show.When>
            </div>
          </Show.Else>
        </Show>
      </Show.Else>
    </Show>
  );
};

export default MediaSelector;
