export const notificationRoutes = {
  NEW_USER: (refId, market) => {
    const [name, id] = refId?.split('|');
    if (id) return `/sellers/${market}/${id}/profile`;
  },
  APPLICATION_DISPUTE: (refId) => {
    if (refId) return `/disputes/${refId}`;
  },
  REFUTE_PAYMENT_DISPUTED: (refId) => {
    if (refId) return `/disputes/${refId}`;
  },
  APPLICATION_REFUND: (refId) => {
    if (refId) return `/refunds/${refId}`;
  },
};
