export const getVenueDetails = async (
  welletContext,
  marketId,
  selectedDates,
  orgId = null,
  limit,
  skip,
  orderBy,
) => {
  let url = `api/dashboard/market/${marketId}/venues/detail`;
  if (orgId) {
    url = `api/dashboard/market/${marketId}/venues/${orgId}/detail`;
  }
  return await welletContext.apis.admin.post(url, {
    from: selectedDates.from,
    to: selectedDates.to,
    limit,
    skip,
    orderBy,
  });
};

export const getCityDetails = async (welletContext, marketId, selectedDates, orgId = null) => {
  let url = `api/dashboard/market/${marketId}/city/detail`;
  if (orgId) {
    url = `api/dashboard/market/${marketId}/venues/${orgId}/city/detail`;
  }
  return await welletContext.apis.admin.post(url, selectedDates);
};

export const getDashboard = async (
  welletContext,
  orgId = null,
  marketId,
  selectedDates,
  isCommission = false,
) => {
  let url = `api/dashboard/market/${marketId}/getgraph`;
  if (isCommission && orgId === null) {
    url = `api/dashboard/market/${marketId}/commission/getgraph`;
  }
  if (orgId) {
    url = `api/dashboard/market/${marketId}/venues/${orgId}/getgraph`;
  }
  if (orgId && isCommission) {
    url = `api/dashboard/market/${marketId}/venues/${orgId}/commission/getgraph`;
  }
  return await welletContext.apis.admin.post(url, selectedDates);
};
export const getDashboardSummary = async (welletContext, marketId, orgId = null, selectedDates) => {
  let url = `api/dashboard/market/${marketId}/summary`;
  if (orgId) {
    url = `api/dashboard/market/${marketId}/venues/${orgId}/summary`;
  }
  return await welletContext.apis.admin.post(url, selectedDates);
};

export const getWeekdaySummary = async (welletContext, marketId, selectedDates, orgId = null) => {
  let url = `api/dashboard/market/${marketId}/weekday`;
  if (orgId) {
    url = `api/dashboard/market/${marketId}/venues/${orgId}/weekday`;
  }
  return await welletContext.apis.admin.post(url, selectedDates);
};

export const getAffiliateSummary = async (
  welletContext,
  marketId,
  selectedDates,
  limit,
  skip,
  orgId = null,
  orderBy,
) => {
  let url = `api/dashboard/market/${marketId}/summaryaffiliates`;
  if (orgId) {
    url = `api/dashboard/market/${marketId}/venues/${orgId}/summaryaffiliates`;
  }
  return await welletContext.apis.admin.post(url, {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
    limit,
    skip,
    orderBy,
  });
};
