import { useSelector } from 'react-redux';

const useUser = () => {
  const organization = useSelector((state) => state.user.user);
  const jwt = useSelector((state) => state.user.jwt);
  const isSuperAdmin = organization?.roles?.findIndex((r) => r === 'superadministrator') > -1;
  const isMarketAdmin = organization?.roles?.findIndex((r) => r === 'marketadmin') > -1;
  const isAdmin = organization?.roles?.findIndex((r) => r === 'admin') > -1;
  return {
    isSuperAdmin,
    isAdmin: isAdmin,
    isMarketAdmin: isMarketAdmin,
    user: organization,
    jwt,
    roles: organization.roles
  };
};

export default useUser;
