import { format } from 'date-fns';
import { getDateFromOffset, getWeekAgo } from '../helpers/dates';

const todayDate = getDateFromOffset(-300);
const sevenDaysAgo = getWeekAgo(todayDate);

const initialState = {
  tenantId: '',
  currentOrganization: JSON.parse(localStorage.getItem('currentOrg'))
    ? JSON.parse(localStorage.getItem('currentOrg'))
    : null,
  selectedOrganization: JSON.parse(localStorage.getItem('selectedOrg'))
    ? JSON.parse(localStorage.getItem('selectedOrg'))
    : null,
  selectedUser: JSON.parse(localStorage.getItem('selectedUser'))
    ? JSON.parse(localStorage.getItem('selectedUser'))
    : null,
  selectedDates: {
    from: format(sevenDaysAgo, 'yyyy-MM-dd') + 'T' + format(sevenDaysAgo, 'HH:mm:ss'),
    to: format(todayDate, 'yyyy-MM-dd') + 'T' + format(todayDate, 'HH:mm:ss'),
    // formatted from y to
    formattedFrom: format(sevenDaysAgo, 'yyyy-MM-dd'),
    formattedTo: format(todayDate, 'yyyy-MM-dd'),
  },
  paymentMethods: [],
  utcOffset: 0,
  dateType: 'reservation',
  language: '',
  formsLanguage: '',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TENANT_ID':
      return {
        ...state,
        tenantId: action.payload,
      };
    case 'SET_CURRENT_ORG':
      return {
        ...state,
        currentOrganization: action.payload,
      };
    case 'SET_SELECTED_ORG':
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    case 'SET_SELECTED_USER':
      return {
        ...state,
        selectedUser: action.payload,
      };
    case 'SET_SELECTED_DATE':
      return {
        ...state,
        selectedDates: action.payload,
      };
    case 'SET_SELECTED_DATETYPE':
      return {
        ...state,
        dateType: action.payload,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'UTC_OFFSET':
      return {
        ...state,
        utcOffset: action.payload,
      };
    case 'SET_APP_DATA':
      return {
        ...state,
        name: action.payload.name,
        titleWeb: action.payload.titleWeb,
        favIconUrl: action.payload.favIconUrl,
        logoType: action.payload.logoType,
        logoURL: action.payload.logoURL,
        templateNameId: action.payload.templateNameId,
        hasMarketingCampaign: action.payload.hasMarketingCampaign,
        qrCode: action.payload.qrCodeLogo,
        entityId: action.payload.id
      };
    case 'ASSOCIATES_LOGOUT':
      const todayDate = getDateFromOffset(state.utcOffset);
      return {
        ...state,
        currentOrganization: null,
        selectedOrganization: null,
        selectedDates: {
          from: format(sevenDaysAgo, 'yyyy-MM-dd') + 'T' + format(sevenDaysAgo, 'HH:mm:ss'),
          to: format(todayDate, 'yyyy-MM-dd') + 'T' + format(todayDate, 'HH:mm:ss'),
          // formatted from y to
          formattedFrom: format(sevenDaysAgo, 'yyyy-MM-dd'),
          formattedTo: format(todayDate, 'yyyy-MM-dd'),
        },
        dateType: 'reservation',
      };
    case 'SET_LANGUAGE_FROMS':
      return {
        ...state,
        formsLanguage: action.payload,
      };
    case 'SET_PAYMENT_METHODS':
      return {
        ...state,
        paymentMethods: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
