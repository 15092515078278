import React from 'react';
import Select, { components } from 'react-select';
import './selects.css';
import { useFormContext, Controller } from 'react-hook-form';
import { AllSelect } from './selectAll';
import { ChevronIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const SelectWellet = ({
  options,
  label,
  icon,
  border,
  name,
  className,
  selectAll = false,
  isRequired = false,
  onManualChange = () => {},
  useError = true,
  ...others
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const validationRules = {};

  if (isRequired) {
    validationRules.required = t('required');
  }

  const SelectComponent = selectAll ? AllSelect : Select;

  return (
    <div className={`form-group ${className}`}>
      {label && (
        <label className='mb-1 fw-500' style={{ color: 'var(--color-dark)' }}>
          {label}
        </label>
      )}

      <div
        className={`input-base-container select-container ${errors[name] ? 'input-error' : ''} ${
          icon ? 'd-flex' : ''
        } w-100`}
      >
        {icon ? (
          <span
            className='input-group-text px-2 input-icon'
            style={{ color: 'var(--color-subtitle)' }}
          >
            {icon}
          </span>
        ) : (
          <></>
        )}
        <Controller
          name={name}
          control={control}
          rules={validationRules}
          render={({ field }) => (
            <SelectComponent
              {...field}
              placeholder='Seleccionar'
              styles={{
                control: (baseStyles, props) => ({
                  ...baseStyles,
                  border: 'none',
                  borderRadius: 'none',
                  outline: 0,
                  boxShadow: 'none',
                  padding: 0,
                  background: props.isDisabled ? 'var(--color-border-table)' : 'none',
                }),
                container: (baseStyles, props) => ({
                  background: props.isDisabled
                    ? 'var(--color-border-table)'
                    : 'var(--color-background-app)',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  padding: '0 8px',
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  padding: 0,
                  margin: 0,
                  color: 'var(--text-color)',
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  padding: 0,
                  backgroundColor: 'var(--color-background-app)',
                  borderRadius: '5px',
                  zIndex: 99999,
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  padding: 0,
                  borderRadius: '5px',
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: 'var(--text-color)',
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: 'var(--hover)',
                  color: 'var(--text-color)',
                }),
                multiValueGeneric: (baseStyles) => ({
                  ...baseStyles,
                  color: 'var(--color-title)',
                }),
                multiValueRemove: (baseStyles, state) => ({
                  ...baseStyles,
                  display: state.isDisabled ? 'none' : 'flex',
                  backgroundColor: 'var(--hover)',
                  color: 'var(--text-color)',
                  ':hover': {
                    color: 'var(--color-title)',
                    backgroundColor: '#cacaca',
                  },
                }),
                menuPortal: (baseStyles) => ({
                  ...baseStyles,
                  zIndex: 9999,
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: 'var(--text-color)',
                  backgroundColor: state.isSelected
                    ? 'rgba(0,0,0,.10)'
                    : 'var(--color-background-app)',
                  ':hover': {
                    backgroundColor: state.isSelected ? 'rgba(0,0,0,.10)' : 'rgba(0,0,0,.03)',
                  },
                }),
              }}
              {...others}
              onChange={(e) => {
                field.onChange(e);
                onManualChange(e);
              }}
              menuPosition='fixed'
              components={{
                ...others.components,
                IndicatorSeparator: () => null,
                IndicatorsContainer: (prop) => (
                  <div className='pr-2'>
                    {others.customIcon ? (
                      others.customIcon
                    ) : (
                      {...others.isClearable ? <components.IndicatorsContainer {...prop} /> :  <ChevronIcon rotation={'90'} size='16px' color='var(--color-subtitle)' /> }
                    )}
                  </div>
                ),
              }}
              options={options}
              className={`select-style ${border ? 'border-left' : ''}`}
            />
          )}
        />
      </div>
      {useError && errors[name] && (
        <div className='d-flex align-items-center error-content'>
          <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
          <p className='text-danger p-0 m-0'>{errors[name].message}</p>
        </div>
      )}
    </div>
  );
};

export default SelectWellet;
