import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../context/wellet/welletContext';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { getSettings, putSettings } from '../../services';
import {
  FormButton,
  InputCheckbox,
  InputNumber,
  InputTime,
  Loading,
  ModernSwitcher,
  Select,
  Toast,
} from '../../uiComponents/common';
import FormRow from '../../components/forms/formRow';
import { useCreateBreadCrumbs } from '../../hooks';
import { PageHeader } from '../../components';
import { formatTimeFromMinutes, formatTimeToMinutes } from '../../helpers/format';
import { useGetSalesCategory } from '../../hooks/queries/useGetSalesCategory';

const Settings = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.settings' });
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const methods = useForm();

  const saleCategorysQuery = useGetSalesCategory(organization.marketId);

  const settingQuery = useQuery({
    queryKey: ['market-settings', organization.marketId],
    queryFn: () => getSettings(welletContext, organization.marketId),
    onSuccess: (data) => methods.reset(formatActivityData(data)),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: saleCategorysQuery.isSuccess,
  });

  const settingMutation = useMutation({
    mutationFn: (body) => {
      return putSettings(welletContext, body, organization.marketId);
    },
  });

  const formatActivityData = (data) => {
    const formattedData = {
      minimumDefaultCommission: data.minimumDefaultCommission,
      commissionSplitLevel1: data?.commissionSplitLevel1,
      pointValue: data?.pointValue,
      iva: data.iva,
      biometricAutomatic: data?.biometricAutomatic,
      biometricRequired: data.biometricRequired,
      commissionPercentageLevel2: data.commissionPercentageLevel2,
      maxPaymentsPerReservation: data.maxPaymentsPerReservation,
      welletCommissionPercentage: data.welletCommissionPercentage,
      enableAutomaticPayments: data.enableAutomaticPayments,
      welletCommissionPaidAfterHours: data.welletCommissionPaidAfterHours,
      ctCookieTime: data.ctCookieTime,
      restaurantExpiration: data.restaurantTimeExpirationInMinutes
        ? formatTimeFromMinutes(data.restaurantTimeExpirationInMinutes)
        : formatTimeFromMinutes(0),
      groupExpiration: data.groupTimeExpirationInMinutes
        ? formatTimeFromMinutes(data.groupTimeExpirationInMinutes)
        : formatTimeFromMinutes(0),
      areaExpiration: data.areaTimeExpirationInMinutes
        ? formatTimeFromMinutes(data.areaTimeExpirationInMinutes)
        : formatTimeFromMinutes(0),
      paymentLinkGroupExpiration: data.paymentLinkGroupExpiration
        ? formatTimeFromMinutes(data.paymentLinkGroupExpiration)
        : formatTimeFromMinutes(0),
      expirationShow: data.defaultMinutesForNoShow
        ? formatTimeFromMinutes(data.defaultMinutesForNoShow)
        : formatTimeFromMinutes(0),
      requiresRP: data?.requiresRP,
      defaultSalesCategoryId: salesCategoryFormatted?.some(
        (s) => s.value === data?.defaultSalesCategoryId,
      )
        ? salesCategoryFormatted?.find((s) => s.value === data?.defaultSalesCategoryId)
        : null,
    };

    data?.reservationTypeConfigs?.forEach((type) => {
      const key = `hasCallCenter_${type.id}_${type.reservationType}`;
      formattedData[key] = type.hasCallcenter;
    });

    return formattedData;
  };

  const onSubmit = async (data) => {
    const body = {
      minimumDefaultCommission: data?.minimumDefaultCommission,
      commissionSplitLevel1: data?.commissionSplitLevel1,
      pointValue: data?.pointValue,
      iva: data?.iva,
      biometricAutomatic: data?.biometricAutomatic,
      biometricRequired: data?.biometricRequired,
      commissionPercentageLevel2: data?.commissionPercentageLevel2,
      maxPaymentsPerReservation: data?.maxPaymentsPerReservation,
      welletCommissionPercentage: data?.welletCommissionPercentage,
      enableAutomaticPayments: data?.enableAutomaticPayments,
      welletCommissionPaidAfterHours: data?.welletCommissionPaidAfterHours,
      ctCookieTime: data?.ctCookieTime,
      groupTimeExpirationInMinutes: formatTimeToMinutes(data.groupExpiration),
      restaurantTimeExpirationInMinutes: formatTimeToMinutes(data.restaurantExpiration),
      defaultMinutesForNoShow: formatTimeToMinutes(data.expirationShow),
      areaTimeExpirationInMinutes: formatTimeToMinutes(data.areaExpiration),
      paymentLinkGroupExpiration: formatTimeToMinutes(data.paymentLinkGroupExpiration),
      requiresRP: data?.requiresRP,
      defaultSalesCategoryId: data?.defaultSalesCategoryId?.value
        ? data?.defaultSalesCategoryId?.value
        : null,
    };

    const reservationTypeConfigs = Object.keys(data)
      .filter((key) => key.startsWith('hasCallCenter'))
      .map((key) => {
        const id = parseInt(key.split('_')[1], 10);
        return { id: id, hasCallCenter: data[key], reservationType: key.split('_')[2] };
      });

    body.reservationTypeConfigs = reservationTypeConfigs;

    settingMutation.mutate(body);
  };

  useCreateBreadCrumbs([
    { name: t('settings'), path: '/settings' },
    { name: t('title'), path: '/setting' },
  ]);

  const salesCategoryFormatted = saleCategorysQuery?.data?.data?.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  return (
    <div>
      {settingMutation.isSuccess && (
        <Toast
          message={t('toast.success')}
          type='SUCCESS'
          onCallback={() => settingMutation.reset()}
        />
      )}
      <FormProvider {...methods}>
        <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
          {settingQuery.isLoading || saleCategorysQuery.isLoading ? (
            <Loading />
          ) : (
            <>
              <PageHeader
                title={t('title')}
                endComponent={
                  <FormButton
                    all={false}
                    nextStep={true}
                    handleRedirect={() => {}}
                    showCancel={false}
                    isDisabled={
                      settingMutation.isSuccess ||
                      settingMutation.isLoading ||
                      settingMutation.isError
                    }
                  />
                }
              />
              <FormRow
                text={t('minimumDefaultCommission')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'minimumDefaultCommission'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('commissionSplitLevel1')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'commissionSplitLevel1'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('commissionPercentageLevel2')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'commissionPercentageLevel2'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('pointValue')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'pointValue'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('iva')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'iva'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('biometricRequired')}
                endComponent={
                  <div className='col'>
                    <InputCheckbox name={'biometricRequired'} label={t('active')} />
                  </div>
                }
              />
              <FormRow
                text={t('biometricAutomatic')}
                endComponent={
                  <div className='col'>
                    <InputCheckbox name={'biometricAutomatic'} label={t('active')} />
                  </div>
                }
              />
              <FormRow
                text={t('maxPaymentsPerReservation')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'maxPaymentsPerReservation'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('welletCommissionPercentage')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'welletCommissionPercentage'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('welletCommissionPaidAfterHours')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'welletCommissionPaidAfterHours'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('enableAutomaticPayments')}
                endComponent={
                  <div className='col'>
                    <InputCheckbox name={'enableAutomaticPayments'} label={t('active')} />
                  </div>
                }
              />
              <FormRow
                text={t('ctCookieTime')}
                endComponent={
                  <InputNumber
                    label={''}
                    name={'ctCookieTime'}
                    className={'col-6'}
                    placeholder={''}
                    isRequired={true}
                  />
                }
              />
              <FormRow
                text={t('timeExpiration')}
                endComponent={
                  <div className='col-6'>
                    <InputTime
                      label={t('restaurant')}
                      className={'mb-2'}
                      name={'restaurantExpiration'}
                      required={true}
                    />
                    <InputTime
                      label={'Areas'}
                      className={'mb-2'}
                      name={'areaExpiration'}
                      required={true}
                    />
                    <InputTime
                      label={t('group')}
                      className={'mb-2'}
                      name={'groupExpiration'}
                      required={true}
                    />
                  </div>
                }
              />
              <FormRow
                text={t('expirationTimeShow.title')}
                endComponent={
                  <div className='col-6'>
                    <InputTime
                      tooltip={t('expirationTimeShow.tooltip')}
                      label={t('expirationTimeShow.label')}
                      className={'mb-2'}
                      name={'expirationShow'}
                      required={true}
                    />
                  </div>
                }
              />
              <FormRow
                text={t('depositTimeExpiration.title')}
                endComponent={
                  <div className='col-6'>
                    <InputTime
                      label={t('depositTimeExpiration.label')}
                      className={'mb-2'}
                      name={'paymentLinkGroupExpiration'}
                      required={true}
                    />
                  </div>
                }
              />
              <FormRow
                text={t('loyaltyDefault.label')}
                endComponent={
                  <div className='col-6 '>
                    <div className='row'>
                      <Select
                        options={salesCategoryFormatted}
                        placeholder={t('loyaltyDefault.placeholder')}
                        className={'mb-3 col-6 '}
                        name={'defaultSalesCategoryId'}
                      />
                    </div>
                  </div>
                }
              />
              <FormRow
                text={t('requiresRP')}
                endComponent={
                  <div className='col'>
                    <InputCheckbox name={'requiresRP'} label={t('active')} />
                  </div>
                }
              />
              <FormRow
                text={t('hasCallCenter.title')}
                endComponent={
                  <div className='col-6'>
                    {settingQuery?.data?.reservationTypeConfigs?.map((p, i) => (
                      <React.Fragment key={p.id}>
                        <ModernSwitcher
                          className='col-auto mb-1'
                          text={t(p.reservationType)}
                          name={`hasCallCenter_${p.id}_${p.reservationType}`}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                }
              />
              <div className='d-flex justify-content-end mt-2'>
                <FormButton
                  all={false}
                  nextStep={true}
                  handleRedirect={() => {}}
                  showCancel={false}
                  isDisabled={
                    settingMutation.isSuccess ||
                    settingMutation.isLoading ||
                    settingMutation.isError
                  }
                />
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default Settings;
