import React, { useContext, useState } from 'react';
import {
  PageHeader,
  FilterCard,
  DashboardRestaurants,
  TimeLine,
  DateFilter,
  Show,
} from '../../components';
import { Loading, PageError } from '../../uiComponents/common';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getDashboard, getDashboardSummary } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import './dashboard.css';
import { useTranslation } from 'react-i18next';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import TitleTab from '../../components/titleTab/titleTab';

const Dashboard = () => {
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const [filter, setFilter] = useState([]);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.dashboard' });

  const selectedFormattedDates = {
    from: selectedDates?.formattedFrom,
    to: selectedDates?.formattedTo,
  };

  // Main API Query
  const dashboardQuery = useQuery({
    queryKey: [
      'dashboard',
      organization,
      selectedFormattedDates,
      filter,
      organization.id,
      organization.marketId,
    ],
    queryFn: () => {
      if (organization.id !== organization.marketId) {
        return getDashboard(
          welletContext,
          organization.id,
          organization.marketId,
          selectedFormattedDates,
          filter[0] === 'commission' || filter[0] === 'commissions',
        );
      }
      return getDashboard(
        welletContext,
        null,
        organization.marketId,
        selectedFormattedDates,
        filter[0] === 'commission' || filter[0] === 'commissions',
      );
    },
    ...defaultQueryOptions,
    enabled: selectedDates !== null,
  });
  const dashboardSummaryQuery = useQuery({
    queryKey: ['dashboard-summary', organization.id, selectedFormattedDates],
    queryFn: () => {
      if (organization.id !== organization.marketId) {
        return getDashboardSummary(
          welletContext,
          organization.marketId,
          organization.id,
          selectedFormattedDates,
        );
      }
      return getDashboardSummary(
        welletContext,
        organization.marketId,
        null,
        selectedFormattedDates,
      );
    },
    ...defaultQueryOptions,
  });

  function formatPosObjects(posObjects) {
    return posObjects?.items;
  }

  const timeLineData = formatPosObjects(dashboardQuery.data?.data) ?? [];

  const displayDiagonal = timeLineData.length >= 15 ? { labelDisplay: 'rotate', slantLabel: 1 } : {};

  return (
    <>
      <TitleTab />
      <PageHeader title={t('title')} endComponent={<DateFilter filterName={'Fecha'} />} />
      <Show>
        <Show.When isTrue={dashboardSummaryQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={dashboardSummaryQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row mt-2'>
            <div className='row pl-3 chart-container pr-0 col-xl-8'>
              <div className='col-6 col-sm-auto p-0'>
                <FilterCard
                  category={t('cardFilters.reservations')}
                  quantity={dashboardSummaryQuery?.data?.data?.reservations}
                  onClick={() => setFilter(['reservations'])}
                  isSelected={filter[0] === 'reservations'}
                />
              </div>
              <div className='col-6 col-sm-auto p-0'>
                <FilterCard
                  category={t('cardFilters.incomes')}
                  isMoney={true}
                  quantity={
                    dashboardSummaryQuery?.data?.data?.income
                      ? dashboardSummaryQuery?.data?.data?.income
                      : 0
                  }
                  onClick={() => setFilter([])}
                  isSelected={filter.length === 0}
                  minify={100000000}
                />
              </div>
              <div className='col-6 col-sm-auto p-0'>
                <FilterCard
                  category={t('cardFilters.commissions')}
                  isMoney={true}
                  quantity={
                    dashboardSummaryQuery?.data?.data?.commission
                      ? dashboardSummaryQuery?.data?.data?.commission
                      : 0
                  }
                  onClick={() => setFilter(['commissions'])}
                  isSelected={filter[0] === 'commissions'}
                />
              </div>
              <div className='col-6 col-sm-auto p-0'>
                <FilterCard
                  category={t('cardFilters.avgCommission')}
                  quantity={
                    dashboardSummaryQuery?.data?.data?.averageCommissionPercentage
                      ? dashboardSummaryQuery?.data?.data?.averageCommissionPercentage + '%'
                      : 0 + '%'
                  }
                  onClick={() => setFilter(['commission'])}
                  isSelected={filter[0] === 'commission'}
                />
              </div>
              <div className='col-6 col-sm-auto p-0'>
                <FilterCard
                  category={t('cardFilters.activeConcierges')}
                  quantity={dashboardSummaryQuery?.data?.data?.activeConcierges}
                  onClick={() => setFilter(['concierges'])}
                  isSelected={filter[0] === 'concierges'}
                />
              </div>
            </div>
          </div>
          <Show>
            <Show.When isTrue={dashboardQuery.isLoading}>
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ height: '400px' }}
              >
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.When isTrue={dashboardQuery.isError}>
              <PageError text={t('graphics.error')} globaly={false} />
            </Show.When>
            <Show.Else>
              <div className='row mt-4'>
                <div className='col-12 chart-container'>
                  <TimeLine
                    data={timeLineData.map((c) => ({
                      label: c.group,
                      value:
                        filter[0] === 'reservations'
                          ? c.reservation
                          : filter[0] === 'commission'
                          ? c.avgCommissionPercent
                          : filter[0] === 'concierges'
                          ? c.activeConcierge
                          : filter[0] === 'commissions'
                          ? c.commission
                          : c.income,
                      toolText:
                        filter[0] === 'reservations'
                          ? `${c.reservation}`
                          : filter[0] === 'commission'
                          ? `%${c.avgCommissionPercent}`
                          : filter[0] === 'concierges'
                          ? `${c.activeConcierge}`
                          : filter[0] === 'commissions'
                          ? `${organization.currencySymbol} ${c.commission?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                          : `${organization.currencySymbol} ${c.income?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`,
                    }))}
                    chart={{  canvasLeftMargin: 33, canvasRightMargin: 33, ...displayDiagonal }}
                    yAxis={filter[0] === 'commission' ? 'Porcentaje' : ''}
                  />
                </div>
              </div>
            </Show.Else>
          </Show>
          <DashboardRestaurants selectedOption={filter[0]} />
        </Show.Else>
      </Show>
    </>
  );
};

export default Dashboard;
