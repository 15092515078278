import { BadgeOutline } from '../uiComponents/common';
import BadgeNew from '../uiComponents/common/badge/badge';

export const commissionBadge = (status, t) => {
  switch (status) {
    case 'PENDING_REVIEW':
      return (
        <BadgeNew
          text={t('components.badgeStatus.commissionBadge.pendingReview')}
          type={'violet'}
        />
      );
    case 'REJECTED':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.rejected')} type={'red'} />;
    case 'DISPUTED':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.disputed')} type={'red'} />;
    case 'CANCELLED':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.cancelled')} type={'red'} />;
    case 'CANCELLED_MODIFICATION':
      return (
        <BadgeNew
          text={t('components.badgeStatus.commissionBadge.cancelledModification')}
          type={'red'}
        />
      );
    case 'CANCELLED_CONCIERGE':
      return (
        <BadgeNew
          text={t('components.badgeStatus.commissionBadge.cancelledConcierge')}
          type={'red'}
        />
      );
    case 'CANCELLED_CUSTOMER':
      return (
        <BadgeNew
          text={t('components.badgeStatus.commissionBadge.cancelledCustomer')}
          type={'red'}
        />
      );
    case 'CONFIRMED':
      return (
        <BadgeNew text={t('components.badgeStatus.commissionBadge.confirmed')} type={'violet'} />
      );
    case 'EXPIRED':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.expired')} type={'red'} />;
    case 'CLOSED':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.closed')} type={'grey'} />;
    case 'NO_SHOW':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.noShow')} type={'grey'} />;
    case 'NO_SHOW_MANUAL':
      return (
        <BadgeNew text={t('components.badgeStatus.commissionBadge.noShowManual')} type={'grey'} />
      );
    case 'PENDING':
      return (
        <BadgeNew text={t('components.badgeStatus.commissionBadge.pending')} type={'orange'} />
      );
    case 'CHECKIN':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.checkin')} type={'green'} />;
    case 'PAYING':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.paying')} type={'green'} />;
    case 'PAYMENT_PENDING':
      return (
        <BadgeNew
          text={t('components.badgeStatus.commissionBadge.paymentPending')}
          type={'green'}
        />
      );
    case 'PAYMENT_ACCREDITED':
      return (
        <BadgeNew
          text={t('components.badgeStatus.commissionBadge.paymentAccredited')}
          type={'green'}
          icon={'check'}
        />
      );
    case 'WAITING_DEPOSIT':
      return (
        <BadgeNew
          text={t('components.badgeStatus.commissionBadge.waitingDeposit')}
          type={'violet'}
        />
      );
    case 'SEATED':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.seated')} type={'blue'} />;
    case 'REJECTED':
      return <BadgeNew text={t('components.badgeStatus.commissionBadge.invalid')} type={'red'} />;
    default:
      return status;
  }
};
export const paymentBatchBadge = (status, arePaymentsBeingExecuted, t) => {
  switch (status) {
    case 'PENDING_REVIEW':
      return (
        <BadgeNew
          text={t('components.badgeStatus.paymentBatchBadge.pendingReview')}
          type={'violet'}
        />
      );
    case 'REVIEWED':
      return (
        <BadgeNew text={t('components.badgeStatus.paymentBatchBadge.reviewed')} type={'green'} />
      );
    case 'APPROVED':
      // if (arePaymentsBeingExecuted) {
      //   return (
      //     <BadgeNew
      //       text={t('components.badgeStatus.paymentBatchBadge.approved.executing')}
      //       type={'orange'}
      //     />
      //   );
      // }
      return (
        <BadgeNew
          text={t('components.badgeStatus.paymentBatchBadge.approved.paid')}
          type={'green'}
          icon={'check'}
        />
      );
    default:
      return status;
  }
};
export const reservationBadge = (status, t) => {
  switch (status) {
    case 'CONFIRMED':
      return (
        <BadgeNew text={t('components.badgeStatus.reservationBadge.confirmed')} type={'violet'} />
      );
    case 'CHECKIN':
      return (
        <BadgeNew text={t('components.badgeStatus.reservationBadge.checkin')} type={'green'} />
      );
    case 'NO_SHOW_MANUAL':
      return (
        <BadgeNew text={t('components.badgeStatus.reservationBadge.noShowManual')} type={'red'} />
      );
    case 'PAYING':
      return <BadgeNew text={t('components.badgeStatus.reservationBadge.paying')} type={'green'} />;
    case 'CLOSED':
      return <BadgeNew text={t('components.badgeStatus.reservationBadge.closed')} type={'grey'} />;
    case 'NO_SHOW_MANUAL':
      return (
        <BadgeNew text={t('components.badgeStatus.reservationBadge.noShowManuals')} type={'red'} />
      );
    default:
      return <BadgeNew text={status} type={'violet'} />;
  }
};
export const paymentSummaryBadge = (status, statusDetail, t) => {
  switch (status) {
    case 'PENDING':
      return (
        <BadgeNew text={t('components.badgeStatus.paymentSummaryBadge.pending')} type={'violet'} />
      );
    case 'IN_PROGRESS':
      if (statusDetail === 'waiting_account_fund') {
        return (
          <BadgeNew
            text={t('components.badgeStatus.paymentSummaryBadge.inProgressWaitingAccountFund')}
            type={'red'}
          />
        );
      }
      return (
        <BadgeNew
          text={t('components.badgeStatus.paymentSummaryBadge.inProgress')}
          type={'violet'}
        />
      );
    case 'ERROR':
      return <BadgeNew text={t('components.badgeStatus.paymentSummaryBadge.error')} type={'red'} />;
    case 'ACCREDITED':
      return (
        <BadgeNew
          text={t('components.badgeStatus.paymentSummaryBadge.accredited')}
          type={'green'}
        />
      );
    default:
      return <BadgeNew text={status} type={'violet'} />;
  }
};
export const paymentSummaryBadgeOutline = (status, statusDetail, t) => {
  switch (status) {
    case 'PENDING':
      return (
        <BadgeOutline
          text={t('components.badgeStatus.paymentSummaryBadge.pending')}
          type={'violet'}
        />
      );
    case 'IN_PROGRESS':
      if (statusDetail === 'waiting_account_fund') {
        return (
          <BadgeOutline
            text={t('components.badgeStatus.paymentSummaryBadge.inProgressWaitingAccountFund')}
            type={'red'}
          />
        );
      }
      return (
        <BadgeOutline
          text={t('components.badgeStatus.paymentSummaryBadge.inProgress')}
          type={'violet'}
        />
      );
    case 'ERROR':
      return (
        <BadgeOutline text={t('components.badgeStatus.paymentSummaryBadge.error')} type={'red'} />
      );
    case 'ACCREDITED':
      return (
        <BadgeOutline
          text={t('components.badgeStatus.paymentSummaryBadge.accredited')}
          type={'green'}
        />
      );
    default:
      return <BadgeOutline text={status} type={'violet'} />;
  }
};
export const paymentLiveFeedBadge = (status, t, isFinance) => {
  switch (status) {
    case 'PENDING':
      return <BadgeNew text={t('components.badgeStatus.paymentStatus.pending')} type={'gray'} />;
    case 'ERROR':
      return <BadgeNew text={t('components.badgeStatus.paymentStatus.error')} type={'red'} />;
    case 'REQUIRES_ACTION':
      return (
        <BadgeNew text={t('components.badgeStatus.paymentStatus.requires_action')} type={'red'} />
      );
    case 'PARTIALLY_REFUNDED':
      return (
        <BadgeNew
          text={t('components.badgeStatus.paymentStatus.partially_refunded')}
          type={'red'}
        />
      );
    case 'REFUNDED':
      return <BadgeNew text={t('components.badgeStatus.paymentStatus.refunded')} type={'violet'} />;
    case 'PROCESSING':
      return (
        <BadgeNew text={t('components.badgeStatus.paymentStatus.processing')} type={'violet'} />
      );
    case 'OK':
      return <BadgeNew text={t('components.badgeStatus.paymentStatus.ok')} type={'green'} />;
    case 'APPROVED':
      return <BadgeNew text={t('components.badgeStatus.paymentStatus.ok')} type={'green'} />;
    case 'REFUND_REQUESTED':
      return (
        <BadgeNew text={t('components.badgeStatus.paymentStatus.refund_requested')} type={'gray'} />
      );
    case 'DISPUTED_WINNED':
      return <BadgeNew text={t('components.badgeStatus.disputes.won')} type={'green'} />;
    case 'DISPUTED_LOST':
      return <BadgeNew text={t('components.badgeStatus.disputes.lost')} type={'red'} />;
    case 'DISPUTED':
      return <BadgeNew text={t('components.badgeStatus.disputes.pending')} type={'grey'} />;
    case 'DISPUTED_NOT_ACCEPTED':
      return <BadgeNew text={t('components.badgeStatus.disputes.notAccepted')} type={'orange'} />;
    case 'DEADLINE_MISSIED':
      return (
        <BadgeNew text={t('components.badgeStatus.disputes.deadlineMissied')} type={'orange'} />
      );
    case 'STRIPE_REVIEW':
      if (isFinance) {
        return (
          <BadgeNew text={t('components.badgeStatus.disputes.stripeReview')} type={'violet'} />
        );
      } else {
        return <BadgeNew text={t('components.badgeStatus.disputes.notAccepted')} type={'orange'} />;
      }
    default:
      return <BadgeNew text={status} type={'red'} />;
  }
};

export const disputesBadge = (status, t, isFinance) => {
  switch (status) {
    case 'DISPUTED_WINNED':
      return <BadgeNew text={t('components.badgeStatus.disputes.won')} type={'green'} />;
    case 'DISPUTED_LOST':
      return <BadgeNew text={t('components.badgeStatus.disputes.lost')} type={'red'} />;
    case 'DISPUTED':
      return <BadgeNew text={t('components.badgeStatus.disputes.pending')} type={'grey'} />;
    case 'DISPUTED_NOT_ACCEPTED':
      return <BadgeNew text={t('components.badgeStatus.disputes.notAccepted')} type={'orange'} />;
    case 'DEADLINE_MISSIED':
      return (
        <BadgeNew text={t('components.badgeStatus.disputes.deadlineMissied')} type={'orange'} />
      );
    case 'STRIPE_REVIEW':
      if (isFinance) {
        return (
          <BadgeNew text={t('components.badgeStatus.disputes.stripeReview')} type={'violet'} />
        );
      } else {
        return <BadgeNew text={t('components.badgeStatus.disputes.notAccepted')} type={'orange'} />;
      }
    default:
      return <BadgeNew text={status} type={'violet'} />;
  }
};

export const requestStatus = (status, text) => {
  switch (status) {
    case 'APPROVED':
      return <BadgeNew text={text} type={'green'} icon={'check'} />;
    case 'PENDING':
      return <BadgeNew text={text} type={'grey'} />;
    case 'REJECTED':
      return <BadgeNew text={text} type={'red'} icon={''} />;
    default:
      return status;
  }
};

export const requestKycStatus = (status, text) => {
  switch (status) {
    case 'SEND':
      return <BadgeNew text={text} type={'blue'} icon={'arrowup'} className='fw-600' />;
    case 'PENDING':
      return <BadgeNew text={text} type={'grey'} className='fw-600' />;
    case 'APPROVED':
      return <BadgeNew text={text} type={'green'} icon={'check'} className='fw-600' />;
    case 'REJECTED':
      return <BadgeNew text={text} type={'red'} icon={''} className='fw-600' />;
    case 'REVALIDATION':
      return <BadgeNew text={text} type={'orange'} icon={''} className='fw-600' />;
    case 'SEND_WITH_OBSERVATIONS':
      return <BadgeNew text={text} type={'orange'} icon={''} className='fw-600' />;
    default:
      return status;
  }
};

export const accountsStatus = (status, text) => {
  switch (status) {
    case 'POSITIVE':
      return <BadgeNew text={text} type={'green'} />;
    case 'NEGATIVE':
      return <BadgeNew text={text} type={'red'} />;
    default:
      return status;
  }
};
