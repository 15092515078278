import React from 'react';
import { getOccasionText } from '../../../utils/getOcassionIcon';
import { ContanierSection, Divider } from '../../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../components';
import { getLocale2 } from '../../../helpers/dates';
import '../reservation-detail.css';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

const ReservationInfo = ({ reservation, mode = 'detail' }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.reservationInfo' });
  const { t: translate } = useTranslation();
  const language = useSelector((state) => state.app.language);

  const getReservation = () => {
    let response = {};
    if (mode === 'detail') {
      response.venue = reservation.activityName;
      response.date = reservation.reservationDateLocalTime;
      response.customer = reservation.customerName;
      response.pax = reservation.paxs;
      response.email = reservation.emailClient 
    } else {
      response.venue = reservation?.actualReservation?.activityName;
      response.date = reservation?.actualReservation?.date;
      response.customer = reservation.customer;
      response.pax = reservation?.actualReservation?.pax;
      response.email = reservation?.email;
    }
    return response;
  };

  const locale = getLocale2(language);

  const date = format(new Date(getReservation()?.date), 'EEEE d MMM.', { locale });
  const time = format(new Date(getReservation()?.date), 'HH:mm a.', { locale });
  return (
    <>
      <ContanierSection maxWidth='1200px'>
        <div className='row no-gutters container-data margin-reservation'>
          <div className='col-12 col-md-3'>
            <div className='dark-grey container-label'>Venue</div>
            <div className='fw-700 container-text'>{getReservation().venue}</div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='dark-grey container-label'>{t('details.dateReservation')}</div>
            <div className='fw-700 container-text sentences-capitalize'>{date}</div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='dark-grey container-label'>{t('details.time')}</div>
            <div className='fw-700 container-text text-lowercase'>{time}</div>
          </div>
        </div>
        <div className='row no-gutters container-data margin-reservation'>
          <div className='col-12 col-md-3'>
            <div className='dark-grey container-label'>{t('details.adults')}</div>
            <div className='fw-700 container-text'>
              {reservation.reservationType === 'RESTAURANT'
                ? getReservation().pax
                : reservation?.reservationDetail?.adultPaxs}
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='dark-grey container-label'>{t('details.minors')}</div>
            <div className='fw-700 container-text'>
              {reservation?.reservationDetail?.kidPaxs
                ? reservation?.reservationDetail?.kidPaxs
                : 0}
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='dark-grey container-label'>{t('details.infants')}</div>
            <div className='fw-700 container-text'>
              {reservation?.reservationDetail?.infantPaxs
                ? reservation?.reservationDetail?.infantPaxs
                : 0}
            </div>
          </div>
        </div>
        <div className='row no-gutters container-data mb-1'>
          <div className='col-12 col-md-3'>
            <div className='dark-grey container-label'>{t('details.nameOf')}</div>
            <div className='fw-700 container-text'>
              {getReservation().customer ? getReservation().customer : '-'}
            </div>
          </div>
          <div className='col-12 col-md-4 text-truncate'>
            <div className='dark-grey container-label'>{t('details.email')}</div>
            <div className='fs-16 fw-400'>{getReservation().email ? getReservation().email : '-'}</div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='dark-grey  container-label'>{t('details.clientPhone')}</div>
            <div className='fs-16 fw-400'>{reservation.customerPhone}</div>
          </div>
        </div>
      </ContanierSection>
      <Divider className='mb-26' />
      <ContanierSection maxWidth='1200px'>
        <div className='row no-gutters container-data margin-reservation'>
          <div className='col-12 col-md-3 '>
            <div className='dark-grey  container-label'>{t('details.chance')}</div>
            <div className='container-text fw-600'>
              {getOccasionText(reservation.occasion, translate) ?? '-'}
            </div>
          </div>
          <div className='col-12 col-md-4 '>
            <div className='dark-grey  container-label'>{t('details.event')}</div>
            <div className='container-text fw-600'>
              {reservation.eventName ? reservation.eventName : '-'}
            </div>
          </div>
          <Show.When isTrue={reservation.status === 'CANCELLED' && mode !== 'detail'}>
            <div className='col-12 col-md-4'>
              <div className='dark-grey container-label'>{t('details.cancelBy')}</div>
              <div className='fw-700 container-text'>
                {reservation?.hasModification
                  ? t('details.modify')
                  : reservation?.cancelledByUserId
                  ? 'Concierge'
                  : t('details.client')}
              </div>
            </div>
          </Show.When>
        </div>
        <div className='row no-gutters container-data'>
          <div className='col-12'>
            <div className='dark-grey  container-label'>{t('details.specialRequest')}</div>
            <div className='fs-16 fw-400'>{reservation.comments ? reservation.comments : '-'}</div>
          </div>
        </div>
      </ContanierSection>
    </>
  );
};

export default ReservationInfo;
