import React from 'react';
import { Button } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';

const PaginationByItems = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  className = 'd-flex justify-content-between w-100',
}) => {
  const from = currentPage === 1 ? '1' : (currentPage - 1) * itemsPerPage + 1;
  const to = currentPage * itemsPerPage > totalItems ? totalItems : currentPage * itemsPerPage;
  const { t } = useTranslation('translation', { keyPrefix: 'components.pagination' });

  return (
    <div className={className}>
      <div className='pagination-text text-general'>
        {totalItems > 0 ? t('paginationByItems', { from, to, totalItems }) : null}
      </div>
      <div>
        <Button
          disabled={currentPage === 1}
          size={'small'}
          onClick={() => onPageChange(currentPage - 1)}
          className='btn-custom-white'
          text={t('before')}
        />
        <Button
          size={'small'}
          disabled={currentPage * itemsPerPage >= totalItems}
          onClick={() => onPageChange(currentPage + 1)}
          className='ml-2 btn-custom-white'
          text={t('after')}
        />
      </div>
    </div>
  );
};

export default PaginationByItems;
