import React from 'react';
import { ExclamationRoundedIcon, HighRisk, LowRisk, MidRisk } from '../../icons';
import { getRiskLevel } from '../../../helpers/format';
import './risks.css';

const Risks = ({ risk, onClick, hover = true }) => {
  const getRisk = () => {
    return risk === 'HIGH' ? (
      <HighRisk />
    ) : risk === 'LOW' ? (
      <LowRisk />
    ) : risk === 'MEDIUM' ? (
      <MidRisk />
    ) : (
      '-'
    );
  };
  return (
    <div
      className={`d-flex align-items-center justify-content-between risk-container  ${
        !hover ? 'not-hover' : ''
      }`}
      onClick={onClick}
    >
      <div className={`pl-2 d-flex align-items-center risk-text-${risk?.toLocaleLowerCase()}`}>
        <div className='mr-1'>
          <ExclamationRoundedIcon />
        </div>
        {getRiskLevel(risk)}
      </div>
      <div className='pr-2'>{getRisk()}</div>
    </div>
  );
};

export default Risks;
