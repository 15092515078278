export const tabsTypes = {
  PROFILE: 'PROFILE',
  DOCUMENTATION: 'DOCUMENTATION',
  LOYALTY: 'LOYALTY',
};
export const advantageNetworkTypes = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};
export const kycStatusType = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  SEND: 'SEND',
  REVALIDATION: 'REVALIDATION',
  SEND_WITH_OBSERVATIONS: 'SEND_WITH_OBSERVATIONS',
};
