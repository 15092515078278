import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from '../modal/modal';
import Button from '../button/button';
import { InputText } from '../inputs';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { approveReservation, postDispute } from '../../../services';
import DobleFormButton from '../formButton/dobleFormButton';
import Toast from '../toast/toast';

const ModalPendingReservations = ({ open, onClose, reservation, callback }) => {
  const welletContext = useContext(WelletContext);
  const methods = useForm();
  const reff = useRef();
  const reffButton = useRef();
  const { orderId } = reservation ?? {};
  const [showToast, setShowToast] = useState(false);

  //Queries
  const handleReservationQueryMutate = useMutation({
    mutationFn: (body) => {
      const btn = reffButton.current;
      return btn === 'APROBAR'
        ? approveReservation(welletContext, orderId, body)
        : postDispute(welletContext, orderId, body);
    },
    onSettled: () => {
      setShowToast(true);
    },
  });

  //Inputs
  const disputeComment = methods.watch('disputeComment');
  const userComment = methods.watch('userComment');
  //Functions
  const onSubmit = (data) => {
    const body = {
      comments: data?.disputeComment,
      userComments: data?.userComment,
    };

    if (reffButton.current === 'DISPUTAR' && (!disputeComment || !userComment)) {
      if (!disputeComment) {
        methods.setError('disputeComment', { type: 'custom', message: 'Este campo es requerido' });
      }
      if (!userComment) {
        methods.setError('userComment', { type: 'custom', message: 'Este campo es requerido' });
      }
    } else {
      methods.clearErrors('disputeComment');
      methods.clearErrors('userComment');
      handleReservationQueryMutate.mutate(body);
    }
  };
  const handleRedirect = (string) => {
    reffButton.current = string;
  };
  const toastCallback = () => {
    setShowToast(false);
    methods.reset();
    callback();
    onClose();
  };

  return (
    <>
      {showToast && (
        <Toast
          message={`${
            handleReservationQueryMutate.isSuccess
              ? `La reserva se ha ${
                  reffButton.current === 'DISPUTAR' ? 'disputado' : 'aprobado'
                } correctamente`
              : `Hubo un error al ${
                  reffButton.current === 'DISPUTAR' ? 'dsipautar' : 'aprobar'
                } la reserva`
          }`}
          type={`${handleReservationQueryMutate.isSuccess ? 'SUCCESS' : 'ERROR'}`}
          onCallback={toastCallback}
        />
      )}
      <Modal
        title={'Acciones'}
        open={open}
        nested={true}
        maxWidth='600px'
        onClose={onClose}
        closeIcon={true}
        //handleSubmit={onSubmit}
        customButtons={
          <DobleFormButton
            mutation={handleReservationQueryMutate}
            textOne={'DISPUTAR'}
            textTwo={'APROBAR'}
            reff={reffButton}
            handleRedirect={handleRedirect}
            submit={methods.handleSubmit(onSubmit)}
          />
        }
      >
        <FormProvider {...methods}>
          <form>
            <div className='modal-background'>
              <div className='pt-2'>
                <InputText
                  maxLength={512}
                  label={'Comentario Interno'}
                  name={'disputeComment'}
                  placeholder={'Ingresa un comentario interno'}
                  ref={reff}
                  // isRequired={reffButton.current === 'DISPUTAR'}
                />
              </div>
              <div className='pt-2'>
                <InputText
                  maxLength={512}
                  label={'Comentario Concierge'}
                  name={'userComment'}
                  placeholder={'Ingresa un comentario para el concierge'}
                  ref={reff}
                  // isRequired={reffButton.current === 'DISPUTAR'}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default ModalPendingReservations;
