import React, { useContext, useState } from 'react';
import { Button, Modal, Toast } from '../../../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import {  Show } from '../../../../components';
import { useMutation, useQueryClient } from 'react-query';
import { resetKyc } from '../../../../services';
import { WelletContext } from '../../../../context/wellet/welletContext';
import { useLocation, useParams } from 'react-router-dom';

const ApprovedKycModal = ({setkycStatus}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.profile.documentation.rowKycStatus.modal.approved',
  });
  const welletContext = useContext(WelletContext);
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  // Refetch
  const queryClient = useQueryClient();
  const handleRefetch = () => {
    setkycStatus("APPROVED");
    queryClient.refetchQueries(['kyc']);
  };
  // Mutation
  const handleKycMutation = useMutation({
    mutationFn: (body) => {
      const response = resetKyc(welletContext, body);
      return response;
    },
  });

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  const setApproved = () => {
    const body = {
      kyc: 'APPROVED',
      userId: id,
      message: '',
    };
    handleKycMutation.mutate(body);
  };
  const handleClose = () => {
    handleRefetch();
    closeModal();
  };
  const handleCloseError = () => {
    closeModal();
  };
  return (
    <>
      <Button
        className={'btn-wellet-primary text-truncate'}
        text={t('button')}
        onClick={openModal}
        size={'medium'}
        type='button'
      />
      <Modal
        title={t('title')}
        open={open}
        onClose={closeModal}
        closeIcon={false}
        maxWidth='550px'
        closeOnDocumentClick={false}
        customButtons={
          <>
            <div className='d-flex justify-content-end'>
              <Button
                text={t('buttons.cancel')}
                disabled={handleKycMutation.isLoading}
                className={'btn-general btn-wellet-secondary white'}
                size={'medium'}
                onClick={closeModal}
                type='button'
              />
              <Button
                text={t('buttons.approved')}
                disabled={handleKycMutation.isLoading}
                className={'ml-2 btn-general btn-wellet-primary'}
                size={'medium'}
                onClick={setApproved}
                type='button'
              />
            </div>
          </>
        }
      >
        <div className='py-4'>{t('text')}</div>
      </Modal>
      <Show.When isTrue={handleKycMutation.isError}>
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleCloseError();
          }}
        />
      </Show.When>
      <Show.When isTrue={handleKycMutation.isSuccess}>
        <Toast
          message={t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default ApprovedKycModal;
