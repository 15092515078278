import React from 'react';
import styles from './disputeTimer.module.css';
import { Loading } from '../../uiComponents/common';
import { useExpirationTimer } from '../../hooks';
import { useTranslation } from 'react-i18next';

const DisputeTimer = ({ expiresIn, showLoading = false }) => {
  const { loading, days, hours, minutes, seconds } = useExpirationTimer(expiresIn);
  const { t } = useTranslation('translation', { keyPrefix: 'components.disputeTimer' });

  const formatTimeLeft = () => {
    if (days > 0) return t('timeLeft.days', { count: days });
    if (hours > 0) return t('timeLeft.hours', { count: hours });
    if (minutes > 0) return t('timeLeft.minutes', { count: minutes });
    if (seconds > 0) return t('timeLeft.seconds', { count: seconds });
    return t('timeLeft.expired');
  };

  const timeLeft = formatTimeLeft();

  return (
    <div>
      {!loading ? (
        <div className={styles.disputeTimerContainer}>{timeLeft}</div>
      ) : showLoading ? (
        <div className='d-flex ml-2'>
          <Loading global={false} />
        </div>
      ) : null}
    </div>
  );
};

export default DisputeTimer;
