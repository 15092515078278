import React, { useContext } from 'react';
import GraphicsGrid from './grapichsGrid/graphicsGrid';
import { Loading } from '../../uiComponents/common';
import { useQuery } from 'react-query';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  getAvgCommissions,
  getAvgIncomes,
  getAvgPeople,
  getEvents,
  getOccassions,
  getSummaryCards,
  getTimeline,
  getTypeReservation,
} from '../../services';
import FilterCard from '../filterCard/filterCard';
import Show from '../show/show';
import SuccessfullCampaigns from './tabels/successfullCampaigns';
import { useTranslation } from 'react-i18next';
import TopConcierges from './tabels/topConcierges';

const CampaignsStatistics = ({ show }) => {
  const { t } = useTranslation('translation', { keyPrefix: `screens.campaigns.statistics` });
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const selectedDates = useSelector((state) => state.app.selectedDates);

  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };

  const reservationTypeQuery = useQuery({
    queryKey: ['get-reservation-type', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      if (organization.isMarket) {
        return getTypeReservation(welletContext, organization.marketId, null, body);
      } else {
        return getTypeReservation(welletContext, organization.marketId, organization.id, body);
      }
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Reservation Type Graphics
  const occassionQuery = useQuery({
    queryKey: ['get-occasions', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      return getOccassions(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        body,
      );
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Reservation Occasions Graphics
  const eventsQuery = useQuery({
    queryKey: ['get-events', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      return getEvents(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        body,
      );
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Access through links  Graphics
  const timelineQuery = useQuery({
    queryKey: ['get-timeline', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      return getTimeline(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        body,
      );
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Timeline Graphics
  const summaryCardsQuery = useQuery({
    queryKey: ['get-summary-cards', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
      };
      return getSummaryCards(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        body,
      );
    },

    ...defaultQueryOptions,
    enabled: show,
  }); // Summary Cards
  const avgIncomeQuery = useQuery({
    queryKey: ['get-avg-incomes', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
      };
      return getAvgIncomes(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        body,
      );
    },

    ...defaultQueryOptions,
    enabled: show,
  }); //Average income per campaign Graphics
  const avgCommissionsQuery = useQuery({
    queryKey: ['get-avg-comissions', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
      };
      return getAvgCommissions(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        body,
      );
    },
    ...defaultQueryOptions,
    enabled: show,
  }); //Average commission per campaign Graphics
  const avgPaxQuery = useQuery({
    queryKey: ['get-avg-people', selectedFormattedDates, organization],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
      };
      return getAvgPeople(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        body,
      );
    },
    ...defaultQueryOptions,
    enabled: show,
  }); // People (who attended the reservation) Graphics

  // Functions
  function calculatePercentageVisit(reservationsArray) {
    let completedReservations = 0;
    let incompletedReservations = 0;

    reservationsArray.forEach((item) => {
      if (item.key === 'RESERVATIONS_COMPLETED') {
        completedReservations = item.value;
      } else if (item.key === 'RESERVATIONS_INCOMPLETED') {
        incompletedReservations = item.value;
      }
    });

    let clickCounts = completedReservations + incompletedReservations;
    let percentageVisit = 0;

    if (clickCounts > 0) {
      percentageVisit = ((completedReservations / clickCounts) * 100).toFixed(3);
    }

    return `${parseFloat(percentageVisit)}%`;
  }
  return (
    <>
      <Show>
        <Show.When
          isTrue={
            reservationTypeQuery.isLoading ||
            occassionQuery.isLoading ||
            avgIncomeQuery.isLoading ||
            avgCommissionsQuery.isLoading ||
            avgPaxQuery.isLoading ||
            eventsQuery.isLoading ||
            timelineQuery.isLoading ||
            summaryCardsQuery.isLoading
          }
        >
          <Loading />
        </Show.When>
        <Show.Else>
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('cardsFilter.campaigns')}
              quantity={summaryCardsQuery.data?.data?.totalCampaignsCreated}
              onClick={() => null}
              className=' col-auto'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.reservation')}
              quantity={summaryCardsQuery.data?.data?.totalReservations}
              onClick={() => null}
              className=' col-auto'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.incomes')}
              isMoney
              quantity={summaryCardsQuery.data?.data?.totalIncomes}
              onClick={() => null}
              className='text-truncate col-auto'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.commissions')}
              isMoney
              quantity={summaryCardsQuery.data?.data?.totalCommissions}
              onClick={() => null}
              className='text-truncate col-auto'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.commissionsAverage')}
              quantity={
                summaryCardsQuery.data?.data?.totalAvgCommissions
                  ? summaryCardsQuery.data?.data?.totalAvgCommissions + '%'
                  : 0 + '%'
              }
              onClick={() => null}
              className=' col-auto'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.clicks')}
              quantity={summaryCardsQuery.data?.data?.totalEvents}
              onClick={() => null}
              className=' col-auto'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.conversions')}
              quantity={calculatePercentageVisit(eventsQuery.data?.data ?? [])}
              onClick={() => null}
              className=' col-auto'
              isSelected={false}
            />
          </div>
          <GraphicsGrid
            mode={'GENERAL'}
            barGraphicData={reservationTypeQuery.data?.data}
            occassionsPie={occassionQuery.data?.data}
            eventsPie={eventsQuery.data?.data}
            timeline={timelineQuery.data?.data}
            avgIncomes={avgIncomeQuery.data?.data}
            avgCommissions={avgCommissionsQuery.data?.data}
            avgPax={avgPaxQuery.data?.data}
          />
          <div className='row mt-5'>
            <div className='col-12 col-md-6'>
              <SuccessfullCampaigns show={show} />
            </div>
            <div className='col-12 col-md-6'>
              <TopConcierges show={show} />
            </div>
          </div>
        </Show.Else>
      </Show>
    </>
  );
};

export default CampaignsStatistics;
