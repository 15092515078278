import React, { useEffect } from 'react';
import './table.css';
import { usePagination } from '../../context/paginationContext';
import Pagination from '../pagination/pagination';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const DragDropTableOLD = ({
  data,
  columns,
  renderRow,
  tableKey,
  handleDragEnd,
  totalP = data?.length,
  showText = true,
  hasPagination = true,
  perPage = 10,
  disableDrag = false
}) => {
  const itemsPerPage = perPage;
  const totalItems = totalP;
  const { paginationState, setPagination } = usePagination();
  // Verifica si hay un estado de paginación para esta tabla en el contexto
  const tablePagination = paginationState[tableKey] || {
    currentPage: 1,
    totalPages: Math.ceil(totalItems / itemsPerPage),
  };

  const { currentPage, totalPages } = tablePagination;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const dataToDisplay = hasPagination ? data.slice(startIndex, endIndex) : data;

  const handlePageChange = (newPage) => {
    setPagination(tableKey, { currentPage: newPage, totalPages: totalPages });
  };
  useEffect(() => {
    const currentTableKey = Object.keys(paginationState);
    if (tableKey !== currentTableKey[0]) {
      setPagination(tableKey, { currentPage: 1, totalPages: totalPages });
    }
  }, [tableKey]);

  return (
    <div className='w-100'>
      <div className='datatable-scroll '>
        <DragDropContext onDragEnd={handleDragEnd}>
          <table
            className='table datatable-basic dataTable no-footer'
            role='grid'
            aria-describedby='DataTables_Table_0_info'
          >
            <thead className=''>
              <tr className=''>
                {columns.map((h, index) => {
                  return index === columns.length - 1 ? (
                    <th
                      className='sorting text-left text-sidebar-link ctm-text-small font-weight-bold'
                      tabIndex='0'
                      aria-controls='DataTables_Table_0'
                      rowSpan='1'
                      colSpan='1'
                      aria-label={h}
                      key={index}
                    >
                      {h}
                    </th>
                  ) : (
                    <th
                      className='sorting text-left text-sidebar-link font-weight-bold ctm-text-small'
                      tabIndex='0'
                      aria-controls='DataTables_Table_0'
                      rowSpan='1'
                      colSpan='1'
                      aria-label={h}
                      key={index}
                    >
                      {h}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <Droppable droppableId='tbody'>
              {(provided) => (
                <tbody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {dataToDisplay.map((rowData, index) => (
                    <Draggable index={index} key={index} draggableId={String(index)} isDragDisabled={disableDrag}>
                      {(provided) => renderRow(rowData, index, provided)}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
      <div className='datatable-footer '>
        {/* Utiliza handlePageChange para manejar el cambio de página */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages ? totalPages : 0}
          onPageChange={handlePageChange}
          showText={showText}
        />
      </div>
    </div>
  );
};

export default DragDropTableOLD;