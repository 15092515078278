export const getFAQS = async (welletContext, limit, skip) => {
  return await welletContext.apis.admin.post(`/api/FAQ/all`, {
    limit,
    skip,
  });
};
export const getFAQById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/FAQ/${id}`);
};

export const postFAQ = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/FAQ`, body);
};

export const putFAQ = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/FAQ`, body);
};

export const postFAQPriority = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/FAQ/priority`, body);
};
export const deleteFAQ = async (welletContext, id) => {
  return await welletContext.apis.admin.delete(`/api/FAQ/${id}`);
};

