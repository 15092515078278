import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormButton, Loading, Tabs, Toast } from '../../../uiComponents/common';
import { useMutation, useQuery } from 'react-query';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCallcenterSchedules,
  getSchedules,
  putCallcenterSchedules,
  putSchedules,
} from '../../../services';
import { generateRandomId } from '../../../helpers/forms';
import { useSelector } from 'react-redux';
import CreateSchedule from './createSchedule';
import { useTranslation } from 'react-i18next';
import useGenericTranslation from '../../../hooks/useGenericTranslation';

const SchedulesForm = ({ sidebar = false, callcenter = false }) => {
  const lastClickedButton = useRef(null);
  const methods = useForm();
  const welletContext = useContext(WelletContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.callCenter' });
  const { getClientTranslation } = useGenericTranslation();
  const [schedule, setSchedule] = useState(null);
  const [isRedirect, setIsRedirect] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [tabs, setTabs] = useState([
    { isActive: true, title: getClientTranslation('title.singular'), type: 'RESTAURANT' },
    { isActive: false, title: 'Areas VIP ', type: 'AREA' },
    { isActive: false, title: t('group'), type: 'GROUP' },
  ]);

  // Functions
  const formattedApiData = (data) => {
    const getDayIndex = (day) => {
      const daysOfWeek = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ];
      return daysOfWeek.indexOf(day.toLowerCase());
    };
    const format = (apiData) => {
      const newData = apiData?.map((data) => {
        return {
          day: data.weekday,
          schedule: data?.times?.map((t) => ({
            startTime: t.startTime,
            endTime: t.endTime,
            id: generateRandomId(),
          })),
        };
      });

      return newData;
    };

    let dataFormatted = format(data);
    dataFormatted.sort((a, b) => getDayIndex(a.day) - getDayIndex(b.day));

    return dataFormatted;
  };
  const onSubmit = () => {
    function transformToTimesArray(originalArray) {
      const timesArray = [];

      originalArray.forEach((dayEntry) => {
        const { day, schedule } = dayEntry;

        schedule.forEach((timeSlot) => {
          const { startTime, endTime } = timeSlot;
          if (methods.getValues(`open_${day}`)) {
            timesArray?.push({
              weekday: day,
              startTime: {
                hour: startTime?.hour,
                minutes: startTime?.minutes,
              },
              endTime: {
                hour: endTime?.hour,
                minutes: endTime?.minutes,
              },
            });
          }
        });
      });

      return timesArray;
    }

    const timesFormatted = transformToTimesArray(schedule);

    const body = {
      id: 0,
      [sidebar ? 'marketId' : 'showId']: sidebar ? organization.marketId : Number(id),
      times: timesFormatted,
    };

    if (!sidebar) {
      body['reservationType'] = tabs.find((t) => t.isActive).type;
    }

    schedulesMutation.mutate(body);
  };
  const addSchedule = useCallback(
    (info) => {
      const { day, startTime, endTime, id } = info;
      const newInfo = { startTime, endTime, id: id };
      setSchedule((prevSchedule) => {
        return prevSchedule.map((item) => {
          return item.day === day ? { ...item, schedule: [...item.schedule, newInfo] } : item;
        });
      });
      methods.setValue(`startTime_${day}_${id}`, `${startTime.hour}:${startTime.minutes}`);
      methods.setValue(`endTime_${day}_${id}`, `${endTime.hour}:${endTime.minutes}`);
    },
    [setSchedule, methods],
  );
  const removeSchedule = useCallback(
    (day, id) => {
      setSchedule((prevSchedule) => {
        return prevSchedule.map((item) => {
          if (item.day === day) {
            const updatedSchedule = item.schedule.filter((schedule) => schedule.id !== id);
            return { ...item, schedule: updatedSchedule };
          }
          return item;
        });
      });

      // Borrar los valores de los inputs relacionados con el horario eliminado
      methods.setValue(`startTime_${day}_${id}`, '');
      methods.setValue(`endTime_${day}_${id}`, '');
    },
    [setSchedule, methods],
  );
  const toggleEditing = useCallback(
    (day, id, newInfo) => {
      setSchedule((prevSchedule) => {
        return prevSchedule.map((item) => {
          if (item.day === day) {
            const updatedSchedule = item.schedule.map((time) => {
              if (time.id === id) {
                return { ...time, endTime: newInfo.endTime, startTime: newInfo.startTime };
              } else {
                return time;
              }
            });

            return { ...item, schedule: updatedSchedule };
          }
          return item;
        });
      });
    },
    [setSchedule],
  );

  const handleToastCallback = () => {
    if (isRedirect) {
      methods.reset();
      setShowToast(false);
      navigate(`/restaurantes`, { replace: true });
    } else {
      setShowToast(false);
    }
    schedulesMutation.reset();
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };
  const clearForm = () => {
    if (callcenter) {
      navigate('/settings');
    } else {
      navigate(`/restaurantes`);
    }
    schedulesMutation.reset();
    methods.reset();
  };
  // Mutation

  const marketId = useSelector((state) => state.app.selectedOrganization.marketId);

  const schedulesQuery = useQuery({
    queryKey: ['schedules', id, tabs],
    queryFn: () =>
      sidebar
        ? getCallcenterSchedules(welletContext, marketId)
        : getSchedules(welletContext, id, tabs.find((t) => t.isActive).type),
    onSuccess: (data) => {
      let newData = formattedApiData(data.data);
      setSchedule(newData);
      newData.forEach((time) => {
        let day = time.day;
        if (time.schedule.length > 0) {
          methods.setValue(`open_${day}`, true);
          time.schedule.forEach((t) => {
            methods.setValue(
              `startTime_${day}_${t.id}`,
              `${t.startTime.hour}:${t.startTime.minutes}`,
            );
            methods.setValue(`endTime_${day}_${t.id}`, `${t.endTime.hour}:${t.endTime.minutes}`);
          });
        } else {
          methods.setValue(`open_${day}`, false);
        }
      });
      schedulesMutation.reset();
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const schedulesMutation = useMutation({
    mutationFn: (body) => {
      return sidebar
        ? putCallcenterSchedules(welletContext, id, body)
        : putSchedules(welletContext, id, body);
    },
    onSuccess: () => {
      setShowToast(true);
      schedulesMutation.reset();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    return () => {
      schedulesQuery.remove();
    };
  }, []);

  const handleTabs = (type) => {
    const updatedTabs = tabs.map((tab) => {
      if (tab.type === type) {
        return { ...tab, isActive: true };
      } else {
        return { ...tab, isActive: false };
      }
    });
    setTabs(updatedTabs);
  };
  return (
    <>
      {showToast && (
        <Toast
          message={t('toastSuccess')}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {schedulesMutation.isError && (
        <Toast message='Ocurrio un error' type='ERROR' onCallback={handleToastCallback} />
      )}
      {!sidebar && !callcenter ? <Tabs onClick={handleTabs} options={tabs} /> : null}
      <FormProvider {...methods}>
        <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
          {schedulesQuery.isLoading ? (
            <Loading />
          ) : (
            <div className='row m-0 '>
              {schedule &&
                schedule?.map((d, idx) => (
                  <CreateSchedule
                    day={d}
                    key={idx}
                    idx={idx}
                    methods={methods}
                    addSchedule={addSchedule}
                    removeSchedule={removeSchedule}
                    toggleEditing={toggleEditing}
                  />
                ))}
              <div className='col-8 px-0 pt-3'>
                <FormButton
                  mutation={schedulesMutation}
                  reff={lastClickedButton}
                  handleRedirect={handleRedirect}
                  clearForm={clearForm}
                  showSendAndClose={false}
                  isDisabled={schedulesMutation.isLoading || schedulesMutation.isSuccess}
                />
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default SchedulesForm;
