import './iconButton.css';
import styles from './iconButton.module.css';
const IconButton = ({ icon, onClick, classNameIcon, notification = false, isActive = false }) => {
  return (
    <button
      type='button'
      className={`btn ${
        isActive ? 'icon-button-active' : 'btn-outline-light'
      } border-transparent btn-icon btn-sm  ${classNameIcon}`}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        {icon}
        {notification && <span className={styles.badgeNotification}></span>}
      </div>
    </button>
  );
};
export default IconButton;
