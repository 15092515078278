import { useContext, useState } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useCreateBreadCrumbs, useLanguageSwitcher } from '../../hooks';
import { tabsTypes } from './types';
import { useMutation, useQuery } from 'react-query';
import { getPolicies, putPolicies } from './services';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { PageHeader, Show } from '../../components';
import {
  FormButton,
  FormHeader,
  Loading,
  PageError,
  Tabs,
  TextEditor,
  Toast,
} from '../../uiComponents/common';
import FormRow from '../../components/forms/formRow';
import { useSelector } from 'react-redux';

const UsagePolicies = () => {
  const welletContext = useContext(WelletContext);

  const { t } = useTranslation('translation', { keyPrefix: 'screens.usagePolicies' });
  const navigate = useNavigate();
  // Form
  const methods = useForm();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { languages, lngErrors } = useLanguageSwitcher(methods);
  const [activeTab, setActiveTab] = useState(tabsTypes.POLICY);

  const tabs = [
    {
      isActive: activeTab === tabsTypes.POLICY,
      title: t('tabs.policy'),
      type: tabsTypes.POLICY,
    },
    {
      isActive: activeTab === tabsTypes.TERMS,
      title: t('tabs.terms'),
      type: tabsTypes.TERMS,
    },
  ];

  const handleTabs = (type) => {
    setActiveTab(type);
  };

  // Querys
  const policiesQuery = useQuery({
    queryKey: ['usage-policies', organization.marketId],
    queryFn: async () => {
      const data = await getPolicies(welletContext, organization.marketId);
      return data;
    },
    onSuccess: (data) => {
      if (data?.data?.length === 0) {
        methods.reset({
          termsAndConditions_es: null,
          termsAndConditions_en: null,
          privacyStatement_es: null,
          privacyStatement_en: null,
        });
      } else {
        let dataFormatted = formatData(data?.data);
        methods.reset(dataFormatted);
      }
    },
    ...defaultQueryOptions,
  });

  // Mutations
  const policiesMutation = useMutation({
    mutationFn: (body) => {
      putPolicies(welletContext, body, organization.marketId);
    },
  });

  // Functions
  function buildDataArray(languages, textData) {
    return languages.map((lang) => {
      const shortForm = lang.shortForm;

      return {
        termsAndConditions: textData[`termsAndConditions_${shortForm}`] || '',
        privacyStatement: textData[`privacyStatement_${shortForm}`] || '',
        language: shortForm,
      };
    });
  }
  const formatData = (data) => {
    let formattedData = {};

    data.forEach((item) => {
      const lang = item.language;

      formattedData[`termsAndConditions_${lang}`] = item.termsAndConditions;
      formattedData[`privacyStatement_${lang}`] = item.privacyStatement;
    });

    return formattedData;
  };
  const onSubmit = (data) => {
    let body;
    body = buildDataArray(languages, data);
    policiesMutation.mutate(body);
  };

  const handleRedirectCancel = () => {
    navigate('/settings');
  };

  useCreateBreadCrumbs([
    { name: t('breadcrumbs.prev'), path: '/settings' },
    { name: t('breadcrumbs.current') },
  ]);

  return (
    <>
      <PageHeader border={false} title={t('title')} />
      <Tabs className='mb-4' options={tabs} onClick={handleTabs} />
      <Show>
        <Show.When isTrue={policiesQuery.isLoading || policiesQuery.isFetching}>
          <Loading />
        </Show.When>
        <Show.When isTrue={policiesQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <FormProvider {...methods}>
            <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
              <Show>
                <Show.When isTrue={activeTab === tabsTypes.TERMS}>
                  <FormHeader
                    title={t('rowTermsAndConditions.title')}
                    subtitle={t('rowTermsAndConditions.description')}
                    border={true}
                    endComponent={
                      <FormButton
                        handleRedirect={() => {}}
                        clearForm={handleRedirectCancel}
                        all={false}
                        nextStep={true}
                        isDisabled={policiesMutation.isLoading || policiesMutation.isSuccess}
                      />
                    }
                  />
                  <FormRow
                    text={t('rowTermsAndConditions.title')}
                    isFirst={false}
                    withBorder={false}
                    endComponent={
                      <div className='col-12 col-md-6'>
                        <div className='row no-gutters'>
                          <div className='col-12 d-flex'>
                            <div
                              className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                            ></div>
                            <TextEditor
                              options={['inline', 'link', 'list', 'remove']}
                              className='w-100'
                              name={`termsAndConditions_es`}
                              customErrors={lngErrors}
                            />
                          </div>
                          <div className='col-12 d-flex'>
                            <div
                              className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                            ></div>
                            <TextEditor
                              options={['inline', 'link', 'list', 'remove']}
                              className='w-100'
                              name={`termsAndConditions_en`}
                              customErrors={lngErrors}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Show.When>
                <Show.When isTrue={activeTab === tabsTypes.POLICY}>
                  <FormHeader
                    title={t('rowPolicies.title')}
                    subtitle={t('rowPolicies.description')}
                    border={true}
                    endComponent={
                      <FormButton
                        handleRedirect={() => {}}
                        clearForm={handleRedirectCancel}
                        all={false}
                        nextStep={true}
                        isDisabled={policiesMutation.isLoading || policiesMutation.isSuccess}
                      />
                    }
                  />
                  <FormRow
                    text={t('rowPolicies.title')}
                    withBorder={false}
                    endComponent={
                      <div className='col-12 col-md-6'>
                        <div className='row no-gutters'>
                          <div className='col-12 d-flex'>
                            <div
                              className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                            ></div>
                            <TextEditor
                              options={['inline', 'link', 'list', 'remove']}
                              className='w-100'
                              name={`privacyStatement_es`}
                              customErrors={lngErrors}
                            />
                          </div>
                          <div className='col-12 d-flex'>
                            <div
                              className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                            ></div>
                            <TextEditor
                              options={['inline', 'link', 'list', 'remove']}
                              className='w-100'
                              name={`privacyStatement_en`}
                              customErrors={lngErrors}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Show.When>
              </Show>
            </form>
          </FormProvider>

          <Show.When isTrue={policiesMutation.isSuccess}>
            <Toast
              message={t('toast.success')}
              type={`SUCCESS`}
              onCallback={() => policiesMutation.reset()}
            />
          </Show.When>
          <Show.When isTrue={policiesMutation.isError}>
            <Toast
              message={t('toast.errors')}
              type={`ERROR`}
              onCallback={() => policiesMutation.reset()}
            />
          </Show.When>
        </Show.Else>
      </Show>
    </>
  );
};

export default UsagePolicies;
