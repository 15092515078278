import React from 'react';

export default function ChevronIcon({ size = '16px', color = '#ccc', rotation = 0 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 32 32'
      version='1.1'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
    >
      <g>
        <path
          fill={color}
          d='M 10.636719 6.617188 L 10 7.21875 L 14.6875 11.59375 C 17.261719 14 19.375 15.980469 19.375 16 C 19.375 16.019531 17.269531 18 14.6875 20.40625 L 10 24.78125 L 10.644531 25.386719 L 11.28125 25.988281 L 11.582031 25.730469 C 12.113281 25.261719 21.988281 16.03125 21.988281 16 C 21.988281 15.96875 12.105469 6.730469 11.582031 6.273438 L 11.28125 6.011719 Z M 10.636719 6.617188 '
        />
      </g>
    </svg>
  );
}
