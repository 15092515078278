import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading, Modal, Toast } from '../../../../uiComponents/common'; // Asegúrate de que InputVideo se importe correctamente
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { deleteVideo } from '../../../../services';

const DeleteVideo = ({ videoId, open, setOpen, refetchQuery }) => {
  const methods = useForm();
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'components.galleryVideo' });

  const deleteMutation = useMutation({
    mutationFn: () => {
      return deleteVideo(welletContext, videoId);
    },
    onSuccess: () => {
      refetchQuery();
    },
  });

  const handleClose = () => {
    setOpen(false);
    deleteMutation.reset()
  };

  const onSubmit = () => {
    deleteMutation.mutate();
  };

  return (
    <>
      <FormProvider {...methods}>
        
          <Modal
            textCancel={t('cancel')}
            textSubmit={t('delete')}
            idModal={'videoUploader'}
            title={t('title.delete')}
            btnType='submit'
            maxWidth='450px'
            onClose={() => {
              setOpen(false);
            }}
            open={open}
            isLoading={deleteMutation.isLoading || deleteMutation.isSuccess}
            handleSubmit={methods.handleSubmit(onSubmit)}
          >
            {deleteMutation.isLoading || deleteMutation.isSuccess ? (
              <div className='p-4'>
                <Loading />
              </div>
            ) : (
              <div className='text-left mt-2 mb-3'>{t('ask')}</div>
            )}
          </Modal>
       
      </FormProvider>
      {deleteMutation.isError && (
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
      {deleteMutation.isSuccess && (
        <Toast
          message={t('toast.deleteSuccess')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default DeleteVideo;
