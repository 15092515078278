import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateBreadCrumbs, usePagination } from '../../hooks';
import { Button, IconButton, Loading, Slider } from '../../uiComponents/common';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../context/wellet/welletContext';
import { DragDropTable, PageHeader,  TableCell } from '../../components';
import FaqModal from './modals/faqModal';
import { getFAQS, postFAQPriority } from '../../services';
import { FwTrashIcon, MenuBurger, PencilIcon } from '../../uiComponents/icons';
import Popup from 'reactjs-popup';
import DeleteFAQ from './modals/deleteFaq';

const Faq = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.faq' });
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const [open, setOpen] = useState(false);
  const [deleteFAQ, setDeleteFAQ] = useState('');
  const [faq, setFaq] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [currentDragTable, setCurrentDragTable] = useState([]);

  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = showAll ? null : 10;
  let skip = showAll ? null : 10 * ((currentPage ? currentPage : 1) - 1);

  const faqQuery = useQuery({
    queryKey: ['faq', organization.marketId, limit, skip],
    queryFn: () => getFAQS(welletContext, limit, skip),
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.totalRegisters);
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const faqPriorityMutation = useMutation({
    mutationFn: (body) => {
      return postFAQPriority(welletContext, body);
    },
    onSuccess: () => {
      faqQuery.refetch();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const queryClient = useQueryClient();

  const headerAdmin = [
    { title: '', value: '', filterable: false },
    { title: t('table.title'), value: '', filterable: false },
    { title: t('table.languages'), value: '', filterable: false },
    { title: t('table.description'), value: '', filterable: false },
    { title: t('table.link'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs([{name: t('settings'), path: '/settings' }, { name: t('title'), path: '/faq' }]);

  const handleDragEnd = (result) => {
    const currentData = queryClient.getQueryData(['faq', organization.marketId, limit, skip]);

    if (!currentData?.data || !result.destination) {
      return;
    }
    const items = [...currentData?.data?.items];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCurrentDragTable(items);
    faqPriorityMutation.mutate(items.map((item) => item.id));
    queryClient.setQueryData(['faq', organization.marketId, limit, skip], {
      data: { items: items },
    });
  };
  return (
    <div>
      {faqQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <PageHeader
            title={t('title')}
            marginBottom={false}
            endComponent={
              <Button
                size={'medium'}
                className={'btn-wellet-secondary'}
                text={t('btnCreate')}
                onClick={() => {
                  setFaq(null);
                  setOpen(true);
                }}
              />
            }
          />
          <div className='my-2 row align-items-center'>
            <Popup
              on={'hover'}
              trigger={
                <div>
                  <Slider
                    className={'col-auto'}
                    defaultChecked={showAll}
                    onClick={() => setShowAll(!showAll)}
                    label={t('priority')}
                  />
                </div>
              }
            >
              <div className='tooltipCard'>{t('priorityMessage')}</div>
            </Popup>
          </div>
          <DragDropTable
            data={faqQuery?.data?.data?.items ?? currentDragTable}
            columns={headerAdmin}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            showPagination={!showAll}
            disableDrag={!showAll}
            handleDragEnd={(results) => handleDragEnd(results)}
            renderRow={(faq, index, provided) => (
              <tr
                key={String(index)}
                className='not-hover'
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableCell style={{ width: '60px' }} type={'primary'}>
                  <div className='d-flex align-items-center justify-content-center'>
                    {showAll ? (
                      <div className='mr-1'>
                        <MenuBurger size='15px' color='currentColor' />
                      </div>
                    ) : (
                      <>
                        <div>
                          <IconButton
                            icon={<FwTrashIcon size='20px' />}
                            onClick={() => setDeleteFAQ(faq?.id)}
                          />
                          <IconButton
                            icon={<PencilIcon size='20px' />}
                            onClick={() => {
                              setFaq(faq);
                              setOpen(true);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell className={'fw-700 text-truncate'} style={{ maxWidth: '200px' }}>
                  {faq?.langFAQ[0]?.title}
                </TableCell>
                <TableCell>{faq?.defaultLanguage}</TableCell>
                <TableCell className={'text-truncate'} style={{ maxWidth: '250px' }}>
                  {faq?.langFAQ[0]?.description}
                </TableCell>
                <TableCell className={'text-truncate'} style={{ maxWidth: '250px' }}>
                  {faq?.langFAQ[0]?.videoUrl ? (
                    <a href={faq?.langFAQ[0]?.videoUrl} rel='noreferrer' target='_blank'>
                      {faq?.langFAQ[0]?.videoUrl}
                    </a>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </tr>
            )}
          />
          <DeleteFAQ
            faqID={deleteFAQ}
            open={deleteFAQ !== ''}
            setOpen={setDeleteFAQ}
            refetchQuery={faqQuery.refetch}
          />
          <FaqModal
            faq={faq}
            setFaq={setFaq}
            open={open}
            setOpen={setOpen}
            refetchQuery={faqQuery.refetch}
          />
        </>
      )}
    </div>
  );
};

export default Faq;
