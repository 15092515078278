export const getSettings = async (welletContext, id) => {
  try {
    const response = await welletContext.apis.admin.get(`api/MarketSetup/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};

export const putSettings = async (welletContext, body, id) => {
  return await welletContext.apis.admin.put(`api/MarketSetup/${id}`, body);
};
