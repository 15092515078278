export const getDisputeLiveFeed = async (
  welletContext,
  marketSetupId = null,
  orgId = null,
  dates = {
    from: null,
    to: null,
  },
  status,
  limit = null,
  skip = null,
  fieldFilter = null,
  dateType = null,
  userId,
  showId = [],
  citiesIds = [],
  brands,
  gateway,
  amount,
) => {
  const url = `/api/payment/${marketSetupId}/dispute/LiveFeed`;
  let showIdArray = showId.length === 0 ? null : showId;

  const body = {
    from: dates.from,
    to: dates.to,
    organizationId: orgId,
    filterField: fieldFilter,
    showId: showIdArray,
    limit,
    skip,
    status: status ? status : [],
    userId: [],
    sortBy: null,
    columnFilter: null,
    dateType,
    citiesIds,
    cardType: brands,
    typeGateway: gateway ? gateway : null,
    minAmount: amount?.min ? amount.min : null,
    maxAmount: amount?.max ? amount.max : null,
  };

  return await welletContext.apis.admin.post(url, body);
};

export const getDisputedPayment = async (welletContext, marketId, paymentId) => {
  return await welletContext.apis.admin.get(
    `api/payment/${marketId}/${paymentId}`,
  );
};
export const refutePayment = async (welletContext, body) => {
  return await welletContext.apis.tickets.post(
    `admin/associate/payment/dispute/refute`, body
  );
};

export const acceptDisputePayment = async (welletContext, paymentId, body) => {
  return await welletContext.apis.tickets.post(
    `admin/associate/payment/${paymentId}/dispute/accept`, body
  );
};
export const getFilePayment = async (welletContext, paymentId, body) => {
  return await welletContext.apis.tickets.post(
    `/admin/associate/payment/${paymentId}/file`, body
  );
};

export const lostDisputePayment = async (welletContext, paymentId, body) => {
  return await welletContext.apis.tickets.post(
    `admin/associate/payment/${paymentId}/lost`, body
  );
};
export const winDisputePayment = async (welletContext, paymentId, body) => {
  return await welletContext.apis.tickets.post(
    `admin/associate/payment/${paymentId}/win`, body
  );
};
export const sendToStripePayment = async (welletContext, paymentId, body) => {
  return await welletContext.apis.tickets.post(
    `admin/associate/payment/${paymentId}/stripe/review`, body
  );
};