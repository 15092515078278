import React from 'react';
import './half-circle-progress-bar.css';

const HalfCircleProgressBar = ({ percentage, text }) => {
  const rotate = 45 + (percentage * 180) / 100;

  return (
    <div className='half-circle-progress' style={{ width: '160px' }}>
      <div className='barOverflow'>
        <div className='bar' style={{ transform: `rotate(${rotate}deg)` }}></div>
      </div>
      <div className='progress-legend'>
        <div className='light-grey fs-12 mb-1'>{text}</div>
        <div className='progress-percentage'>{percentage}%</div>
      </div>
    </div>
  );
};

export default HalfCircleProgressBar;
