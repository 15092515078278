const FAQIcon = ({ size = '20px', color = 'currentColor' }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2335 4599 c-316 -33 -662 -156 -924 -329 -393 -258 -674 -627 -820
-1075 -72 -219 -101 -405 -101 -647 0 -348 78 -658 242 -969 l51 -95 -72 -412
c-53 -300 -71 -422 -66 -451 15 -88 87 -146 180 -144 40 0 113 27 380 137
l329 137 96 -50 c695 -362 1552 -287 2181 190 105 79 272 240 352 339 395 488
552 1130 425 1745 -63 310 -218 629 -429 887 -430 525 -1135 809 -1824 737z
m500 -314 c526 -93 952 -380 1229 -827 92 -148 176 -369 215 -560 182 -889
-333 -1767 -1195 -2037 -479 -151 -1015 -83 -1444 182 -50 31 -78 41 -120 45
-51 4 -71 -3 -288 -93 -129 -53 -235 -95 -237 -93 -1 2 20 136 46 298 27 162
49 310 49 329 0 24 -19 67 -59 140 -113 200 -194 443 -221 664 -18 142 -8 423
19 561 153 758 751 1311 1521 1406 105 12 377 4 485 -15z'
        />
        <path
          d='M2422 3505 c-136 -30 -286 -126 -369 -238 -112 -152 -162 -356 -113
-465 33 -74 130 -102 217 -63 52 24 73 62 85 159 15 127 68 208 170 263 58 31
70 34 148 33 72 0 93 -4 136 -26 28 -15 65 -38 81 -52 45 -38 92 -134 99 -205
10 -95 -20 -146 -186 -313 -76 -75 -154 -162 -175 -194 -55 -82 -94 -182 -107
-271 -10 -72 -10 -82 10 -126 17 -38 30 -52 64 -67 54 -25 102 -25 156 0 48
22 81 79 82 141 0 67 46 137 174 264 184 182 231 249 278 390 33 102 32 242
-4 343 -38 106 -83 177 -164 257 -151 151 -371 215 -582 170z'
        />
        <path
          d='M2495 1746 c-101 -44 -125 -178 -46 -257 65 -65 157 -65 222 0 124
124 -15 327 -176 257z'
        />
      </g>
    </svg>
  );
};

export default FAQIcon;
