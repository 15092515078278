import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getRPs } from '../../services';
import { defaultQueryOptionsFilter } from '../../utils/defaultQueryOptions';

export const useGetRPs = (marketId, options) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptionsFilter,
    ...options,
  };

  return useQuery(['rps', marketId], () => getRPs(welletContext, marketId), queryOptions);
};
