import React, { useContext, useState } from 'react';
import Show from '../show/show';
import { Button, Modal, Toast } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { postExecutePayment } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';

const ModalSendPayments = ({ id, callBack, refetch }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.paymentSummary' });
  const [openPayment, setOpenPayment] = useState(false);

  const batchExecuteMutation = useMutation({
    mutationFn: () => {
      return postExecutePayment(welletContext, id);
    },
  });

  const handleModal = () => {
    setOpenPayment((prevState) => !prevState);
  };
  const handleToastSucces = () => {
    handleModal();
    refetch?.();
    callBack?.();
    batchExecuteMutation.reset();
  };

  return (
    <>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
        text={t('btnExecute')}
        onClick={handleModal}
        size={'custom'}
      />
      <Show.When isTrue={openPayment}>
        <Modal
          open={openPayment !== null}
          handleSubmit={() => batchExecuteMutation.mutate()}
          isLoading={batchExecuteMutation.isLoading || batchExecuteMutation.isSuccess}
          showComment={true}
          onClose={handleModal}
          title={t('modal.title')}
          textCancel={t('modal.cancel')}
          children={<div className='text-left mt-2'>{t('modal.ask')}</div>}
          textSubmit={t('modal.submit')}
          maxWidth={'450px'}
        />
      </Show.When>
      <Show.When isTrue={batchExecuteMutation?.error}>
        <Toast
          message={t('toastError')}
          type='ERROR'
          onCallback={() => {
            batchExecuteMutation.reset();
          }}
        />
      </Show.When>
      <Show.When isTrue={batchExecuteMutation?.isSuccess}>
        <Toast message={t('toastSucces')} type='SUCCESS' onCallback={handleToastSucces} />
      </Show.When>
    </>
  );
};

export default ModalSendPayments;
