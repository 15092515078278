export const fetchsOrganizations = async (welletContext, url = '/setup') => {
  try {
    const response = await welletContext.apis.admin.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};

export const changePassword = async (welletContext, values) => {
  return await welletContext.apis.tickets.put(`users/password/change`, {
    newPassword: values.new_password,
    confirmation: values.confirm_password,
  });
};
export const resetPassword = async (welletContext, email, tenantId) => {
  return await welletContext.apis.tickets.post(`users/forgotpassword`, {
    email: email,
    tenantId: tenantId,
  });
};
export const validateCode = async (welletContext, email, tenantId, code) => {
  return await welletContext.apis.tickets.post(`users/forgotpassword/validate`, {
    email,
    tenantId,
    code,
  });
};

export const getAllLanguages = async (welletContext) => {
  return await welletContext.apis.admin.get(`api/Language/all`);
};
