import { createStaticRanges } from 'react-date-range';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
} from 'date-fns';
import { getDateFromOffset } from '../../helpers/dates';

const defineds = {
  startOfWeek: startOfWeek(getDateFromOffset(-300)),
  endOfWeek: endOfWeek(getDateFromOffset(-300)),
  startOfLastWeek: startOfWeek(addDays(getDateFromOffset(-300), -7)),
  endOfLastWeek: endOfWeek(addDays(getDateFromOffset(-300), -7)),
  startOfToday: startOfDay(getDateFromOffset(-300)),
  endOfToday: endOfDay(getDateFromOffset(-300)),
  startOfYesterday: startOfDay(addDays(getDateFromOffset(-300), -1)),
  endOfYesterday: endOfDay(addDays(getDateFromOffset(-300), -1)),
  startOfMonth: startOfMonth(getDateFromOffset(-300)),
  endOfMonth: endOfMonth(getDateFromOffset(-300)),
  startOfLastMonth: startOfMonth(addMonths(getDateFromOffset(-300), -1)),
  endOfLastMonth: endOfMonth(addMonths(getDateFromOffset(-300), -1)),
  startOfYear: startOfYear(getDateFromOffset(-300)),
  endOfYear: endOfYear(getDateFromOffset(-300)),
  startOfLastYear: startOfYear(addYears(getDateFromOffset(-300), -1)),
  endOfLastYear: endOfYear(addYears(getDateFromOffset(-300), -1)),
};

export const defaultStaticRanges = (t) =>
  createStaticRanges([
    {
      label: t('today'),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: t('yesterday'),
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },

    {
      label: t('thisWeek'),
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },
    {
      label: t('lastWeek'),
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    },
    {
      label: t('thisMonth'),
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: t('lastMonth'),
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
    {
      label: t('thisYear'),
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    },
    {
      label: t('lastYear'),
      range: () => ({
        startDate: defineds.startOfLastYear,
        endDate: defineds.endOfLastYear,
      }),
    },
  ]);
