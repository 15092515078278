import { enUS, es, pt, ru, de, zhCN, fr, hi } from 'date-fns/locale';
import { format } from 'date-fns';

export const getLocale2 = (language) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'es':
      return es;
    case 'pt':
      return pt;
    case 'ru':
      return ru;
    case 'de':
      return de;
    case 'cn':
      return zhCN;
    case 'fr':
      return fr;
    case 'hi':
      return hi;
    default:
      return enUS;
  }
};

export const alignTimezone = (date, reverse = false) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  let offset = date.getTimezoneOffset() * 60 * 1000;
  if (reverse) {
    offset *= -1;
  }
  return new Date(date.valueOf() + offset);
};

export const getWeekdays = (days) => {
  switch (days) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    default:
      return '';
  }
};

export const getToday = () => {
  const fechaHoy = new Date();

  const year = fechaHoy.getFullYear();
  let month = fechaHoy.getMonth() + 1;
  let day = fechaHoy.getDate();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  const fechaFormateada = `${year}-${month}-${day}`;

  return fechaFormateada;
};

export const getWeekAgo = (fechaHoy) => {
  const sevenDaysAgo = new Date(fechaHoy);

  return sevenDaysAgo.setDate(fechaHoy.getDate() - 7);
  // Borrar en algun momento
  // const year = sevenDaysAgo.getFullYear();
  // let month = sevenDaysAgo.getMonth() + 1;
  // let day = sevenDaysAgo.getDate();

  // month = month < 10 ? `0${month}` : month;
  // day = day < 10 ? `0${day}` : day;

  // const fechaFormateada = `${year}-${month}-${day}`;

  // return fechaFormateada;
};

export const getDateFromOffset = (offset = 0) => {
  let date = new Date();
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  date = date.setMinutes(date.getMinutes() + offset);
  return new Date(date);
};

export const getUiDate = (date, locale) => {
  if (date) return format(new Date(date), 'dd MMM yy - HH:mm', { locale }) + ' hs';
  else return '-';
};

export function translateDayToEnglish(day) {
  const weekdays = {
    lunes: 'monday',
    martes: 'tuesday',
    miércoles: 'wednesday',
    jueves: 'thursday',
    viernes: 'friday',
    sábado: 'saturday',
    domingo: 'sunday',
  };

  const lowercaseDay = day.toLowerCase();
  return weekdays[lowercaseDay] || day;
}

// Date Formatted
const capitalizeDate = (text) => {
  return text.replace(/\b(?!de|del|hs)\w+/gi, (match) => {
    return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
  });
};

export const daysToMinutes = (days) => {
  return days * 24 * 60; // Convert days directly to minutes
};
export const minutesToDays = (minutes) => {
  const days = minutes / (24 * 60); // Divide minutos por la cantidad de minutos en un día
  return days;
};

export const formatDateWeekday = (date = new Date(), locale) => {
  const dateFromatted = format(
    new Date(date),
    locale.code === 'es' ? `EEEE, dd 'de' MMM 'de' yyyy` : `EEEE, MMM dd, yyyy`,
    {
      locale,
    },
  );
  return capitalizeDate(dateFromatted);
};

const dateTypes = {
  en: {
    extraSmall: `dd MMM`,
    small: `MMM d, yyyy`,
    medium: `EEEE, d MMMM`,
    large: `MMMM d, yyyy`,
    extraLarge: `EEEE, MMMM  dd`,
  },
  es: {
    extraSmall: `MMM dd`,
    small: `d MMM, yyyy`,
    medium: "EEEE, d 'de' MMMM",
    large: `d 'de' MMMM 'de' yyyy`,
    extraLarge: `EEEE, dd 'de' MMMM`,
  },
  time: `HH:mm 'hr'`,
  // Add other languages as needed
};
const localesMap = new Map([
  ['en', enUS],
  ['es', es],
  ['pt', pt],
  ['ru', ru],
  ['de', de],
  ['cn', zhCN],
  ['fr', fr],
  ['hi', hi],
]);
export const getLocale = (language) => {
  return localesMap.get(language) || enUS;
};
export const formatDateTranslation = (date, language, formatType) => {
  if (!date) return '-';

  const locale = getLocale(language);

  const dateFormat = dateTypes[language][formatType] || dateTypes['en'][formatType];
  const timeFormat = dateTypes.time;

  const dateFormatted = format(new Date(date), dateFormat, { locale });
  const timeFormatted = format(new Date(date), timeFormat, { locale });

  const dateTimeFormatted = `${dateFormatted} - ${timeFormatted}`;

  return {
    date: dateFormatted,
    time: timeFormatted,
    dateTime: dateTimeFormatted,
  };
};

export const removeTimezoneOffset = (dateString) => {

  const tIndex = dateString?.indexOf('T');

  if (tIndex === -1) {
    throw new Error('El formato de fecha no es válido');
  }

  return dateString?.split('+')[0];
};