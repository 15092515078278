import React from 'react';

export default function Arrow({ size = '16px', color = 'currentColor', rotate = 0 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 13 12'
      fill='none'
      style={{ transform: `rotate(${rotate})`}}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.58301 0.993242L6.58301 11.0067M6.58301 11.0067L11.5898 5.99999M6.58301 11.0067L1.57626 5.99999'
        stroke={color}
        strokeWidth='1.7166'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}