import React, { useEffect } from 'react';
import { Badge, ContanierSection, Money, Subtitle } from '../../uiComponents/common';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import { Table, TableCell } from '../../components';
import { useTranslation } from 'react-i18next';
import { usePagination, useUser } from '../../hooks';
import { ModalReturnMoney } from '../../components/modals';
import { ItemIcon } from '../../uiComponents/icons';
import { hasAllowedRoles } from '../../helpers/roles';

const ReservationProductZone = ({ reservation, showPayment = true, showProduct = true }) => {
  const language = useSelector((state) => state.app.language);
  const { t } = useTranslation('translation', { keyPrefix: 'components.reservationProductZone' });
  const locale = getLocale(language);
  const { roles } = useUser();
  const { currentPage, setTotalAndReset } = usePagination(1, 1);
  const currency = useSelector((state) => state.app.selectedOrganization);

  useEffect(() => {
    if (reservation?.paymentDetail?.length > 0) {
      setTotalAndReset(reservation?.paymentDetail?.length);
    }
  }, []);

  if (!reservation.reservationDetail || reservation.reservationType === 'RESTAURANT') {
    return null;
  }

  const reservationPaymentDetail =
    reservation.paymentDetail !== null ? reservation?.paymentDetail[currentPage - 1] : null;

  function transformOrderItems(orderItems) {
    if (orderItems === null) return [];
    let transformedItems = [];

    orderItems.forEach((item) => {
      transformedItems.push(item);

      if (item.extraPaxs > 0) {
        transformedItems.push({
          productId: `${item.productId}-extra`,
          name: item.name,
          quantity: item.extraPaxs,
          paxsPerItem: 0,
          extraPaxs: 0,
          amountPerUnit: item.amountPerExtraPax,
          amountPerExtraPax: item.amountPerExtraPax,
          consumptionAmount: 0,
          totalAmountExtraPax: item.totalAmountExtraPax,
          isExtraPax: true,
        });
      }
    });

    return transformedItems;
  }

  const isWelletFinance = hasAllowedRoles(roles, ['wellet_finance']);

  const depositBadge = (type, isWelletFinance) => {
    switch (type) {
      case 'OK':
        return <Badge icon={'check'} type={'green'} text={t('guaranteeDeposit.paid')} />;
      case 'partially_refunded':
        return <Badge type={'red'} text={t('guaranteeDeposit.partiallyRefunded')} />;
      case 'REFUNDED':
        return <Badge type={'red'} text={t('guaranteeDeposit.refunded')} />;
      case 'DISPUTED':
        return <Badge type={'red'} text={t('guaranteeDeposit.disputed')} />;
      case 'DISPUTED_NOT_ACCEPTED':
        return <Badge type={'orange'} text={t('guaranteeDeposit.disputedNotAccepted')} />;
      case 'DEADLINE_MISSIED':
        return <Badge type={'orange'} text={t('guaranteeDeposit.disputedMissed')} />;
      case 'DISPUTED_LOST':
        return <Badge type={'red'} text={t('guaranteeDeposit.disputedLost')} />;
      case 'DISPUTED_WINNED':
        return <Badge type={'green'} text={t('guaranteeDeposit.disputedWinned')} />;
      case 'STRIPE_REVIEW':
        if (isWelletFinance) {
          return <Badge type={'violet'} text={t('guaranteeDeposit.disputedStripeReview')} />;
        } else return <Badge type={'orange'} text={t('guaranteeDeposit.disputedNotAccepted')} />;

      default:
        return <Badge text={type} />;
    }
  };

  const totalPayAmount = (paymentDetails) => {
    if (paymentDetails === null) return 0;
    return paymentDetails.reduce((total, item) => {
      return total + (item.amount || 0);
    }, 0);
  };

  return (
    <div className='container-section'>
      {showProduct ? (
        <div className='mb-3'>
          {reservation.reservationDetail.orderItems.map((o) => (
            <div className='mt-1' key={o.productId + 'P'}>
              <div className='row'>
                <div style={{ fontSize: '24px' }} className='col mt-1 text-truncate fw-700'>
                  {o.name} {o.paxsPerItem >= 2 && ` (${o.paxsPerItem} ${t('guests')})`} x{' '}
                  {o.quantity}
                </div>
              </div>
              {o.extraPaxs > 0 ? (
                <div className='row'>
                  <div style={{ fontSize: '24px' }} className='col mt-1 text-truncate fw-700'>
                    {o.name} - {t('extraGuests')} {o.extraPaxs}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
      {showPayment ? (
        <>
          <Subtitle marginBottom='mb-3' text={<div className='mt-3 mb-0'>{t('title')}</div>} />
          <ContanierSection maxWidth='100%'>
            <div>
              <Table
                hasPagination={false}
                borderTop={false}
                showPagination={false}
                columns={[
                  { title: 'Items' },
                  { title: t('qty') },
                  { title: t('price') },
                  { title: 'Total' },
                ]}
                pagination='normal'
                borderContainer={true}
                header='white'
                showText={false}
                data={transformOrderItems(reservation?.reservationDetail?.orderItems)}
                paddingTh={'padding-white-header'}
                renderRow={(item) => (
                  <>
                    <TableCell className={'fw-700'}>
                      <div className='d-flex align-items-center'>
                        <span className='mr-2'>
                          <ItemIcon />
                        </span>
                        {item.name} {item.isExtraPax && `- ${t('extraGuests')}`}{' '}
                        {item.paxsPerItem >= 2 && ` (${item.paxsPerItem} ${t('guests')})`}
                      </div>
                    </TableCell>
                    <TableCell style={{ width: '150px' }} className={'dark-grey'}>
                      {item.quantity}
                    </TableCell>
                    <TableCell className={'dark-grey'} style={{ width: '200px' }}>
                      <Money
                        currencyClass={'dark-grey'}
                        value={item.amountPerUnit}
                        showCurrencyEnd={false}
                        currencyCode={currency.currency}
                      />
                    </TableCell>
                    <TableCell className={'dark-grey'} style={{ width: '0px' }}>
                      <Money
                        currencyClass={'dark-grey'}
                        value={item.amountPerUnit * item.quantity}
                        showCurrencyEnd={false}
                        currencyCode={currency.currency}
                      />
                    </TableCell>
                  </>
                )}
                rowClass='not-hover'
              />
              <div className='row mt-3 px-3 dark-grey'>
                <div style={{ maxWidth: '200px', width: '100%' }} className='col-auto text-left'>
                  {t('credit')}
                </div>
                <div className='col  text-left'>
                  (
                  <Money
                    value={
                      reservation.reservationDetail.totalConsumptionAmount
                        ? reservation.reservationDetail.totalConsumptionAmount
                        : 0
                    }
                  />
                  )
                </div>

                <div className='col text-right'>{t('subTotal')}</div>
                <div style={{ maxWidth: '200px', width: '100%' }} className='col-auto  text-right'>
                  <Money value={reservation.reservationDetail.subTotal} />
                </div>
              </div>
              <div className='row mt-1 pr-3 dark-grey '>
                <div className='col  text-right'>{t('tip')}</div>
                <div style={{ maxWidth: '200px', width: '100%' }} className='col-auto  text-right'>
                  <Money value={reservation.reservationDetail.tip} />
                </div>
              </div>
              <div className='row mt-1 pr-3 dark-grey'>
                <div className='col text-right'>{t('tax')}</div>
                <div style={{ maxWidth: '200px', width: '100%' }} className='col-auto  text-right'>
                  <Money value={reservation.reservationDetail.tax} />
                </div>
              </div>
              <div className='row mt-1 fw-700 pr-3 fs-16 dark-grey'>
                <div className='col text-right'>{t('total')}</div>
                <div style={{ maxWidth: '200px', width: '100%' }} className='col-auto  text-right'>
                  <Money value={reservation.reservationDetail.total} />
                </div>
              </div>

              <div className='row mt-1 pr-3 dark-grey'>
                <div className='col text-right'>{t('depositReservation')}</div>
                <div style={{ maxWidth: '200px', width: '100%' }} className='col-auto  text-right'>
                  <Money value={reservation.reservationDetail.deposit} />
                </div>
              </div>
              {reservationPaymentDetail === null && // <- VER ESTA CONDICION
              reservation.status !== 'REJECTED' &&
              reservation.status !== 'CANCELLED' &&
              reservation.status !== 'PENDING' ? (
                <div className='row mt-1 pr-3 dark-grey'>
                  <div className='col text-right'>{t('guaranteeExpires')}</div>
                  <div
                    style={{ maxWidth: '200px', width: '100%' }}
                    className='col-auto  text-right text-capitalize'
                  >
                    {reservation?.reservationDetail?.orderExpirationDate
                      ? format(
                          new Date(reservation?.reservationDetail?.orderExpirationDate),
                          'EEEE dd MMM. HH:mma',
                          { locale },
                        )
                      : '-'}
                  </div>
                </div>
              ) : null}
            </div>
            <Subtitle
              marginBottom='mb-3'
              text={<div className='mt-3 mb-0'>{t('payments.title')}</div>}
            />
            <div className='row no-gutters container-data mb-26'>
              <div className='col-auto pr-5 mr-5'>
                <div className='dark-grey container-label'>{t('payments.total')}</div>
                <div className='d-flex justify-content-start fw-600 fs-16'>
                  <Money
                    currencyClass={'fw-600 fs-16'}
                    showCurrencyEnd={false}
                    value={totalPayAmount(reservation?.paymentDetail)}
                    currencyCode={currency.currency}
                  />
                </div>
              </div>
            </div>
            <div className='mb-2 fw-700'>{t('payments.subtitle')}</div>
            {reservation?.paymentDetail?.length > 0 ? (
              <Table
                hasPagination={false}
                borderTop={false}
                showPagination={false}
                columns={[
                  { title: t('payments.table.date') },
                  { title: t('payments.table.status') },
                  { title: t('payments.table.amount') },
                  { title: '' },
                ]}
                pagination='normal'
                borderContainer={true}
                header='white'
                showText={false}
                data={reservation?.paymentDetail}
                paddingTh={'padding-white-header'}
                renderRow={(item) => {
                  const { dateTime } = formatDateTranslation(item.date, language, 'small');
                  return (
                    <>
                      <TableCell className={'dark-grey sentences-capitalize'}>{dateTime}</TableCell>
                      <TableCell>
                        {depositBadge(
                          item.statusDetail === 'partially_refunded'
                            ? item.statusDetail
                            : item.status,
                          isWelletFinance,
                        )}
                      </TableCell>
                      <TableCell className={'dark-grey'}>
                        <Money
                          currencyClass={'dark-grey'}
                          showCurrencyEnd={false}
                          currencyCode={currency.currency}
                          value={item.amount}
                        />
                        {item?.refundedAmount && item.refundedAmount !== item.amount ? (
                          <div className='d-flex'>
                            <div className='dark-grey fw-600'>
                              {t('guaranteeDeposit.refundedAmount')} &nbsp;
                            </div>

                            {item?.refundedAmount ? (
                              <Money
                                value={item.refundedAmount}
                                currencyCode={currency.currency}
                                showCurrencyEnd={false}
                                currencyClass={'fw-600'}
                              />
                            ) : (
                              '-'
                            )}
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell className={'dark-grey'}>
                        {item.brand ? (
                          <>
                            <span
                              className='mr-2'
                              style={{
                                border: '1px solid #EAECF0',
                                borderRadius: '4px',
                                padding: '6px',
                              }}
                            >
                              <img
                                height={'32px'}
                                src={
                                  'https://cdn1.wellet.fun/images/credit-card/' +
                                  item.brand?.toLocaleLowerCase() +
                                  '-logo.png'
                                }
                                alt=''
                              />
                            </span>
                            <span>
                              {item?.brand[0]?.toUpperCase() +
                                item?.brand?.slice(1)?.toLocaleLowerCase() +
                                t('finishedIn') +
                                item?.lastNumberCard}
                            </span>
                          </>
                        ) : null}
                      </TableCell>
                      {/* <TableCell>
                        <div className='row'>
                          {item.canRefund ? (
                            <div className='col-auto px-0'>
                              <ModalReturnMoney
                                reservationId={reservation.id}
                                paymentDetail={reservation.paymentDetail}
                                operationId={item.operationId}
                              />
                            </div>
                          ) : null}
                        </div>
                      </TableCell> */}
                    </>
                  );
                }}
                rowClass='not-hover'
              />
            ) : (
              t('payments.no')
            )}
          </ContanierSection>
        </>
      ) : null}
    </div>
  );
};

export default ReservationProductZone;
