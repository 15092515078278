import React from 'react';
import UserImage from '../userImage/userImage';
import { InfoRenderer } from '../../../components';
import style from './affiliateCard.module.css';

const AffiliateCard = ({
  url = null,
  name = null,
  company = null,
  size = '40px',
  isVerified = false,
  customClass = '',
  email = null,
}) => {
  return (
    <div className={`row flex-nowrap no-gutters ${customClass}`}>
      <div className={`col-auto`}>
        <UserImage url={url} name={name} isVerified={isVerified} size={size} />
      </div>
      <div className={`col ${style.affiliateCardText}`}>
        <div className={`fw-500   ${style.affiliateCardName}`}>
          <InfoRenderer content={name} className='text-capitalize' />
        </div>
        <div className={`dark-grey fw-400`}>
          {email ? (
            <InfoRenderer content={email} />
          ) : (
            <InfoRenderer content={company} className='text-capitalize' />
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliateCard;
