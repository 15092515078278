import React from 'react';
import '../campaign.css';

const GraphicCard = ({ title, children }) => (
  <div className={`card-campaign card-campaign-padding d-flex flex-column justify-content-between`}>
    <h3 className='fw-600 campaign-title'>{title}</h3>
    {children}
  </div>
);

export default GraphicCard;