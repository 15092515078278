import axios from 'axios';

export const apis = {
  tickets: axios.create({
    baseURL: process.env.REACT_APP_SALES_API_URL,
  }),
  admin: axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_URL,
  }),
};

//https://core.wellet.dev/api/Dashboard/ApprovalActivityFeed
