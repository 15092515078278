import { useSelector } from 'react-redux';
import { WelletProvider } from './wellet/welletContextProvider';

function ContextProvider({ children }) {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const apiUrls = {
    ticketsUrl: process.env.REACT_APP_SALES_API_URL,
    adminUrl: process.env.REACT_APP_ADMIN_API_URL
  };

  const contextUser = {
    jwt: user.jwt,
  };

  const appData = {
    name: app.name,
    logo: app.logoURL,
    language: app.language,
    scopeOrganization: app.scopeOrganization ? app.scopeOrganization.organizationId : -1,
  };

  return (
    <WelletProvider
      apiUrls={apiUrls}
      user={contextUser}
      app={appData}
      redirectPathWhenUnauthorized='/'
    >
      {children}
    </WelletProvider>
  );
}

export default ContextProvider;
