import { useTranslation } from 'react-i18next';
import { commissionBadge } from '../../helpers/status';
import { Button, Money } from '../../uiComponents/common';
import Show from '../show/show';
import { ModalConsumption } from '../modals';
import { WalletPayemntIcon } from '../../uiComponents/icons';
import { useSelector } from 'react-redux';

const CommissionHeader = ({
  reservation,
  onOpenDispute,
  onOpenDisputeReject,
  onOpenDisputeApprove,
  onOpenApprove,
}) => {
  const { t: translate } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.details.commission.header',
  });
  const currency = useSelector((state) => state.app.selectedOrganization);

  return (
    <div className='container-header with-border'>
      <div className='row align-items-end'>
        <div className='col-auto'>
          <div className='light-grey  d-flex justify-content-start  align-items-center container-small-title'>
            <WalletPayemntIcon size='14px' color='currentColor' customClass={'mr-2'} />
            <div className=''>{t('title')}</div>
          </div>
          <div className='container-title'>
            <div className='row'>
              <div></div>
            </div>
            <Money value={reservation.totalCommissionsAmount} currencyCode={currency.currency} />
            <div className='d-inline ml-3'>
              {commissionBadge(reservation.auditStatus, translate)}
            </div>
          </div>
        </div>

        <div className='col my-auto'></div>
        {/* Espero por si ya lo maneja el BACK */}
        <div className='col-auto'>
          <div className='row'>
            <Show.When isTrue={reservation.canDispute}>
              <div className='col-auto'>
                <Button
                  className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
                  onClick={onOpenDispute}
                  size={'custom'}
                  text={t('btnRevision')}
                />
              </div>
            </Show.When>
            <Show.When isTrue={reservation.canDisputeReject}>
              <div className='col-auto'>
                <Button
                  className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
                  onClick={onOpenDisputeReject}
                  size={'custom'}
                  text={t('btnRejecte')}
                />
              </div>
            </Show.When>
            <Show.When isTrue={reservation.canDisputeApprove}>
              <div className='col-auto'>
                <Button
                  className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
                  onClick={onOpenDisputeApprove}
                  size={'custom'}
                  text={
                    <>
                      <span className='dot dot-green mr-2 d-inline-block'></span>
                      {t('btnAuthorize')}
                    </>
                  }
                />
              </div>
            </Show.When>
            <Show.When isTrue={reservation.canAddCheck}>
              <div className='col-auto'>
                <ModalConsumption
                  showComment={true}
                  maxWidth={'650px'}
                  reservationId={reservation.id}
                />
              </div>
            </Show.When>
            <Show.When isTrue={reservation.canApprove}>
              <div className='col-auto'>
                <Button
                  className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
                  onClick={onOpenApprove}
                  size={'custom'}
                  text={
                    <>
                      <span className='dot dot-green mr-2 d-inline-block'></span>
                      {t('btnApprove')}
                    </>
                  }
                />
              </div>
            </Show.When>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionHeader;
