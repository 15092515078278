import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { SignInHeader } from '../../components';
import { Input, Button, Card, CardBody, Loading } from '../../uiComponents/common';
import WarningIcon from '../../uiComponents/icons/warningIcon';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { resetPassword, validateCode } from '../../services';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TitleTab from '../../components/titleTab/titleTab';

const ForgotPassword = () => {
  const [error, setError] = useState('');
  const [mode, setMode] = useState('');
  const { logoURL, tenantId } = useSelector((state) => state.app);
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.forgotPassword' });
  const auth = getAuth();

  const resetPasswordMutation = useMutation({
    mutationFn: () => resetPassword(welletContext, values.email, tenantId),
    onError: (error) => {
      setError(error.response.data);
    },
  });
  const validateCodeMutation = useMutation({
    mutationFn: () => validateCode(welletContext, values.email, tenantId, values.code),
    onSuccess: (data) => {
      if (data.data.isValid) signInMutation.mutate(data.data.customToken);
    },
    onError: (error) => {
      setError(error.response.data);
    },
  });
  const signInMutation = useMutation({
    mutationFn: (data) => signInWithCustomToken(auth, data),
    onError: (error) => {
      setError(error.response.data);
    },
  });

  const sendForm = async () => {
    setError('');
    validateCodeMutation.reset();
    if (mode === '') {
      setSeconds(120);
      resetPasswordMutation.mutate();
    } else {
      validateCodeMutation.mutate();
    }
  };

  const validate = () => {
    setError('');
    let errors = {};

    if (!values.email) {
      errors.email = t('errors.emailRequired');
    }
    if (mode === 'code') {
      if (!values.code) {
        errors.code = t('errors.codeRequired');
      }
    }

    return errors;
  };

  const defaultTime = 120;
  const [seconds, setSeconds] = useState(defaultTime);

  useEffect(() => {
    let interval;
    if (defaultTime && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [defaultTime]);

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
  const isValid = validateCodeMutation?.data ? validateCodeMutation?.data?.data?.isValid : true;

  return (
    <>
      <TitleTab />
      <div className='d-flex flex-column justify-content-beetween align-items-strech vh-100 w-100'>
        <SignInHeader />
        {resetPasswordMutation.isLoading ? <Loading opacity={true} global={false} /> : null}
        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
          <form onSubmit={handleSubmit} className='login-form'>
            <Card>
              <div className='row justify-content-center'>
                <div
                  className='mt-3 mb-2 d-flex align-items-center justify-content-center'
                  style={{
                    height: '100px',
                    width: '100px',
                    borderRadius: '50%',
                    backgroundImage: `url(${logoURL})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                  }}
                ></div>
              </div>
              <div className='row'>
                <h5 className='col-12 text-center mb-0' style={{ color: 'var(--color-subtitle)' }}>
                  {t('forgotPassword')}
                </h5>
                <p className='col text-center text-muted'>{t('instructions')}</p>
              </div>
              <CardBody>
                {!resetPasswordMutation.isSuccess ? (
                  <Input
                    placeholder={t('email')}
                    name={'email'}
                    value={values.email}
                    type={'email'}
                    error={errors.email}
                    onChangeValue={handleChange}
                  />
                ) : (
                  <Input
                    placeholder={t('code')}
                    name={'code'}
                    value={values.code}
                    type={'text'}
                    error={errors.code}
                    onChangeValue={handleChange}
                  />
                )}
                {resetPasswordMutation.isSuccess ? (
                  <div className='mt-2 d-flex align-items-center'>
                    <div>{t('verifyMail')}</div>
                  </div>
                ) : (
                  <></>
                )}

                {error || !isValid ? (
                  <div className='mt-2 d-flex align-items-center'>
                    <div className='mr-1'>
                      <WarningIcon color={'#CD3D64'} />
                    </div>
                    <div className='error-text'>{!isValid ? t('invalidCode') : error}</div>
                  </div>
                ) : (
                  <></>
                )}
                <Button
                  type='submit'
                  size={'small'}
                  className={'mt-2 btn-custom-black'}
                  width={'100%'}
                  onClick={() => {
                    if (resetPasswordMutation.isSuccess) setMode('code');
                    else setMode('');
                  }}
                  text={resetPasswordMutation.isSuccess ? t('validCode') : t('forgotPassword')}
                />
                {resetPasswordMutation.isSuccess ? (
                  <Button
                    type='submit'
                    size={'small'}
                    disabled={seconds > 0 && resetPasswordMutation.isSuccess}
                    className={'mt-2 btn-outline'}
                    width={'100%'}
                    onClick={() => setMode('')}
                    text={`${t('validCode')}  ${
                      seconds > 0 && resetPasswordMutation.isSuccess ? `(${seconds})` : ''
                    }`}
                  />
                ) : (
                  <></>
                )}
                <div className='py-2 mt-2 content-divider text-muted'>
                  <span className='px-3'></span>
                </div>
                <div className='row'>
                  <div className='col'>
                    <Link to={'/'} className='custom-link'>
                      {t('goLogin')}
                    </Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
