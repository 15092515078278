import { Link } from 'react-router-dom';
import './signInHeader.css';
import { useSelector } from 'react-redux';

export default function SignInHeader() {
  const { logoType } = useSelector((state) => state.app);
  return (
    <div className='navbar sign-header-container justify-content-center py-3'>
      <Link to={'/'}>
        <img alt={''} src={logoType} height={35} />
      </Link>
    </div>
  );
}
