import React from 'react';

const VerifiedIcon = ({ size = '14px '}) => {
  return (
    <svg width={size} height={size} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1553_1569)'>
        <path
          d='M10.8111 2.47932C10.944 2.80089 11.1993 3.0565 11.5206 3.18995L12.6475 3.65673C12.9691 3.78994 13.2246 4.04545 13.3579 4.36705C13.4911 4.68865 13.4911 5.04999 13.3579 5.37159L12.8914 6.49768C12.7581 6.81943 12.758 7.18114 12.8918 7.50272L13.3575 8.62848C13.4235 8.78777 13.4575 8.9585 13.4575 9.13093C13.4576 9.30336 13.4236 9.47411 13.3576 9.63342C13.2916 9.79272 13.1949 9.93746 13.073 10.0594C12.951 10.1813 12.8062 10.278 12.6469 10.3439L11.5208 10.8103C11.1993 10.9433 10.9436 11.1985 10.8102 11.5199L10.3434 12.6468C10.2102 12.9684 9.9547 13.2239 9.6331 13.3571C9.3115 13.4903 8.95015 13.4903 8.62855 13.3571L7.50246 12.8907C7.18085 12.7578 6.81964 12.7581 6.49823 12.8914L5.37133 13.3575C5.04991 13.4904 4.68887 13.4903 4.36754 13.3572C4.0462 13.2241 3.79083 12.9689 3.65753 12.6477L3.19061 11.5204C3.05765 11.1989 2.80242 10.9432 2.48105 10.8098L1.35415 10.343C1.03269 10.2099 0.777252 9.95452 0.643992 9.6331C0.510732 9.31169 0.510551 8.95051 0.64349 8.62897L1.10993 7.50287C1.24282 7.18126 1.24255 6.82005 1.10918 6.49864L0.643405 5.37093C0.577367 5.21165 0.543363 5.04091 0.543336 4.86848C0.543308 4.69605 0.577257 4.5253 0.643244 4.366C0.70923 4.20669 0.80596 4.06195 0.927907 3.94004C1.04985 3.81814 1.19463 3.72145 1.35395 3.65552L2.48005 3.18907C2.80134 3.05622 3.0568 2.80132 3.19034 2.48032L3.65712 1.35341C3.79033 1.03182 4.04584 0.776305 4.36744 0.643095C4.68904 0.509884 5.05039 0.509884 5.37199 0.643095L6.49808 1.10954C6.81969 1.24242 7.1809 1.24215 7.50231 1.10878L8.62969 0.643818C8.95124 0.510682 9.31251 0.510709 9.63405 0.643894C9.95558 0.777078 10.2111 1.03251 10.3443 1.35403L10.8112 2.48127L10.8111 2.47932Z'
          fill='#2E90FA'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.7416 5.16499C9.83513 5.01809 9.86646 4.84006 9.82872 4.67006C9.79098 4.50006 9.68725 4.35201 9.54035 4.25849C9.39346 4.16497 9.21543 4.13363 9.04542 4.17137C8.87542 4.20911 8.72738 4.31284 8.63385 4.45974L6.06398 8.49786L4.88798 7.02786C4.77926 6.89187 4.62097 6.80464 4.44793 6.78536C4.27489 6.76608 4.10128 6.81633 3.96529 6.92505C3.8293 7.03377 3.74207 7.19206 3.72279 7.3651C3.70351 7.53814 3.75376 7.71175 3.86248 7.84774L5.61248 10.0352C5.6777 10.1169 5.76146 10.1817 5.85681 10.2245C5.95216 10.2672 6.05633 10.2865 6.16065 10.2809C6.26498 10.2752 6.36646 10.2448 6.45663 10.192C6.54681 10.1392 6.62308 10.0657 6.6791 9.97749L9.7416 5.16499Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1553_1569'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerifiedIcon;
