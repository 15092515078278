import React from 'react';
import './alert.css';
const Alert = ({ title, text, icon, type, list, className = '', link = '' }) => {
  const alertClass = `alert alert-${type} ${
    link ? 'cursor-pointer' : 'cursor-default'
  } my-1 d-flex align-items-start ${className}`;

  return (
    <div className={alertClass}>
      <div className='alert-content'>
        <div className='d-flex align-items-center justify-content-center'>
          <div className='d-flex align-items-center'>
            <span className='mr-1'>{icon}</span>
            <p>
              <strong>{title}</strong> {text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
