import { useState } from 'react';
import { IconButton } from '../../uiComponents/common';
import { CloseIcon } from '../../uiComponents/icons';
import styles from './banner.module.css';

const Banner = ({ icon, title, text, onReviewClick, endComponent }) => {

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.iconTextContainer}>
        <div className={styles.iconContainer}>
          {icon}
        </div>
        <div className={styles.textContainer}>
            <div className={styles.spanText} onClick={onReviewClick}>
              {title}
            </div>
          <p>
            {text}
          </p>
        </div>
      </div>
      <div className={styles.actionsContainer}>
        {endComponent}
      </div>
    </div>
  );
};

export default Banner;
