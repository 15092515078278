import React from 'react';
import { ApprovedKycModal, ApproveModal, RejectedModal, RevalidateKycModal } from '../index';
import { advantageNetworkTypes, kycStatusType } from '../../../../types/index';

const SelectConfig = ({ status, usecase, id, callback, refetchStatus, mode = 'BUTTON', setkycStatus }) => {
  const idStatus = id;
  const statusRefetch = refetchStatus;

  const config = {
    request: {
      [advantageNetworkTypes.APPROVED]: {
        component: (
          <RejectedModal
            onClick={callback}
            id={idStatus}
            refetchStatus={statusRefetch}
            isLink={mode === 'LINK'}
          />
        ),
      },
      [advantageNetworkTypes.REJECTED]: {
        component: (
          <ApproveModal
            onClick={callback}
            id={idStatus}
            refetchStatus={statusRefetch}
            isLink={mode === 'LINK'}
          />
        ),
      },
      [advantageNetworkTypes.PENDING]: {
        component: (
          <>

            {/* <PendingModal id={idStatus} refetchStatus={statusRefetch} /> */}
            <RejectedModal onClick={callback} id={idStatus} refetchStatus={statusRefetch} />
            <ApproveModal onClick={callback} id={idStatus} refetchStatus={statusRefetch} />
          </>
        ),
      },
    },
    kyc: {
      [kycStatusType.APPROVED]: {
        component: <RevalidateKycModal setkycStatus={setkycStatus}/>,
      },
      [kycStatusType.SEND]: {
        component: (
          <>
            <RevalidateKycModal setkycStatus={setkycStatus}/>
            <ApprovedKycModal setkycStatus={setkycStatus}/>
          </>
        ),
      },
      [kycStatusType.SEND_WITH_OBSERVATIONS]: {
        component: (
          <>
            <RevalidateKycModal setkycStatus={setkycStatus}/>
            <ApprovedKycModal setkycStatus={setkycStatus}/>
          </>
        ),
      },
      [kycStatusType.PENDING]: {
        component: null,
      },
    },
  };

  const selectedConfig = config[usecase][status] || {
    component: null,
  };

  return selectedConfig.component;
};

export default SelectConfig;
