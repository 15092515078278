import { useState } from 'react';

const useOrderBy = () => {
  const [orderBy, setOrderBy] = useState(null);
  const [orderType, setOrderType] = useState(null);

  const handleColumnClick = (column) => {
    if (orderType === column) {
      // Si ya se hizo clic en la misma columna, cambiar el tipo de ordenamiento
      setOrderBy(orderBy === 'ASC' ? 'DESC' : 'ASC');
    } else {
      // Si es una nueva columna, establecerla como la columna seleccionada y ordenar de forma ascendente
      setOrderType(column);
      setOrderBy('ASC');
    }
  };
  const resetState = () => {
    setOrderBy(null);
    setOrderType(null);
  };

  return { orderBy, orderType, handleColumnClick, resetState };
};

export default useOrderBy;
