const ContanierSection = ({ children, maxWidth = '1000px' }) => {
  return (
    <div className='container-section' style={{ maxWidth }}>
      <div className='row no-gutters'>
        <div className='col-12'>{children}</div>
      </div>
    </div>
  );
};

export default ContanierSection;
