import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonFilter,
  Input,
  Loading,
  OrganizationCard,
  PageError,
  Status,
} from '../../uiComponents/common';
import {
  useCreateBreadCrumbs,
  useDebounce,
  useGetRestaurants,
  useLocalStorage,
  usePagination,
} from '../../hooks';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { FilterCard, PageHeader, Show, Table, TableCell, TableRow } from '../../components';
import { getAllProducts, getCategoryAndSubcategoryData, getSpecialType } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { SearchIcon } from '../../uiComponents/icons';
import { filtersFormat } from '../../helpers/format';
import { useTranslation } from 'react-i18next';
import { productStatus } from '../../data';
import mergeWithDefaults from '../../helpers/localstorage';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Products = ({ mode }) => {
  const navigate = useNavigate();
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { t, i18n } = useTranslation('translation', { keyPrefix: `components.products` });
  const { t: translate } = useTranslation();
  const { getClientTranslation } = useGenericTranslation();
  // Local Storages
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage(`filter-${mode}`, {
    categories: [],
    subCategories: [],
    location: [],
    filterStatus: productStatus(translate),
    filterCard: null,
    filterField: null,
    specialType: null,
    page: 1,
  });

  //Pagination
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // States
  const [categories, setCategories] = useState(filterLocalStorage.categories);
  const [filterRestaurante, setFilterRestaurante] = useState(filterLocalStorage.location);
  const [subCategories, setSubCategories] = useState(filterLocalStorage.subCategories);
  const [value, setValue] = useState(filterLocalStorage.filterField);
  const [filterStatus, setFilterStatus] = useState(filterLocalStorage.filterStatus);
  const [filterSpecial, setFilterSpecial] = useState(filterLocalStorage?.specialType);
  const [filterCard, setFilterCard] = useState(
    filterLocalStorage?.filterCard ? filterLocalStorage?.filterCard : [],
  );
  const debouncedSearch = useDebounce(value, 700);

  // Functions
  const handleFilter = (array) => {
    setFilterStatus(filterStatus.map((f) => ({ ...f, isActive: false })));
    setFilterCard(array);
    handlePageChange(1);
  };
  const getValuesOfActiveElements = (array) => {
    const activeElements = array.filter((element) => element.isActive);
    return activeElements.length > 0 ? activeElements.map((element) => element.value) : [];
  };
  const handleNavigate = (product) => {
    navigate({
      pathname: `/edit-${mode}/${product.id}`,
    });
  };

  // Query API Calls
  const productsQuery = useQuery({
    queryKey: [
      'get-products',
      organization.marketId,
      organization.isMarket,
      skip,
      limit,
      [],
      subCategories,
      categories,
      debouncedSearch,
      filterStatus,
      filterCard,
      filterRestaurante,
      organization.id,
      filterSpecial,
      mode,
    ],
    queryFn: () =>
      getAllProducts(
        welletContext,
        organization.marketId,
        skip,
        limit,
        organization.isMarket ? filtersFormat(filterRestaurante) : [],
        filtersFormat(subCategories),
        filtersFormat(categories),
        debouncedSearch?.trim(),
        filterCard?.includes('CUSTOM') ? filtersFormat(filterStatus) : filterCard,
        organization.isMarket ? null : organization.id,
        getMode(),
        filterSpecial ? filtersFormat(filterSpecial) : [],
      ),
    ...defaultQueryOptions,
    onSettled: (data) => {
      setTotalAndReset(data?.data?.products?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        categories: categories,
        subCategories: subCategories,
        filterField: value,
        filterStatus: filterStatus,
        filterCard: filterCard,
        location: organization.isMarket ? filterRestaurante : [],
        specialType: filterSpecial,
        page: currentPage,
      });
    },
  });

  const specialTypeQuery = useQuery({
    queryKey: ['specialType-simple', organization.id, i18n.language],
    queryFn: () => getSpecialType(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let response = data?.data
        ?.filter((s) => s !== 'EXTRAPRODUCT')
        ?.map((c) => ({
          label: t(`getSpecialType.${c}`),
          value: c,
          isActive:
            filterLocalStorage?.specialType?.length > 0
              ? filtersFormat(filterLocalStorage?.specialType).includes(c)
              : false,
        }));
      setFilterSpecial(response);
    },
    ...defaultQueryOptions,
    enabled: mode === 'group',
  });

  const categoriesQuery = useQuery({
    queryKey: ['categories-simple', organization.id],
    queryFn: () => getCategoryAndSubcategoryData(welletContext),
    ...defaultQueryOptions,
    onSuccess: (data) => {
      //Se formatea la data para los filtros
      const dataCategoryFormatted = data?.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          isActive:
            filterLocalStorage?.categories?.length > 0
              ? filtersFormat(filterLocalStorage?.categories).includes(item.id)
              : false,
        };
      });
      const dataSubCategoryFormatted = data?.data?.flatMap((category) =>
        category.subCategories.map((subCategory) => ({
          label: subCategory.name,
          value: subCategory.id,
          isActive:
            filterLocalStorage?.subCategories?.length > 0
              ? filtersFormat(filterLocalStorage?.subCategories).includes(subCategory.id)
              : false,
          category: category.id,
        })),
      );
      setCategories(dataCategoryFormatted || []);
      setSubCategories(dataSubCategoryFormatted || []);
    },
  });

  const restaurantesQuery = useGetRestaurants(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((org) => ({
        label: org.name,
        value: org.id,
        isActive:
          filterLocalStorage.location.length > 0
            ? filtersFormat(filterLocalStorage.location).includes(org.id)
            : false,
      }));
      setFilterRestaurante(response);
    },
    enabled: organization.id === organization.marketId,
    keepPreviousData: true,
  });

  const commonHeadersAdmin = [
    { title: t(`table.${mode}.product`), value: '', filterable: false },
    { title: t('table.pax'), value: '', filterable: false },
    { title: t('table.categorie'), value: '', filterable: false },
    { title: t('table.subCategorie'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  const commonHeadersGroup = [
    { title: t(`table.${mode}.product`), value: '', filterable: false },
    { title: t('table.categorie'), value: '', filterable: false },
    { title: t('table.subCategorie'), value: '', filterable: false },
    { title: t('table.specialType'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  const headerAdmin = organization.isMarket
    ? [{ title: getClientTranslation('title.singular'), value: '', filterable: false }, ...commonHeadersAdmin]
    : commonHeadersAdmin;

  const headerGroup = organization.isMarket
    ? [{ title: getClientTranslation('title.singular'), value: '', filterable: false }, ...commonHeadersGroup]
    : commonHeadersGroup;

  useCreateBreadCrumbs([{ name: t(`${mode}.title`) }], mode);

  const getMode = () => {
    switch (mode) {
      case 'areas':
        return 'area';
      default:
        return mode;
    }
  };

  useEffect(() => {
    setFilterStatus(mergeWithDefaults(filterLocalStorage.filterStatus, productStatus(translate)));
  }, [i18n.language]);

  return (
    <>
      <PageHeader
        title={t(`${mode}.title`)}
        endComponent={
          <Button
            size={'medium'}
            className={'btn-wellet-secondary'}
            text={t(`${mode}.btnCreate`)}
            onClick={() => navigate(`/new-${mode}`)}
          />
        }
      />

      <Show>
        <Show.When
          isTrue={
            productsQuery.isLoading ||
            categoriesQuery.isLoading ||
            restaurantesQuery.isLoading ||
            specialTypeQuery.isLoading
          }
        >
          <Loading />
        </Show.When>
        <Show.When isTrue={productsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          {/* Cards Filter */}
          <div className='row mb-3'>
            <div className='col-xl-8'>
              <div className='row'>
                <div className='col-6 col-sm-auto p-0'>
                  <FilterCard
                    category={t('cardFilter.total')}
                    quantity={productsQuery?.data?.data?.totalCount}
                    onClick={() => handleFilter([])}
                    isSelected={
                      filterCard?.length === 0 &&
                      filterCard[0] !== 'CUSTOM' &&
                      filtersFormat(filterStatus).length === 0
                    }
                  />
                </div>
                <div className='col-6 col-sm-auto p-0'>
                  <FilterCard
                    category={t('cardFilter.actives')}
                    quantity={productsQuery?.data?.data?.enabledCount}
                    onClick={() => handleFilter(['ENABLED'])}
                    isSelected={filterCard[0] === 'ENABLED'}
                  />
                </div>
                <div className='col-6 col-sm-auto p-0'>
                  <FilterCard
                    category={t('cardFilter.inactives')}
                    quantity={productsQuery?.data?.data?.disabledCount}
                    onClick={() => handleFilter(['DISABLED'])}
                    isSelected={filterCard[0] === 'DISABLED'}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Button Filters */}
          <div className='row align-items-center my-3'>
            <div className='col-auto'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                customClass='mb-0 mr-3'
                name={'search'}
                value={value}
                onChangeValue={(e) => setValue(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <div className='col'>
              <div className='d-flex justify-content-start  flex-wrap' style={{ gap: '15px' }}>
                <ButtonFilter
                  filterName={t('btnFilters.categories')}
                  filterOptions={categories}
                  error={categoriesQuery.isError}
                  callback={setCategories}
                />
                <ButtonFilter
                  filterName={t('btnFilters.subCategories')}
                  filterOptions={subCategories}
                  callback={setSubCategories}
                  parentsOptions={getValuesOfActiveElements(categories)}
                  error={categoriesQuery.isError}
                />
                <ButtonFilter
                  filterName={t('btnFilters.state')}
                  filterOptions={filterStatus}
                  callback={(filter) => {
                    handleFilter(['CUSTOM']);
                    setFilterStatus(filter);
                  }}
                />
                <Show.When isTrue={organization.id === organization.marketId}>
                  <ButtonFilter
                    isSearchable={true}
                    filterName={getClientTranslation('title.plural')}
                    filterOptions={filterRestaurante}
                    error={restaurantesQuery.isError}
                    callback={setFilterRestaurante}
                  />
                </Show.When>
                <Show.When isTrue={mode === 'group'}>
                  <ButtonFilter
                    filterName={t('btnFilters.specialType')}
                    filterOptions={filterSpecial}
                    error={specialTypeQuery.isError}
                    callback={setFilterSpecial}
                  />
                </Show.When>
              </div>
            </div>
          </div>

          <Table
            data={productsQuery?.data?.data?.products?.items ?? []}
            columns={mode === 'group' ? headerGroup : headerAdmin}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            renderRow={(product, index) => (
              <>
                <Show.When isTrue={organization.isMarket}>
                  <TableCell>
                    <OrganizationCard
                      name={product?.restaurant?.name}
                      date={product?.restaurant?.cityName}
                      size={40}
                      imageUrl={product?.restaurant?.logo}
                      hightLightName={true}
                    />
                  </TableCell>
                </Show.When>

                <TableCell className={'fw-700'} style={{ width: '200px' }}>
                  {product.name}
                </TableCell>
                <Show.When isTrue={mode === 'areas'}>
                  <TableCell className={'fw-700'}>
                    {product.pax + ' + ' + product.extraPax}
                  </TableCell>
                </Show.When>
                <TableCell>{product?.subCategory?.category?.name ?? '-'}</TableCell>
                <TableCell>{product?.subCategory?.name ?? '-'}</TableCell>
                <Show.When isTrue={mode === 'group'}>
                  <TableCell>
                    <Show>
                      <Show.When isTrue={product?.specialType !== null}>
                        <Status text={t(`getSpecialType.${product?.specialType}`)} useDot={false} />
                      </Show.When>
                      <Show.Else>-</Show.Else>
                    </Show>
                  </TableCell>
                </Show.When>

                <TableCell>
                  <Status
                    status={product.isActive}
                    text={product.isActive ? t('active') : t('inactive')}
                  />
                </TableCell>
              </>
            )}
            onRowClick={(product) => handleNavigate(product)}
          />
        </Show.Else>
      </Show>
    </>
  );
};

export default Products;
