import React, { useContext, useState } from 'react';
import { Button, InputFile, InputText, Modal, Toast } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation, useQueryClient } from 'react-query';
import { getWireDetail, postReviewWire } from '../../services';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InfoRenderer, Show } from '../../components';

const ModalTransfer = ({ callback, wire, mode, handleSubmit, wireId }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalTransfer' });
  const methods = useForm();
  const [open, setOpen] = useState(null);

  // API Query
  const queryClient = useQueryClient();

  const confirmMutation = useMutation({
    mutationFn: (data) => {
      return postReviewWire(welletContext, wireId, data);
    },
  });

  // Functions
  const handleModal = () => {
    setOpen((prevState) => !prevState);
  };
  const handleClose = () => {
    methods.reset();
    confirmMutation.reset();
    handleModal();
  };
  const onSubmit = (data) => {
    const body = {
      referenceNumber: data.referenceNumber,
      file: data?.file?.base64,
    };

    return confirmMutation.mutate(body);
  };
  const renderDetails = () => {
    if (mode === 'WIRE_MEXICO') {
      return (
        <div className='row'>
          <div className='col-auto pr-0 dark-grey'>
            <div className='dark-grey fw-500 pb-1'>{t('beneficiary')}:</div>
            <div className='dark-grey fw-500 pb-1'>{t('accountType')}:</div>
            <div className='dark-grey fw-500 pb-1'>{t('accountNumber')}:</div>
            {/* <div className='dark-grey fw-500 pb-1'>{t('address')}:</div> */}
            <div className='dark-grey fw-500 pb-1'>{t('keyBank')}</div>
            <div className='dark-grey fw-500 pb-1'>{t('bank')}:</div>
          </div>
          <div className='col text-right pl-3'>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.recipientFullName} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.typeAccount} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.accountNumber} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.clabe} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.bank} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='row'>
          <div className='col-12 text-black  fw-500 pb-1'>{t('beneficiaryDetails')}</div>
          <div className='col-auto pr-0 dark-grey'>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('beneficiary')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('accountType')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('accountNumber')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('address')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('country')}:</div>
          </div>
          <div className='col text-right pl-3'>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.recipientFullName} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.typeAccount} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.accountNumber} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.recipientAddress} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.country} />
            </div>
          </div>
          <div className='col-12 text-black fw-500 pb-1'>{t('bankDetails')}:</div>
          <div className='col-auto pr-0 dark-grey'>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('bank')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('bankAddress')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('swift')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('aba')}:</div>
            <div className='dark-grey fw-500 pb-1 pl-1'>{t('currency')}:</div>
          </div>
          <div className='col text-right pl-3'>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.bank} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.bankAddress} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.swift} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.abaRoutingNumber} />
            </div>
            <div className='text-primary fw-600 pb-1'>
              <InfoRenderer content={wire?.currency} />
            </div>
          </div>
        </div>
      );
    }
  };
  const handleRefetch = () => queryClient.refetchQueries({ queryKey: ['wire-detail', wireId] });

  return (
    <>
      <Button
        className={'btn-wellet-secondary fw-600 bg-custom-light p-0 px-2 m-0'}
        text={'+ ' + t('button.settle')}
        size={'custom'}
        heightConfig={'36px'}
        fontConfig={'14px'}
        onClick={handleModal}
      />
      <Show.When isTrue={open}>
        <Modal
          title={`${t('title')} ${mode === 'WIRE_MEXICO' ? t('national') : t('international')}`}
          open={open}
          onClose={handleClose}
          closeIcon={true}
          maxWidth='450px'
          handleSubmit={handleSubmit}
          closeOnDocumentClick={false}
          customButtons={
            <>
              <div className='row px-2 text-center'>
                <Button
                  text={t('cancel')}
                  disabled={confirmMutation.isLoading || confirmMutation.isSuccess}
                  className={'col btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                <Button
                  text={t('settle')}
                  disabled={confirmMutation.isLoading || confirmMutation.isSuccess}
                  className={'col ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={() => methods.handleSubmit(onSubmit)()}
                />
              </div>
            </>
          }
        >
          <FormProvider {...methods}>
            <div className='text-left mt-1'>{renderDetails()}</div>
            <InputText
              maxLength={512}
              placeholder={t('inputReference')}
              label={<span className='text-black fw-500'>{t('referenceNumber')}:</span>}
              name={'referenceNumber'}
              isRequired={true}
            />
            <InputFile
              placeholder={t('inputFile')}
              label={<span className='text-black fw-500'>{t('inputFile')}:</span>}
              name={'file'}
              pdf={true}
              isRequired={false}
              showDrag={false}
            />
          </FormProvider>
        </Modal>
      </Show.When>

      <Show.When isTrue={confirmMutation.isError}>
        <Toast
          message={t('error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
      <Show.When isTrue={confirmMutation.isSuccess}>
        <Toast
          message={t('success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleRefetch();
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default ModalTransfer;
