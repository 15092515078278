// queryOptions.js
const retryNumber = +process.env.REACT_APP_RETRY_COUNT;
const retryDelay = +process.env.REACT_APP_RETRY_COUNT_DELAY;

export const defaultQueryOptions = {
  refetchInterval: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchIntervalInBackground: false,
  refetchOnMount: true,
  retry: retryNumber,
  retryDelay: retryDelay,
  retryOnMount: true,
  keepPreviousData: false, // Este tiene que ser False, solamente va en los casos que el query use el isPreviousData
  //enabel: by default is true
};

export const defaultQueryOptionsFilter = {
  refetchInterval: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchIntervalInBackground: false,
  refetchOnMount: true,
  retry: retryNumber,
  retryDelay: retryDelay,
  retryOnMount: false,
  keepPreviousData: false, // Este tiene que ser False, solamente va en los casos que el query use el isPreviousData
  //enabel: by default is true
};
