import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Alert,
  BreadCrumbs,
  Button,
  FormButton,
  InputDescription,
  InputNumber,
  InputPhone,
  InputText,
  Loading,
  Select,
  Subtitle,
  TextEditor,
  Toast,
} from '../../../uiComponents/common';

import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';

import {
  CityIcon,
  CountryIcon,
  FwFlagIcon,
  FwGlobeIcon,
  FwLocationIcon,
  FwOrganizationIcon,
  StateIcon,
  ZipCodeIcon,
} from '../../../uiComponents/icons';
import {
  getCities,
  getStates,
  getVenueById,
  getCountries,
  postVenue,
  putVenue,
} from '../../../services';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const VenuesForm = () => {
  const methods = useForm();
  const lastClickedButton = useRef(null);
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [isFirstRender, setIsFirstRender] = useState({
    state: true,
    city: true,
  });
  const organization = useSelector((state) => state.app.selectedOrganization);
  const isEditing = location.pathname.split('/')[2] === 'edit-venue';

  const [isRedirect, setIsRedirect] = useState(null);

  const venueQuery = useQuery({
    queryKey: ['venue', id],
    queryFn: () => getVenueById(welletContext, id),
    onSuccess: (data) =>
      methods.reset({
        name: data.data.name,
        alias: data.data.shortName,
        country: { label: data.data?.address?.country, value: data.data?.address?.countryId },
        city: { label: data.data?.address?.city, value: data.data?.address?.cityId },
        state: { label: data.data?.address?.state, value: data.data?.address?.stateId },
        address: data?.data?.address?.street,
        customerDescription: data?.data?.customerSupportDescription,
        customerPhones: data?.data?.customerSupportPhones,
        phone: data?.data?.phone,
        contract: data?.data?.contract,
        voucher: data?.data?.legals,
        activityProvider: data?.data?.activityProvider,
        lat: data?.data?.location?.lat,
        lng: data?.data?.location?.lng,
        postalCode: data?.data?.address?.postalCode,
      }),
    enabled: !!id,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const countriesQuery = useQuery({
    queryKey: ['countries', organization.id],
    queryFn: () => getCountries(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const statesQuery = useQuery({
    queryKey: ['states', organization.id, methods?.watch('country')?.value],
    queryFn: () => getStates(welletContext, methods?.watch('country')?.value),
    onSuccess: () => {
      if (!isFirstRender.state) {
        methods.setValue('state', '');
        methods.setValue('city', '');
      }
      setIsFirstRender({
        city: isFirstRender.city,
        state: false,
      });
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!methods?.watch('country')?.value,
  });
  const citiesQuery = useQuery({
    queryKey: ['cities', organization.id, methods?.watch('state')?.value],
    queryFn: () => getCities(welletContext, methods?.watch('state')?.value),
    onSuccess: () => {
      if (!isFirstRender.city) {
        methods.setValue('city', '');
      }
      setIsFirstRender({
        state: isFirstRender.state,
        city: false,
      });
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!methods?.watch('state')?.value,
  });
  const venueMutation = useMutation({
    mutationFn: (body) => {
      if (id) {
        return putVenue(welletContext, body);
      }
      return postVenue(welletContext, body);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const countries = countriesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const state = statesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const city = citiesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));

  // Functions
  const onSubmit = (data) => {
    const body = {
      name: data.name,
      shortName: data.alias,
      phone: data.phone,
      address: {
        street: data.address,
        postalCode: data.postalCode,
        city: data.city.label,
        state: data.state.label,
        country: data.country.label,
        countryId: data.country.value,
        stateId: data.state.value,
        cityId: data.city.value,
      },
      location: {
        lat: data.lat,
        lng: data.lng,
      },
      organizationId: organization.id,
      activityProvider: data.activityProvider,
      contract: data.contract,
      customerSupportPhones: data.customerPhones,
      customerSupportDescription: data.customerDescription,
      legals: data.voucher,
    };

    if (id) {
      body.id = id;
    }
    venueMutation.mutate(body);
  };
  const clearForm = () => {
    if (id) {
      navigate('/venues');
    } else {
      venueMutation.reset();
      methods.reset();
      navigate('/venues');
    }
  };
  const handleToastCallback = () => {
    if (isRedirect) {
      clearForm();
      navigate('/venues');
    } else {
      venueMutation.reset();
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  return (
    <>
      <BreadCrumbs
        crumbs={[
          {
            name: 'Venues',
            path: '/venues',
          },
          {
            name: isEditing ? 'Edit Venue' : 'New Venue',
            path: isEditing ? '/venues/edit-venue' : '/venues/new-venue',
          },
        ]}
      />
      {venueMutation.isSuccess && (
        <Toast
          message={`Venue ${id ? 'updated' : 'created'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {venueQuery.isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
            <Subtitle text={<h3>Main Data</h3>} />
            <div className='row mt-3'>
              <InputText
                name={'name'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwFlagIcon />}
                label={'Name'}
                placeholder={'Enter venue name'}
                isRequired={true}
              />
              <InputText
                name={'alias'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwFlagIcon />}
                label={'Alias'}
                placeholder={'Enter alias'}
                isRequired={true}
              />
            </div>
            <div className='row mt-2'>
              <Select
                options={countries}
                label={'Country'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<CountryIcon />}
                name={'country'}
                isRequired={true}
              />
              <Select
                options={state}
                label={'State'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<StateIcon />}
                name={'state'}
                isRequired={true}
                isDisabled={!!!methods.watch('country')?.label}
              />
            </div>
            <div className='row mt-3'>
              <Select
                options={city}
                label={'City'}
                isDisabled={!!!methods.watch('state')?.label}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<CityIcon />}
                name={'city'}
                isRequired={true}
              />
              <InputText
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwLocationIcon />}
                name={'address'}
                placeholder={'Enter address'}
                label={'Address'}
                isRequired={true}
              />
            </div>
            <div className='row mt-2'>
              <InputText
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<ZipCodeIcon />}
                name={'postalCode'}
                placeholder={'Enter ZIP Code'}
                label={'ZIP Code'}
                isRequired={false}
              />
              <InputText
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwOrganizationIcon />}
                name={'activityProvider'}
                placeholder={'Enter Activity Provider'}
                minLength={1}
                label={'Activity Provider'}
                isRequired={false}
              />
            </div>
            <div className='row mt-2'>
              <InputPhone
                name={'phone'}
                className={'col-12 col-sm-6 col-xl-4 my-2 my-sm-0'}
                label={'Phone'}
                placeholder={'Enter Phone Number'}
              />
              <InputText
                className={'col-12 col-sm-6 col-xl-4'}
                name={'contract'}
                placeholder={'Enter Contract'}
                label={'Contract'}
                minLength={1}
              />
            </div>
            <div className='row mt-2'>
              <InputDescription
                className={'col-12 col-sm-6 col-xl-4'}
                name={'customerPhones'}
                placeholder={'Enter Customer Support Phones'}
                label={'Customer Support Phones'}
              />
              <InputDescription
                className={'col-12 col-sm-6 col-xl-4'}
                name={'customerDescription'}
                placeholder={'Enter Customer Support Description'}
                label={'Customer Support Description'}
              />
            </div>
            <Subtitle text={<h3>Important Information</h3>} />
            <div className='row mt-2'>
              <TextEditor className={'col col-xl-8'} label={'Voucher'} name={'voucher'} />
            </div>
            <Subtitle text={<h3>Add geo location information from Google Maps</h3>} />
            <div className='row mt-2'>
              <InputNumber
                label={'Latitude'}
                name={'lat'}
                className={'col-6 col-xl-4'}
                icon={<FwGlobeIcon />}
                placeholder={'Latitude'}
                isRequired={true}
                acceptNegative={false}
                maxNumber={90}
                minNumber={-90}
              />
              <InputNumber
                label={'Longitude'}
                name={'lng'}
                className={'col-6 col-xl-4'}
                icon={<FwGlobeIcon />}
                placeholder={'Longitude'}
                isRequired={true}
                acceptNegative={false}
                maxNumber={180}
                minNumber={-180}
              />
            </div>
            <div className='d-flex justify-content-end mt-2'>
              {/* <Button
                size={'small'}
                className={'btn-custom-primary'}
                type='submit'
                text={`${
                  venueMutation.isLoading && lastClickedButton.current === 'send'
                    ? 'Sending...'
                    : 'Send'
                }`}
                onClick={() => {
                  handleRedirect(false, 'send');
                }}
              />
              <Button
                size={'small'}
                type='button'
                className={'btn-custom-white  ml-2'}
                text={`Cancel`}
                onClick={clearForm}
              />
              <Button
                size={'small'}
                className={' ml-2 btn-custom-primary'}
                type='submit'
                text={`${
                  venueMutation.isLoading && lastClickedButton.current === 'sendAndClose'
                    ? 'Sending...'
                    : 'Send and Close'
                }`}
                onClick={() => {
                  handleRedirect(true, 'sendAndClose');
                }}
              /> */}
              <FormButton
                mutation={venueMutation}
                handleRedirect={handleRedirect}
                clearForm={clearForm}
                all={false}
                nextStep={true}
                isDisabled={venueMutation.isLoading}
              />
            </div>
          </form>
        </FormProvider>
      )}
      {venueMutation?.error && <Alert title={''} text={'Error'} type={'danger'} />}
    </>
  );
};

export default VenuesForm;
