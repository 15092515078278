import React from 'react';

export default function FilterIcon({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      width={size}
      heighth={size}
      viewBox='0 0 10 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.53033 12.5303C5.23744 12.8232 4.76256 12.8232 4.46967 12.5303L0.46967 8.53033C0.176777 8.23744 0.176777 7.76256 0.46967 7.46967C0.762563 7.17678 1.23744 7.17678 1.53033 7.46967L5 10.9393L8.46967 7.46967C8.76256 7.17678 9.23744 7.17678 9.53033 7.46967C9.82322 7.76256 9.82322 8.23744 9.53033 8.53033L5.53033 12.5303Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.46967 0.46967C4.76256 0.176777 5.23744 0.176777 5.53033 0.46967L9.53033 4.46967C9.82322 4.76256 9.82322 5.23744 9.53033 5.53033C9.23744 5.82322 8.76256 5.82322 8.46967 5.53033L5 2.06066L1.53033 5.53033C1.23744 5.82322 0.762563 5.82322 0.46967 5.53033C0.176777 5.23744 0.176777 4.76256 0.46967 4.46967L4.46967 0.46967Z'
        fill={color}
      />
    </svg>
  );
}
