import React, { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getAllVenues } from '../../services';
import { Button, IconButton, Loading } from '../../uiComponents/common';
import { PageHeader, Table, TableCell, TableRow } from '../../components';
import { useSelector } from 'react-redux';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';
import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../hooks';
import { useTranslation } from 'react-i18next';

const Venues = () => {
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.venues' });
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const venuesQuery = useQuery({
    queryKey: ['venues-all', selectedOrganization.id],
    queryFn: () => getAllVenues(welletContext, null, null, selectedOrganization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => setTotalAndReset(data?.data?.venues?.length),
  });
  const headerAdmin = [
    { title: '', value: '', filterable: false },
    { title: t('table.name'), value: '', filterable: false },
    { title: t('table.alias'), value: '', filterable: false },
    { title: t('table.address'), value: '', filterable: false },
    { title: t('table.city'), value: '', filterable: false },
    { title: t('table.state'), value: '', filterable: false },
    { title: t('table.country'), value: '', filterable: false },
    { title: t('table.cp'), value: '', filterable: false },
    { title: t('table.phone'), value: '', filterable: false },
    { title: t('table.latitude'), value: '', filterable: false },
    { title: t('table.longitude'), value: '', filterable: false },
  ];
  return (
    <>
      <PageHeader
        title={'Venues'}
        marginBottom={false}
        endComponent={
          <Button
            className={'btn-custom-primary'}
            onClick={() => navigate('new-venue')}
            size={'medium'}
            text={t('btnCreate')}
          />
        }
      />
      {venuesQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            tableKey={`venues-${selectedOrganization.id}`}
            data={venuesQuery?.data?.data?.venues}
            totalP={totalItems}
            borderTop={false}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            columns={headerAdmin}
            renderRow={(venue, index) => (
              <TableRow
                key={index}
                // onClick={() => navigate(venue)}
              >
                <TableCell>
                  <IconButton
                    onClick={() => navigate(`edit-venue/${venue.id}`)}
                    icon={<FwEditIcon />}
                  />
                </TableCell>
                <TableCell>{venue.name}</TableCell>
                <TableCell>{venue.shortName}</TableCell>
                <TableCell>{venue.address.street}</TableCell>
                <TableCell>{venue.address.city}</TableCell>
                <TableCell>{venue.address.state}</TableCell>
                <TableCell>{venue.address.country}</TableCell>
                <TableCell>{venue.address.postalCode}</TableCell>
                <TableCell>{venue.phone}</TableCell>
                <TableCell>{venue.location.lat}</TableCell>
                <TableCell>{venue.location.lng}</TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Venues;
