import React, { useContext } from 'react';
import { PageHeader, Show, Table, TableCell } from '../../components';
import { Loading, Money } from '../../uiComponents/common';
import { useCreateBreadCrumbs, usePagination } from '../../hooks';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getAccountDetail } from '../../services';
import { Link, useParams } from 'react-router-dom';

const AccountDetail = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.accountDetail' });
  const { id } = useParams();
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // Main API Query
  const accountQuery = useQuery({
    queryKey: ['account', id, organization.marketId, limit, skip],
    queryFn: () =>
      getAccountDetail(
        welletContext, // wellet context
        id,
        {
          limit, // limit
          skip, // skip
        },
      ),
    onSettled: (data) => {
      setTotalAndReset(data?.data?.totalRegisters ? data?.data?.totalRegisters : 0);
    },
    ...defaultQueryOptions,
    refetchInterval: false,
  });

  useCreateBreadCrumbs([
    { name: t('breadcrumbs.prev'), path: '/accounts' },
    { name: t('breadcrumbs.current') },
  ]);

  return (
    <>
      <PageHeader title={t('title')} border={false} />
      <Show>
        <Show.When isTrue={accountQuery.isLoading}>
          <Loading />
        </Show.When>

        <Show.Else>
          <Table
            borderTop={false}
            header='grey'
            columns={[
              { title: t('table.payment') },
              { title: t('table.reservation') },
              { title: t('table.amount') },
              { title: t('table.reason') },
              { title: t('table.pending') },
            ]}
            totalP={totalItems}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            data={accountQuery?.data?.data?.items}
            renderRow={(item) => {
              return (
                <>
                  <TableCell style={{ width: 'auto' }}>
                    {item.chargeId ? (
                      <div className='fw-600'>
                        <Link
                          className='custom-link-file'
                          to={
                            item.type === 'REFUND'
                              ? `/accounts/refunds/${item.chargeId}`
                              : item.type === 'DISPUTE' ? `/accounts/disputes/${item.disputeId}` : `/accounts/transactions/${item.chargeId}`
                          }
                        >
                          {item.chargeId}
                        </Link>
                      </div>
                    ) : (
                      '-'
                    )}
                  </TableCell>

                  <TableCell>
                    <div className='fw-600'>
                      {item?.restaurant}{' '}
                      {item?.reservation?.referenceCode ? (
                        <>
                          -{' '}
                          {item.reservation?.id ? (
                            <Link
                              className='custom-link-file'
                              to={`/accounts/reservation/${item.reservation?.id}`}
                            >
                              {item.reservation.referenceCode}
                            </Link>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </TableCell>

                  <TableCell style={{ width: 'auto' }} type={'primary'}>
                    <div className='fw-600'>
                      <>
                        {' '}
                        <Money value={item.amount} />{' '}
                      </>
                    </div>
                  </TableCell>

                  <TableCell className={'fw-500'}>
                    {item.type ? t(`status.${item.type}`) : '-'}
                  </TableCell>

                  <TableCell style={{ width: 'auto' }} type={'primary'}>
                    <div className='fw-600'>
                      <>
                        {' '}
                        <Money value={item.pending} />{' '}
                      </>
                    </div>
                  </TableCell>
                </>
              );
            }}
            rowClass='not-hover'
          />
        </Show.Else>
      </Show>
    </>
  );
};

export default AccountDetail;
