import React from 'react';
import { NoPhotoIcon } from '../../icons';
import style from './organizationCard.module.css';

const OrganizationCard = ({
  imageUrl,
  className,
  size = '40px',
  date,
  name,
  width = '168px',
  hightLightName = false,
  hightLightDate = false,
}) => {
  return (
    <div className='row flex-nowrap no-gutters'>
      <div className={`col-auto`}>
        {imageUrl ? (
          <img
            className={className}
            style={{
              height: size,
              width: size,
              borderRadius: '50%',
              objectFit: 'cover',
            }}
            src={imageUrl}
            alt={name}
          />
        ) : (
          <NoPhotoIcon size={size} className={className} />
        )}
      </div>
      <div className={`col ${style.organizationCardText}`}>
        <div
          className={`${hightLightName ? 'fw-600' : 'fw-500'} text-capitalize wrap-text ${
            style.organizationCardName
          }`}
        >
          {name}
        </div>
        <div className={`${hightLightDate ? 'fw-600' : 'fw-400'}`}>{date}</div>
      </div>
    </div>
  );
};

export default OrganizationCard;
