export const getAllDays = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/productprice/product/${id}`);
};

export const updateProductPrice = async (
  welletContext,
  productId,
  productPrices,
  url = '/api/productprice',
) => {
  return await welletContext.apis.admin.put(url, {
    productId: productId,
    ProductPrices: productPrices.map((price) => ({
      weekday: price.weekDay,
      price: price.price,
      priceExtraPax: price.priceExtraPax,
      consumption: price.consumption,
      tipPercentage: price.tipPercentage,
      taxPercentage: price.taxPercentage,
      depositPercentage: price.depositPercentage,
      isActive: price.isActive,
    })),
  });
};

export const getAllProducts = async (
  welletContext,
  marketId,
  skip = 0,
  limit = 20,
  restaurantIds = [],
  subcategoryIds = [],
  categoryIds = [],
  fieldFilter = null,
  productStatus = [],
  organizationId = null,
  mode,
  specialTypes
) => {
  const url = `/api/product/${marketId}/all`;
  const body = {
    skip: skip,
    limit: limit,
    productStatus: productStatus,
    restaurantIds: restaurantIds,
    subcategoryIds: subcategoryIds,
    categoryIds: categoryIds,
    fieldFilter: fieldFilter,
    organizationId: organizationId,
    reservationType: mode,
    specialTypes
  };

  return await welletContext.apis.admin.post(url, body);
};

export const getProductById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/product/${id}`);
};
export const getSpecialType = async (welletContext) => {
  return await welletContext.apis.admin.get(`api/product/specialType`);
};

export const postProduct = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/product`, body);
};

export const putProduct = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/product`, body);
};

export const getCategoryAndSubcategoryData = async (welletContext) => {
  const url = `/api/category/subcategory`;
  const response = await welletContext.apis.admin.get(url);
  return response;
};
