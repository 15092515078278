import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../hooks';
import { useSelector } from 'react-redux';
import { canAccessOrView } from '../helpers/permissions';

const rolesList = (isMarket = true) => {
  return [
    {
      role: 'finance',
      home: isMarket ? '/payments' : '/commissions',
      priority: 7, // ver
    },
    {
      role: 'comercial',
      home: '/payments',
      priority: 6, // ver
    },
    {
      role: 'marketadmin',
      home: '/',
      priority: 3,
    },
    {
      role: 'affiliatecenter',
      home: '/sellers',
      priority: 5, // ver
    },
    {
      role: 'affiliatecenter-onlyview',
      home: '/sellers',
      priority: 5, // ver
    },
    {
      role: 'dashboard',
      home: '/',
      priority: 4, // ver
    },
    {
      role: 'reservationcenter',
      home: '/reservations-pending',
      priority: 8, // ver
    },
    {
      role: 'reservationcenter-onlyview',
      home: '/reservation-history',
      priority: 8, // ver
    },
    {
      role: 'admin',
      home: '/',
      priority: 2,
    },
    {
      role: 'superadministrator',
      home: '/',
      priority: 1,
    },
    {
      role: 'auditor',
      home: isMarket ? '/payments' : '/commissions',
      priority: 9,
    },
    {
      role: 'auditor-onlyview',
      home: isMarket ? '/payments' : '/commissions',
      priority: 9,
    },
    {
      role: 'bosssales',
      home: '/',
      priority: 10, // ver
    },
    {
      role: 'salesteamlead',
      home: '/',
      priority: 11, // ver
    },
    {
      role: 'soldout',
      home: '/sold-out',
      priority: 8, // ver
    },
    {
      role: 'checker',
      home: '/check-ins',
      priority: 9, // ver
    },
    {
      role: 'callcenterlead',
      home: '/reservations-pending',
      priority: 10, // ver
    },
    {
      role: 'restaurantvip',
      home: '/areas',
      priority: 10,
    },
    {
      role: 'group',
      home: '/groups',
      priority: 10,
    },
    {
      role: 'productpriceloader',
      home: '/areas',
      priority: 11,
    },
    {
      role: 'productloader',
      home: '/areas',
      priority: 11,
    },
    {
      role: 'wellet_finance',
      home: '/disputes',
      priority: 11,
    },
    {
      role: 'marketing',
      home: '/campaigns-list',
      priority: 11,
    }
  ];
};

// Functions
const mergeRolesWithList = (roles, rolesList) => {
  const mergedRoles = [];

  roles.forEach((role) => {
    const matchedRole = rolesList.find((item) => item.role === role);
    if (matchedRole) {
      mergedRoles.push({
        role: matchedRole.role,
        home: matchedRole.home,
        priority: matchedRole.priority,
      });
    }
  });

  return mergedRoles;
};
const getHighestPriorityHome = (rolesList) => {
  // Ordenar roles por prioridad de mayor a menor
  const sortedRoles = rolesList.sort((a, b) => a.priority - b.priority);
  // Obtener el objeto con la mayor prioridad
  const highestPriorityRole = sortedRoles[0];
  // Devolver el valor de la clave home del objeto con la mayor prioridad
  return highestPriorityRole.home;
};

export default function RolesRoute({ roles, title }) {
  const { user } = useUser();
  const organization = useSelector((state) => state.app.currentOrganization);

  const hasAllowedRole = user.roles.some((role) => roles.some((item) => item === role));
  const currentRoles = mergeRolesWithList(
    hasAllowedRole ? roles : user.roles,
    rolesList(organization.isMarket),
  );

  const shouldRedirect = () => {
    if (!user || !user.roles || user.roles.length === 0) return true;
    let hasAllowedRole = user.roles.some((role) => roles.some((item) => item === role));
    if (title === 'areas' || title === 'groups') {
      hasAllowedRole = canAccessOrView(user.roles, roles, title);
    }

    return !hasAllowedRole;
  };

  return shouldRedirect() ? (
    <Navigate to={{ pathname: getHighestPriorityHome(currentRoles) }} replace />
  ) : (
    <Outlet />
  );
}
