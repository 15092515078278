import { useCreateBreadCrumbs, useUser } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { ConciergeDetail, InfoRenderer, Show } from '../../../components';
import { ContanierSection, Money, PaymentDetailHeader } from '../../../uiComponents/common';
import ModalPreviewFile from '../modalPreviewFile';
import ModalTransfer from '../modalTransfer';
import Notes from '../../../components/reservation/notes';
import { hasAllowedRoles } from '../../../helpers/roles';
import { formatDateTranslation, getLocale } from '../../../helpers/dates';
import { useSelector } from 'react-redux';
import TabTableWire from './tabTableWire/tabTableWire';
import TimelineViewer from './timeLineWire/timeLineWire';
import WirePaymentMethodDetail from './wirePaymentMethodDetail/wirePaymentMethodDetail';
import { useQueryClient } from 'react-query';
import ModalRetryPayment from './modals/modalRetryPayment';

const WiresPaymentDetail = ({ data = null, mode, refecth }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.wiresPaymentDetail' });
  const { roles } = useUser();
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);

  const queryClient = useQueryClient();
  const handleRefetch = () => queryClient.refetchQueries({ queryKey: ['wire-detail', data?.id] });
  const getNameandPath = (mode = null) => {
    switch (mode) {
      case 'WIRE_MEXICO':
        return { name: t('breadcrumbs.wireMexico'), path: '/wire-payments' };
      case 'WIRE_INTERNATIONAL':
        return { name: t('breadcrumbs.wireInternational'), path: '/international-wire-payments' };
      case 'CASH':
        return { name: t('breadcrumbs.cash'), path: '/cash-payments' };
      case 'MERCADOPAGO':
        return { name: t('breadcrumbs.mp'), path: '/mercadopago-payments' };
      case 'CERTIFICATE':
        return { name: t('breadcrumbs.certificates'), path: '/certificate-payments' };
      case 'STRIPE':
        return { name: 'Stripe', path: '/stripe-payments' };
      default:
        return null;
    }
  };

  const { dateTime } = formatDateTranslation(data?.paymentDate, language, 'small');
  useCreateBreadCrumbs([getNameandPath(mode), { name: t('breadcrumbs.detail') }], mode);
  return (
    <Show.When isTrue={data !== null}>
      <div className='container-page'>
        {/* HEADER */}
        <PaymentDetailHeader
          title={t('title')}
          amount={data?.amount}
          userName={data?.concierge.fullName}
          status={data?.status}
          endComponent={
            <>
              <Show.When
                isTrue={
                  data?.status === 'PENDING' &&
                  data?.isPaymentExecuted &&
                  (mode === 'WIRE_MEXICO' || mode === 'WIRE_INTERNATIONAL') &&
                  hasAllowedRoles(roles, [
                    'superadministrator',
                    'admin',
                    'marketadmin',
                    'comercial',
                    'finance',
                    'auditor',
                  ])
                }
              >
                <div className='pr-2'>
                  <ModalTransfer wire={data?.userWithdrawalMethod} mode={mode} wireId={data?.id} />
                </div>
              </Show.When>
              <Show.When
                isTrue={
                  data?.canResendPayment &&
                  mode === 'MERCADOPAGO' &&
                  hasAllowedRoles(roles, ['superadministrator'])
                }
              >
                <div className='pr-2'>
                  <ModalRetryPayment id={data?.id} refetch={refecth} />
                </div>
              </Show.When>
            </>
          }
        />
        {/*GENERAL DETAILS */}
        <ContanierSection>
          <div className='row no-gutters'>
            <div className='col-12 container-data'>
              <div className='dark-grey container-label'>{t('generalDetail.paymentLote')}</div>
              <InfoRenderer content={data?.batchDescription} className='container-text' />
            </div>

            <div className='col-12 col-md-4 font-weight-semibold container-data'>
              <div className='dark-grey container-label'>{t('generalDetail.import')}</div>
              <InfoRenderer
                content={<Money value={data?.amount} />}
                styles={{ fontSize: '20px', fontWeight: '700' }}
              />
            </div>

            <div className='col-12 col-md-5 font-weight-semibold container-data'>
              <div className='dark-grey container-label'>{t('generalDetail.paymentDate')}</div>
              <InfoRenderer content={dateTime} className='container-text sentences-capitalize' />
            </div>

            <div className='col-12 col-md-4 font-weight-semibold container-data'>
              <div className='dark-grey container-label'>{t('generalDetail.reff')}</div>
              <InfoRenderer content={data?.reference} className='container-text' />
            </div>

            <div className='col-12 col-md-5 font-weight-semibold container-data'>
              <div className='dark-grey container-label'>{t('generalDetail.ticket')}</div>
              <Show>
                <Show.When isTrue={data?.receiptFile}>
                  <ModalPreviewFile url={data?.receiptFile} />
                </Show.When>
                <Show.Else>-</Show.Else>
              </Show>
            </div>
          </div>
        </ContanierSection>

        {/*METHOD PAYMENTS DETAILS */}
        <WirePaymentMethodDetail data={data?.userWithdrawalMethod} mode={mode} />

        {/* TIME LINE */}
        <TimelineViewer language={language} data={data?.timeLine} mode={mode} withdrawalMethod={data?.userWithdrawalMethod}/>

        {/* PAYMENT DETAIL INFORMATION (TABLE) */}

        <TabTableWire paymentId={data?.id} isWellet={data?.isWellet} />

        {/* CONCIERGES DETAILS */}
        <Show.When isTrue={!data?.isWellet}>
          <ConciergeDetail
            language={language}
            data={{ concierge: data?.concierge, rp: data?.rp, boss: data?.boss }}
          />
        </Show.When>
        <div style={{ marginBottom: '66px' }}></div>
        {/* NOTES */}
        <Notes
          callback={handleRefetch}
          currentNotes={data?.paymentNotes ?? []}
          reservationId={data?.id}
          url={'payment'}
        />
      </div>
    </Show.When>
  );
};

export default WiresPaymentDetail;
