import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { SignInHeader } from '../../components';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';

import './login.css';
import { Input, Button, Card, CardBody, Loading } from '../../uiComponents/common';
import { exchangeToken, setLoginLoading } from '../../actions';
import { useDispatch } from 'react-redux';
import WarningIcon from '../../uiComponents/icons/warningIcon';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TitleTab from '../../components/titleTab/titleTab';

const Login = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'screens.login' });
  const [errorLogin, setErrorLogin] = useState('');
  const [loading, setLoading] = useState(false);
  const { logoURL } = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const tenantId = useSelector((state) => state.app.tenantId);
  const auth = getAuth();
  auth.tenantId = tenantId;
  const dispatch = useDispatch();

  const sendForm = async () => {
    try {
      setLoading(true);
      dispatch(setLoginLoading(true));
      const res = await signInWithEmailAndPassword(auth, values.email, values.password);
      await onLoginSuccess(res.user);
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          setErrorLogin(t('errors.seeYourPassword'));
          break;
        case 'auth/user-disabled':
          setErrorLogin(t('errors.accountBan'));
          break;
        default:
          setErrorLogin(t('errors.error'));
          break;
      }
    } finally {
      dispatch(setLoginLoading(false));
      setLoading(false);
    }
  };

  const onLoginSuccess = async (user) => {
    const res = await exchangeToken(dispatch, user);
    return res;
  };

  const validate = () => {
    let errors = {};
    if (!values.email?.trim()) {
      errors.email = t('errors.emailRequired');
    }
    if (!values.password?.trim()) {
      errors.password = t('errors.passwordRequired');
    }
    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  return (
    <>
      <TitleTab />
      <div className='d-flex flex-column justify-content-beetween align-items-strech vh-100 w-100'>
        {loading || user.isLogin ? <Loading opacity={true} global={false} /> : null}
        <SignInHeader />
        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
          <form onSubmit={handleSubmit} className='login-form'>
            <Card>
              <div className='row pt-3 pb-2 justify-content-center'>
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{
                    height: '100px',
                    width: '100px',
                    borderRadius: '50%',
                    backgroundImage: `url(${logoURL})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                  }}
                ></div>
              </div>
              <div className='row'>
                <h5 className='col-12 text-center mb-0' style={{ color: 'var(--color-subtitle)' }}>
                  {t('signIn')}
                </h5>
                <p className='col text-center text-muted'>{t('credentials')}</p>
              </div>
              <CardBody>
                <Input
                  placeholder={t('email')}
                  name={'email'}
                  value={values.email}
                  type={'email'}
                  error={errors.email}
                  onChangeValue={handleChange}
                />
                <Input
                  placeholder={t('password')}
                  name={'password'}
                  type={'password'}
                  value={values.password}
                  error={errors.password}
                  onChangeValue={handleChange}
                />
                {errorLogin ? (
                  <div className='mt-2 d-flex align-items-center'>
                    <div className='mr-1'>
                      <WarningIcon color={'#CD3D64'} />
                    </div>
                    <div className='error-text'>{errorLogin}</div>
                  </div>
                ) : (
                  <></>
                )}
                <div className='row py-2'>
                  <div className='col text-right pl-0'>
                    <Link className='custom-link' to={'/forgot-password'}>
                      {t('forgotPassword')}
                    </Link>
                  </div>
                </div>
                <Button
                  type='submit'
                  size={'small'}
                  className={'mt-2 btn-custom-black'}
                  width={'100%'}
                  text={t('signIn')}
                />
                <div className='py-2 mt-2 content-divider text-muted'>
                  <span className='px-3'></span>
                </div>
                <div className='text-muted text-center'>
                  <span>
                    {t('confirmTerms')}
                    <Link className='custom-link' href={''}>
                      {' '}
                      {t('termsAndConditions')}
                    </Link>{' '}
                  </span>
                </div>
              </CardBody>
            </Card>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
