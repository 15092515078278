import React, { useState } from 'react';
// import { ExclamationIcon } from '@/ui-components/icons/exclamation-icon';
import './input.css';
// import { EyeIcon } from '@/ui-components/icons/eye-icon';
// import { EyeHideIcon } from '@/ui-components/icons/eye-hide-icon';
import { ExclamationIcon, EyeIcon, EyeHideIcon } from '../../icons';

export default function Input({
  value,
  placeholder,
  name,
  type,
  icon,
  error,
  dataCheckout,
  style,
  onChangeValue,
  customClass = '',
  disabled = false,
  label = null,
  ...inputOptions
}) {
  const className = `field ${customClass} ${error ? 'error' : ''}`;
  const classInput = icon ? 'input-icon' : '';
  const [ForceToShow, setForceToShow] = useState(false);
  return (
    <>
      {label ? <label className='mb-1' style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
      <div className={className}>
        <div id='icon-wellet'>{icon}</div>
        <div className='input-placeholder'>
          <input
            name={name}
            type={ForceToShow ? 'text' : type}
            value={value || ''}
            placeholder={placeholder}
            onChange={onChangeValue}
            className={classInput}
            disabled={disabled}
            style={style}
            autoCapitalize={type === 'password' || type === 'email' ? 'off' : 'on'}
            autoCorrect={type === 'password' || type === 'email' ? 'off' : 'on'}
            data-checkout={dataCheckout}
            {...inputOptions}
          />
          {type === 'password' ? (
            !ForceToShow ? (
              <span onClick={() => setForceToShow(true)} className='reveal'>
                <EyeHideIcon color={'var(--color-subtitle)'} size={'24px'} />
              </span>
            ) : (
              <span onClick={() => setForceToShow(false)} className='reveal'>
                <EyeIcon color={'var(--color-subtitle)'} size={'24px'} />
              </span>
            )
          ) : null}
        </div>
        {error && error.length > 0 && (
          <div className='error-label'>
            <ExclamationIcon color={'#fff'} size={'14px'} /> {error}
          </div>
        )}
      </div>
    </>
  );
}
