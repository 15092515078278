import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  useDebounce,
  useGetCities,
  useGetRestaurants,
  useGetSellers,
  useLocalStorage,
  useOrderBy,
  usePagination,
} from '../../hooks';
import { productStatus } from '../../data';
import { filtersFormat } from '../../helpers/format';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useSocialNetworkQuery from '../../hooks/queries/useSocialNetworkQuery';
import mergeWithDefaults from '../../helpers/localstorage';
import TableCell from '../table/tableCell';
import {
  ButtonFilter,
  ErrorAlert,
  IconButton,
  Input,
  Loading,
  Modal,
  Money,
  OrganizationCard,
  PageError,
  Slider,
  Status,
  Toast,
} from '../../uiComponents/common';
import { FwTrashIcon, PencilIcon, SearchIcon } from '../../uiComponents/icons';
import Table from '../table/table';
import { deleteCampaign, getAllCampaigns, orderCampaigns } from '../../services';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import Show from '../show/show';
import Popup from 'reactjs-popup';
import DragDropTable from '../table/dragTable';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const CampaignsList = ({ show }) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: `screens.campaigns.list` });
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const language = useSelector((state) => state.app.language);
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { getClientTranslation } = useGenericTranslation();
  // Local Storages State
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-campaings', {
    filterField: '',
    filterStatus: productStatus(translate),
    cities: [],
    restaurantes: [],
    concierge: [],
    page: 1,
  });

  // Other states
  const [filterField, setFilterField] = useState(filterLocalStorage.filterField ?? []);
  const [filterStatus, setFilterStatus] = useState(filterLocalStorage.filterStatus ?? []);
  const [filterCities, setFilterCities] = useState(filterLocalStorage.cities ?? []);
  const [filterRestaurante, setFilterRestaurante] = useState(filterLocalStorage.restaurantes ?? []);
  const [filterConcierges, setFilterConcierges] = useState(filterLocalStorage.concierge ?? []);
  const [isOpen, setIsOpen] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [currentDragTable, setCurrentDragTable] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  //Sort Columns
  const { orderBy, orderType, handleColumnClick } = useOrderBy();
  //Pagination
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = showAll ? null : 10;
  let skip = showAll ? null : 10 * ((currentPage ? currentPage : 1) - 1);

  //Search
  const debouncedSearch = useDebounce(filterField, 500);
  // Main Api Query
  const queryClient = useQueryClient();

  const campaignsQuery = useQuery({
    queryKey: [
      'get-campaigns-list',
      organization.marketId,
      organization.id,
      skip,
      limit,
      orderBy,
      orderType,
      determineStatus(filterStatus), // show inactives
      filtersFormat(filterRestaurante),
      filtersFormat(filterCities),
      debouncedSearch,
      filtersFormat(filterConcierges),
    ],
    queryFn: () =>
      getAllCampaigns(
        welletContext,
        organization.marketId,
        organization.id,
        showAll ? null : limit,
        showAll ? null : skip,
        orderBy,
        orderType,
        determineStatus(filterStatus), // show inactives
        filtersFormat(filterRestaurante),
        filtersFormat(filterCities),
        [],
        debouncedSearch,
        filtersFormat(filterConcierges),
      ),
    onSettled: (data) => {
      setTotalAndReset(data?.data?.totalRegisters);
      setCampaigns(data?.data?.items);
      setFilterLocalStorage({
        ...filterLocalStorage,
        filterField: filterField, // search
        filterStatus: filterStatus,
        restaurantes: filterRestaurante,
        page: currentPage,
        concierge: filterConcierges,
      });
    },
    ...defaultQueryOptions,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    //keepPreviousData: true,
    enabled: show,
  });
  // Others Api Querys
  const restaurantesQuery = useGetRestaurants(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((org) => ({
        label: org.name,
        value: org.id,
        isActive:
          filterLocalStorage.restaurantes.length > 0
            ? filtersFormat(filterLocalStorage.restaurantes).includes(org.id)
            : false,
      }));
      setFilterRestaurante(response);
    },
    keepPreviousData: true,
  });
  const citiesQuery = useGetCities(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((city) => ({
        label: city.name,
        value: city.id,
        isActive:
          filterLocalStorage?.cities?.length > 0
            ? filtersFormat(filterLocalStorage?.cities).includes(city.id)
            : false,
      }));
      setFilterCities(response);
    },
  });
  const conciergesQuery = useGetSellers(organization.id, organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.items?.map((user) => ({
        label: user.name,
        value: user.sid,
        isActive:
          filterLocalStorage.concierge.length > 0
            ? filtersFormat(filterLocalStorage.concierge).includes(user.sid)
            : false,
      }));
      setFilterConcierges(response);
    },
    enabled: isOpen && filterConcierges.length === 0,
  });
  // Mutation
  const deleteQuery = useMutation({
    mutationKey: ['delete-campaign'],
    mutationFn: (id) => deleteCampaign(welletContext, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const campaignsPriorityMutation = useMutation({
    mutationFn: (body) => {
      return orderCampaigns(welletContext, body);
    },
    onSuccess: () => {
      // campaignsQuery.refetch();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  // Functions
  function determineStatus(optionsArray) {
    // Format the options array (if needed)
    const options = filtersFormat(optionsArray);

    // Check if "ENABLED" and "DISABLED" options are present
    const hasEnabled = options.includes('ENABLED');
    const hasDisabled = options.includes('DISABLED');

    // Determine the return value based on the presence of options
    return hasEnabled && hasDisabled
      ? null
      : !options.length || (!hasEnabled && !hasDisabled)
      ? null
      : hasEnabled
      ? true
      : false;
  }
  const handleDelete = (id) => {
    setCampaignId(id);
  };
  const handleToast = () => {
    deleteQuery.reset();
    setCampaignId(null);
    campaignsQuery.refetch();
  };
  const submitDelete = () => {
    deleteQuery.mutate(campaignId);
  };
  const handleCloseModal = () => {
    handleDelete(null);
    deleteQuery.reset();
  };
  const handleEditNavigate = (e, id) => {
    e.stopPropagation();
    navigate(`/edit-campaign/${id}`);
  };
  const handleNavigate = (campaign) => {
    navigate(`/campaign/${campaign.id}`);
  };
  const handleDragEnd = (result) => {
    const currentData = queryClient.getQueryData([
      'get-campaigns-list',
      organization.marketId,
      organization.id,
      skip,
      limit,
      orderBy,
      orderType,
      determineStatus(filterStatus), // show inactives
      filtersFormat(filterRestaurante),
      filtersFormat(filterCities),
      debouncedSearch,
      filtersFormat(filterConcierges),
    ]);

    if (!currentData?.data || !result.destination) {
      return;
    }
    const items = [...currentData?.data?.items];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    campaignsPriorityMutation.mutate(items.map((item) => item.id));
    setCurrentDragTable(items);
    queryClient.setQueryData(
      [
        'get-campaigns-list',
        organization.marketId,
        organization.id,
        skip,
        limit,
        orderBy,
        orderType,
        determineStatus(filterStatus), // show inactives
        filtersFormat(filterRestaurante),
        filtersFormat(filterCities),
        debouncedSearch,
        filtersFormat(filterConcierges),
      ],
      { data: { items: items } },
    );
  };

  const header = [
    { title: '', value: '', filterable: '', center: false },
    { title: t('table.campaignName'), value: 'name', filterable: true },
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.creation'), value: 'createdDateUTC', filterable: true, center: true },
    { title: t('table.incomes'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  useEffect(() => {
    setFilterStatus(mergeWithDefaults(filterLocalStorage.filterStatus, productStatus(translate)));
  }, [i18n.language]);

  return (
    <>
      <Show.When isTrue={deleteQuery.isSuccess}>
        <Toast message={t('modal.success')} type='SUCCESS' onCallback={handleToast} />
      </Show.When>
      <Show>
        <Show.When
          isTrue={campaignsQuery.isLoading || restaurantesQuery.isLoading || citiesQuery.isLoading}
        >
          <Loading />
        </Show.When>
        <Show.When isTrue={campaignsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row align-items-center my-3'>
            <div className='col-auto'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                customClass='mb-0 mr-3'
                name={'search'}
                value={filterField}
                onChangeValue={(e) => setFilterField(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <div className='col'>
              <div className='d-flex justify-content-start  flex-wrap' style={{ gap: '15px' }}>
                <ButtonFilter
                  isSearchable={true}
                  filterName={getClientTranslation('title.plural')}
                  filterOptions={filterRestaurante}
                  callback={setFilterRestaurante}
                  error={restaurantesQuery.isError}
                  loading={restaurantesQuery.isFetching}
                />
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonFilters.cities')}
                  filterOptions={filterCities}
                  callback={setFilterCities}
                  error={citiesQuery.isError}
                />
                <ButtonFilter
                  filterName={t('buttonFilters.status')}
                  filterOptions={filterStatus}
                  callback={(filter) => {
                    setFilterStatus(filter);
                  }}
                />

                <ButtonFilter
                  isSearchable={true}
                  filterName={'Concierges'}
                  filterOptions={filterConcierges}
                  onClick={() => setIsOpen(true)}
                  loading={conciergesQuery.isLoading}
                  error={conciergesQuery.isError}
                  callback={(filter) => {
                    setFilterConcierges(filter);
                  }}
                />
                <Popup
                  on={'hover'}
                  trigger={
                    <div>
                      <Slider
                        className={'col-auto'}
                        defaultChecked={showAll}
                        onClick={() => setShowAll(!showAll)}
                        label={t('priority')}
                      />
                    </div>
                  }
                >
                  <div className='tooltipCard'>{t('priorityMessage')}</div>
                </Popup>
              </div>
            </div>
          </div>
          <DragDropTable
            data={campaigns ?? currentDragTable}
            columns={header}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            showPagination={!showAll}
            disableDrag={!showAll}
            handleDragEnd={(results) => handleDragEnd(results)}
            renderRow={(campaign, index, provided) => {
              const { date, time } = formatDateTranslation(
                campaign?.creationDate,
                language,
                'extraSmall',
              );
              return (
                <tr
                  key={String(index)}
                  className='not-hover'
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <TableCell style={{ width: '30px' }} type={'primary'}>
                    <IconButton
                      icon={<FwTrashIcon size='20px' />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(campaign.id);
                      }}
                    />
                    <IconButton
                      icon={<PencilIcon size='20px' />}
                      onClick={(e) => {
                        handleEditNavigate(e, campaign.id);
                      }}
                    />
                  </TableCell>
                  <TableCell className={'fw-500 text-truncate'}>{campaign.campaignName}</TableCell>

                  <TableCell>
                    <OrganizationCard
                      name={campaign.showName}
                      date={campaign.showCity}
                      imageUrl={campaign.showLogo}
                    />
                  </TableCell>
                  <TableCell className={'text-center'}>
                    <div>
                      <span className='sentences-capitalize container-label d-block fw-700 '>
                        {date}
                      </span>
                      <span className='fw-700 text-center'>{time}</span>
                    </div>
                  </TableCell>
                  <TableCell className={'fw-700'}>
                    <Money value={campaign.income} />
                  </TableCell>

                  <TableCell>
                    <Status
                      status={campaign.isActive}
                      text={campaign.isActive ? t('active') : t('inactive')}
                    />
                  </TableCell>
                </tr>
              );
            }}
            onRowClick={(campaign) => handleNavigate(campaign)}
          />
        </Show.Else>
      </Show>

      <Show.When isTrue={!!campaignId}>
        <Modal
          idModal={'campaigns-modal-delete'}
          title={t('modal.title')}
          open={!!campaignId}
          handleSubmit={submitDelete}
          onClose={handleCloseModal}
          isLoading={deleteQuery.isLoading || deleteQuery.isSuccess}
          textCancel={t('modal.cancel')}
          textSubmit={t('modal.submit')}
        >
          <div className='modal-background font-weight-bold text-center'>
            {t('modal.ask')}
            <Show.When isTrue={deleteQuery?.error}>
              <ErrorAlert text={t('modal.error')} />
            </Show.When>
          </div>
        </Modal>
      </Show.When>
    </>
  );
};

export default CampaignsList;
