import { WiresPayment } from '../screens';

export const paymentRoutesConfig = {
  MERCADOPAGO: {
    title: 'mercadopago',
    path: '/mercadopago-payments',
    component: () => <WiresPayment mode={'mercadopago'} key={'mercadopago'} />,
  },
  CASH: {
    title: 'cash',
    path: '/cash-payments',
    component: () => <WiresPayment mode={'cash'} key={'cash'} />,
  },
  CERTIFICATE: {
    title: 'certificates',
    path: '/certificate-payments',
    component: () => <WiresPayment mode={'certificate'} key={'certificate'} />,
  },
  WIRE_MEXICO: {
    title: 'nationalTransfer',
    path: '/wire-payments',
    component: () => <WiresPayment mode={'national'} key={'national'} />,
  },
  WIRE_INTERNATIONAL: {
    title: 'nationalInternational',
    path: '/international-wire-payments',
    component: () => <WiresPayment mode={'international'} key={'international'} />,
  },
  STRIPE: {
    title: 'stripe',
    path: '/stripe-payments',
    component: () => <WiresPayment mode={'stripe'} key={'stripe'} />,
  },
};
