import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { configureApi } from './api/gigamont';
import reducers from './reducers/reducers';
import { QueryClientProvider, QueryClient } from 'react-query';
import ContextProvider from './context/contextProvider';
import { ThemeProvider } from './context/ThemeContext';
import { PaginationProvider } from './context/paginationContext';
import FusionCharts from 'fusioncharts';
import { BreadCrumbProvider } from './context/breadcrumbContext';
import initTranslations from './i18n';
import { cleanLanguageCode } from './helpers/languages';
import { HelmetProvider } from 'react-helmet-async';

FusionCharts.options.license({
  key: process.env.REACT_APP_FUSIONCHARTS,
  creditLabel: false,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
configureApi(process.env.REACT_APP_SALES_API_URL);

const container = document.getElementById('adminRoot');
const root = ReactDOM.createRoot(container);

const queryClient = new QueryClient();

initTranslations();

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ContextProvider>
            <ThemeProvider>
              <PaginationProvider>
                <BreadCrumbProvider>
                  <App />
                </BreadCrumbProvider>
              </PaginationProvider>
            </ThemeProvider>
          </ContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>,
);
reportWebVitals();
