import { useState, useEffect } from 'react';

const useExpirationTimer = (expirationSeconds) => {
  const [expirationTime, setExpirationTime] = useState('');
  const [time, setTime] = useState({ days: '', hours: '', seconds: '', minutes: '' });
  const [color, setColor] = useState('loading');

  useEffect(() => {
    const initialExpirationDate = new Date();
    initialExpirationDate.setSeconds(initialExpirationDate.getSeconds() + expirationSeconds);

    const intervalId = setInterval(() => {
      const now = new Date();

      if (initialExpirationDate <= now) {
        setExpirationTime('-- : --');
        setColor('expired');
        clearInterval(intervalId);
        return;
      }

      const difference = Math.floor((initialExpirationDate - now) / 1000);

      // Cálculo manual de días, horas, minutos y segundos
      const days = Math.floor(difference / (3600 * 24));
      const hours = Math.floor((difference % (3600 * 24)) / 3600);
      const minutes = Math.floor((difference % 3600) / 60);
      const seconds = difference % 60;

      let formattedTime;
      let newColor;

      if (days > 0) {
        formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else if (hours > 0) {
        formattedTime = `${hours}h ${minutes}m ${seconds}s`;
      } else if (minutes > 0) {
        formattedTime = `${minutes}m ${seconds}s`;
      } else {
        formattedTime = `${seconds}s`;
      }

      if (difference < 300) {
        newColor = 'yellow-light';
      } else if (difference < 600) {
        newColor = 'yellow';
      } else if (difference <= 1000) {
        newColor = 'blue';
      } else {
        newColor = 'blue-full';
      }

      setExpirationTime(formattedTime);
      setTime({ days, hours, minutes, seconds });
      setColor(newColor);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [expirationSeconds]);

  return {
    expirationTime,
    color,
    loading: color === 'loading',
    days: time.days,
    minutes: time.minutes,
    hours: time.hours,
    seconds: time.seconds,
  };
};

export default useExpirationTimer;