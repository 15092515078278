import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCreateBreadCrumbs } from '../../hooks';
import Details from '../../components/reservation/details';
import { useTranslation } from 'react-i18next';
//import './reservation-detail.css';

const ReservationDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.details.reservation.breadcrumbs',
  });
  const isFromDispute = location?.pathname?.includes('/disputes/reservation');
  const isFromRefunds = location?.pathname?.includes('/refunds/reservation');
  const isFromMonitor = location?.pathname?.includes('/transactions/reservation');
  const isFromCompensations = location?.pathname?.includes('/accounts/reservation');
  useCreateBreadCrumbs([
    {
      name: isFromDispute
        ? t('prevDispute')
        : isFromRefunds
        ? t('prevRefund')
        : isFromMonitor
        ? t('prevMonitor')
        : isFromCompensations
        ? t('prevCompensation')
        : t('prev'),
      path: isFromDispute
        ? '/disputes'
        : isFromRefunds
        ? '/refunds'
        : isFromMonitor
        ? '/transactions'
        : isFromCompensations
        ? '/accounts'
        : '/reservation-history',
    },
    {
      name: t('current'),
    },
  ]);

  return <Details id={id} mode={'reservation'} />;
};

export default ReservationDetails;
