import React from 'react';
import { CampaignCard, Column2d, Pie3D, Show, TimeLine } from '../..';
import styles from './graphicsGrid.module.css';
import '../campaign.css';
import GraphicCard from '../graphicCard/graphicCard';
import SmallGraphicCard from '../graphicCard/smallGraphicCard';
import { useTranslation } from 'react-i18next';
import { getOccasionText } from '../../../utils/getOcassionIcon';
import { getReservationStatus } from '../../../helpers/campaigns';
import { CurrencyIcon, PaxIcon, UpGraphicIcon } from '../../../uiComponents/icons';
import { useSelector } from 'react-redux';
const GraphicsGrid = ({
  barGraphicData,
  occassionsPie,
  eventsPie,
  timeline,
  avgIncomes,
  avgCommissions,
  avgPax,
  mode,
  allCards = null,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: `screens.campaigns.statistics.gridGraphics`,
  });
  const { t: translate } = useTranslation();
  const currency = useSelector((state) => state.app.selectedOrganization);

  // Style Graphics configurations
  const isLaptop = window.innerWidth < 1400;
  let heightColums = isLaptop ? '480' : '650';
  let heightPie = isLaptop ? '180' : '300';
  let heightCard = isLaptop ? '80' : '130';
  let configPie = isLaptop
    ? {
        legendXPosition: '250',
        legendYPosition: '40',
        legendItemFontSize: '11',
        chartLeftMargin: -150,
      }
    : {
        legendXPosition: '400',
        legendYPosition: '90',
        legendItemFontSize: '13',
        chartLeftMargin: -350,
      };

  // Functions
  const getDataForColumn = (data) => {
    let dataFormatted = data.map((a) => ({
      value: a.value,
      toolText: `${a.value}`,
      label: `${a.value} <br/> ${t(`barReservation.${a.key}`)}`,
    }));
    return dataFormatted;
  };
  function formatPosObjects(posObjects) {
    return posObjects?.items;
  }

  const barGraphicDataFormatted = getDataForColumn(barGraphicData);
  const occassionsDataFormatted = occassionsPie
    .sort((a, b) => b.value - a.value)
    .map((occasion) => ({
      label: getOccasionText(occasion.key, translate),
      value: occasion.value,
    }));
  const eventsDataFormatted = eventsPie
    .sort((a, b) => b.value - a.value)
    .map((occasion) => ({
      label: getReservationStatus(occasion.key, translate),
      value: occasion.value,
    }));
  const timeLineData = formatPosObjects(timeline) ?? [];
  const timeLineDataFormatted = timeLineData.map((c) => ({
    label: c.group,
    value: c.income,
    toolText: `${currency.currencySymbol} ${c.income?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
  }));

  // Declare variables outside the if block
  let avgIncome, lastAvgIncomes, avgIncomeItems;
  let avgPaxs, lastAvgPaxs, avgPaxItems;
  let avgCommissionsIncome, lastAvgCommissions, avgItemsCommissions;

  // If all cards its Provide  use this lets
  let avgComissionIncomesPerReservation,
    avgIncomePerReservation,
    paxsThatAssisted,
    totalComissionIncomes,
    totalIncomes;
  // If allCards is not provided, extract data from props
  if (allCards === null) {
    ({ avgIncome, lastAVG: lastAvgIncomes, items: avgIncomeItems } = avgIncomes);
    ({ paxs: avgPaxs, lastPaxs: lastAvgPaxs, items: avgPaxItems } = avgPax);
    ({
      avgIncome: avgCommissionsIncome,
      lastAVG: lastAvgCommissions,
      items: avgItemsCommissions,
    } = avgCommissions);
  } else {
    ({
      avgComissionIncomesPerReservation,
      avgIncomePerReservation,
      paxsThatAssisted,
      totalComissionIncomes,
      totalIncomes,
    } = allCards);
  }

  const displayDiagonal =
    timeLineData?.length >= 15 ? { labelDisplay: 'rotate', slantLabel: 1 } : null;

  return (
    <>
      <div className='mt-3'>
        <div className='chart-container'>
          <TimeLine
            data={timeLineDataFormatted}
            chart={{ ...displayDiagonal, canvasLeftMargin: 33, canvasRightMargin: 33 }}
          />
        </div>
      </div>
      <div className={styles.gridContainer}>
        <div className={styles.column1}>
          <GraphicCard title={t('barReservation.title')}>
            <Column2d
              chartConfig={{
                plotToolText: '<b>$toolText</b>',
              }}
              data={barGraphicDataFormatted}
              height={heightColums}
              palettecolors={['#675DFF', '#B3AEFF', '#C7C4FC']}
            />
          </GraphicCard>
        </div>

        <div className={styles.column2}>
          <GraphicCard title={t('ocassions')}>
            <Pie3D
              legendPosition={isLaptop ? 'bottom' : 'right-top'}
              data={occassionsDataFormatted}
              height={heightPie}
              configs={configPie}
            />
          </GraphicCard>
          <GraphicCard title={t('events')}>
            <Pie3D
              legendPosition={isLaptop ? 'bottom' : 'right-top'}
              data={eventsDataFormatted}
              height={heightPie}
              configs={configPie}
            />
          </GraphicCard>
        </div>

        <div className={styles.column3}>
          <Show.When isTrue={mode === 'DETAIL'}>
            <CampaignCard
              title={t('totalIncomes')}
              mainvalue={totalIncomes}
              value={avgIncomePerReservation}
              valueText={t('avgReservation')}
              text={null}
              isMoney={true}
              icon={<UpGraphicIcon size='36' color='#EAECF0' />}
              data={null}
            />
            <CampaignCard
              title={t('commissions')}
              mainvalue={totalComissionIncomes}
              value={avgComissionIncomesPerReservation}
              valueText={t('avgReservation')}
              text={null}
              isMoney={true}
              icon={<CurrencyIcon width='21' height='40' color='#EAECF0' />}
              data={null}
            />
            <CampaignCard
              title={t('totalPaxs')}
              mainvalue={paxsThatAssisted}
              valueText={t('avgReservation')}
              text={t('paxsAmount')}
              isMoney={false}
              data={null}
              icon={<PaxIcon width='40' height='28' color='#EAECF0' />}
            />
          </Show.When>
          <Show.When isTrue={mode === 'GENERAL'}>
            <SmallGraphicCard
              className={'small-card-campaign'}
              value={avgIncome}
              title={t('incomesAverage')}
              beforePeriod={t('beforePeriod')}
              data={avgIncomeItems}
              beforeValue={lastAvgIncomes}
              heightCard={heightCard}
            />
            <SmallGraphicCard
              className={'small-card-campaign'}
              title={t('commissionesAverage')}
              value={avgCommissionsIncome}
              beforePeriod={t('beforePeriod')}
              data={avgItemsCommissions}
              beforeValue={lastAvgCommissions}
              heightCard={heightCard}
            />
            <SmallGraphicCard
              className={'small-card-campaign'}
              title={t('paxs')}
              value={avgPaxs}
              isMoney={false}
              beforePeriod={t('beforePeriod')}
              data={avgPaxItems}
              beforeValue={lastAvgPaxs}
              heightCard={heightCard}
            />
          </Show.When>
        </div>
      </div>
    </>
  );
};

export default GraphicsGrid;
