const DocumentIcon = ({
  size = '16px',
  color = 'currentColor'
}) => {
  return (
    <svg height={size} width={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35.39 47.64'>
      <g>
        <g>
          <path
            d='M35.29,12.16c-.06-.15-.15-.28-.27-.4,0,0,0,0,0,0L23.48.36s-.09-.06-.14-.09c-.08-.06-.16-.13-.26-.17-.15-.06-.31-.1-.47-.1H1.24C.55,0,0,.55,0,1.24v45.17c0,.68.55,1.24,1.24,1.24h32.92c.68,0,1.24-.55,1.24-1.24V12.64c0-.17-.03-.33-.1-.48ZM23.85,4.19l7.3,7.21h-7.3v-7.21ZM2.47,45.17V2.47h18.9v10.16c0,.68.55,1.24,1.24,1.24h10.31v31.3H2.47Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M25.92,22.59H8.93c-.68,0-1.24.55-1.24,1.24s.55,1.24,1.24,1.24h16.99c.68,0,1.24-.55,1.24-1.24s-.55-1.24-1.24-1.24Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M25.92,29H8.93c-.68,0-1.24.55-1.24,1.24s.55,1.24,1.24,1.24h16.99c.68,0,1.24-.55,1.24-1.24s-.55-1.24-1.24-1.24Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M25.92,35.41H8.93c-.68,0-1.24.55-1.24,1.24s.55,1.24,1.24,1.24h16.99c.68,0,1.24-.55,1.24-1.24s-.55-1.24-1.24-1.24Z'
            fill={color}
            strokeWidth='0'
          />
        </g>
      </g>
    </svg>
  );
};

export default DocumentIcon;
