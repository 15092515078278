import React, { useContext, useState } from 'react';
import { TableRow, TableCell, TableHead } from '../../components';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  BreadCrumbs,
  CheckBox,
  Loading,
  Money,
  Toast,
  UserImage,
} from '../../uiComponents/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { getBatchByIds, getOrganizationsBatchs, postBatch, putAdjustment } from '../../services';
import ModalComment from './modalComment';
import { DetailIcon, FwPlusIcon } from '../../uiComponents/icons';
import CommissionTable from '../../components/table/commissionTable';
import { useCreateBreadCrumbs, useUser } from '../../hooks';
import { hasAllowedRoles } from '../../helpers/roles';
import { paymentBatchBadge } from '../../helpers/status';
import { useMemo } from 'react';

const PaymentBatch = () => {
  const { id } = useParams();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const totalAmount = useMemo(
    () => items.filter((i) => i.isApproved).reduce((total, i) => total + i.amount, 0),
    [items],
  );

  const { roles } = useUser();

  const batchQuery = useQuery({
    queryKey: ['batch', id],
    queryFn: () => getBatchByIds(welletContext, organization.marketId, id),
    refetchOnWindowFocus: false,
    enabled: id !== undefined,
    onSuccess: (data) => {
      setItems(
        data.data.detail.map((i) => ({
          amount: i.amount,
          sellerId: i.sellerUserId,
          id: i.id,
          name: i.sellerName,
          comment: i.comments,
          isApproved: isPending ? true : i.isApproved,
        })),
      );
    },
  });

  const batchMutation = useMutation({
    mutationFn: (data) => {
      const body = items
        .filter((i) => i.isApproved === false)
        .map((i) => ({
          paymentId: i.id,
          comments: data?.comment,
        }));
      return postBatch(welletContext, id, { disapprovedPayments: body });
    },
  });

  const handleCheck = (batchId) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === batchId ? { ...item, comment: '', isApproved: false } : item,
      ),
    );
  };

  const handleReCheck = (batchId) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === batchId ? { ...item, comment: '', isApproved: true } : item,
      ),
    );
  };

  const allApproved = items.length > 0 && items.filter((i) => i.isApproved === false).length === 0;

  const handleApproveAll = (approved) => {
    setItems((prevItems) =>
      prevItems.map((item) => ({ ...item, comment: '', isApproved: approved })),
    );
  };

  const batchInfo = batchQuery?.data?.data;
  const isPending = batchInfo?.batch?.status === 'PENDING_REVIEW';

  const excludedUsers = items.filter((i) => i.isApproved === false).map((u) => u.sellerId);

  const headerAdmin =
    batchInfo?.batch?.status === 'PENDING_REVIEW'
      ? ['', 'Afiliado', 'Monto', '']
      : ['Estado', 'Afiliado', 'Monto', 'Comentario', ''];

  const isFromApprovals = location.pathname.split('/')[1] === 'conciergeCommissions';
  const isFromPayments = location.pathname.split('/')[1] === 'payments';

  const crumbs = [
    {
      name: isFromApprovals ? 'Lotes de Pagos' : isFromPayments ? 'Lotes de Pagos' : 'Pagos',
      path: isFromApprovals ? location.state : isFromPayments ? location.state : '/paymentList',
    },
    {
      name: 'Auditar lotes de pagos',
      path: isFromApprovals ? '/conciergeCommissions/' + id : '/paymentList' + id,
    },
  ];

  useCreateBreadCrumbs(crumbs)

  return (
    <>
      {batchMutation.isSuccess && (
        <Toast
          message={`Comisiones revisadas satisfactoriamente.`}
          type='SUCCESS'
          onCallback={() => {
            batchMutation.reset();
            batchQuery.refetch();
          }}
        />
      )}
      {batchQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='container-page color-body font-weight-semibold'>
            <div className='container-header'>
              <div className='row'>
                <div className='col'>
                  <div className='light-grey'>{batchQuery?.data?.data?.batch?.description}</div>
                  <div className='container-title'>
                    <Money value={totalAmount} currencyCode={batchQuery?.data?.data?.batch.currency} />
                    <div className='d-inline ml-3'>
                      {paymentBatchBadge(batchQuery?.data?.data?.batch?.status)}
                    </div>
                  </div>
                </div>
                <div className='col-auto my-auto'>
                  <div className='row'>
                    <div className='col-auto my-auto'>
                      {batchQuery?.data?.data?.batch?.status === 'PENDING_REVIEW' ? (
                        <button className='btn-wellet btn-accept' onClick={() => setOpen(true)}>
                          <div
                            style={{
                              width: '8px',
                              height: '8px',
                              background: 'rgb(23; 178; 106)',
                              borderRadius: '9999px',
                              display: 'inline-block',
                              marginBottom: '1px',
                              marginRight: '0.25rem',
                              backgroundColor: '#17B26A',
                            }}
                          ></div>
                          Enviar a Pagar
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-2'>
            <CommissionTable
              data={batchQuery?.data?.data?.detail ?? [{}]}
              columns={headerAdmin}
              renderHeader={() => (
                <>
                  <TableHead style={{ width: 'auto' }}>
                    <CheckBox
                      direction='column'
                      label={''}
                      checked={allApproved}
                      handleCheck={() => handleApproveAll(!allApproved)}
                      className={'ml-1'}
                      isDisabled={!isPending}
                    />
                  </TableHead>
                  <TableHead className='sorting text-left text-sidebar-link ctm-text-small'>
                    Comisión
                  </TableHead>
                  <TableHead className='sorting text-left text-sidebar-link ctm-text-small'>
                    Afiliado
                  </TableHead>
                  <TableHead
                    style={{ verticalAlign: 'bottom' }}
                    className={`${isPending ? 'text-right' : ''}`}
                  >
                    &nbsp;
                  </TableHead>
                </>
              )}
              renderRow={(batch, index) => (
                <TableRow
                  key={batch.id}
                  index={index}
                  onClick={() => {
                    if (isPending) {
                      if (items.find((i) => i.id === batch.id)?.isApproved) {
                        handleCheck(batch.id);
                      } else {
                        handleReCheck(batch.id);
                      }
                    }
                  }}
                >
                  <TableCell style={{ width: '20px'}}>
                      <div onClick={(e) => e.stopPropagation()}>
                        <CheckBox
                          className={'ml-1'}
                          checked={items.find((i) => i.id === batch.id)?.isApproved}
                          isDisabled={!isPending}
                          handleCheck={() => {
                            if (items.find((i) => i.id === batch.id)?.isApproved) {
                              handleCheck(batch.id);
                            } else {
                              handleReCheck(batch.id);
                            }
                          }}
                          
                        />
                      </div>
                    
                  </TableCell>
                  <TableCell className='font-weight-semibold'>
                    <Money value={batch.amount} />
                  </TableCell>
                  <TableCell>
                    <div className='row'>
                      <div className='col-auto pr-0'>
                        <div className='profile-pic'>
                          <UserImage
                            url={batch.sellerProfilePicture}
                            name={batch.sellerName}
                            className='mr-1'
                            size={'40px'}
                          />
                        </div>
                      </div>
                      <div className='col'>
                        <div className='font-weight-semibold'>{batch.sellerName}</div>
                        <div className='dark-grey'>Afiliado Digital</div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className='text-left'><a href={`/conciergeCommissions/${batchQuery?.data?.data?.batch?.id}/${batch.sellerUserId}`} target='_blank'>Ver</a></TableCell>
                </TableRow>
              )}
            />
          </div>
          <ModalComment
            open={open}
            showComment={!allApproved}
            title={'Envia a pagar'}
            fields={
              !allApproved
                ? [{ name: 'comment', placeholder: '', label: 'Comentario', isRequired: true }]
                : []
            }
            maxWidth={'480px'}
            placeholder='Ingrese un comentario'
            onClose={() => setOpen(false)}
            handleSubmit={(data) => {
              setOpen(false);
              batchMutation.mutate(data);
            }}
            body={
              <div className='text-left py-2'>
                <div className='font-weight-bold'>
                  ¿Estás seguro que deseas enviar a pagar{' '}
                  {<Money value={totalAmount} currencyCode={batchQuery?.data?.data?.batch.currency} />}?
                </div>
              </div>
            }
            textSubmit={'Envíar'}
          />
        </>
      )}
    </>
  );
};

export default PaymentBatch;
