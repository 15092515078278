import React, { useContext, useState } from 'react';
import { Badge, Button, IconButton, Loading, Slider, Status } from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchActivities, orderByPriority } from '../../services/activityServices';
import { WelletContext } from '../../context/wellet/welletContext';
import { PageHeader, TableCell } from '../../components';
import useUser from '../../hooks/useUser';
import Popup from 'reactjs-popup';
import { MenuBurger, PencilIcon } from '../../uiComponents/icons';
import { hasAllowedRoles } from '../../helpers/roles';
import { useTranslation } from 'react-i18next';
import { useCreateBreadCrumbs } from '../../hooks';
import DragDropTableOLD from '../../components/table/dragTableOLD';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Activity = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.restaurants' });
  const navigate = useNavigate();
  const { roles } = useUser();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const { getClientTranslation } = useGenericTranslation();
  const [showInactives, setShowInactives] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const activityQuery = useQuery({
    queryKey: ['activities', organization.id, showInactives],
    queryFn: () => fetchActivities(welletContext, organization.id, showInactives),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const activityPriorityMutation = useMutation({
    mutationFn: (body) => {
      return orderByPriority(welletContext, organization.id, body);
    },
    onSuccess: () => {
      activityQuery.refetch();
    },
  });
  const queryClient = useQueryClient();

  const handleDragEnd = (result) => {
    const currentData = queryClient.getQueryData(['activities', organization.id, showInactives]);

    if (!currentData || !result.destination) {
      return;
    }

    const items = [...currentData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    activityPriorityMutation.mutate(items.map((item) => item.id));
    queryClient.setQueryData(['activities', organization.id, showInactives], items);
  };

  const columns = [
    '',
    getClientTranslation('title.singular'),
    t('table.lng'),
    t('table.priority'),
    t('table.location'),
    t('table.capacity'),
    t('table.state'),
  ];

  useCreateBreadCrumbs([{ name: getClientTranslation('title.plural'), path: '/restaurantes' }]);

  return (
    <>
      <PageHeader
        title={getClientTranslation('title.plural')}
        endComponent={
          hasAllowedRoles(roles, ['superadministrator']) && (
            <Button
              className={'btn-wellet-secondary'}
              onClick={() => navigate('/restaurantes/nuevo-restaurante')}
              size={'medium'}
              text={`+ ${t('btnCreate', { title: getClientTranslation('title.singular').toLocaleLowerCase()})}`}
            />
          )
        }
      />
      {activityQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='my-2 row align-items-center'>
            <Popup
              on={'hover'}
              trigger={
                <div>
                  <Slider
                    className={'col-auto'}
                    defaultChecked={showAll}
                    onClick={() => setShowAll(!showAll)}
                    label={t('priority')}
                  />
                </div>
              }
            >
              <div className='tooltipCard'>{t('tooltip')}</div>
            </Popup>
            <Slider
              className={'col-auto'}
              label={t('onlyActives')}
              onClick={() => setShowInactives(!showInactives)}
              defaultChecked={!showInactives}
            />
          </div>

          <DragDropTableOLD
            tableKey={`activity-${organization.id}-${showAll}`}
            data={activityQuery?.data ?? []}
            columns={columns}
            perPage={
              showAll ? (activityQuery.data?.length > 0 ? activityQuery.data?.length : 10) : 10
            }
            disableDrag={!showAll}
            handleDragEnd={(results) => handleDragEnd(results)}
            renderRow={(activity, index, provided) => (
              <tr
                key={String(index)}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableCell
                  className='ctm-text font-weight-bold text-center'
                  style={{ width: showAll ? '80px' : '60px' }}
                  type={'primary'}
                >
                  <div className='d-flex align-items-center justify-content-center'>
                    {showAll ? (
                      <div className='mr-1'>
                        <MenuBurger size='15px' color='currentColor' />
                      </div>
                    ) : (
                      <></>
                    )}
                    <IconButton
                      onClick={() => navigate(`/restaurantes/editar-restaurante/${activity.id}`)}
                      icon={<PencilIcon />}
                    />
                    <IconButton
                      onClick={() => navigate(`/restaurantes/schedule/${activity.id}`)}
                      icon={<i className='far fa-clock'></i>}
                    />
                  </div>
                </TableCell>
                <TableCell style={{ width: '220px' }}>
                  {activity.logoImageUrl ? (
                    <img
                      className='mr-2'
                      style={{
                        height: '35px',
                        width: '35px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      src={activity.logoImageUrl}
                      alt={''}
                    />
                  ) : (
                    <></>
                  )}
                  {activity.name}
                </TableCell>
                <TableCell style={{ width: '120px' }}>
                  {activity.langShows.map((s, i, shows) =>
                    i === shows.length - 1 ? s.language : `${s.language} | `,
                  )}
                </TableCell>
                <TableCell style={{ width: '120px' }}>{activity.priority}</TableCell>
                <TableCell style={{ width: '200px' }}>{activity.location}</TableCell>
                <TableCell style={{ width: '120px' }}>{activity.capacity}</TableCell>
                <TableCell style={{ width: '120px' }}>
                  <Status
                    status={activity.isActive ? true : false}
                    text={activity.isActive ? t('active') : t('inactive')}
                  />
                </TableCell>
              </tr>
            )}
          />
        </>
      )}
    </>
  );
};

export default Activity;
