import React, { useContext, useEffect, useState } from 'react';
import {
  PageHeader,
  Table,
  FilterCard,
  TableRow,
  TableCell,
  DateFilter,
  Show,
  InfoRenderer,
} from '../../components';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  AffiliateCard,
  AmountFilter,
  Button,
  ButtonFilter,
  Input,
  Loading,
  Money,
  OrganizationCard,
  PageError,
  Toast,
} from '../../uiComponents/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { downloadReservations, getReservationsHistory } from '../../services';
import {
  useCreateBreadCrumbs,
  useGetBosses,
  useGetRPs,
  useGetRestaurants,
  useGetSellers,
  useLocalStorage,
  useOrderBy,
  usePagination,
  useUser,
} from '../../hooks';
import useDebounce from '../../hooks/useDebounce';
import { SearchIcon } from '../../uiComponents/icons';
import { commissionBadge } from '../../helpers/status';
import downloadFileFromResponse from '../../utils/downloadFile';
import { hasAllowedRoles } from '../../helpers/roles';
import { consumptionStatus, reservationStatus, reservationTypes } from '../../data';
import { useTranslation } from 'react-i18next';
import { filtersFormat, getCancelledBy, getReservationType } from '../../helpers/format';
import mergeWithDefaults from '../../helpers/localstorage';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { useGetCities } from '../../hooks/queries/useGetCities';
import { getOccasionText } from '../../utils/getOcassionIcon';
import { formatDateTranslation } from '../../helpers/dates';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const ReservationsHistory = () => {
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const welletContext = useContext(WelletContext);

  const location = useLocation();
  const navigate = useNavigate();
  const language = useSelector((state) => state.app.language);
  const { t: translate } = useTranslation();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'screens.reservations' });
  const { roles } = useUser();
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-reservation', {
    filterStatus: reservationStatus(translate),
    location: [],
    filterField: '',
    concierge: [],
    rp: [],
    boss: [],
    filterByUrl: null,
    filterAmount: '',
    filterConsumption: consumptionStatus(translate),
    page: 1,
  });
  const [isOpen, setIsOpen] = useState(false);
  const { orderBy, orderType, handleColumnClick } = useOrderBy();
  const { getClientTranslation } = useGenericTranslation();
  // filters
  const [filterConsumptionAmount, setFilterConsumptionAmount] = useState(
    filterLocalStorage.filterAmount,
  );
  const [filterConsumption, setFilterConsumption] = useState(filterLocalStorage.filterConsumption);
  const [filterStatus, setFilterStatus] = useState(filterLocalStorage.filterStatus);
  const [filterReservationType, setFilterReservationType] = useState(
    filterLocalStorage?.reservationType?.length > 0
      ? mergeWithDefaults(filterLocalStorage.reservationType, reservationTypes(translate))
      : reservationTypes(translate),
  );
  const [filterCities, setFilterCities] = useState(filterLocalStorage.citiesIds ?? []);
  const showFilterCard =
    filtersFormat(filterStatus).length === 0 && filterLocalStorage.filterByUrl === 'custom'
      ? []
      : filterLocalStorage.filterByUrl
      ? filterLocalStorage.filterByUrl.split('-')
      : [];
  const [filter, setFilter] = useState(showFilterCard);
  const [filterRestaurante, setFilterRestaurante] = useState(filterLocalStorage.location);
  const [filterRP, setFilterRP] = useState(filterLocalStorage.rp);
  const [filterUser, setFilterUser] = useState(filterLocalStorage.concierge);
  const [filterBoss, setFilterBoss] = useState(filterLocalStorage.boss);
  const [value, setValue] = useState(filterLocalStorage.filterField);
  const dateType = useSelector((state) => state.app.dateType);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
  );
  const debouncedSearch = useDebounce(value, 700);

  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const filterByStatus =
    filtersFormat(filterStatus).length === 0
      ? filter.length > 0 && filter[0] !== 'custom'
        ? filter
        : []
      : filtersFormat(filterStatus).flat();

  const navigateToReservationDetail = (id) => {
    navigate(`${id}`, { state: location.pathname + location.search });
  };
  const onFilterClick = (filter) => {
    setFilter(filter);
    handlePageChange(1);
    const filterStatusClean = filterStatus.map((f) => ({ ...f, isActive: false }));
    setFilterStatus(filterStatusClean);
    const filterParams = filter?.map((f) => `${f}`).join('-');
    setFilterLocalStorage({ ...filterLocalStorage, filterByUrl: filterParams, page: 1 });
  };

  // Main API Query
  const reservationsQuery = useQuery({
    queryKey: [
      'reservations-history',
      organization.id,
      filter,
      selectedFormattedDates,
      limit,
      skip,
      debouncedSearch,
      dateType,
      filtersFormat(filterUser),
      filtersFormat(filterStatus),
      filtersFormat(filterRP),
      filtersFormat(filterBoss),
      filtersFormat(filterRestaurante),
      orderBy,
      orderType,
      filterConsumptionAmount,
      filtersFormat(filterConsumption),
      filtersFormat(filterReservationType),
      filtersFormat(filterCities),
    ],
    queryFn: () =>
      getReservationsHistory(
        welletContext,
        limit,
        skip,
        organization.marketId,
        Number(organization.id),
        filterByStatus,
        selectedFormattedDates,
        debouncedSearch.trim(),
        dateType.toLocaleUpperCase(),
        filtersFormat(filterUser),
        filtersFormat(filterRP),
        filtersFormat(filterRestaurante),
        filtersFormat(filterBoss),
        orderBy,
        orderType,
        filterConsumptionAmount,
        filtersFormat(filterConsumption),
        filtersFormat(filterReservationType),
        filtersFormat(filterCities),
      ),
    onSuccess: (data) => {
      setTotalAndReset(data?.data.reservations?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        filterStatus,
        location: filterRestaurante,
        filterField: value,
        concierge: filterUser,
        rp: filterRP,
        boss: filterBoss,
        page: currentPage,
        filterAmount: filterConsumptionAmount,
        filterConsumption: filterConsumption,
        reservationType: filterReservationType,
        citiesIds: filterCities,
      });
    },
    ...defaultQueryOptions,
    keepPreviousData: true,
    enabled: filter !== null,
  });
  // Hooks Query Filters
  const rpQuery = useGetRPs(organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.map((user) => ({
        label: user.fullName,
        value: user.id,
        isActive:
          filterLocalStorage.rp.length > 0
            ? filtersFormat(filterLocalStorage.rp).includes(user.id)
            : false,
      }));
      setFilterRP(response);
    },
  });
  const bossQuery = useGetBosses(organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.map((user) => ({
        label: user.fullName,
        value: user.id,
        isActive:
          filterLocalStorage.boss.length > 0
            ? filtersFormat(filterLocalStorage.boss).includes(user.id)
            : false,
      }));
      setFilterBoss(response);
    },
  });
  const sellersQuery = useGetSellers(organization.id, organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.items?.map((user) => ({
        label: user.name,
        value: user.sid,
        isActive:
          filterLocalStorage.concierge.length > 0
            ? filtersFormat(filterLocalStorage.concierge).includes(user.sid)
            : false,
      }));
      setFilterUser(response);
    },
    enabled: isOpen && filterUser.length === 0,
  });
  const restaurantesQuery = useGetRestaurants(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((org) => ({
        label: org.name,
        value: org.id,
        isActive:
          filterLocalStorage.location.length > 0
            ? filtersFormat(filterLocalStorage.location).includes(org.id)
            : false,
      }));
      setFilterRestaurante(response);
    },
  });
  const citiesQuery = useGetCities(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((city) => ({
        label: city.name,
        value: city.id,
        isActive:
          filterLocalStorage?.citiesIds?.length > 0
            ? filtersFormat(filterLocalStorage?.citiesIds).includes(city.id)
            : false,
      }));
      setFilterCities(response);
    },
  });

  // Query Mutation
  const downloadReservationMutation = useMutation({
    mutationFn: () => {
      const response = downloadReservations(welletContext, {
        organizationId: !organization.isMarket ? Number(organization.id) : null,
        marketSetupId: organization.marketId,
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        status: filterByStatus,
        filterField: debouncedSearch.trim(),
        dateType: dateType.toLocaleUpperCase(),
        userId: filtersFormat(filterUser),
        rpId: filtersFormat(filterRP),
        showId: filtersFormat(filterRestaurante),
        consumptionAmount: filterConsumptionAmount ? filterConsumptionAmount : 0,
        consumptionFilterEnum:
          filtersFormat(filterConsumption).length > 0 ? filtersFormat(filterConsumption)[0] : null,
        reservationType:
          filtersFormat(filterReservationType).length > 0
            ? filtersFormat(filterReservationType)
            : null,
        citiesIds: filtersFormat(filterCities).length > 0 ? filtersFormat(filterCities) : [],
      });

      return response;
    },
    onSuccess: (data) => downloadFileFromResponse(data, 'reservations.xlsx'),
  });

  const headerAdmin = [
    { title: t('table.code'), value: '', filterable: false },
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.schedule'), value: 'reservationDateLocalTime', filterable: true },
    { title: t('table.consumption'), value: 'totalAmount', filterable: true },
    { title: t('table.client'), value: '', filterable: false },
    { title: t('table.pax'), value: 'paxs', filterable: true },
    { title: t('table.concierge'), value: '', filterable: false },
    { title: t('table.tpye'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs([{ name: t('title') }]);
  useEffect(() => {
    setFilterStatus(
      mergeWithDefaults(filterLocalStorage.filterStatus, reservationStatus(translate)),
    );
    setFilterConsumption(
      mergeWithDefaults(filterLocalStorage.filterConsumption, consumptionStatus(translate)),
    );
  }, [i18n.language]);

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={<DateFilter filterName={'Fecha'} isMultipleDate={true} />}
      />
      <Show>
        <Show.When
          isTrue={reservationsQuery.isLoading || restaurantesQuery.isLoading || bossQuery.isLoading}
        >
          <Loading />
        </Show.When>
        <Show.When isTrue={reservationsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row px-1 my-3'>
            <FilterCard
              category={t('cardFilter.total')}
              quantity={reservationsQuery?.data?.data?.totalCount}
              onClick={() => onFilterClick([])}
              className='reservations col'
              isSelected={filter.length === 0}
            />
            <FilterCard
              category={t('cardFilter.pendings')}
              quantity={reservationsQuery?.data?.data?.pendingCount}
              onClick={() => onFilterClick(['PENDING'])}
              isSelected={filter[0] === 'PENDING'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.confirms')}
              quantity={reservationsQuery?.data?.data?.confirmedCount}
              onClick={() => onFilterClick(['CONFIRMED'])}
              isSelected={filter[0] === 'CONFIRMED'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.cancels')}
              quantity={reservationsQuery?.data?.data?.cancelledCount}
              onClick={() => onFilterClick(['CANCELLED'])}
              className='reservations col'
              isSelected={filter[0] === 'CANCELLED'}
            />
            <FilterCard
              category={t('cardFilter.rejecte')}
              className='reservations col'
              quantity={reservationsQuery?.data?.data?.rejectedCount}
              onClick={() => onFilterClick(['REJECTED'])}
              isSelected={filter[0] === 'REJECTED'}
            />
            <FilterCard
              category={t('cardFilter.openTabel')}
              quantity={reservationsQuery?.data?.data?.checkinCount}
              onClick={() => onFilterClick(['CHECKIN'])}
              isSelected={filter[0] === 'CHECKIN'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.regConsumption')}
              quantity={reservationsQuery?.data?.data?.payingCount}
              onClick={() => onFilterClick(['PAYING'])}
              isSelected={filter[0] === 'PAYING'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.finaly')}
              quantity={reservationsQuery?.data?.data?.closedCount}
              onClick={() => onFilterClick(['CLOSED'])}
              isSelected={filter[0] === 'CLOSED'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.missing')}
              quantity={reservationsQuery?.data?.data?.noShowCount}
              onClick={() => onFilterClick(['NO_SHOW', 'NO_SHOW_MANUAL'])}
              isSelected={filter[0] === 'NO_SHOW'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.deposit')}
              quantity={reservationsQuery?.data?.data?.waitingDepoCount}
              onClick={() => onFilterClick(['WAITING_DEPOSIT'])}
              isSelected={filter[0] === 'WAITING_DEPOSIT'}
              className='reservations col'
            />
          </div>
          <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
            <div className='col col-sm-auto align-items-center justify-content-center'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                customClass='mb-0 mr-3'
                name={'search'}
                value={value}
                onChangeValue={(e) => setValue(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <div className='row'>
              <Show.When isTrue={organization.isMarket}>
                <div className='col-auto mb-1  pl-1 justify-content-center'>
                  <ButtonFilter
                    isSearchable={true}
                    filterName={getClientTranslation('title.plural')}
                    filterOptions={filterRestaurante}
                    callback={setFilterRestaurante}
                    error={restaurantesQuery.isError}
                  />
                </div>
              </Show.When>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonsFilter.rp')}
                  filterOptions={filterRP}
                  callback={setFilterRP}
                  error={rpQuery.isError}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonsFilter.bossSeller')}
                  filterOptions={filterBoss}
                  callback={setFilterBoss}
                  error={bossQuery.isError}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonsFilter.concierge')}
                  filterOptions={filterUser}
                  loading={sellersQuery.isLoading}
                  error={sellersQuery.isError}
                  onClick={() => setIsOpen(true)}
                  callback={(data) => {
                    setFilterUser(data);
                  }}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <AmountFilter
                  filterName={t('buttonsFilter.consumption')}
                  initialValue={filterConsumptionAmount}
                  filterOptions={filterConsumption}
                  callback={(data) => {
                    setFilterConsumptionAmount(data.amount);
                    setFilterConsumption(data.filter);
                  }}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.typeReserves')}
                  filterOptions={filterReservationType}
                  callback={(data) => {
                    setFilterReservationType(data);
                  }}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.cities')}
                  filterOptions={filterCities}
                  error={citiesQuery.isError}
                  callback={(data) => {
                    setFilterCities(data);
                  }}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.state')}
                  filterOptions={filterStatus}
                  callback={(filter) => {
                    onFilterClick(['custom']);
                    setFilterStatus(filter);
                  }}
                />
              </div>
            </div>
            <Show.When
              isTrue={hasAllowedRoles(roles, [
                'superadministrator',
                'finance',
                'marketadmin',
                'auditor',
                'bossales',
                'salesteamlead',
                'callcenterlead',
                'auditor-onlyview',
              ])}
            >
              <div className='col d-flex justify-content-end'>
                <Button
                  className={'btn-wellet-secondary opacity justify-content-center'}
                  size={'small'}
                  text={t('download')}
                  width={'36px'}
                  disabled={downloadReservationMutation.isLoading}
                  loading={downloadReservationMutation.isLoading}
                  onClick={() => downloadReservationMutation.mutate()}
                />
              </div>
            </Show.When>
          </div>
          <Show>
            <Show.When
              isTrue={
                reservationsQuery.isPreviousData ||
                bossQuery.isLoading ||
                rpQuery.isLoading ||
                citiesQuery.isLoading
              }
            >
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <Table
                data={reservationsQuery?.data?.data?.reservations?.items ?? []}
                hasPagination={false}
                totalP={totalItems}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                columns={headerAdmin}
                onColumnClick={handleColumnClick}
                activeColumn={orderType}
                sortOrder={orderBy}
                renderRow={(reservations, index) => {
                  const { date, time } = formatDateTranslation(
                    reservations.reservationDateLocalTime,
                    language,
                    'extraSmall',
                  );
                  return (
                    <>
                      <>
                        <TableCell className='fw-700' style={{ width: '100px' }}>
                          {reservations.referenceCode}
                        </TableCell>
                        <TableCell style={{ width: '250px' }}>
                          <OrganizationCard
                            name={reservations?.restaurant?.name}
                            date={reservations?.restaurant?.cityName}
                            size={40}
                            imageUrl={reservations?.restaurant?.logo}
                            hightLightName={true}
                          />
                        </TableCell>
                        <TableCell style={{ width: 'auto' }} className={'fw-700'}>
                          <div className='d-flex flex-column '>
                            <span className='pl-1 sentences-capitalize'>{date}</span>
                            <span>{time}</span>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 'auto' }} type={'primary'}>
                          {reservations.consume ? <Money value={reservations.consume} /> : '-'}
                        </TableCell>
                        <TableCell>
                          <div className='fw-700 wrap-text text-capitalize'>
                            {reservations.customerName}
                          </div>
                          <div className='dark-grey wrap-text'>
                            <InfoRenderer
                              content={getOccasionText(reservations.occasion, translate)}
                            />
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 'auto' }}>
                          <div className='fw-700 pl-2'>{reservations.pax}</div>
                        </TableCell>

                        <TableCell style={{ minWidth: '200px' }}>
                          <AffiliateCard
                            company={reservations?.sellerCompany}
                            size={'40px'}
                            isVerified={reservations?.sellerIsVerified}
                            url={reservations?.sellerProfilePicture}
                            name={reservations?.sellerFullName}
                          />
                        </TableCell>
                        <TableCell>
                          <div>{getReservationType(reservations?.reservationType, translate)}</div>
                        </TableCell>

                        <TableCell>
                          {reservations?.status
                            ? commissionBadge(
                                reservations?.status === 'CANCELLED'
                                  ? getCancelledBy(reservations?.cancelledFrom)
                                  : reservations?.status,
                                translate,
                              )
                            : null}
                        </TableCell>
                      </>
                      {index === reservationsQuery?.data?.data?.reservations?.items.length - 1 && (
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#eee' }}>Total</TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}>
                            <Money value={reservationsQuery?.data?.data?.totalOrdersAmount} />
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}>
                            <div className='pl-3'>{reservationsQuery?.data?.data?.totalPax}</div>
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                }}
                onRowClick={(reservations) => navigateToReservationDetail(reservations.id)}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
      <Show.When isTrue={downloadReservationMutation?.isError}>
        <Toast
          message={
            downloadReservationMutation?.error?.response?.status === 413
              ? t('toast.registryError')
              : t('toast.error')
          }
          type='ERROR'
          onCallback={() => {
            downloadReservationMutation.reset();
          }}
        />
      </Show.When>
    </>
  );
};

export default ReservationsHistory;
