import { useCallback, useContext, useState } from 'react';
import SidebarContext from '../../context/sidebarContext';
import styles from './selector.module.css';
import { Dropdown, Loading } from '../../uiComponents/common';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentOrg, setSelectedOrg } from '../../actions/appActions';
import ItemDropdown from '../itemDropdown/itemDropdown';
import useUser from '../../hooks/useUser';
import { hasAllowedRoles } from '../../helpers/roles';
import { ChevronIcon, NoPhotoIcon } from '../../uiComponents/icons';
import Show from '../show/show';
import { useTranslation } from 'react-i18next';

const MarketSelector = ({ isSelected = false, data }) => {
  const { isMouseOver, isSidebarResized } = useContext(SidebarContext);
  const { t } = useTranslation('translation', { keyPrefix: 'components.organizationSelector' });
  const dispacth = useDispatch();
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(null);
  const { user, roles } = useUser();

  const markets = data?.data?.markets
    ?.filter((item) => item.organizationId === selectedOrganization.marketId)
    ?.flatMap((item) => item.organizations)
    ?.map((org) => ({
      name: org.name,
      id: org.organizationId,
      isMarket: org.isMarket,
      logo: org.logo,
    }));

  const handleSubmenuToggle = useCallback((index) => {
    if (index === undefined) return;
    setActiveSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);
  const selectOrganization = (org) => {
    const reduxOrgData = {
      ...selectedOrganization,
      id: +org.id,
      isMarket: org.isMarket,
      name: org.name,
      organizationLogo: org.logo,
    };

    dispacth(setSelectedOrg(reduxOrgData));
    dispacth(setCurrentOrg(reduxOrgData));
  };
  const resetOrganization = () => {
    setActiveSubmenuIndex(null);
    const reduxOrgData = {
      ...selectedOrganization,
      id: selectedOrganization.marketId,
      isMarket: true,
      name: null,
      organizationLogo: null,
    };
    dispacth(setSelectedOrg(reduxOrgData));
    dispacth(setCurrentOrg(reduxOrgData));
  };
  const isAllowed = hasAllowedRoles(roles, [
    'superadministrator',
    'auditor',
    'auditor-onlyview',
    'finance',
    'marketadmin',
    'dashboard',
    'reservationcenter',
    'reservationcenter-onlyview',
    'bosssales',
    'callcenterlead',
    'salesteamlead',
    'checker',
    'productloader',
    'productpriceloader',
    'marketing'
    // 'wellet_finance'
    //'soldout',
  ]);
  const selectorDropdown = (
    <div
      className={`${styles.selector} nav-item  justify-content-${
        isMouseOver || !isSidebarResized ? 'between' : 'center'
      } `}
    >
      <div className='w-100 d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center company-selector-dropdown'>
          <Show>
            <Show.When isTrue={selectedOrganization.name && selectedOrganization.organizationLogo}>
              <img
                className={'mr-2'}
                style={{
                  height: '24px',
                  width: '24px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                src={selectedOrganization.organizationLogo}
                alt={selectedOrganization.name}
              />
              {selectedOrganization.name ? selectedOrganization.name : t('text')}
            </Show.When>
            <Show.When isTrue={selectedOrganization.name && !selectedOrganization.organizationLogo}>
              <NoPhotoIcon size={'24px'} className={''} />
              {selectedOrganization.name ? selectedOrganization.name : t('text')}
            </Show.When>
            <Show.Else>
              {selectedOrganization.name
                ? selectedOrganization.name
                : `${t('text')} ${markets?.length ? `(${markets.length})` : ''}`}
            </Show.Else>
          </Show>
        </div>
        <div className={styles.companyIcon}>
          {isAllowed ? <ChevronIcon rotation={90} color='#667085' /> : null}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`${isSelected ? styles.menuSelected : ''} fw-500 justify-content-${
        isMouseOver || !isSidebarResized ? 'between' : 'center'
      } `}
    >
      {user.organization.isMarket && isAllowed ? (
        <Dropdown
          id={'organizationSelector'}
          btn={selectorDropdown}
          isHover={false}
          customDropdwon={''}
          isMouseOver={isSidebarResized ? isMouseOver : undefined}
          callBack={handleSubmenuToggle}
          renderList={() => (
            <>
              {data.isLoading ? (
                <Loading global={false} />
              ) : (
                <>
                  <div
                    className={`dropdown-item text-general w-100 border-bottom`}
                    onClick={resetOrganization}
                  >
                    <div className='d-flex align-items-center justify-content-between w-100'>
                      <div>
                        {t('text')} {markets?.length ? `(${markets.length})` : ''}
                      </div>
                    </div>
                  </div>

                  {markets &&
                    markets?.map((org, idx) => (
                      <ItemDropdown
                        item={org}
                        key={idx}
                        idx={idx}
                        show={idx === activeSubmenuIndex}
                        setActive={handleSubmenuToggle}
                        isOrganization={true}
                        selectOrganization={selectOrganization}
                      />
                    ))}
                </>
              )}
            </>
          )}
        />
      ) : (
        selectorDropdown
      )}
    </div>
  );
};

export default MarketSelector;
