import React, { useContext, useState } from 'react';
import { PriceForm, Table, TableCell, TableRow } from '../../components';
import {
  BreadCrumbs,
  Button,
  IconButton,
  Loading,
  Modal,
  Money,
  PerformanceWeekdays,
  Subtitle,
  Toast,
  Tooltip,
} from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { deletePrice, getAllPrices, getTicketById } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation, useQuery } from 'react-query';
import { getWeekdays } from '../../helpers/dates';
import { format } from 'date-fns';
import { FwTrashIcon } from '../../uiComponents/icons';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';
import { useParams } from 'react-router-dom';
import { usePagination } from '../../hooks';

const Prices = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [currentPrice, setCurrentPrice] = useState();
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const currentOrg = useSelector((state) => state.app.currentOrganization);

  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();

  const pricesQuery = useQuery({
    queryKey: ['prices', organization.id, id],
    queryFn: () => getAllPrices(welletContext, organization.id, null, null, false, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!organization.id,
    onSuccess: (data) => setTotalAndReset(data?.data?.length),
  });

  const ticketsQuery = useQuery({
    queryKey: ['ticket', id],
    queryFn: () => getTicketById(welletContext, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const deleteQuery = useMutation({
    mutationKey: ['delete-price'],
    mutationFn: (ids) => deletePrice(welletContext, ids),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const handleOpenEdit = (price) => {
    setCurrentPrice(price);
    setOpen(true);
  };
  return (
    <>
      {deleteQuery.isSuccess && (
        <Toast
          message='Price deleted successfully.'
          type='SUCCESS'
          onCallback={() => {
            deleteQuery.reset();
            setDeleteIds([]);
            setOpenDelete(false);
            pricesQuery.refetch();
          }}
        />
      )}
      {pricesQuery.isLoading || ticketsQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          {id && currentOrg.id !== 1 ? (
            <BreadCrumbs
              crumbs={[
                { name: 'Tickets', path: `/tickets` },
                { name: `${ticketsQuery?.data?.data?.name} Prices`, path: `/prices/${id}` },
              ]}
            />
          ) : (
            <></>
          )}
          <Subtitle
            text={
              <h1 className='m-0 ctm-title font-weight-bold'>
                {id ? `${ticketsQuery?.data?.data?.name} Precios` : 'Precios'}
              </h1>
            }
            endComponent={
              <Button
                size={'medium'}
                className={'btn-custom-primary'}
                text={'Agregar Precio'}
                onClick={() => setOpen(true)}
              />
            }
          />
          <Table
            data={pricesQuery?.data?.data}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            columns={[
              '',
              'Precio',
              'Consumisioón Incluida',
              'Tickets',
              'Horarios',
              'Días',
              'Desde',
              'Hasta',
            ]}
            renderRow={(prices, index) => (
              <>
                <TableCell style={{ width: '100px' }} type={'primary'}>
                  <IconButton onClick={() => handleOpenEdit(prices)} icon={<FwEditIcon />} />
                  <IconButton
                    onClick={() => {
                      setDeleteIds(prices.ids);
                      setOpenDelete(true);
                    }}
                    icon={<FwTrashIcon />}
                  />
                </TableCell>
                {organization.id === 1 ? (
                  <TableCell type={'primary'}>{prices.organization.name}</TableCell>
                ) : null}
                <TableCell type={'primary'} className='ctm-text font-weight-bold '>
                  <Money value={prices.price} currencyCode={prices.currency} />
                </TableCell>
                <TableCell type={'primary'} className='ctm-text font-weight-bold '>
                  <Money value={prices.consumption} currencyCode={prices.currency} />
                </TableCell>
                <TableCell type={'primary'}>{prices.ticketProduct.name}</TableCell>
                <TableCell type={'primary'}>
                  <TableCell type={'primary'}>
                    <>
                      <Tooltip
                        marginL='55px'
                        text={
                          prices.performancePriceRule &&
                          prices.performancePriceRule.map((p, i, arr) => {
                            if (i === arr.length - 1) return `${p.activityTime}`;
                            return `${p.activityTime}, `;
                          })
                        }
                      >
                        {prices.performancePriceRule &&
                          (prices.performancePriceRule.length < 6
                            ? prices.performancePriceRule.map((p, i, arr) => {
                                return i === arr.length - 1
                                  ? `${p.activityTime}`
                                  : `${p.activityTime}, `;
                              })
                            : prices.performancePriceRule.slice(0, 6).map((p, i, arr) => {
                                return i === arr.length - 1
                                  ? `${p.activityTime}, more...`
                                  : `${p.activityTime}, `;
                              }))}
                      </Tooltip>
                    </>
                  </TableCell>
                </TableCell>
                <TableCell type={'primary'} style={{ width: '300px' }}>
                  <PerformanceWeekdays performance={prices.weekdays.map((w) => getWeekdays(w))} />
                </TableCell>
                <TableCell type={'primary'}>
                  {prices.fromDate ? format(new Date(prices.fromDate), 'MMM dd, yyyy') : '-'}
                </TableCell>
                <TableCell type={'primary'}>
                  {prices.toDate ? format(new Date(prices.toDate), 'MMM dd, yyyy') : '-'}
                </TableCell>
              </>
            )}
          />
        </>
      )}
      {deleteQuery?.error && deleteQuery?.error?.response?.data ? (
        <Toast
          message={`Error while attempting to delete the price.`}
          type='ERROR'
          onCallback={() => {
            deleteQuery.reset();
            pricesQuery.refetch();
          }}
        />
      ) : (
        <></>
      )}
      <PriceForm
        open={open}
        onClose={() => setOpen(false)}
        price={currentPrice}
        onSuccess={pricesQuery.refetch}
        setPrice={setCurrentPrice}
      />
      <Modal
        idModal={'priceModal'}
        title={'Delete Price'}
        open={openDelete}
        handleSubmit={() => deleteQuery.mutate(deleteIds)}
        onClose={() => {
          setOpenDelete(false);
          setDeleteIds([]);
        }}
        textCancel={'Cancel'}
        textSubmit={'Delete'}
      >
        <div className='modal-background font-weight-bold text-center'>
          Are you sure you want to delete this price?
        </div>
      </Modal>
    </>
  );
};

export default Prices;
