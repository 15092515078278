import React from 'react';
import { Button } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  text,
  className = 'd-flex justify-content-between w-100',
  showText = true,
}) => {
  const { t } = useTranslation();
  let startText = t('components.pagination.pageAt');
  if (text) {
    startText = text;
  }
  return (
    <div className={className}>
      <div className='pagination-text text-general'>
        {showText ? (
          <>
            {startText} {totalPages !== 0 ? currentPage : totalPages}{' '}
            {t('components.pagination.of')} {totalPages}
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Button
          disabled={currentPage === 1}
          size={'small'}
          onClick={() => onPageChange(currentPage - 1)}
          className='btn-custom-white'
          text={t('components.pagination.before')}
        />
        <Button
          size={'small'}
          disabled={currentPage === totalPages || totalPages === 0}
          onClick={() => onPageChange(currentPage + 1)}
          className='ml-2 btn-custom-white'
          text={t('components.pagination.after')}
        />
      </div>
    </div>
  );
};

export default Pagination;
