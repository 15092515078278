import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function WelletOrganization({ checkSelectedOrg = true, onlyMarket = false }) {
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrg = useSelector((state) => state.app.selectedOrganization);
  // TO DO: ver si es necesario hacer esta validacion de redireccion en base a las necesidades del cliente/
  // const isFromWellet = organization.id === 83 || organization.id === 371 || organization.id === 0;
  // const selectedWellet = selectedOrg.id === 83 || selectedOrg.id === 371 || selectedOrg.id === 0;
  const selectedMarket =
    selectedOrg.isMarket  || organization.isMarket

  const shouldRedirect = () => {
    if (onlyMarket) {
      return selectedMarket;
    } 
    // else if (isFromWellet && checkSelectedOrg) {
    //   return selectedWellet;
    // }

    // return isFromWellet;
    return true;
  };

  return !shouldRedirect() ? <Navigate to={{ pathname: '/' }} replace /> : <Outlet />;
}
