import React, { useContext, useState } from 'react';
import { Button, ErrorAlert, Loading, Modal } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useTranslation } from 'react-i18next';
import { Show } from '../../components';
import { useQuery } from 'react-query';
import { getFile } from '../../services';

const ModalPreviewFile = ({ url, handleSubmit }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalPreviewFile' });
  const [open, setOpen] = useState(null);
  const [fileSrc, setFileSrc] = useState(null);

  // API Query
  const fileQuery = useQuery(['file', url], () => getFile(welletContext, url), {
    onSuccess: (data) => {
      const { stream, type } = data?.data;
      let fileUrl;

      if (type === 'image/jpeg' || type === 'image/png') {
        const base64String = stream;
        fileUrl = `data:${type};base64,${base64String}`;
      } else if (type === 'application/pdf') {
        const byteCharacters = atob(stream);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        fileUrl = URL.createObjectURL(blob);
      }

      setFileSrc({ src: fileUrl, type: type.split('/')[1] });
    },
    enabled: open && url ? true : false,
  });

  // Functions
  const handleModal = () => {
    setOpen((prevState) => !prevState);
  };
  const handleClose = () => {
    handleModal();
  };
  const handleDownload = () => {
    if (fileSrc && fileSrc.src) {
      const link = document.createElement('a');
      link.href = fileSrc.src;
      link.setAttribute('download', `file.${fileSrc.type}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <div onClick={handleModal} className='custom-link-file'>
        {t('btn')}
      </div>
      <Show.When isTrue={open}>
        <Modal
          title={t('title')}
          open={open}
          onClose={handleClose}
          closeIcon={true}
          maxWidth='600px'
          handleSubmit={handleSubmit}
          closeOnDocumentClick={false}
          customButtons={
            <>
              <div className='row px-2 text-center'>
                <Button
                  text={t('btnClose')}
                  disabled={false}
                  className={'col btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                <Button
                  text={t('btnDownload')}
                  disabled={false}
                  className={'col ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={handleDownload}
                />
              </div>
            </>
          }
        >
          <Show>
            <Show.When isTrue={fileSrc === null || fileQuery.isLoading}>
              <div className='my-4 py-4'>
                <Loading />
              </div>
            </Show.When>
            <Show.When isTrue={fileQuery.isError}>
              <ErrorAlert text={t('error')} />
            </Show.When>
            <Show.When isTrue={fileSrc?.src && fileSrc?.type !== 'pdf' && !fileQuery.isError}>
              <div className='mt-2 d-flex justify-content-center'>
                <img src={fileSrc?.src} alt='Preview' style={{ width: '400px', height: '400px' }} />
              </div>
            </Show.When>
            <Show.Else>
              <div className='mt-2'>
                <iframe
                  title='file'
                  src={fileSrc?.src}
                  width='600px'
                  height='400px'
                  style={{ overflowX: 'hidden' }}
                />
              </div>
            </Show.Else>
          </Show>
        </Modal>
      </Show.When>
    </>
  );
};

export default ModalPreviewFile;
