export const getDocumentTypeIdAnalizer = (t) => {
    return [
      {
        label: t('documentTypeIdAnalizer.passport'),
        value: 'P',
      },
      {
        label: t('documentTypeIdAnalizer.license'),
        value: 'D',
      },
      {
        label: t('documentTypeIdAnalizer.identityCard'),
        value: 'I',
      },
      {
        label: t('documentTypeIdAnalizer.visa'),
        value: 'V',
      },
      {
        label: t('documentTypeIdAnalizer.residenceCard'),
        value: 'R',
      },
      {
        label: t('documentTypeIdAnalizer.other'),
        value: 'O',
      },
    ];
  };
  