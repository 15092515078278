import './checkbox.css';
export function CheckBox({
  handleCheck,
  checked,
  label,
  direction = 'row',
  size = '18px',
  className,
  isDisabled = false
}) {
  className = isDisabled ? className + " disabled" : className;

  return (
    <label className={`${className} checkbox-container mb-0`} style={{ flexDirection: direction }}>
      <input type='checkbox' style={{display: 'none'}} onChange={handleCheck} checked={checked} disabled={isDisabled} />
      <span className='checkmark' style={{ height: size, width: size }}></span>
      <span style={{ color: 'var(--color-subtitle)' }}>{label}</span>
    </label>
  );
}
