export default function downloadFileFromResponse(response, customFileName = null) {
    if (response && response.data) {
      let fileName = customFileName || `file.xlsx`;
  
      const contentDispositionHeader = response.headers['content-disposition'];
      if (contentDispositionHeader) {
        const matches = contentDispositionHeader.split("filename=");
        if (matches && matches.length > 1) {
          fileName = matches[1];
        }
      }
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
  
      link.remove();
      window.URL.revokeObjectURL(url);
    }
}