export const getOrderBy = (selectedOption) => {
    switch (selectedOption) {
      case 'reservations':
        return 'RESERVATION';
      case 'commissions':
        return 'COMMISSION';
      case 'commission':
        return 'AVG';
      case 'concierges':
        return 'ACTIVE_CONCIERGES';
      default:
        return 'INCOME';
    }
  };