import React, { useRef } from 'react';
import InputBase from './inputBase';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../button/button';
import { FwSearchIcon, FwTrashIcon, FwUploadIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const InputFile = ({
  id,
  name,
  placeholder,
  label,
  showDrag = true,
  className = '',
  initialValue = null,
  isRequired = false,
  maxSize = 1048576,
  pdf = false,
}) => {
  const validationRules = {};
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const fileInputRef = useRef(null);
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleRemoveFile = () => {
    fileInputRef.current.value = '';
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      clearErrors(name);
      previewFile(file)
        .then((base64String) => {
          setValue(name, { base64: base64String, name: file.name });
        })
        .catch((error) => {
          console.error('Error al convertir la imagen:', error);
        });
    }
  };
  const previewFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = pdf ? /\.(gif|png|jpe?g|bmp|pdf)$/i : /\.(gif|png|jpe?g|bmp)$/i;
      if (allowedExtensions.test(file.name)) {
        if (file.size <= maxSize) {
          handleFileInputChange(e);
        } else {
          setError(name, {
            type: 'custom',
            message: t('maxSize'),
          });
        }
      } else {
        setError(name, {
          type: 'custom',
          message: t('invalidFile'),
        });
      }
    }
  };

  if (isRequired) {
    validationRules.required = t('required');
  }

  return (
    <div className={`input-file-container ${className}`}>
      <label style={{ color: 'var(--color-text)' }} className='mb-1'>
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={validationRules}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              {showDrag ? (
                <div
                  className='file-dropzone mb-2'
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const droppedFile = e.dataTransfer.files[0];
                    if (droppedFile) {
                      const allowedExtensions = pdf
                        ? /\.(gif|png|jpe?g|bmp|pdf)$/i
                        : /\.(gif|png|jpe?g|bmp)$/i;
                      if (allowedExtensions.test(droppedFile.name)) {
                        if (droppedFile.size <= maxSize) {
                          clearErrors(name);
                          previewFile(droppedFile)
                            .then((base64String) => {
                              setValue(name, { base64: base64String, name: droppedFile.name });
                            })
                            .catch((error) => {
                              console.error('Error al convertir la imagen:', error);
                            });
                        } else {
                          setError(name, {
                            type: 'custom',
                            message: t('maxSize'),
                          });
                        }
                      } else {
                        setError(name, {
                          type: 'custom',
                          message: t('invalidFile'),
                        });
                      }
                    }
                  }}
                >
                  {value && value?.base64 ? (
                    <img src={value.base64} alt={value.name} className='preview-image' />
                  ) : (
                    <p>Drag & Drop Imagen</p>
                  )}
                </div>
              ) : (
                <></>
              )}
              <input
                type='file'
                id={id}
                name={name}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelection}
              />
              <InputBase
                errors={errors}
                icon={<FwUploadIcon />}
                type='text'
                name={name}
                value={value?.name || ''}
                label={''}
                readOnly={true}
                placeholder={placeholder}
                disabled
                endComponent={
                  <div className='file-btn d-flex'>
                    {value ? (
                      <>
                        <Button
                          type='button'
                          size={'medium'}
                          heights={'44px'}
                          className={'remove-file'}
                          text={<FwTrashIcon />}
                          onClick={() => {
                            handleRemoveFile();
                            onChange(null);
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <Button
                      type='button'
                      size={'medium'}
                      className={'send-file'}
                      heights={'44px'}
                      text={<FwSearchIcon color='' />}
                      onClick={handleFileInputClick}
                    />
                  </div>
                }
              />
              {errors[name] && (
                <div className='d-flex align-items-center error-content'>
                  <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                  <p className='text-danger p-0 m-0'>{errors[name].message}</p>
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
};
export default InputFile;
