export const getAllCampaigns = async (
  welletContext,
  marketId,
  organizationId,
  limit,
  skip,
  sortBy,
  columnFilter,
  includeInactive,
  showId,
  cityId,
  socialNetworkId,
  fieldfilter,
  conciergeId,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
    isActive: includeInactive,
    showId: showId.length === 0 ? null : showId,
    citiesId: cityId.length === 0 ? null : cityId,
    socialNetworkId: socialNetworkId.length === 0 ? null : socialNetworkId,
    fieldfilter: fieldfilter,
    conciergeId: conciergeId,
  };

  let baseUrl = `/api/campaignDashboard/list/market/${marketId}`;
  let url =
    organizationId === marketId
      ? baseUrl
      : `/api/CampaignDashboard/list/market/${marketId}/organization/${organizationId}`;

  return await welletContext.apis.admin.post(url, body);
};
export const getCampaignById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/campaign/${id}`);
};
export const putCampaign = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`/campaign`, body);
};
export const postCampaign = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/campaign`, body);
};
export const getTypeReservation = async (
  welletContext,
  marketId,
  organizationId,
  body,
  campaignId,
) => {
  let url = `/api/campaignDashboard/statistics/market/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getOccassions = async (welletContext, marketId, organizationId, body, campaignId) => {
  let url = `/api/campaignDashboard/occassion/market/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getAvgIncomes = async (welletContext, marketId, organizationId, body, campaignId) => {
  let url = `/api/campaignDashboard/avg/income/market/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getAvgCommissions = async (
  welletContext,
  marketId,
  organizationId,
  body,
  campaignId,
) => {
  let url = `/api/CampaignDashboard/avg/commission/market/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getAvgPeople = async (welletContext, marketId, organizationId, body, campaignId) => {
  let url = `/api/CampaignDashboard/paxgraph/market/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getTopConcierges = async (
  welletContext,
  marketId,
  organizationId,
  limit,
  skip,
  sortBy,
  columnFilter,
  date,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
    includeInactive: true,
    from: date.from,
    to: date.to,
  };
  let url = `/api/campaignDashboard/users/top/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getTopCampaigns = async (
  welletContext,
  marketId,
  organizationId,
  limit,
  skip,
  sortBy,
  columnFilter,
  date,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
    includeInactive: true,
    from: date.from,
    to: date.to,
  };
  let url = `/api/campaignDashboard/top/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getEvents = async (welletContext, marketId, organizationId, body, campaignId) => {
  let url = `/api/campaignDashboard/events/market/${marketId}`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getCampaignDetail = async (welletContext, marketId, campaignId) => {
  return await welletContext.apis.admin.get(
    `/api/campaignDashboard/details/market/${marketId}/es/${campaignId}`,
  );
};
export const getCampaignReservations = async (welletContext, marketId, campaignId, limit, skip) => {
  return await welletContext.apis.admin.post(
    `/api/campaignDashboard/details/market/${marketId}/list-reservations/${campaignId}`,
    {
      limit,
      skip,
      fromDate: null,
      toDate: null,
    },
  );
};
export const getTimeline = async (welletContext, marketId, organizationId, body, campaignId) => {
  let url = `/api/CampaignDashboard/timeline/market/${marketId}/commission`;
  if (organizationId) {
    url = `/api/CampaignDashboard/timeline/market/${marketId}/organization/${organizationId}/commission`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getSummaryCards = async (
  welletContext,
  marketId,
  organizationId,
  body,
  campaignId,
) => {
  let url = `/api/CampaignDashboard/market/${marketId}/summary`;
  if (organizationId) {
    url += `/organization/${organizationId}`;
  }
  if (campaignId) {
    url += `?campaignId=${campaignId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getAllSmallCards = async (welletContext, marketId, body, campaignId) => {
  let url = `/api/campaignDashboard/generalInfo/${marketId}/summary?campaignId=${campaignId}`;

  return await welletContext.apis.admin.post(url, body);
};
export const getMediaRestaurantById = async (welletContext, restaurantId) => {
  let url = `/campaign/showMedia/${restaurantId}`;
  return await welletContext.apis.admin.get(url);
};
export const deleteCampaign = async (welletContext, campaignId) => {
  let url = `/campaign/delete/${campaignId}`;

  return await welletContext.apis.admin.put(url);
};
export const orderCampaigns = async (welletContext, body) => {
  let url = `/campaign/changePriority`;
  return await welletContext.apis.admin.put(url, body);
};
