import React, { useContext, useState } from 'react';
import { FilterCard, PageHeader, Show, Table, TableCell } from '../../components';
import { useTranslation } from 'react-i18next';
import { Button, Loading, PageError } from '../../uiComponents/common';
import { useLocalStorage, useOrderBy, usePagination, useUser } from '../../hooks';
import { formatDateTranslation } from '../../helpers/dates';
import { useSelector } from 'react-redux';
import styles from '../../components/notificationMenu/notificationMenu.module.css';
import { useGetNotifications } from '../../hooks/queries/useGetNotifications';
import { setCurrentOrg, setNotification, setSelectedOrg } from '../../actions';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { postNotification } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { notificationRoutes } from '../../helpers/notifications';
import { useNavigate } from 'react-router-dom';
import TitleTab from '../../components/titleTab/titleTab';

const Notifications = () => {
  const { t } = useTranslation('translation', { keyPrefix: `screens.notifications` });
  const { language, selectedOrganization } = useSelector((state) => state.app);
  const { hasNotification } = useSelector((state) => state.user.user);
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-notifications', {
    filter: 'Unread',
  });
  const [filter, setFilter] = useState(filterLocalStorage?.filter ?? 'Unread');
  const headerTable = [
    {
      title: t('table.notification'),
    },
    { title: t('table.date'), filterable: true, value: 'createDateUTC' },
  ];
  const queryClient = useQueryClient();
  const userId = useSelector((state) => state.user.user.sid);

  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const { orderBy, orderType, handleColumnClick } = useOrderBy();
  
  const notificationQuery = useGetNotifications(
    {
      onSuccess: (data) => {
        setTotalAndReset(data?.data?.totalRegisters);
        const allRead = data?.data?.items?.every((notification) => notification.isViewed);
        if (allRead && hasNotification) {
          dispatch(setNotification(false));
        }
      },
      onSettled: () => {
        setFilterLocalStorage({ ...filterLocalStorage, filter });
      },
    },
    { limit, skip, filter, includeInactive: true, sortBy: orderBy, columnFilter: orderType },
  );

  const readMutation = useMutation({
    mutationFn: (id) => postNotification(welletContext, id),
    mutationKey: ['read-noti'],
    onSettled: () => notificationQuery.refetch(),
  });

  const handleClickNotification = (n) => {
    const route = notificationRoutes[n.type];
    if (!n.isViewed) {
      readMutation.mutate([n.id]);
    }

    if (route) {
      if (
        n.type === 'APPLICATION_DISPUTE' ||
        n.type === 'REFUTE_PAYMENT_DISPUTED' ||
        n.type === 'NEW_USER' ||
        n.type === 'APPLICATION_REFUND'
      ) {
        const marketsData = queryClient.getQueryData(['organizations', userId])?.markets;
        const market = marketsData?.find((m) => m.organizationId === n.marketSetupId);
        if (market && (selectedOrganization.marketId !== n.marketSetupId || selectedOrganization.id !== n.marketSetupId)) {
          const reduxOrgData = {
            ...selectedOrganization,
            name: null,
            id: market.organizationId,
            isMarket: market.isMarket,
            marketId: market.organizationId,
            marketName: market.marketName,
          };

          dispatch(setSelectedOrg(reduxOrgData));
          dispatch(setCurrentOrg(reduxOrgData));
        }

        navigate(route(n.refId, n.marketSetupId));
      } else {
        navigate(route(n.refId, n.marketSetupId));
      }
    }
  };

  const unreadNotificationIds = notificationQuery?.data?.data?.items
    ?.filter((notification) => !notification.isViewed)
    .map((notification) => notification.id);

  return (
    <div>
      <TitleTab />
      <PageHeader title={t('title')} border={false} />
      <Show>
        <Show.When isTrue={notificationQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={notificationQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row px-1 mt-3 mb-2'>
            <FilterCard
              category={t('cardFilter.unread')}
              showQuantity={false}
              onClick={() => setFilter('Unread')}
              isSelected={filter === 'Unread'}
              className='col'
            />
            <FilterCard
              category={t('cardFilter.all')}
              showQuantity={false}
              onClick={() => setFilter('All')}
              isSelected={filter === 'All'}
              className='col'
            />
          </div>
          <div className='row align-items-center justify-content-end my-3'>
            {hasNotification ? (
              <Button
                text={t('button', { number: unreadNotificationIds?.length })}
                className={'mr-2 btn-general  btn-wellet-primary'}
                size={'medium'}
                onClick={() => readMutation.mutate(unreadNotificationIds)}
                type='button'
              />
            ) : null}
          </div>
          <Table
            data={notificationQuery?.data?.data?.items}
            columns={headerTable}
            totalP={totalItems}
            header='white'
            borderTop={false}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            onColumnClick={handleColumnClick}
            activeColumn={orderType}
            sortOrder={orderBy}
            onRowClick={(item) => handleClickNotification(item)}
            renderRow={(notification) => {
              const { date, time } = formatDateTranslation(
                notification?.createDate,
                language,
                'extraSmall',
              );
              return (
                <>
                  <TableCell className={'fw-500 text-truncate'}>
                    <div className='d-flex'>
                      {!notification.isViewed ? (
                        <div className={`${styles.badgeNotification} mr-2`}></div>
                      ) : null}
                      {notification.body}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={'text-start w-auto'}>
                      <div className='fw-500'>{date}</div>
                      <div className='fw-400 dark-grey'>{time}</div>
                    </div>
                  </TableCell>
                </>
              );
            }}
          />
        </Show.Else>
      </Show>
    </div>
  );
};

export default Notifications;
