import React, { useState, useContext } from 'react';
import { Button, Modal, Toast } from '../../../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../../components';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { putConciergeStatus } from '../../../../services';
import { WelletContext } from '../../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { NavigateIcon } from '../../../../uiComponents/icons';
import styles from '../modal.module.css';

const ApproveModal = ({ id, refetchStatus, isLink }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.profile.rowRequests.modal.approved',
  });
  const { watch } = useFormContext();
  const [open, setOpen] = useState(false);

  const organization = useSelector((state) => state.app.currentOrganization);
  const welletContext = useContext(WelletContext);

  const statusMutation = useMutation({
    mutationFn: (body) => putConciergeStatus(welletContext, organization.marketId, body),
  });

  const handleClick = () => {
    const body = {
      userId: id,
      advantageNetworkStatus: 'APPROVED',
    };
    statusMutation.mutate(body);
  };

  const closeModal = () => {
    setOpen(false);
    statusMutation.reset();
  };

  const openModal = () => {
    setOpen(true);
  };

  const onCallback = () => {
    setOpen(false);
    refetchStatus();
    statusMutation.reset();
  };

  return (
    <>
      {!isLink ? (
        <Button
          className={'btn-wellet-primary bg-custom-light text-truncate'}
          text={t('button')}
          onClick={openModal}
          size={'medium'}
          type='button'
        />
      ) : (
        <div className={`d-flex align-items-center ${styles.action}`} onClick={openModal}>
          {t(`textButton`)}
          <div className='ml-1'>
            <NavigateIcon />
          </div>
        </div>
      )}
      <Modal
        title={t('title')}
        open={open}
        onClose={closeModal}
        closeIcon={false}
        maxWidth='550px'
        closeOnDocumentClick={false}
        customButtons={
          <>
            <div className='d-flex justify-content-end'>
              <Button
                text={t('buttons.cancel')}
                disabled={!statusMutation.isIdle}
                className={'btn-general btn-wellet-secondary white'}
                size={'medium'}
                onClick={closeModal}
                type='button'
              />
              <Button
                text={t('buttons.accept')}
                disabled={!statusMutation.isIdle}
                className={'ml-2 btn-general btn-wellet-primary'}
                size={'medium'}
                onClick={handleClick}
                type='button'
              />
            </div>
          </>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t(`text.${watch('advantageNetworkStatus')?.toLowerCase()}`),
          }}
          className='py-4'
        />
      </Modal>
      <Show.When isTrue={statusMutation.isSuccess}>
        <Toast message={t('toast.success')} type={`SUCCESS`} onCallback={onCallback} />
      </Show.When>
      <Show.When isTrue={statusMutation.isError}>
        <Toast
          message={t('toast.rejected')}
          type={`ERROR`}
          onCallback={() => statusMutation.reset()}
        />
      </Show.When>
    </>
  );
};

export default ApproveModal;
