import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import { DateFilter, PageHeader, Show, Table, TableCell, TableRow } from '../../components';
import { useCreateBreadCrumbs, useLocalStorage, useOrderBy, usePagination } from '../../hooks';
import { useQuery } from 'react-query';
import { Loading, Money, PageError, Status, TabFilter } from '../../uiComponents/common';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { getBillings } from '../../services/billingServices';
import { WelletContext } from '../../context/wellet/welletContext';
import { Link } from 'react-router-dom';

const Billing = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.billing' });
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const selectedDates = useSelector((state) => state.app.selectedDates);

  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };
  const locale = getLocale(language);
  const tabelItems = 10;

  // Handle Tabs
  const tabTypes = {
    ALL: null,
    ACCREDITED: 'ACCREDITED',
    PENDING: 'PENDING',
  };

  // Hooks
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('billing', {
    status: tabTypes.ALL,
    page: 1,
  });
  const [activeTab, setActiveTab] = useState(filterLocalStorage.status);
  const tabs = [
    {
      isActive: activeTab === tabTypes.ALL,
      title: t('tabs.alls'),
      type: tabTypes.ALL,
    },
    {
      isActive: activeTab === tabTypes.ACCREDITED,
      title: t('tabs.payments'),
      type: tabTypes.ACCREDITED,
    },
    {
      isActive: activeTab === tabTypes.PENDING,
      title: t('tabs.pendings'),
      type: tabTypes.PENDING,
    },
  ];
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
    tabelItems,
  );
  let limit = tabelItems;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);
  const { orderBy, orderType, handleColumnClick } = useOrderBy();

  // Main Query
  const billingQuery = useQuery({
    queryKey: ['billing', limit, skip, orderBy, orderType, activeTab, selectedFormattedDates],
    queryFn: () =>
      getBillings(
        welletContext,
        limit,
        skip,
        orderBy,
        orderType,
        activeTab,
        selectedFormattedDates,
      ),
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.results?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        page: currentPage,
        status: activeTab,
      });
    },
    ...defaultQueryOptions,
  });

  // Table Header
  const headerAdmin = [
    { title: t('table.date'), value: 'fromDate', filterable: true },
    { title: t('table.order'), value: '', filterable: false },
    { title: t('table.amount'), value: 'totalAmount', filterable: true },
    { title: t('table.status'), value: '', filterable: false },
  ];

  // Functions
  const handleTabs = (type) => {
    setActiveTab(type);
  };

  useCreateBreadCrumbs([{ name: t('title') }]);

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={
          <div className='mt-1'>
            <DateFilter />
          </div>
        }
      />

      <div className='row px-1 my-3 '>
        <TabFilter tabs={tabs} onClick={handleTabs} />
      </div>
      <Show>
        <Show.When isTrue={billingQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={billingQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <>
            <Table
              data={billingQuery?.data?.data?.results?.items}
              currentPage={currentPage}
              header='grey'
              perPage={tabelItems}
              hasPagination={false}
              onPageChange={(page) =>
                handlePageChange(page, setFilterLocalStorage({ ...filterLocalStorage, page }))
              }
              totalP={totalItems}
              columns={headerAdmin}
              onColumnClick={handleColumnClick}
              activeColumn={orderType}
              sortOrder={orderBy}
              renderRow={(billing, index) => {
                const { date } = formatDateTranslation(billing?.date, language, 'extraSmall');

                return (
                  <>
                    <>
                      <TableCell style={{ width: '200px' }}>
                        <div className='sentences-capitalize'>{date}</div>
                      </TableCell>
                      <TableCell style={{ width: '200px' }}>
                        <Link to={`/billing/summary/${billing?.id}`} className='link-underline'>
                          {billing?.refOrder}
                        </Link>
                      </TableCell>
                      <TableCell className='fw-700' style={{ width: '200px' }}>
                        <Money value={billing?.amount} />
                      </TableCell>
                      <TableCell>
                        <Status
                          status={billing?.status === 'PENDING' ? 'PENDING' : true}
                          text={billing?.status === 'PENDING' ? t('pending') : t('payment')}
                        />
                      </TableCell>
                    </>
                    <Show.When
                      isTrue={index === billingQuery?.data?.data?.results?.items.length - 1}
                    >
                      <TableRow>
                        <TableCell
                          style={{ backgroundColor: '#FAFAFA', fontWeight: 400, width: '200px' }}
                        >
                          Total
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: '#FAFAFA', width: '200px' }}
                        ></TableCell>
                        <TableCell style={{ backgroundColor: '#FAFAFA', width: '200px' }}>
                          <div className='fw-700'>
                            <Money value={billingQuery?.data?.data?.totalAmount} />
                          </div>
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#FAFAFA' }}></TableCell>
                      </TableRow>
                    </Show.When>
                  </>
                );
              }}
              rowClass='not-hover'
            />
          </>
        </Show.Else>
      </Show>
    </>
  );
};

export default Billing;
