const PadlockIcon = ({ size = '16px', color = 'currentColor', customClass = null, rotate = 0 }) => {
  return (
    <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 41.24 57.71'>
      <g>
        <g>
          <path
            d='M37.5,26.26v-9.38C37.5,7.57,29.93,0,20.62,0S3.74,7.57,3.74,16.88v9.38c-2.19.93-3.74,3.1-3.74,5.63v19.71c0,3.37,2.74,6.11,6.11,6.11h29.02c3.37,0,6.11-2.74,6.11-6.11v-19.71c0-2.53-1.54-4.7-3.74-5.63ZM6.21,16.88c0-7.95,6.46-14.41,14.41-14.41s14.41,6.46,14.41,14.41v8.9h-4.43v-9.29c0-5.5-4.48-9.98-9.98-9.98s-9.98,4.48-9.98,9.98v9.29h-4.43v-8.9ZM28.13,25.78h-15.02v-9.29c0-4.14,3.37-7.51,7.51-7.51s7.51,3.37,7.51,7.51v9.29ZM38.77,51.6c0,2.01-1.63,3.64-3.64,3.64H6.11c-2.01,0-3.64-1.63-3.64-3.64v-19.71c0-2.01,1.63-3.64,3.64-3.64h29.02c2.01,0,3.64,1.63,3.64,3.64v19.71Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M20.62,33.09c-2.73,0-4.94,2.22-4.94,4.94,0,2.3,1.58,4.22,3.71,4.77v5.14c0,.68.55,1.24,1.24,1.24s1.24-.55,1.24-1.24v-5.14c2.13-.55,3.71-2.47,3.71-4.77,0-2.73-2.22-4.94-4.94-4.94ZM20.62,40.51c-1.36,0-2.47-1.11-2.47-2.47s1.11-2.47,2.47-2.47,2.47,1.11,2.47,2.47-1.11,2.47-2.47,2.47Z'
            fill={color}
            strokeWidth='0'
          />
        </g>
      </g>
    </svg>
  );
};

export default PadlockIcon;
