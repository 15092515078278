import React from 'react';
import { InputDescription, InputText, Modal } from '../../uiComponents/common';
import { FormProvider, useForm } from 'react-hook-form';

const ModalComment = ({
  open,
  onClose,
  handleSubmit,
  title,
  textSubmit,
  fields,
  body,
  isLoading,
  textCancel = 'Cancelar',
  placeholder = 'Comentario',
  ...others
}) => {
  const methods = useForm();

  const onSubmit = (data) => {
    handleSubmit(data);
  };

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <form key={title}>
        <Modal
          title={title}
          open={open}
          onClose={handleClose}
          textCancel={textCancel}
          textSubmit={textSubmit}
          closeIcon={true}
          isLoading={isLoading}
          handleSubmit={methods.handleSubmit(onSubmit)}
          {...others}
        >
          {body}
          {fields.map((f, i) => (
            <div className={f.className ? f.className : ''} key={i}>
              <InputDescription
                height='75px'
                maxLength={512}
                label={f.label}
                name={f.name}
                isDisabled={isLoading}
                placeholder={f.placeholder}
                isRequired={f.isRequired}
              />
            </div>
          ))}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default ModalComment;
