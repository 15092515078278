export const getAllSoldOut = async (
  welletContext,
  orgId = null,
  limit = null,
  skip = null,
  includeInactive = false,
) => {
  let url = 'api/SoldOut/all';
  if (orgId && orgId !== 1) {
    url += `/${orgId}`;
  }
  return await welletContext.apis.admin.post(url, {
    limit,
    skip,
    includeInactive,
  });
};
export const postSoldOut = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/SoldOut`, body);
};
export const putSoldOut = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/SoldOut`, body);
};
export const deleteSoldOut = async (welletContext, id) => {
  return await welletContext.apis.admin.delete(`api/SoldOut/${id}`);
};

export const getSoldoutSchedules = async (welletContext, id, date, reservationType) => {
  return await welletContext.apis.tickets.get(
    `/shows/GetSchedule?showId=${id}&date=${date}&reservationType=
  ${reservationType}`,
  );
};
export const getProducts = async (welletContext, showId, date) => {
  const body = {
    from: date,
    to: date,
  };
  return await welletContext.apis.admin.post(`/api/SoldOut/GetAllByShowId/${showId}`, body);
};
export const soldoutSingleSchedule = async (welletContext, id, body) => {
  return await welletContext.apis.admin.put(`/api/schedule/sold-out/${id}`, body);
};
export const postProductAction = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/SoldOut/toggle`, body);
};
