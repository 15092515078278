import React from 'react';
import { RestaurantForm } from '../../components';
import { Subtitle } from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useCreateBreadCrumbs } from '../../hooks';
import { useTranslation } from 'react-i18next';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const NewActivity = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'forms.restaurants' });
  const { getClientTranslation } = useGenericTranslation();
  const organization = useSelector((state) => state.app.selectedOrganization.name);
  useCreateBreadCrumbs(
    [
      { name: organization + ' ' + t('breadcrumbs.prev'), path: '/restaurantes' },
      {
        name: t('breadcrumbs.current.new', {
          title: getClientTranslation('title.singular').toLocaleLowerCase(),
        }),
      },
    ],
    organization,
  );
  return (
    <>
      <Subtitle
        text={
          <h1 className='m-0 ctm-title font-weight-bold'>
            {t('title.new', { title: getClientTranslation('title.singular').toLocaleLowerCase() })}
          </h1>
        }
      />
      <RestaurantForm />
    </>
  );
};

export default NewActivity;
