import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getSalesCategorys } from '../../services';
import { defaultQueryOptionsFilter } from '../../utils/defaultQueryOptions';

export const useGetSalesCategory = (marketId, options) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptionsFilter,
    ...options,
  };

  return useQuery(['salesCategory', marketId], () => getSalesCategorys(welletContext, marketId), queryOptions);
};
