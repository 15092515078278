import React, { useEffect, useState } from 'react';
import { Select } from '../selects';
import InputText from './inputText';
import Button from '../button/button';
import IconButton from '../iconButton/iconButton';
import { CloseIcon, FwCheckIcon, FwEdit, FwTrashIcon } from '../../icons';
import './inputs.css';
import InputPhone from './inputPhone';
import InputEmail from './inputEmail';

const InputList = ({
  className,
  watch,
  setValue,
  unregister,
  resetField,
  formState: { errors },
  setError,
  clearErrors,
  initialState = [],
  data = [],
  labelSelect = '',
  placeholderSelect = '',
  labelInput = '',
  nameSelect = null,
  nameInput = null,
  baseNameInput = null,
  placeholder = null,
}) => {
  const [options, setOptions] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (initialState.length !== 0) {
      const newState = initialState.map((i) => ({
        value: i.value,
        label: i.label,
        type: i.type,
      }));
      newState.forEach((i) => setValue(baseNameInput + i.type + `_${i.value}`, i.value));
      setOptions(newState);
    } else {
      setOptions([]);
    }
  }, []);

  const currentInputText = watch(nameInput);
  const currentOptionSelected = watch(nameSelect);

  const formatLabel = (label) => {
    const words = label.split('_');
    const formattedLabel = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    return formattedLabel;
  };

  const handleSeleccionChange = (selectedOption) => {
    setValue(nameInput, null);
    setValue(nameSelect, selectedOption);
    clearErrors(nameSelect);
  };

  const addOption = (newOption) => {
    const selfCurrent = { ...newOption, value: currentInputText };

    if (currentInputText) {
      setOptions([...options, selfCurrent]);
      resetField(nameInput);
      setValue(nameSelect, null);
      setValue(baseNameInput + selfCurrent.type + `_${currentInputText}`, currentInputText);
      clearErrors(nameSelect);
    }
  };

  const startEdit = (idx) => {
    setEditingIndex(idx);
  };

  // Revisar el guardar
  const saveEdit = (social) => {
    const currentInput = watch(baseNameInput + social);

    const newOptions = [...options];
    newOptions[editingIndex] = {
      ...newOptions[editingIndex],
      value: currentInput,
    };
    if (currentInput === options[editingIndex].value) {
      setEditingIndex(null);
    } else {
      unregister(baseNameInput + social);
      setValue(
        baseNameInput + options[editingIndex].type + `_${newOptions[editingIndex].value}`,
        newOptions[editingIndex].value,
      );
      setOptions(newOptions);
      setEditingIndex(null);
    }
  };

  // Revisar el cancelar
  const cancelEdit = () => {
    if (editingIndex !== null) {
      const editedOption = options[editingIndex];
      // Revertir cualquier cambio realizado durante la edición
      setValue(baseNameInput + editedOption.type + `_${editedOption.value}`, editedOption.value);

      // Limpiar errores asociados al input que estaba en edición
      clearErrors(baseNameInput + editedOption.type + `_${editedOption.value}`);

      // Finalizar la edición
      setEditingIndex(null);
    }
  };
  const deleteOption = (index) => {
    const currentState = options;
    const currentInput = currentState[index];
    unregister(baseNameInput + currentInput.type + `_${currentInput.value}`);
    const newOptions = currentState.filter((_, i) => i !== index);
    setOptions(newOptions);
  };
  const dataFormatted = data.map((d) => ({ ...d, label: formatLabel(d.label) }));

  return (
    <div className={`${className} row p-0 m-0`}>
      <div className='row col-12 align-items-sm-end'>
        <Select
          isSearchable={false}
          options={dataFormatted}
          label={labelSelect}
          placeholder={placeholderSelect}
          className={'col-12 col-sm-4 m-sm-0'}
          name={nameSelect}
          useError={false}
          onManualChange={(option) => {
            handleSeleccionChange(option);
            clearErrors(nameSelect);
          }}
        />
        {currentOptionSelected?.value?.includes('PHONE') ||
        currentOptionSelected?.value?.includes('WHATSAPP') ? (
          <InputPhone
            name={nameInput}
            label={labelInput}
            className={'col-12 col-sm-6 m-sm-0'}
            placeholder={placeholder}
            isDisabled={!currentOptionSelected}
            defaultCountry={
              currentOptionSelected.value.split('_').pop().length < 4
                ? currentOptionSelected.value.split('_').pop()
                : undefined
            }
            isRequired={false}
          />
        ) : currentOptionSelected?.value?.includes('EMAIL') ? (
          <InputEmail
            name={nameInput}
            label={labelInput}
            className={'col-12 col-sm-6 m-sm-0'}
            placeholder={'Enter email'}
            isDisabled={!currentOptionSelected}
            isRequired={false}
          />
        ) : (
          <InputText
            name={nameInput}
            label={labelInput}
            className={'col-12 col-sm-6 m-sm-0'}
            placeholder={placeholder}
            isDisabled={!currentOptionSelected}
            isRequired={false}
          />
        )}
        <div className='col-12 col-sm-2 p-sm-0 d-flex justify-content-center'>
          <Button
            className={'btn-wellet-primary mb-1'}
            size={'small'}
            type='button'
            text={'Add'}
            onClick={() => addOption(currentOptionSelected)}
            disabled={!currentInputText}
            icon
          />
        </div>
      </div>

      {errors[nameSelect] && (
        <div className='d-flex align-items-center error-content'>
          <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
          <p className='text-danger p-0 m-0'>{errors[nameSelect].message}</p>
        </div>
      )}

      {options && options.length !== 0
        ? options.map((op, idx) => {
            return (
              <div
                className='col-12 align-items-start align-items-sm-end row mt-2'
                key={idx + op.value}
              >
                <div className='form-group col-12 col-sm-4 m-sm-0'>
                  <div className='input-style d-flex justify-content-start align-items-center'>
                    {formatLabel(op?.label)}
                  </div>
                </div>

                {op.type.includes('PHONE') || op.type.includes('WHATSAPP') ? (
                  <InputPhone
                    name={baseNameInput + op.type + `_${op.value}`}
                    initialValue={op.value}
                    className={'col-8 col-sm-6 m-sm-0'}
                    isDisabled={editingIndex !== idx}
                    defaultCountry={
                      op.type.split('_').pop().length < 4 ? op.type.split('_').pop() : undefined
                    }
                    isRequired={false}
                  />
                ) : op.type.includes('EMAIL') ? (
                  <InputEmail
                    name={baseNameInput + op.type + `_${op.value}`}
                    className={'col-8 col-sm-6 m-sm-0'}
                    isDisabled={editingIndex !== idx}
                    isRequired={false}
                  />
                ) : (
                  <InputText
                    name={baseNameInput + op.type + `_${op.value}`}
                    className={'col-8 col-sm-6 m-sm-0'}
                    isDisabled={editingIndex !== idx}
                    isRequired={false}
                  />
                )}

                {editingIndex === idx ? (
                  <div className='col-4 col-sm-2 d-flex justify-content-start align-items-start'>
                    <IconButton
                      onClick={() => saveEdit(`${op.type}_${op.value}`)}
                      icon={<FwCheckIcon />}
                    />
                    <IconButton onClick={cancelEdit} icon={<CloseIcon size='18px' />} />
                  </div>
                ) : (
                  <div className='col-4 col-sm-2 d-flex justify-content-start align-items-start'>
                    <IconButton onClick={() => startEdit(idx)} icon={<FwEdit />} />
                    <IconButton onClick={() => deleteOption(idx)} icon={<FwTrashIcon />} />
                  </div>
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default InputList;
