import React, { useContext, useState } from 'react';
import { Table, TableRow, TableCell, Pagination } from '../../components';
import {
  BreadCrumbs,
  Loading,
  Money,
  OrganizationCard,
  UserImage,
} from '../../uiComponents/common';
import { useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { getConciergePayment, postDispute, putAdjustment } from '../../services';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { FwPlusIcon } from '../../uiComponents/icons';
import { useNavigate } from 'react-router-dom';
import usePagination from '../../hooks/usePagination';
import { useSelector } from 'react-redux';
import { getLocale } from '../../helpers/dates';
import { hasAllowedRoles } from '../../helpers/roles';
import { useCreateBreadCrumbs, useUser } from '../../hooks';

const ConciergePayments = () => {
  const welletContext = useContext(WelletContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { roles } = useUser();
  const { id, sellerId } = useParams();

  const isFromApprovals = location.pathname.split('/')[1] === 'conciergeCommissions';

  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);

  const conciergeQuery = useQuery({
    queryKey: ['concierge-payment', id, sellerId],
    queryFn: () => getConciergePayment(welletContext, id, sellerId),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    onSuccess: (data) => {
      if (data?.data?.length === 0) {
        navigate(`/${isFromApprovals ? 'conciergeCommissions' : 'paymentList'}/${id}`);
      }
    },
  });

  const { currentPage, handlePageChange, totalItems, setTotalAndReset } = usePagination(
    1,
    1,
    false,
  );

  const commissions = conciergeQuery?.data?.data || [];
  const name = commissions[0]?.sellerName;

  useCreateBreadCrumbs([
    {
      name: 'Lotes de Pagos',
      path: '/conciergeCommissions',
    },
    {
      name: 'Auditar lotes de pagos',
      path: '/conciergeCommissions/' + id,
    },
    {
      name: `Operaciones ${name ? name : '-'}`
    },
  ], name)

  return (
    <>
      {conciergeQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='container-page color-body font-weight-semibold'>
            <div className='container-header'>
              <div className='row'>
                <div className='col'>
                  <div className='light-grey'>Comisiones a pagar a {commissions[0].sellerName}</div>
                  <div className='container-title'>
                    <Money
                      value={commissions.reduce(
                        (total, i) => total + i.adjustmentsSellers + i.commissionsSellers,
                        0,
                      )}
                      currencyCode={commissions.currency}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-2'>
            <Table
              data={commissions}
              currentPage={currentPage}
              showPagination={false}
              onPageChange={handlePageChange}
              totalP={totalItems}
              columns={[
                'Código',
                'Comisión',
                'Consumo',
                'Afiliado',
                'Restaurante',
                'Fecha de solicitud',
                'Estatus',
              ]}
              renderRow={(commission, index) => (
                <TableRow
                  key={index}
                  onClick={() => navigate(`/commissions-details/${commission.reservationId}`)}
                >
                  <TableCell style={{ width: '100px' }}>
                    {commission.referenceCode ? commission.referenceCode : '-'}
                  </TableCell>

                  <TableCell
                    className='ctm-text font-weight-bold'
                    style={{ width: '150px' }}
                    type={'primary'}
                  >
                    {commission.commissionsSellers ? (
                      commission.adjustmentsSellers === 0 ? (
                        <Money value={commission.commissionsSellers} />
                      ) : (
                        <div>
                          <div className='text-strikethrough'>
                            <Money value={commission.commissionsSellers} />
                          </div>
                          <div>
                            <Money
                              value={commission.commissionsSellers + commission.adjustmentsSellers}
                            />
                          </div>
                        </div>
                      )
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className='ctm-text ' style={{ width: '150px' }} type={'primary'}>
                    {commission.orderTotalAmount ? (
                      <Money value={commission.orderTotalAmount} />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {commission.isWellet ? (
                      commission?.userName
                    ) : (
                      <div className='row'>
                        <div className='col-auto pr-0'>
                          <div className='profile-pic'>
                            <UserImage
                              url={commission?.sellerProfilePicUrl}
                              name={commission?.sellerName}
                              size={'40px'}
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='font-weight-semibold' style={{ color: '#101828' }}>
                            {commission?.sellerName}
                          </div>
                          <div className='dark-grey'>Afiliado Digital</div>
                        </div>
                      </div>
                    )}
                  </TableCell>

                  <TableCell style={{ width: '200px' }}>
                    <OrganizationCard
                      name={commission.showName}
                      date={format(
                        new Date(commission.reservationDateLocalTime),
                        "dd MMM - HH:mm 'hs'",
                      )}
                      userName={commission.customerName}
                      quantity={commission.paxs}
                      size={40}
                      imageUrl={commission.showLogo}
                    />
                  </TableCell>

                  <TableCell className='text-capitalize' style={{ width: '150px' }}>
                    {commission.reservationRequestDateLocalTime
                      ? format(
                          new Date(commission.reservationRequestDateLocalTime),
                          'MMM. dd, yyyy',
                          { locale },
                        )
                      : '-'}
                  </TableCell>
                  <TableCell style={{ width: ' 150px' }}>
                    <button className='btn-wellet btn-block' onClick={() =>  navigate(`/commissions-details/${commission.reservationId}`)}>
                      Auditar Comisión
                    </button>
                  </TableCell>
                </TableRow>
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConciergePayments;
