import React from 'react';
import { ProductMode } from '../../components';

const Groups = () => {
  return (
    <div>
      <ProductMode mode='group' />
    </div>
  );
};

export default Groups;
