import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useMutation, useQuery } from 'react-query';
import {
  Alert,
  BreadCrumbs,
  Button,
  InputClock,
  InputDate,
  Loading,
  Select,
  Subtitle,
  Toast,
  ToggleToken,
} from '../../../uiComponents/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FwCalendarIcon, FwClockIcon, FwMasksIcon } from '../../../uiComponents/icons';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import {
  getPerformanceById,
  postPerformance,
  putPerformance,
  fetchActivities,
} from '../../../services';
import Weekdays from '../../../data/weekdays.json';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import { formatTimeFromMinutes, formatTimeToMinutes } from '../../../helpers/format';
import AdminAlert from '../../adminAlert/adminAlert';
import { useSelector } from 'react-redux';

const PerformanceForm = () => {
  const methods = useForm();
  const lastClickedButton = useRef(null);
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const isEditing = location.pathname.split('/')[1] === 'edit-performance';

  const [isRedirect, setIsRedirect] = useState(null);

  const activitiesQuery = useQuery({
    queryKey: ['activities', organization.id],
    queryFn: () => fetchActivities(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const performanceQuery = useQuery({
    queryKey: ['performance', id],
    queryFn: () => getPerformanceById(welletContext, id),
    onSuccess: (data) =>
      methods.reset({
        activity: activities?.find((a) => a.value === data?.data.showId),
        fromDate: data?.data?.from.split('T')[0],
        toDate: data?.data?.to?.split('T')[0],
        time: data?.data?.time,
        openDoors:
          data?.data?.startBefore !== undefined
            ? formatTimeFromMinutes(data?.data?.startBefore)
            : '',
        closeDoors:
          data?.data?.closeAfter !== undefined ? formatTimeFromMinutes(data?.data?.closeAfter) : '',
        weekdays: data?.data?.weekdays.map((r) => Weekdays.find((v) => v.value === r)),
        isActive: data?.data?.isActive,
        salesLimitTime: data?.data?.sellHourOffset,
      }),
    enabled: !!id && activitiesQuery.isSuccess,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const performanceMutation = useMutation({
    mutationFn: (body) => {
      if (id) {
        return putPerformance(welletContext, body);
      }
      return postPerformance(welletContext, body);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  //  useRedirectIfSuperAdmin(performanceQuery.isFetching, '/performances');

  const dateFrom = methods.watch('fromDate');
  const dateTo = methods.watch('toDate');
  const closeDoors = methods.watch('closeDoors');
  const activities = activitiesQuery?.data?.map((v) => ({ label: v.name, value: v.id }));
  const performanceData = performanceQuery.data?.data?.showName;
  const performanceDataTime = methods.formState?.defaultValues?.time;

  // Functions
  const onSubmit = (data) => {
    const body = {
      id: 0,
      showName: null,
      from: data.fromDate,
      to: data.toDate ? data.toDate : null,
      weekdays: data.weekdays.map((d) => d.value),
      time: {
        hour: Number(data.time.split(':')[0]),
        minutes: Number(data.time.split(':')[1]),
      },
      description: data.time,
      startBefore: formatTimeToMinutes(data.openDoors),
      closeAfter: formatTimeToMinutes(data.closeDoors),
      notes: '',
      showId: data.activity.value,
      isActive: isEditing ? data.isActive : true,
      companyId: organization.id,
      venueId: null,

      sellHourOffset: {
        hour: Number(data.salesLimitTime.split(':')[0]),
        minutes: Number(data.salesLimitTime.split(':')[1]),
      },
    };

    if (id) {
      body.id = id;
    }
    performanceMutation.mutate(body);
  };
  const clearForm = () => {
    if (id) {
      navigate('/performances');
    } else {
      performanceMutation.reset();
      methods.reset();
      navigate('/performances');
    }
  };
  const handleToastCallback = () => {
    if (isRedirect) {
      clearForm();
      navigate('/performances');
    } else {
      performanceMutation.reset();
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };
  return (
    <>
      <BreadCrumbs
        crumbs={[
          {
            name: 'Performances',
            path: '/performances',
          },
          {
            name: isEditing ? 'Edit Performance' : 'New Performance',
            path: isEditing ? '/edit-performance' : '/new-performances',
          },
        ]}
      />
      {performanceMutation.isSuccess && (
        <Toast
          message={`Performance ${id ? 'updated' : 'created'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      <AdminAlert
        extraValidation={!isEditing}
        text={'Please select a company to create a performance.'}
      >
        {performanceQuery.isLoading || activitiesQuery.isLoading ? (
          <Loading />
        ) : (
          <FormProvider {...methods}>
            <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
              <Subtitle
                text={
                  <h3>
                    {isEditing
                      ? `Edit Performance - ${performanceData} - ${performanceDataTime}`
                      : 'New Performance'}
                  </h3>
                }
              />

              <div className='row mt-2'>
                <Select
                  options={activities}
                  label={'Activity'}
                  className={'col-12 col-sm-6 col-xl-4 m-0 mb-1'}
                  icon={<FwMasksIcon />}
                  name={'activity'}
                  isRequired={true}
                />
                {isEditing ? (
                  <div className='col-12 col-sm-6 col-xl-4'>
                    <ToggleToken
                      label={'Status'}
                      activeText={'Active'}
                      inactiveText={'Inactive'}
                      name={'isActive'}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Subtitle text={<h3>Main Data</h3>} />
              <div className='row mt-3'>
                <InputDate
                  placeholder={'Select Date'}
                  label={'From Date'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwCalendarIcon />}
                  name={'fromDate'}
                  isRequired={true}
                  fromDate={new Date('01/01/2021')}
                  toDate={dateTo ? new Date(dateTo + 'T00:00:00') : new Date('01/01/2030')}
                />
                <InputDate
                  placeholder={'Select Date'}
                  label={'To Date'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwCalendarIcon />}
                  name={'toDate'}
                  fromDate={dateFrom ? new Date(dateFrom + 'T00:00:00') : new Date('01/01/2021')}
                  toDate={new Date('01/01/2030')}
                />
              </div>
              <div className='row mt-2'>
                <Select
                  options={Weekdays}
                  label={'Weekdays'}
                  className={'col-12 col-xl-8'}
                  icon={<FwClockIcon />}
                  name={'weekdays'}
                  components={{
                    Option: InputOption,
                  }}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  blurInputOnSelect={false}
                  isRequired={true}
                />
              </div>
              <Subtitle text={<h3>Times</h3>} />
              <div className='row'>
                <InputClock
                  label={'Performance Time'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-2 mt-2'}
                  name={`time`}
                  required
                />
                <InputClock
                  label={'Open Doors'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-2 mt-2'}
                  name={`openDoors`}
                  required
                />
                <InputClock
                  label={'Close Doors'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-2 mt-2'}
                  name={`closeDoors`}
                  required
                />
                <InputClock
                  label={'Sales Limit Time'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-2 mt-2'}
                  name={`salesLimitTime`}
                  maxTime={closeDoors}
                  required={false}
                  usecMaxTime={true}
                />
              </div>
              <div className='row mt-5 align-items-center'>
                <div className='col-12 col-xl-8'>
                  <Button
                    size={'small'}
                    type='submit'
                    className={'btn-custom-primary'}
                    text={`${
                      performanceMutation.isLoading && lastClickedButton.current === 'send'
                        ? 'Sending...'
                        : 'Send'
                    }`}
                    onClick={() => {
                      handleRedirect(false, 'send');
                    }}
                  />
                  <Button
                    size={'small'}
                    type='button'
                    className={'btn-custom-white  ml-2'}
                    text={`Cancel`}
                    onClick={clearForm}
                  />
                  <Button
                    size={'small'}
                    className={'btn-custom-primary ml-2'}
                    type='submit'
                    text={`${
                      performanceMutation.isLoading && lastClickedButton.current === 'sendAndClose'
                        ? 'Sending...'
                        : 'Send & Close'
                    }`}
                    onClick={() => {
                      handleRedirect(true, 'sendAndClose');
                    }}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        )}
      </AdminAlert>
      {performanceMutation?.error && <Alert title={''} text={'Error'} type={'danger'} />}
    </>
  );
};

export default PerformanceForm;
