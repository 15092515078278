import React, { useContext, useState } from 'react';
import { Table, TableRow, TableCell, Pagination } from '../../components';
import {
  BreadCrumbs,
  Button,
  CardRowDetails,
  Loading,
  Modal,
  Money,
  Risks,
  Subtitle,
  Toast,
  ModalPendingReservations,
} from '../../uiComponents/common';
import { format } from 'date-fns';
import { getReservatiionPendingsApprove, postDispute } from '../../services';
import { useMutation, useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { formatMinutes } from '../../helpers/format';
import usePagination from '../../hooks/usePagination';
import { useSelector } from 'react-redux';
import { getLocale } from '../../helpers/dates';
import { hasAllowedRoles } from '../../helpers/roles';
import { useUser } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import './reservations.css';

const ReservationsPendingApprove = () => {
  const welletContext = useContext(WelletContext);
  const { roles } = useUser();
  const marketId = useSelector((state) => state.app.selectedOrganization.marketId);
  const currency = useSelector((state) => state.app.selectedOrganization);
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const [openRisk, setOpenRisk] = useState(null);
  const [currentReservation, setCurrentReservation] = useState(null);

  const { currentPage, handlePageChange, totalItems, setTotalAndReset } = usePagination(1, 1, false);
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);

  // Queries
  const reservationsPendingApproveQuery = useQuery({
    queryKey: ['reservations-pending-approve', marketId, limit, skip],
    queryFn: () =>
      getReservatiionPendingsApprove(welletContext, marketId, 'PENDING_REVIEW', limit, skip),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    onSuccess: (data) => setTotalAndReset(data?.data.reservations?.totalRegisters),
  });
  const disputeMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        comments: data?.disputeComment,
        userComments: data?.userComment,
      };
      return postDispute(welletContext, selectedOrder, body);
    },
  });
  // To do: Crear mutation para aprobar una reserva

  const headerAdmin = [
    'Cód. de Referencia',
    'Restaruante',
    'Comisiones',
    'Fecha de Reserva',
    'Riesgo',
    '',
  ];

  // Functions
  const handleOpenRisk = (risk) => {
    setOpenRisk(risk);
    setTotalAndReset(risk?.length);
  };
  const handleCloseRisk = () => {
    handlePageChange(1);
    setOpenRisk(null);
  };
  const renderRiskBody = (risk) => {
    if (risk.riskType === 'RESERVATION_TIME') {
      return (
        <div>
          <div className='mb-2'>
            <CardRowDetails
              className='align-items-center'
              title={'Nivel'}
              value={<Risks hover={false} risk={risk.riskLevel} />}
            />
          </div>
          <div className='mb-2'>
            <CardRowDetails title={'Tipo'} value={'Reserva'} />
          </div>
          <CardRowDetails
            title={'Descripción'}
            value={
              risk.minutesBetweenReservationAndTableOpen > 0
                ? `Pasaron ${formatMinutes(
                    risk.minutesBetweenReservationAndTableOpen,
                  )} desde que se generó la reserva hasta que se abrió la mesa.`
                : ` La mesa se abrió ${formatMinutes(
                    Math.abs(risk.minutesBetweenReservationAndTableOpen),
                  )} antes de que se generara la reserva.`
            }
          />
        </div>
      );
    } else if (risk.riskType === 'HAS_DISCOUNT') {
      return (
        <div>
          <div className='mb-2'>
            <CardRowDetails title={'Nivel'} value={<Risks hover={false} risk={risk.riskLevel} />} />
          </div>
          <div className='mb-3'>
            <CardRowDetails title={'Tipo'} value={'Descuento'} />
          </div>
          <CardRowDetails
            title={'Descripción'}
            value={
              <div>
                Se aplicó el descuento '{risk.discountName}' al consumo de esta mesa. <br />
                Monto: {currency.currencySymbol}
                {risk.discountAmount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                (${risk.discountPercentage}%)
              </div>
            }
          />
        </div>
      );
    } else if (risk.riskType === 'MULTIPLE_PAYMENTS') {
      return (
        <div>
          <div className='mb-2'>
            <CardRowDetails title={'Nivel'} value={<Risks hover={false} risk={risk.riskLevel} />} />
          </div>
          <div className='mb-2'>
            <CardRowDetails title={'Tipo'} value={'Pagos Múltiples'} />
          </div>
          <CardRowDetails
            title={'Descripción'}
            value={`Se registraron ${
              risk.paymentsCount
            } pagos en esta mesa. Lapso de tiempo entre el primero y el último: ${formatMinutes(
              risk.paymentsTimeSpanMinutes,
            )}.`}
          />
        </div>
      );
    }
  };
  const getHigherRisk = (risks) => {
    if (risks.length === 0) return null;

    if (risks.some((risk) => risk.riskLevel === 'HIGH')) {
      return 'HIGH';
    }

    if (risks.some((risk) => risk.riskLevel === 'MEDIUM')) {
      return 'MEDIUM';
    }
    return 'LOW';
  };
  const navigateToDetail = (id) => {
    navigate(`/reservations-pending-approve/reservations-detail/${id}`);
  };

  return (
    <>
      {disputeMutation.isSucces && (
        <Toast
          message={`${
            disputeMutation.isSuccess ? 'Disputa creada' : 'Ajuste creado'
          } correctamente`}
          type='SUCCESS'
          onCallback={() => {
            if (disputeMutation.isSuccess) {
              disputeMutation.reset();
            }
            reservationsPendingApproveQuery.refetch();
          }}
        />
      )}

      {reservationsPendingApproveQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <BreadCrumbs
            crumbs={[
              {
                name: 'Aprobar Reservas',
                path: '/reservations-pending-approve',
              },
            ]}
          />
          <Subtitle
            border={false}
            text={
              <div className='payment-title d-flex align-items-center'>
                Reservas Pendientes de Auditoría
              </div>
            }
          />
          <Table
            data={reservationsPendingApproveQuery?.data?.data?.reservations?.items ?? []}
            showPagination={true}
            showText={false}
            hasPagination={false}
            columns={headerAdmin}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalP={totalItems}
            renderRow={(reservation, index) => (
              <TableRow
                key={reservation.id}
                className={''}
                index={index}
                onClick={() => navigateToDetail(reservation.id)}
              >
                <TableCell>{reservation.referenceCode}</TableCell>
                <TableCell>{reservation.activityName}</TableCell>

                <TableCell className='ctm-text font-weight-bold' type={'primary'}>
                  <Money value={reservation.commission} />
                </TableCell>
                <TableCell>
                  {reservation.reservationDateLocalTime
                    ? format(
                        new Date(reservation.reservationDateLocalTime),
                        "dd 'de' MMMM h:mm a",
                        { locale },
                      )
                    : '-'}
                </TableCell>

                <TableCell>
                  <div onClick={(e) => e.stopPropagation()}>
                    {reservation?.risks?.length > 0 ? (
                      <Risks
                        onClick={() => handleOpenRisk(reservation.risks)}
                        risk={getHigherRisk(reservation.risks)}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  {reservation.status === 'PENDING_REVIEW' &&
                  hasAllowedRoles(roles, [
                    'auditor',
                    'superadministrator',
                    'marketadmin',
                    'finance',
                  ]) &&
                  hasAllowedRoles(roles, ['auditor', 'superadministrator', 'marketadmin']) ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      <Button
                        size={'small'}
                        text={'Acciones'}
                        className={'btn-custom-white'}
                        onClick={() => {
                          setCurrentReservation(reservation);
                          setOpen(true);
                        }}
                      />
                    </div>
                  ) : null}
                </TableCell>
              </TableRow>
            )}
          />
          <Modal
            maxWidth={'500px'}
            title={'Riesgo'}
            open={openRisk !== null}
            customButtons={<></>}
            closeIcon={true}
            onClose={handleCloseRisk}
          >
            <div className='pt-2 px-3 pb-0'>
              {openRisk?.length > 0 ? renderRiskBody(openRisk[currentPage - 1]) : null}
              {openRisk?.length > 1 ? (
                <div className='mt-3 text-right payment-pagination pt-3'>
                  <Pagination
                    text='Riesgo'
                    className='d-flex align-items-center justify-content-between'
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    totalPages={totalItems}
                  />
                </div>
              ) : null}
            </div>
          </Modal>
          <ModalPendingReservations
            open={open}
            reservation={currentReservation}
            onClose={() => setOpen(false)}
            callback={() => reservationsPendingApproveQuery.refetch()}
          />
        </>
      )}
    </>
  );
};

export default ReservationsPendingApprove;
