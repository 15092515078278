import React from 'react';
import { NewTooltip } from '../../uiComponents/common';
import Show from '../show/show';

const FormRow = ({
  text,
  description,
  textClass = 'col-auto pr-5',
  endComponent,
  withBorder = true,
  tooltip = null,
  positionTooltip = 'top',
  centerContent = false,
}) => {
  return (
    <div className={`row py-2 mt-2 ${centerContent ? 'align-items-center' : 'align-items-start'}`}>
      <div className={textClass} style={{ maxWidth: '350px', width: '100%' }}>
        <div className='dark fw-600 d-flex align-items-center'>
          <span className='mr-2'>{text}</span>
          <Show.When isTrue={tooltip !== null}>
            <NewTooltip tooltip={tooltip} position={positionTooltip} />
          </Show.When>
        </div>
        <div className='dark-grey'>{description}</div>
      </div>
      {endComponent}
      {withBorder ? (
        <div className='col-12 mt-2'>
          <div style={{ height: '1px', background: 'var(--color-border-table)' }}></div>
        </div>
      ) : null}
    </div>
  );
};

export default FormRow;
