import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function UnauthRoute() {
  const user = useSelector((state) => state.user);

  if (user.roles === 'unauthorized') {
    return <Outlet />;
  }
  return <Navigate to={{ pathname: '/' }} replace />;
}
