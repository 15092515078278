import { useContext } from 'react';
import { getAllBatchs } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';

export const useGetBatch = (marketId, limit, skip, status, options, onlyApprovedPayments) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptions,
    ...options,
  };

  return useQuery(
    ['payment-batch', marketId, limit, skip, status],
    () => getAllBatchs(welletContext, marketId, limit, skip, status, onlyApprovedPayments),
    queryOptions,
  );
};
