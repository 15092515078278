import React, { useContext } from 'react';
import { BreadCrumbContext } from '../../context/breadcrumbContext';
import { BreadCrumbs } from '../../uiComponents/common';
const Content = ({ children, overflow }) => {
  const { breadcrumbs } = useContext(BreadCrumbContext);
  return (
    <div
      className='content-wrapper'
      style={{
        margin: '10px',
        overflowY: overflow ? 'auto' : 'hidden',
        padding: '10px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ maxWidth: '1920px' }}>
        <div className='mb-3'>
          <BreadCrumbs crumbs={breadcrumbs} />
        </div>

        {children}
      </div>
    </div>
  );
};

export default Content;
