import React from 'react';
import { MainConfigurations } from './sections';
import { Show } from '../../components';
import { useCreateBreadCrumbs } from '../../hooks';
import { useTranslation } from 'react-i18next';

const Configurations = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.configurations',
  });

  useCreateBreadCrumbs([{ name: t('title'), path: '/settings' }]);

  // TODO: Logica para mostrar configuración Wellet o Market
  return (
    <>
      <Show>
        <Show.When isTrue={true}>
          <MainConfigurations />
        </Show.When>
        {/* <Show.Else>
          <EntityConfigurations />
        </Show.Else> */}
      </Show>
    </>
  );
};

export default Configurations;
