import React from 'react';
import { useParams } from 'react-router-dom';
import { useCreateBreadCrumbs } from '../../hooks';
import Details from '../../components/reservation/details';
import { useTranslation } from 'react-i18next';
//import './reservation-detail.css';

const ReservationDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.details.commission.breadcrumbs',
  });

  useCreateBreadCrumbs([
    {
      name: t('prev'),
      path: '/commissions',
    },
    {
      name: t('current'),
    },
  ]);

  return <Details id={id} mode={'commission'} />;
};

export default ReservationDetails;
