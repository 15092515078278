import React, { useContext, useState } from 'react';
import { Button, Modal, Toast } from '../../../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../../components';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { WelletContext } from '../../../../context/wellet/welletContext';
import { useMutation, useQueryClient } from 'react-query';
import { resetKyc } from '../../../../services';
import { InputDescription } from '../../../../uiComponents/common/inputs';

const RevalidateKycModal = ({setkycStatus}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.profile.documentation.rowKycStatus.modal.revalidate',
  });
  const language = useSelector((state) => state.app.language);
  const methods = useForm({
    defaultValues: {
      message: null,
    },
  });
  
  const { id } = useParams();
  const welletContext = useContext(WelletContext);
  const [open, setOpen] = useState(false);

  // Refetch
  const queryClient = useQueryClient();
  const handleRefetch = () => {
    setkycStatus("REVALIDATE");
    queryClient.refetchQueries(['kyc']);
  };
  // Mutation
  const handleKycMutation = useMutation({
    mutationFn: (body) => {
      const response = resetKyc(welletContext, body);
      return response;
    },
    onSuccess: (data, variables, context) => {},
    onError: (error, variables, context) => {},
  });

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  const onSubmit = (data) => {
    const body = {
      kyc: 'REVALIDATION',
      userId: id,
      message: data?.message,
    };
    handleKycMutation.mutate(body);
  };
  const handleClose = () => {
    handleRefetch();
    closeModal();
  };
  const handleCloseError = () => {
    closeModal();
  };
  return (
    <>
      <Button
        className={'btn-wellet-secondary white text-truncate'}
        text={t('button')}
        onClick={openModal}
        size={'medium'}
        type='button'
      />
      <Modal
        title={t('title')}
        open={open}
        onClose={closeModal}
        closeIcon={false}
        maxWidth='550px'
        closeOnDocumentClick={false}
        customButtons={
          <>
            <div className='d-flex justify-content-end'>
              <Button
                text={t('buttons.cancel')}
                disabled={handleKycMutation.isLoading}
                className={'btn-general btn-wellet-secondary white'}
                size={'medium'}
                onClick={closeModal}
                type='button'
              />
              <Button
                text={t('buttons.revalidate')}
                disabled={handleKycMutation.isLoading}
                className={'ml-2 btn-general btn-wellet-primary'}
                size={'medium'}
                onClick={methods.handleSubmit(onSubmit)}
                type='submit'
              />
            </div>
          </>
        }
      >
        <FormProvider {...methods}>
          <form key={1} className=''>
            <InputDescription
              label={t('input.label')}
              name='message'
              className='mt-2'
              placeholder={`${t('input.placeholder')} (${language})`}
              isRequired
              height={'80px'}
            />
          </form>
        </FormProvider>
      </Modal>
      <Show.When isTrue={handleKycMutation.isError}>
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleCloseError();
          }}
        />
      </Show.When>
      <Show.When isTrue={handleKycMutation.isSuccess}>
        <Toast
          message={t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default RevalidateKycModal;
