import React, { useContext, useEffect } from 'react';
import ReservationPendingDetail from './reservationPendingDetail';
import ReservationModification from './reservationModifcation';
import { getCurrentReservationReviewing, getModifyReservation } from '../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { Loading } from '../../uiComponents/common';
import { useCreateBreadCrumbs, useUser } from '../../hooks';
import { hasAllowedRoles } from '../../helpers/roles';
import { useTranslation } from 'react-i18next';
import { Show } from '../../components';

// (4-16-24) To Do: Al inciar el componente ocurren 3 re-renders, luego entre cada API call se originan entre 2 o 3 re-renders

const ReservationPendingParent = () => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'screens' });
  const navigate = useNavigate();
  const { id } = useParams();
  const { roles } = useUser();

  // API Querys
  const reservationIdQuery = useQuery({
    queryKey: ['reservation-pending', id],
    queryFn: () => getModifyReservation(welletContext, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const reservationIsReviewed = useQuery({
    queryKey: ['reservation-pending-once'],
    queryFn: () => {
      return getCurrentReservationReviewing(welletContext, true, id);
    },
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    keepPreviousData: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    //onSuccess: () => console.log('API call Parent'),
  });

  const reservation = reservationIdQuery.data?.data;
  const isAllowed = hasAllowedRoles(roles, [
    'superadministrator',
    'admin',
    'marketadmin',
    'reservationcenter',
    'callcenterlead',
  ]);

  useEffect(() => {
    return () => {
      getCurrentReservationReviewing(welletContext, false, id);
    };
  }, []);

  useCreateBreadCrumbs([
    { name: t('requests.breadcumbs'), path: '/reservations-pending' },
    { name: t('requestDetails.breadcumbs') },
  ]);

  // Redirections
  if (reservationIsReviewed.isSuccess && !reservationIsReviewed?.data?.data) {
    navigate('/reservations-pending');
  }
  if (!isAllowed) {
    navigate('/reservations-pending');
  }

  // Logica para saber si es reservationspending

  return (
    <Show>
      <Show.When isTrue={reservationIdQuery.isLoading}>
        <Loading />
      </Show.When>
      <Show.Else>
        <Show>
          <Show.When isTrue={!reservation?.oldReservation}>
            <ReservationPendingDetail
              reservation={reservation}
              callback={reservationIdQuery.refetch}
            />
          </Show.When>
          <Show.Else>
            <ReservationModification
              reservation={reservation}
              callback={reservationIdQuery.refetch}
            />
          </Show.Else>
        </Show>
      </Show.Else>
    </Show>
  );
};

export default ReservationPendingParent;
