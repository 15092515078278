import React, { useContext, useState } from 'react';
import {
  Button,
  InputDate,
  InputText,
  Loading,
  Modal,
  ModernSwitcher,
  Money,
  Toast,
} from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { postPreviewCreateBatch, putCreateBatch } from '../../services';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Show } from '../../components';
import { useSelector } from 'react-redux';

const ModalPayment = ({ callback }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalPayment' });
  const { t: translatePayment } = useTranslation('translation', { keyPrefix: 'paymentMethods' });
  const paymentMethods = useSelector((state) => state.app.paymentMethods);
  const [open, setOpen] = useState(false);
  const defaultValues = paymentMethods?.reduce((acc, method) => {
    acc[method] = true;
    return acc;
  }, {});
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });
  const welletContext = useContext(WelletContext);
  const [openDetail, setOpenDetail] = useState(false);
  const [openError, setOpenError] = useState(false);

  // API Query Mutation
  const previewMutation = useMutation({
    mutationFn: (data) => {
      return postPreviewCreateBatch(welletContext, {
        description: data?.description,
        minReservationDateLocalTime: data?.minReservationDateLocalTime,
        maxReservationDateLocalTime: data?.maxReservationDateLocalTime,
        paymentMethods: data?.paymentMethods,
      });
    },
    onSuccess: (data) => {
      if (data.data) {
        setOpenDetail(true);
      } else {
        setOpenError(true);
      }
    },
  });
  const confirmMutation = useMutation({
    mutationFn: (data) => {
      return putCreateBatch(welletContext, {
        description: data?.description,
        minReservationDateLocalTime: data?.minReservationDateLocalTime,
        maxReservationDateLocalTime: data?.maxReservationDateLocalTime,
        paymentMethods: data?.paymentMethods,
      });
    },
    onSuccess: (data) => {
      if (data.data) {
        callback();
      } else {
        setOpenError(true);
      }
    },
  });

  // Functions
  const handleClose = () => {
    setOpen(false);
    setOpenDetail(false);
    setOpenError(false);
    methods.reset({
      description: null,
      minDate: null,
      maxDate: null,
      ...defaultValues,
    });
    previewMutation.reset();
    confirmMutation.reset();
  };
  const handleModal = () => {
    setOpen((prevState) => !prevState);
  };
  const onSubmit = (data) => {
    const selectedPaymentMethods = Object.keys(data).filter(
      (key) => data[key] && paymentMethods.includes(key),
    );

    let body = {
      description: data?.description,
      minReservationDateLocalTime: data?.minDate,
      maxReservationDateLocalTime: data?.maxDate,
      paymentMethods: selectedPaymentMethods,
    };

    if (!openDetail) {
      return previewMutation.mutate(body);
    } else {
      body = {
        description: previewMutation?.variables?.description,
        minReservationDateLocalTime: previewMutation?.variables?.minReservationDateLocalTime,
        maxReservationDateLocalTime: previewMutation?.variables?.maxReservationDateLocalTime,
        paymentMethods: selectedPaymentMethods,
      };
    }
    return confirmMutation.mutate(body);
  };

  const paymentMethodMap = {
    MERCADOPAGO: 'mercadopagoAmount',
    CASH: 'cashAmount',
    CERTIFICATE: 'certificateAmount',
    WIRE_MEXICO: 'wiretransferMexicoAmount',
    WIRE_INTERNATIONAL: 'wiretransferInternationalAmount',
    STRIPE: 'stripeAmount',
  };

  const previewPayment = previewMutation?.data?.data;
  const dateFrom = methods.watch('minDate');
  const dateTo = methods.watch('maxDate');

  return (
    <>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate'}
        text={t('button')}
        onClick={handleModal}
        size={'medium'}
      />
      <FormProvider {...methods}>
        <Modal
          title={t('title')}
          open={open}
          onClose={handleClose}
          closeIcon={true}
          maxWidth='450px'
          closeOnDocumentClick={false}
          customButtons={
            <>
              <div className='row px-2 text-center'>
                <Button
                  text={t('cancel')}
                  disabled={
                    previewMutation.isLoading ||
                    confirmMutation.isLoading ||
                    confirmMutation.isSuccess
                  }
                  className={'col btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                <Button
                  text={openDetail ? t('confirm') : t('preview')}
                  disabled={
                    previewMutation.isLoading ||
                    confirmMutation.isLoading ||
                    confirmMutation.isSuccess
                  }
                  className={'col ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={() => methods.handleSubmit(onSubmit)()}
                />
              </div>
            </>
          }
        >
          <div className='row p-0'>
            <InputText
              maxLength={512}
              label={t('inputDescription.label')}
              placeholder={t('inputDescription.placeholder')}
              name={'description'}
              isDisabled={previewMutation.isLoading || previewMutation.isSuccess}
              isRequired={true}
              minLength={0}
              className='col pt-2 pb-0'
            />
          </div>
          <div className='row p-0'>
            <InputDate
              label={t('inputStartDate.label')}
              placeholder={t('inputStartDate.placeholder')}
              className={'col'}
              name={'minDate'}
              isDisabled={previewMutation.isLoading || previewMutation.isSuccess}
              maxDate={dateTo ? new Date(dateTo + 'T00:00:00') : undefined}
            />
            <InputDate
              label={t('inputEndDate.label')}
              placeholder={t('inputEndDate.placeholder')}
              className={'col'}
              isDisabled={previewMutation.isLoading || previewMutation.isSuccess}
              name={'maxDate'}
              minDate={dateFrom ? new Date(dateFrom + 'T00:00:00') : undefined}
            />
          </div>
          <div className='row p-0'>
            {paymentMethods?.map((p, i) => (
              <React.Fragment key={i}>
                <ModernSwitcher
                  className='col-auto mb-1'
                  text={translatePayment(p)}
                  name={p}
                  isDisabled={openDetail}
                />
              </React.Fragment>
            ))}
          </div>
          <Show.When isTrue={previewMutation.isLoading}>
            <div className='py-4'>
              <Loading global={false} />
            </div>
          </Show.When>
          <Show.When isTrue={openDetail}>
            <div className='text-left'>
              <div className='row'>
                <div className='col text-truncate pr-0 dark-grey'>
                  <div className='dark-grey fw-500 pb-1 mt-1'>{t('openDetail.paymentNumbers')}</div>
                  {paymentMethods?.map((p, i) => (
                    <div key={'total' + i} className='dark-grey fw-500 pb-1'>
                      {t(`openDetail.${p}`)}
                    </div>
                  ))}
                  <div className='dark-grey fw-500 pb-1'>{t('openDetail.total')}</div>
                </div>
                <div className='col-auto text-right pl-1 '>
                  <div className='text-primary fw-600 pb-1 mt-1'>{previewPayment?.numberOfPayments}</div>
                  {paymentMethods.map((method) => {
                    const amountKey = paymentMethodMap[method];
                    return (
                      <div
                        className='text-primary d-flex justify-content-end fw-600 pb-1'
                        key={method + amountKey}
                      >
                        <Money
                          currencyClass={'text-primary fw-600'}
                          value={
                            previewPayment
                              ? previewPayment[amountKey]
                                ? previewPayment[amountKey]
                                : 0
                              : 0
                          }
                          currencyCode={previewPayment?.currency}
                        />
                      </div>
                    );
                  })}
                  <div className='text-primary fw-600 pb-2'>
                    <Money
                      currencyClass={'text-primary fw-600'}
                      value={previewPayment?.totalAmount}
                      currencyCode={previewPayment?.currency}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Show.When>
        </Modal>
      </FormProvider>

      <Show.When isTrue={openError}>
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
      <Show.When isTrue={confirmMutation.isSuccess}>
        <Toast
          message={t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default ModalPayment;
