import React, { useContext, useState } from 'react';
import {
  AffiliateCard,
  Button,
  ContanierSection,
  InputDescription,
  Modal,
  Subtitle,
  Toast,
} from '../../uiComponents/common';
import { useUser } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { postAddNotes } from '../../services';
import { getUserRole } from '../../helpers/roles';

import { useSelector } from 'react-redux';
import { formatDateTranslation, getDateFromOffset } from '../../helpers/dates';
import { useTranslation } from 'react-i18next';
const Notes = ({
  currentNotes = [],
  reservationId,
  callback,
  url = 'reservation',
  marginBottom = '150px',
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.notes' });
  const { t: translate } = useTranslation();
  const language = useSelector((state) => state.app.language);
  const utcOffset = useSelector((state) => state.app.utcOffset);
  const [openNote, setOpenNote] = useState(false);
  const { user } = useUser();
  const welletContext = useContext(WelletContext);
  const methods = useForm({
    defaultValues: {
      note: '',
    },
  });

  const noteMutation = useMutation({
    mutationFn: (data) => {
      return postAddNotes(welletContext, url, {
        reservationId,
        note: data.note,
        isInternal: true,
      });
    },
  });

  const handleClose = () => {
    noteMutation.reset();
    setOpenNote(false);
    methods.reset({
      note: '',
    });
  };

  const onSubmit = (data) => {
    const body = {
      note: data.note,
      user: {
        fullName: user.name,
        profilePicUrl: user.profilePic,
        role: user?.roles[0]?.toLocaleUpperCase(),
      },
      date: getDateFromOffset(utcOffset),
    };

    return noteMutation.mutate(body);
  };
  const handleSuccess = () => {
    callback?.();
    handleClose();
  };

  return (
    <>
      <Subtitle
        text={t('title')}
        endComponent={
          <Button
            className={'btn-general btn-wellet-secondary'}
            onClick={() => {
              setOpenNote(true);
            }}
            size={'custom'}
            text={<>{t('btnAdd')}</>}
          />
        }
      />
      <ContanierSection maxWidth='1200px'>
        {currentNotes?.length === 0 ? (
          <div className='col fw-600'>{t('noNotes')}</div>
        ) : (
          currentNotes &&
          currentNotes.map((n, i) => {
            const { dateTime } = formatDateTranslation(n.date, language, 'small');
            return (
              <div className='row no-gutters justify-content-start' key={i}>
                <div className='col-12'>
                  <div className='row no-gutters'>
                    <div className='col-12 col-md-3 pr-0'>
                      <AffiliateCard
                        name={n?.user?.fullName}
                        url={n?.user?.profilePicUrl}
                        company={getUserRole(n?.user?.role, translate)}
                      />
                    </div>
                    <div className='col-12 col-md-3 mb-3'>
                      <div className='dark-grey fw-500'>{t('note')}</div>
                      <div className='fw-600 fs-16'>{n.note ? n.note : '-'}</div>
                    </div>
                    <div className='col-12 col-md mb-3 pl-4'>
                      <div className='dark-grey fw-500'>{t('typeNote')}</div>
                      <div className='fw-600 fs-16'>
                        {n.typeComment === 'INTERNAL_COMMENT' ? t('internal') : t('external')}
                      </div>
                    </div>
                    <div className='col-12 col-md-3 mb-3'>
                      <div className='dark-grey fw-500'>{t('date')}</div>
                      <div className='fw-600 fs-16 sentences-capitalize'>{dateTime}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </ContanierSection>

      <div className='row' style={{ marginBottom }}></div>
      <Modal
        title={t('modal.title')}
        maxWidth='450px'
        closeIcon={true}
        open={openNote}
        onClose={handleClose}
        customButtons={
          <>
            <div className='w-100 text-center d-flex justify-content-center'>
              <Button
                text={t('modal.cancel')}
                className={'mr-2 btn-general btn-wellet-secondary col'}
                size={'custom'}
                disabled={noteMutation.isLoading || noteMutation.isSuccess}
                onClick={() => {
                  setOpenNote(false);
                }}
              />
              <Button
                text={t('modal.add')}
                className={'mr-2 btn-general btn-wellet-primary col'}
                size={'custom'}
                disabled={noteMutation.isLoading || noteMutation.isSuccess}
                onClick={() => {
                  methods.handleSubmit(onSubmit)();
                }}
              />
            </div>
          </>
        }
      >
        <FormProvider {...methods}>
          <div className='pt-2'>
            <InputDescription
              name={'note'}
              height='75px'
              label={''}
              isRequired={true}
              placeholder={t('modal.inputDescription.placeholder')}
            />
          </div>
        </FormProvider>
      </Modal>
      {noteMutation.isError && (
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
      {noteMutation.isSuccess && (
        <Toast
          message={t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleSuccess();
          }}
        />
      )}
    </>
  );
};

export default Notes;
