import React, { useState } from 'react';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { useFormContext, Controller } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './textEditor.css';
import { useEffect } from 'react';
import htmlToDraft from 'html-to-draftjs';

const TextEditor = ({
  name,
  className,
  label,
  options = [
    'image',
    'colorPicker',
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'link',
    'embedded',
    'emoji',
    'remove',
    'history',
  ],
  placeholder = ''
}) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const {
    control,
    // formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      // Simular una carga de imagen exitosa y devolver su URL
      setTimeout(() => {
        const reader = new FileReader();
        reader.onload = (data) => {
          resolve({ data: { link: data.target.result } });
        };
        reader.onerror = () => {
          reject('Error loading the image');
        };
        reader.readAsDataURL(file);
      }, 1000); // Simular una carga de imagen que demora 1 segundo
    });
  };

  const handleSaveClick = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateJSON = convertToRaw(contentState);
    const contentHTML = draftToHtml(contentStateJSON);
    setValue(name, contentHTML);
  };
  useEffect(() => {
    const blocks = getValues(name);
    if (blocks && blocks !== 'null') {
      const isString = blocks[0] === '"';
      const blocksFromHTML = htmlToDraft(
        isString ? JSON.parse(blocks) : JSON.parse(JSON.stringify(blocks)),
      );
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [name]);

  return (
    <div className={`mb-3 ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        render={({ field }) => (
          <>
            {label ? <label style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
            <Editor
              {...field}
              onContentStateChange={handleSaveClick}
              editorState={editorState}
              onEditorStateChange={setEditorState}
              // wrapperClassName={`wrapper-class`}
              wrapperClassName={``}
              // editorClassName='editor-class'
              editorClassName='editor-new-class'
              // toolbarClassName='toolbar-class'
              toolbarClassName='toolbar-new-class'
              placeholder={placeholder}
              toolbar={{
                options: options,
                image: {
                  icon: null,
                  className: 'btn-editor image-btn-editor',
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: uploadImageCallBack,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  },
                },
                colorPicker: {
                  icon: null,
                  className: 'color-btn-editor btn-editor',
                },
                inline: {
                  options: ['bold', 'italic', ],
                  bold: { icon: null, className: 'bold-btn-editor btn-editor' },
                  italic: { icon: null, className: 'italic-btn-editor btn-editor' },
                  // underline: { icon: null, className: 'underline-btn-editor btn-editor' },
                  // strikethrough: { icon: null, className: 'striketrhough-btn-editor btn-editor' },
                  // monospace: { icon: null, className: 'monospace-btn-editor btn-editor' },
                },
                blockType: { className: 'btn-general btn-custom-text-editor noindex' },
                fontSize: { className: 'btn-general btn-custom-text-editor noindex' },
                list: {
                  options: ['unordered', 'ordered'],
                  unordered: { icon: null, className: 'unordered-btn-editor btn-editor' },
                  ordered: { icon: null, className: 'ordered-btn-editor btn-editor' },
                  indent: { icon: null, className: 'indent-btn-editor btn-editor' },
                  outdent: { icon: null, className: 'outdent-btn-editor btn-editor' },
                },
                textAlign: {
                  options: ['left', 'center', 'right', 'justify'],
                  left: { icon: null, className: 'left-btn-editor btn-editor' },
                  center: { icon: null, className: 'center-btn-editor btn-editor' },
                  right: { icon: null, className: 'right-btn-editor btn-editor' },
                  justify: { icon: null, className: 'justify-btn-editor btn-editor' },
                },
                link: {
                  options: ['link'],
                  link: { icon: null, className: 'link-btn-editor btn-editor' },
                },
                embedded: { icon: null, className: 'embedded-btn-editor btn-editor' },
                emoji: { icon: null, className: 'emoji-btn-editor btn-editor' },
                remove: { icon: null, className: 'remove-btn-editor btn-editor' },
                history: {
                  options: ['undo', 'redo'],
                  undo: { icon: null, className: 'undo-btn-editor btn-editor' },
                  redo: { icon: null, className: 'redo-btn-editor btn-editor' },
                },
              }}
            />
          </>
        )}
      />

      {/* To do: mostrar errores en base a la validacion */}
    </div>
  );
};

export default TextEditor;
