export const getMonitorByChargeId = async (welletContext, marketId = null, chargeId) => {
  let url = `api/payment/${marketId}/charge/${chargeId}`;
  if (!marketId) {
    url = `/api/payment/charge/${chargeId}`;
  }
  return await welletContext.apis.admin.get(url);
};

export const requestRefund = async (welletContext, chargeId, body) => {
  let url = `/admin/refund/request/${chargeId}`;
  return await welletContext.apis.tickets.post(url, body);
};
export const requestConfirmRefund = async (welletContext, chargeId) => {
  let url = `/admin/refund/${chargeId}`;
  return await welletContext.apis.tickets.post(url);
};
export const getAffectedPayments = async (welletContext, chargeId, isDispute = false) => {
  let url = `api/ConnectedAccounts/refund/detail/${chargeId}`;

  if (isDispute) {
    url = `api/ConnectedAccounts/dispute/detail/${chargeId}`;
  }

  return await welletContext.apis.admin.post(url, {
    limit: null,
    skip: null,
    reconciliationType: [],
  });
};
