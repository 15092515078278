import React from 'react';

export default function NoPhotoIcon({ size = '60px', color = 'currentColor', className }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z'
        fill='#F2F4F7'
      />
      <path
        d='M0.375 20C0.375 9.16141 9.16141 0.375 20 0.375C30.8386 0.375 39.625 9.16141 39.625 20C39.625 30.8386 30.8386 39.625 20 39.625C9.16141 39.625 0.375 30.8386 0.375 20Z'
        stroke='black'
        strokeOpacity='0.08'
        strokeWidth='0.75'
      />
      <path
        d='M28 29C28 27.6044 28 26.9067 27.8278 26.3389C27.44 25.0605 26.4395 24.06 25.1611 23.6722C24.5933 23.5 23.8956 23.5 22.5 23.5H17.5C16.1044 23.5 15.4067 23.5 14.8389 23.6722C13.5605 24.06 12.56 25.0605 12.1722 26.3389C12 26.9067 12 27.6044 12 29M24.5 15.5C24.5 17.9853 22.4853 20 20 20C17.5147 20 15.5 17.9853 15.5 15.5C15.5 13.0147 17.5147 11 20 11C22.4853 11 24.5 13.0147 24.5 15.5Z'
        stroke='#667085'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
