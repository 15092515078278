import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './zoomImg.css';
import { Show } from '../../../components';
import { NoImage } from '../../../screens/users/components';

export const ZoomImg = ({ width = '100%', className = '', img = '', text }) => {
  return (
    <Show>
      <Show.When isTrue={img}>
        <div className={`card-zoom ${className}`}>
          <Zoom>
            <div
              className='img-container'
              role='img'
              style={{
                width,
                height: '200px',
                background: `url(${img}) center/cover no-repeat`,
                borderRadius: '30px',
              }}
            ></div>
          </Zoom>
        </div>
      </Show.When>
      <Show.Else>
        <NoImage text={text} />
      </Show.Else>
    </Show>
  );
};
