import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { getQrLink } from '../../services';

export const useDownloadPdfMutationNew = () => {
  const welletContext = useContext(WelletContext);

  const mutation = useMutation({
    mutationFn: async (id) => {
      const response = getQrLink(welletContext, id);
      return response;
    },
    onSuccess: (data) => {
      const { stream, type, fileName } = data?.data;
      if (type === 'application/pdf') {
        const byteCharacters = atob(stream);
        const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type });
        const fileUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(fileUrl);

        // Reset the mutation state after success
        mutation.reset();
      }
    },
    onError: (error) => console.error(error),
  });

  return mutation;
};
