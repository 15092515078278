import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../uiComponents/common';
import ToggleableMenu from '../../uiComponents/sideBarCommon/togglableMenu/togglabeMenu';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../actions';
import { useMutation } from 'react-query';
import { postLngUser } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';

function LanguageSelector() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);

  const userLngMutation = useMutation({
    mutationFn: (lng) => {
      return postLngUser(welletContext, lng);
    },
  });
  const changeLanguage = (lng) => {
    //dispatch() -> idioma de user
    userLngMutation.mutate(lng);
    dispatch(setLanguage(lng));
    i18n.changeLanguage(lng);
  };

  const currentLanguage = i18n.resolvedLanguage; // Obtener el idioma actual

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ToggleableMenu
        title={
          <div
            style={{ color: '#000', marginLeft: '-5px', cursor: 'pointer' }}
            className='d-flex justify-content-start align-items-center px-3 py-2'
          >
            <div
              className={`language-selector-flag language-selector-flag-${
                currentLanguage === 'es' ? 'mx' : 'en'
              }`}
            ></div>
            <span>{currentLanguage === 'es' ? 'Español' : 'English'} +</span>
          </div>
        }
        secondaryTitle={
          <div
            style={{ color: '#000', marginLeft: '-5px', cursor: 'pointer' }}
            className='d-flex justify-content-start align-items-center px-3 py-2 '
          >
            <div
              className={`language-selector-flag language-selector-flag-${
                currentLanguage === 'es' ? 'mx' : 'en'
              }`}
            ></div>
            <span>{currentLanguage === 'es' ? 'Español' : 'English'} -</span>
          </div>
        }
      >
        <div
          className='px-4 dropdown-item'
          onClick={() => changeLanguage(currentLanguage === 'es' ? 'en' : 'es')}
        >
          {currentLanguage === 'en' && (
            <div
              style={{ color: '#000', padding: 0, marginLeft: '-5px', cursor: 'pointer' }}
              className='d-flex justify-content-center align-items-center '
            >
              <div className={`language-selector-flag language-selector-flag-mx`}></div>
              {currentLanguage === 'es' ? 'Español' : 'Spanish'}
            </div>
          )}
          {currentLanguage === 'es' && (
            <div
              style={{ color: '#000', padding: 0, marginLeft: '-5px', cursor: 'pointer' }}
              className='d-flex justify-content-center align-items-center '
            >
              <div className={`language-selector-flag language-selector-flag-en`}></div>
              {currentLanguage === 'en' ? 'English' : 'Inglés'}
            </div>
          )}
        </div>
      </ToggleableMenu>
    </div>
  );
}

export default LanguageSelector;
