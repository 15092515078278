import React from 'react';

export default function ArrowUp({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.46967 0.469671C4.76256 0.176777 5.23744 0.176778 5.53033 0.469671L9.53033 4.46967C9.82322 4.76256 9.82322 5.23744 9.53033 5.53033C9.23744 5.82322 8.76256 5.82322 8.46967 5.53033L5 2.06066L1.53033 5.53033C1.23744 5.82322 0.762562 5.82322 0.469669 5.53033C0.176776 5.23744 0.176776 4.76256 0.469669 4.46967L4.46967 0.469671Z'
        fill={color}
      />
    </svg>
  );
}
