const CheckIcon = ({ size = '10px', color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 1L3.5 6.5L1 4'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckIcon;
