import React from 'react';

const AskIcon = ({ size = '16px', color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.06065 6.00004C6.21739 5.55449 6.52675 5.17878 6.93395 4.93946C7.34116 4.70015 7.81991 4.61267 8.28544 4.69252C8.75096 4.77236 9.1732 5.01439 9.47737 5.37573C9.78154 5.73706 9.94802 6.19439 9.94732 6.66671C9.94732 8.00004 7.94732 8.66671 7.94732 8.66671M8.00065 11.3334H8.00732M14.6673 8.00004C14.6673 11.6819 11.6826 14.6667 8.00065 14.6667C4.31875 14.6667 1.33398 11.6819 1.33398 8.00004C1.33398 4.31814 4.31875 1.33337 8.00065 1.33337C11.6826 1.33337 14.6673 4.31814 14.6673 8.00004Z'
        stroke={color}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AskIcon;
