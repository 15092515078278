import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../uiComponents/common';
import { getLocale } from '../../helpers/dates';
import './reservation-detail.css';
import { useNavigate } from 'react-router-dom';
import ModalConfirmation from './modalConfirmation';
import ModalRejection from './modalRejection';
import ModalCancellation from './modalCancellation';
import Notes from '../../components/reservation/notes';
import ReservationProductZone from './reservationProductZone';
import ModalDeposit from './modalDeposit';
import { useTranslation } from 'react-i18next';
import { ConciergeDetail, Show } from '../../components';
import ReservationInfo from './request/reservationInfo';
import TablePdf from '../../components/reservation/tablesPdf';

// TO DO: UNIFICAR CON RESERVATION MODIFICATION

// (4-16-24) To Do: Que pasa si no existe una reservation, se muestra algo o se redirige?

const ReservationPendingDetail = ({ reservation, callback }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.requestDetails' });
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);

  const navigate = useNavigate();
  const conciergeLang = reservation?.concierge?.language === 'es' ? 'Español' : 'Inglés';

  return (
    <Show.When isTrue={reservation}>
      <div className='container-page color-body'>
        <div className='container-header with-border'>
          <div className='row align-items-end'>
            <div className='col-auto'>
              <div className='light-grey container-small-title'>{t('title')}</div>
              <div className='container-title'>
                {reservation?.actualReservation?.referenceCode}:{' '}
                {reservation?.actualReservation?.activityName}
              </div>
            </div>
            <div className='col my-auto'></div>
            <div className='col-auto'>
              <div className='row'>
                <Show>
                  <Show.When isTrue={reservation?.status === 'CANCELLED'}>
                    <div className='col-auto'>
                      <ModalCancellation
                        reservation={reservation}
                        onClose={() => {
                          navigate('/reservations-pending');
                        }}
                      />
                    </div>
                  </Show.When>
                  <Show.When isTrue={reservation?.status === 'CONFIRMED'}>
                    <div className='col-auto'>
                      <ModalDeposit
                        onClose={() => {
                          navigate('/reservations-pending');
                        }}
                        reservation={reservation?.actualReservation}
                      />
                    </div>
                  </Show.When>
                  <Show.Else>
                    <div className='col-auto'>
                      <ModalRejection
                        locale={locale}
                        onClose={() => {
                          navigate('/reservations-pending');
                        }}
                        language={conciergeLang}
                        reservation={reservation?.actualReservation}
                      />
                    </div>
                    <div className='col-auto'>
                      <ModalConfirmation
                        locale={locale}
                        onClose={() => {
                          navigate('/reservations-pending');
                        }}
                        language={conciergeLang}
                        reservation={reservation?.actualReservation}
                      />
                    </div>
                  </Show.Else>
                </Show>
              </div>
            </div>
          </div>
        </div>

        <ReservationInfo reservation={reservation} mode='request' />

        <ReservationProductZone reservation={reservation} showProduct={false} showPayment={true} />

        <ConciergeDetail
          data={{ concierge: reservation?.concierge, rp: reservation?.rp, boss: reservation?.boss }}
          language={language}
        />
        <Notes
          callback={callback}
          currentNotes={reservation.listComments ? reservation.listComments : []}
          reservationId={reservation.actualReservation.id}
          marginBottom={reservation?.status === 'CONFIRMED' ? '50px' : '150px'}
        />
        <Show.When isTrue={reservation?.status === 'CONFIRMED'}>
          <TablePdf data={reservation?.orderDocuments} />
        </Show.When>
      </div>
    </Show.When>
  );
};

export default ReservationPendingDetail;
