import React from 'react';
import { InputDescription, InputText, Modal } from '../../uiComponents/common';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ModalDispute = ({ open, onClose, handleSubmit, isLoading, lng }) => {
  const methods = useForm();
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalDispute' });

  const onSubmit = (data) => {
    handleSubmit(data);
    methods.reset();
  };
  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <form key={'dispute'}>
        <Modal
          title={t('title')}
          open={open}
          onClose={handleClose}
          maxWidth='450px'
          textCancel={t('cancel')}
          isLoading={isLoading}
          textSubmit={t('submit')}
          handleSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='pt-2'>
            <InputDescription
              maxLength={512}
              height='75px'
              label={t('form.inputComment.label')}
              placeholder={t('form.inputComment.placeholder')}
              name={'disputeComment'}
            />
          </div>
          <InputDescription
            maxLength={512}
            height='75px'
            label={t('form.inputConciergesComment.label')}
            name={'userComment'}
            placeholder={
              lng === 'es'
                ? t('form.inputConciergesComment.placeholderEs')
                : t('form.inputConciergesComment.placeholderEn')
            }
          />
        </Modal>
      </form>
    </FormProvider>
  );
};

export default ModalDispute;
