import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { WelletContext } from '../../../context/wellet/welletContext';
import { Show } from '../../../components';
import { Button, Modal, Toast } from '../../../uiComponents/common';
// import { deleteLevel } from '../services';
import { useUser } from '../../../hooks';
import { useSelector } from 'react-redux';
import { deleteLevel } from '../../../services';

const ModalDeleteLevel = ({ refetchLevels, open, setOpen }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.deleteLevel' });
  const organization = useSelector((state) => state.app.selectedOrganization);

  const { isSuperAdmin, isMarketAdmin } = useUser();

  // API Queries
  const deleteLevelMutation = useMutation({
    mutationFn: () => {
      return deleteLevel(
        welletContext,
        open?.id,
        isSuperAdmin || isMarketAdmin,
        organization.marketId,
      );
    },
    onSuccess: () => {
      refetchLevels();
    },
  });

  // Functions
  const handleModal = () => {
    setOpen(null);
  };

  const handleClose = () => {
    handleModal();
    deleteLevelMutation.reset();
  };

  const canDelete = open?.amountOfUsersInCategory === 0;

  return (
    <>
      <Show.When isTrue={deleteLevelMutation.isSuccess}>
        <Toast
          message={t('toastSucces')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
      <Show.When isTrue={deleteLevelMutation.isError}>
        <Toast message={t('toastError')} type={`ERROR`} onCallback={handleClose} />
      </Show.When>
      <Show.When isTrue={open !== null}>
        <Modal
          title={t('title')}
          open={open !== null}
          onClose={handleClose}
          maxWidth='450px'
          closeIcon={true}
          closeOnDocumentClick={false}
          customButtons={
            <>
              <div className='d-flex justify-content-end'>
                <Button
                  text={t('btnClose')}
                  disabled={deleteLevelMutation.isLoading || deleteLevelMutation.isSuccess}
                  className={' btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                {canDelete ? (
                  <Button
                    text={t('btnConfirmCancel')}
                    disabled={deleteLevelMutation.isLoading || deleteLevelMutation.isSuccess}
                    className={' ml-2 btn-general btn-wellet-primary'}
                    size={'medium'}
                    onClick={() => deleteLevelMutation.mutate()}
                  />
                ) : null}
              </div>
            </>
          }
        >
          <div className='text-left mt-2 mb-3 fw-500'>
            {canDelete ? t('ask') : t('cantDelete')}
          </div>
        </Modal>
      </Show.When>
    </>
  );
};

export default ModalDeleteLevel;
