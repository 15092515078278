import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputDescription,
  InputText,
  InputVideo,
  Loading,
  Modal,
  Tabs,
  TextAlert,
  Toast,
} from '../../../uiComponents/common'; // Asegúrate de que InputVideo se importe correctamente
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/wellet/welletContext';
import { updateVideo, uploadVideo } from '../../../services';
import { useMutation } from 'react-query';
import { useLanguageSwitcher } from '../../../hooks';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';

const Gallery = ({ open, setOpen, showdId, refetchQuery, showVideo }) => {
  const methods = useForm();
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'components.galleryVideo' });
  const validateFields = {
    nameImage: {
      rule: (values) => {
        if (!values.nameImage && !values.descriptionImage) {
          return true;
        } else if (values.nameImage && values.nameImage.trim() !== '') {
          return true;
        } else {
          return false;
        }
      },
      errorMsg: t('errorMsg.name'),
    },
  };

  const { languages, lngErrors, currentLng, changeLanguage, resetLngErrors } = useLanguageSwitcher(
    validateFields,
    methods,
  );

  const uploadMutation = useMutation({
    mutationFn: ({ data, langShows }) => {
      if (showVideo?.id) {
        return updateVideo(welletContext, {
          id: showVideo?.id ? showVideo?.id : null,
          showId: showdId,
          video: data?.videoModal?.url,
          langShowVideos: langShows,
          isActive: true,
        });
      } else
        return uploadVideo(welletContext, {
          showId: showdId,
          video: data?.videoModal?.url,
          langShowVideos: langShows,
          isActive: true,
        });
    },
    onSuccess: () => {
      refetchQuery();
    },
  });

  const handleClose = () => {
    setOpen(false);
    uploadMutation.reset();
    methods.reset(resetMethod());
  };

  const onSubmit = (data) => {
    let langShowImage = languages.map((lng, i) => {
      return {
        showVideoId: showVideo?.langShowVideos[i]?.showVideoId
          ? showVideo?.langShowVideos[i]?.showVideoId
          : 0,
        id: showVideo?.langShowVideos[i]?.id ? showVideo?.langShowVideos[i]?.id : 0,
        language: lng.shortForm,
        title: data[`nameImage_${lng.shortForm}`],
        description: data[`descriptionImage_${lng.shortForm}`],
      };
    });
    const handleLangShowsEmptys = removeObjectsWithEmptyFields(langShowImage, [
      'title',
      'description',
    ]);
    uploadMutation.mutate({ data, langShows: handleLangShowsEmptys });
  };

  const formatVideo = (data) => {
    const formattedData = {
      videoModal: {
        url: data?.videoUrl,
        name: data?.videoUrl,
      },
    };
    data?.langShowVideos?.forEach((vid) => {
      const lang = vid.language;
      formattedData[`nameImage_${lang}`] = vid.title;
      formattedData[`descriptionImage_${lang}`] = vid.description;
    });

    return formattedData;
  };

  const resetMethod = () => {
    const formattedData = {
      videoModal: null,
    };
    languages.forEach((lng) => {
      const lang = lng.shortForm;
      formattedData[`nameImage_${lang}`] = null;
      formattedData[`descriptionImage_${lang}`] = null;
    });
    return formattedData;
  };

  useEffect(() => {
    if (showVideo) {
      if (showVideo?.langShowVideos[0]?.language) {
        changeLanguage(
          languages?.find((l) => l.shortForm === showVideo?.langShowVideos[0]?.language)?.title,
        );
      } else {
        changeLanguage(languages[0]?.title);
      }
      methods.reset(formatVideo(showVideo));
    }
  }, [showVideo]);

  return (
    <>
      <FormProvider {...methods}>
        <form key={2}>
          <Modal
            textCancel={t('cancel')}
            textSubmit={showVideo?.id ? t('edit') : t('add')}
            idModal={'videoUploader'}
            title={showVideo?.id ? t('title.edit') : t('title.new')}
            btnType='submit'
            onClose={() => {
              setOpen(false);
              resetLngErrors();
              methods.reset(resetMethod());
            }}
            open={open}
            isLoading={uploadMutation.isLoading || uploadMutation.isSuccess}
            handleSubmit={methods.handleSubmit(onSubmit)}
          >
            {uploadMutation.isLoading || uploadMutation.isSuccess ? (
              <div className='p-5 m-5'>
                <Loading />
              </div>
            ) : (
              <>
                <InputVideo
                  id={'videoModal'}
                  name={'videoModal'}
                  className='col'
                  placeholder={t('placeholder')}
                  label={''}
                  isRequired={true}
                />
                <div className='my-2'>
                  <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
                  {Object.keys(lngErrors).length !== 0 && (
                    <div className='px-2'>
                      <TextAlert text={lngErrors.name} type={'danger'} />
                    </div>
                  )}
                </div>
                <InputText
                  name={'nameImage_' + currentLng}
                  label={t(`nameImage.label`)}
                  className={'col'}
                  minLength={0}
                  placeholder={t(`nameImage.placeholder`)}
                />
                <InputDescription
                  className='col'
                  name={'descriptionImage_' + currentLng}
                  label={t(`descriptionImage.label`)}
                  minLength={0}
                  placeholder={t(`descriptionImage.placeholder`)}
                />
              </>
            )}
          </Modal>
        </form>
      </FormProvider>
      {uploadMutation.isError && (
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
      {uploadMutation.isSuccess && (
        <Toast
          message={showVideo?.id ? t('toast.successEdit') : t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default Gallery;
