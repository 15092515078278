import React from 'react';
import './modern.css';
import { useFormContext, Controller } from 'react-hook-form';

const ModernSwitcher = ({
  text,
  name,
  isRequired,
  onClick,
  isDisabled = false,
  className
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validationRules = {};

  if (isRequired) {
    validationRules.required = 'Este campo es requerido.';
  }

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      rules={validationRules}
      defaultValue=''
      render={({ field }) => (
        <div className={className}>
          <label
            className={`modern_switcher ${field.value ? 'active' : 'inactive'} ${
              isDisabled && 'disabled'
            }`}
            onClick={onClick}
          >
            <input
              className='toggle_modern'
              type='checkbox'
              value='1'
              name={name}
              required={isRequired}
              defaultChecked={field.value}
              checked={field.value}
              {...field}
              disabled={isDisabled}
            />
            {field.value ? (
              <span className='modern_checkmarked'></span>
            ) : (
              <span className='modern_checkmark'></span>
            )}
            <span className='modern_text'>{text}</span>
          </label>
          {errors[name] && (
            <div className='d-flex align-items-center  error-content'>
              <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
              <p className='text-danger p-0 m-0'>{errors[name].message}</p>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default ModernSwitcher;
