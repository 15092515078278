import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InputFile, Modal } from '../../../uiComponents/common';
import { useTranslation } from 'react-i18next';

const Gallery = ({ images, setImages, open, setOpen, selectedImage }) => {
  const methods = useForm();
  const { t } = useTranslation('translation', { keyPrefix: 'components.gallery' });
  const onSubmit = (data) => {
    if (selectedImage !== null) {
      const newImage = images.map((i) =>
        i.id === selectedImage?.data?.id ? { imageUrl: data?.imageModal?.base64, id: null } : i,
      );
      setImages(newImage);
    } else {
      setImages([
        ...images,
        {
          id: null,
          imageUrl: data?.imageModal?.base64,
        },
      ]);
    }
    methods.reset();
    setOpen(false);
  };

  useEffect(() => {
    if (selectedImage) {
      let formattedData = {
        imageModal: {
          base64: selectedImage?.data?.imageUrl ?? '',
          name: selectedImage.data?.imageUrl ?? '',
        },
      };
      methods.reset(formattedData);
    } else {
      methods.reset({ imageModal: null });
    }
  }, [selectedImage]);

  return (
    <FormProvider {...methods}>
      <form key={2}>
        <Modal
          textCancel={t('cancel')}
          textSubmit={t('add')}
          idModal={'imageUploader'}
          title={selectedImage !== null ? t('title.edit') : t('title.new')}
          btnType='submit'
          onClose={() => {
            setOpen(false);
            methods.reset();
          }}
          handleSubmit={methods.handleSubmit(onSubmit)}
          open={open}
        >
          <InputFile
            id={'imageModal'}
            name={'imageModal'}
            className='col'
            placeholder={t('placeholder')}
            label={''}
            isRequired={true}
            maxSize={524288}
          />
        </Modal>
      </form>
    </FormProvider>
  );
};

export default Gallery;
