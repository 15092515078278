export const fetchsOperationsSeller = async (
  welletContext,
  organizationId,
  userId,
  filter = [],
  dates = {},
) => {
  try {
    const response = await welletContext.apis.admin.post(
      `/api/Wallet/summary/operations/${organizationId}/${userId}`,
      {
        OperationTypes: filter,
        From: dates?.from ? dates?.from : null,
        To: dates?.to ? dates?.to : null,
      },
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const downloadConcierges = async (welletContext, marketId, body) => {
  return await welletContext.apis.admin.post(
    `/api/excelreport/advantageNetwork/${marketId}`,
    body,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getAllConcierges = async (
  welletContext,
  limit = 20,
  skip = 0,
  marketId = null,
  orgId = null,
  fieldFilter = null,
  includeInactive = false,
  kycFilter,
  isAdvantageNetwork,
  userStatus,
  roleFilter = [],
  RPId,
  paymentTypes = null,
  bossIds = null,
  salesCategoriesId = null,
  sortBy,
  columnFilter,
  advantageNetworkStatus = [],
  FilterPending = false,
  includeSalesCategory = false,
) => {
  let url = `api/user/${marketId}/GetDataUsers`;

  if (marketId !== orgId) {
    url = `api/user/${marketId}/${orgId}/GetDataUsers`;
  }

  const body = {
    limit: limit,
    skip: skip,
    includeInactive: includeInactive,
    userStatus,
    fieldFilter: fieldFilter,
    KYCFilter: kycFilter,
    isAdvantageNetwork,
    includeSalesCategory,
    RPIds: RPId ? RPId : [],
    withdrawalMethod: paymentTypes !== null && paymentTypes.length > 0 ? paymentTypes : null,
    bossIds: bossIds ? bossIds : [],
    salesCategoriesId: salesCategoriesId ? salesCategoriesId : [],
    sortBy,
    columnFilter,
    advantageNetworkStatus: advantageNetworkStatus,
    FilterPending,
    roleFilter
  };

  return await welletContext.apis.admin.post(url, body);
};
