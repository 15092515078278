import React from 'react';

const ItemIcon = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_4213_8016'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='4'
        y='0'
        width='32'
        height='40'
      >
        <path
          d='M4 4C4 1.79086 5.79086 0 8 0H24L36 12V36C36 38.2091 34.2091 40 32 40H8C5.79086 40 4 38.2091 4 36V4Z'
          fill='url(#paint0_linear_4213_8016)'
        />
      </mask>
      <g mask='url(#mask0_4213_8016)'>
        <path
          d='M4 4C4 1.79086 5.79086 0 8 0H24L36 12V36C36 38.2091 34.2091 40 32 40H8C5.79086 40 4 38.2091 4 36V4Z'
          fill='#F2F4F7'
        />
      </g>
      <path d='M24 0L36 12H28C25.7909 12 24 10.2091 24 8V0Z' fill='#EAECF0' />
      <path
        d='M24.6674 30H25.341C25.9884 30 26.3122 30 26.4906 29.865C26.6461 29.7474 26.7423 29.5677 26.7539 29.3731C26.7672 29.1497 26.5876 28.8804 26.2285 28.3417L24.2216 25.3313C23.9248 24.8862 23.7765 24.6636 23.5895 24.586C23.426 24.5182 23.2422 24.5182 23.0787 24.586C22.8917 24.6636 22.7433 24.8862 22.4466 25.3313L21.9504 26.0755M24.6674 30L19.5444 22.6001C19.2498 22.1745 19.1025 21.9618 18.9185 21.887C18.7575 21.8215 18.5773 21.8215 18.4164 21.887C18.2323 21.9618 18.085 22.1745 17.7904 22.6001L13.8262 28.3262C13.4509 28.8684 13.2632 29.1394 13.2739 29.3649C13.2832 29.5612 13.3787 29.7435 13.5348 29.8629C13.7141 30 14.0438 30 14.7032 30H24.6674ZM26.0007 20C26.0007 21.1046 25.1053 22 24.0007 22C22.8962 22 22.0007 21.1046 22.0007 20C22.0007 18.8954 22.8962 18 24.0007 18C25.1053 18 26.0007 18.8954 26.0007 20Z'
        stroke='#344054'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4213_8016'
          x1='20'
          y1='0'
          x2='20'
          y2='40'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopOpacity='0.4' />
          <stop offset='1' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ItemIcon;
