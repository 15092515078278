import React, { useContext } from 'react';
import { FormHeader, KycImage, Loading, PageError, Toast } from '../../../uiComponents/common';

import { useQuery } from 'react-query';
import { WelletContext } from '../../../context/wellet/welletContext';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';
import { useTranslation } from 'react-i18next';
import { getImgsKyc } from '../../../services';
import { hasAllowedRoles } from '../../../helpers/roles';
import { useUser } from '../../../hooks';
import { requestKycStatus } from '../../../helpers/status';
import SelectConfig from './selectedConfig/selectedConfig';
import { Show } from '../../../components';
import FormRow from '../../../components/forms/formRow';

const KycDoc = ({ id = null, setkycStatus}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.profile.documentation',
  });

  const welletContext = useContext(WelletContext);

  // Api Querys
  const kycQuery = useQuery({
    queryKey: ['kyc', id],
    queryFn: () => getImgsKyc(welletContext, id),
    ...defaultQueryOptions,
  });
  const kycData = kycQuery?.data?.data;
  const { roles } = useUser();

  return (
    <div className='mt-3'>
      <Show>
        <Show.When isTrue={kycQuery.isLoading || kycQuery.isFetching}>
          <Loading global={true} />
        </Show.When>
        <Show.When isTrue={kycQuery.isError}>
          <PageError />
        </Show.When>

        <Show.Else>
          <FormHeader title={t('title')} subtitle={t('text')} />
          <FormRow
            text={t('rowKycImage.title')}
            description={''}
            endComponent={
              <div className='col-12 col-md-6'>
                <KycImage
                  front={kycData?.front}
                  back={kycData?.back}
                  face={kycData?.face}
                  status={kycData?.kycStatus}
                  textNoImage={t('noImage')}
                />
              </div>
            }
          />
          <FormRow
            text={t('rowKycStatus.title')}
            description={''}
            endComponent={
              <div className='col-12 col-md-6'>
                <div className='row align-items-center justify-content-center justify-content-md-between'>
                  <div className='col-12 col-md-auto my-2'>
                    <div className='row align-items-center'>
                      <div className='col-auto'>
                        {requestKycStatus(
                          kycData?.kycStatus,
                          t(`rowKycStatus.badge.${kycData?.kycStatus?.toLowerCase()}.label`),
                        )}
                      </div>
                      <div className='col'>
                        <span>
                          {t(`rowKycStatus.badge.${kycData?.kycStatus?.toLowerCase()}.text`)}
                        </span>
                      </div>
                    </div>
                  </div>
                  {hasAllowedRoles(roles, [
                    'superadministrator',
                    'marketadmin',
                    'affiliatecenter',
                  ]) ? (
                    <div className='col-12 col-md-auto my-2'>
                      <div
                        className='d-flex align-items-center justify-content-center justify-content-md-end'
                        style={{ gap: '20px' }}
                      >
                        <SelectConfig status={kycData?.kycStatus} usecase='kyc' setkycStatus={setkycStatus}/>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            }
          />
        </Show.Else>
      </Show>

      <Show.When isTrue={false}>
        <Toast message={t('toast.success')} type={`SUCCESS`} onCallback={() => null} />
      </Show.When>
      <Show.When isTrue={false}>
        <Toast message={t('toast.errors')} type={`ERROR`} onCallback={() => null} />
      </Show.When>
    </div>
  );
};

export default KycDoc;
