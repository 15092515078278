import React, { useContext, useEffect, useState } from 'react';
import {
  AffiliateCard,
  Button,
  Input,
  Loading,
  Money,
  PageError,
} from '../../../uiComponents/common';
import { useMutation, useQuery } from 'react-query';
import { DateFilter, FilterCard, PageHeader, Show, Table, TableCell } from '../../../components';
import { useCreateBreadCrumbs, useDebounce, useLocalStorage, usePagination } from '../../../hooks';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { downloadPayment, getPaymentByMethod } from '../../../services';
import { formatDateTranslation, getLocale } from '../../../helpers/dates';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../../uiComponents/icons';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';
import downloadFileFromResponse from '../../../utils/downloadFile';
import { paymentSummaryBadge } from '../../../helpers/status';

const WiresPayment = ({ mode }) => {
  const welletContext = useContext(WelletContext);
  const { t: translate } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.wiresPayment' });
  const organization = useSelector((state) => state.app.currentOrganization);
  const language = useSelector((state) => state.app.language);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const navigate = useNavigate();
  const [status, setStatus] = useState([]);
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage(`filter-payments-${mode}`, {
    filterField: '',
    page: 1,
  });
  const [value, setValue] = useState(filterLocalStorage.filterField);
  const debouncedSearch = useDebounce(value, 700);
  // Pagination
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
  );
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const selectedFormattedDates = {
    from: selectedDates?.formattedFrom,
    to: selectedDates?.formattedTo,
  };

  // API Query
  const wireQuery = useQuery(
    [
      'wire',
      mode,
      organization.marketId,
      limit,
      skip,
      status,
      selectedFormattedDates,
      debouncedSearch.trim(),
    ],
    () =>
      getPaymentByMethod(welletContext, organization.marketId, {
        limit,
        skip,
        status,
        withdrawalMethod:
          mode === 'national'
            ? ['WIRE_MEXICO']
            : mode === 'cash'
            ? ['CASH']
            : mode === 'mercadopago'
            ? ['MERCADOPAGO']
            : mode === 'certificate'
            ? ['CERTIFICATE']
            : mode === 'stripe'
            ? ['STRIPE']
            : ['WIRE_INTERNATIONAL'],
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        filterField: debouncedSearch.trim(),
      }),
    {
      onSuccess: (data) => {
        setTotalAndReset(
          data?.data?.payments?.totalRegisters ? data?.data?.payments?.totalRegisters : 0,
        );
        setFilterLocalStorage({
          filterField: debouncedSearch.trim(),
          page: currentPage,
          mode,
        });
      },
      ...defaultQueryOptions,
      keepPreviousData: true,
    },
  );

  // Functions
  const getStatusAmount = (summary, status) => {
    const count = summary?.find((item) => item.status === status);
    return count ? count.count : null;
  };
  const onFilterClick = (filter) => {
    setStatus(filter);
    handlePageChange(1);
  };
  const getName = (mode) => {
    switch (mode) {
      case 'national':
        return t('title.wireMexico');
      case 'international':
        return t('title.wireInternational');
      case 'cash':
        return t('title.cash');
      case 'mercadopago':
        return t('title.mp');
      case 'certificate':
        return t('title.certificates');
      case 'stripe':
        return 'Stripe';
      default:
        return mode;
    }
  };
  const handleNavigate = (id) => {
    navigate(`/payment-details/${id}`);
  };
  useEffect(() => {
    setStatus([]);
  }, [mode]);

  const headerAdmin =
    mode === 'mercadopago'
      ? [
          { title: t('table.concierge') },
          { title: t('table.account') },
          { title: t('table.import') },
          { title: t('table.paymentLote') },
          { title: t('table.date') },
          { title: t('table.state') },
        ]
      : [
          { title: t('table.concierge') },
          { title: t('table.import') },
          { title: t('table.paymentLote') },
          { title: t('table.date') },
          { title: t('table.state') },
        ];

  useCreateBreadCrumbs([{ name: getName(mode) }], mode);

  const downloadReservationMutation = useMutation({
    mutationFn: () => {
      const response = downloadPayment(welletContext, organization.marketId, {
        limit,
        skip,
        status,
        withdrawalMethod:
          mode === 'national'
            ? ['WIRE_MEXICO']
            : mode === 'cash'
            ? ['CASH']
            : mode === 'mercadopago'
            ? ['MERCADOPAGO']
            : mode === 'certificate'
            ? ['CERTIFICATE']
            : mode === 'stripe'
            ? ['STRIPE']
            : ['WIRE_INTERNATIONAL'],
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        filterField: debouncedSearch.trim(),
      });

      return response;
    },
    onSuccess: (data) => downloadFileFromResponse(data, 'reservations.xlsx'),
  });

  return (
    <>
      <PageHeader
        title={getName(mode)}
        marginBottom={false}
        endComponent={<DateFilter filterName={'Fecha'} />}
      />

      <Show>
        <Show.When isTrue={wireQuery.isLoading}>
          <Loading />
        </Show.When>

        <Show.When isTrue={wireQuery.isError}>
          <PageError />
        </Show.When>

        <Show.Else>
          <div className='row px-1 my-2'>
            <FilterCard
              category={t('cardFilter.total')}
              quantity={wireQuery?.data?.data?.totalSummary}
              className='col-auto'
              onClick={() => onFilterClick([])}
              isSelected={status.length === 0}
            />
            <FilterCard
              category={t('cardFilter.pendings')}
              quantity={getStatusAmount(wireQuery?.data?.data?.summary, 'PENDING')}
              className='col-auto'
              onClick={() => onFilterClick(['PENDING'])}
              isSelected={status[0] === 'PENDING'}
            />
            <FilterCard
              category={t('cardFilter.inProgress')}
              quantity={getStatusAmount(wireQuery?.data?.data?.summary, 'IN_PROGRESS')}
              className='col-auto'
              onClick={() => onFilterClick(['IN_PROGRESS'])}
              isSelected={status[0] === 'IN_PROGRESS'}
            />
            <FilterCard
              category={t('cardFilter.payments')}
              quantity={getStatusAmount(wireQuery?.data?.data?.summary, 'ACCREDITED')}
              className='col-auto'
              onClick={() => onFilterClick(['ACCREDITED'])}
              isSelected={status[0] === 'ACCREDITED'}
            />
            <FilterCard
              category={t('cardFilter.error')}
              quantity={getStatusAmount(wireQuery?.data?.data?.summary, 'ERROR')}
              className='col-auto'
              onClick={() => onFilterClick(['ERROR'])}
              isSelected={status[0] === 'ERROR'}
            />
          </div>
          <div className='row my-1'>
            <div className='col-auto'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                name={'search'}
                value={value}
                onChangeValue={(e) => setValue(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <div className='col d-flex justify-content-end'>
              <Button
                className={'btn-wellet-secondary opacity justify-content-center'}
                size={'small'}
                text={t('download')}
                width={'36px'}
                disabled={downloadReservationMutation.isLoading}
                loading={downloadReservationMutation.isLoading}
                onClick={() => downloadReservationMutation.mutate()}
              />
            </div>
          </div>
          <Show>
            <Show.When isTrue={wireQuery.isPreviousData}>
              <Loading />
            </Show.When>

            <Show.Else>
              <Table
                data={wireQuery?.data?.data?.payments?.items ?? []}
                hasPagination={false}
                totalP={totalItems}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                columns={headerAdmin}
                renderRow={(wire, index) => {
                  const { dateTime } = formatDateTranslation(wire.createdDate, language, 'small');
                  return (
                    <>
                      <TableCell>
                        <AffiliateCard
                          url={wire.concierge.profilePicUrl}
                          name={wire.concierge.fullName}
                          isVerified={wire.concierge.isVerified}
                          company={wire.concierge.company}
                        />
                      </TableCell>
                      {mode === 'mercadopago' ? (
                        <TableCell>
                          {wire?.withdrawalMethod?.email ? wire?.withdrawalMethod?.email : '-'}
                        </TableCell>
                      ) : null}
                      <TableCell className={'font-weight-bold'}>
                        <Money value={wire.commission} />
                      </TableCell>
                      <TableCell>{wire.batchName}</TableCell>
                      <TableCell>
                        <div className='sentences-capitalize'>{dateTime}</div>
                      </TableCell>
                      <TableCell>{paymentSummaryBadge(wire?.status, null, translate)}</TableCell>
                    </>
                  );
                }}
                onRowClick={(wire) => handleNavigate(wire.id)}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </>
  );
};

export default WiresPayment;
