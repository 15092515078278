import React, { useState } from 'react';
import { DateFilter, PageHeader, Show } from '../../components';
import { Button, Tabs } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { useCreateBreadCrumbs } from '../../hooks';
import { CampaignsList, CampaignsStatistics } from '../../components/campaigns';
import { useNavigate } from 'react-router-dom';

const Campaigns = () => {
  const { t } = useTranslation('translation', { keyPrefix: `screens.campaigns` });
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('LIST');
  const tabs = [
    { isActive: activeTab === 'LIST', title: t('tabs.list'), type: 'LIST' },
    { isActive: activeTab === 'STATISTICS', title: t('tabs.statistics'), type: 'STATISTICS' },
  ];
  const handleTabs = (type) => {
    setActiveTab(type);
  };

  useCreateBreadCrumbs([{ name: t('title') }]);

  return (
    <>
      <PageHeader
        title={t('title')}
        border={false}
        endComponent={
          <>
            <Show.When isTrue={activeTab === 'LIST'}>
              <Button
                size={'medium'}
                className={'btn-wellet-secondary'}
                text={t('btnCreate')}
                onClick={() => navigate(`/new-campaign`)}
              />
            </Show.When>
            <Show.When isTrue={activeTab === 'STATISTICS'}>
              <DateFilter filterName={'Fecha'} />
            </Show.When>
          </>
        }
      />
      <Tabs options={tabs} onClick={handleTabs} />
      <Show.When isTrue={activeTab === 'STATISTICS'}>
        <CampaignsStatistics show={activeTab === 'STATISTICS'} />
      </Show.When>
      <Show.When isTrue={activeTab === 'LIST'}>
        <CampaignsList show={activeTab === 'LIST'} />
      </Show.When>
    </>
  );
};

export default Campaigns;
