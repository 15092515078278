import React, { useState } from 'react';
import {
  Button,
  IconButton,
  InputClock,
  ModernSwitcher,
  Select,
} from '../../../uiComponents/common';
import { generateRandomId } from '../../../helpers/forms';
import {
  CloseIcon,
  FwAddIcon,
  FwCheckIcon,
  FwEdit,
  FwTrashIcon,
} from '../../../uiComponents/icons';
import { useTranslation } from 'react-i18next';

const CreateSchedule = ({ day, methods, addSchedule, removeSchedule, toggleEditing }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const errors = methods.formState.errors;
  const { t } = useTranslation('translation', { keyPrefix: 'screens.callCenter' });
  const inputOpen = methods.watch(`open_${day.day}`);
  const inputStartTime = methods.watch(`startTime_${day.day}`);
  const inputEndTime = methods.watch(`endTime_${day.day}`);

  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const timeString = `${hour.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
      timeOptions.push({ label: timeString, value: timeString });
    }
  }
  const addTime = () => {
    const selectedStartTime = methods.watch(`startTime_${day.day}`);
    const selectedEndTime = methods.watch(`endTime_${day.day}`);
    const parseTime = (timeString) => {
      const [hour, minutes] = timeString.split(':').map(Number);
      return { hour, minutes };
    };

    const startTime = parseTime(selectedStartTime.value);
    let endTime = parseTime(selectedEndTime.value);

    if (
      endTime.hour < startTime.hour ||
      (endTime.hour === startTime.hour && endTime.minutes <= startTime.minutes)
    ) {
      endTime = {
        hour: endTime.hour,
        minutes: startTime.minutes,
      };
    }

    const newTime = {
      day: day.day,
      startTime: startTime,
      endTime: endTime,
      id: generateRandomId(),
    };

    addSchedule(newTime);
  };

  const updatedSchedule = (currentDay, id) => {
    const startTimeName = `startTime_${currentDay}_${id}`;
    const endTimeName = `endTime_${currentDay}_${id}`;
    const startTime = methods.watch(startTimeName);
    const endTime = methods.watch(endTimeName);

    if (!startTime) {
      methods.setError(startTimeName, { type: 'string', message: 'Debe seleccionar un horario' });
      return;
    }
    if (!endTime) {
      methods.setError(endTimeName, { type: 'string', message: 'Debe seleccionar un horario' });
      return;
    }

    const originalStartTime = day?.schedule[editingIndex]?.startTime;
    const originalEndTime = day?.schedule[editingIndex]?.endTime;

    let newTime = {};

    if (originalStartTime && originalEndTime) {
      newTime.startTime = originalStartTime;
      newTime.endTime = originalEndTime;
    }

    if (startTime.label) {
      newTime['startTime'] = {
        hour: startTime?.label?.split(':')[0],
        minutes: startTime?.label?.split(':')[1],
      };
    }
    if (endTime.label) {
      newTime['endTime'] = {
        hour: endTime?.label?.split(':')[0],
        minutes: endTime?.label?.split(':')[1],
      };
    }
    toggleEditing(currentDay, id, newTime);
    if (startTime.label) {
      methods.setValue(
        startTimeName,
        `${startTime?.label?.split(':')[0]}:${startTime?.label?.split(':')[1]}`,
      );
    }
    if (endTime.label) {
      methods.setValue(
        endTimeName,
        `${endTime?.label?.split(':')[0]}:${endTime?.label?.split(':')[1]}`,
      );
    }

    setEditingIndex(null);
    methods.clearErrors(startTimeName);
    methods.clearErrors(endTimeName);
  };
  const closeEditing = (currentDay, id) => {
    const startTimeName = `startTime_${currentDay}_${id}`;
    const endTimeName = `endTime_${currentDay}_${id}`;

    const originalStartTime = day.schedule[editingIndex].startTime;
    const originalEndTime = day.schedule[editingIndex].endTime;

    if (originalStartTime.hour && originalEndTime.hour) {
      methods.setValue(startTimeName, `${originalStartTime.hour}:${originalStartTime.minutes}`);
      methods.setValue(endTimeName, `${originalEndTime.hour}:${originalEndTime.minutes}`);
    }
    setEditingIndex(null);
  };

  return (
    <div className='row col-10 ml-1 px-0 border-table-bottom align-items-center py-3'>
      <div className='row col-12 justify-content-between px-0'>
        <div className='col-3 mb-1 d-flex flex-row align-items-end justify-content-between'>
          <div className='w-50' style={{ fontSize: '18px' }}>
            {t(`weekday.${day.day}`)}
          </div>
          <ModernSwitcher text={t('open')} name={`open_${day.day}`} />
        </div>
        <div className='row col-8'>
          <div className='col-6 d-flex flex-row align-items-center justify-content-center'>
            <div className='w-25'>{t('openAt')}</div>
            <Select
              options={timeOptions}
              className={'w-75 m-0'}
              name={`startTime_${day.day}`}
              required={false}
              isDisabled={!inputOpen}
              placeholder={t('select')}
            />
          </div>
          <div className='col-6 d-flex flex-row align-items-center justify-content-center'>
            <div className='w-25'>{t('closeAt')}</div>
            <Select
              options={timeOptions}
              className={'w-75 m-0'}
              name={`endTime_${day.day}`}
              required={false}
              isDisabled={!inputOpen}
              placeholder={t('select')}
            />
          </div>
        </div>
        <div className='col-1 mt-1 p-0'>
          <Button
            className={
              'btn-custom-primary d-flex flex-row align-items-center justify-content-center w-100'
            }
            size={'small'}
            type='button'
            text={t('btnAdd')}
            onClick={addTime}
            icon
            disabled={!inputStartTime || !inputEndTime || !inputOpen}
            typeIcon={<FwAddIcon fontSize='14px' />}
          />
        </div>
      </div>
      {day?.schedule?.length > 0
        ? day?.schedule?.map((s, idx) => (
            <div
              className='row col-12 pt-2 justify-content-between align-items-center px-0'
              key={s.id}
              style={{ display: inputOpen ? '' : 'none' }}
            >
              <div className='col-3'></div>
              <div className={`row col-8 ${inputOpen ? 'border-table-top' : null} pt-2`}>
                <div className='col-6 d-flex flex-row align-items-center justify-content-center'>
                  <div className='w-25' style={{ display: inputOpen ? '' : 'none' }}>
                    {t('openAt')}
                  </div>
                  {editingIndex === idx ? (
                    <Select
                      options={timeOptions}
                      className={'w-75 m-0'}
                      name={`startTime_${day.day}_${s.id}`}
                      required={false}
                      isDisabled={editingIndex !== idx}
                      placeholder={t('select')}
                    />
                  ) : inputOpen ? (
                    <InputClock
                      className={'w-75 m-0'}
                      name={`startTime_${day.day}_${s.id}`}
                      required={false}
                      isDisabled={editingIndex !== idx}
                      useError={false}
                      format='HH:mm'
                      useCustomMaxTime={true}
                      customMaxTime={'23:30'}
                    />
                  ) : null}
                </div>
                <div className='col-6 d-flex flex-row align-items-center justify-content-center'>
                  <div className='w-25' style={{ display: inputOpen ? '' : 'none' }}>
                    {t('closeAt')}
                  </div>
                  {editingIndex === idx ? (
                    <Select
                      options={timeOptions}
                      className={'w-75 m-0'}
                      name={`endTime_${day.day}_${s.id}`}
                      required={false}
                      isDisabled={editingIndex !== idx}
                      placeholder={t('select')}
                    />
                  ) : inputOpen ? (
                    <InputClock
                      className={'w-75 m-0'}
                      name={`endTime_${day.day}_${s.id}`}
                      required={false}
                      isDisabled={editingIndex !== idx}
                      useError={false}
                      format='HH:mm'
                      useCustomMaxTime={true}
                      customMaxTime={'23:30'}
                    />
                  ) : null}
                </div>
                {errors[`endTime_${day.day}_${s.id}`] || errors[`startTime_${day.day}_${s.id}`] ? (
                  <div className='col-12 row'>
                    <div className='col-6 d-flex justify-content-center align-items-center error-content'>
                      {errors[`startTime_${day.day}_${s.id}`] && (
                        <>
                          <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                          <p className='text-danger p-0 m-0'>
                            {errors[`startTime_${day.day}_${s.id}`].message}
                          </p>
                        </>
                      )}
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center error-content'>
                      {errors[`endTime_${day.day}_${s.id}`] && (
                        <>
                          <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                          <p className='text-danger p-0 m-0'>
                            {errors[`endTime_${day.day}_${s.id}`].message}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              {editingIndex === idx ? (
                <div className='col-1 d-flex justify-content-between'>
                  <IconButton
                    onClick={() => updatedSchedule(day.day, s.id)}
                    icon={<FwCheckIcon fontSize='18px' />}
                  />
                  <IconButton
                    onClick={() => closeEditing(day.day, s.id)}
                    icon={<CloseIcon size='18px' />}
                  />
                </div>
              ) : (
                <div className='col-1 d-flex justify-content-between'>
                  <IconButton onClick={() => setEditingIndex(idx)} icon={<FwEdit />} />
                  <IconButton
                    onClick={() => removeSchedule(day.day, s.id)}
                    icon={<FwTrashIcon color='#FF274E' />}
                  />
                </div>
              )}
            </div>
          ))
        : null}
    </div>
  );
};

export default CreateSchedule;
