import React from 'react';

const ExclamationRoundedIcon = ({ size = '10px' , color = 'currentColor'}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 10 10' fill='none'>
      <path
        d='M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 7.5C4.725 7.5 4.5 7.275 4.5 7V5C4.5 4.725 4.725 4.5 5 4.5C5.275 4.5 5.5 4.725 5.5 5V7C5.5 7.275 5.275 7.5 5 7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z'
        fill={color}
      />
    </svg>
  );
};

export default ExclamationRoundedIcon;
