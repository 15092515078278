export const getCountries = async (welletContext, language = 'es') => {
  return await welletContext.apis.admin.get(`api/location/countries?lang=${language}`);
};

export const getStates = async (welletContext, country) => {
  return await welletContext.apis.admin.get(`api/location/state/${country}`);
};
export const getCities = async (welletContext, state) => {
  return await welletContext.apis.admin.get(`api/location/city/${state}`);
};

export const getCitiesByMarket = async (welletContext, market) => {
  return await welletContext.apis.admin.get(`api/Location/city/market/${market}`);
};
