import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getConciergeSimple } from '../../services';

export const useGetSellers = (orgId, marketId, options) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery(
    ['sellers-commissions', orgId],
    () => getConciergeSimple(welletContext, marketId),
    queryOptions,
  );
};
