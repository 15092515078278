import React from 'react';
import { InputNumber, Switcher } from '../../uiComponents/common';
import { FormProvider } from 'react-hook-form';
import { Show, Table, TableCell, TableRow } from '../../components';
import { useTranslation } from 'react-i18next';

const ProductsSoldout = ({ methods, data, mode }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'forms.products' });
  const productsRow =
    mode === 'areas'
      ? [
          { title: t('sectionPrices.table.day') },
          { title: '' },
          { title: t('sectionPrices.table.price') },
          { title: t('sectionPrices.table.pricePax') },
          {
            title: t('sectionPrices.table.consumption'),
            tooltip: t('sectionPrices.tooltip.consumption'),
          },
          {
            title: t('sectionPrices.table.consumptionPerExtraPax'),
          },
          { title: t('sectionPrices.table.tip'), tooltip: t('sectionPrices.tooltip.tip') },
          { title: t('sectionPrices.table.tax'), tooltip: t('sectionPrices.tooltip.tax') },
          {
            title: t('sectionPrices.table.deposit'),
            tooltip: t('sectionPrices.tooltip.deposit'),
          },
          { title: t('sectionPrices.table.stock'), tooltip: t('sectionPrices.tooltip.stock') },
        ]
      : [
          { title: t('sectionPrices.table.day') },
          { title: '' },
          { title: t('sectionPrices.table.price') },
          { title: t('sectionPrices.table.tip'), tooltip: t('sectionPrices.tooltip.tip') },
          { title: t('sectionPrices.table.tax'), tooltip: t('sectionPrices.tooltip.tax') },
          {
            title: t('sectionPrices.table.deposit'),
            tooltip: t('sectionPrices.tooltip.deposit'),
          },
        ];

  return (
    <>
      <FormProvider {...methods}>
        <Show.When isTrue={mode === 'groups'}>
          <Table
            data={data ? data : []}
            showPagination={false}
            borderTop={false}
            borderContainer={true}
            columns={productsRow}
            renderRow={(products, index) => (
              <>
                <TableCell style={{ verticalAlign: 'baseline' }} className='pr-1'>
                  {t(`sectionPrices.weekday.${products.weekDay}`)}
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline' }}>
                  <Switcher name={'isActive_' + products.weekDay} />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '130px' }}>
                  <InputNumber
                    name={'price_' + products.weekDay}
                    isRequired={true}
                    iconPadding={'pl-2'}
                    className='hideArrow'
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    icon={<span style={{ fontSize: '16px' }}>$</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '100px' }}>
                  <InputNumber
                    name={'tipPercentage_' + products.weekDay}
                    isRequired={true}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    iconPadding={'pl-2'}
                    maxNumber={100}
                    minNumber={0}
                    className='hideArrow'
                    icon={<span style={{ fontSize: '16px' }}>%</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '100px' }}>
                  <InputNumber
                    name={'taxPercentage_' + products.weekDay}
                    isRequired={true}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    iconPadding={'pl-2'}
                    maxNumber={100}
                    minNumber={0}
                    className='hideArrow'
                    icon={<span style={{ fontSize: '16px' }}>%</span>}
                  />
                </TableCell>
                <TableCell style={{ minWidth: '100px', verticalAlign: 'baseline' }}>
                  <InputNumber
                    name={'depositPercentage_' + products.weekDay}
                    isRequired={true}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    iconPadding={'pl-2'}
                    maxNumber={100}
                    minNumber={0}
                    className='hideArrow'
                    icon={<span style={{ fontSize: '16px' }}>%</span>}
                  />
                </TableCell>
              </>
            )}
          />
        </Show.When>
        <Show.When isTrue={mode === 'areas'}>
          <Table
            data={data ?? []}
            showPagination={false}
            borderTop={false}
            borderContainer={true}
            columns={productsRow}
            renderRow={(products, index) => (
              <>
                <TableCell style={{ verticalAlign: 'baseline' }} className='pr-1'>
                  {t(`sectionPrices.weekday.${products.weekDay}`)}
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline' }}>
                  <Switcher name={'isActive_' + products.weekDay} />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '130px' }}>
                  <InputNumber
                    name={'price_' + products.weekDay}
                    isRequired={true}
                    iconPadding={'pl-2'}
                    className='hideArrow'
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    icon={<span style={{ fontSize: '16px' }}>$</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '130px' }}>
                  <InputNumber
                    name={'priceExtraPax_' + products.weekDay}
                    iconPadding={'pl-2'}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    className='hideArrow'
                    icon={<span style={{ fontSize: '16px' }}>$</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '130px' }}>
                  <InputNumber
                    name={'consumption_' + products.weekDay}
                    iconPadding={'pl-2'}
                    className='hideArrow'
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    isRequired={true}
                    icon={<span style={{ fontSize: '16px' }}>$</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '130px' }}>
                  <InputNumber
                    name={'consumptionPerExtraPax_' + products.weekDay}
                    iconPadding={'pl-2'}
                    className='hideArrow'
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    isRequired={true}
                    icon={<span style={{ fontSize: '16px' }}>$</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '100px' }}>
                  <InputNumber
                    name={'tipPercentage_' + products.weekDay}
                    isRequired={true}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    iconPadding={'pl-2'}
                    maxNumber={100}
                    minNumber={0}
                    className='hideArrow'
                    icon={<span style={{ fontSize: '16px' }}>%</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '100px' }}>
                  <InputNumber
                    name={'taxPercentage_' + products.weekDay}
                    isRequired={true}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    iconPadding={'pl-2'}
                    maxNumber={100}
                    minNumber={0}
                    className='hideArrow'
                    icon={<span style={{ fontSize: '16px' }}>%</span>}
                  />
                </TableCell>
                <TableCell style={{ verticalAlign: 'baseline', minWidth: '100px' }}>
                  <InputNumber
                    name={'depositPercentage_' + products.weekDay}
                    isRequired={true}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    iconPadding={'pl-2'}
                    maxNumber={100}
                    minNumber={0}
                    className='hideArrow'
                    icon={<span style={{ fontSize: '16px' }}>%</span>}
                  />
                </TableCell>
                <TableCell style={{ minWidth: '100px', verticalAlign: 'baseline' }}>
                  <InputNumber
                    className='hideArrow'
                    name={'stock_' + products.weekDay}
                    isDisabled={!methods.watch('isActive_' + products.weekDay)}
                    isRequired={true}
                  />
                </TableCell>
              </>
            )}
          />
        </Show.When>
      </FormProvider>
    </>
  );
};

export default ProductsSoldout;
