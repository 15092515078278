import React, { useContext, useState } from 'react';
import { Button, IconButton, Input, Loading, Slider, Status } from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchOrganizations } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { PageHeader, Table, TableCell, TableRow } from '../../components';
import { useDispatch } from 'react-redux';
import { setCurrentOrg, setSelectedOrg } from '../../actions';
import { PencilIcon, SearchIcon } from '../../uiComponents/icons';
import useDebounce from '../../hooks/useDebounce';
import { useCreateBreadCrumbs, usePagination } from '../../hooks';
import { useTranslation } from 'react-i18next';

const Organizations = () => {
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.organization' });
  const welletContext = useContext(WelletContext);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const [showInactives, setShowInactives] = useState(false);
  const [value, setValue] = useState('');
  const location = useLocation();

  const debouncedSearch = useDebounce(value, 500);
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // Api Querys
  const organizationQuery = useQuery({
    queryKey: ['organizations', organization.id, showInactives, debouncedSearch, skip],
    queryFn: () =>
      fetchOrganizations(
        welletContext, // wellet contex
        organization.id, // id
        showInactives, // include inactives
        debouncedSearch, // filedFilter
        limit, // limit
        skip, // skip
      ),
    onSuccess: (data) => setTotalAndReset(data?.data?.totalRegisters),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const organizationData = organizationQuery?.data?.data?.items ?? [];

  const headerAdmin = [
    { title: '', value: '', filterable: false },
    { title: '', value: '', filterable: false },
    { title: t('table.name'), value: '', filterable: false },
    { title: t('table.address'), value: '', filterable: false },
    { title: t('table.city'), value: '', filterable: false },
    { title: t('table.state'), value: '', filterable: false },
    { title: t('table.country'), value: '', filterable: false },
    { title: t('table.email'), value: '', filterable: false },
    { title: t('table.phone'), value: '', filterable: false },
    { title: t('table.isActive'), value: '', filterable: false },
  ];

  // Functions
  const handleNavigate = (org) => {
    const organizationBody = {
      ...organization,
      marketName: organization.marketName,
      name: organization.marketName === org.organization.name ? null : org.organization.name,
      id: org.organization.organizationId,
      isMarket: org.organization.marketSetupId === org.organization.organizationId,
      marketId: org.organization.marketSetupId,
      organizationLogo: org.organization.logo ?? null,
    };

    dispatch(setCurrentOrg(organizationBody));
    dispatch(setSelectedOrg(organizationBody));
  };

  useCreateBreadCrumbs([{ name: t('title') }]);

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={
          <Button
            className={'btn-wellet-secondary'}
            onClick={() => navigate('/new-organization')}
            size={'medium'}
            text={`+ ${t('btnCreate')}`}
          />
        }
      />
      <div className='my-2 row align-items-center'>
        <div className='col col-sm-auto'>
          <Input
            icon={<SearchIcon color='currentColor' size='16px' />}
            type={'search'}
            name={'search'}
            value={value}
            onChangeValue={(e) => setValue(e.target.value)}
            placeholder={t('search')}
          />
        </div>
        <div className='col col-sm-auto'>
          <Slider
            className={'col-auto'}
            label={t('onlyActives')}
            onClick={() => setShowInactives(!showInactives)}
            defaultChecked={!showInactives}
          />
        </div>
      </div>

      {organizationQuery.isLoading ||
      organizationData.lenght === 0 ||
      organizationQuery.isPreviousData ? (
        <Loading />
      ) : (
        <>
          <Table
            data={organizationData}
            hasPagination={false}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            columns={headerAdmin}
            renderRow={(org, index) => (
              <>
                <TableCell style={{ width: '60px' }} type={'primary'}>
                  <IconButton
                    icon={<PencilIcon size='20px' />}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/edit-organization/${org.organization.organizationId}`, {
                        state: location.pathname + location.search,
                      });
                    }}
                  />
                </TableCell>
                <TableCell type={'primary'}>
                  <img
                    className='mr-2'
                    style={{
                      height: '35px',
                      width: '35px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    src={org.organization.logo}
                    alt={''}
                  />
                </TableCell>
                <TableCell type={'primary'}>{org.organization.name}</TableCell>
                <TableCell type={'primary'}>{org.organization.address}</TableCell>
                <TableCell type={'primary'}>{org.organization.city}</TableCell>
                <TableCell type={'primary'}>{org.organization.state}</TableCell>
                <TableCell type={'primary'}>{org.organization.countryCode}</TableCell>
                <TableCell type={'primary'}>{org.organization.email}</TableCell>
                <TableCell type={'primary'}>{org.organization.telephone}</TableCell>
                <TableCell type={'primary'}>
                  <Status
                    status={org.organization.isActive ? true : false}
                    text={org.organization.isActive ? t('active') : t('inactive')}
                  />
                </TableCell>
              </>
            )}
            onRowClick={(org) => handleNavigate(org)}
          />
        </>
      )}
    </>
  );
};

export default Organizations;
