import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { WelletContext } from '../../../context/wellet/welletContext';
import { getWireDetail } from '../../../services';
import { Show } from '../../../components';
import { ErrorAlert, Loading } from '../../../uiComponents/common';
import WiresPaymentDetail from './wiresPaymentDetail';

// (04/24/24) To Do: Corregir el breadcumb cuando volvemos a la pagina
const WiresPaymentDetailContainer = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.wiresPaymentDetail' });
  const welletContext = useContext(WelletContext);

  const { id } = useParams();
  const idNumber = +id;

  // API Queries
  const wireDetailQuery = useQuery({
    queryKey: ['wire-detail', idNumber],
    queryFn: () => getWireDetail(welletContext, idNumber),
    enabled: id !== null,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const mode = wireDetailQuery?.data?.data?.userWithdrawalMethod?.withdrawalMethod?.methodType;

  return (
    <>
      <Show>
        <Show.When
          isTrue={
            wireDetailQuery.isFetching ||
            wireDetailQuery.isLoading ||
            wireDetailQuery.isPreviousData
          }
        >
          <Loading />
        </Show.When>
        <Show.When isTrue={wireDetailQuery.isError}>
          <ErrorAlert text={t('error')} />
        </Show.When>
        <Show.Else>
          <WiresPaymentDetail data={wireDetailQuery.data?.data} mode={mode} />
        </Show.Else>
      </Show>
    </>
  );
};

export default WiresPaymentDetailContainer;
