import React from 'react';

export default function ArrowDown({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.53033 5.53033C5.23744 5.82322 4.76256 5.82322 4.46967 5.53033L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L5 3.93934L8.46967 0.46967C8.76256 0.176777 9.23744 0.176777 9.53033 0.469671C9.82322 0.762564 9.82322 1.23744 9.53033 1.53033L5.53033 5.53033Z'
        fill={color}
      />
    </svg>
  );
}
