import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getAllNotifications } from '../../services';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';

export const useGetNotifications = (options, { limit, skip, filter, sortBy, columnFilter }) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptions,
    ...options,
  };
  return useQuery(
    ['notifications', limit, skip, filter, sortBy, columnFilter],
    () =>
      getAllNotifications(welletContext, {
        limit,
        skip,
        status: filter,
        includeInactive: true,
        sortBy,
        columnFilter,
      }),
    queryOptions,
  );
};
