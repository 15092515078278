import './header.css';
import {
  MenuBurger,
  FwUserIcon,
  ChangePasswordIcon,
  LogOutIcon,
  SettingsIcon,
} from '../../uiComponents/icons';
import { useContext } from 'react';
import SidebarContext from '../../context/sidebarContext';
import { useDispatch } from 'react-redux';
import { logout, setPaymentMethods } from '../../actions';
import { Dropdown, UserImage } from '../../uiComponents/common';

import { IconButton } from '../../uiComponents/common';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  LanguageSelector,
  MarketSelector,
  NotificationMenu,
  OrganizationSelector,
  Show,
} from '../../components';
import { useOrganizations, useUser } from '../../hooks';
import { useSelector } from 'react-redux';
import BellIcon from '../../uiComponents/icons/bellIcon';
import { hasAllowedRoles } from '../../helpers/roles';

export default function Header() {
  const { t } = useTranslation('translation', { keyPrefix: 'components.account' });
  const { toggleIsSidebarOpen } = useContext(SidebarContext);
  const userId = useSelector((state) => state.user.user.sid);
  const isMobile = window.innerWidth < 992;
  const dispatch = useDispatch();
  const location = useLocation();
  const { roles } = useUser();
  const hasNotification = useSelector((state) => state.user.user.hasNotification);
  const organizationsQuery = useOrganizations(userId, {
    enabled: !isMobile,
    refetchOnMount: false,
    onSuccess: (data) => {
      dispatch(setPaymentMethods(data?.withdrawalMethods));
    },
  });

  const handleSignOut = () => {
    dispatch(logout());
  };

  const navigate = useNavigate();

  return (
    <div className='navbar header-container py-2 row '>
      <Show.When isTrue={isMobile}>
        <div
          className=' col-auto cursor-pointer text-sidebar-link mr-3 d-flex d-lg-none align-items-center'
          onClick={toggleIsSidebarOpen}
        >
          <MenuBurger color='currentColor' size='18px' />
        </div>
      </Show.When>
      <Show.When isTrue={!isMobile}>
        <div className='col-auto'>
          <MarketSelector callBaclClose={toggleIsSidebarOpen} data={organizationsQuery} />
        </div>
        <div className='col-auto'>
          <OrganizationSelector callBaclClose={toggleIsSidebarOpen} data={organizationsQuery} />
        </div>
      </Show.When>

      <div className='col d-flex justify-content-end align-items-center'>
        {hasAllowedRoles(roles, ['superadministrator', 'marketadmin', 'admin']) ? (
          <div className='mr-2'>
            <IconButton
              isActive={location.pathname.includes('/settings')}
              onClick={() => navigate('/settings')}
              icon={<SettingsIcon color='#667085' size='20px' />}
            />
          </div>
        ) : null}

        <Dropdown
          id={'notification'}
          isHover={false}
          isClickable={true}
          btn={
            <div className='mr-2 pr-1'>
              <IconButton
                notification={hasNotification}
                icon={<BellIcon color='#667085' size='20px' />}
              />
            </div>
          }
          renderList={({ handleClose, open }) => (
            <NotificationMenu handleClose={handleClose} open={open} />
          )}
        />

        <Dropdown
          id={'logoutDropdown'}
          isHover={false}
          customDropdwon={''}
          btn={<UserImage url={''} name={''} isVerified={false} size={40} />}
          renderList={() => (
            <div>
              <Link className='dropdown-item justify-content-start' to={'/profile'}>
                <FwUserIcon color='currentColor' size='20px' />
                <span className='ml-2'>{t('profile')}</span>
              </Link>
              <Link className='dropdown-item justify-content-start' to={'/change-password'}>
                <ChangePasswordIcon color='currentColor' size='20px' />
                <span className='ml-2'>{t('password')}</span>
              </Link>
              <div className='dropdown-item justify-content-start' onClick={handleSignOut}>
                <LogOutIcon color='currentColor' size='20px' />
                <span className='ml-2'>{t('session')}</span>
              </div>

              <LanguageSelector />
            </div>
          )}
        />
      </div>
    </div>
  );
}
