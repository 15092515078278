import React, { useEffect, useState } from 'react';
import InputBase from './inputBase';
import { format } from 'date-fns';
import { useFormContext, Controller } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { Calendar } from 'react-date-range';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../helpers/dates';
import { CloseIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const InputDate = ({
  name,
  placeholder,
  className,
  icon,
  label,
  isRequired = false,
  popUpProps,
  isDisabled,
  minDate,
  maxDate,
  dateText = false,
  clear = false,
  cleanIfDisable = false,
  clearCallback = () => {},
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDaySelect = (date, close) => {
    if (
      date &&
      (selectedDate ? format(date, 'y-MM-dd') !== format(selectedDate, 'y-MM-dd') : true)
    ) {
      const formattedDate = format(date, 'y-MM-dd');
      setSelectedDate(date);
      setValue(name, formattedDate);
    } else {
      setSelectedDate(null);
      setValue(name, '');
    }
    close();
  };

  const getDate = (value) => {
    if (locale.code === 'es') {
      return format(new Date(value), 'dd MMMM yyyy', { locale });
    } else return format(new Date(value), 'MMMM dd, yyyy', { locale });
  };

  const getDateTextFormatted = (value) => {
    if (locale.code === 'es') {
      return format(new Date(value), "dd 'de' MMMM 'de' yyyy", { locale });
    } else return format(new Date(value), 'MMMM dd, yyyy', { locale });
  };

  useEffect(() => {
    if (isDisabled && cleanIfDisable) {
      setValue(name, '');
    }
  }, [isDisabled]);

  const handleClear = () => {
    clearCallback();
    setValue(name, '');
  };

  return (
    <div className={`form-group ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        rules={{
          required: isRequired ? t('required') : null,
        }}
        render={({ field }) => (
          <div>
            <Popup
              nested
              trigger={
                <InputBase
                  type='text'
                  errors={errors}
                  name={name}
                  placeholder={placeholder}
                  icon={icon}
                  label={label}
                  value={
                    field.value
                      ? dateText
                        ? getDateTextFormatted(selectedDate ? selectedDate : field.value)
                        : getDate(selectedDate ? selectedDate : field.value)
                      : field.value
                  }
                  isRequired={isRequired}
                  isDisabled={isDisabled}
                  autoComplete={'off'}
                  readOnly={true}
                  endComponent={
                    clear && (
                      <div onClick={handleClear} className='px-2' style={{ cursor: 'pointer' }}>
                        <CloseIcon />
                      </div>
                    )
                  }
                />
              }
              className='test'
              modal
            >
              {(close) => (
                <div className='card-popup' onClick={(e) => e.stopPropagation()}>
                  <Calendar
                    onChange={(date) => handleDaySelect(date, close)}
                    locale={locale}
                    date={selectedDate ? selectedDate : field.value}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </div>
              )}
            </Popup>
            {errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      ></Controller>
    </div>
  );
};

export default InputDate;
