import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { cancelReservation } from '../../services';

export const useCancelMutation = () => {
  const welletContext = useContext(WelletContext);

  return useMutation((data) =>
    cancelReservation(welletContext, {
      reservationId: data.reservationId,
      amount: data.amount,
      refundComment: data?.comment,
      chargeId: data.chargeId !== '' ? data.chargeId : null,
    }),
  );
};
