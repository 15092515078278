import React, { useContext, useState } from 'react';
import { useRef } from 'react';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { getTimezoneFormatted } from '../../../helpers/format';
import {
  InputNumber,
  InputPhone,
  InputText,
  InputEmails,
  ModernSwitcher,
  Select,
  Subtitle,
  Toast,
  ToggleToken,
  Loading,
  TextEditor,
  InputList,
  Tabs,
  InputMedia,
  FormButton,
} from '../../../uiComponents/common';
import {
  CityIcon,
  CountryIcon,
  FwAtSignIcon,
  FwClockIcon,
  FwGlobeIcon,
  FwLocationIcon,
  FwOrganizationIcon,
  FwPhoneIcon,
  NameIcon,
  NumberTaskIcon,
  StateIcon,
  TaxIcon,
  TimeZoneIcon,
  WebsiteIcon,
  ZipCodeIcon,
} from '../../../uiComponents/icons';
import {
  fetchTaxTypes,
  getCities,
  getOrganizationById,
  getStates,
  postOrganization,
  putOrganization,
} from '../../../services';
import { useSelector } from 'react-redux';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import { useTranslation } from 'react-i18next';

const OrganizationForm = ({
  timezoneQuery,
  organization,
  countriesQuery,
  isEditingOrganization,
  socialNetwork,
  contactInformation,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'forms.organization' });
  const navigate = useNavigate();
  const { orgId } = useParams();
  const methods = useForm();
  const welletContext = useContext(WelletContext);
  const lastClickedButton = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const user = useSelector((state) => state.user.user.sid);
  const [isRedirect, setIsRedirect] = useState(null);

  // Data Froms Querys
  // const enabelBiometric = methods.watch('isBiometric');
  const countryInput = methods.watch('country')?.value;
  const stateInput = methods.watch('state')?.value;
  const enabelWpp = methods.watch('isWhatsapp');

  // Api call when user is editing organization
  const { languages, currentLng, changeLanguage } = useLanguageSwitcher({}, methods);

  const organizationQuery = useQuery({
    queryKey: ['organization', orgId],
    queryFn: () => getOrganizationById(welletContext, orgId),
    onSuccess: (data) => {
      methods.reset(formatActivityData(data));
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
    enabled:
      isEditingOrganization && languages.length > 0 && countriesQuery.isSuccess ? true : false,
  });

  // Api calls that depends on others inputs
  const taxTypeQuery = useQuery({
    queryKey: ['timezones', countryInput],
    queryFn: () => fetchTaxTypes(welletContext, countryInput),
    onSuccess: (data) =>
      methods.setValue(
        'taxType',
        data.data.map((tax) => ({ value: tax.shortForm, label: tax.shortForm })),
      ),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: countryInput ? true : false,
  });
  const statesQuery = useQuery({
    queryKey: ['states', countryInput],
    queryFn: () => getStates(welletContext, countryInput),
    onSuccess: () => {
      if (!isEditingOrganization) {
        methods.setValue('state', '');
        methods.setValue('city', '');
      }
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: countryInput ? true : false,
  });
  const citiesQuery = useQuery({
    queryKey: ['cities', stateInput],
    queryFn: () => getCities(welletContext, stateInput),
    onSuccess: () => {
      if (!isEditingOrganization) {
        methods.setValue('city', '');
      }
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: stateInput ? true : false,
  });
  const organizationMutation = useMutation({
    mutationFn: (body) => {
      if (isEditingOrganization) {
        return putOrganization(welletContext, body);
      } else {
        return postOrganization(welletContext, body);
      }
    },
    onSuccess: () => {
      setShowToast(true);
    },
    onError: (error) => {},
  });
  // Formatted Data
  const timezone = timezoneQuery.data;
  const taxTypes = taxTypeQuery?.data?.data?.map((ty) => ({
    label: ty.shortForm,
    value: ty.shortForm,
  }));
  const socialNetworkFormatted = socialNetwork?.data?.data?.map((ow) => ({
    label: ow,
    value: ow,
  }));
  const contactInformationFormatted = contactInformation?.data?.data?.map((ow) => ({
    label: ow,
    value: ow,
  }));
  const countries = countriesQuery?.data?.data?.map((c) => ({
    label: c.name,
    value: c.id,
    countryCode: c.countryCode,
  }));
  const state = statesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const city = citiesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));

  const queryClient = useQueryClient();
  const editorOptions = ['inline', 'blockType', 'fontSize', 'list', 'textAlign'];
  const socialInitialState = methods.getValues('socialNetworks') ?? [];
  const organizationContact = methods.getValues('organizationContact') ?? [];

  // Functions
  const clearForm = () => {
    if (isEditingOrganization) {
      navigate(`/organizations`);
    } else {
      methods.reset();
      navigate(`/organizations`);
    }
  };
  const onSubmit = (data) => {
    const langOrganizations = languages.map((lng) => {
      return {
        organizationId: data.organizationId ?? 0,
        language: lng.shortForm,
        termsAndConditions: data[`terms_${lng.shortForm}`],
        faq: data[`faq_${lng.shortForm}`],
        privacyPolicy: data[`privacy_${lng.shortForm}`],
      };
    });

    let socialNetworks = [];

    for (const key in data) {
      if (key.startsWith('nameSocialNetwork_')) {
        const socialNetworkName = key.substring('nameSocialNetwork_'.length);
        const socialNetworkValue = data[key];

        socialNetworks.push({
          socialNetwork: socialNetworkName,
          value: socialNetworkValue,
        });
      }
    }
    let organizationContacts = [];
    for (const key in data) {
      if (key.startsWith('nameContactInfo_')) {
        const organizationContact = key.substring('nameContactInfo_'.length);
        const value = data[key];

        organizationContacts.push({
          organizationContact: organizationContact,
          value: value,
        });
      }
    }

    const body = {
      organizationId: data.organizationId ?? 0,
      businessType: 'ShowProducer',
      name: data.name,
      address: data.address || null,
      city: data.city.label || null,
      state: data.state.label || null,
      countryCode: data.country.countryCode || null,
      zipCode: data.zip || null,
      legalName: data.legalName || null,
      timezone: data.timezone.value,
      telephone: data.phone || null,
      email: data.email || null,
      isActive: !isEditingOrganization ? true : data.isActive,
      //tenantId: organization.tenantId,
      logo: data?.logo?.base64 ?? data?.logo,
      whatsAppConfirmation: data.isWhatsapp ? data.whatsapp || null : null,
      website: data.website || null,
      taxIdentificationNumber: data.tax || null,
      taxIdentificationType: data?.taxType[0]?.value || null,
      isAutomaticConfirmation: false,
      isWhatsAppEnable: data.isWhatsapp ? data.isWhatsapp : false,
      purchaseConfirmationEmails: data?.emails?.map((email) => email.value.trim()) ?? [],
      marketSetupId: organization.id,
      biometricRequired: false, // sacar
      biometricAutomatic: false, // sacar
      utcOffSet: parseInt(data.utcOffSet),
      faq: data.faq,
      termsAndConditions: data.terms,
      privacyPolicy: data.privacy,
      defaultLanguage: data.defaultLanguage.value,
      socialNetworks: socialNetworks,
      organizationContact: organizationContacts,
      langOrganizations: removeObjectsWithEmptyFields(langOrganizations, [
        'termsAndConditions',
        'faq',
        'privacyPolicy',
      ]),
      logoType: data?.logoType?.base64 ? data?.logoType?.base64 : null,
      logoTypeLight: data?.logoTypeLight?.base64 ? data?.logoTypeLight?.base64 : null,
    };

    organizationMutation.mutate(body);
  };
  const formatActivityData = (d) => {
    const data = d.data.organization;
    const formattedData = {
      organizationId: data.organizationId,
      name: data.name,
      address: data.address || null,
      country: countries?.find((cty) => cty.countryCode === data?.countryCode),
      state: { label: data?.state, value: data?.cityDTO?.state?.id },
      city: { label: data?.city, value: data?.cityDTO?.id },
      zip: data.zipCode || null,
      legalName: data.legalName || null,
      timezone: getTimezoneFormatted(timezone)?.find((tz) => tz.value === data?.timezone),
      phone: data.telephone || null,
      email: data.email || null,
      isActive: data.isActive ? data.isActive : false,
      tenantId: data.tenantId,
      logo: data.logo ? { base64: data.logo, name: 'logo' } : null,
      logoType: data.logoType ? { base64: data.logoType, name: 'logo' } : null,
      logoTypeLight: data.logoTypeLight ? { base64: data.logoTypeLight, name: 'logoLight' } : null,
      website: data.website || null,
      description: data.description || null,
      tax: data.taxIdentificationNumber || null,
      taxType: data?.taxIdentificationType,
      legals: '',
      isAutomatic: false,
      isWhatsapp: data.isWhatsAppEnable ? data.isWhatsAppEnable : false,
      whatsapp: data.whatsAppConfirmation,
      emails: data.purchaseConfirmationEmails.map((d) => ({ label: d, value: d })),
      marketSetupId: organization.id,
      socialNetworks: data?.socialNetworks,
      organizationContact: data?.organizationContact,
      utcOffSet: data.utcOffSet || null,
      defaultLanguage: data?.defaultLanguage
        ? {
            value: data?.defaultLanguage,
            label: languages.find((l) => l.shortForm === data?.defaultLanguage)?.title,
          }
        : null,
    };

    data.langOrganizations.forEach((langOrg) => {
      const lang = langOrg.language;
      formattedData[`terms_${lang}`] = langOrg.termsAndConditions;
      formattedData[`faq_${lang}`] = langOrg.faq;
      formattedData[`privacy_${lang}`] = langOrg.privacyPolicy;
    });
    return formattedData;
  };
  const handleToastCallback = () => {
    queryClient.refetchQueries({ queryKey: ['organizations', user], exact: true });
    if (isRedirect) {
      methods.reset();
      navigate(`/organizations`);
      setShowToast(false);
    } else {
      setShowToast(false);
    }
    organizationMutation.reset();
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  return (
    <>
      {showToast && (
        <Toast
          message={isEditingOrganization ? t('toastSuccess.edit') : t('toastSuccess.new')}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {organizationMutation.error ? (
        <Toast
          message={isEditingOrganization ? t('toastError.edit') : t('toastError.new')}
          type='ERROR'
          onCallback={() => {
            organizationMutation.reset();
          }}
        />
      ) : (
        <></>
      )}
      {organizationQuery.isLoading ||
      countriesQuery.isLoading ||
      timezoneQuery.isLoading ||
      socialNetwork.isLoading ||
      (!!orgId && !organizationQuery.isFetched) ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='row mt-3 mt-sm-2 align-items-end '>
              <Select
                options={languages.map((language) => ({
                  label: language.title,
                  value: language.shortForm,
                }))}
                label={t('inputLng.label')}
                placeholder={t('inputLng.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwGlobeIcon />}
                name={'defaultLanguage'}
                isRequired={true}
              />
            </div>
            <Subtitle text={<h3>{t('mainInformation.title')}</h3>} />
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'name'}
                label={t('mainInformation.inputName.label')}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={t('mainInformation.inputName.placeholder')}
                icon={<NameIcon />}
                isRequired={true}
              />
              <div className={'col-12 col-sm-6 col-xl-4'}>
                {isEditingOrganization && (
                  <ToggleToken
                    label={t('mainInformation.inputActive.label')}
                    activeText={t('mainInformation.inputActive.active')}
                    inactiveText={t('mainInformation.inputActive.inactive')}
                    name={'isActive'}
                  />
                )}
              </div>
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'legalName'}
                label={t('mainInformation.inputLegalName.label')}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={t('mainInformation.inputLegalName.placeholder')}
                icon={<FwOrganizationIcon />}
                isRequired={true}
              />
              <Select
                options={countries}
                label={t('mainInformation.inputCountry.label')}
                placeholder={t('mainInformation.inputCountry.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<CountryIcon />}
                name={'country'}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <Select
                options={state}
                label={t('mainInformation.inputState.label')}
                placeholder={t('mainInformation.inputState.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<StateIcon />}
                name={'state'}
                isDisabled={countryInput === undefined}
                isRequired={true}
              />
              <Select
                options={city}
                label={t('mainInformation.inputCity.label')}
                placeholder={t('mainInformation.inputCity.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<CityIcon />}
                name={'city'}
                isDisabled={stateInput === undefined}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'zip'}
                label={t('mainInformation.inputCp.label')}
                placeholder={t('mainInformation.inputCp.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<ZipCodeIcon />}
              />
              <Select
                options={getTimezoneFormatted(timezone)}
                label={t('mainInformation.inputTimeZone.label')}
                placeholder={t('mainInformation.inputTimeZone.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<TimeZoneIcon />}
                name={'timezone'}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'address'}
                label={t('mainInformation.inputAddress.label')}
                placeholder={t('mainInformation.inputAddress.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwLocationIcon />}
              />
            </div>
            <Subtitle text={<h3 className='mt-3'>{t('generalInformation.title')}</h3>} />
            <div className='row mt-3'>
              <div className='col-auto mb-1'>
                <ModernSwitcher
                  text={t('generalInformation.inputWpp.label')}
                  name={'isWhatsapp'}
                  onClick={() => {
                    methods.clearErrors('whatsapp');
                  }}
                />
              </div>
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputPhone
                name='phone'
                className={'col-12 col-sm-6 col-xl-4 my-2 my-sm-0'}
                label={t('generalInformation.inputPhone.label')}
                placeholder={t('generalInformation.inputPhone.placeholder')}
                icon={<FwPhoneIcon />}
                defaultCountry='MX'
                initialValue={methods.watch('phone')}
              />
              <InputPhone
                name='whatsapp'
                className={'col-12 col-sm-6 col-xl-4 my-2 my-sm-0'}
                label={t('generalInformation.inputPhoneWpp.label')}
                placeholder={t('generalInformation.inputPhoneWpp.placeholder')}
                icon={<FwPhoneIcon />}
                defaultCountry='MX'
                isDisabled={enabelWpp ? false : true}
                isRequired={false}
                initialValue={methods.watch('whatsapp')}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'website'}
                label={t('generalInformation.inputWebSite.label')}
                placeholder={t('generalInformation.inputWebSite.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<WebsiteIcon />}
              />
              <InputText
                name={'email'}
                label={t('generalInformation.inputEmail.label')}
                placeholder={t('generalInformation.inputEmail.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwAtSignIcon />}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <Select
                options={taxTypes}
                label={t('generalInformation.inputTypeId.label')}
                placeholder={t('generalInformation.inputTypeId.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<TaxIcon size='18px' />}
                name={'taxType'}
                isDisabled={true}
              />
              <InputText
                name={'tax'}
                label={t('generalInformation.inputNroIdentification.label')}
                placeholder={t('generalInformation.inputNroIdentification.placeholder')}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<NumberTaskIcon size='18px' />}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputEmails
                name={'emails'}
                label={t('generalInformation.inputConfirmationEmails.label')}
                placeholder={t('generalInformation.inputConfirmationEmails.placeholder')}
                className={'col-xl-8 col'}
                icon={<FwAtSignIcon />}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputNumber
                label={t('generalInformation.inputHandleTimeZone.label')}
                placeholder={t('generalInformation.inputHandleTimeZone.placeholder')}
                className={'col-xl-4 col'}
                name={'utcOffSet'}
                icon={<FwClockIcon />}
                acceptNegative={true}
              />
            </div>
            <div className='row mt-4'>
              <InputList
                className={'col-xl-8 col-12'}
                {...methods}
                data={socialNetworkFormatted}
                initalStae={socialInitialState}
                labelSelect={t('generalInformation.inputSocialNetworks.label')}
                nameSelect={'currentSocialNetwork'}
                nameInput={'currentSocialNetworkName'}
                baseNameInput={'nameSocialNetwork_'}
                placeholder={t('generalInformation.inputSocialNetworks.placeholder')}
                placeholderSelect={t('generalInformation.inputSocialNetworks.placeholderSelect')}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputList
                className={'col-xl-8 col-12'}
                {...methods}
                data={contactInformationFormatted}
                initalStae={organizationContact}
                nameSelect={'currentContactInfo'}
                nameInput={'currentContactInfoName'}
                baseNameInput={'nameContactInfo_'}
                labelSelect={t('generalInformation.inputContact.label')}
                placeholder={t('generalInformation.inputContact.placeholder.contact')}
                placeholderSelect={t('generalInformation.inputContact.placeholderSelect')}
                isPhone={true}
              />
            </div>

            <Subtitle text={<h3 className='mt-3'>{t('textEditor.title')}</h3>} />
            <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
            <div className='row mt-3 mt-sm-2'>
              <TextEditor
                label={t('textEditor.faq')}
                className='col col-xl-8'
                name={`faq_${currentLng}`}
                options={editorOptions}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <TextEditor
                label={t('textEditor.termsAndConditions')}
                className='col col-xl-8'
                name={`terms_${currentLng}`}
                options={editorOptions}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <TextEditor
                label={t('textEditor.privatePolicies')}
                className='col col-xl-8'
                name={`privacy_${currentLng}`}
                options={editorOptions}
              />
            </div>
            <Subtitle text={<h3>{t('multimedia.title')}</h3>} />
            <div className='row mt-3 mb-2 mt-sm-2'>
              <InputMedia
                id={'logoFile'}
                name={'logo'}
                className='col-12 col-sm-6 col-xl-3 mb-1'
                placeholder={'Logo'}
                label={'Logo'}
                isRequired={true}
                maxSize={4096000}
                titleModal='Logo'
              />
              <InputMedia
                id={'logoType'}
                name={'logoType'}
                className='col-12 col-sm-6 col-xl-3 mb-1'
                label={t('multimedia.logoType')}
                isRequired={true}
                maxSize={4096000}
              />
              <InputMedia
                id={'logoTypeLight'}
                name={'logoTypeLight'}
                className='col-12 col-sm-6 col-xl-3 mb-1'
                label={t('multimedia.logoTypeLight')}
                isRequired={true}
                maxSize={4096000}
              />
            </div>
            <div className='d-flex justify-content-end mt-2'>
              <FormButton
                mutation={organizationMutation}
                handleRedirect={handleRedirect}
                clearForm={clearForm}
                reff={lastClickedButton}
                isDisabled={
                  organizationMutation.isSuccess ||
                  organizationMutation.isLoading ||
                  organizationMutation.isError
                }
              />
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default OrganizationForm;
