import React, { useState } from 'react';
import { IconButton } from '../../../uiComponents/common';
import { FwEdit, FwTrashIcon } from '../../../uiComponents/icons';
import DeleteVideo from './modals/deleteVideo';
import { GalleryVideo } from '../gallery';

const VideoGallery = ({ videos, refetchQuery }) => {
  const [video, setVideo] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  if (videos.length === 0) return null;

  const onRemove = (video) => {
    setVideo(video.id);
  };

  return (
    <>
      <div className='row justify-content-center justify-content-sm-start'>
        {videos.map((video, idx) => (
          <div className='col-auto p-0 m-1 gallery-item' key={video.id || idx}>
            <div className='card-gallery'>
              <div className='card-img-actions p-1'>
                <div
                  className='video-container d-flex align-items-center'
                  style={{ height: '100%', minWidth: '230px' }}
                >
                  <video width='100%' height='130px' controls>
                    <source src={video.videoUrl} type='video/mp4' />
                  </video>
                </div>
                <h6 className='m-0 mt-2 font-weight-bold text-truncate'>{video.title}</h6>
              </div>
              <div className='image-overlay d-flex justify-content-end'>
                <IconButton
                  onClick={() => setShowVideo(video)}
                  classNameIcon={'notHoverIcon'}
                  icon={<FwEdit color='var(--hover)' size={20} classNameIcon={'hoverIcon'} />}
                />
                <IconButton
                  onClick={() => onRemove(video)}
                  classNameIcon={'notHoverIcon'}
                  icon={<FwTrashIcon color='var(--hover)' size={20} classNameIcon={'hoverIcon'} />}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <DeleteVideo
        videoId={video}
        open={video !== false}
        setOpen={setVideo}
        refetchQuery={refetchQuery}
      />
      <GalleryVideo
        open={showVideo !== false}
        setOpen={setShowVideo}
        showdId={showVideo?.showId}
        refetchQuery={refetchQuery}
        showVideo={showVideo}
      />
    </>
  );
};

export default VideoGallery;