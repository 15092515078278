import { getAuth, signOut } from 'firebase/auth';
import { setCurrentOrg, setLanguage, setSelectedOrg } from './appActions';
import parseJwt from '../helpers/parseJwt';
import { apis } from '../api';
import { checkKeyInLocalStorage } from '../helpers/localstorage';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Acción para iniciar sesión
export const login = (userData) => ({
  type: LOGIN,
  payload: userData,
});
export const setLoginLoading = (loading) => ({
  type: 'SET_LOGIN_LOADING',
  payload: loading,
});
export const authChecked = () => {
  return { type: 'ASSOCIATES_AUTH_CHECKED' };
};
export const exchangeToken = async (dispatch, user, callback = null) => {
  const token = await user.getIdToken();

  try {
    const response = await apis.tickets.post(`/auth/exchange`, { token });
    await dispatch(associateAuthenticated(response.data.token));
    await dispatch(getAssociateSaleSetup(response.data.token, callback));

    return response;
  } catch (error) {
    // Revisar el Logout
    dispatch(logout());
    console.error(error);
  }
};
// Acción para cerrar sesión
export const logout = (callback = null) => {
  return (dispatch) => {
    const auth = getAuth();
    localStorage.clear();
    signOut(auth).then(() => {
      dispatch({ type: 'ASSOCIATES_LOGOUT' });
      if (typeof callback === 'function') {
        callback();
      }
    });
  };
};

export const associateAuthenticated = (jwt) => {
  const userDetails = jwt ? parseJwt(jwt) : null;
  const userRoles = userDetails['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

  return {
    type: 'ASSOCIATES_AUTHENTICATED',
    jwt,
    userRoles,
  };
};

export const getAssociateSaleSetup = (jwt, callback) => {
  // const currOrg = JSON.parse(localStorage.getItem('currentOrg'));
  return async (dispatch) => {
    try {
      const setup = await apis.admin.get(`/api/user`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      await dispatch({
        type: 'ASSOCIATES_SALES_SETUP',
        payload: setup.data,
      });
      await dispatch({
        type: 'UTC_OFFSET',
        payload: setup.data.organization.utcOffSet,
      });

      if (
        !localStorage.getItem('currentOrg') ||
        !checkKeyInLocalStorage('selectedOrg', 'marketName') ||
        !checkKeyInLocalStorage('selectedOrg', 'currency')
      ) {
        await dispatch(
          setCurrentOrg({
            marketName: setup.data.organization.marketName,
            name: setup.data.organization.isMarket ? null : setup.data.organization.name,
            id: setup.data.organization.id,
            isMarket: setup.data.organization.isMarket,
            marketId: setup.data.organization.marketSetupId,
            organizationLogo: setup.data.organization.isMarket
              ? null
              : setup.data.organization.logoOrganization,
            currency: setup.data.organization.currency,
            currencySymbol: setup.data.organization.currencySymbol,
          }),
        );
      }

      if (
        !localStorage.getItem('selectedOrg') ||
        !checkKeyInLocalStorage('selectedOrg', 'marketName') ||
        !checkKeyInLocalStorage('selectedOrg', 'currency')
      ) {
        await dispatch(
          setSelectedOrg({
            marketName: setup.data.organization.marketName,
            name: setup.data.organization.isMarket ? null : setup.data.organization.name,
            id: setup.data.organization.id,
            isMarket: setup.data.organization.isMarket,
            marketId: setup.data.organization.marketSetupId,
            organizationLogo: setup.data.organization.isMarket
              ? null
              : setup.data.organization.logoOrganization,
            currency: setup.data.organization.currency,
            currencySymbol: setup.data.organization.currencySymbol,
          }),
        );
      }
      await dispatch({
        type: 'ASSOCIATES_INFO',
        payload: setup.data,
      });

      await dispatch(setLanguage(setup.data.language));

      await dispatch({
        type: 'FETCH_CURRENCIES',
        payload: setup.data.currencies,
      });
    } catch (error) {
      if (error.response.status === 403) {
        await dispatch({
          type: 'UNAUTHORIZED',
        });
      }
      console.error(error);
    }
  };
};

export const setNotification = (noti) => {
  return {
    type: 'SET_NOTIFICATION',
    payload: noti,
  };
};
