import React from 'react';
import { InputBase } from '../inputs';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const InputText = ({
  name,
  placeholder,
  className,
  minLength = 3,
  maxLength = 100,
  icon,
  label,
  description = '',
  isRequired = false,
  onClick = () => {}
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });

  const validationRules = {
    minLength: {
      value: minLength,
      message: t('minLength'),
    },
    maxLength: {
      value: maxLength,
      message: t('maxLength'),
    },
  };

  if (isRequired) {
    validationRules.required = t('required');
  }

  return (
    <div className={`form-group ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        rules={validationRules}
        render={({ field }) => (
          <label onClick={() => onClick(!field.value)}>
            <div className='d-flex align-items-center cursor-pointer' >
              <InputBase
                type='checkbox'
                field={field}
                errors={errors}
                name={name}
                placeholder={placeholder}
                icon={icon}
                defaultChecked={field.value}
              />
              <span className='ml-2 fw-500'>{label}</span>
              {errors[name] && (
                <div className='d-flex align-items-center  error-content'>
                  <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                  <p className='text-danger p-0 m-0'>{errors[name].message}</p>
                </div>
              )}
            </div>
            <div style={{ marginLeft: '13px', marginTop: '-5px' }}>
              <span className='ml-2 dark-grey'>{description}</span>
            </div>
          </label>
        )}
      />
    </div>
  );
};

export default InputText;
