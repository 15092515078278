import React from 'react';
import './filterCard.css';
import { Money } from '../../uiComponents/common';

const FilterCard = ({
  category,
  quantity,
  onClick,
  isSelected,
  className = '',
  isMoney = false,
  minify = null,
  showQuantity = true,
}) => {
  const cardClasses = `m-1 filter-card ${isSelected ? 'filter-card-selected' : ''} ${className}`;

  return (
    <div className={cardClasses} onClick={onClick}>
      <div className=''>
        <div className='d-flex'>
          <h3 className='ctm-text fw-500 dark-grey mb-0'>{category}</h3>
        </div>

        {showQuantity ? (
          <div
            className={`ctm-text-medium font-weight-bold mt-1 ${
              isSelected ? 'text-primary-blue' : ''
            } mb-0`}
          >
            {isMoney ? (
              <Money value={quantity} minify={minify} />
            ) : (
              <p>{quantity ? quantity : 0}</p>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FilterCard;
