import { useContext } from 'react';
import { WelletContext } from '../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getCountries } from '../services';
import { defaultQueryOptionsFilter } from '../utils/defaultQueryOptions';
const useGetCountries = (params = null, options, propsArray = []) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptionsFilter,
    ...options,
  };

  return useQuery(
    ['countries-market', params, ...propsArray],
    () => getCountries(welletContext, params),
    queryOptions,
  );
};

export default useGetCountries;
