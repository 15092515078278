export const setQueryParams = (params, navigate, location) => {
  const searchParams = new URLSearchParams(location.search);

  for (const [key, value] of Object.entries(params)) {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  }

  const newSearch = searchParams.toString();
  const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
  navigate(newUrl, { state: location.state });
};

export const getQueryParam = (paramKey, location) => {
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(paramKey);
};
