import React from 'react';
import { InputBase } from '../inputs';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const InputText = ({
  name,
  placeholder,
  className,
  minLength = 3,
  maxLength = 100,
  border,
  icon,
  label,
  isRequired = false,
  isDisabled = false,
  useError = true,
  customValidation,
  hintText,
  copy,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const defaultValidationRule = {
    minLength: {
      value: minLength,
      message: t('minLength'),
    },
    maxLength: {
      value: maxLength,
      message: t('maxLength'),
    },
  };

  const validationRules = customValidation || defaultValidationRule;

  if (isRequired) {
    validationRules.required = t('required');
  }

  return (
    <div className={`form-group ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        rules={validationRules}
        render={({ field }) => (
          <div>
            <InputBase
              type='text'
              field={field}
              errors={errors}
              name={name}
              placeholder={placeholder}
              icon={icon}
              label={label}
              border={border}
              isDisabled={isDisabled}
              isRequired={isRequired}
              copy={copy}
            />
            {hintText ? hintText : null}
            {useError && errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default InputText;
