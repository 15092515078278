import React from 'react';
import MercadoPago from '../../icons/mercadoPago';
import CashIcon from '../../icons/cashIcon';
import Certificate from '../../icons/certificate';
import { Stripe, WireIcon, WireInterIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const WithDrawal = ({ withdrawal = '', withdrawalData, showOnlyText = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.withDrawal' });
  const renderIcon = () => {
    let icon = null;
    let text = null;
    let data = null;

    switch (withdrawal.toLocaleUpperCase()) {
      case 'MERCADOPAGO':
        icon = <MercadoPago size='30px' />;
        text = withdrawalData.name;
        data = withdrawalData.email;
        break;
      case 'CERTIFICATE':
        icon = <Certificate size='30px' />;
        text = t('certificate');
        break;
      case 'CASH':
        icon = <CashIcon size='30px' />;
        text = t('cash');
        break;
      case 'WIRE_INTERNATIONAL':
        icon = <WireInterIcon size='30px' />;
        text = t('wireInternational');
        break;
      case 'WIRE_MEXICO':
        icon = <WireIcon size='30px' />;
        text = t('wireMexico');
        break;
      case 'STRIPE':
        icon = <Stripe size='30px' />;
        text = 'Stripe';
        break;
      default:
        icon = null;
        text = '-';
        break;
    }

    return { icon, text, data };
  };

  const { icon, text, data } = renderIcon();

  return (
    <div className='d-flex align-items-center justify-content-start'>
      {showOnlyText ? (
        text
      ) : (
        <>
          <div className='img-table d-flex align-items-center rounded justify-content-center overflow-hidden'>
            {icon}
          </div>
          <div className='d-flex flex-column'>
            <div className='ml-2 d-block'>{text}</div>
            <div className='ml-2 d-block'>{data && data}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default WithDrawal;
