export default function ClockNormal({ size = '16px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group 835'>
        <g id='Vector'>
          <path
            d='M10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8078 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92894C15.6725 1.53041 13.8907 0.578006 11.9509 0.192153C10.0111 -0.1937 8.00043 0.00433435 6.17317 0.76121C4.3459 1.51809 2.78412 2.79981 1.6853 4.4443C0.586488 6.08879 -8.60215e-07 8.02219 -1.03312e-06 10C-1.26498e-06 12.6522 1.05357 15.1957 2.92893 17.0711C4.80429 18.9464 7.34783 20 10 20ZM10 1.81819C11.6182 1.81819 13.2001 2.29804 14.5456 3.19707C15.8911 4.0961 16.9397 5.37393 17.559 6.86896C18.1783 8.36399 18.3403 10.0091 18.0246 11.5962C17.7089 13.1833 16.9297 14.6412 15.7854 15.7854C14.6412 16.9297 13.1833 17.7089 11.5962 18.0246C10.0091 18.3403 8.36398 18.1783 6.86895 17.559C5.37392 16.9398 4.09609 15.8911 3.19706 14.5456C2.29804 13.2001 1.81818 11.6182 1.81818 10C1.81818 7.83005 2.68019 5.74897 4.21458 4.21458C5.74897 2.6802 7.83005 1.81819 10 1.81819Z'
            fill='#1B6EA8'
          />
          <path
            d='M10 1.81819C11.6182 1.81819 13.2001 2.29804 14.5456 3.19707C15.8911 4.0961 16.9397 5.37393 17.559 6.86896C18.1783 8.36399 18.3403 10.0091 18.0246 11.5962C17.7089 13.1833 16.9297 14.6412 15.7854 15.7854C14.6412 16.9297 13.1833 17.7089 11.5962 18.0246C10.0091 18.3403 8.36398 18.1783 6.86895 17.559C5.37392 16.9398 4.09609 15.8911 3.19706 14.5456C2.29804 13.2001 1.81818 11.6182 1.81818 10C1.81818 7.83005 2.68019 5.74897 4.21458 4.21458C5.74897 2.6802 7.83005 1.81819 10 1.81819Z'
            fill='white'
          />
        </g>
        <path
          id='Vector 18'
          d='M10 19C14.95 19 19 14.95 19 10L10 10L10 19Z'
          fill='#1B6EA8'
          stroke='#1B6EA8'
        />
        <path
          id='Vector 20'
          d='M10 10L10 1C4.6 1 1 5.05 1 10C1 14.95 5.05 19 10 19V10Z'
          fill='#1B6EA8'
          stroke='#1B6EA8'
        />
        <path
          id='Vector 22'
          d='M10 10L19 10C19 7.66372 17.9875 5.53982 16.3563 4L10 10Z'
          fill='#1B6EA8'
          stroke='#1B6EA8'
        />
      </g>
    </svg>
  );
}
