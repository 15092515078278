import React from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import World from 'fusioncharts/maps/fusioncharts.world';
import Maps from 'fusioncharts/fusioncharts.maps';
import Usa from 'fusioncharts/maps/fusioncharts.usa';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import Zoomline from 'fusioncharts/fusioncharts.zoomline';
import Zoomscatter from 'fusioncharts/fusioncharts.zoomscatter';
import Vml from 'fusioncharts/fusioncharts.vml';
import gantt from 'fusioncharts/fusioncharts.gantt';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Overlappedcolumn2d from 'fusioncharts/fusioncharts.overlappedcolumn2d';
import ReactFusioncharts from 'react-fusioncharts';

ReactFC.fcRoot(
  FusionCharts,
  Maps,
  World,
  Usa,
  Charts,
  gantt,
  Widgets,
  Vml,
  FusionTheme,
  Zoomline,
  Zoomscatter,
  Column2D,
  Overlappedcolumn2d,
);

const ColumnOverlapped = ({ data, width = '100%', height = '100%', palettecolors = '#675DFF', chartConfig }) => {
  // Creating the JSON object to store the chart configurations

  const dataSource = {
    chart: {
      //showValues: '0',
      theme: 'fusion',
      palettecolors: palettecolors,
      baseFont: '"Inter", sans-serif',
      labelFont: '"Inter", sans-serif',
      labelFontSize: '13px',
      baseFontSize: '13px',
      patternType: 'line',
      patternSize: 20,
      ...chartConfig
    },
    data: data,
  };

  return (
    <ReactFusioncharts
      type='column2d'
      width={width}
      height={height}
      dataFormat='JSON'
      dataSource={dataSource}
    />
  );
};

export default ColumnOverlapped;