import React from 'react';

const FormHeader = ({ title, subtitle, endComponent, border = false }) => {
  return (
    <div className={`d-flex flex-row justify-content-between  ${border && 'border-table-bottom pb-3'}`}>
      <div className=''>
        <div className='fw-600 fs-18'>{title}</div>
        <div className='dark-grey'>{subtitle}</div>
      </div>
      <div className='text-right'>{endComponent}</div>
    </div>
  );
};

export default FormHeader;
