import { Routes, Route } from 'react-router-dom';
import {
  Login,
  Sellers,
  Activity,
  NewActivity,
  EditActivity,
  Prices,
  ChangePassword,
  Performances,
  ReservationsHistory,
  ForgotPassword,
  SoldOut,
  Users,
  Category,
  OrganizationParentForm,
  Organizations,
  UserProfile,
  Venues,
  Schedules,
  ReservationDetails,
  PaymentBatch,
  BatchDetail,
  PaymentList,
  Commissions,
  CommissionDetails,
  Dashboard,
  ConciergePayment,
  Disputes,
  DisputeDetails,
  ReservationsPending,
  Unauthorized,
  ReservationsPendingApprove,
  Checkin,
  ReservationsPendingParent,
  WiresPaymentDetailContainer,
  Products,
  Payments,
  Settings,
  Billing,
  BillingSummary,
  Faq,
  Groups,
  CampaignSuggestion,
  SocialNetworks,
  Campaigns,
  CampaignDetail,
  Refunds,
  MonitorDetail,
  DisputesWellet,
  Refute,
  DisputeDetail,
  Notifications,
  Loyalty,
  Accounts,
  Configurations,
  UsagePolicies,
  Memberships,
  AccountDetail,
} from './screens';

import './assets/bootstrap.min.css';
import './assets/bootstrap_limitless.css';
import './assets/colors.css';
import './assets/components.css';
import './assets/layout.css';
import './assets/css/fonts-inter.css';
import './assets/global-styles.css';

// Custom Global Styles
import './assets/css/fonts.css';
import './assets/css/custom-colors.css';
import './assets/icons/icomoon/styles.min.css';
import './assets/icons/fontawesome/styles.min.css';

import { initializeApp } from 'firebase/app';
import AuthRoute from './routes/authRoute';
import Auth from './components/auth/Auth';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoginRoute from './routes/loginRoute';
import { Loading } from './uiComponents/common';
import OrganizationRoute from './routes/organizationRoute';
import {
  SocialNetworkForm,
  PerformanceForm,
  ProductForm,
  VenuesForm,
  CampaignForm,
} from './components';
import WelletOrganization from './routes/welletOrganization';
import RolesRoute from './routes/roleRoute';
import UnauthRoute from './routes/unauthRoute';
import ReservationsSchedule from './screens/reservations/reservationsSchedule';
import PaymentsActions from './screens/paymentBatch/paymentsActions';
import PaymentSummary from './screens/paymentBatch/paymentSummary';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchAppData, setPaymentMethods } from './actions';
import { paymentRoutesComponent } from './routes/paymentRoute';
import { useOrganizations } from './hooks';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE);

const renderRoutes = (withdrawalMethod, hasMarketingCampaign = false) => {
  return (
    <Routes>
      {/* App */}
      <Route element={<AuthRoute />}>
        {/* --------- Dashboard --------- */}
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'admin',
                'marketadmin',
                'dashboard',
                'bosssales',
                'salesteamlead',
              ]}
            />
          }
        >
          <Route path='/'>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path='/reports'>
            <Route index element={<Dashboard />} />
          </Route>
        </Route>
        <Route
          element={
            <RolesRoute
              roles={[
                // VER
                'superadministrator',
                'marketadmin',
                'wellet_finance',
                'finance',
              ]}
            />
          }
        >
          <Route path='/transactions' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<Payments />} />
            <Route path='/transactions/:id'>
              <Route index element={<MonitorDetail />} />
            </Route>
            <Route path='/transactions/reservation/:id' element={<ReservationDetails />} />
          </Route>
        </Route>
        <Route
          element={
            <RolesRoute
              roles={[
                // VER
                'superadministrator',
                'marketadmin',
                'wellet_finance',
                'finance',
              ]}
            />
          }
        >
          <Route path='/refunds' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<Refunds />} />
            <Route path='/refunds/:id' element={<MonitorDetail />} />
            <Route path='/refunds/reservation/:id' element={<ReservationDetails />} />
          </Route>
        </Route>
        <Route
          element={
            <RolesRoute
              roles={[
                // VER
                'superadministrator',
                'wellet_finance',
                'marketadmin',
                'finance',
              ]}
            />
          }
        >
          <Route path='/disputes' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<DisputesWellet />} />
            <Route path='/disputes/:paymentId' element={<DisputeDetail />} />
            <Route path='/disputes/refute/:id' element={<WelletOrganization onlyMarket={true} />}>
              <Route index element={<Refute />} />
            </Route>
            <Route path='/disputes/reservation/:id' element={<ReservationDetails />} />
          </Route>
        </Route>
        <Route
          element={
            <RolesRoute
              roles={[
                // VER
                'superadministrator',
                'marketadmin',
                'wellet_finance',
                'finance',
              ]}
            />
          }
        >
          <Route path='/accounts' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<Accounts />} />
            <Route path=':id' element={<AccountDetail />} />
            <Route path='reservation/:id' element={<ReservationDetails />} />
            <Route path='transactions/:id' element={<MonitorDetail />} />
            <Route path='refunds/:id' element={<MonitorDetail />} />
            <Route path='disputes/:paymentId' element={<DisputeDetail />} />
          </Route>
        </Route>
        {/* --------- Reservas --------- */}
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'admin',
                'marketadmin',
                'reservationcenter',
                'salesteamlead',
                'bosssales',
                'callcenterlead',
                'finance',
                'auditor',
                'auditor-onlyview',
                'reservationcenter-onlyview',
                //'soldout',
              ]}
            />
          }
        >
          <Route path='/reservation-history'>
            <Route index element={<ReservationsHistory />} />
            {/* <Route path='/reservation-history/:id' element={<ReservationDetailsNew />} /> */}
            <Route path='/reservation-history/:id' element={<ReservationDetails />} />
          </Route>
        </Route>
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'admin',
                'marketadmin',
                'reservationcenter',
                'reservationcenter-onlyview',
                'callcenterlead',
              ]}
            />
          }
        >
          <Route path='/reservations-pending'>
            <Route index element={<ReservationsPending />} />
            <Route path='/reservations-pending/:id' element={<ReservationsPendingParent />} />
          </Route>
        </Route>
        <Route
          path='/reservations-pending-approve'
          element={<WelletOrganization onlyMarket={true} />}
        >
          <Route index element={<ReservationsPendingApprove />} />
          <Route
            path='/reservations-pending-approve/reservations-detail/:id'
            element={<ReservationDetails />}
          />
        </Route>
        {/* ---------- Sold Out ---------- */}
        <Route
          element={
            <RolesRoute
              roles={['superadministrator', 'marketadmin', 'soldout', 'callcenterlead']}
            />
          }
        >
          <Route path='/sold-out' element={<SoldOut />} />
        </Route>
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'marketadmin',
                'admin',
                'productloader',
                'productpriceloader',
                'group',
              ]}
              title={'groups'}
            />
          }
        >
          <Route path='/groups' element={<Groups />} />
          <Route path='/new-group' element={<ProductForm mode={'groups'} />} />
          <Route path='/edit-group/:id' element={<ProductForm mode={'groups'} />} />
        </Route>
        {/* --------- Productos ----------- */}
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'marketadmin',
                'admin',
                'productloader',
                'productpriceloader',
                'restaurantvip',
              ]}
              title={'areas'}
            />
          }
        >
          <Route path='/areas' element={<Products />} />
          <Route path='/new-areas' element={<ProductForm mode={'areas'} />} />
          <Route path='/edit-areas/:id' element={<ProductForm mode={'areas'} />} />
        </Route>
        <Route element={<RolesRoute roles={['superadministrator', 'marketadmin', 'checker']} />}>
          <Route path='/check-ins' element={<Checkin />} />
        </Route>
        {/* --------- Billings --------- */}
        <Route
          element={<RolesRoute roles={['superadministrator', 'admin', 'marketadmin', 'finance']} />}
        >
          <Route path='/billing' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<Billing />} />
            <Route path='/billing/summary/:id' element={<BillingSummary />} />
          </Route>
        </Route>
        {/* --------- Central de Pagos (Lotes de Pagos) --------- */}
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'admin',
                'marketadmin',
                'comercial',
                'finance',
                'auditor',
                'auditor-onlyview',
              ]}
            />
          }
        >
          <Route path='/paymentList' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<PaymentList />} />
            <Route path='/paymentList/:id' element={<BatchDetail />} />
            <Route path='/paymentList/:id/:sellerId' element={<ConciergePayment />} />
          </Route>

          <Route path='/payments' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<PaymentsActions />} />
            <Route path='/payments/:id' element={<BatchDetail />} />
          </Route>
          <Route path='/payment-summary/:id' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<PaymentSummary />} />
          </Route>
          {paymentRoutesComponent(withdrawalMethod)}
          <Route path='/payment-details/:id' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<WiresPaymentDetailContainer />} />
          </Route>
        </Route>
        {/* --------- Aprobacion de Reservas de Pagos --------- */}
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'admin',
                'marketadmin',
                'finance',
                'auditor',
                'auditor-onlyview',
              ]}
            />
          }
        >
          <Route path='/conciergeCommissions' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<PaymentBatch />} />
            <Route path='/conciergeCommissions/:id/:sellerId' element={<ConciergePayment />} />
          </Route>
          <Route path='/payments' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<PaymentsActions />} />
            <Route path='/payments/:id' element={<BatchDetail />} />
          </Route>
        </Route>
        {/* --------- Central de Pagos (Comisiones y Disputas) --------- */}
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'admin',
                'marketadmin',
                'comercial',
                'finance',
                'auditor',
                'auditor-onlyview',
                'salesteamlead',
                'bosssales',
              ]}
            />
          }
        >
          <Route path='/under-review' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<Disputes />} />
            <Route path='/under-review/:id' element={<DisputeDetails />} />
          </Route>
          <Route path='/commissions' element={<Commissions />} />
          <Route path='/commissions-details/:id' element={<CommissionDetails />} />
        </Route>
        {/* --------- Afiliados --------- */}
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'marketadmin',
                'affiliatecenter',
                'affiliatecenter-onlyview',
                'bosssales',
                'salesteamlead',
              ]}
            />
          }
        >
          <Route element={<WelletOrganization onlyMarket={true} />}>
            <Route path='/sellers' element={<Sellers />} />
          </Route>
          <Route path='/sellers/:orgId/:id/profile' element={<UserProfile />} />
        </Route>
        {/* --------- Settings (ONLY SUPERADMIN) --------- */}
        <Route element={<RolesRoute roles={['superadministrator', 'marketadmin']} />}>
          {/* -------- ONLY MARKETS --------*/}
          <Route element={<RolesRoute roles={['superadministrator']} />}>
            <Route element={<WelletOrganization onlyMarket={true} />}>
              <Route path='/organizations' element={<Organizations />} />
              <Route path='/edit-organization/:orgId' element={<OrganizationParentForm />} />
              <Route path='/new-organization' element={<OrganizationParentForm />} />
            </Route>
          </Route>

          {/* --------- FORMS --------- */}
          <Route element={<RolesRoute roles={['superadministrator', 'marketadmin']} />}>
            {process.env.REACT_APP_ENVIRONMENT === 'production' ? null : (
              <Route element={<WelletOrganization onlyMarket={true} />}>
                <Route path='/socialnetworks' element={<SocialNetworks />} />
                <Route path='/socialnetworks/new-social' element={<SocialNetworkForm />} />
                <Route path='/socialnetworks/edit-social/:id' element={<SocialNetworkForm />} />
                <Route path='/socialnetworks/suggestions/:id' element={<CampaignSuggestion />} />
              </Route>
            )}
          </Route>
          <Route element={<OrganizationRoute checkSelectedOrg={false} />}>
            <Route element={<RolesRoute roles={['superadministrator', 'marketadmin']} />}>
              <Route path='/restaurantes'>
                <Route index element={<Activity />} />
                <Route path='/restaurantes/nuevo-restaurante' element={<NewActivity />} />
                <Route path='/restaurantes/editar-restaurante/:id' element={<EditActivity />} />
                <Route path='/restaurantes/schedule/:id' element={<Schedules />} />
              </Route>
            </Route>
            <Route element={<RolesRoute roles={['superadministrator']} />}>
              <Route path='/prices'>
                <Route index element={<Prices />} />
                <Route path='/prices/:id' element={<Prices />} />
              </Route>
              <Route path='/performances' element={<Performances />} />
              <Route path='/venues' element={<Venues />} />
              <Route path='/venues/new-venue' element={<VenuesForm />} />
              <Route path='/venues/edit-venue/:id' element={<VenuesForm />} />
              <Route path='/new-performance' element={<PerformanceForm />} />
              <Route path='/edit-performance/:id' element={<PerformanceForm />} />
            </Route>
          </Route>
        </Route>
        {/* --------- Campaigns --------- */}
        {hasMarketingCampaign ? (
          <Route
            element={
              <RolesRoute
                roles={[
                  // VER
                  'superadministrator',
                  'marketadmin',
                  'marketing',
                ]}
              />
            }
          >
            {/*  Campaigns Form  */}
            <Route path='/campaigns-list'>
              <Route index element={<Campaigns />} />
            </Route>
            <Route path='/new-campaign'>
              <Route index element={<CampaignForm />} />
            </Route>
            <Route path='/edit-campaign/:id'>
              <Route index element={<CampaignForm />} />
            </Route>
            <Route path='/campaign/:id'>
              <Route index element={<CampaignDetail />} />
            </Route>
          </Route>
        ) : null}
        {/* --------- Profile --------- */}
        <Route path='/profile' element={<UserProfile />} />
        <Route path='/notifications' element={<Notifications />} />

        {/* Configurations */}
        <Route element={<RolesRoute roles={['superadministrator', 'admin', 'marketadmin']} />}>
          <Route
            path='/settings'
            // element={<ValidationRoute canEntity={true} canWellet={true} canAccount={true} />}
          >
            <Route index element={<Configurations />} />
            <Route element={<RolesRoute roles={['superadministrator', 'marketadmin']} />}>
              <Route path='team-and-security' element={<Users />} />
              <Route path='team-and-security/edit/:id' element={<UserProfile />} />
              <Route path='team-and-security/new-user' element={<UserProfile />} />
              <Route path='usage-policies' element={<UsagePolicies />} />
              <Route path='memberships' element={<Memberships />} />
              <Route path='callcenter/:id' element={<WelletOrganization onlyMarket={true} />}>
                <Route index element={<ReservationsSchedule />} />
              </Route>
  
              {process.env.REACT_APP_ENVIRONMENT === 'production' ? null : (
                <Route element={<WelletOrganization onlyMarket={true} />}>
                  <Route path='categories'>
                    <Route index element={<Category />} />
                  </Route>
                </Route>
              )}
              <Route path='faq' element={<WelletOrganization onlyMarket={true} />}>
                <Route index element={<Faq />} />
              </Route>
            </Route>
            <Route element={<RolesRoute roles={['superadministrator']} />}>
              <Route path='setting' element={<WelletOrganization onlyMarket={true} />}>
                <Route index element={<Settings />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<RolesRoute roles={['superadministrator', 'marketadmin']} />}>
          <Route path='/loyalty' element={<WelletOrganization onlyMarket={true} />}>
            <Route index element={<Loyalty />} />
          </Route>
        </Route>
      </Route>

      <Route element={<AuthRoute overflow={false} />}>
        <Route
          element={
            <RolesRoute
              roles={[
                'superadministrator',
                'admin',
                'marketadmin',
                'comercial',
                'finance',
                'auditor',
                'auditor-onlyview',
              ]}
            />
          }
        >
          <Route element={<WelletOrganization onlyMarket={true} />}>
            <Route path='/conciergeCommissions/:id' element={<BatchDetail />} />
          </Route>
        </Route>
      </Route>

      {/* --------- Login / Signup --------- */}
      <Route element={<LoginRoute />}>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
      </Route>
      <Route element={<UnauthRoute />}>
        <Route path='/error-page' element={<Unauthorized />} />
      </Route>
      <Route path='/change-password' element={<ChangePassword />} />
    </Routes>
  );
};

function App() {
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const { i18n } = useTranslation();
  const firebaseApp = initializeApp(firebaseConfig);
  const tenantId = useSelector((state) => state.app.tenantId);
  const { titleWeb, favIconUrl, hasMarketingCampaign, entityId } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const authenticated = user.isAuthenticated;
  const authChecked = user.authChecked;
  const paymentMethods = useSelector((state) => state.app.paymentMethods);

  // (4-15-16) To Do: Este use effect genera 1 re-render de toda la app
  useEffect(() => {
    if (tenantId) {
      // get app data based on tenant
      dispatch(fetchAppData(tenantId));
    }
  }, [tenantId, dispatch]);

  useEffect(() => {
    // Actualizar el título de la página
    // if (titleWeb) {
    //   document.title = titleWeb;
    // }
    // Actualizar el favicon
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = favIconUrl;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = favIconUrl;
      document.head.appendChild(newLink);
    }
  }, [favIconUrl, titleWeb]);

  useEffect(() => {
    if (user.user?.language !== null) i18n.changeLanguage(user.user?.language);
  }, [user.user?.language]);

  const organizationsQuery = useOrganizations(user?.user?.sid, {
    enabled: authenticated && authChecked && user.users?.language && user?.user?.sid,
    refetchOnMount: false,
    onSuccess: (data) => {
      dispatch(setPaymentMethods(data?.withdrawalMethods));
    },
  });

  return (
    <div className='App'>
      <Suspense fallback={<Loading />}>
        {(!authenticated ||
          (authenticated && user.user?.language) ||
          user.roles === 'unauthorized') &&
        authChecked &&
        !organizationsQuery.isLoading ? (
          renderRoutes(paymentMethods, hasMarketingCampaign || entityId === 1)
        ) : (
          <Loading />
        )}
        <Auth key='auth' />
      </Suspense>
    </div>
  );
}

export default App;
