import React, { useContext, useState } from 'react';
import styles from './notificationMenu.module.css';
import FilterCard from '../filterCard/filterCard';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetNotifications } from '../../hooks/queries/useGetNotifications';
import Show from '../show/show';
import { Button, Loading } from '../../uiComponents/common';
import { formatDateTranslation } from '../../helpers/dates';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { postNotification } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { setCurrentOrg, setNotification, setSelectedOrg } from '../../actions';
import { useDispatch } from 'react-redux';
import { notificationRoutes } from '../../helpers/notifications';
import { hasAllowedRoles } from '../../helpers/roles';
import { useUser } from '../../hooks';

const NotificationMenu = ({ handleClose, open }) => {
  const { t } = useTranslation('translation', { keyPrefix: `components.notificationsMenu` });
  const { language, selectedOrganization } = useSelector((state) => state.app);
  const { hasNotification } = useSelector((state) => state.user.user);
  const [filter, setFilter] = useState('Unread');
  const welletContext = useContext(WelletContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSetFilter = (filter) => setFilter(filter);
  const queryClient = useQueryClient();
  const userId = useSelector((state) => state.user.user.sid);

  const notificationQuery = useGetNotifications(
    {
      enabled: open,
      onSuccess: (data) => {
        const allRead = data?.data?.items?.every((notification) => notification.isViewed);
        if (allRead && hasNotification) {
          dispatch(setNotification(false));
        }
      },
    },
    { limit: null, skip: null, filter, sortBy: 'DESC', columnFilter: 'createDateUTC' },
  );

  const readMutation = useMutation({
    mutationFn: (id) => {
      return postNotification(welletContext, id);
    },
    mutationKey: ['read'],

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', null, null, filter, 'DESC', 'createDateUTC'],
      });
      if (variables.length === 1) {
        handleClose();
      }
    },
  });

  const unreadNotificationIds = notificationQuery?.data?.data?.items
    ?.filter((notification) => !notification.isViewed)
    .map((notification) => notification.id);

  const handleClickNotification = (n) => {
    const route = notificationRoutes[n.type];
    if (!n.isViewed) {
      readMutation.mutate([n.id]);
    }

    if (route) {
      if (
        n.type === 'APPLICATION_DISPUTE' ||
        n.type === 'REFUTE_PAYMENT_DISPUTED' ||
        n.type === 'NEW_USER' ||
        n.type === 'APPLICATION_REFUND' 
      ) {
        const marketsData = queryClient.getQueryData(['organizations', userId])?.markets;
        const market = marketsData?.find((m) => m.organizationId === n.marketSetupId);
        if (market && (selectedOrganization.marketId !== n.marketSetupId || selectedOrganization.id !== n.marketSetupId)) {
          const reduxOrgData = {
            ...selectedOrganization,
            name: null,
            id: market.organizationId,
            isMarket: market.isMarket,
            marketId: market.organizationId,
            marketName: market.marketName,
          };

          dispatch(setSelectedOrg(reduxOrgData));
          dispatch(setCurrentOrg(reduxOrgData));
        }

        navigate(route(n.refId, n.marketSetupId));
      } else {
        navigate(route(n.refId, n.marketSetupId));
      }
    }

    if (n.isViewed) {
      handleClose();
    }
  };

  return (
    <div className=''>
      <div className='d-flex justify-content-between align-items-center pt-2 pr-1'>
        <div className='fw-600 fs-sm p-2 ml-1'>{t('title')}</div>
        {hasNotification && unreadNotificationIds?.length ? (
          <Button
            text={t('button', { number: unreadNotificationIds?.length })}
            className={'mr-2 btn-general btn-wellet-primary'}
            size={'small'}
            onClick={() => readMutation.mutate(unreadNotificationIds)}
            type='button'
          />
        ) : null}
      </div>
      <Show>
        <Show.When isTrue={notificationQuery.isFetching}>
          <div className='py-3'>
            <Loading global={false} />
          </div>
        </Show.When>
        <Show.Else>
          <div className='d-flex w-100 px-2'>
            <FilterCard
              className='m-0'
              onClick={() => handleSetFilter('Unread')}
              showQuantity={false}
              category={t('cardFilter.unread')}
              isSelected={filter === 'Unread'}
            />
            <FilterCard
              className='m-0 flex-grow-1'
              onClick={() => handleSetFilter('All')}
              showQuantity={false}
              category={t('cardFilter.all')}
              isSelected={filter === 'All'}
            />
          </div>

          {notificationQuery?.data?.data?.items?.length > 0 ? (
            <>
              <div className={styles.menuItemContainer}>
                {notificationQuery?.data?.data?.items?.map((n) => (
                  <React.Fragment key={n.id}>
                    <div
                      className={`${styles.notificationMenuItem}`}
                      onClick={() => handleClickNotification(n)}
                    >
                      <div className='d-flex flex-column'>
                        <div>
                          {!n.isViewed ? (
                            <div className={`${styles.badgeNotification} d-inline-flex `}></div>
                          ) : null}
                          <span className='fw-500 '>{n.body}</span>
                        </div>
                        <div className='dark-grey fs-2xs mt-1'>
                          {formatDateTranslation(n?.createDate, language, 'extraSmall').date}
                        </div>
                      </div>
                    </div>
                    <div className={styles.dividerContainer}>
                      <div className={styles.dividerLine}></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div className={`d-flex justify-content-between ${styles.paginationNotification}`}>
                <div className='dark-grey fs-2xs'>
                  {t('pagination.text', {
                    current: notificationQuery?.data?.data?.totalRegisters,
                    status: filter === 'Unread' ? t('unread') : t('all'),
                  })}
                </div>
                <div className='dark-grey fs-2xs'>
                  {' '}
                  <Link to={'/notifications'} onClick={handleClose}>
                    {t('pagination.link')}
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <div className='text-center py-3 fs-2xs fw-500'>
              {t('info', { infoText: filter === 'Unread' ? t('withoutRead') : t('infoRead') })}
            </div>
          )}
        </Show.Else>
      </Show>
    </div>
  );
};

export default NotificationMenu;
