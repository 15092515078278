import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputNumber,
  InputText,
  Select,
  Subtitle,
  TextEditor,
  TextAlert,
  Toast,
  Loading,
  IconButton,
  ButtonGallery,
  FormButton,
  InputCheckbox,
  InputColor,
  Switcher,
} from '../../../uiComponents/common';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from 'react-query';
import { FwEdit, FwTrashIcon, CloudIcon, ChevronIcon } from '../../../uiComponents/icons';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import {
  getAdvantageNetwork,
  getCategoriesSimple,
  getProductById,
  getSpecialType,
  getSubcategoriesSimple,
  postProduct,
  putProduct,
} from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateBreadCrumbs, useGetRestaurants, useUser } from '../../../hooks';
import { Gallery } from '../gallery';
import ProductsSoldout from '../../../screens/productsSoldout/productsSoldout';
import ProductWeekdays from '../../../data/productsWeekdays.json';
import FormRow from '../formRow';
import { useTranslation } from 'react-i18next';
import { hasAllowedRoles } from '../../../helpers/roles';
import Show from '../../show/show';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import { useGetSalesCategory } from '../../../hooks/queries/useGetSalesCategory';
import useGenericTranslation from '../../../hooks/useGenericTranslation';

const ProductsForm = ({ mode }) => {
  const { id } = useParams();
  const methods = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'forms.products' });
  const { t: translate } = useTranslation('translation', {
    keyPrefix: 'components.products.getSpecialType',
  });
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const { getClientTranslation } = useGenericTranslation();
  const [open, setOpen] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isRedirect, setIsRedirect] = useState(null);
  const { roles } = useUser();

  useCreateBreadCrumbs([
    { name: t(`${mode}.breadcrumbs.prev`), path: `/${mode}` },
    { name: !!id ? t(`${mode}.breadcrumbs.current.edit`) : t(`${mode}.breadcrumbs.current.new`) },
  ]);

  const productQuery = useQuery({
    queryKey: ['get-product', id],
    queryFn: () => getProductById(welletContext, id),
    onSuccess: (data) => {
      const formattedData = {
        extraPaxs: data?.data?.extraPax,
        paxs: data?.data?.pax,
        subcategory: data?.data?.subCategory?.name
          ? { label: data?.data?.subCategory?.name, value: data?.data?.subCategory?.id }
          : null,
        category: data?.data?.subCategory?.category?.name
          ? {
              label: data?.data?.subCategory?.category?.name,
              value: data?.data?.subCategory?.category?.id,
            }
          : null,
        isActive: data?.data?.isActive,
        image: data?.data?.image
          ? { base64: data?.data?.image, name: data?.data?.image?.split('/')[3] ?? '' }
          : null,
        locationCode: data?.data?.locationCode,
        hexColor: data?.data?.hexColor,
        specialType: data?.data?.specialType
          ? { label: translate(data?.data?.specialType), value: data?.data?.specialType }
          : null,
        isSpecial: data?.data?.specialType !== null ? true : false,
        isExclusive: data?.data?.isExclusive ? true : false,
        usersIds: data?.data?.productUser
          ? data?.data?.productUser?.map((u) => ({ label: u.name, value: u.id }))
          : [],
        salesCategories: data?.data?.productLoyalty
          ? data?.data?.productLoyalty?.map((u) => ({ label: u.name, value: u.id }))
          : [],
        minQuantityAmount: data?.data?.minQuantity ? data?.data?.minQuantity : 1,
      };

      data?.data?.langProducts.forEach((langShow) => {
        const lang = langShow.language;
        formattedData[`name_${lang}`] = langShow.name;
        formattedData[`description_${lang}`] = langShow.description;
        formattedData[`cancellations_${lang}`] = langShow.changesCancellations;
      });

      let productPrices = data?.data?.productPrices ? data?.data?.productPrices : ProductWeekdays;
      productPrices.forEach((week) => {
        formattedData[`price_${week.weekDay}`] = week.price;
        formattedData[`priceExtraPax_${week.weekDay}`] = week.priceExtraPax;
        formattedData[`consumption_${week.weekDay}`] = week.consumption;
        formattedData[`depositPercentage_${week.weekDay}`] = week.depositPercentage;
        formattedData[`tipPercentage_${week.weekDay}`] = week.tipPercentage;
        formattedData[`taxPercentage_${week.weekDay}`] = week.taxPercentage;
        formattedData[`isActive_${week.weekDay}`] = week.isActive;
        formattedData[`consumptionPerExtraPax_${week.weekDay}`] = week.consumptionPerExtraPax;
        formattedData[`stock_${week.weekDay}`] = week.stock;
      });

      setImages(data?.data?.galery);
      methods.reset(formattedData);
    },
    enabled: !!id,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const postProductMutation = useMutation({
    mutationFn: (body) => {
      if (!!id) {
        return putProduct(welletContext, body);
      }
      return postProduct(welletContext, body);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const clearForm = () => {
    navigate(`/${mode}`);
  };

  const validateFields = {
    name: {
      rule: (values) => {
        if (!values.name) {
          return true;
        } else if (values.name && values.name.trim() !== '') {
          if (values.name.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: t('errorMsg'),
    },
  };
  const restaurantesQuery = useGetRestaurants(organization, {
    keepPreviousData: true,
  });
  const categoriesQuery = useQuery({
    queryKey: ['categories-simple', organization.id],
    queryFn: () => getCategoriesSimple(welletContext),
    onSuccess: () => {
      if (isFirstRender) {
        return methods.setValue('subcategory', '');
      }
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const subcategoriesQuery = useQuery({
    queryKey: ['subcategories-simple', organization.id, methods?.watch('category')?.value],
    queryFn: () => getSubcategoriesSimple(welletContext, methods?.watch('category')?.value),
    refetchInterval: false,
    onSuccess: () => {
      if (isFirstRender) {
        return methods.setValue('subcategory', '');
      }
      setIsFirstRender(true);
    },
    refetchOnWindowFocus: false,
    enabled: !!methods?.watch('category')?.value,
  });
  const specialTypeQuery = useQuery({
    queryKey: ['specialType-simple', organization.id],
    queryFn: () => getSpecialType(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const handleRemoveImg = (idx) => {
    const imagesCopy = images.filter((i, index) => index !== idx);
    setImages(imagesCopy);
  };
  const handleEditImg = (i, idx) => {
    setSelectedImage({
      data: i,
      id: idx,
    });
    setOpen(true);
  };

  const { lngErrors, isLoading } = useLanguageSwitcher(validateFields, methods);

  const formatedRestaurant = restaurantesQuery?.data?.data?.map((c) => ({
    label: c.name,
    value: c.id,
  }));
  const formatedCategory = categoriesQuery?.data?.data?.map((c) => ({
    label: c.name,
    value: c.id,
  }));
  const formatedSubCategory = subcategoriesQuery?.data?.data?.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  const formatedSpecialType = specialTypeQuery?.data?.data
    ?.map((c) => ({
      label: translate(c),
      value: c,
    }))
    ?.filter((c) => c.value !== 'EXTRAPRODUCT');

  const getMode = () => {
    switch (mode) {
      case 'areas':
        return 'area';
      case 'groups':
        return 'group';
      default:
        return mode;
    }
  };

  // Functions
  const onSubmit = (data) => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const initialLangShows = [{ shortForm: 'es' }, { shortForm: 'en' }].map((lng) => {
      return {
        language: lng.shortForm,
        name: data[`name_${lng.shortForm}`],
        description: data[`description_${lng.shortForm}`]
          ? data[`description_${lng.shortForm}`]
          : null
      };
    });
    const handelLangShowsEmptys = removeObjectsWithEmptyFields(initialLangShows, ['name']);

    if (Object.keys(lngErrors).length === 0) {
      const body = {
        id: !!id ? id : null,
        organizationId: organization.isMarket ? null : organization.id,
        showId: !!id
          ? productQuery?.data?.data?.restaurant?.id
          : organization.isMarket
          ? data?.restaurant?.value
          : null,
        name: handelLangShowsEmptys.some((s) => s.language === 'es')
          ? handelLangShowsEmptys.find((s) => s.language === 'es')?.name
          : handelLangShowsEmptys.find((l) => !!l.name).name,
        langProducts: handelLangShowsEmptys,
        subCategoryId: data.subcategory?.value ? data.subcategory?.value : null,
        isActive: !!id ? (data.isActive ? data.isActive : false) : true,
        isExclusive: data.isExclusive ? data.isExclusive : false,
        image: data?.image?.base64 ? data?.image?.base64 : null,
        userIds: data?.usersIds ? data?.usersIds?.map((c) => c.value) : null,
        salesCategories: data?.salesCategories ? data?.salesCategories?.map((c) => c.value) : null,
        galery: images,
        reservationType: getMode(),
        minQuantity: data?.minQuantityAmount !== '' ? data?.minQuantityAmount : 1,
      };

      const commonProductPrices = (day) => ({
        weekDay: day,
        price: data[`price_${day}`],
        tipPercentage: data[`tipPercentage_${day}`],
        taxPercentage: data[`taxPercentage_${day}`],
        depositPercentage: data[`depositPercentage_${day}`],
        isActive: data[`isActive_${day}`],
      });

      if (mode === 'areas') {
        body['locationCode'] = data?.locationCode;
        body['hexColor'] = data?.hexColor;
        body['pax'] = data.paxs;
        body['extraPax'] = data.extraPaxs ? data.extraPaxs : 0;
        body['productPrices'] = days.map((day) => ({
          ...commonProductPrices(day),
          priceExtraPax: data[`priceExtraPax_${day}`],
          consumption: data[`consumption_${day}`],
          stock: data[`stock_${day}`],
          consumptionPerExtraPax: data[`consumptionPerExtraPax_${day}`],
        }));
      } else {
        body['pax'] = null;
        body['specialType'] =
          data?.specialType?.value && data?.isSpecial ? data?.specialType?.value : null;
        body['productPrices'] = days.map((day) => ({
          ...commonProductPrices(day),
          stock: null,
        }));
      }

      postProductMutation.mutate(body);
    }
  };
  const handleToastCallback = () => {
    if (isRedirect && !id) {
      navigate(`/${mode}`);
    }
    postProductMutation.reset();
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
  };

  useEffect(() => {
    let formattedData = {};
    if (!id) {
      let productPrices = ProductWeekdays;
      productPrices.forEach((week) => {
        formattedData[`price_${week.weekDay}`] = week.price;
        formattedData[`priceExtraPax_${week.weekDay}`] = week.priceExtraPax;
        formattedData[`consumption_${week.weekDay}`] = week.consumption;
        formattedData[`depositPercentage_${week.weekDay}`] = week.depositPercentage;
        formattedData[`tipPercentage_${week.weekDay}`] = week.tipPercentage;
        formattedData[`taxPercentage_${week.weekDay}`] = week.taxPercentage;
        formattedData[`isActive_${week.weekDay}`] = week.isActive;
        formattedData[`stock_${week.weekDay}`] = mode === 'groups' ? null : week.stock;
        formattedData[`consumptionPerExtraPax_${week.weekDay}`] = week.consumptionPerExtraPax;
      });
      methods.reset(formattedData);
    }
  }, []);

  const productSpecial = methods.watch('isSpecial');

  const saleCategorysQuery = useGetSalesCategory(organization.marketId);
  const sellersQuery = useQuery({
    queryKey: ['sellers-areas-group', organization.marketId],
    queryFn: () => getAdvantageNetwork(welletContext, organization.marketId),
    enabled: isOpen,
  });

  const salesCategoryFormatted = saleCategorysQuery?.data?.data?.map((c) => ({
    label: c.name,
    value: c.id,
  }));
  const sellersQueryFormatted = sellersQuery?.data?.data?.items?.map((c) => ({
    label: c.name,
    value: c.sid,
  }));

  if (
    isLoading ||
    productQuery.isFetching ||
    saleCategorysQuery.isLoading ||
    categoriesQuery.isLoading ||
    (!isFirstRender && subcategoriesQuery.isLoading && !!id)
  ) {
    return <Loading />;
  }

  return (
    <>
      {postProductMutation.isError && (
        <Toast
          message={t('toastError')}
          type='ERROR'
          onCallback={() => postProductMutation.reset()}
        />
      )}
      {postProductMutation.isSuccess && (
        <Toast
          message={!!id ? t(`${mode}.toastSuccess.edit`) : t(`${mode}.toastSuccess.new`)}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      <FormProvider {...methods}>
        <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
          <Subtitle
            text={<h3>{t(`${mode}.title`)}</h3>}
            endComponent={
              <FormButton
                mutation={postProductMutation}
                handleRedirect={handleRedirect}
                clearForm={clearForm}
                all={false}
                nextStep={true}
                isDisabled={
                  postProductMutation.isSuccess ||
                  postProductMutation.isLoading ||
                  postProductMutation.isError
                }
              />
            }
          />
          {Object.keys(lngErrors).length !== 0 && (
            <TextAlert text={lngErrors.name} type={'danger'} />
          )}
          <FormRow
            text={t('sectionName.title')}
            endComponent={
              <div className='col-6 pr-0'>
                <div className='row'>
                  <div className='col-12 pr-0 mb-2 d-flex'>
                    <div
                      className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                    ></div>
                    <InputText
                      name={'name_es'}
                      label={''}
                      className={'mb-0 w-100'}
                      placeholder={t('sectionName.inputName.placeholder')}
                      isRequired={true}
                      maxLength={510}
                    />
                  </div>
                  <div className='col-12 pr-0 d-flex'>
                    <div
                      className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                    ></div>
                    <InputText
                      name={'name_en'}
                      label={''}
                      className={'mb-0 w-100'}
                      placeholder={t('sectionName.inputName.placeholder')}
                      isRequired={true}
                      maxLength={510}
                    />
                  </div>
                </div>
              </div>
            }
          />

          {!id ? (
            <FormRow
              text={getClientTranslation('title.singular')}
              endComponent={
                <div className='col-6 pr-0'>
                  <div className='row'>
                    <Select
                      options={formatedRestaurant}
                      label={getClientTranslation('title.singular')}
                      placeholder={t('sectionRestaurante.inputRestaurante.placeholder')}
                      className={'mb-3 col-12 pr-0'}
                      name={'restaurant'}
                      isRequired={true}
                    />
                  </div>
                </div>
              }
            />
          ) : null}

          <FormRow
            text={t('sectionClassification.title')}
            endComponent={
              <div className='col-6 pr-0'>
                <div className='row'>
                  <Select
                    options={formatedCategory}
                    label={t('sectionClassification.inputCategorie.label')}
                    placeholder={t('sectionClassification.inputCategorie.placeholder')}
                    className={'mb-3 col-12 pr-0'}
                    name={'category'}
                    isRequired={true}
                  />
                  <Select
                    options={formatedSubCategory}
                    label={t('sectionClassification.inputSubcategoríe.label')}
                    placeholder={t('sectionClassification.inputSubcategoríe.placeholder')}
                    className={'mb-2 col-12 pr-0'}
                    name={'subcategory'}
                    isRequired={true}
                  />
                </div>
              </div>
            }
          />

          <FormRow
            text={t('sectionDescription.title')}
            description={t(`sectionDescription.inputDescription.${mode}.label`)}
            endComponent={
              <div className='col-6 pr-0'>
                <div className='row'>
                  <div className='col-12 d-flex pr-0'>
                    <div
                      className={`language-selector-flag language-selector-flag-es mt-1 p-0`}
                    ></div>
                    <TextEditor
                      className={'w-100'}
                      name={'description_es'}
                      options={['inline', 'link', 'list', 'remove']}
                      customErrors={lngErrors}
                    />
                  </div>
                  <div className='col-12 d-flex pr-0'>
                    <div
                      className={`language-selector-flag language-selector-flag-en mt-1 p-0`}
                    ></div>
                    <TextEditor
                      className={'w-100'}
                      name={'description_en'}
                      options={['inline', 'link', 'list', 'remove']}
                      customErrors={lngErrors}
                    />
                  </div>
                </div>
              </div>
            }
          />

          <FormRow
            text={t('sectionMultimedia.title')}
            description={
              <span>
                {t(`sectionMultimedia.inputMultimedia.${mode}.label`)} <br />{' '}
                {t('sectionMultimedia.inputMultimedia.resolution')}: 1024x1024 <br />{' '}
                {t('sectionMultimedia.inputMultimedia.size')}: 512KB
              </span>
            }
            endComponent={
              <div className='col-6'>
                <div className='row'>
                  <div className='col justify-content-center justify-content-sm-start'>
                    <div className='row'>
                      <div className='col-auto m-1'>
                        <ButtonGallery
                          height='150px'
                          width='auto'
                          icon={<CloudIcon size='20px' color='var(--color-text-light)' />}
                          text={
                            <div>
                              <span style={{ color: '#6941C6' }} className='fw-600 '>
                                {t('sectionMultimedia.inputMultimedia.placeholder.clickOrDrag')}{' '}
                              </span>
                              {t('sectionMultimedia.inputMultimedia.placeholder.toUploadFile')}
                            </div>
                          }
                          msg={'GIF, PNG, JPG, JPEG o BMP'}
                          onClick={() => {
                            setOpen(true);
                            setSelectedImage(null);
                          }}
                        />
                      </div>
                      {images.length > 0
                        ? images.map((i, idx) => (
                            <div className='col-auto p-0 m-1 gallery-item' key={i.imageUrl}>
                              <div className='card-gallery card-gallery-size'>
                                <div className='card-img-actions p-1'>
                                  <div className='image-container' style={{ height: '140px' }}>
                                    <img
                                      src={i.imageUrl}
                                      className='card-img img-fluid'
                                      alt='galeria'
                                    />
                                  </div>
                                  <h6 className='m-0 mt-2 font-weight-bold text-truncate'>
                                    {i.image}
                                  </h6>
                                </div>
                                <div className='image-overlay d-flex justify-content-end'>
                                  <IconButton
                                    onClick={() => handleEditImg(i, idx)}
                                    classNameIcon={'hoverIconConatiner'}
                                    icon={
                                      <FwEdit
                                        color='var(--hover)'
                                        size={20}
                                        classNameIcon={'hoverIcon'}
                                      />
                                    }
                                  />
                                  <IconButton
                                    onClick={() => handleRemoveImg(idx)}
                                    classNameIcon={'hoverIconConatiner'}
                                    icon={
                                      <FwTrashIcon
                                        color='var(--hover)'
                                        size={20}
                                        classNameIcon={'hoverIcon'}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            }
          />

          <FormRow
            text={t('sectionPost.title')}
            endComponent={
              <div className='col'>
                <InputCheckbox name={'isActive'} label={t('sectionPost.inputPost.label')} />
              </div>
            }
          />

          <FormRow
            text={t('sectionExclusive.title')}
            tooltip={t('sectionExclusive.tooltip')}
            endComponent={
              <div className='col mb-3'>
                <Switcher text={t('sectionExclusive.label')} name={'isExclusive'} />
              </div>
            }
          />
          <FormRow
            text={t('sectionConcierge.title')}
            description={t('sectionConcierge.description')}
            endComponent={
              <div className='col-6 pr-0'>
                <Select
                  options={sellersQueryFormatted}
                  placeholder={t('sectionConcierge.placeholder')}
                  className={'mb-3 col-12 pr-0'}
                  name={'usersIds'}
                  loadingMessage={() => (
                    <div className='py-3'>
                      <Loading global={false} />
                    </div>
                  )}
                  isRequired={false}
                  isMulti={true}
                  isLoading={sellersQuery.isLoading}
                  components={{
                    Option: InputOption,
                  }}
                  onMenuOpen={() => setIsOpen(true)}
                  closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  hideSelectedOptions={false}
                />
              </div>
            }
          />

          <FormRow
            text={t('sectionLoyalty.title')}
            description={t('sectionLoyalty.description')}
            endComponent={
              <div className='col-6 pr-0'>
                <Select
                  options={salesCategoryFormatted}
                  placeholder={t('sectionLoyalty.placeholder')}
                  className={'mb-3 col-12 pr-0'}
                  name={'salesCategories'}
                  isRequired={false}
                  isMulti={true}
                  components={{
                    Option: InputOption,
                  }}
                  closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  hideSelectedOptions={false}
                />
              </div>
            }
          />

          <Show.When isTrue={mode === 'groups'}>
            <FormRow
              text={t('sectionSpecialType.title')}
              tooltip={t('sectionSpecialType.tooltip')}
              positionTooltip='right'
              endComponent={
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-12 mb-4'>
                      <Switcher text={t('sectionSpecialType.label')} name={'isSpecial'} />
                    </div>

                    <Show.When isTrue={productSpecial}>
                      <div className='col-12'>
                        <Select
                          options={formatedSpecialType}
                          label={''}
                          placeholder={t('sectionSpecialType.placeholder')}
                          className={'mb-3 w-100'}
                          name={'specialType'}
                          isRequired={productSpecial}
                          isClearable={true}
                          components={{
                            // ClearIndicator: (props) => (
                            //   <components.ClearIndicator {...props}>
                            //     <TrashIcon />
                            //   </components.ClearIndicator>
                            // ),
                            DropdownIndicator: () => (
                              <ChevronIcon
                                rotation={'90'}
                                size='16px'
                                color='var(--color-subtitle)'
                              />
                            ),
                          }}
                        />
                      </div>
                    </Show.When>
                  </div>
                </div>
              }
            />
          </Show.When>
          <FormRow
            text={t('sectionQuantity.title')}
            description={t('sectionQuantity.description')}
            endComponent={
              <div className='col-6 pr-0'>
                <InputNumber
                  label={''}
                  name={'minQuantityAmount'}
                  className={'col-6 mb-2'}
                  placeholder={'1'}
                  minNumber={1}
                  onlyMinNumber={true}
                  isRequired={true}
                />
              </div>
            }
          />
          <Show.When isTrue={mode === 'areas'}>
            <FormRow
              text={t('sectionCapacity.title')}
              endComponent={
                <div className='col-6 row pr-0'>
                  <InputNumber
                    label={t('sectionCapacity.inputPassenger.label')}
                    name={'paxs'}
                    className={'col-6 mb-2'}
                    placeholder={'0'}
                    minNumber={0}
                    maxNumber={999999999}
                    showText={false}
                    isRequired={true}
                  />
                  <InputNumber
                    label={t('sectionCapacity.inputPassengerExtra.label')}
                    name={'extraPaxs'}
                    className={'col-6 mb-2 pr-0'}
                    placeholder={'0'}
                    minNumber={0}
                    maxNumber={999999999}
                    showText={false}
                    isRequired={false}
                  />
                </div>
              }
            />

            <FormRow
              text={t('sectionLocationCode.title')}
              endComponent={
                <div className='col-6 pr-0'>
                  <div className='row'>
                    <div className='col'>
                      <div className='row'>
                        <InputText
                          label={t('sectionLocationCode.inpuCode.label')}
                          name={'locationCode'}
                          className={'col-6 mb-2'}
                          placeholder={t('sectionLocationCode.inpuCode.placeholder')}
                          maxLength={3}
                          minLength={2}
                          showText={false}
                          isRequired={true}
                        />
                      </div>
                    </div>
                    <div className='col'>
                      <div className='row'>
                        <InputColor
                          label={t('sectionLocationCode.inputColor.label')}
                          name={'hexColor'}
                          className={'col-6 mb-2  pr-0'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </Show.When>

          {hasAllowedRoles(roles, ['superadministrator', 'marketadmin', 'productpriceloader']) ? (
            <FormRow
              text={t('sectionPrices.title')}
              endComponent={
                <div className='col-12 mt-2 mb-2'>
                  <ProductsSoldout
                    mode={mode}
                    methods={methods}
                    data={productQuery.data?.data?.productPrices ?? ProductWeekdays}
                  />
                </div>
              }
            />
          ) : null}

          <div className='d-flex justify-content-end mt-2'>
            <FormButton
              mutation={postProductMutation}
              handleRedirect={handleRedirect}
              clearForm={clearForm}
              all={false}
              nextStep={true}
              isDisabled={
                postProductMutation.isSuccess ||
                postProductMutation.isLoading ||
                postProductMutation.isError
              }
            />
          </div>
        </form>
      </FormProvider>

      <Gallery
        open={open}
        setOpen={setOpen}
        images={images}
        setImages={setImages}
        selectedImage={selectedImage}
      />
    </>
  );
};

export default ProductsForm;
