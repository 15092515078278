import React from 'react';
import styles from './noImage.module.css';

const NoImage = ({ text }) => {
  return (
    <div className={styles.noImage}>
      <span>{text}</span>
    </div>
  );
};

export default NoImage;
