import React from 'react';
import { RestaurantForm } from '../../components';
import { useSelector } from 'react-redux';
import { useCreateBreadCrumbs } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { Subtitle } from '../../uiComponents/common';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const EditActivity = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'forms.restaurants' });
  const organization = useSelector((state) => state.app.selectedOrganization.name);
  const { getClientTranslation } = useGenericTranslation();
  useCreateBreadCrumbs([
    { name: organization + ' ' + t('breadcrumbs.prev'), path: '/restaurantes' },
    {
      name: t('breadcrumbs.current.edit', {
        title: getClientTranslation('title.singular').toLocaleLowerCase(),
      }),
    },
  ]);
  return (
    <>
      <Subtitle
        text={
          <h1 className='m-0 ctm-title font-weight-bold'>
            {t('title.edit', { title: getClientTranslation('title.singular').toLocaleLowerCase() })}
          </h1>
        }
      />
      <RestaurantForm />
    </>
  );
};

export default EditActivity;
