import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { getClientSecret, getPublickKey } from '../../services/paymentServices';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectPaymentDetails } from '@stripe/react-connect-js';
import { useSelector } from 'react-redux';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { Show } from '../../components';
import { Loading, Toast } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';

const PaymentDetail = ({ id = null, chargeId = null, destinationPaymentId = null, onClose }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.paymentsLiveFeed' });
  const language = useSelector((state) => state.app.language);
  const [publickKey, setPublicKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const [showError, setShowError] = useState(false);
  const isOpen = (chargeId !== null || destinationPaymentId !== null) && id !== null;
  const publicQuery = useQuery({
    queryKey: ['public-key', id],
    queryFn: () => getPublickKey(welletContext, id),

    onSuccess: (data) => {
      setPublicKey(data?.data);
    },
    onError: () => {
      setShowError(true);
    },
    ...defaultQueryOptions,
    enabled: id !== null && (chargeId !== null || destinationPaymentId !== null),
  });

  const fetchClientSecret = async () => {
    setLoading(true);
    try {
      const response = await getClientSecret(welletContext, id);
      if (response.data) {
        return response.data;
      } else {
        setShowError(true);
        return undefined;
      }
    } catch {
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };
  const initStripeConnectInstance = () => {
    const locale = language === 'es' ? 'es-ES' : 'en-US';
    const stripeConnectInstance = loadConnectAndInitialize({
      publishableKey: publickKey,
      fetchClientSecret: fetchClientSecret,
      locale,
    });
    setStripeConnectInstance(stripeConnectInstance);
  };

  useEffect(() => {
    if (publickKey) {
      initStripeConnectInstance();
    }
  }, [publickKey]);

  const handleClose = () => {
    setPublicKey(null);
    setStripeConnectInstance(null);
    publicQuery.remove();
    setShowError(false);
    onClose();
  };

  const PaymentDetailUI = () => {
    if (!stripeConnectInstance) return null;

    return (
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        {chargeId || destinationPaymentId ? <ConnectPaymentDetails payment={destinationPaymentId !== null ? destinationPaymentId : chargeId} onClose={handleClose} /> : null}
      </ConnectComponentsProvider>
    );
  };

  return (
    <>
      {showError && (
        <Toast message={t('toast.error.paymentDetail')} type='ERROR' onCallback={handleClose} />
      )}
      <div className={isOpen ? 'opacityDiv' : ''}>
        <Show>
          <Show.When isTrue={publicQuery.isLoading || loading}>
            <Loading />
          </Show.When>
          <Show.Else>
            <PaymentDetailUI />
          </Show.Else>
        </Show>
      </div>
    </>
  );
};

export default PaymentDetail;
