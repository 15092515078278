export const getAllPerformances = async (
  welletContext,
  orgId,
  limit = 20,
  skip = 0,
  includeInactive = false,
  activityId = null,
  date = null,
) => {
  let url = 'api/Performance/all';
  if (orgId && orgId !== 1) {
    url += `/${orgId}`;
  }
  return await welletContext.apis.admin.post(url, {
    limit,
    skip,
    includeInactive,
    activityId,
    date,
  });
};
export const getPerformanceById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/Performance/${id}`);
};

export const postPerformance = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/Performance`, body);
};
export const putPerformance = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/Performance`, body);
};
