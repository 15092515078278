import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { getMemberships, putMemberships } from '../../../services';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';
import { daysToMinutes, minutesToDays } from '../../../helpers/dates';
import { Show } from '../../../components';
import {
  FormButton,
  FormHeader,
  InputDate,
  InputNumber,
  InputText,
  Loading,
  PageError,
  Select,
  Toast,
} from '../../../uiComponents/common';
import FormRow from '../../../components/forms/formRow';
import ClockIcon from '../../../uiComponents/icons/clockIcon';

const MembershipForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.memberships' });
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      name: '',
      appname: '',
      requalification: '',
      date_requalification: '',
      points: '',
      ctCookieTime: 30,
    },
  });

  // Query and mutations
  const membershipQuery = useQuery({
    queryKey: ['memberships', organization.marketId],
    queryFn: () => getMemberships(welletContext, organization.marketId), // reemplazar por el account id
    onSuccess: (data) => methods.reset(formatMembershipData(data?.data)),
    ...defaultQueryOptions,
  });

  const putMembershipsMutation = useMutation({
    mutationFn: (body) => {
      return putMemberships(welletContext, organization.marketId, body);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // Local Data
  const requalification = [
    { label: t('requalification.anual'), value: 360 },
    { label: t('requalification.biannual'), value: 180 },
    { label: t('requalification.quadrimetral'), value: 120 },
    { label: t('requalification.trimetral'), value: 90 },
    { label: t('requalification.monthly'), value: 30 },
  ];

  // Functions
  const onSubmit = (data) => {
    const body = {
      programName: data?.name,
      appName: data?.appname,
      requalificationFrequency: data?.requalification?.value,
      requalificationDate: data?.date_requalification,
      pointValue: data?.points,
      ctCookieTime: daysToMinutes(data?.ctCookieTime),
    };

    return putMembershipsMutation.mutate(body); 
  };
  const formatMembershipData = (data) => {
    let body = {
      name: data?.programName,
      appname: data?.appName,
      requalification: requalification.find((r) => r.value === data?.requalificationFrequency),
      date_requalification: data?.requalificationDate ? data?.requalificationDate : new Date(),
      points: data?.pointValue,
      ctCookieTime: data?.ctCookieTime
        ? minutesToDays(data?.ctCookieTime)
        : methods.formState.defaultValues.ctCookieTime,
    };
    return body;
  };
  const handleRedirect = () => {
    navigate('/settings');
  };

  const currency = membershipQuery.data?.data?.currency;
  return (
    <Show>
      <Show.When isTrue={membershipQuery.isFetching}>
        <Loading />
      </Show.When>
      <Show.When isTrue={membershipQuery.isError}>
        <PageError />
      </Show.When>
      <Show.Else>
        <FormProvider {...methods}>
          <form key={'memberships'} onSubmit={methods.handleSubmit(onSubmit)}>
            <FormHeader
              title={t('form.title')}
              border={true}
              subtitle={t('form.subtitle')}
              endComponent={
                <FormButton
                  mutation={putMembershipsMutation}
                  handleRedirect={() => {}}
                  clearForm={handleRedirect}
                  all={false}
                  nextStep={true}
                  isDisabled={
                    putMembershipsMutation.isSuccess ||
                    putMembershipsMutation.isLoading ||
                    putMembershipsMutation.isError
                  }
                />
              }
            />
            <FormRow
              text={t('form.inputName.title')}
              endComponent={
                <div className='col-12 col-md-6'>
                  <InputText
                    name={'name'}
                    label={''}
                    placeholder={t('form.inputName.placeholder')}
                    isRequired={true}
                    maxLength={510}
                  />
                </div>
              }
            />
            <FormRow
              text={t('form.inputRequalification.title')}
              tooltip={t('form.inputRequalification.tooltip')}
              endComponent={
                <div className='col-12 col-md-6'>
                  <Select
                    options={requalification}
                    placeholder={t('form.inputRequalification.placeholder')}
                    className={'mb-2'}
                    name={'requalification'}
                    isRequired={true}
                    icon={<ClockIcon color='rgb(102, 112, 133)'/>}
                  />
                </div>
              }
            />
            <FormRow
              text={t('form.inputDateRequalification.title')}
              tooltip={t('form.inputDateRequalification.tooltip')}
              endComponent={
                <div className='col-12 col-md-6'>
                  <InputDate
                    name={'date_requalification'}
                    icon={<ClockIcon color='rgb(102, 112, 133)' />}
                    customFormat='dd MMMM'
                    isRequired={true}
                    dateText={true}
                    isUtc={true}
                  />
                </div>
              }
            />
            <FormRow
              text={t('form.inputPoints.title', { currency: currency })}
              tooltip={t('form.inputPoints.tooltip', { currency: currency })}
              endComponent={
                <div className='col-12 col-md-6'>
                  <InputNumber
                    name={'points'}
                    placeholder={'0'}
                    minNumber={0}
                    className='mb-2'
                    showText={false}
                    isRequired={true}
                  />
                </div>
              }
            />
            <FormRow
              text={t('form.inputCookies.title')}
              description={t('form.inputCookies.description')}
              tooltip={t('form.inputCookies.tooltip')}
              endComponent={
                <div className='col-12 col-md-6'>
                  <InputNumber
                    name={'ctCookieTime'}
                    placeholder={'0'}
                    minNumber={1}
                    className='mb-2'
                    showText={false}
                    isRequired={true}
                  />
                </div>
              }
            />
          </form>
        </FormProvider>
        {putMembershipsMutation.isError && (
          <Toast
            message={t('toastError')}
            type='ERROR'
            onCallback={() => putMembershipsMutation.reset()}
          />
        )}
        {putMembershipsMutation.isSuccess && (
          <Toast
            message={t(`toastSuccess`)}
            type='SUCCESS'
            onCallback={() => putMembershipsMutation.reset()}
          />
        )}
      </Show.Else>
    </Show>
  );
};

export default MembershipForm;
