export const hasAllowedRoles = (userRoles, allowedRoles) => {
  return userRoles.some((role) => allowedRoles.some((item) => item === role));
};

export const getUserRole = (roleType, t) => {
  switch (roleType) {
    case 'AFFILIATE':
      return t('functions.getRoles.affiliate');
    case 'CALL_CENTER':
      return t('functions.getRoles.callCenter');
    case 'CUSTOMER':
      return t('functions.getRoles.customer');
    case 'AUDITOR':
      return t('functions.getRoles.auditor');
    case 'FINANCE':
      return t('functions.getRoles.finance');
    case 'RP':
      return t('functions.getRoles.rp');
    case 'BOSS':
      return t('functions.getRoles.bossSeller');
    case 'CASHIER':
      return t('functions.getRoles.cashier');
    case 'ADMIN':
      return t('functions.getRoles.admin');
    case 'SUPERADMINISTRATOR':
      return t('functions.getRoles.spuerAdmin');
    case 'CHECKER':
      return t('functions.getRoles.checker');
  }

  return '';
};
