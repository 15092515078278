import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { setSelectedDate, setSelectedDateType } from '../../actions';
import './dateFilter.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { Button, RadioButton } from '../../uiComponents/common';
import { defaultStaticRanges } from './dateFilterHelper';
import CalendarIcon from '../../uiComponents/icons/calendarIcon';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import { useTranslation } from 'react-i18next';

const DateFilter = ({
  filterName,
  isMultipleDate = false,
  minDate = undefined,
  maxDate = undefined,
  showStaticRanges = true,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'components.dateFilter' });
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const dateType = useSelector((state) => state.app.dateType);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);

  const [tempSelectedDateRange, setTempSelectedDateRange] = useState(null);
  const screenWidth = window.innerWidth;

  const [selectedOption, setSelectedOption] = useState(dateType);
  const [isApplying, setIsApplying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(selectedDates?.from),
    endDate: new Date(selectedDates?.to),
    key: 'selection',
  });

  const popupRef = useRef(null);
  const handleApplyClick = (close) => {
    setIsApplying(true);
    if (tempSelectedDateRange) {
      setSelectedDateRange(tempSelectedDateRange);
      dispatch(
        setSelectedDate({
          from: tempSelectedDateRange.startDate,
          to: tempSelectedDateRange.endDate,
          formattedFrom: format(tempSelectedDateRange.startDate, 'yyyy-MM-dd'),
          formattedTo: format(tempSelectedDateRange.endDate, 'yyyy-MM-dd'),
        }),
      );
    }
    if (selectedOption) {
      dispatch(setSelectedDateType(selectedOption));
    }
    close();
  };

  const onClickClear = (close) => {
    setTempSelectedDateRange(null);
    setSelectedOption(dateType);
    close();
  };

  useEffect(() => {
    if (!isApplying && !isOpen) {
      setSelectedOption(dateType);
    }
  }, [isApplying, isOpen]);

  const isMobile = window.innerWidth < 441;

  return (
    <div>
      <Popup
        ref={popupRef}
        keepTooltipInside={'.App'}
        onOpen={() => {
          setIsApplying(false);
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        trigger={
          <div className='btnFilter'>
            {isMultipleDate ? (
              <div className='pr-1'>
                {dateType === 'creation' ? `${t('creationDate')} -` : `${t('reservationDate')} -`}
              </div>
            ) : null}
            <CalendarIcon size={20} />
            <div className='p-0 pl-1 m-0 row justify-content-center'>
              <div className='col-auto px-0 pr-1 sentences-capitalize'>
                {formatDateTranslation(selectedDates?.from, language, 'small')?.date}
              </div>
              <div className='col-auto px-0 pr-1 sentences-capitalize'>
                {isMobile ? ' ' : '- '}{' '}
                {formatDateTranslation(selectedDates?.to, language, 'small')?.date}
              </div>
            </div>
          </div>
        }
        nested
        arrow={false}
        className='pop-container'
      >
        {(close) => (
          <div
            className='d-inline-block'
            style={{ backgroundColor: '#fff', border: '1px solid #ccc' }}
          >
            <DateRangePicker
              onChange={(date) => setTempSelectedDateRange(date.selection)}
              moveRangeOnFirstSelection={false}
              showSelectionPreview={true}
              ranges={[tempSelectedDateRange || selectedDateRange]}
              locale={locale}
              minDate={minDate}
              maxDate={maxDate}
              showDateDisplay={false}
              months={screenWidth > 600 ? 2 : 1}
              direction='horizontal'
              preventSnapRefocus={true}
              staticRanges={showStaticRanges ? defaultStaticRanges(t) : []}
            />
            <div className='row'>
              {isMultipleDate ? (
                <div className='col d-flex align-items-center'>
                  <div className='ml-3'>
                    <RadioButton
                      text={t('reservationDate')}
                      value='reservation'
                      checked={selectedOption === 'reservation'}
                      onChange={handleOptionChange}
                    />
                  </div>
                  <div className='ml-3'>
                    <RadioButton
                      text={t('creationDate')}
                      value='creation'
                      checked={selectedOption === 'creation'}
                      onChange={handleOptionChange}
                    />
                  </div>
                </div>
              ) : null}
              <div className='d-flex justify-content-end col position-relative rdr-buttons-position mb-2 mr-3'>
                <Button
                  size={'small'}
                  onClick={() => onClickClear(close)}
                  className='btn-wellet-secondary'
                  text={t('cancel')}
                />
                <Button
                  size={'small'}
                  onClick={() => handleApplyClick(close)}
                  className='ml-2 btn-wellet-primary'
                  text={t('apply')}
                />
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};
export default DateFilter;
