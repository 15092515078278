import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Chart from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { CardDashboard } from '../../uiComponents/common';

ReactFC.fcRoot(FusionCharts, Chart, FusionTheme);

const Pie2D = ({ title, subtitle, data, parentClass, legendPosition, pieRadius }) => {
  // Creating the JSON object to store the chart configurations
  const chartConfigs = {
    type: 'doughnut2d', // The chart type
    width: '100%', // Width of the chart
    height: '500', // Height of the chart
    dataFormat: 'json', // Data type
    dataEmptyMessage: 'No hay información para esas fechas.',
    dataSource: {
      // Chart Configuration
      chart: {
        showLabels: 0,
        showValues: 0,
        baseFont: '"Inter", sans-serif',
        labelFont: '"Inter", sans-serif',
        labelFontSize: '13px',
        legendItemFont: '"Inter", sans-serif',
        legendItemFontSize: '11px',
        //Set the chart caption
        caption: title,
        subtitle: subtitle,
        theme: 'fusion',
        pieRadius,
        doughnutRadius: '80%',
        legendPosition,
        palettecolors: "#675dff,#B3AEFF,#C7C4FC,#CFCDF8, #EAECF0, #D4D5D8, #BDBEC0, #A7A7A8, #909090"
      },
      // Chart Data
      data,
    },
  };

  return (
    <CardDashboard className={parentClass}>
      <ReactFC {...chartConfigs} />
    </CardDashboard>
  );
};

export default Pie2D;
