import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import TimePicker from 'react-time-picker';
import { CloseIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const InputClock = ({
  name,
  className,
  label,
  icon,
  required = true,
  isDisabled = false,
  useError = true,
  useCustomMaxTime = false,
  customMinTime = undefined,
  customMaxTime = '23:30',
  format = 'hh:mm a',
  closeIcon = true
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });

  return (
    <div className={className}>
      {label ? <label style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
      <Controller
        name={name}
        control={control}
        defaultValue=''
        rules={
          required && {
            required: t('required'),
          }
        }
        render={({ field }) => (
          <>
            <div className={`d-flex input-base-container ${errors[name] ? 'input-error' : ''}`}>
              {icon ? (
                <span
                  className='input-group-text px-2 input-icon'
                  style={{ color: 'var(--color-subtitle)', background: '#fff' }}
                >
                  {icon}
                </span>
              ) : (
                <></>
              )}
              <TimePicker
                className={`input-style ${errors[name] && 'input-error'}`}
                disableClock={true}
                value={field.value}
                clearIcon={closeIcon ? <CloseIcon color='var(--color-subtitle)' /> : null}
                format={format}
                minTime={customMinTime ? customMinTime : undefined}
                maxTime={useCustomMaxTime ? customMaxTime : '23:30'}
                disabled={isDisabled}
                {...field}
                style={{ visibility: 'visible', position: 'static', zIndex: 'auto' }}
              />
            </div>
            {useError && errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InputClock;
