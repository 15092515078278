export default function ClockWarning({ size = '16px' }) {
  return (
    <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 845'>
        <g id='Vector'>
          <path
            d='M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.0043329 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18.1818C8.38179 18.1818 6.79992 17.702 5.45443 16.8029C4.10894 15.9039 3.06025 14.6261 2.44099 13.131C1.82173 11.636 1.6597 9.99092 1.9754 8.40381C2.29109 6.81669 3.07034 5.35883 4.21458 4.21458C5.35883 3.07033 6.81669 2.29109 8.40381 1.97539C9.99093 1.6597 11.636 1.82172 13.131 2.44099C14.6261 3.06025 15.9039 4.10893 16.8029 5.45442C17.702 6.79992 18.1818 8.38179 18.1818 10C18.1818 12.17 17.3198 14.251 15.7854 15.7854C14.251 17.3198 12.17 18.1818 10 18.1818Z'
            fill='#C84801'
          />
          <path
            d='M10 18.1818C8.38179 18.1818 6.79992 17.702 5.45443 16.8029C4.10894 15.9039 3.06025 14.6261 2.44099 13.131C1.82173 11.636 1.6597 9.99092 1.9754 8.40381C2.29109 6.81669 3.07034 5.35883 4.21458 4.21458C5.35883 3.07033 6.81669 2.29109 8.40381 1.97539C9.99093 1.6597 11.636 1.82172 13.131 2.44099C14.6261 3.06025 15.9039 4.10893 16.8029 5.45442C17.702 6.79992 18.1818 8.38179 18.1818 10C18.1818 12.17 17.3198 14.251 15.7854 15.7854C14.251 17.3198 12.17 18.1818 10 18.1818Z'
            fill='white'
          />
        </g>
        <path
          id='Vector 17'
          d='M10 10V1C4.6 1 1 5.05 1 10C1 14.95 5.05 19 10 19V10Z'
          fill='#C84801'
          stroke='#C84801'
        />
        <path
          id='Vector 19'
          d='M10 10L10 19C12.3363 19 14.4602 17.9875 16 16.3563L10 10Z'
          fill='#C84801'
          stroke='#C84801'
        />
      </g>
    </svg>
  );
}
