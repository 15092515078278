import React from 'react';
import Button from '../button/button';
import { useTranslation } from 'react-i18next';

const FormButton = ({
  handleRedirect,
  clearForm,
  mutation,
  reff,
  all = true,
  nextStep = false,
  useModal = false,
  submit,
  isDisabled = false,
  showSendAndClose = true,
  showCancel = true,
  align = 'justify-content-center',
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.formButton' });
  const textRedirections = {
    normal: nextStep ? t('save') : t('saveAndClose'),
    sending: t('saving'),
  };

  const onClickSave = () => {
    if (useModal) {
      submit();
      handleRedirect(false, 'send');
    } else {
      handleRedirect(false, 'send');
    }
  };
  const onClickSaveAndClose = () => {
    if (useModal) {
      submit();
      handleRedirect(true, 'sendAndClose');
    } else {
      handleRedirect(true, 'sendAndClose');
    }
  };
  return (
    <div className='row align-items-center align'>
      {showCancel ? (
        <Button
          size={'medium'}
          type='button'
          className={`${all ? 'btn-without-bg' : 'btn-wellet-secondary'}`}
          text={t('cancel')}
          onClick={clearForm}
          disabled={isDisabled}
        />
      ) : null}
      {all && (
        <Button
          size={'medium'}
          type='submit'
          text={t('save')}
          onClick={onClickSave}
          className={showSendAndClose ? 'btn-wellet-secondary ml-2' : 'btn-wellet-primary ml-2'}
          disabled={isDisabled}
        />
      )}
      {showSendAndClose && (
        <Button
          size={'medium'}
          className={'btn-wellet-primary ml-2'}
          type='submit'
          text={`${textRedirections.normal}`}
          onClick={onClickSaveAndClose}
          disabled={isDisabled}
        />
      )}
    </div>
  );
};

export default FormButton;
