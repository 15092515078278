import React from 'react';

const HighRisk = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='71' height='8' viewBox='0 0 71 8' fill='none'>
      <path d='M0 4C0 1.79086 1.79086 0 4 0H11V8H4C1.79086 8 0 6.20914 0 4Z' fill='#F88D94' />
      <rect x='12' width='11' height='8' fill='#F88D94' />
      <rect x='24' width='11' height='8' fill='#F88D94' />
      <rect x='36' width='11' height='8' fill='#F88D94' />
      <path
        d='M71 4C71 6.20914 69.2091 8 67 8L60 8L60 1.81028e-07L67 6.72712e-07C69.2091 8.27883e-07 71 1.79086 71 4Z'
        fill='#F88D94'
      />
      <rect x='48' width='11' height='8' fill='#F88D94' />
    </svg>
  );
};

export default HighRisk;
