import React, { useContext, useState } from 'react';
import { Button, ErrorAlert, Loading, Modal } from '../../../../uiComponents/common';
import { WelletContext } from '../../../../context/wellet/welletContext';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../../components';
import { useQuery } from 'react-query';
import { getFilePayment } from '../../../../services';
import { defaultQueryOptions } from '../../../../utils/defaultQueryOptions';

const ModalPreviewFile = ({ open, setOpen, paymentId }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalPreviewFile' });
  const [fileSrc, setFileSrc] = useState(null);

  // API Query
  const fileQuery = useQuery(
    ['file', open],
    () => getFilePayment(welletContext, paymentId, { file: open }),
    {
      ...defaultQueryOptions,
      onSuccess: (data) => {
        const { stream, fileType: type, fileName } = data?.data;
        let fileUrl;
     
        if (type === 'image/jpeg' || type === 'image/png' || type.includes('jpg') || type.includes('mp4')) {
          const base64String = stream;
          fileUrl = `data:${type};base64,${base64String}`;
        } else if (type === 'application/pdf') {
          const byteCharacters = atob(stream);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          fileUrl = URL.createObjectURL(blob);
        }

        setFileSrc({ src: fileUrl, type: type?.split('/')[1], name: fileName });
      },
      enabled: !!open,
    },
  );

  // Functions
  const handleModal = () => {
    setOpen('');
  };
  const handleClose = () => {
    handleModal();
    setFileSrc(null)
  };
  const handleDownload = () => {
    if (fileSrc && fileSrc.src) {
      const link = document.createElement('a');
      link.href = fileSrc.src;
      link.setAttribute('download', fileSrc.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Modal
        title={t('title')}
        open={!!open}
        onClose={handleClose}
        closeIcon={true}
        maxWidth='600px'
        closeOnDocumentClick={false}
        customButtons={
          <>
            <div className='row px-2 text-center'>
              <Button
                text={t('btnClose')}
                disabled={false}
                className={'col btn-general btn-wellet-secondary'}
                size={'medium'}
                onClick={handleModal}
              />
              <Button
                text={t('btnDownload')}
                disabled={false}
                className={'col ml-2 btn-general btn-wellet-primary'}
                size={'medium'}
                onClick={handleDownload}
              />
            </div>
          </>
        }
      >
        <Show>
          <Show.When isTrue={fileQuery.isFetching || fileQuery.isLoading}>
            <div className='my-4 py-4'>
              <Loading />
            </div>
          </Show.When>
          <Show.When isTrue={fileQuery.isError}>
            <ErrorAlert text={t('error')} />
          </Show.When>
          <Show.When
            isTrue={
              fileSrc?.src &&
              fileSrc?.type !== 'pdf' &&
              fileSrc?.type !== 'mp4' &&
              !fileQuery.isError
            }
          >
            <div className='mt-2 d-flex justify-content-center'>
              <img src={fileSrc?.src} alt='Preview' style={{ width: '400px', height: '400px' }} />
            </div>
          </Show.When>
          <Show.When isTrue={fileSrc?.src && fileSrc?.type === 'mp4' && !fileQuery.isError}>
            <div className='d-flex justify-content-center'>
              <video controls width='500px' height='400px'>
                <source src={fileSrc?.src} type={`video/${fileSrc?.type}`} />
                Your browser does not support the video tag.
              </video>
            </div>
          </Show.When>
          <Show.Else>
            <div className='mt-2'>
              <iframe
                title='file'
                src={fileSrc?.src}
                width='600px'
                height='400px'
                style={{ overflowX: 'hidden' }}
              />
            </div>
          </Show.Else>
        </Show>
      </Modal>
    </>
  );
};

export default ModalPreviewFile;
