import React, { useEffect, useState } from 'react';
import Button from '../button/button';
import Popup from 'reactjs-popup';
import { FwAddIcon } from '../../icons';
import Input from '../input/input';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../components';
import ErrorAlert from '../errorAlert/errorAlert';
import { useSelector } from 'react-redux';

const MinMaxFilter = ({
  filterName,
  filterOptions = {},
  callback,
  initialValue = { min: null, max: null },
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.minMaxFilter' });
  const currency = useSelector((state) => state.app.selectedOrganization);
  const [amount, setAmount] = useState(initialValue);
  const [tempAmount, setTempAmount] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const handleApplyClick = (close) => {
    // Parsear los valores a números
    let min = tempAmount.min !== null ? parseFloat(tempAmount.min) : null;
    let max = tempAmount.max !== null ? parseFloat(tempAmount.max) : null;

    // Verificar si los valores son NaN y asignar null en ese caso
    min = isNaN(min) ? null : min;
    max = isNaN(max) ? null : max;

    // Validar que el valor mínimo no sea mayor que el valor máximo
    if (min !== null && min !== 0 && max !== null && max !== 0 && min > max) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    // Validacion para no actualizar estado
    if ((max === null || max === 0) && (min === null || min === 0)) {
      close();
      return;
    }
    setAmount({ max, min });
    callback && callback({ max, min });
    close();
  };
  const handleClear = (close) => {
    const resetValue = { min: null, max: null };
    setAmount(resetValue);
    setTempAmount(resetValue);
    callback && callback(resetValue);
    close();
  };
  const handleOpen = () => {
    setTempAmount(amount);
    setIsOpen(true);
  };
  const checkError = (min, max) => {
    setError(min !== null && min !== 0 && max !== null && max !== 0 && min > max);
  };
  const handleMinChange = (e) => {
    const newMin = parseFloat(e.target.value);
    const newTempAmount = { ...tempAmount, min: newMin };
    setTempAmount(newTempAmount);
    checkError(newMin, tempAmount.max);
  };

  const handleMaxChange = (e) => {
    const newMax = parseFloat(e.target.value);
    const newTempAmount = { ...tempAmount, max: newMax };
    setTempAmount(newTempAmount);
    checkError(tempAmount.min, newMax);
  };
  const handleClose = () => {
    setIsOpen(false);
    setError(false);
  };

  return (
    <>
      <Popup
        keepTooltipInside={'.App'}
        trigger={
          <div className='btnFilterDashed text-nowrap'>
            <div
              className='d-flex mr-1 align-items-center justify-content-center'
              style={{
                borderRadius: '50%',
                border: '1px solid var(--color-border-table)',
                paddingLeft: '2px',
                width: '22px',
                height: '22px',
                background: '#F9FAFB',
              }}
            >
              <FwAddIcon color='#667085' fontSize='10px' />
            </div>
            <p className='p-0 m-0'>{filterName}</p>
            {amount.min !== null && amount.min > 0 && <span className='mx-1'>|</span>}
            <p className='p-0 m-0 option-selected text-truncate'>
              {amount.min !== null && amount.min > 0
                ? `${currency.currencySymbol} ${Number(amount.min)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : null}
            </p>
            {amount.max !== null && amount.max > 0 && <span className='mx-1'>-</span>}
            <p className='p-0 m-0 option-selected text-truncate'>
              {amount.max !== null && amount.max > 0
                ? `${currency.currencySymbol} ${Number(amount.max)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : null}
            </p>
          </div>
        }
        nested
        arrow={false}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {(close) => (
          <div className={`filter-modal isOpen`} onClick={(e) => e.stopPropagation()}>
            <Input
              icon={<div style={{ fontSize: '12px' }}>{currency.currencySymbol}</div>}
              type={'number'}
              name={'numberMin'}
              value={tempAmount.min}
              autoComplete='off'
              onChange={handleMinChange} // Agregar esta línea
              placeholder={t('searchMin')}
              min={0}
              max={99999999}
            />
            <Input
              icon={<div style={{ fontSize: '12px' }}>{currency.currencySymbol}</div>}
              type={'number'}
              name={'numberMax'}
              value={tempAmount.max}
              autoComplete='off'
              onChange={handleMaxChange} // Agregar esta línea
              placeholder={t('searchMax')}
              min={0}
              max={99999999}
            />
            <Show.When isTrue={error}>
              <ErrorAlert text={t('error')} />
            </Show.When>

            <div className='w-100 d-flex justify-content-center pt-2'>
              <Button
                size={'small'}
                onClick={() => handleClear(close)}
                className='btn-wellet-secondary'
                text={t('delete')}
              />
              <Button
                size={'small'}
                onClick={() => handleApplyClick(close)}
                className='btn-wellet-primary  ml-2'
                text={t('apply')}
                disabled={error}
              />
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default MinMaxFilter;
