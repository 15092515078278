import React from 'react';
import { InputBase } from '../inputs';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const InputColor = ({
  name,
  placeholder,
  className,
  icon,
  label,
  isRequired = false,
  isDisabled = false,
  useError = true,
  customValidation,
  hintText,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });

  const validationRules = customValidation;

  if (isRequired) {
    validationRules.required = t('required');
  }

  return (
    <div className={`form-group ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        rules={validationRules}
        render={({ field }) => (
          <div>
            <InputBase
              type='color'
              field={field}
              errors={errors}
              name={name}
              placeholder={placeholder}
              icon={icon}
              label={label}
              isDisabled={isDisabled}
              style={{ height: '41px', padding: '12px' }}
            />
            {hintText ? hintText : null}
            {useError && errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default InputColor;
