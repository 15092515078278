export const getBillings = async (
  welletContext,
  limit,
  skip,
  sortBy,
  columnFilter,
  status = null,
  date,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
    status: status,
    from: date.from,
    to: date.to,
  };
  return await welletContext.apis.admin.post(`api/wallet/welletorder`, body);
};
export const getDetailBillingByComission = async (
  welletContext,
  id,
  limit,
  skip,
  sortBy,
  columnFilter,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
  };
  return await welletContext.apis.admin.post(`api/wallet/welletorder/${id}/detail`, body);
};
export const getDetailBillingByRest = async (
  welletContext,
  id,
  limit,
  skip,
  sortBy,
  columnFilter,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
  };
  return await welletContext.apis.admin.post(`api/wallet/welletorder/${id}/restaurant`, body);
};
