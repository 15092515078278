import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useOrderBy, usePagination } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { Loading, PageError, Subtitle, UserImage } from '../../../uiComponents/common';

import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';
import { getTopConcierges } from '../../../services';
import TableRow from '../../table/tableRow';
import TableCell from '../../table/tableCell';
import Show from '../../show/show';
import Table from '../../table/table';

const TopConcierges = ({ show }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.campaigns.statistics.tables.concierges',
  });
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);

  // Dates
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };

  //Sort Columns
  const { orderBy, orderType, handleColumnClick } = useOrderBy();

  // Pagination
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    1,
    10,
    false,
  );
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // API Query
  const topUsersQuery = useQuery({
    queryKey: [
      'get-top-users',
      limit,
      skip,
      orderBy,
      orderType,
      selectedFormattedDates,
      organization,
    ],
    queryFn: () => {
      return getTopConcierges(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        limit,
        skip,
        orderBy ? orderBy : 'DESC',
        orderType ? orderType : 'totalReservations',
        selectedFormattedDates,
      );
    },
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.totalRegisters);
    },
    onError: (error) => {
      console.error('Tabla Concierges', error);
    },
    ...defaultQueryOptions,
    enabled: show,
  });

  const header = [
    { title: t('name'), value: '', filterable: false, center: false },
    { title: t('reservation'), value: 'totalReservations', filterable: true, center: false },
    { title: t('campaigns'), value: '', filterable: false, center: false },
  ];

  return (
    <Show>
      <Show.When isTrue={topUsersQuery.isLoading}>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '400px' }}
        >
          <Loading global={false} />
        </div>
      </Show.When>
      <Show.When isTrue={topUsersQuery.isError}>
        <PageError globaly={false} />
      </Show.When>
      <Show.Else>
        <Subtitle text={<h3 className='mb-0'>{t('title')}</h3>} border={false} />
        <div className='mt-2'>
          <Table
            data={topUsersQuery?.data?.data?.items ?? []}
            columns={header}
            totalP={totalItems}
            onColumnClick={handleColumnClick}
            activeColumn={orderType}
            sortOrder={orderBy}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagination='normal'
            header='white'
            paddingTh={'padding-white-header'}
            borderTop={false}
            borderContainer
            renderRow={(affiliate) => (
              <>
                <TableCell>
                  <div className='row flex-nowrap'>
                    <div className='col-auto pr-0'>
                      <div className='profile-pic'>
                        <UserImage
                          url={affiliate?.seller?.profilePicUrl}
                          name={affiliate?.seller?.fullName}
                          size={'40px'}
                          isVerified={affiliate?.seller?.isVerified}
                        />
                      </div>
                    </div>
                    <div className='col'>
                      <div className='font-weight-semibold'>{affiliate?.seller?.fullName}</div>
                      <div className='dark-grey line-height-1 text-capitalize'>
                        {affiliate?.seller?.company ?? '-'}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>{affiliate?.totalReservations}</TableCell>
                <TableCell>{affiliate?.totalCampaigns}</TableCell>
              </>
            )}
            rowClass='not-hover'
          />
        </div>
      </Show.Else>
    </Show>
  );
};

export default TopConcierges;
