import React, { useRef } from 'react';
import Select from 'react-select';

export const AllSelect = (props) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: 'all',
    label: 'All Options',
  };

  const isSelectAllSelected = () => valueRef.current?.length === props.options?.length;

  const isOptionSelected = (option) => {
    if (option) {
      return (
        valueRef?.current?.some(({ value }) => value === option.value) || isSelectAllSelected()
      );
    }
  };

  const getOptions = () => (!!props.options?.length ? [selectAllOption, ...props.options] : []);

  const getValue = () => {
    if (props.isFetching) {
      return [];
    } else if (isSelectAllSelected() && !!props.options?.length) {
      return [selectAllOption];
    } else if (!props?.options?.length) {
      return [];
    } else {
      return props.value;
    }
  };

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      const allValues = props.options.map((option) => option);
      props.onChange(allValues, actionMeta);
    } else if (
      (action === 'deselect-option' && option.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta,
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <Select
      isOptionSelected={isOptionSelected}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      isMulti
      {...props}
      value={getValue()}
      onChange={onChange}
      options={getOptions()}
      placeholder='Seleccionar'
    />
  );
};
