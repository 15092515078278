import React from 'react';
import { useSelector } from 'react-redux';
import { getLocale } from '../../helpers/dates';
import './reservation-detail.css';
import { Link, useNavigate } from 'react-router-dom';
import { ConciergeDetail, Show } from '../../components';
import ModalModification from './modalModification';
import Notes from '../../components/reservation/notes';
import ReservationProductZone from './reservationProductZone';
import ModalDeposit from './modalDeposit';
import { useTranslation } from 'react-i18next';
import ReservationInfo from './request/reservationInfo';
import { WarningModernIcon } from '../../uiComponents/icons';
import TablePdf from '../../components/reservation/tablesPdf';

const ReservationModification = ({ reservation, callback }) => {
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.modification' });

  // TO DO: Unificar con ReservationPendingDetail
  return (
    <Show.When isTrue={reservation}>
      <div className='container-page color-body'>
        <div className='container-header with-border'>
          <div className='row'>
            <div className='col-auto'>
              <div className='light-grey container-small-title'>{t('subtitle')}</div>
              <div className='container-title'>
                <div className='row'>
                  <div></div>
                </div>
                {reservation?.actualReservation?.activityName}
              </div>
            </div>
            <div className='col my-auto'></div>
            <div className='col-auto text-right d-flex align-items-end justify-content-center'>
              <Show>
                <Show.When isTrue={reservation?.status === 'CONFIRMED'}>
                  <ModalDeposit
                    onClose={() => {
                      navigate('/reservations-pending');
                    }}
                    reservation={reservation?.actualReservation}
                  />
                </Show.When>
                <Show.Else>
                  <ModalModification
                    locale={locale}
                    language={reservation?.concierge?.language}
                    onClose={() => {
                      navigate('/reservations-pending');
                    }}
                    reservation={reservation}
                  />
                </Show.Else>
              </Show>
            </div>
          </div>
        </div>

        <div
          className='w-100 d-flex my-3 align-items-center'
          style={{ background: '#7F56D9', color: '#fff', padding: '4px 12px' }}
        >
          <WarningModernIcon color={'#fec73d'} size='20px' />
          <div className='fw-700 pl-2' style={{ fontSize: '20px', marginTop: '4px' }}>
            {t('title')}{' '}
            <Link
              target='_blank'
              style={{ textDecoration: 'underline', textUnderlineOffset: '2px', color: '#fff' }}
              rel='noopener noreferrer'
              to={`/reservation-history/${reservation.oldReservation.id}`}
            >
              {reservation.oldReservation.referenceCode}
            </Link>
          </div>
        </div>

        <ReservationInfo reservation={reservation} mode='request' />

        <ReservationProductZone reservation={reservation} showPayment={true} showProduct={false} />

        <ConciergeDetail
          data={{ concierge: reservation?.concierge, rp: reservation?.rp, boss: reservation?.boss }}
          language={language}
        />
        <Notes
          currentNotes={reservation.listComments ? reservation.listComments : []}
          reservationId={reservation.actualReservation.id}
          callback={callback}
          marginBottom={reservation?.status === 'CONFIRMED' ? '50px' : '150px'}
        />
        <Show.When isTrue={reservation?.status === 'CONFIRMED'}>
          <TablePdf data={reservation?.orderDocuments} />
        </Show.When>
      </div>
    </Show.When>
  );
};

export default ReservationModification;
