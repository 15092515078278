export default function CircleFullIcon({ size = '16px', color = '#ccc' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='10' fill={color} />
    </svg>
  );
}
