import React, { useContext } from 'react';
import Table from '../table/table';
import { usePagination } from '../../hooks';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getVenueDetails } from '../../services';
import TableCell from '../table/tableCell';
import { Loading, Money } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { getOrderBy } from '../../helpers/dashboard';
import useGenericTranslation from '../../hooks/useGenericTranslation';

export const getDashboardRow = (option, reservation) => {
  if (option === 'reservations') {
    return <div>{reservation.reservations ?? '-'}</div>;
  } else if (option === 'commissions') {
    return <Money value={reservation.commission} />;
  } else if (option === 'commission') {
    return <div>{reservation.avgCommission}%</div>;
  } else if (option === 'concierges') {
    return <div>{reservation.activeConcierges ?? '-'}</div>;
  } else {
    return <Money value={reservation.income} />;
  }
};

const UnitsTable = ({ selectedOption }) => {
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.dashboard.tables.tableRestaurant',
  });
  const { getClientTranslation } = useGenericTranslation();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    1,
    10,
    false,
  );
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const restaurantQuery = useQuery({
    queryKey: [
      'dashboard-restaurants',
      organization.id,
      selectedDates,
      limit,
      skip,
      selectedOption,
    ],
    queryFn: () =>
      getVenueDetails(
        welletContext,
        organization.marketId,
        selectedFormattedDates,
        !organization.isMarket ? organization.id : null,
        limit,
        skip,
        getOrderBy(selectedOption),
      ),
    //refetchInterval: 10000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => setTotalAndReset(data?.data?.totalRegisters),
  });

  const headerAdmin = [
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    {
      title:
        selectedOption === 'reservations'
          ? t('headers.reservations')
          : selectedOption === 'commissions'
          ? t('headers.commissions')
          : selectedOption === 'commission'
          ? t('headers.avgCommission')
          : selectedOption === 'concierges'
          ? t('headers.activeConcierges')
          : t('headers.incomes'),
      value: '',
      filterable: false,
    },
    { title: 'Pax', value: '', filterable: false },
  ];
  return (
    <div>
      {restaurantQuery.isLoading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '400px' }}
        >
          <Loading global={false} />
        </div>
      ) : (
        <Table
          hasPagination={false}
          borderTop={false}
          showPagination={true}
          totalP={totalItems}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagination='normal'
          header='white'
          paddingTh={'padding-white-header'}
          borderContainer={true}
          columns={headerAdmin}
          showText={false}
          data={restaurantQuery.data?.data.items}
          renderRow={(restaurant) => (
            <>
              <TableCell>
                <div className='row flex-nowrap'>
                  <div className='col-auto pr-1'>
                    <img
                      src={restaurant.showLogo}
                      alt={restaurant.showName}
                      style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    />
                  </div>
                  <div className='col my-auto'>
                    <div className='font-weight-semibold'>{restaurant.showName}</div>
                  </div>
                </div>
              </TableCell>
              <TableCell className='ctm-text font-weight-bold' type={'primary'}>
                {getDashboardRow(selectedOption, restaurant)}
              </TableCell>
              <TableCell>{restaurant.paxs}</TableCell>
            </>
          )}
          rowClass='not-hover'
        />
      )}
    </div>
  );
};

export default UnitsTable;
