import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { exchangeToken, authChecked, setTenantId as setAppTenant } from '../../actions';
import axios from 'axios';

const Auth = () => {
  const dispatch = useDispatch();
  const [tenantId, setTenantId] = useState(null)
  
  const fetchTenant = async () => {
    let tenant = null;
    try {
      const welletApi = process.env.REACT_APP_SALES_API_URL;
      const response = await axios.get(`${welletApi}/auth/tenant`);
      tenant = response.data
    }
    catch (error) {
      console.error(error)
    }

    setTenantId(tenant);
    dispatch(setAppTenant(tenant));

    return tenant;
  }

  useEffect(() => {
    if (!tenantId) {
      fetchTenant();
    }
  }, [tenantId]);

  return tenantId ? <AuthWithTenant tenantId={tenantId} /> : null;
};

const AuthWithTenant = ({ tenantId }) => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);
  const auth = getAuth();
  auth.tenantId = tenantId;

  const [user] = useAuthState(auth);

  useEffect(() => {
    if (auth._isInitialized && !user) {
      dispatch(authChecked());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth._isInitialized]);

  useEffect(() => {
    if (user && !isLogin) {
      exchangeToken(dispatch, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};

export default Auth;
