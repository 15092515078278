import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { ChevronIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../components';
import NewTooltip from '../tooltip/newTooltip';

const InputDescription = ({
  name,
  className = '',
  placeholder,
  label,
  icon,
  isRequired = false,
  minLength = 3,
  maxLength = 500,
  height = '34px',
  dropdown = false,
  tooltip = null,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const [show, setShow] = useState(false);

  const validationRules = {
    minLength: {
      value: minLength,
      message: t('minLength'),
    },
    maxLength: {
      value: maxLength,
      message: t('maxLength'),
    },
  };

  if (isRequired) {
    validationRules.required = t('required');
  }
  return (
    <div className={`form-group ${className}`}>
      <div className='d-flex'>
        <label className='mb-1 d-flex align-items-center' style={{ color: 'var(--color-text)' }}>
          {label}
          <Show.When isTrue={tooltip !== null}>
            <div className='ml-1'>
              <NewTooltip tooltip={tooltip} position={'top'} />
            </div>
          </Show.When>
        </label>
        {dropdown ? (
          <div
            className='ml-1 cursor-pointer arrow-input-description'
            onClick={() => setShow(!show)}
          >
            <ChevronIcon rotation={!show ? 90 : 270} size='16px' color='#475467' />
          </div>
        ) : null}
      </div>
      {!show && dropdown ? null : (
        <Controller
          name={name}
          control={control}
          key={name}
          defaultValue=''
          rules={validationRules}
          render={({ field }) => (
            <>
              <div className={`d-flex input-base-container ${errors[name] ? 'input-error' : ''}`}>
                {icon ? (
                  <span
                    className='input-group-text px-2 input-icon'
                    style={{ color: 'var(--color-subtitle)' }}
                  >
                    {icon}
                  </span>
                ) : (
                  <></>
                )}
                <textarea
                  style={{ height: height }}
                  placeholder={placeholder}
                  {...field}
                  className={`input-style textarea-style`}
                />
              </div>
              {errors[name] && (
                <div className='d-flex align-items-center error-content'>
                  <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                  <p className='text-danger p-0 m-0'>{errors[name].message}</p>
                </div>
              )}
            </>
          )}
        />
      )}
    </div>
  );
};

export default InputDescription;
