import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation, useQuery } from 'react-query';
import { downloadConcierges, getAllConcierges } from '../../services';
import {
  Loading,
  Input,
  Status,
  ButtonFilter,
  Button,
  Toast,
  PageError,
  AffiliateCard,
  Tabs,
  Money,
} from '../../uiComponents/common';
import { FilterCard, PageHeader, Show, Table, TableCell } from '../../components';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useCreateBreadCrumbs,
  useDebounce,
  useGetBosses,
  useGetRPs,
  useLocalStorage,
  useOrderBy,
  usePagination,
} from '../../hooks';
import { SearchIcon } from '../../uiComponents/icons';
import { filtersFormat, getPaymentMethods } from '../../helpers/format';
import { conciergeStatus, pendingAssociatesStatus } from '../../data';
import downloadFileFromResponse from '../../utils/downloadFile';
import { formatDateTranslation } from '../../helpers/dates';
import { useTranslation } from 'react-i18next';
import mergeWithDefaults from '../../helpers/localstorage';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { useGetSalesCategory } from '../../hooks/queries/useGetSalesCategory';

const tabTypes = {
  SUMMARY: '',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

// To Do: Traducciones de los errrores
const Sellers = () => {
  const welletContext = useContext(WelletContext);
  const location = useLocation();
  const { t: translate } = useTranslation();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'screens.concierges' });
  const paymentMethods = useSelector((state) => state.app.paymentMethods);
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-sellers', {
    filterStatus: conciergeStatus(translate),
    filterKYC: pendingAssociatesStatus(translate),
    filterPaymentMethods: getPaymentMethods(paymentMethods, translate, true),
    filterLoyalty: [],
    filterField: '',
    rp: [],
    boss: [],
    filterByUrl: null,
    page: 1,
    advantageNetworkStatus: tabTypes.SUMMARY,
  });
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const [value, setValue] = useState(filterLocalStorage.filterField);
  const language = useSelector((state) => state.app.language);
  const [activeTab, setActiveTab] = useState(
    filterLocalStorage?.advantageNetworkStatus
      ? filterLocalStorage?.advantageNetworkStatus
      : tabTypes.SUMMARY,
  );
  const debouncedSearch = useDebounce(value, 500);
  const [notifications, setNotifications] = useState(0);
  const [filterStatus, setFilterStatus] = useState(filterLocalStorage.filterStatus ?? []);
  const [filterLoyalty, setFilterLoyalty] = useState(filterLocalStorage.filterLoyalty ?? []);
  const [filterKYC, setFilterKYC] = useState(filterLocalStorage.filterKYC);
  const showFilterCard =
    filtersFormat(filterStatus).length === 0 && filterLocalStorage.filterByUrl === 'custom'
      ? []
      : filterLocalStorage.filterByUrl
      ? filterLocalStorage.filterByUrl.split('-')
      : [];
  const [filter, setFilter] = useState(showFilterCard || '');
  const [filterRP, setFilterRP] = useState(filterLocalStorage.rp);
  const [filterBoss, setFilterBoss] = useState(filterLocalStorage.boss);
  const [filterPaymentMethods, setFilterPaymentMethods] = useState(
    mergeWithDefaults(
      filterLocalStorage.filterPaymentMethods,
      getPaymentMethods(paymentMethods, translate, true),
    ),
  );

  const { orderBy, orderType, handleColumnClick } = useOrderBy();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
  );
  const navigate = useNavigate();

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // Main API Query
  const sellersQuery = useQuery({
    queryKey: [
      'sellers',
      selectedOrganization.id,
      debouncedSearch,
      skip,
      filter,
      filtersFormat(filterRP),
      filtersFormat(filterPaymentMethods),
      filtersFormat(filterStatus),
      filtersFormat(filterBoss),
      filtersFormat(filterKYC),
      filtersFormat(filterLoyalty),
      orderBy,
      orderType,
      activeTab,
    ],
    queryFn: () =>
      getAllConcierges(
        welletContext, // welletContext
        limit, // limit
        skip, // skip
        organization.marketId, // marketID
        organization.id, // Org ID
        debouncedSearch.trim(), //fieldFilter
        false, // includeInactive
        filtersFormat(filterKYC), // kyc filter
        true,
        filtersFormat(filterStatus).length === 0
          ? filter.length > 0 && filter[0] !== 'custom'
            ? filter
            : []
          : filtersFormat(filterStatus),
        [],
        filtersFormat(filterRP),
        filtersFormat(filterPaymentMethods),
        filtersFormat(filterBoss),
        filtersFormat(filterLoyalty),
        orderBy,
        orderType,
        activeTab === tabTypes.PENDING ? [] : !activeTab ? null : [activeTab],
        activeTab === tabTypes.PENDING ? true : false,
        true, //Include sales category
      ),
    onSuccess: (data) => {
      setNotifications(data?.data?.sendCount);
      setTotalAndReset(data?.data?.users?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        filterStatus,
        filterKYC,
        filterField: value,
        filterPaymentMethods,
        rp: filterRP,
        boss: filterBoss,
        page: currentPage,
        filterLoyalty,
        advantageNetworkStatus: activeTab,
      });
    },
    ...defaultQueryOptions,
    keepPreviousData: true,
  });
  // Hooks Query Filters
  const rpQuery = useGetRPs(organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.map((user) => ({
        label: user.fullName,
        value: user.id,
        isActive:
          filterLocalStorage.rp.length > 0
            ? filtersFormat(filterLocalStorage.rp).includes(user.id)
            : false,
      }));
      setFilterRP(response);
    },
  });
  const saleCategorysQuery = useGetSalesCategory(organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.map((loyalty) => ({
        label: loyalty.name,
        value: loyalty.id,
        isActive:
          filterLocalStorage?.filterLoyalty?.length > 0
            ? filtersFormat(filterLocalStorage?.filterLoyalty).includes(loyalty.id)
            : false,
      }));
      setFilterLoyalty(response);
    },
  });

  const bossQuery = useGetBosses(organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.map((user) => ({
        label: user.fullName,
        value: user.id,
        isActive:
          filterLocalStorage.boss.length > 0
            ? filtersFormat(filterLocalStorage.boss).includes(user.id)
            : false,
      }));
      setFilterBoss(response);
    },
  });
  // Query Mutation
  const downloadConciergeMutation = useMutation({
    mutationFn: () => {
      const response = downloadConcierges(welletContext, organization.marketId, {
        KYCFilter: filtersFormat(filterKYC).length === 0 ? null : filtersFormat(filterKYC),
        fieldFilter: debouncedSearch.trim(),
        includeInactive: false,
        includeSalesCategory: true,
        isAdvantageNetwork: true,
        advantageNetworkStatus:
          activeTab === tabTypes.PENDING ? [] : !activeTab ? null : [activeTab],
        filterPending: activeTab === tabTypes.PENDING ? true : false,
        userStatus:
          filtersFormat(filterStatus).length === 0
            ? filter.length > 0 && filter[0] !== 'custom'
              ? filter
              : []
            : filtersFormat(filterStatus),
        withdrawalMethod:
          filtersFormat(filterPaymentMethods).length === 0
            ? null
            : filtersFormat(filterPaymentMethods),
        RPIds: filtersFormat(filterRP).length === 0 ? null : filtersFormat(filterRP),
        bossIds: filtersFormat(filterBoss).length === 0 ? null : filtersFormat(filterBoss),

        salesCategoriesId:
          filtersFormat(filterLoyalty).length === 0 ? null : filtersFormat(filterLoyalty),
      });

      return response;
    },
    onSuccess: (data) => downloadFileFromResponse(data, 'concierges.xlsx'),
    onError: (error) => console.error(error),
  });

  const navigateToProfile = (seller) => {
    navigate(`/sellers/${selectedOrganization.id}/${seller.sid}/profile`, {
      state: location.pathname + location.search,
    });
  };
  const handleFilter = (filter) => {
    setFilterStatus(filterStatus.map((f) => ({ ...f, isActive: false })));
    setFilter(filter);
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, filterByUrl: filter[0], page: 1 });
  };

  const headerAdmin = [
    { title: t('table.startDate'), value: 'createdDate', filterable: true },
    { title: t('table.concierge'), value: '', filterable: false },
    { title: t('table.state'), value: '', filterable: false },
    { title: t('table.organization'), value: '', filterable: false },
    { title: t('table.loyalty'), value: '', filterable: false },
    { title: t('table.rp'), value: '', filterable: false },
    { title: t('table.bossSeller'), value: '', filterable: false },
    { title: t('table.last30daysVolume'), value: 'income', filterable: true, center: true },
    {
      title: t('table.last30daysReservations'),
      value: 'reservation',
      filterable: true,
      center: true,
    },
  ];

  useEffect(() => {
    setFilterPaymentMethods(
      mergeWithDefaults(
        filterLocalStorage.filterPaymentMethods,
        getPaymentMethods(paymentMethods, translate, true),
      ),
    );
    setFilterStatus(mergeWithDefaults(filterLocalStorage.filterStatus, conciergeStatus(translate)));
    setFilterKYC(
      mergeWithDefaults(filterLocalStorage.filterKYC, pendingAssociatesStatus(translate)),
    );
  }, [i18n.language]);

  useCreateBreadCrumbs([{ name: t('title') }]);

  const tabs = [
    {
      isActive: activeTab === tabTypes.SUMMARY,
      title: t('tabs.summary'),
      type: tabTypes.SUMMARY,
    },
    {
      isActive: activeTab === tabTypes.PENDING,
      title: t('tabs.pending'),
      type: tabTypes.PENDING,
      notification: notifications,
    },
    {
      isActive: activeTab === tabTypes.REJECTED,
      title: t('tabs.rejected'),
      type: tabTypes.REJECTED,
    },
  ];

  const handleTabs = (type) => {
    setActiveTab(type);
  };

  return (
    <>
      <PageHeader title={t('title')} marginBottom={false} />

      <Show>
        <Show.When
          isTrue={
            sellersQuery.isLoading ||
            saleCategorysQuery.isLoading ||
            bossQuery.isLoading ||
            rpQuery.isLoading
          }
        >
          <Loading />
        </Show.When>
        <Show.When isTrue={sellersQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <Tabs onClick={handleTabs} options={tabs} />
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('cardFilter.total')}
              onClick={() => handleFilter([], null)}
              className='col'
              showQuantity={false}
              isSelected={
                filtersFormat(filterStatus).length === 0 &&
                (filter.length === 0 || filter[0] === 'custom')
              }
            />
            <FilterCard
              category={t('cardFilter.actives')}
              onClick={() => handleFilter(['ENABLED'], null)}
              isSelected={filter[0] === 'ENABLED'}
              showQuantity={false}
              className='col'
            />
            <FilterCard
              category={t('cardFilter.inactives')}
              onClick={() => handleFilter(['DISABLED'], null)}
              isSelected={filter[0] === 'DISABLED'}
              className='col'
              showQuantity={false}
            />
            <FilterCard
              category={t('cardFilter.deleted')}
              onClick={() => handleFilter(['DELETED'], null)}
              isSelected={filter[0] === 'DELETED'}
              className='col'
              showQuantity={false}
            />
          </div>
          <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
            <div className='col col-sm-auto'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                name={'search'}
                value={value}
                onChangeValue={(e) => setValue(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <div className='row'>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.validateDoc')}
                  filterOptions={filterKYC}
                  callback={setFilterKYC}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={false}
                  filterName={t('buttonsFilter.getaway')}
                  filterOptions={filterPaymentMethods}
                  callback={setFilterPaymentMethods}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonsFilter.rp')}
                  filterOptions={filterRP}
                  error={rpQuery.isError}
                  callback={setFilterRP}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.bossSeller')}
                  filterOptions={filterBoss}
                  error={bossQuery.isError}
                  callback={setFilterBoss}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.loyalty')}
                  filterOptions={filterLoyalty}
                  error={saleCategorysQuery.isError}
                  callback={setFilterLoyalty}
                />
              </div>
            </div>
            <div className='col d-flex justify-content-end'>
              <Button
                className={'btn-wellet-secondary opacity justify-content-center'}
                size={'small'}
                text={t('download')}
                width={'36px'}
                disabled={downloadConciergeMutation.isLoading}
                loading={downloadConciergeMutation.isLoading}
                onClick={() => downloadConciergeMutation.mutate()}
              />
            </div>
          </div>
          <Show>
            <Show.When isTrue={sellersQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <Table
                data={sellersQuery?.data?.data?.users?.items ?? []}
                hasPagination={false}
                totalP={totalItems}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                columns={headerAdmin}
                onColumnClick={handleColumnClick}
                activeColumn={orderType}
                sortOrder={orderBy}
                renderRow={(seller) => {
                  const { date } = formatDateTranslation(seller.createdDate, language, 'small');
                  return (
                    <>
                      <TableCell className={'sentences-capitalize'}>{date}</TableCell>
                      <TableCell style={{ minWidth: '200px' }}>
                        <AffiliateCard
                          url={seller.profilePic}
                          name={seller.name}
                          company={seller.company}
                          isVerified={seller?.isVerified}
                        />
                      </TableCell>
                      <TableCell style={{ width: 'auto' }}>
                        <Status
                          status={seller.isDeleted ? false : seller.isActive}
                          text={
                            seller.isDeleted
                              ? t('statusBadge.delete')
                              : seller.isActive
                              ? t('statusBadge.active')
                              : t('statusBadge.inactive')
                          }
                        />
                      </TableCell>
                      <TableCell className={'dark-grey'}>
                        <div className='font-weight-semibold wrap-text text-capitalize'>
                          {seller.company}
                        </div>
                        <div className='dark-grey wrap-text'>
                          {seller.jobTitle ? seller.jobTitle : '-'}
                        </div>
                      </TableCell>
                      <TableCell className='fw-500' style={{ minWidth: '120px' }}>
                        {seller?.salesCategory?.name ?? '-'}
                      </TableCell>
                      <TableCell className={'dark-grey'} style={{ width: 'auto' }}>
                        {seller.reportToUserName ? seller.reportToUserName : '-'}
                      </TableCell>
                      <TableCell className={'dark-grey'} style={{ width: 'auto' }}>
                        {seller.bossUser ? seller.bossUser?.fullName : '-'}
                      </TableCell>
                      <TableCell className={'text-center'} style={{ width: 'auto' }}>
                        <Money value={seller.income ?? 0} />
                      </TableCell>
                      <TableCell className={'text-center'} style={{ width: 'auto' }}>
                        {seller.reservation}
                      </TableCell>
                    </>
                  );
                }}
                onRowClick={(seller) => navigateToProfile(seller)}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>

      <Show.When isTrue={downloadConciergeMutation?.isError}>
        <Toast
          message={
            downloadConciergeMutation?.error?.response?.status === 413
              ? 'La cantidad de registros a descargar ha superado el límite.'
              : `Ocurrio un error`
          }
          type='ERROR'
          onCallback={() => {
            downloadConciergeMutation.reset();
          }}
        />
      </Show.When>
    </>
  );
};

export default Sellers;
