import React from 'react';
import './subtitle.css';

const Subtitle = ({ border = true, text, endComponent, className = null, marginBottom = '' }) => {
  return (
    <div
      className={
        className
          ? className
          : `${
              border ? 'subtitle-border' : ''
            } flex-wrap container-subtitle d-flex justify-content-between align-items-center ${marginBottom}`
      }
    >
      <div>{text}</div>
      <div>{endComponent}</div>
    </div>
  );
};

export default Subtitle;
