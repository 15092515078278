import React from 'react';
import styles from './button.module.css';
import Loading from '../loading/loading';

export default function Button({
  text,
  size,
  type = 'submit',
  icon = false,
  iconColor = '#fff',
  typeIcon = null,
  onClick,
  className,
  disabled = false,
  dataDismiss,
  ariaLabel,
  width = null,
  heightConfig = null,
  fontConfig = null,
  heights = null,
  isClicked = false,
  isSelected = false,
  startIcon = null,
  loading = false,
}) {
  const sizeConfig = {
    large: {
      widthConfig: '100%',
      height: heights ? heights : '60px',
      fontSizeConfig: '18px',
    },
    medium: {
      widthConfig: `${width}`,
      height: heights ? heights : '35px',
      fontSizeConfig: '14px',
    },
    small: {
      widthConfig: `${width}`,
      height: heights ? heights : '30px',
      fontSizeConfig: '12px',
    },
    card: {
      widthConfig: `${width}`,
      height: heights ? heights : '70px',
      fontSizeConfig: '10px',
    },
    custom: {
      widthConfig: width,
      height: heightConfig,
      fontSizeConfig: fontConfig,
    },
  };

  const { height, widthConfig, fontSizeConfig } = sizeConfig[size];

  const buttonStyles = {
    width: widthConfig,
    height,
    fontSize: fontSizeConfig,
  };

  return (
    <button
      style={buttonStyles}
      className={`btn-general ${styles.buttonWellet} ${className ? className : null}  ${
        isSelected ? styles.btnSelected : isClicked ? styles.btnClickeable : ''
      } ${disabled ? styles.disabledOpacity : ''}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-dismiss={dataDismiss}
      aria-label={ariaLabel}
    >
      {startIcon && icon ? <span className='mr-2'>{typeIcon}</span> : null}
      {loading ? <Loading size='1.2rem' global={false} color='currentColor' /> : <p className={styles.text}>{text}</p>}
      {!startIcon && icon ? <span className='ml-2'>{typeIcon}</span> : null}
    </button>
  );
}
