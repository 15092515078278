import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  Badge,
  Button,
  IconButton,
  Loading,
  PerformanceWeekdays,
  Slider,
  Subtitle,
} from '../../uiComponents/common';
import { useNavigate } from 'react-router-dom';
import { Table, TableCell, TableRow } from '../../components';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';
import { getWeekdays } from '../../helpers/dates';
import { formatTimeFromMinutes } from '../../helpers/format';
import { format } from 'date-fns';
import { getAllPerformances } from '../../services';
import { useSelector } from 'react-redux';
import { usePagination } from '../../hooks';

const Performances = () => {
  const welletContext = useContext(WelletContext);
  const [showInactives, setShowInactives] = useState(false);
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const performancesQuery = useQuery({
    queryKey: ['performances', showInactives, organization.id],
    queryFn: () => getAllPerformances(welletContext, organization.id, null, null, showInactives),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => setTotalAndReset(data?.data?.length),
  });

  const columns =
    organization.id === 1
      ? [
          '',
          'Organization',
          'Name',
          'From Date',
          'To Date',
          'Weekdays',
          'Time',
          'Open Doors',
          'Close Doors',
          'Status',
        ]
      : [
          '',
          'Name',
          'From Date',
          'To Date',
          'Weekdays',
          'Time',
          'Open Doors',
          'Close Doors',
          'Status',
        ];

  return (
    <>
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Performances</h1>}
        endComponent={
          <Button
            size={'medium'}
            className={'btn-custom-primary'}
            text={'New Performance'}
            onClick={() => navigate('/new-performance')}
          />
        }
      />
      {performancesQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Slider
            className={'col-auto my-2'}
            label={'Only Actives'}
            onClick={() => setShowInactives(!showInactives)}
            defaultChecked={!showInactives}
          />
          <Table
            data={performancesQuery?.data?.data ?? []}
            columns={columns}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            renderRow={(performance, index) => (
              <>
                <TableCell style={{ width: '60px' }} type={'primary'}>
                  <IconButton
                    onClick={() => navigate(`/edit-performance/${performance.id}`)}
                    icon={<FwEditIcon />}
                  />
                </TableCell>
                {organization.id === 1 ? (
                  <TableCell type={'primary'}>{performance.organization.name}</TableCell>
                ) : null}
                <TableCell type={'primary'}>{performance.showName}</TableCell>
                <TableCell type={'primary'}>
                  {format(new Date(performance.from.split('+00:00')[0]), 'dd-MM-yyyy')}
                </TableCell>
                <TableCell type={'primary'}>
                  {performance.to
                    ? format(new Date(performance.to.split('+00:00')[0]), 'dd-MM-yyyy')
                    : '-'}
                </TableCell>
                <TableCell type={'primary'} style={{ width: '300px' }}>
                  <PerformanceWeekdays
                    performance={performance.weekdays.map((w) => getWeekdays(w))}
                  />
                </TableCell>
                <TableCell type={'primary'}>{performance.time}</TableCell>
                <TableCell type={'primary'}>
                  {formatTimeFromMinutes(performance.startBefore)}
                </TableCell>
                <TableCell type={'primary'}>
                  {' '}
                  {formatTimeFromMinutes(performance.closeAfter)}
                </TableCell>
                <TableCell type={'primary'}>
                  <Badge status={performance.isActive ? 'Active' : 'Inactive'} />
                </TableCell>
              </>
            )}
          />
        </>
      )}
    </>
  );
};

export default Performances;
