export const getReservationsLiveFeed = async (
  welletContext,
  limit = 20,
  skip = 0,
  marketId = null,
  orgId = null,
  filter,
  url = '/api/reservation/LiveFeed',
) => {
  return await welletContext.apis.admin.post(url, {
    organizationId: marketId !== orgId ? orgId : null,
    marketSetupId: marketId,
    from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString(),
    to: null,
    status: filter ? [filter] : [],
    limit,
    skip,
  });
};
export const getReservationsHistory = async (
  welletContext,
  limit = 20,
  skip = 0,
  marketId = null,
  orgId = null,
  filter,
  dates = {
    from: null,
    to: null,
  },
  user = null,
  dateType = 'reservation',
  userId = null,
  rpId = null,
  showId = null,
  bossIds,
  orderBy = null,
  orderType = null,
  consumptionAmount = null,
  consumptionFilterEnum = [],
  reservationType = [],
  citiesIds = [],
  url = '/api/reservation/LiveFeed',
) => {
  const body = {
    organizationId: marketId !== orgId ? orgId : null,
    marketSetupId: marketId,
    from: dates.from,
    to: dates.to,
    status: filter ? filter : [],
    limit,
    skip,
    filterField: user,
    dateType,
    userId,
    rpId,
    showId,
    bossIds: bossIds ? bossIds : [],
    sortBy: orderBy,
    columnFilter: orderType,
    consumptionAmount: consumptionAmount ? consumptionAmount : 0,
    consumptionFilterEnum: consumptionFilterEnum.length > 0 ? consumptionFilterEnum[0] : null,
    reservationType: reservationType.length > 0 ? reservationType : null,
    citiesIds: citiesIds ? citiesIds : [],
  };
  return await welletContext.apis.admin.post(url, body);
};
export const getPendingReservations = async (
  welletContext,
  limit = 20,
  skip = 0,
  marketId = null,
  orgId = null,
  dates = {
    from: null,
    to: null,
  },
  user = null,
  status,
  url = '/api/reservation/pending/LiveFeed',
) => {
  const body = {
    organizationId: marketId !== orgId ? orgId : null,
    marketSetupId: marketId,
    from: dates.from,
    to: dates.to,
    limit,
    skip,
    status,
    filterField: user,
  };
  return await welletContext.apis.admin.post(url, body);
};
export const getReservationById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/reservation/${id}`);
};
export const getReservationAuditById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/reservation/audit/${id}`);
};
export const postStatusReservation = async (welletContext, organizationId, status) => {
  const url = `api/Orders/changeBookingStatus/${organizationId}`;
  let currentStatus = status ? 'CONFIRMATION_RESERVATION' : 'CANCELED';
  const body = {
    status: currentStatus,
  };
  return await welletContext.apis.admin.put(url, body);
};
export const postConfirmRejectReservation = async (welletContext, body) => {
  return await welletContext.apis.tickets.post(`admin/reservations/bo/setstatus`, body);
};
export const patchConfirmRejectReservation = async (welletContext, reservationId) => {
  return await welletContext.apis.admin.patch(
    `api/reservation/view-cancelled-Reservation/${reservationId}`,
  );
};
export const patchViewDeposit = async (welletContext, reservationId) => {
  return await welletContext.apis.admin.patch(
    `api/reservation/view-deposit-Reservation/${reservationId}`,
  );
};
export const getCurrentReservationReviewing = async (welletContext, save, reservationId) => {
  const body = {
    save: save,
    reservationId: reservationId,
  };
  return await welletContext.apis.admin.post(`/api/Reservation/assign-reservation`, body);
};
export const getReservatiionPendingsApprove = async (
  welletContext,
  marketId,
  status,
  limit = 20,
  skip = 0,
) => {
  const body = {
    marketSetupId: marketId,
    from: null,
    to: null,
    status: [status],
    limit: limit,
    skip: skip,
  };
  return await welletContext.apis.admin.post(`/api/reservation/commission`, body);
};

export const getCheckinHistory = async (
  welletContext,
  limit = 20,
  skip = 0,
  marketId = null,
  filter,
  field,
  organizationId = null,
) => {
  const body = {
    marketSetupId: marketId,
    status: filter ? [filter] : [],
    filterField: field,
    organizationId,
    limit,
    skip,
  };
  return await welletContext.apis.admin.post('/api/reservation/checkIn/livefeed', body);
};

export const putConfirmPresent = async (welletContext, id) => {
  return await welletContext.apis.admin.put(`api/reservation/${id}/noshow`);
};

export const downloadReservations = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/ExcelReport/reservations`, body, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getModifyReservation = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/reservation/request/${id}`);
};

export const putAddCheck = async (welletContext, body) => {
  return await welletContext.apis.tickets.put(`admin/reservations/check`, body);
};

export const postAddNotes = async (welletContext, url, body) => {
  return await welletContext.apis.admin.post(`/api/${url}/add-comment`, body);
};

export const getReservationsTransfer = async (welletContext, restaurantId, userId) => {
  return await welletContext.apis.admin.get(
    `api/reservation/restaurant/${restaurantId}/concierge/${userId}`,
  );
};

export const getPdf = async (welletContext, url) => {
  return await welletContext.apis.tickets.get(url);
};
