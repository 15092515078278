import React from 'react';
import { Button } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';

const PaginationByButtons = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  className = 'd-flex align-items-center justify-content-between w-100',
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.pagination' });
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  return (
    <div className={className}>
      <Button
        disabled={currentPage === 1}
        size={'custom'}
        width={'auto'}
        fontConfig={'14px'}
        heightConfig={'36px'}
        onClick={() => onPageChange(currentPage - 1)}
        className='btn-pagination-wellet'
        text={t('before')}
      />
      <div className='pagination-text'>
        {totalItems > 0 ? `${t('page')} ${currentPage} ${t('of')} ${totalPages}` : null}
      </div>
      <div>
        <Button
          size={'custom'}
          width={'auto'}
          fontConfig={'14px'}
          heightConfig={'36px'}
          disabled={currentPage * itemsPerPage >= totalItems}
          onClick={() => onPageChange(currentPage + 1)}
          className='ml-2 btn-pagination-wellet'
          text={t('after')}
        />
      </div>
    </div>
  );
};

export default PaginationByButtons;
