import React from 'react';
import './table.css';

const TableHead = ({ children, className, style, index }) => {
  return (
    <th
      className={className}
      style={style}
      tabIndex='0'
      aria-controls='DataTables_Table_0'
      rowSpan='1'
      colSpan='1'
      key={index}
    >
      {children}
    </th>
  );
};

export default TableHead;
