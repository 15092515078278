import React, { useContext, useState } from 'react';
import { Table, TableRow, TableCell, PageHeader, Show } from '../../components';
import { Alert, Button, Loading, Money, PageError, Subtitle } from '../../uiComponents/common';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useCreateBreadCrumbs, useGetBatch, usePagination, useUser } from '../../hooks';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import { paymentBatchBadge } from '../../helpers/status';
import { hasAllowedRoles } from '../../helpers/roles';
import ModalPayment from './modalPayment';
import { useTranslation } from 'react-i18next';

const PaymentsActions = () => {
  const { t: translate } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.payments' });

  const organization = useSelector((state) => state.app.currentOrganization);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const location = useLocation();
  const navigate = useNavigate();
  const { roles } = useUser();

  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // Main API Query
  const batchQuery = useGetBatch(
    organization.marketId,
    limit,
    skip,
    ['APPROVED', 'REVIEWED'],
    {
      refetchOnMount: true,
      onSuccess: (data) => setTotalAndReset(data?.data?.totalRegisters),
    },
    true,
  );

  const navigateToBatch = (id) => {
    navigate(`/payment-summary/${id}`, { state: location.pathname + location.search });
  };

  const headerAdmin = [
    { title: t('table.creationDate'), value: '', filterable: false },
    { title: t('table.totalImport'), value: '', filterable: false },
    { title: t('table.paymentLot'), value: '', filterable: false },
    { title: '', value: '', filterable: false },
    { title: t('table.state'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs([{ name: t('title') }]);

  // we are not showing warnings for now
  const renderWarningIcon = (batch) => {
    return null;
    // if (batch.countErrorPayments == 0 && batch.countInsufficientFundsPayments == 0) {
    //   return null;
    // }

    // let text = '';
    // if (batch.countErrorPayments > 0) {
    //   const paymentText = batch.countErrorPayments > 1 ? 'pagos' : 'pago';
    //   text = `${batch.countErrorPayments} ${paymentText} con error.`;
    // }

    // if (batch.countInsufficientFundsPayments > 0) {
    //   const paymentText = batch.countInsufficientFundsPayments > 1 ? 'pagos' : 'pago';
    //   if(text.length > 0) text += ' ';
    //   text += `Insuficientes fondos para ${batch.countInsufficientFundsPayments} ${paymentText}.`;
    // }

    // return (
    //   <Tooltip text={text}>
    //     <WarningIcon color={'#ffb46f'} className='ml-2' />
    //   </Tooltip>
    // )
  };
  return (
    <>
      <PageHeader
        border={false}
        title={t('title')}
        endComponent={
          <Show.When
            isTrue={hasAllowedRoles(roles, [
              'superadministrator',
              'marketadmin',
              'auditor',
              'finance',
            ])}
          >
            <ModalPayment callback={batchQuery.refetch} />
          </Show.When>
        }
      />
      <Show>
        <Show.When isTrue={batchQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={batchQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <>
            <Table
              data={batchQuery?.data?.data?.items ?? []}
              hasPagination={false}
              totalP={totalItems}
              borderTop={false}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              columns={headerAdmin}
              renderRow={(batch, index) => {
                const { dateTime } = formatDateTranslation(batch.reviewedAt, language, 'small');
                return (
                  <>
                    <TableCell>
                      <span className='sentences-capitalize'>{dateTime}</span>
                    </TableCell>
                    <TableCell className='font-weight-bold'>
                      <Money value={batch.amount} currencyCode={batch.currency} />
                    </TableCell>
                    <TableCell style={{ width: '180px' }}>{batch.description}</TableCell>
                    <TableCell className='font-weight-bold'>
                      <Link to={`/payment-summary/${batch.id}`}>{t('link')}</Link>
                    </TableCell>
                    <TableCell>
                      {
                        <div className='d-flex align-items-center'>
                          {paymentBatchBadge(
                            batch.status,
                            batch.arePaymentsBeingExecuted,
                            translate,
                          )}
                          {renderWarningIcon(batch)}
                        </div>
                      }
                    </TableCell>
                  </>
                );
              }}
              onRowClick={(batch) => navigateToBatch(batch.id)}
            />
          </>
        </Show.Else>
      </Show>
    </>
  );
};

export default PaymentsActions;
