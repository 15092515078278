import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { fetchsOrganizations } from '../../services';
import { defaultQueryOptionsFilter } from '../../utils/defaultQueryOptions';

export const useOrganizations = (userId, options) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptionsFilter,
    ...options,
  };

  return useQuery({
    queryKey: ['organizations', userId],
    queryFn: () => fetchsOrganizations(welletContext),
    ...queryOptions,
  });
};
