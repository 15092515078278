export const getAllCategories = async (welletContext, skip, limit) => {
  const body = {
    skip,
    limit,
  };
  return await welletContext.apis.admin.post(`/api/Category/all`, body);
};

export const getCategoriesSimple = async (welletContext) => {
  return await welletContext.apis.admin.get(`api/category`);
};

export const getSubcategoriesSimple = async (welletContext, categoryId) => {
  return await welletContext.apis.admin.get(`api/category/${categoryId}/subcategory`);
};

export const getCategoriesAndSubcategories = async (welletContext) => {
  return await welletContext.apis.admin.get(`api/CategoryShow/subcategories`);
};

export const getAllSubcategories = async (welletContext) => {
  return await welletContext.apis.admin.get(`api/SubCategoryShow/all`);
};

export const getCategoryById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/Category/${id}`);
};

export const getSubcategoriesByIds = async (welletContext, ids) => {
  return await welletContext.apis.admin.post(`api/categoryShow/filter`, {
    CategoriesIds: ids,
  });
};

export const postCategoryAndSubcategory = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/Category`, body);
};
export const putCategory = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`/api/Category`, body);
};
export const postSubcategory = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/subCategory`, body);
};
export const putSubcategory = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`/api/subCategory`, body);
};
export const deleteCategory = async (welletContext, categoryId) => {
  return await welletContext.apis.admin.delete(`api/category?categoryId=${categoryId}`);
};
export const deleteSubCategory = async (welletContext, subcategoryId) => {
  return await welletContext.apis.admin.delete(`api/subCastegory?subcategoryId=${subcategoryId}`);
};
export const orderCategory = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/CategoryShow/priority`, body);
};
