import React, { useEffect, useState } from 'react';
import Button from '../button/button';
import Popup from 'reactjs-popup';
import { FwAddIcon } from '../../icons';
import Input from '../input/input';
import { useTranslation } from 'react-i18next';

const AmountFilter = ({ filterName, filterOptions = [], callback, initialValue = '' }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.amountFilter' });
  const [localFilterOptions, setLocalFilterOptions] = useState([]);
  const [amount, setAmount] = useState(initialValue);
  const [isApplying, setIsApplying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const renderSelectedOption = () => {
    const selectedLabels = localFilterOptions.filter((option) => option.isActive);
    if (selectedLabels.length > 0 && amount !== '') return `${selectedLabels[0].label} $${amount}`;
    return '';
  };

  const handleCheckboxChange = (option) => {
    const updatedOptions = localFilterOptions.map((item) => {
      if (item.value === option) {
        return { ...item, isActive: !item.isActive };
      }
      return { ...item, isActive: false };
    });
    setLocalFilterOptions(updatedOptions);
  };

  const handleApplyClick = (close) => {
    if (amount !== '' && localFilterOptions?.filter((option) => option.isActive)?.length > 0) {
      setIsApplying(true);
      callback && callback({ filter: localFilterOptions, amount });
    }
    close();
  };

  const handleClear = (close) => {
    setAmount('');
    const newLocalState = localFilterOptions.map((item) => ({ ...item, isActive: false }));
    setLocalFilterOptions(newLocalState);
    callback && callback({ filter: newLocalState, amount });
    close();
  };

  useEffect(() => {
    if (!isApplying && !isOpen) {
      setLocalFilterOptions([...filterOptions]);
    }
  }, [isApplying, isOpen]);

  useEffect(() => {
    setLocalFilterOptions(filterOptions);
  }, [filterOptions]);

  return (
    <>
      <Popup
        keepTooltipInside={'.App'}
        trigger={
          <div className='btnFilterDashed text-nowrap'>
            <div
              className='d-flex mr-1 align-items-center justify-content-center'
              style={{
                borderRadius: '50%',
                border: '1px solid var(--color-border-table)',
                paddingLeft: '2px',
                width: '22px',
                height: '22px',
                background: '#F9FAFB',
              }}
            >
              <FwAddIcon color='#667085' fontSize='10px' />
            </div>
            <p className='p-0 m-0'>{filterName}</p>
            {localFilterOptions.some((option) => option.isActive) && amount !== '' && (
              <span className='mx-1'>|</span>
            )}
            <p className='p-0 m-0 option-selected text-truncate'>{renderSelectedOption()}</p>
          </div>
        }
        nested
        arrow={false}
        onOpen={() => {
          setIsApplying(false);
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {(close) => (
          <div className={`filter-modal isOpen`} onClick={(e) => e.stopPropagation()}>
            <Input
              icon={<div style={{ fontSize: '12px' }}>$</div>}
              type={'text'}
              name={'number'}
              value={amount}
              autocomplete="off"
              onChangeValue={(e) => {
                const value = e.target.value;
                if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
                  setAmount(value);
                }
              }}
              placeholder={t('search')}
              min={0}
              max={999999}
            />
            <h4 className='ctm-text-medium'>{t('title')}</h4>
            <ul className='d-flex flex-column m-0 p-0 content-filter'>
              {localFilterOptions.map((option) => (
                <label key={option.value}>
                  <input
                    type='checkbox'
                    value={option.value}
                    onChange={() => handleCheckboxChange(option.value)}
                    checked={option.isActive}
                    className='mr-1'
                  />
                  {option.label}
                </label>
              ))}
            </ul>
            <div className='w-100 d-flex justify-content-center pt-2'>
              <Button
                size={'small'}
                onClick={() => handleClear(close)}
                className='btn-wellet-secondary'
                text={t('delete')}
              />
              <Button
                size={'small'}
                onClick={() => handleApplyClick(close)}
                className='btn-wellet-primary  ml-2'
                text={t('apply')}
              />
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default AmountFilter;
