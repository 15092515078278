import { useContext } from 'react';
import { WelletContext } from '../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getStates } from '../services';
import { defaultQueryOptionsFilter } from '../utils/defaultQueryOptions';

export const useGetStates = (params = null, options, propsArray = []) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptionsFilter,
    ...options,
  };

  return useQuery(
    ['state-market', params, ...propsArray],
    () => getStates(welletContext, params),
    queryOptions,
  );
};
