import React from 'react';
import './buttonOutline.css'

const ButtonOutline = ({ text, icon, className = '', onClick }) => {
  return (
    <div className={'btnOutline d-flex align-items-center ' + className} onClick={onClick}>
      {icon ? (
        <span className='mr-1'>
          {icon}
        </span>
      ) : (
        <></>
      )}
      <p className='p-0 m-0'>{text}</p>
    </div>
  );
};

export default ButtonOutline;
