import { useTranslation } from 'react-i18next';
import React from 'react';
import { ConfigCard, Subtitle } from '../../../../uiComponents/common';
import {
  CallCenterIcon,
  DocumentIcon,
  FAQIcon,
  FwListIcon,
  PadlockIcon,
  SettingsIcon,
} from '../../../../uiComponents/icons';
import MembershipIcon from '../../../../uiComponents/icons/membershipIcon';
import { useSelector } from 'react-redux';
import { useUser } from '../../../../hooks';
import { hasAllowedRoles } from '../../../../helpers/roles';

const MainConfigurations = () => {
  const { selectedOrganization } = useSelector((state) => state.app);
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.configurations',
  });
  const { roles } = useUser();

  const canOnlySuperAndMarket = hasAllowedRoles(roles, ['superadministrator', 'marketadmin']);
  const canOnlySuper = hasAllowedRoles(roles, ['superadministrator']);
  return (
    <>
      <Subtitle text={<h3 className='fw-600 mb-3'>{t('title')}</h3>} border={false} />
      <div className='row'>
        {canOnlySuperAndMarket ? (
          <>
            <div className='col-12 col-md-6 col-lg-4 my-2'>
              <ConfigCard
                title={t('mainConfig.cards.teamAndSecurity.title')}
                text={t('mainConfig.cards.teamAndSecurity.text')}
                icon={<PadlockIcon color='#675dff' size='20px' />}
                link='/settings/team-and-security'
              />
            </div>
            {selectedOrganization?.isMarket ? (
              <>
                <div className='col-12 col-md-6 col-lg-4 mt-24'>
                  <ConfigCard
                    title={t('entityConfig.company.cards.policies.title')}
                    text={t('entityConfig.company.cards.policies.text')}
                    icon={<DocumentIcon color='#675dff' size='20px' />}
                    link='/settings/usage-policies'
                  />
                </div>
                <div className='col-12 col-md-6 col-lg-4 mt-24'>
                  <ConfigCard
                    title={t('entityConfig.company.cards.membership.title')}
                    text={t('entityConfig.company.cards.membership.text')}
                    icon={<MembershipIcon color='#675dff' size='20px' />}
                    link='/settings/memberships'
                  />
                </div>
                <div className='col-12 col-md-6 col-lg-4 mt-24'>
                  <ConfigCard
                    title={t('entityConfig.company.cards.callCenter.title')}
                    text={t('entityConfig.company.cards.callCenter.text')}
                    icon={<CallCenterIcon color='#675dff' size='20px' />}
                    link={`/settings/callcenter/${selectedOrganization.marketId}`}
                  />
                </div>
                <div className='col-12 col-md-6 col-lg-4 mt-24'>
                  <ConfigCard
                    title={t('entityConfig.company.cards.faq.title')}
                    text={t('entityConfig.company.cards.faq.text')}
                    icon={<FAQIcon color='#675dff' size='20px' />}
                    link={`/settings/faq`}
                  />
                </div>
                {process.env.REACT_APP_ENVIRONMENT === 'production' ? null :
                <div className='col-12 col-md-6 col-lg-4 mt-24'>
                  <ConfigCard
                    title={t('entityConfig.company.cards.categories.title')}
                    text={t('entityConfig.company.cards.categories.text')}
                    icon={<FwListIcon color='#675dff' size='20px' />}
                    link={`/settings/categories`}
                  />
                </div>}
                {canOnlySuper ? (
                  <div className='col-12 col-md-6 col-lg-4 mt-24'>
                    <ConfigCard
                      title={t('entityConfig.company.cards.setting.title')}
                      text={t('entityConfig.company.cards.setting.text')}
                      icon={<SettingsIcon color='#675dff' size='20px' />}
                      link={`/settings/setting`}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
};

export default MainConfigurations;
