import React, { useRef } from 'react';
import InputBase from './inputBase';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../button/button';
import { FwUploadIcon, FwTrashIcon, FwSearchIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const InputVideo = ({
  id,
  name,
  placeholder,
  label,
  showDrag = true,
  className = '',
  initialValue = null,
  isRequired = false,
  maxSize = 524288000,
}) => {
  const validationRules = {};
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const fileInputRef = useRef(null);
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleRemoveFile = () => {
    fileInputRef.current.value = '';
    setValue(name, null);
  };
  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'video/mp4') {
        // if (file.size <= maxSize) {
        clearErrors(name);
        previewFile(file)
          .then((videoUrl) => {
            setValue(name, { url: videoUrl, name: file.name });
          })
          .catch((error) => {
            console.error('Error al cargar el video:', error);
          });
        // }
        //  else {
        //   setError(name, {
        //     type: 'custom',
        //     message: t('maxSize'),
        //   });
        // }
      } else {
        setError(name, {
          type: 'custom',
          message: t('videoInvalid'),
        });
      }
    }
  };

  const previewFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  if (isRequired) {
    validationRules.required = t('required');
  }

  return (
    <div className={`input-video-container ${className}`}>
      <label style={{ color: 'var(--color-text)' }} className='mb-1'>
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={validationRules}
        render={({ field: { value, onChange } }) => (
          <>
            {showDrag && (
              <div
                className='file-dropzone mb-2'
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  const droppedFile = e.dataTransfer.files[0];
                  if (droppedFile.type === 'video/mp4') {
                    clearErrors(name);
                    previewFile(droppedFile)
                      .then((videoUrl) => {
                        setValue(name, { url: videoUrl, name: droppedFile.name });
                      })
                      .catch((error) => {
                        console.error('Error al cargar el video:', error);
                      });
                  }
                  // else if (droppedFile.size >= maxSize) {
                  //   setError(name, {
                  //     type: 'custom',
                  //     message: t('maxSize'),
                  //   });
                  // }
                  else {
                    setError(name, {
                      type: 'custom',
                      message: t('videoInvalid'),
                    });
                  }
                }}
              >
                {value && value.url ? (
                  <video width='100%' height='100%' controls>
                    <source src={value.url} type='video/mp4' />
                    {t('notSupported')}
                  </video>
                ) : (
                  <p>{t('dragVideo')}</p>
                )}
              </div>
            )}
            <input
              type='file'
              id={id}
              name={name}
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileSelection}
              accept='video/mp4'
            />
            <InputBase
              errors={errors}
              icon={<FwUploadIcon />}
              type='text'
              name={name}
              value={value?.name || ''}
              label={''}
              readOnly={true}
              placeholder={placeholder}
              disabled
              endComponent={
                <div className='file-btn d-flex'>
                  {value ? (
                    <Button
                      type='button'
                      size={'medium'}
                      heights={'44px'}
                      className={'remove-file'}
                      text={<FwTrashIcon />}
                      onClick={handleRemoveFile}
                    />
                  ) : null}
                  <Button
                    type='button'
                    size={'medium'}
                    heights={'44px'}
                    text={<FwSearchIcon />}
                    onClick={handleFileInputClick}
                  />
                </div>
              }
            />
            {errors[name] && (
              <div className='d-flex align-items-center error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InputVideo;