import React from 'react';
import './loading.css';
const Loading = ({ text, global = true, opacity = false, color = 'var(--color-primary-blue)', size = '2rem' }) => {
  return (
    <div className={`${global ? 'loaderDiv' : ''} ${opacity ? 'opacityDiv' : ''} text-center`}>
      <div className='spinner-border' role='status' style={{ color: color, width: size, height: size }}>
        <span className='sr-only' />
      </div>
      {text ? <span className='mt-1 d-block'>{text}</span> : null}
    </div>
  );
};

export default Loading;
