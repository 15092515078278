import React, { useContext } from 'react';
import { PageHeader, Show, Table, TableCell } from '../../components';
import { Loading, Money, PageError } from '../../uiComponents/common';
import { useCreateBreadCrumbs, usePagination } from '../../hooks';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getAccounts } from '../../services';
import { accountsStatus } from '../../helpers/status';
import { Link } from 'react-router-dom';

const Accounts = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.accounts' });

  const welletContext = useContext(WelletContext);

  const organization = useSelector((state) => state.app.selectedOrganization);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // Main API Query
  const accountsQuery = useQuery({
    queryKey: ['accounts', organization.marketId, limit, skip],
    queryFn: () =>
      getAccounts(
        welletContext, // wellet context
        organization.marketId,
        {
          limit, // limit
          skip, // skip
        },
      ),
    onSettled: (data) => {
      setTotalAndReset(data?.data?.totalRegisters ? data?.data?.totalRegisters : 0);
    },
    ...defaultQueryOptions,
    refetchInterval: false,
  });

  const headerAdmin = [
    { title: t('table.name'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
    { title: t('table.balance'), value: '', filterable: false },
    { title: '', value: '', filterable: false },
  ];

  useCreateBreadCrumbs([{ name: t('title'), path: '/accounts' }]);

  return (
    <>
      <PageHeader title={t('title')} border={false} />
      <Show>
        <Show.When isTrue={accountsQuery.isLoading}>
          <Loading />
        </Show.When>

        <Show.Else>
          <Table
            data={accountsQuery?.data?.data?.items}
            currentPage={currentPage}
            header='grey'
            hasPagination={false}
            onPageChange={(page) => handlePageChange(page)}
            totalP={totalItems}
            columns={headerAdmin}
            rowClass='not-hover'
            renderRow={(account) => {
              const isNegative = account.totalAmount < 0
              return (
                <>
                  <TableCell className={'fw-500'} style={{ width: '300px' }}>
                    {account.name ?? '-'}
                  </TableCell>
                  <TableCell style={{ width: '150px' }}>
                    {accountsStatus(
                      isNegative ? 'NEGATIVE' : 'POSITIVE',
                      isNegative ? t('status.negative') : t('status.positive'),
                    )}
                  </TableCell>
                  <TableCell style={{ width: '150px' }} type={'primary'}>
                    <div className='fw-600'>
                      {isNegative ? (
                        <>
                          - <Money value={Math.abs(account.totalAmount)} />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {isNegative ? <Link to={`/accounts/${account.id}`}>{t('link')}</Link> : null}
                  </TableCell>
                </>
              );
            }}
          />
        </Show.Else>
      </Show>
    </>
  );
};

export default Accounts;
