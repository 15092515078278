import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import {
  AffiliateCard,
  Button,
  ContanierSection,
  Modal,
  Money,
  Subtitle,
  Toast,
  UserImage,
} from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import usePagination from '../../hooks/usePagination';
import { formatMinutes, getCancelledBy } from '../../helpers/format';
import {
  getReservationAuditById,
  postDispute,
  approveReservation,
  putAdjustment,
  postApproveDispute,
  postRejectDispute,
} from '../../services';
import { formatDateTranslation } from '../../helpers/dates';
import HalfCircleProgressBar from '../../components/halfCircleProgressBar/halfCircleProgressBar';
import Badge from '../../uiComponents/common/badge/badge';

import './reservationDetail.css';

import {
  Comments,
  ConciergeDetail,
  InfoRenderer,
  Show,
  TableCell,
  TableRow,
  UserComments,
} from '../../components';
import OutlineTable from '../../components/table/outlineTable';
import MercadoPago from '../../uiComponents/icons/mercadoPago';
import ModalComment from '../../screens/paymentBatch/modalComment';
import ModalDispute from '../../screens/paymentBatch/modalDispute';
import ModalAdjustment from '../../screens/paymentBatch/modalAdjustment';
import CommissionHeader from './commissionHeader';
import ReservationHeader from './reservationHeader';
import Notes from './notes';
import ReservationProductZone from '../../screens/reservations/reservationProductZone';
import { useTranslation } from 'react-i18next';
import { getUserRole } from '../../helpers/roles';
import { WarningModernIcon } from '../../uiComponents/icons';
import TablePdf from './tablesPdf';
import ReservationInfo from '../../screens/reservations/request/reservationInfo';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Details = ({ id, mode }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.details' });
  const { t: translate } = useTranslation();

  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);

  // States
  const [openDispute, setOpenDispute] = useState(false);
  const [openDisputeApprove, setOpenDisputeApprove] = useState(false);
  const [openDisputeReject, setOpenDisputeReject] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openAdjust, setOpenAdjust] = useState(false);

  const { getClientTranslation } = useGenericTranslation();

  // Pagination
  const { currentPage, handlePageChange, totalItems, setTotalAndReset } = usePagination(
    1,
    1,
    false,
  );

  // Main Query API
  const reservationIdQuery = useQuery({
    queryKey: ['reservation', id],
    queryFn: () => getReservationAuditById(welletContext, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.externalPayments?.length);
    },
  });

  // Functions
  const getReservationEvent = (event) => {
    switch (event) {
      case 'EXPIRED':
        return t('timeline.reservationEvent.expired');
      case 'CREATED':
        return t('timeline.reservationEvent.created');
      case 'CONFIRMED':
        return t('timeline.reservationEvent.confirmed');
      case 'REJECTED':
        return t('timeline.reservationEvent.rejected');
      case 'CHECKIN':
        return getClientTranslation('checkin')
      case 'NO_SHOW':
        return t('timeline.reservationEvent.noShow');
      case 'NO_SHOW_MANUAL':
        return t('timeline.reservationEvent.noShow');
      case 'CANCELLED':
        return t('timeline.reservationEvent.cancelled');
      case 'TABLE_CLOSED':
        return t('timeline.reservationEvent.tableClosed');
      case 'COMMISSION_AUDIT_DISPUTED':
        return t('timeline.reservationEvent.commissionAuditDisputed');
      case 'COMMISSION_AUDIT_REJECTED':
        return t('timeline.reservationEvent.commissionAuditRejected');
      case 'COMMISSION_ADJUSTMENT':
        return t('timeline.reservationEvent.commissionAdjustment');
      case 'COMMISSION_AUDIT_APPROVED':
        return t('timeline.reservationEvent.commissionAuditApproved');
      case 'PAYMENT_EXECUTED':
        return t('timeline.reservationEvent.paymentExecuted');
      case 'PAYMENT_ACCREDITED_CASH':
        return t('timeline.reservationEvent.paymentAccreditedCash');
      case 'PAYMENT_ACCREDITED_CERTIFICATE':
        return t('timeline.reservationEvent.paymentAccreditedCertificate');
      case 'PAYMENT_ACCREDITED_MERCADOPAGO':
        return t('timeline.reservationEvent.paymentAccreditedMercadoPago');
      case 'MANUAL_CHECK_ADDED':
        return t('timeline.reservationEvent.manualCheckAdded');
      case 'WIRE_SETTLED':
        return t('timeline.reservationEvent.wireSettled');
      case 'DEPOSIT_PAID':
        return t('timeline.reservationEvent.depositPaid');
      case 'DEPOSIT_VIEWED':
        return t('timeline.reservationEvent.depositViewed');
      case 'REFUNDED':
        return t('timeline.reservationEvent.refunded');
      case 'CANCELLED_EXPIRATION':
        return t('timeline.reservationEvent.cancelledExpiration');
      case 'REJECTED_EXPIRATION':
        return t('timeline.reservationEvent.rejectedExpiration');
      case 'CANCELLED_BY_CONCIERGE':
        return t('timeline.reservationEvent.cancelledConcierge');
      case 'CANCELLED_BY_CUSTOMER':
        return t('timeline.reservationEvent.cancelledCustomer');
      case 'CANCELLED_MISSING_PAYMENT':
        return t('timeline.reservationEvent.cancelledMissingPayment');
      case 'PAYMENT_DISPUTED':
        return t('timeline.reservationEvent.paymentDisputed');
      case 'PAYMENT_DISPUTED_NOT_ACCEPTED':
        return t('timeline.reservationEvent.disputedNotAccepted');
      case 'PAYMENT_DISPUTED_ACCEPTED':
        return t('timeline.reservationEvent.disputedAccepted');
      case 'PAYMENT_DISPUTED_LOST':
        return t('timeline.reservationEvent.disputedLost');
      case 'PAYMENT_DISPUTED_WINNED':
        return t('timeline.reservationEvent.disputedWinned');
      case 'CANCEL_COMMISSION':
        return t('timeline.reservationEvent.refundCommission');
    }
  };
  const getRisk = (risk) => {
    switch (risk) {
      case 'LOW':
        return t('timeline.risk.low');
      case 'MEDIUM':
        return t('timeline.risk.medium');
      case 'HIGH':
        return t('timeline.risk.high');
    }
  };
  const getRiskColor = (risk) => {
    switch (risk) {
      case 'LOW':
        return 'green';
      case 'MEDIUM':
        return 'yellow';
      case 'HIGH':
        return 'red';
    }
  };
  const renderRiskItem = (risk) => {
    if (risk.riskType === 'RESERVATION_TIME') {
      const descriptionKey = risk.minutesBetweenReservationAndTableOpen > 0 ? 'passed' : 'before';
      return t(`timeline.riskItems.reservationTime.description.${descriptionKey}`, {
        minutes: formatMinutes(Math.abs(risk.minutesBetweenReservationAndTableOpen), translate),
      });
    } else if (risk.riskType === 'HAS_DISCOUNT') {
      return t('timeline.riskItems.hasDiscount.description', {
        discountName: risk.discountName,
        discountAmount: risk.discountAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        discountPercentage: risk.discountPercentage,
      });
    } else if (risk.riskType === 'MULTIPLE_PAYMENTS') {
      return t('timeline.riskItems.multiplePayments.description', {
        paymentsCount: risk.paymentsCount,
        timeSpan: formatMinutes(risk.paymentsTimeSpanMinutes, translate),
      });
    } else if (risk.riskType === 'MANUAL_ADJUSTMENT') {
      return t('timeline.riskItems.manualAdjustment.description');
    } else if (risk.riskType === 'COMMISSION_AMOUNT') {
      return t('timeline.riskItems.commissionAmount.description');
    }

    return '';
  };
  const renderTimelineUser = (timelineEvent) => {
    if (!timelineEvent) return null;

    if (timelineEvent.reservationEvent === 'PAYMENT_ACCREDITED_MERCADOPAGO') {
      return (
        <div className='row flex-nowrap'>
          <div className='col-auto pr-0'>
            <div className='profile-pic'>
              <MercadoPago size={'40px'} />
            </div>
          </div>
          <div className='col'>
            <div className='font-weight-semibold'>{t('timeline.paymentAccount')}</div>
            <div className='dark-grey'>{timelineEvent.comments}</div>
          </div>
        </div>
      );
    } else if (
      !timelineEvent.user ||
      !timelineEvent?.user?.id ||
      timelineEvent.reservationEvent === 'EXPIRED' ||
      timelineEvent.reservationEvent === 'REJECTED_EXPIRATION' ||
      timelineEvent.reservationEvent === 'CANCELLED_EXPIRATION'
    ) {
      return null;
    }

    return (
      <AffiliateCard
        name={getUserRole(timelineEvent.user.role, translate)}
        url={timelineEvent?.user?.profilePicUrl}
        company={timelineEvent?.user?.fullName}
        isVerified={timelineEvent?.user?.isVerified}
        size='40px'
      />
    );
  };

  // Mutations Querys
  const disputeMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        comments: data?.disputeComment,
        userComments: data?.userComment,
      };
      return postDispute(welletContext, reservation.orderId, body);
    },
  });
  const disputeApproveMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        comments: data?.comment,
        userComments: data?.userComment,
      };
      return postApproveDispute(welletContext, reservation.orderId, body);
    },
  });
  const disputeRejectMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        comments: data?.comment,
        userComments: data?.userComment,
      };
      return postRejectDispute(welletContext, reservation.orderId, body);
    },
  });
  const approveMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        comments: '',
        userComments: '',
      };
      return approveReservation(welletContext, reservation.orderId, body);
    },
  });
  const adjustmentMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        comments: data?.comment,
        amount: data?.amount,
        userId: reservation?.seller?.id,
        orderId: reservation.orderId,
      };
      return putAdjustment(welletContext, body);
    },
  });

  // Data
  const reservation = reservationIdQuery.data?.data;
  const uniqueLocationNames = new Set();

  reservation?.externalPayments?.forEach((payment) => {
    uniqueLocationNames.add(payment.locationName);
  });

  return reservation ? (
    <>
      <div className='container-page color-body'>
        {/* ---------- HEADERS ---------- */}
        <Show>
          <Show.When isTrue={mode === 'reservation'}>
            <ReservationHeader
              mode={mode}
              referenceCode={reservation.referenceCode}
              customer={reservation.customerName}
              status={
                reservation.commissions && reservation.commissions.length === 0
                  ? reservation.status === 'CANCELLED'
                    ? getCancelledBy(reservation.cancelledFrom)
                    : reservation.status
                  : reservation.auditStatus
              }
              reservation={reservation}
            />
          </Show.When>
          <Show.Else>
            <CommissionHeader
              reservation={reservation}
              onOpenDispute={() => setOpenDispute(true)}
              onOpenDisputeReject={() => setOpenDisputeReject(true)}
              onOpenDisputeApprove={() => setOpenDisputeApprove(true)}
              onOpenApprove={() => setOpenApprove(true)}
            />
          </Show.Else>
        </Show>
        {/* ---------- DETALLE RESERVA ---------- */}
        <ReservationInfo reservation={reservation} />
        {/* ---------- ANALISIS DE RIESGO ---------- */}
        <Show.When isTrue={reservation.risks.risks && mode === 'commission'}>
          <Subtitle
            text={t('riskAnalysis.title')}
            className={'container-subtitle subtitle-border'}
          />
          <ContanierSection maxWidth='1200px'>
            <div className='row no-gutters'>
              <div className='col-12 col-md-3'>
                <div className='dark-grey'>{t('riskAnalysis.fraudAssessment')}</div>
                <div className='d-flex align-items-center'>
                  <InfoRenderer
                    content={getRisk(reservation.risks.riskLevel)}
                    className='font-weight-semibold'
                  />
                  <span
                    className={`dot dot-${getRiskColor(
                      reservation.risks.riskLevel,
                    )} ml-2 d-inline-block`}
                  ></span>
                </div>
              </div>
              <div className='col-12 col-md-3'>
                <HalfCircleProgressBar
                  percentage={reservation.risks.confidendePercentage}
                  text={t('riskAnalysis.trust')}
                />
              </div>
              <div className='col-12 col-md'>
                <Show.When isTrue={reservation.risks.risks.length !== 0}>
                  <InfoRenderer
                    content={
                      reservation.risks.risks.length > 1
                        ? t('riskAnalysis.riskAlerts')
                        : t('riskAnalysis.riskAlert')
                    }
                    className='dark-grey'
                  />
                  {reservation.risks.risks.map((r) => (
                    <div className='font-weight-semibold' key={r.id}>
                      {renderRiskItem(r)}
                    </div>
                  ))}
                </Show.When>
              </div>
            </div>
          </ContanierSection>
        </Show.When>
        {/* ---------- LINEA DE TIEMPO ---------- */}
        <Subtitle text={t('timeline.title')} className={'container-subtitle subtitle-border'} />

        <ContanierSection maxWidth='1200px'>
          <div className='timeline reservation ml-2'>
            {reservation.timeline.map((i, index) => {
              const { dateTime } = formatDateTranslation(i.dateLocalTime, language, 'small');
              return (
                <div key={index} className='row no-gutters timeline-item'>
                  <div className='col-3'>
                    <div className='row no-gutters'>
                      <div className='col-auto'>
                        <div
                          className={`dot ${
                            (i.reservationEvent === 'COMMISSION_AUDIT_DISPUTED' ||
                              i.reservationEvent === 'COMMISSION_AUDIT_REJECTED' ||
                              i.reservationEvent === 'CANCEL_COMMISSION' ||
                              i.reservationEvent === 'REJECTED' ||
                              i.reservationEvent === 'EXPIRED' ||
                              i.reservationEvent === 'REFUNDED' ||
                              i.reservationEvent === 'CANCELLED' ||
                              i.reservationEvent === 'CANCELLED_EXPIRATION' ||
                              i.reservationEvent === 'CANCELLED_BY_CONCIERGE' ||
                              i.reservationEvent === 'CANCELLED_BY_CUSTOMER' ||
                              i.reservationEvent === 'CANCELLED_MISSING_PAYMENT' ||
                              i.reservationEvent === 'REJECTED_EXPIRATION' ||
                              i.reservationEvent === 'NO_SHOW_MANUAL' ||
                              i.reservationEvent === 'PAYMENT_DISPUTED' ||
                              i.reservationEvent === 'PAYMENT_DISPUTED_NOT_ACCEPTED' ||
                              i.reservationEvent === 'PAYMENT_DISPUTED_ACCEPTED' ||
                              i.reservationEvent === 'PAYMENT_DISPUTED_LOST' ||
                              i.reservationEvent === 'NO_SHOW') &&
                            'alert-dot'
                          }`}
                        ></div>
                      </div>
                      <div className='col-auto dark-grey pl-3 container-data'>
                        <InfoRenderer
                          content={getReservationEvent(i.reservationEvent)}
                          className='dark-grey font-weight-semibold text-wrap container-label'
                        />
                        <InfoRenderer content={dateTime} className='font-weight-regular ' />
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>{renderTimelineUser(i)}</div>
                  <div className='col-3'>
                    {i.userComments && (
                      <>
                        {/* concierges */}
                        <UserComments text={i.userComments} />
                      </>
                    )}
                  </div>
                  {i.comments &&
                  i.comments.length > 0 &&
                  i.reservationEvent !== 'PAYMENT_ACCREDITED_MERCADOPAGO' ? (
                    <div className='pl-3 col-3'>
                      <Comments text={i.comments} />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </ContanierSection>

        {/* ---------- PRODUCT ZONE ---------- */}
        <Show.When isTrue={mode === 'reservation'}>
          <ReservationProductZone showProduct={false} reservation={reservation} />
        </Show.When>
        {/* ---------- DETALLE DE CONCIERGES ---------- */}
        <ConciergeDetail
          data={{ concierge: reservation?.seller, rp: reservation?.rp, boss: reservation?.boss }}
          language={language}
        />
        {/* ---------- DETALLE INFO CAJA ---------- */}
        <Show.When isTrue={reservation.externalPayments && reservation.externalPayments.length > 0}>
          <Subtitle text={t('infobox.title')} className={'container-subtitle subtitle-border'} />
          <div className='container-fluid p-0 container-section'>
            <div className='row no-gutters justify-content-start'>
              <div className='col-12 col-xl-10'>
                <div className='row no-gutters'>
                  <div className='col-auto pr-3 dark-grey'>
                    <div className='dark-grey fw-500 pb-1'>{t('infobox.numberChecks')}</div>
                    <div className='dark-grey fw-500 pb-1'>{t('infobox.amountRestaurants', { restaurants: getClientTranslation('title.plural')?.toLocaleLowerCase()})}</div>
                    <div className='dark-grey fw-500'>{t('infobox.commissionableAmount')}</div>
                  </div>
                  <div className='col pl-4'>
                    <InfoRenderer
                      content={reservation.externalPayments.length}
                      className='text-primary fw-600 pb-1'
                    />
                    <InfoRenderer
                      content={uniqueLocationNames.size}
                      className='text-primary fw-600 pb-1'
                    />
                    <InfoRenderer
                      content={
                        <Money
                          currencyClass={'text-primary fw-600'}
                          value={reservation.consume}
                        />
                      }
                      className='text-primary fw-600'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Show.When isTrue={reservation.externalPayments.length > 0}>
            <div className='container-section'>
              {reservation.externalPayments?.map((payment, index) => (
                <div className='mt-4' key={index}>
                  <OutlineTable
                    key={index}
                    data={[payment]}
                    showPagination={false}
                    showText={false}
                    onPageChange={handlePageChange}
                    renderHeader={() => (
                      <Subtitle
                        text={`${t('infobox.outlineTable.title')} ${index + 1}`}
                        className={'fs-15 subtitle-border pb-2 fw-600'}
                      />
                    )}
                    currentPage={currentPage}
                    totalP={totalItems}
                    borderTop={false}
                    renderRow={(check, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        {[
                          [
                            t('infobox.outlineTable.idCheck'),
                            check.checkNumber,
                            t('infobox.outlineTable.nroTable'),
                            check.tableNumber,
                            null,
                          ],
                          [
                            getClientTranslation('title.singular'),
                            check.locationName,
                            t('infobox.outlineTable.codeReservation'),
                            formatDateTranslation(
                              check.welletCodeAddedAtLocalTime,
                              language,
                              'small',
                            )?.dateTime,
                            check.welletCodeAddedByUserName,
                          ],
                          [
                            t('infobox.outlineTable.commissionableAmount'),
                            <Money
                              value={check.amount}
                              currencyClass={'text-primary'}
                            />,
                            t('infobox.outlineTable.openTable'),
                            formatDateTranslation(check.openedAtLocalTime, language, 'small')
                              ?.dateTime,
                            check.openedByUserName,
                          ],
                          [
                            t('infobox.outlineTable.amountPax'),
                            check.paxs,
                            t('infobox.outlineTable.closeTable'),
                            formatDateTranslation(check.tableClosedAtLocalTime, language, 'small')
                              ?.dateTime,
                            check.openedByUserName,
                            check.tableClosedByUserName,
                          ],
                          [
                            t('infobox.outlineTable.discounts'),
                            check.hasDiscount ? (
                              <div className='d-flex'>
                                {check.discountName ? (
                                  <span className='mr-1'> {check.discountName}</span>
                                ) : null}
                                <Money
                                  currencyClass={'text-primary'}
                                  value={check.discountAmount}
                                />
                              </div>
                            ) : (
                              t('infobox.outlineTable.noDiscounts')
                            ),
                            t('infobox.outlineTable.paymentTable'),
                            formatDateTranslation(check.tablePaidAtLocalTime, language, 'small')
                              ?.dateTime,
                            check.tablePaidByUserName,
                          ],
                        ].map((info, infoIndex) => (
                          <TableRow key={infoIndex} className={'outline-tr'}>
                            <TableCell
                              className={'outline-td secondary text-nowrap'}
                              style={{ width: '200px' }}
                            >
                              {info[0]}
                            </TableCell>
                            <TableCell
                              className={'outline-td primary text-nowrap'}
                              style={{ width: '300px' }}
                            >
                              {info[1] ? info[1] : '-'}
                            </TableCell>
                            <TableCell
                              className={'outline-td secondary text-nowrap'}
                              style={{ width: '200px' }}
                            >
                              {info[2]}
                            </TableCell>
                            <TableCell
                              className={'outline-td primary text-nowrap'}
                              style={{ width: '300px' }}
                            >
                              {info[3] ? info[3] : '-'}
                            </TableCell>
                            <TableCell style={{ width: '30%' }} className={'outline-td secondary'}>
                              {info[4]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    )}
                    rowClass='outline-tr'
                  />
                </div>
              ))}
            </div>
          </Show.When>
        </Show.When>
        {/* ---------- DETALLE DE COMISION ---------- */}
        <Show.When
          isTrue={reservation.commissions.length > 0 || reservation.hasCommissionDesactivated}
        >
          <Subtitle
            text={t('commissionDetail.title')}
            endComponent={
              <Show.When isTrue={reservation.canAddAdjustment}>
                <Button
                  className={'btn-general btn-wellet-secondary'}
                  onClick={() => setOpenAdjust(true)}
                  size={'custom'}
                  text={<>{t('commissionDetail.addAdjustment')}</>}
                />
              </Show.When>
            }
          />
          <div className='container-fluid p-0 container-section'>
            <div className='row no-gutters justify-content-start'>
              <div className='col-12 col-xl-10'>
                <div className={`row ${reservation.hasCommissionDesactivated && 'pl-2'}`}>
                  <Show>
                    <Show.When isTrue={reservation.hasCommissionDesactivated}>
                      <>
                        <div
                          className='col-auto d-flex align-items-center'
                          style={{ background: '#7F56D9', color: '#fff', padding: '4px 12px' }}
                        >
                          <WarningModernIcon color={'#fec73d'} size='20px' />
                          <div
                            className='fw-700 pl-2'
                            style={{ fontSize: '16px', marginTop: '4px' }}
                          >
                            {t('commissionDesactivated')}
                          </div>
                        </div>
                      </>
                    </Show.When>
                    <Show.Else>
                      <>
                        <div className='col-3'>{t('commissionDetail.commissionImport')}</div>
                        <div className='col-auto pl-3 '>
                          <InfoRenderer
                            content={
                              <Money
                                value={reservation.commissions.reduce(
                                  (total, c) => total + c.amount,
                                  0,
                                )}
                              />
                            }
                            className='text-primary fw-700'
                          />
                        </div>
                        <div className='col'>
                          <div className='border-left pl-3'>
                            {reservation.commissions.map((c, i) => (
                              <div className={`row ${i !== 0 ? 'mt-3' : ''}`} key={c.id}>
                                <div className='col-4'>
                                  <div className='row'>
                                    <div className='col-auto'>
                                      <div className='profile-pic'>
                                        <UserImage
                                          url={c.user.profilePicUrl}
                                          name={c.user.fullName}
                                          isVerified={c.user.isVerified}
                                          size={'40px'}
                                        />
                                      </div>
                                    </div>
                                    <div className='col'>
                                      <InfoRenderer
                                        content={c.user.fullName}
                                        className='font-weight-semibold'
                                      />
                                      <div className='dark-grey'>
                                        {t('commissionDetail.affiliate')}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-2'>
                                  {c.operationType === 'COMMISSION' && (
                                    <>
                                      <div className='dark-grey'>
                                        {t('commissionDetail.percentage')}
                                      </div>
                                      <div className='font-weight-semibold'>{c.percentage}%</div>
                                    </>
                                  )}
                                </div>
                                <div className='col-3'>
                                  <div className='dark-grey'>
                                    {c.operationType === 'COMMISSION'
                                      ? t('commissionDetail.commission')
                                      : t('commissionDetail.adjustment')}
                                  </div>
                                  <InfoRenderer
                                    content={<Money value={c.amount} />}
                                    className='font-weight-semibold fw-700'
                                  />
                                </div>
                                {c.operationState === 'PAID' && (
                                  <div className='col-3 my-auto'>
                                    <Badge
                                      text={t('commissionDetail.paymentExecuted')}
                                      type={'green'}
                                      icon={'check'}
                                    />
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    </Show.Else>
                  </Show>
                </div>
              </div>
            </div>
          </div>
        </Show.When>
        {/* ---------- NOTES ---------- */}
        <Notes
          callback={reservationIdQuery.refetch}
          currentNotes={reservation.listComments ? reservation.listComments : []}
          reservationId={id}
          marginBottom={mode === 'reservation' ? '50px' : '150px'}
        />
        <Show.When isTrue={mode === 'reservation'}>
          <TablePdf data={reservation?.orderDocuments} />
        </Show.When>

        {/* ----------MODALS---------- */}
        <ModalDispute
          open={openDispute}
          isLoading={disputeMutation.isLoading || disputeMutation.isSuccess}
          onClose={() => setOpenDispute(false)}
          handleSubmit={(data) => {
            disputeMutation.mutate(data);
          }}
          lng={reservation?.seller?.language}
        />
        <ModalComment
          open={openDisputeApprove}
          isLoading={disputeApproveMutation.isLoading || disputeApproveMutation.isSuccess}
          showComment={true}
          maxWidth={'450px'}
          onClose={() => setOpenDisputeApprove(false)}
          fields={[
            {
              label: t('modals.disputeApprove.form.inputComment.label'),
              name: 'comment',
              placeholder: t('modals.disputeApprove.form.inputComment.placeholder'),
              isRequired: false,
            },
            {
              label: t('modals.disputeApprove.form.inputConciergesComment.label'),
              name: 'userComment',
              placeholder:
                reservation?.seller?.language === 'es'
                  ? t('modals.disputeApprove.form.inputConciergesComment.placeholderEs')
                  : t('modals.disputeApprove.form.inputConciergesComment.placeholderEn'),
              isRequired: false,
            },
          ]}
          title={t('modals.disputeApprove.title')}
          textSubmit={t('modals.disputeApprove.submit')}
          handleSubmit={(data) => {
            disputeApproveMutation.mutate(data);
          }}
          body={<div className='text-left mt-1 mb-2'>{t('modals.disputeApprove.ask')}</div>}
        />
        <ModalComment
          open={openDisputeReject}
          isLoading={disputeRejectMutation.isLoading || disputeRejectMutation.isSuccess}
          showComment={true}
          maxWidth={'450px'}
          onClose={() => setOpenDisputeReject(false)}
          fields={[
            {
              label: t('modals.disputeReject.form.inputComment.label'),
              name: 'comment',
              placeholder: t('modals.disputeReject.form.inputComment.placeholder'),
              isRequired: true,
            },
            {
              label: t('modals.disputeReject.form.inputConciergeComment.label'),
              name: 'userComment',
              placeholder:
                reservation?.seller?.language === 'es'
                  ? t('modals.disputeReject.form.inputConciergeComment.placeholderEs')
                  : t('modals.disputeReject.form.inputConciergeComment.placeholderEn'),
              isRequired: true,
            },
          ]}
          title={t('modals.disputeReject.title')}
          textSubmit={t('modals.disputeReject.submit')}
          textCancel={t('modals.disputeReject.cancel')}
          handleSubmit={(data) => {
            disputeRejectMutation.mutate(data);
          }}
          body={
            <div className='text-left mt-2 mb-2'>
              <div className='font-weight-bold'>{t('modals.disputeReject.ask')}</div>
              <span className='text-light'>{t('modals.disputeReject.text')}</span>
            </div>
          }
        />
        <Modal
          open={openApprove}
          handleSubmit={() => {
            approveMutation.mutate(openApprove);
          }}
          isLoading={approveMutation.isLoading || approveMutation.isSuccess}
          showComment={true}
          onClose={() => setOpenApprove(false)}
          title={t('modals.approve.title')}
          textCancel={t('modals.approve.cancel')}
          children={<div className='text-left mt-2'>{t('modals.approve.ask')}</div>}
          textSubmit={t('modals.approve.submit')}
          maxWidth={'450px'}
        />
        <ModalAdjustment
          open={openAdjust}
          onClose={() => setOpenAdjust(false)}
          isLoading={adjustmentMutation.isLoading || adjustmentMutation.isSuccess}
          handleSubmit={(data) => {
            adjustmentMutation.mutate(data);
          }}
          canAddAdjustment={reservation.canAddAdjustment}
          showUsers={false}
        />

        {/* ----------TOASTS ----------*/}
        {disputeMutation.isSuccess && (
          <Toast
            message={t('toast.disputeSuccess')}
            type='SUCCESS'
            onCallback={() => {
              disputeMutation.reset();
              disputeApproveMutation.reset();
              reservationIdQuery.refetch();
              setOpenDispute(false);
            }}
          />
        )}
        {disputeApproveMutation.isSuccess && (
          <Toast
            message={t('toast.approveCommissionSuccess')}
            type='SUCCESS'
            onCallback={() => {
              disputeMutation.reset();
              disputeApproveMutation.reset();
              reservationIdQuery.refetch();
              setOpenDisputeApprove(false);
            }}
          />
        )}
        {disputeRejectMutation.isSuccess && (
          <Toast
            message={t('toast.rejectedCommissionSuccess')}
            type='SUCCESS'
            onCallback={() => {
              disputeMutation.reset();
              reservationIdQuery.refetch();
              setOpenDisputeReject(false);
            }}
          />
        )}
        {approveMutation.isSuccess && (
          <Toast
            message={t('toast.approveSuccess')}
            type='SUCCESS'
            onCallback={() => {
              approveMutation.reset();
              reservationIdQuery.refetch();
              setOpenApprove(false);
            }}
          />
        )}
        {adjustmentMutation.isSuccess && (
          <Toast
            message={t('toast.adjustmentSuccesss')}
            type='SUCCESS'
            onCallback={() => {
              adjustmentMutation.reset();
              reservationIdQuery.refetch();
              setOpenAdjust(false);
            }}
          />
        )}
      </div>
    </>
  ) : null;
};

export default Details;
