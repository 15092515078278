import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SignInHeader } from '../../components';
import logoWellet from '../../assets/images/192x192.png';
import { Card, CardBody } from '../../uiComponents/common';
import { useDispatch } from 'react-redux';
const Unauthorized = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAN_UNAUTHORIZED' });
    };
  }, []);

  return (
    <div className='d-flex flex-column justify-content-beetween align-items-strech vh-100 w-100'>
      <SignInHeader />
      <div className='d-flex flex-column justify-content-center align-items-center h-100'>
        <Card>
          <div className='row justify-content-center'>
            <div
              className='mt-3 mb-2 d-flex align-items-center justify-content-center'
              style={{
                height: '100px',
                width: '100px',
                borderRadius: '50%',
                backgroundImage: `url(${logoWellet})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }}
            ></div>
          </div>
          <div className='row'>
            <h5 className='col-12 text-center mb-0' style={{ color: 'var(--color-subtitle)' }}>
              No es posible acceder
            </h5>
            <p className='col text-center text-muted'>
              ¡Lo sentimos! <br />
              No posees autorización para ingresar. <br />
              Por favor, contáctate con soporte para solicitar permisos.
            </p>
          </div>
          <CardBody>
            <div className='py-2 content-divider text-muted'>
              <span className='px-3'></span>
            </div>
            <div className='row'>
              <div className='col'>
                <Link to={'/'} className='custom-link'>
                  Regresar al login
                </Link>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Unauthorized;
