import React from 'react';
import styles from './configCard.module.css';
import { Link } from 'react-router-dom';

const ConfigCard = ({ icon = null, title = null, text = null, link = '#' }) => {
  return (
    <Link to={link}>
      <div className={`${styles.card} ${styles.configCard}`}>
        <div className={`${styles.iconTitleContainer}`}>
          <div className={`${styles.configContentIcon}`}>{icon}</div>
          <div className={`${styles.titleTextContainer}`}>
            <div className={`${styles.configTitle}`}>{title}</div>
            <div className={`${styles.configText}`}>{text}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ConfigCard;
