import React, { useContext, useEffect, useState } from 'react';
import { Table, TableRow, TableCell, FilterCard, Show } from '../../components';
import {
  AffiliateCard,
  Button,
  ButtonFilter,
  Loading,
  Money,
  NameEmailFilter,
  OrganizationCard,
  PageError,
  Tabs,
  UserImage,
  WithDrawal,
} from '../../uiComponents/common';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  useCreateBreadCrumbs,
  useLocalStorage,
  useOrderBy,
  usePagination,
  useUser,
} from '../../hooks';
import { useMutation, useQuery } from 'react-query';
import {
  getBatchSummaryById,
  downloadPaymentSummary,
  getBatchByIds,
  getAccountsSimple,
} from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import downloadFileFromResponse from '../../utils/downloadFile';
import { paymentSummaryBadgeOutline } from '../../helpers/status';
import { useTranslation } from 'react-i18next';
import { paymentBatchBadge } from '../../helpers/status';
import { hasAllowedRoles } from '../../helpers/roles';
import { ModalSendPayments } from '../../components/modals';
import { WalletPayemntIcon } from '../../uiComponents/icons';
import { filtersFormat } from '../../helpers/format';
import { getPaymentStatus } from '../../data';
import mergeWithDefaults from '../../helpers/localstorage';
import MinMaxFilter from '../../uiComponents/common/amountFilter/minMax';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import useGenericTranslation from '../../hooks/useGenericTranslation';

// To Do: Componetizar header

const PaymentSummary = () => {
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.currentOrganization);
  const navigate = useNavigate();
  const { id } = useParams();
  const { roles } = useUser();
  const tabelItems = 10;

  const { t: translate } = useTranslation();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'screens.paymentSummary' });
  const { t: translatePayment } = useTranslation('translation', { keyPrefix: 'paymentMethods' });
  const { getClientTranslation } = useGenericTranslation();
  const { orderBy, orderType, handleColumnClick } = useOrderBy();

  // Handle Tabs
  const tabTypes = {
    PAYMENTS: 'PAYMENTS',
    RESTAURANTS: 'RESTAURANTS',
  };
  const paymentMethods = useSelector((state) => state.app.paymentMethods);

  const [activeTab, setActiveTab] = useState(tabTypes.PAYMENTS);
  const tabs = [
    {
      isActive: activeTab === tabTypes.PAYMENTS,
      title: t('table.payments.title'),
      type: tabTypes.PAYMENTS,
    },
    {
      isActive: activeTab === tabTypes.RESTAURANTS,
      title: getClientTranslation('title.plural'),
      type: tabTypes.RESTAURANTS,
    },
  ];

  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('payments-wire', {
    wire: null,
    statusPayment: getPaymentStatus(translate),
    page: 1,
    peoplepage: 1,
    amount: {
      min: null,
      max: null,
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState(paymentMethods?.includes(filterLocalStorage?.wire) ? filterLocalStorage.wire : null);
  const [statusPayment, setstatusPayment] = useState(
    mergeWithDefaults(filterLocalStorage.statusPayment, getPaymentStatus(translate)),
  );
  const [filterAmount, setFilterAmount] = useState(
    filterLocalStorage?.amount ? filterLocalStorage?.amount : { min: null, max: null },
  );
  const [filterUser, setFilterUser] = useState([]);

  // Summary Pagination
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
    tabelItems,
  );
  let limit = tabelItems;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // People Pagination
  const {
    currentPage: currentPagePeople,
    totalItems: totalItemsPeople,
    handlePageChange: handlePageChangePeople,
    setTotalAndReset: setTotalAndResetPeople,
  } = usePagination(filterLocalStorage.peoplepage, tabelItems);
  let limitPeople = tabelItems;
  let skipPeople = 10 * ((currentPagePeople ? currentPagePeople : 1) - 1);

  // Main API Querys
  const restaurantQuery = useQuery({
    queryKey: ['payment-summary', id, limit, skip, activeTab],
    queryFn: () => getBatchSummaryById(welletContext, organization.marketId, id, limit, skip),
    onSuccess: (data) => setTotalAndReset(data?.data?.locations.totalRegisters),
    ...defaultQueryOptions,
    enabled: activeTab === 'RESTAURANTS' ? true : false,
  });
  const paymentQuery = useQuery({
    queryKey: [
      'people',
      id,
      activeTab,
      limitPeople,
      skipPeople,
      filter,
      orderBy,
      orderType,
      filtersFormat(statusPayment),
      filterAmount,
      filtersFormat(filterUser),
    ],
    queryFn: () =>
      getBatchByIds(
        welletContext, // welletContext
        organization.marketId, // marketId
        id, // id
        limitPeople, // limit
        skipPeople, // skip
        filter === null ? [] : [filter], // withdrawalmethods
        filtersFormat(filterUser), // PAYMENTS
        filterAmount, // money
        filtersFormat(statusPayment), // status
        orderBy, //sortBy
        orderType, //columnFilter
      ),
    onSuccess: (data) => {
      setTotalAndResetPeople(data?.data?.detail?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        wire: filter,
        peoplepage: currentPagePeople,
        statusPayment: statusPayment,
        amount: { min: filterAmount?.min, max: filterAmount?.max },
      });
    },
    ...defaultQueryOptions,
    keepPreviousData: true,
    enabled: activeTab === 'PAYMENTS' ? true : false,
  });
  // Query Filters
  const usersQuery = useQuery({
    queryKey: ['user-emails-payments', organization.id, id],
    queryFn: () => getAccountsSimple(welletContext, organization.marketId, id),
    onSuccess: (data) => {
      let response = data?.data?.items?.map((user) => ({
        label: user.name,
        value: user.sid,
        email: user.email,
        isActive: false,
      }));
      setFilterUser(response);
    },
    ...defaultQueryOptions,
    enabled: isOpen,
  });
  // Query Mutation
  const downloadTableMutation = useMutation({
    mutationFn: async () => {
      try {
        const response = await downloadPaymentSummary(welletContext, organization.marketId, id);

        downloadFileFromResponse(response, `Pagos-${id}.xlsx`);
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
      }
    },
  });

  const getExtraPaymentMethods = (summary) => {
    if (summary)
      return summary
        .filter((item) => !paymentMethods.includes(item.withdrawalMethod) && item.amount > 0)
        .map((item) => item.withdrawalMethod);
    else return []
  };

  // Esta es la lista combinada de métodos de pago
  const combinedPaymentMethods = [
    ...paymentMethods,
    ...getExtraPaymentMethods(paymentQuery?.data?.data?.summary),
  ];

  // Header Table
  const headerPayments = [
    { title: t('table.payments.account'), value: '', filterable: false },
    { title: t('table.payments.amount'), value: 'amount', filterable: true },
    { title: t('table.payments.getaway'), value: '', filterable: false },
    { title: t('table.payments.state'), value: '', filterable: false },
  ];
  const headerRestaurant = [
    { title: t('table.restaurant.unit'), value: '', filterable: false },
    { title: t('table.restaurant.amount'), value: '', filterable: false },
    ...combinedPaymentMethods.map((method) => ({
      title: translatePayment(method),
      value: '',
      filterable: false,
    })),
  ];

  // Functions
  const handleTabs = (type) => {
    setActiveTab(type);
  };
  const getWithdrawalAmount = (summary, withdrawalMethod) => {
    const withdrawal = summary?.find((item) => item.withdrawalMethod === withdrawalMethod);
    return withdrawal ? withdrawal.amount : null;
  };
  const handleNavigate = (id) => {
    navigate(`/payment-details/${id}`);
  };
  const handleFilter = (filter) => {
    setFilter(filter);
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, wire: filter, page: 1 });
  };

  useCreateBreadCrumbs([
    { name: t('breadcrumbs.prev'), path: '/payments' },
    { name: t('breadcrumbs.current') },
  ]);

  useEffect(() => {
    setstatusPayment(
      mergeWithDefaults(filterLocalStorage.statusPayment, getPaymentStatus(translate)),
    );
  }, [i18n.language]);

  const formatPaymentKeyToField = (key) => {
    switch (key) {
      case 'MERCADOPAGO':
        return 'mercadopagoAmount';
      case 'CASH':
        return 'cashAmount';
      case 'CERTIFICATE':
        return 'certificateAmount';
      case 'WIRE_MEXICO':
        return 'mexicanTransfer';
      case 'WIRE_INTERNATIONAL':
        return 'internationalTransfer';
      case 'STRIPE':
        return 'stripeAmount';
      default:
        return null;
    }
  };

  return (
    <div className='container-page color-body font-weight-semibold'>
      <Show>
        <Show.When isTrue={paymentQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={paymentQuery.isError || restaurantQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          {/* HEADER */}
          <div className='container-header mb-0 without-border'>
            <div className='row align-items-end'>
              <div className='col-auto'>
                <div className='light-grey d-flex align-items-center container-small-title'>
                  <WalletPayemntIcon size='14px' color='currentColor' customClass={'mr-2'} />
                  <div className=''>{paymentQuery?.data?.data?.batch?.description}</div>
                </div>
                <div className='container-title'>
                  <Money
                    value={paymentQuery?.data?.data?.totalSummary}
                    currencyCode={paymentQuery?.data?.data?.batch?.currency}
                  />
                  <div className='d-inline ml-3'>
                    {paymentBatchBadge(paymentQuery?.data?.data?.batch?.status, null, translate)}
                  </div>
                </div>
              </div>
              <div className='col my-auto'></div>
              <div className='col-auto'>
                <div className='row'>
                  <Show.When
                    isTrue={
                      paymentQuery?.data?.data?.batch?.status === 'REVIEWED' &&
                      hasAllowedRoles(roles, ['superadministrator', 'marketadmin', 'finance'])
                    }
                  >
                    <div className='col-auto'>
                      <ModalSendPayments
                        id={id}
                        callBack={() => {}}
                        refetch={() => paymentQuery.refetch()}
                      />
                    </div>
                  </Show.When>
                </div>
              </div>
            </div>
          </div>

          <Tabs onClick={handleTabs} options={tabs} />
          <Show>
            <Show.When isTrue={restaurantQuery.isLoading || paymentQuery.isFetching}>
              <Loading />
            </Show.When>
            <Show.Else>
              <Show.When isTrue={activeTab === 'RESTAURANTS'}>
                <h3 className='fw-700 mt-4 mb-2' style={{ fontSize: '20px' }}>
                  {getClientTranslation('paymentSummary')}
                </h3>
                <Table
                  perPage={tabelItems}
                  data={restaurantQuery?.data?.data?.locations?.items ?? []}
                  hasPagination={false}
                  paginationBorder={false}
                  totalP={totalItems}
                  onPageChange={(page) =>
                    handlePageChange(page, setFilterLocalStorage({ ...filterLocalStorage, page }))
                  }
                  currentPage={currentPage}
                  columns={headerRestaurant}
                  renderRow={(item, index) => (
                    <>
                      <>
                        <TableCell>
                          <OrganizationCard
                            name={item.name}
                            date={item.city}
                            size={40}
                            imageUrl={item.logo}
                            hightLightName={true}
                          />
                        </TableCell>
                        <TableCell className='font-weight-bold'>
                          <Money value={item.totalAmount} />
                        </TableCell>
                        {combinedPaymentMethods.map((method, idx) => (
                          <TableCell key={idx} className='fw-400'>
                            <Money value={item[formatPaymentKeyToField(method)] ?? 0} />
                          </TableCell>
                        ))}
                      </>
                      <Show.When
                        isTrue={index === restaurantQuery?.data?.data?.locations?.items.length - 1}
                      >
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#FAFAFA', fontWeight: 400 }}>
                            Total
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#FAFAFA' }}>
                            <div className='fw-700'>
                              <Money value={restaurantQuery.data?.data?.totalAmount} />
                            </div>
                          </TableCell>
                          {combinedPaymentMethods.map((method, idx) => (
                            <TableCell
                              key={idx}
                              className='fw-700'
                              style={{ backgroundColor: '#FAFAFA' }}
                            >
                              <Money value={item[formatPaymentKeyToField(method)] ?? 0} />
                            </TableCell>
                          ))}
                        </TableRow>
                      </Show.When>
                    </>
                  )}
                />
              </Show.When>
              <Show.When isTrue={activeTab === 'PAYMENTS'}>
                <div className='row px-1 my-3 '>
                  <FilterCard
                    category={t('cardFilter.total')}
                    quantity={<Money value={paymentQuery?.data?.data?.totalSummary} />}
                    className='col-auto'
                    onClick={() => handleFilter(null, null)}
                    isSelected={filter === null}
                  />
                  {combinedPaymentMethods?.map((p) => (
                    <React.Fragment key={p}>
                      <FilterCard
                        category={translatePayment(p)}
                        quantity={
                          <Money
                            value={getWithdrawalAmount(paymentQuery?.data?.data?.summary, p)}
                          />
                        }
                        className='col-auto'
                        onClick={() => handleFilter(p)}
                        isSelected={filter === p}
                      />
                    </React.Fragment>
                  ))}
                </div>

                <div className='w-100 d-flex justify-content-between'>
                  <div className='row'>
                    <div className='col-auto'>
                      <NameEmailFilter
                        isSearchable={true}
                        filterName={t('buttonFilter.account')}
                        filterOptions={filterUser}
                        onClick={() => setIsOpen(true)}
                        loading={usersQuery.isFetching}
                        callback={(data) => {
                          setFilterUser(data);
                        }}
                      />
                    </div>

                    <div className='col-auto'>
                      <MinMaxFilter
                        filterName={t('buttonFilter.amount')}
                        initialValue={filterAmount}
                        filterOptions={filterAmount}
                        callback={(data) => {
                          setFilterAmount(data);
                        }}
                      />
                    </div>

                    <div className='col-auto'>
                      <ButtonFilter
                        isSearchable={true}
                        filterName={t('buttonFilter.status')}
                        filterOptions={statusPayment}
                        callback={setstatusPayment}
                      />
                    </div>
                  </div>
                  <div>
                    {hasAllowedRoles(roles, [
                      'superadministrator',
                      'marketadmin',
                      'auditor',
                      'auditor-onlyview',
                      'finance',
                    ]) ? (
                      <Button
                        className={'btn-wellet-secondary justify-content-center opacity mb-2'}
                        size={'small'}
                        width={'36px'}
                        text={t('download')}
                        disabled={downloadTableMutation.isLoading}
                        loading={downloadTableMutation.isLoading}
                        onClick={() => downloadTableMutation.mutate()}
                      />
                    ) : null}
                  </div>
                </div>

                <Table
                  perPage={tabelItems}
                  data={paymentQuery?.data?.data?.detail?.items ?? []}
                  hasPagination={false}
                  totalP={totalItemsPeople}
                  onPageChange={(page) =>
                    handlePageChangePeople(
                      page,
                      setFilterLocalStorage({ ...filterLocalStorage, pagePeople: page }),
                    )
                  }
                  currentPage={currentPagePeople}
                  columns={headerPayments}
                  onColumnClick={handleColumnClick}
                  activeColumn={orderType}
                  sortOrder={orderBy}
                  paginationBorder={false}
                  renderRow={(item, index) => (
                    <>
                      <>
                        <TableCell style={{ width: '350px' }}>
                          <AffiliateCard
                            url={item.sellerProfilePicture}
                            isVerified={item.isVerified}
                            name={item.sellerName}
                            email={item.sellerEmail}
                            size={'40px'}
                          />
                        </TableCell>
                        <TableCell className='fw-500'>
                          <Money
                            value={item.amount}
                            // currencyCode={restaurantQuery?.data?.data?.currency}
                          />
                        </TableCell>
                        <TableCell className='fw-400'>
                          {item?.withDrawalMethod ? (
                            <WithDrawal
                              showOnlyText={true}
                              withdrawal={item?.withDrawalMethod}
                              withdrawalData={item?.withdrawalMethodData}
                            />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell className=''>
                          {paymentSummaryBadgeOutline(item?.status, item?.statusDetail, translate)}
                        </TableCell>
                      </>
                      {/* FOOTER */}
                      <Show.When
                        isTrue={index === paymentQuery?.data?.data?.detail?.items.length - 1}
                      >
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#FAFAFA', fontWeight: 400 }}>
                            Total
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#FAFAFA' }}>
                            <div className='fw-700'>
                              <Money value={paymentQuery?.data?.data?.totalPaginationAmount} />
                            </div>
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#FAFAFA' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#FAFAFA' }}></TableCell>
                        </TableRow>
                      </Show.When>
                    </>
                  )}
                  onRowClick={(item) => handleNavigate(item.id)}
                />
              </Show.When>
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </div>
  );
};

export default PaymentSummary;
