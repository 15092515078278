import React from 'react';

const WalletPayemntIcon = ({ color = '#000', size = '14px', customClass }) => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 12 10.04'
        className={customClass}
        width={size}
        height={size}
      >
        <g>
          <g>
            <path
              d='M5.24,5.09c0,.27.18.39.6.55v-1.07c-.34.05-.6.23-.6.52Z'
              fill={color}
              opacity='.73'
              strokeWidth='0'
            />
            <path
              d='M6.29,6.61v1.18c.41-.05.69-.26.69-.57,0-.33-.29-.48-.69-.61Z'
              fill={color}
              opacity='.73'
              strokeWidth='0'
            />
            <path
              d='M10.99,2.43H1.01c-.56,0-1.01.45-1.01,1.01v5.6c0,.55.45,1,1.01,1h9.98c.56,0,1.01-.45,1.01-1V3.44c0-.56-.45-1.01-1.01-1.01ZM6.29,8.54v.51h-.45v-.52c-.59-.05-1.2-.27-1.67-.65l.41-.63c.4.29.83.48,1.26.53v-1.31c-.96-.28-1.45-.58-1.45-1.29,0-.77.59-1.29,1.45-1.36v-.46h.45v.47c.51.05,1,.23,1.43.5l-.39.64c-.35-.21-.7-.33-1.04-.37v1.18c.75.22,1.54.47,1.54,1.37,0,.8-.64,1.33-1.54,1.39Z'
              fill={color}
              opacity='.73'
              strokeWidth='0'
            />
            <path
              d='M9.97.81c-.13-.54-.78-.9-1.32-.78L2.35,1.64h7.81l-.19-.83Z'
              fill={color}
              opacity='.73'
              strokeWidth='0'
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default WalletPayemntIcon;
