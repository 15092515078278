import { useContext } from 'react';
import SidebarContext from '../../../context/sidebarContext';
import { CloseIcon } from '../../icons';
import { IconButton } from '../../common';

import './sideBarHeader.css';
import { MarketSelector, OrganizationSelector, Show } from '../../../components';
import { useSelector } from 'react-redux';
import { useOrganizations } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { setPaymentMethods } from '../../../actions';

const SideBarHeader = () => {
  const { toggleIsSidebarOpen } = useContext(SidebarContext);
  const isMobile = window.innerWidth < 992;
  const userId = useSelector((state) => state.user.user.sid);
  const dispatch = useDispatch();
 
  const organizationsQuery = useOrganizations(userId, {
    enabled: isMobile,
    refetchOnMount: false,
    onSuccess: (data) => {
      dispatch(setPaymentMethods(data?.withdrawalMethods));
    },
  });

  return (
    <div className='sidebar-section mt-1'>
      <div className={`d-flex justify-content-end pr-3`}>
        <Show.When isTrue={isMobile}>
          <IconButton
            icon={<CloseIcon color='currentColor' size='18px' />}
            onClick={toggleIsSidebarOpen}
          />
        </Show.When>
      </div>
      <Show.When isTrue={isMobile}>
        <div className='col-auto my-2'>
          <MarketSelector callBaclClose={toggleIsSidebarOpen} data={organizationsQuery} />
        </div>
        <div className='col-auto'>
          <OrganizationSelector callBaclClose={toggleIsSidebarOpen} data={organizationsQuery} />
        </div>
      </Show.When>
    </div>
  );
};

export default SideBarHeader;
