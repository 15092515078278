import React from 'react';

const Certificate = ({ size = '16px', color = '#ccc' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='b'
      data-name='Capa 2'
      viewBox='0 0 48.32 31.36'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <path
            d='M30.28,11.55h-9.86c-.47,0-.85.38-.85.85s.38.85.85.85h9.86c.47,0,.85-.38.85-.85s-.38-.85-.85-.85Z'
            strokeWidth='0'
          />
          <path
            d='M39.06,15.02c2.19-.23,2.49,1.35,2.53,1.68.05.46.47.8.93.75.47-.05.81-.46.76-.93-.02-.15-.18-1.49-1.32-2.42-.48-.39-1.05-.62-1.69-.73v-1.75c0-.47-.38-.85-.85-.85s-.85.38-.85.85v1.77c-1.91.37-3.1,2.12-3.01,3.65.07,1.38,1.13,2.85,3.86,2.85,0,0,0,0,0,0,.63,0,2.11.12,2.17,1.24.04.82-.69,1.81-1.81,1.93-2.19.24-2.49-1.35-2.53-1.68-.05-.46-.47-.8-.93-.75-.47.05-.81.46-.76.93.02.15.18,1.49,1.32,2.42.48.39,1.05.63,1.69.74v1.74c0,.47.38.85.85.85s.85-.38.85-.85v-1.77c1.91-.37,3.1-2.12,3.01-3.65-.07-1.38-1.13-2.85-3.86-2.85,0,0,0,0,0,0-.63,0-2.11-.12-2.17-1.24-.04-.82.69-1.81,1.81-1.93Z'
            strokeWidth='0'
          />
          <path
            d='M48.32,7.63s0,0,0-.01V2.85c0-1.57-1.28-2.85-2.85-2.85H4.86c-1.57,0-2.85,1.28-2.85,2.85v13.21c-1.33.87-2.01,2.69-2.01,5.43s.68,4.55,2.01,5.43v1.6c0,1.57,1.28,2.85,2.85,2.85h40.61c1.57,0,2.85-1.28,2.85-2.85V7.64s0,0,0-.01ZM46.62,2.85v3.93H15.66V1.7h29.81c.63,0,1.15.52,1.15,1.15ZM22.36,21.48c0,1.55-.24,3.53-1.39,4.11-1.11.56-3.15-.09-5.66-1.79.4-.67.63-1.46.63-2.32s-.24-1.65-.63-2.32c2.51-1.7,4.55-2.35,5.66-1.79,1.15.58,1.39,2.56,1.39,4.11ZM3.71,8.48h4.7v8.46c-1.54-.89-3.22-1.55-4.7-1.45v-7.01ZM13.96,17.77c-.57-.36-1.22-.58-1.92-.58s-1.36.22-1.93.58V7.64s0,0,0,0,0,0,0,0V1.7h3.85v16.07ZM9.82,21.48c0-1.43.99-2.59,2.22-2.59s2.22,1.16,2.22,2.59-.99,2.59-2.22,2.59-2.22-1.16-2.22-2.59ZM12.03,25.77c.7,0,1.35-.22,1.92-.58v4.46h-3.85v-4.46c.57.36,1.22.58,1.93.58ZM4.86,1.7h3.55v5.08H3.71v-3.93c0-.63.52-1.15,1.15-1.15ZM3.09,17.37c1.11-.56,3.15.09,5.66,1.79-.4.67-.63,1.47-.63,2.32s.24,1.65.63,2.32c-2.51,1.69-4.55,2.35-5.66,1.79-1.15-.58-1.39-2.56-1.39-4.11s.24-3.53,1.39-4.11ZM3.71,28.51v-1.05c.1,0,.2.03.3.03,1.4,0,2.96-.64,4.4-1.46v3.63h-3.55c-.63,0-1.15-.52-1.15-1.15ZM45.47,29.66H15.66v-3.64c1.44.83,3,1.47,4.4,1.47.6,0,1.17-.12,1.68-.38,1.54-.78,2.32-2.67,2.32-5.62,0-.8-.06-1.51-.18-2.15h6.21c.47,0,.85-.38.85-.85s-.38-.85-.85-.85h-6.73c-.38-.82-.92-1.42-1.62-1.77-1.73-.88-4.03-.1-6.09,1.08v-8.46h30.96v20.03c0,.63-.52,1.15-1.15,1.15Z'
            strokeWidth='0'
          />
        </g>
      </g>
    </svg>
  );
};

export default Certificate;
