import React, { useContext, useState } from 'react';
import { Table, TableCell } from '../../components';
import { Button, Loading, Money, Subtitle, Toast } from '../../uiComponents/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useCreateBreadCrumbs, useGetBatch, usePagination, useUser } from '../../hooks';
import ModalComment from './modalComment';
import { useMutation } from 'react-query';
import { putCreateBatch } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { getLocale } from '../../helpers/dates';
import { hasAllowedRoles } from '../../helpers/roles';

import BadgeNew from '../../uiComponents/common/badge/badge';

const PaymentBatch = () => {
  const [open, setOpen] = useState(false);
  const welletContext = useContext(WelletContext);
  const location = useLocation();
  const organization = useSelector((state) => state.app.currentOrganization);
  const language = useSelector((state) => state.app.language);
  const { roles } = useUser();
  const locale = getLocale(language);
  const navigate = useNavigate();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const batchQuery = useGetBatch(
    organization.marketId,
    limit,
    skip,
    null,
    {
      refetchOnMount: true,
      onSuccess: (data) => setTotalAndReset(data?.data?.totalRegisters),
    },
    false,
  );

  const navigateToBatch = (id) => {
    navigate(`${id}`, { state: location.pathname + location.search });
  };

  const headerAdmin = ['Fecha creado', 'Importe total', 'Lote de pago', 'Estatus'];

  // const batchExecuteMutation = useMutation({
  //   mutationFn: (id) => {
  //     return postExecutePayment(welletContext, id);
  //   },
  // });

  useCreateBreadCrumbs([{ name: 'Lotes de Pagos' }]);

  const batchMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        description: data?.comment,
      };
      return putCreateBatch(welletContext, body);
    },
  });

  const getStatus = (status) => {
    switch (status) {
      case 'PENDING_REVIEW':
        return <button className='btn-wellet'>Auditar lote de pago</button>;
      case 'REVIEWED':
        return <BadgeNew text={'Enviado a Pagar'} type={'green'} />;
      case 'APPROVED':
        return <BadgeNew text={'Pagado'} type={'green'} icon={'check'} />;
      default:
        return status;
    }
  };

  const renderToast = () => {
    if (batchMutation.error) {
      if (batchMutation.error.response.data.errorCode === 'PENDING_BATCH_EXISTS') {
        return (
          <Toast
            message={'Debe auditar el lote de pago anterior antes de poder crear uno nuevo.'}
            type='ERROR'
            onCallback={() => {
              batchMutation.reset();
              setOpen(false);
            }}
          />
        );
      }

      return (
        <Toast
          message={'Ocurrio un error, vuelve a intentarlo más tarde.'}
          type='ERROR'
          onCallback={() => {
            batchMutation.reset();
          }}
        />
      );
    }

    if (batchMutation.isSuccess) {
      return (
        <Toast
          message={`Lote de pago creado exitosamente`}
          type='SUCCESS'
          onCallback={() => {
            setOpen(false);
            batchMutation.reset();
            batchQuery.refetch();
          }}
        />
      );
    }

    return null;
  };

  return (
    <>
      {renderToast()}
      <Subtitle
        border={false}
        text={<div className='mt-1 payment-title d-flex align-items-center'>Lotes de Pagos</div>}
        endComponent={
          hasAllowedRoles(roles, ['superadministrator', 'marketadmin', 'auditor', 'finance']) ? (
            <Button
              className={'btn-general btn-custom-primary'}
              text={'Crear Lote de Pago'}
              onClick={() => setOpen(true)}
              size={'small'}
            />
          ) : null
        }
      />
      {batchQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            data={batchQuery?.data?.data?.items ?? []}
            hasPagination={false}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            columns={headerAdmin}
            renderRow={(batch, index) => (
              <>
                <TableCell className='text-capitalize'>
                  {batch.createdAt
                    ? format(new Date(batch.createdAt), 'MMM dd, yyyy HH:mm', { locale }) + ' hs'
                    : '-'}
                </TableCell>

                <TableCell className='ctm-text font-weight-bold' type={'primary'}>
                  <Money value={batch.amount} currencyCode={batch.currency} />
                </TableCell>
                <TableCell>{batch.description}</TableCell>
                <TableCell>
                  {getStatus(batch.status)}
                  {/* <BadgeNew text={batch.status} type={'green'} /> */}
                  {/* <button className='btn-wellet'>Auditar lote de pago</button> */}
                </TableCell>
              </>
            )}
            onRowClick={(batch) => navigateToBatch(batch.id)}
          />
          <ModalComment
            open={open}
            fields={[
              {
                name: 'comment',
                placeholder: 'Descripción',
                label: 'Descripción',
                className: 'pt-2',
                isRequired: true,
              },
            ]}
            isLoading={batchMutation.isLoading || batchMutation.isSuccess}
            showComment={true}
            onClose={() => setOpen(false)}
            title={'Crear Lote de Pago'}
            textSubmit={'Crear'}
            maxWidth={'450px'}
            placeholder={'Ingrese una descripción'}
            handleSubmit={(data) => {
              batchMutation.mutate(data);
            }}
          />
        </>
      )}
    </>
  );
};

export default PaymentBatch;
