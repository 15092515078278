import React, { useContext } from 'react';
import { ConciergeDetail, Show, Table, TableCell } from '../../components';
import ModalRefund from './components/modalRefund';
import { WalletPayemntIcon, WarningModernIcon } from '../../uiComponents/icons';
import {
  Alert,
  ContanierSection,
  Divider,
  Loading,
  Money,
  OrganizationCard,
  PageError,
  Subtitle,
} from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { paymentLiveFeedBadge } from '../../helpers/status';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getAffectedPayments, getMonitorByChargeId } from './services/monitorDetailServices';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import { useCreateBreadCrumbs, useUser } from '../../hooks';
import { hasAllowedRoles } from '../../helpers/roles';

const MonitorDetail = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.monitorDetail' });
  const { t: translateBadge } = useTranslation();
  const welletContext = useContext(WelletContext);
  const { language, selectedOrganization } = useSelector((state) => state.app);
  const { id } = useParams();
  const location = useLocation();
  const currentPayment = location.pathname.split('/').filter((item) => item)[0];
  const locale = getLocale(language);
  const monitorIdQuery = useQuery({
    queryKey: ['monitor-detail', id],
    queryFn: () => getMonitorByChargeId(welletContext, selectedOrganization.marketId, id),
    ...defaultQueryOptions,
  });

  const isMonitor = currentPayment === 'transactions';
  const isFromAccount = currentPayment === 'accounts';
  const isFromAccountRefund = location.pathname.includes('accounts/refunds')

  const paymentAffectedQuery = useQuery({
    queryKey: ['affected-payments', id],
    queryFn: () => getAffectedPayments(welletContext, id),
    enabled: currentPayment === 'refunds' || isFromAccountRefund,
    ...defaultQueryOptions,
  });

  const hasRefundRequested = monitorIdQuery?.data?.data?.refundRequested;

  const isRequested = hasRefundRequested && monitorIdQuery?.data?.data?.status === 'OK';



  const { roles } = useUser();

  useCreateBreadCrumbs([
    {
      name: isMonitor
        ? t('breadcrumbs.monitor')
        : isFromAccount
        ? t('breadcrumbs.account')
        : t('breadcrumbs.refund'),
      path: isMonitor ? '/transactions' : isFromAccount ? '/accounts' : '/refunds',
    },
    {
      name:
        isMonitor || (isFromAccount && !isFromAccountRefund) ? t('breadcrumbs.monitorDetail') : t('breadcrumbs.refundDetail'),
    },
  ]);

  const isWelletFinance = hasAllowedRoles(roles, ['wellet_finance']);

  return (
    <Show>
      <Show.When isTrue={monitorIdQuery.isLoading}>
        <Loading />
      </Show.When>
      <Show.When isTrue={monitorIdQuery.isError}>
        <PageError />
      </Show.When>
      <Show.Else>
        <div className='container-page color-body'>
          <div className='container-header with-border'>
            <div className='row align-items-end'>
              <div className='col-auto'>
                <div className='light-grey  d-flex justify-content-start  align-items-center container-small-title'>
                  <WalletPayemntIcon size='14px' color='currentColor' customClass={'mr-2'} />
                  <div className=''>{t('payment')}</div>
                </div>
                <div className='container-title d-inline-flex flex-wrap'>
                  <div className='text-uppercase'>
                    <Money
                      fontWeight={'font-weight-semibold'}
                      value={monitorIdQuery?.data?.data?.amount}
                    />
                  </div>
                  <div className='d-inline ml-3'>
                    {monitorIdQuery?.data?.data?.status === 'OK' && hasRefundRequested
                      ? paymentLiveFeedBadge('REFUND_REQUESTED', translateBadge)
                      : paymentLiveFeedBadge(
                          monitorIdQuery?.data?.data?.status,
                          translateBadge,
                          isWelletFinance,
                        )}
                  </div>
                </div>
              </div>
              <div className='col my-auto'></div>
              <div className='col-auto'>
                <div className='row'>
                  <Show.When
                    isTrue={
                      (currentPayment === 'transactions' || currentPayment === 'accounts') &&
                      monitorIdQuery?.data?.data?.status === 'OK' &&
                      monitorIdQuery?.data?.data?.canRefund &&
                      !hasRefundRequested
                    }
                  >
                    <div className='col-auto mt-1'>
                      <ModalRefund payment={monitorIdQuery?.data?.data?.amount} />
                    </div>
                  </Show.When>
                </div>
              </div>
            </div>
          </div>
          <Show.When
            isTrue={
              monitorIdQuery?.data?.data?.status === 'REFUNDED' ||
              monitorIdQuery?.data?.data?.status === 'PARTIALLY_REFUNDED'
            }
          >
            <div
              className='col-auto d-flex align-items-center my-4'
              style={{ background: '#7F56D9', color: '#fff', padding: '4px 12px' }}
            >
              <WarningModernIcon color={'#fec73d'} size='20px' />
              <div className='fw-700 pl-2' style={{ fontSize: '16px', marginTop: '4px' }}>
                {t('refundMessage', {
                  refunded:
                    monitorIdQuery?.data?.data?.status === 'REFUNDED'
                      ? t('refunded')
                      : t('partially'),
                })}
              </div>
            </div>
          </Show.When>
          <Show.When
            isTrue={
              isRequested ||
              monitorIdQuery?.data?.data?.status === 'REFUNDED' ||
              monitorIdQuery?.data?.data?.status === 'PARTIALLY_REFUNDED'
            }
          >
            <ContanierSection maxWidth='1200px'>
              <div className='row no-gutters container-data margin-audit'>
                <div className='col-12 col-md-3 mb-2'>
                  <div className='dark-grey container-label'>{t('details.amountRequested')}</div>
                  <div className='fw-700 container-text'>
                    {monitorIdQuery?.data?.data?.refund?.amountRequest ? (
                      <Money
                        fontWeight={'font-weight-semibold'}
                        value={monitorIdQuery?.data?.data?.refund?.amountRequest}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>

                <div className='col-12 col-md-4 mb-2'>
                  <div className='dark-grey container-label'>{t('details.dateRequested')}</div>
                  <div className='fw-700 container-text sentences-capitalize'>
                    {monitorIdQuery?.data?.data?.refund?.requestDate
                      ? format(
                          new Date(monitorIdQuery?.data?.data?.refund?.requestDate),
                          'EEEE d MMM.',
                          {
                            locale,
                          },
                        )
                      : '-'}
                  </div>
                </div>

                <div className='col-12 col-md-4'>
                  <div className='dark-grey  container-label'>{t('details.reason')}</div>
                  <div className='fs-16 fw-700'>
                    {monitorIdQuery?.data?.data?.refund?.reason
                      ? translateBadge(
                          `functions.getReasonRefunds.${monitorIdQuery?.data?.data?.refund?.reason}`,
                        )
                      : '-'}
                  </div>
                </div>
              </div>
              <div className='row no-gutters container-data mb-1'>
                <div className='col-12 col-md-3 mb-2'>
                  <div className='dark-grey container-label'>{t('details.userRequestedBy')}</div>
                  <div className='fw-700 container-text '>
                    {monitorIdQuery?.data?.data?.refund?.requestedByUser ?? '-'}
                  </div>
                </div>

                <div className='col-12 col-md-4 text-truncate mb-2'>
                  <div className='dark-grey container-label'>{t('details.infoReason')}</div>
                  <div className='fs-16 fw-700'>
                    {monitorIdQuery?.data?.data?.refund?.description ?? '-'}
                  </div>
                </div>
              </div>
            </ContanierSection>
          </Show.When>
          <Show.When
            isTrue={
              isRequested ||
              monitorIdQuery?.data?.data?.status === 'REFUNDED' ||
              monitorIdQuery?.data?.data?.status === 'PARTIALLY_REFUNDED'
            }
          >
            <Subtitle text={t('paymentBreakdown.title')} />
            <ContanierSection maxWidth='1200px'>
              <div className='row mb-2'>
                <div className='col-auto'>{t('paymentBreakdown.paymentAmount')}</div>
                <div className='col text-right'>
                  <Money
                    currencyCode={monitorIdQuery?.data?.data?.currency}
                    value={monitorIdQuery?.data?.data?.amount}
                  />
                </div>
              </div>
              <Divider />
              <div className='row my-2'>
                <div className='col-auto'>{t('paymentBreakdown.refundedAmount')}</div>
                <div className='col text-right'>
                  {monitorIdQuery?.data?.data?.refundedAmount ? (
                    <>
                      -{' '}
                      <Money
                        currencyCode={monitorIdQuery?.data?.data?.currency}
                        value={monitorIdQuery?.data?.data?.refundedAmount}
                      />
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <Divider />
              <div className='row mt-2'>
                <div className='col-auto fw-600'>{t('paymentBreakdown.netAmount')}</div>
                <div className='col text-right fw-600'>
                  <Money
                    currencyCode={monitorIdQuery?.data?.data?.currency}
                    value={monitorIdQuery?.data?.data?.currentNetAmount}
                  />
                </div>
              </div>
              <Show.When isTrue={monitorIdQuery?.data?.data?.comissionsRefunded}>
                <Alert
                  title={t('commissionRefunded')}
                  className={'mt-4  py-2'}
                  type='notification'
                />
              </Show.When>
            </ContanierSection>
          </Show.When>

          <Show.When
            isTrue={
              (currentPayment === 'refunds' || isFromAccountRefund) && paymentAffectedQuery?.data?.data?.items?.length > 0
            }
          >
            <Subtitle text={t('paymentAffected.title')} />
            <ContanierSection maxWidth='1200px'>
              <div className='mb-3'>
                <Table
                  hasPagination={false}
                  borderTop={false}
                  showPagination={false}
                  columns={[
                    { title: t('paymentAffected.table.date') },
                    { title: t('paymentAffected.table.reservation') },
                    { title: t('paymentAffected.table.amount') },
                    { title: t('paymentAffected.table.title') },
                    { title: t('paymentAffected.table.description') },
                  ]}
                  pagination='normal'
                  borderContainer={true}
                  header='white'
                  showText={false}
                  data={paymentAffectedQuery?.data?.data?.items}
                  paddingTh={'padding-white-header'}
                  renderRow={(item) => {
                    const { dateTime } = formatDateTranslation(item.createdAt, language, 'small');
                    return (
                      <>
                        <TableCell>{dateTime}</TableCell>
                        <TableCell>
                          <div className='fw-600'>
                            {item?.restaurant?.name}{' '}
                            {item?.reservation?.referenceCode ? (
                              <>
                                -{' '}
                                {item.reservation?.id ? (
                                  <Link
                                    className='custom-link-file'
                                    to={`/refunds/reservation/${item.reservation?.id}`}
                                  >
                                    {item?.reservation?.referenceCode}
                                  </Link>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </TableCell>

                        <TableCell style={{ width: 'auto' }} type={'primary'}>
                          <div className='fw-600'>
                            {item.amount ? (
                              <>
                                {' '}
                                - <Money value={item.amount} />{' '}
                              </>
                            ) : (
                              '-'
                            )}
                          </div>
                        </TableCell>

                        <TableCell style={{ width: 'auto' }}>
                          {item.chargeId ? (
                            item.chargeId === id ? <div className='fw-500'>{item.chargeId}</div> : <div className='fw-600'>
                              <Link
                                className='custom-link-file'
                                to={`/transactions/${item.chargeId}`}
                              >
                                {item.chargeId}
                              </Link>
                            </div>
                              
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell
                          className={'text-wrap'}
                          style={{ maxWidth: '300px', minWidth: '220px' }}
                        >
                          {item.description}
                        </TableCell>
                      </>
                    );
                  }}
                  rowClass='not-hover'
                />
              </div>
            </ContanierSection>
          </Show.When>

          <Show.When
            isTrue={
              isRequested ||
              monitorIdQuery?.data?.data?.status === 'REFUNDED' ||
              monitorIdQuery?.data?.data?.status === 'PARTIALLY_REFUNDED'
            }
          >
            <Subtitle text={t('transaction.title')} />
          </Show.When>
          <ContanierSection maxWidth='1200px'>
            <div className='row no-gutters container-data margin-audit'>
              <div className='col-12 col-md-3 mb-2'>
                <div className='dark-grey container-label'>{t('details.dateReservation')}</div>
                <div className='fw-700 container-text sentences-capitalize'>
                  {monitorIdQuery?.data?.data?.paymentDate
                    ? format(new Date(monitorIdQuery?.data?.data?.paymentDate), 'EEEE d MMM.', {
                        locale,
                      })
                    : null}
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='dark-grey container-label'>Charge ID</div>
                <div className='fw-700 container-text text-lowercase'>
                  {monitorIdQuery?.data?.data?.chargeId ?? '-'}
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='dark-grey container-label'>{t('details.order')}</div>
                <div className='fw-700 container-text'>
                  <Link
                    className='custom-link-file'
                    to={
                      (currentPayment === 'refunds' || isFromAccountRefund)
                        ? `/refunds/reservation/${monitorIdQuery?.data?.data?.reservationId}`
                        : `/transactions/reservation/${monitorIdQuery?.data?.data?.reservationId}`
                    }
                  >
                    {monitorIdQuery?.data?.data?.referenceCode ?? '-'}
                  </Link>
                </div>
              </div>
            </div>
            <div className='row no-gutters container-data margin-audit'>
              <div className='col-12 col-md-3 mb-2'>
                <div className='dark-grey container-label'>{t('details.cardHolderName')}</div>
                <div className='fw-700 container-text'>
                  {monitorIdQuery?.data?.data?.customer ?? '-'}
                </div>
              </div>
              <div className='col-12 col-md-4 text-truncate mb-2'>
                <div className='dark-grey container-label'>{t('details.mail')}</div>
                <div className='fs-16 fw-700'>
                  {monitorIdQuery?.data?.data?.customerEmail ?? '-'}
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='dark-grey  container-label'>{t('details.brand')}</div>
                <div className='fs-16 fw-700'>
                  <div className='d-flex align-items-center'>
                    <Show.When isTrue={monitorIdQuery?.data?.data?.brand}>
                      <img
                        style={{ height: '35px' }}
                        src={
                          'https://cdn1.wellet.fun/images/credit-card/' +
                          monitorIdQuery?.data?.data?.brand?.toLocaleLowerCase() +
                          '-logo.png'
                        }
                        className='mr-2'
                        alt='card'
                      />
                    </Show.When>
                    <div className='fw-700  mr-2'>
                      <div className='fs-2xs'>
                        {monitorIdQuery?.data?.data?.last4
                          ? `**** ${monitorIdQuery?.data?.data?.last4} `
                          : null}
                      </div>
                      <div className='fs-3xs dark-grey'>
                        {monitorIdQuery?.data?.data?.expirationMonth
                          ? `${monitorIdQuery?.data?.data?.expirationMonth}/${monitorIdQuery?.data?.data?.expirationYear} `
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row no-gutters container-data mb-1'>
              <div className='col-12 col-md-3 mb-2'>
                <div className='dark-grey  container-label'>{t('details.gateway')}</div>
                <div className='fs-16 fw-700'>
                  {monitorIdQuery?.data?.data?.gatewayName
                    ? monitorIdQuery?.data?.data?.gatewayName.charAt(0).toLocaleUpperCase() +
                      monitorIdQuery?.data?.data?.gatewayName.slice(1).toLocaleLowerCase()
                    : '-'}
                </div>
              </div>
              {/* <div className='col-12 col-md-4 text-truncate mb-2'>
                <div className='dark-grey container-label'>{t('details.description')}</div>
                <div className='fs-16 fw-700'>{monitorIdQuery?.data?.data?.description ?? '-'}</div>
              </div> */}
            </div>
          </ContanierSection>

          <Show.When isTrue={monitorIdQuery?.data?.data?.seller}>
            <ConciergeDetail
              data={{
                concierge: monitorIdQuery?.data?.data?.seller,
                rp: monitorIdQuery?.data?.data?.rp,
                boss: monitorIdQuery?.data?.data?.boss,
              }}
              language={language}
            />
          </Show.When>
        </div>
      </Show.Else>
    </Show>
  );
};

export default MonitorDetail;
