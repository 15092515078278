import React from 'react';

const DifusionIcon = ({ size = '16px', color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_556_31799)'>
        <path
          d='M3.64332 4.78271H7.11284V14.7759H3.64332C1.7988 14.7759 0.302307 13.3136 0.302307 11.5111V8.04751C0.302307 6.24507 1.7988 4.78271 3.64332 4.78271Z'
          fill={color}
        />
        <path d='M16.1882 17.3919L8.09265 14.7758V4.78264L16.1882 2.16663V17.3919Z' fill={color} />
        <path
          d='M5.35666 15.6H7.53849L9.72033 16.3115V18.6005C9.72033 19.7778 8.74319 20.7326 7.53849 20.7326C6.3338 20.7326 5.35666 19.7778 5.35666 18.6005V15.6Z'
          fill={color}
        />
        <path
          d='M21.6976 2.48061C21.6976 1.25832 20.6836 0.267456 19.4328 0.267456C18.182 0.267456 17.168 1.25832 17.168 2.48061V17.078C17.168 18.3003 18.182 19.2911 19.4328 19.2911C20.6836 19.2911 21.6976 18.3003 21.6976 17.078V2.48061Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_556_31799'>
          <rect
            width='21.3953'
            height='20.4651'
            fill={color}
            transform='translate(0.302307 0.267456)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DifusionIcon;