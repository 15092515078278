import React from 'react';
import Popup from 'reactjs-popup';
import { AskIcon } from '../../icons';
import './tooltip.css';

const NewTooltip = ({ tooltip, position = 'top' }) => {
  return (
    <Popup
      trigger={() => (
        <div>
          <AskIcon color='#98A2B3' />{' '}
        </div>
      )}
      on={'hover'}
      arrowStyle={{ color: '#0C111D' }}
      position={position}
      arrow={true}
      closeOnDocumentClick
    >
      <div className='fw-600 new-tooltip'>
        <div dangerouslySetInnerHTML={{ __html: tooltip }} className='w-auto' />
      </div>
    </Popup>
  );
};

export default NewTooltip;
