import React from 'react';
import { MsgIcon } from '../../uiComponents/icons';
import { useTranslation } from 'react-i18next';

const Comments = ({ text }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.comments' });
  return (
    <div className='d-flex flex-column w-100' style={{ color: '#687385' }}>
      <div className=' w-100 d-flex justify-content-start align-items-center'>
        <MsgIcon size='12px' />
        <div className='ml-2 font-weight-light'>{t('title')}</div>
      </div>

      <div className='ml-3 font-italic font-weight-semibold'>{text}</div>
    </div>
  );
};

export default Comments;
