import React from 'react';
import { useSelector } from 'react-redux';
import { PageHeader, SchedulesForm } from '../../components';
import { useCreateBreadCrumbs } from '../../hooks';
import { useTranslation } from 'react-i18next';

const Schedules = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.schedule'})
  const organization = useSelector((state) => state.app.selectedOrganization.name);
  useCreateBreadCrumbs([{ name: organization, path: '/restaurantes' }, { name: t('title') }]);

  return (
    <>
      <PageHeader title={t('title')} marginBottom={false} />
      <SchedulesForm />
    </>
  );
};

export default Schedules;
