const initialState = {
  isAuthenticated: false,
  isLogin: false,
  user: null,
  jwt: null,
  roles: null,
  authChecked: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ASSOCIATES_AUTH_CHECKED':
      return {
        ...state,
        authChecked: true,
      };
    case 'SET_LOGIN_LOADING':
      return {
        ...state,
        isLogin: action.payload,
      };
    case 'ASSOCIATES_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: true,
        jwt: action.jwt,
        roles: Array.isArray(action.userRoles) ? action.userRoles : [action.userRoles],
      };
    case 'ASSOCIATES_INFO':
      return {
        ...state,
        authChecked: true,
        user: action.payload,
      };
    case 'UNAUTHORIZED':
      return {
        ...state,
        authChecked: true,
        roles: 'unauthorized',
      };
    case 'CLEAN_UNAUTHORIZED':
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        roles: null,
        jwt: '',
      };
    case 'ASSOCIATES_LOGOUT':
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        roles: null,
        jwt: '',
      };
    case 'SET_NOTIFICATION':
      return {
        ...state,
        user: { ...state.user, hasNotification: action.payload },
      };
    default:
      return state;
  }
};

export default authReducer;
