import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../components';
import NewTooltip from '../tooltip/newTooltip';

const InputTime = ({
  name,
  className,
  label,
  icon,
  required = true,
  isDisabled = false,
  useError = true,
  tooltip = null,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.input.errors' });
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleChange = (event) => {
    let value = event.target.value;

    value = value.replace(/\D/g, '');

    if (value.length > 4) {
      value = value.substring(0, 4);
    }

    let formattedTime = '';

    if (value.length === 3) {
      formattedTime = value.slice(0, 2) + ':' + value.slice(2);
    } else if (value.length === 2) {
      formattedTime = value;
    } else if (value.length === 1) {
      formattedTime = value;
    } else if (value.length === 0) {
      formattedTime = '';
    } else {
      formattedTime = value.slice(0, 2) + ':' + value.slice(2);
    }

    return formattedTime;
  };

  const defaultValidationRule = {
    validate: (value) => {
      if (value === '' && required) return t('filesRequired');
      if (value !== '' && value.length < 5) {
        return t('hourFormat');
      }
      if (value !== '' && !/^([0-9]{1,2}|[0-9]{1,2}:[0-5]?[0-9])$/.test(value)) {
        return t('hourFormatTwo');
      }
    },
  };

  const validationRules = defaultValidationRule;

  if (required) {
    validationRules.required = t('filesRequired');
  }

  return (
    <div className={className}>
      <div className='d-flex align-items-center'>
        {label ? <label className='mr-1' style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
        <Show.When isTrue={tooltip !== null}>
          <NewTooltip tooltip={tooltip} position={'top'} />
        </Show.When>
      </div>
      <Controller
        name={name}
        control={control}
        defaultValue=''
        rules={validationRules}
        render={({ field }) => (
          <>
            <div className={`d-flex input-base-container ${errors[name] ? 'input-error' : ''}`}>
              {icon ? (
                <span
                  className='input-group-text px-2 input-icon'
                  style={{ color: 'var(--color-subtitle)', background: '#fff' }}
                >
                  {icon}
                </span>
              ) : (
                <></>
              )}
              <input
                type='text'
                {...field}
                placeholder='--:--'
                className={`input-style ${errors[name] && 'input-error'}`}
                disabled={isDisabled}
                style={{ visibility: 'visible', position: 'static', zIndex: 'auto' }}
                onChange={(e) => field.onChange(handleChange(e))}
              />
            </div>
            {useError && errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InputTime;
