export const getAllBatchs = async (
  welletContext,
  marketId = null,
  limit,
  skip,
  status = null,
  onlyApprovedPayments = false,
) => {
  return await welletContext.apis.admin.post(`/api/payment/batchs/${marketId}`, {
    limit,
    skip,
    status,
    onlyApprovedPayments,
  });
};

export const getBatchByIds = async (
  welletContext,
  marketId,
  id = null,
  limit,
  skip,
  withdrawalmethods = [],
  concierges = [],
  money,
  status = [],
  sortBy,
  columnFilter,
) => {
  const body = {
    limit,
    skip,
    withdrawalmethods: withdrawalmethods,
    moneyFrom: money.min,
    moneyTo: money.max,
    concierges: concierges,
    status: status,
    sortBy: sortBy,
    columnFilter: columnFilter,
  };
  return await welletContext.apis.admin.post(`/api/payment/batchs/${marketId}/details/${id}`, body);
};

export const getBatchStatus = async (welletContext) => {
  return await welletContext.apis.admin.get(`/api/payment/GetBatchStatus`);
};

export const postBatch = async (welletContext, paymentBatchId, body) => {
  return await welletContext.apis.tickets.post(`admin/paymentBatch/${paymentBatchId}/review`, body);
};

export const getConciergePayment = async (welletContext, id, seller) => {
  return await welletContext.apis.admin.get(`api/payment/batchs/${id}/associate/${seller}`);
};

export const getOrganizationsBatchs = async (welletContext, id, body) => {
  return await welletContext.apis.admin.post(`api/payment/batchs/${id}/organizations`, body);
};

export const postDispute = async (welletContext, orderId, body) => {
  return await welletContext.apis.tickets.post(`admin/order/${orderId}/dispute`, body);
};

export const approveReservation = async (welletContext, orderId, body) => {
  return await welletContext.apis.tickets.post(`admin/order/${orderId}/approve`, body);
};
export const cancelReservation = async (welletContext, body) => {
  return await welletContext.apis.tickets.post(`admin/reservation/cancel`, body);
};

export const putAdjustment = async (welletContext, body) => {
  return await welletContext.apis.tickets.put(`admin/associate/adjustment`, body);
};

export const getDisputesOLD = async (welletContext, id, limit, skip) => {
  return await welletContext.apis.admin.get(`api/orders/market/${id}/disputed/${skip}/${limit}`);
};

export const getDisputeById = async (welletContext, id, orgId) => {
  return await welletContext.apis.admin.get(`/api/orders/${id}/market/${orgId}`);
};

export const postApproveDispute = async (welletContext, orderId, body) => {
  return await welletContext.apis.tickets.post(`admin/order/${orderId}/approve-dispute`, body);
};

export const postRejectDispute = async (welletContext, orderId, body) => {
  return await welletContext.apis.tickets.post(`admin/order/${orderId}/reject-dispute`, body);
};

export const putCreateBatch = async (welletContext, body) => {
  return await welletContext.apis.tickets.put(`admin/associate/paymentBatch`, body);
};

export const postPreviewCreateBatch = async (welletContext, body) => {
  return await welletContext.apis.tickets.post(`admin/associate/paymentBatch/preview`, body);
};

export const postExecutePayment = async (welletContext, paymentBatchId) => {
  return await welletContext.apis.tickets.post(`admin/paymentBatch/${paymentBatchId}/approve`);
};

export const getBatchSummaryById = async (welletContext, marketId, id = null, limit, skip) => {
  const body = {
    limit,
    skip,
  };
  return await welletContext.apis.admin.post(`/api/payment/batchs/${marketId}/summary/${id}`, body);
};

export const downloadPaymentSummary = async (welletContext, marketId, batchId) => {
  return await welletContext.apis.admin.get(
    `/api/excelreport/payment/${marketId}/${batchId}`,
    {
      responseType: 'arraybuffer',
    },
    { 'Content-Type': 'blob' },
  );
};

export const getPaymentByMethod = async (welletContext, marketId, body) => {
  return await welletContext.apis.admin.post(`api/payment/${marketId}/associates`, body);
};
export const postReviewWire = async (welletContext, paymentId, body) => {
  return await welletContext.apis.tickets.post(`admin/associate/payment/${paymentId}/settle`, body);
};

export const getFile = async (welletContext, url) => {
  return await welletContext.apis.tickets.get(url);
};
export const getWireDetail = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/payment/audit/${id}`);
};
export const downloadPayment = async (welletContext, id, body) => {
  return await welletContext.apis.admin.post(
    `/api/ExcelReport/${id}/payments`,
    body,
    {
      responseType: 'arraybuffer',
    },
    { 'Content-Type': 'blob' },
  );
};
export const getWireComissionsDetails = async (
  welletContext,
  paymentId,
  limit,
  skip,
  sortBy,
  columnFilter,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
  };
  return await welletContext.apis.admin.post(`/api/payment/commissions/details/${paymentId}`, body);
};
export const getWireRestaurantsDetails = async (
  welletContext,
  paymentId,
  limit,
  skip,
  sortBy,
  columnFilter,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
  };
  return await welletContext.apis.admin.post(`/api/payment/restaurant/details/${paymentId}`, body);
};
export const getWireOrdersDetails = async (
  welletContext,
  paymentId,
  limit,
  skip,
  sortBy,
  columnFilter,
) => {
  const body = {
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    columnFilter: columnFilter,
  };
  return await welletContext.apis.admin.post(
    `/api/wallet/welletorder/${paymentId}/payment-detail`,
    body,
  );
};

export const putRetryPayment = async (welletContext, associatePaymentId) => {
  return await welletContext.apis.tickets.put(`admin/associate/payment/${associatePaymentId}/redo`);
};
