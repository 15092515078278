import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ErrorAlert,
  InputDate,
  InputNumber,
  Modal,
  Select,
  Toast,
} from '../../../uiComponents/common';

import { WelletContext } from '../../../context/wellet/welletContext';
import { fetchActivities } from '../../../services/activityServices';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import Weekdays from '../../../data/weekdays.json';

import {
  FwCalendarIcon,
  FwClockIcon,
  FwCoinsIcon,
  FwPriceIcon,
  SaleIcon,
} from '../../../uiComponents/icons';
import FwMasksIcon from '../../../uiComponents/icons/font-awesome/fwMasksIcon';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import { createPrice, getAllPerformances, getAllTickets, updatePrice } from '../../../services';

const PriceForm = ({ open, onClose, price, setPrice, onSuccess }) => {
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const currencies = [
    { label: 'USD', value: 'USD' },
    { label: 'MXN', value: 'MXN' },
  ];

  const lastClickedButton = useRef(null);
  const [isRedirect, setIsRedirect] = useState(null);
  const [currentActivity, setCurrentActivity] = useState({ value: price?.activity?.id });
  const [isFirstRender, setIsFirstRender] = useState(true);

  const activitiesQuery = useQuery({
    queryKey: ['price-activities', organization.id],
    queryFn: () => fetchActivities(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const ticketsQuery = useQuery({
    queryKey: ['tickets', currentActivity?.value, organization.id],
    queryFn: () =>
      getAllTickets(
        welletContext,
        [1, 2, 3, 4, 5, 6],
        null,
        null,
        false,
        currentActivity?.value,
        organization.id,
      ),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!currentActivity?.value,
  });
  const performancesQuery = useQuery({
    queryKey: ['performances', currentActivity?.value, organization.id],
    queryFn: () =>
      getAllPerformances(welletContext, organization.id, null, null, false, currentActivity?.value),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!currentActivity?.value,
  });
  const priceMutation = useMutation({
    mutationFn: (body) => {
      if (price) return updatePrice(welletContext, body);
      else return createPrice(welletContext, body);
    },
    onSuccess: (data, body) => {
      if (price) setPrice({ ...body, ids: data.data });
    },
    onError: (error) => {
      console.log('Desde on error', error);
    },
  });

  const tickets = ticketsQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const activities = activitiesQuery?.data?.map((v) => ({ label: v.name, value: v.id }));
  const defaultForm = {
    activity: activities?.find((a) => a.value === price?.ticketProduct?.showId),
    tickets: price?.ticketProduct?.id
      ? [{ label: price?.ticketProduct?.name, value: price?.ticketProduct?.id }]
      : null,
    dateFrom: price?.fromDate?.split('T')[0],
    dateTo: price?.toDate?.split('T')[0],
    consumption: price?.consumption,
    currency: currencies.find((l) => l.label === price?.currency),
    price: price?.price,
    extra_price: price?.additionalGuestPrice,
    performance:
      price?.performancePriceRule?.length > 0
        ? price.performancePriceRule?.map((p) => ({
            label: p.activityTime,
            value: p.performanceId,
          }))
        : null,
    weekdays: price?.weekdays?.map((value) => getWeekdayLabel(value)),
  };
  const methods = useForm({
    defaultValues: useMemo(() => {
      return defaultForm;
    }, [price]),
  });
  const performances = performancesQuery?.data?.data?.map((v) => ({
    label: v.time,
    value: v.id,
  }));

  useEffect(() => {
    if (methods.watch('activity')?.value) {
      setCurrentActivity(methods.watch('activity'));
      if (!isFirstRender) {
        methods.setValue('tickets', null);
        methods.setValue('performance', null);
      } else {
        setIsFirstRender(false);
      }
    }
  }, [methods.watch('activity')]);
  useEffect(() => {
    setIsFirstRender(true);
    methods.reset(defaultForm);
  }, [open]);

  const dateFrom = methods.watch('dateFrom');
  const dateTo = methods.watch('dateTo');
  const activePerformances = methods.watch('performance');

  const onSubmit = (data) => {
    const body = {
      ids: price?.ids ? price.ids : [],
      Weekdays: data.weekdays.map((d) => d.value),
      PerformanceIds: data.performance.map((d) => d.value),
      From: new Date(`${data.dateFrom}T00:00:00`),
      To: new Date(`${data.dateTo}T00:00:00`),
      Price: Number(data.price),
      AdditionalGuestPrice: Number(data.extra_price),
      Currency: data.currency.value,
      Consumption: Number(data.consumption),
      ProductId: Array.isArray(data.tickets) ? data.tickets[0].value : data.tickets.value,
    };

    priceMutation.mutate(body);
  };
  function getWeekdayLabel(value) {
    const weekday = Weekdays.find((day) => day.value === value);

    return weekday || null;
  }
  function getUniqueWeekdaysFromPerformances(activePerformances) {
    if (!activePerformances) {
      return [];
    }

    const weekdaysSet = new Set();

    activePerformances.forEach((performance) => {
      const currentPerformance = performancesQuery?.data?.data?.find(
        (p) => p.id === performance.value,
      );

      if (currentPerformance && Array.isArray(currentPerformance.weekdays)) {
        const performanceWeekdays = currentPerformance.weekdays;

        performanceWeekdays.forEach((day) => {
          weekdaysSet.add(day);
        });
      }
    });

    const weekdays = Array.from(weekdaysSet);

    const weekdaysWithLabels = weekdays.map((value) => getWeekdayLabel(value));

    return weekdaysWithLabels;
  }
  const handleCloseForm = () => {
    setPrice();
    priceMutation.reset();
    methods.reset({
      activity: null,
      consumption: null,
      currency: null,
      dateFrom: null,
      dateTo: null,
      extra_price: null,
      performance: null,
      price: null,
      tickets: null,
      weekdays: null,
    });
    setIsFirstRender(true);
    onClose();
  };
  const handleToastCallback = () => {
    if (isRedirect) {
      handleCloseForm();
    } else {
      priceMutation.reset();
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  const weekdays = getUniqueWeekdaysFromPerformances(activePerformances);
  const customButtons = (
    <div className='row mt-5 align-items-center'>
      <Button
        size={'small'}
        className={'btn-custom-primary'}
        type='button'
        text={`${
          priceMutation.isLoading && lastClickedButton.current === 'send' ? 'Sending...' : 'Send'
        }`}
        onClick={() => {
          methods.handleSubmit(onSubmit)();
          handleRedirect(false, 'send');
        }}
      />
      <Button
        size={'small'}
        type='button'
        className={'btn-custom-white  ml-2'}
        text={`Cancel`}
        onClick={handleCloseForm}
      />
      <Button
        size={'small'}
        className={'btn-custom-primary ml-2'}
        type='button'
        text={`${
          priceMutation.isLoading && lastClickedButton.current === 'sendAndClose'
            ? 'Sending...'
            : 'Send and Close'
        }`}
        onClick={() => {
          methods.handleSubmit(onSubmit)();
          handleRedirect(true, 'sendAndClose');
        }}
      />
    </div>
  );

  return (
    <>
      {priceMutation.isSuccess && (
        <Toast
          message={`Price ${price ? 'updated' : 'created'} successfully.`}
          type='SUCCESS'
          onCallback={() => {
            handleToastCallback();
            onSuccess();
          }}
        />
      )}

      <FormProvider {...methods}>
        <Modal title={'Price Rule'} open={open} nested={true} customButtons={customButtons}>
          <form key={1}>
            <div className='mt-2 row mx-0'>
              <Select
                options={activities}
                label={'Activity'}
                className={'col-12 col-md-6'}
                icon={<FwMasksIcon />}
                name={'activity'}
                isRequired={true}
              />
              <Select
                options={tickets}
                label={'Tickets'}
                className={'col-12 col-md-6'}
                icon={<SaleIcon />}
                name={'tickets'}
                isDisabled={!!!methods.watch('activity')?.label}
                isRequired={true}
              />
            </div>
            <div className='row m-0'>
              <InputNumber
                label={'Unit Price'}
                name={'price'}
                className={'col-12 col-md-6 mb-3'}
                icon={<FwPriceIcon />}
                placeholder={'Price'}
                customValidation={{}}
                isRequired={true}
              />
              <InputNumber
                label={'Extra Guest Price'}
                name={'extra_price'}
                className={'col-12 col-md-6  mb-3'}
                icon={<FwPriceIcon />}
                placeholder={'Extra Guest Price'}
                customValidation={{}}
                isRequired={true}
              />
            </div>
            <div className='row m-0'>
              <InputNumber
                label={'Consumption Included'}
                name={'consumption'}
                className={'col'}
                icon={<FwPriceIcon />}
                placeholder={'Consumption Included'}
                customValidation={{}}
                isRequired={true}
              />
              <Select
                options={currencies}
                label={'Currency'}
                className={'col-12 col-md-6'}
                icon={<FwCoinsIcon />}
                name={'currency'}
                isRequired={true}
              />
            </div>
            <div className='row m-0'>
              <Select
                options={performances}
                label={'Performance'}
                className={'col-12'}
                icon={<FwClockIcon />}
                name={'performance'}
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
                isRequired={true}
              />
              <Select
                options={weekdays}
                label={'Weekdays'}
                className={'col-12'}
                icon={<FwClockIcon />}
                name={'weekdays'}
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
                isRequired={true}
              />
            </div>
            <div className='row m-0'>
              <InputDate
                name={'dateFrom'}
                label={'From'}
                className={'col'}
                placeholder={'Select date from'}
                isRequired={true}
                icon={<FwCalendarIcon />}
              />
              <InputDate
                name={'dateTo'}
                label={'To'}
                className={'col'}
                placeholder={'Select date to'}
                icon={<FwCalendarIcon />}
              />
            </div>
            {priceMutation?.error && !(priceMutation?.error?.response?.data instanceof Object) ? (
              <ErrorAlert text={priceMutation?.error?.response?.data} />
            ) : (
              <></>
            )}
          </form>
        </Modal>
      </FormProvider>
    </>
  );
};

export default PriceForm;
