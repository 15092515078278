import React from 'react';
import Button from '../button/button';

const DobleFormButton = ({
  clearForm,
  textOne,
  textTwo,

  handleRedirect,
  mutation,
  reff,
  submit,
}) => {
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const onClickBtn = (string) => {
    handleRedirect(string);
    submit();
  };
  return (
    <div className='row align-items-center justify-content-center justify-content-sm-start'>
      <Button
        size={'medium'}
        type='submit'
        className={'mr-2 btn-custom-rejected'}
        text={capitalizeFirstLetter(textOne)}
        onClick={() => onClickBtn(textOne)}
        disabled={mutation.isLoading}
        reff={reff}
      />
      <Button
        size={'medium'}
        type='submit'
        text={capitalizeFirstLetter(textTwo)}
        onClick={() => onClickBtn(textTwo)}
        className={'mr-2 btn-custom-approved'}
        disabled={mutation.isLoading}
        reff={reff}
      />
    </div>
  );
};

export default DobleFormButton;
