import React, { useContext, useEffect, useRef } from 'react';
import { Subtitle } from '../../uiComponents/common';
import { OrganizationForm } from '../../components';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  getTimeZones,
  getCountriesByMarketId,
  getSocialNetworks,
  getContactInformation,
} from '../../services';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateBreadCrumbs } from '../../hooks';
import { useTranslation } from 'react-i18next';

const OrganizationParentForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'forms.organization' });
  const navigate = useNavigate();
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.currentOrganization);
  const prevOrganization = useRef(organization);
  const location = useLocation();
  const isEditingOrganization = location.pathname.split('/')[1] === 'edit-organization';
  const breadcrumbs = [
    { name: t('breadcrumbs.prev'), path: '/organizations' },
    { name: isEditingOrganization ? t('breadcrumbs.current.edit') : t('breadcrumbs.current.new') },
  ];

  const timezoneQuery = useQuery({
    queryKey: ['timezones'],
    queryFn: () => getTimeZones(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const countriesQuery = useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountriesByMarketId(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const socailNetworkQuery = useQuery({
    queryKey: ['social'],
    queryFn: () => getSocialNetworks(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const contactInformationQuery = useQuery({
    queryKey: ['contact'],
    queryFn: () => getContactInformation(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (organization.id !== prevOrganization.current.id) {
      navigate('/organizations');
    }
  }, [organization]);

  useCreateBreadCrumbs(breadcrumbs);

  return (
    <>
      <Subtitle
        text={
          <h1 className='m-0 ctm-title font-weight-bold'>
            {isEditingOrganization ? t('title.edit') : t('title.new')}
          </h1>
        }
      />

      <OrganizationForm
        timezoneQuery={timezoneQuery}
        organization={organization}
        countriesQuery={countriesQuery}
        isEditingOrganization={isEditingOrganization}
        socialNetwork={socailNetworkQuery}
        contactInformation={contactInformationQuery}
      />
    </>
  );
};

export default OrganizationParentForm;