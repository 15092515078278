import styles from './badgeOutline.module.css';
const BadgeOutline = ({ text, type }) => {
  return (
    <div className={`${styles.badge} ${styles.strong} ${styles[type]}`}>
      <span className={`dot ${type} mr-1 d-inline-block`}></span>
      {text}
    </div>
  );
};

export default BadgeOutline;
