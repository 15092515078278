import React, { useContext, useState } from 'react';
import { Button, Modal, Select, Toast } from '../../../uiComponents/common';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Show } from '../../../components';
import { WelletContext } from '../../../context/wellet/welletContext';
import { requestRefund } from '../services/monitorDetailServices';
import { FormProvider, useForm } from 'react-hook-form';
import { InputDescription, InputNumber } from '../../../uiComponents/common/inputs';
import { useSelector } from 'react-redux';

const ModalRefund = ({ payment }) => {
  const currency = useSelector((state) => state.app.selectedOrganization);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalRefund' });
  const { id } = useParams();
  const welletContext = useContext(WelletContext);
  const [openRefund, setOpenRefund] = useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      amount: payment,
      comment: null,
      reason: null,
    },
  });

  const sendRefundMutation = useMutation({
    mutationFn: (body) => {
      return requestRefund(welletContext, id, {
        reason: body.reason.value,
        description: body.comment,
        amount: body.amount,
      });
    },
  });

  const handleModal = () => {
    setOpenRefund((prevState) => !prevState);
    methods.reset({
      amount: payment,
      comment: null,
      reason: null,
    });
  };

  const onSubmit = (data) => {
    return sendRefundMutation.mutate({
      amount: data.amount,
      comment: data.comment,
      reason: data.reason,
    });
  };

  const handleToast = () => {
    sendRefundMutation.reset();
    navigate(`/refunds/${id}`);
    handleModal();
  };

  const disableInputs = !sendRefundMutation.isIdle;

  const reasonData = [
    { label: t('missing'), value: 'MISSING' },
    { label: t('duplicate'), value: 'DUPLICATE' },
    { label: t('fraudulent'), value: 'FRAUDULENT' },
    { label: t('byCustomer'), value: 'REQUESTED_BY_CUSTOMER' },
    { label: t('other'), value: 'OTHER' },
  ];

  return (
    <>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
        onClick={handleModal}
        size={'custom'}
        text={t('button')}
      />

      <Show.When isTrue={openRefund}>
        <Modal
          open={openRefund}
          title={t('title')}
          maxWidth={'450px'}
          customButtons={
            <>
              <div className=' px-2 mt-2 d-flex justify-content-end'>
                <Button
                  text={t('cancel')}
                  disabled={disableInputs}
                  className={' btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                <Button
                  text={t('submit')}
                  disabled={disableInputs}
                  className={' ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={() => methods.handleSubmit(onSubmit)()}
                />
              </div>
            </>
          }
        >
          <FormProvider {...methods}>
            <form>
              <div className='text-left fw-500 mt-2'>{t('ask')}</div>
              <div className='row mt-3'>
                <InputNumber
                  name={'amount'}
                  icon={<>$</>}
                  label={t('amount.label')}
                  placeholder={t('amount.placeholder')}
                  isRequired={true}
                  acceptNegative={false}
                  className='mb-2 col-12'
                  customValidation={{
                    validate: (value) => {
                      if (!value || parseFloat(value) < 0) {
                        return t('equalsZero');
                      }
                      if (!value || parseFloat(value) > payment) {
                        return `${t('limit')} ${currency.currencySymbol} ${payment?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`;
                      }
                      if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                        return t('decimals');
                      }
                      return true;
                    },
                  }}
                />

                <Select
                  options={reasonData}
                  label={t('reason.label')}
                  placeholder={t('reason.placeholder')}
                  className={'mb-2 col-12'}
                  name={'reason'}
                  isRequired={true}
                />

                {methods.watch('reason')?.value ? (
                  <InputDescription
                    name={'comment'}
                    label={''}
                    placeholder={t('comment.placeholder')}
                    isRequired={false}
                    minLength={0}
                    maxLength={512}
                    height='80px'
                    className='mb-2 col-12'
                  />
                ) : null}
              </div>
            </form>
          </FormProvider>
        </Modal>
      </Show.When>
      <Show.When isTrue={sendRefundMutation.isSuccess}>
        <Toast message={t('toast.success')} type='SUCCESS' onCallback={handleToast} />
      </Show.When>
      <Show.When isTrue={sendRefundMutation.isError}>
        <Toast message={t('toast.error')} type='ERROR' onCallback={handleToast} />
      </Show.When>
    </>
  );
};

export default ModalRefund;
