import React, { useContext, useState } from 'react';
import { Button, InputDescription, Modal, Toast } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { postConfirmRejectReservation } from '../../services';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Show } from '../../components';

const ModalModification = ({ onClose, handleSubmit, reservation, callback, language, mode }) => {
  const methods = useForm({
    defaultValues: {
      reason: null,
    },
  });
  const welletContext = useContext(WelletContext);
  const [openDetail, setOpenDetail] = useState('');
  const [openError, setOpenError] = useState(false);
  const [openConfirm, setOpenConfirm] = useState({ open: false, mode: null });
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalModification' });

  const confirmMutation = useMutation({
    mutationFn: (data) => {
      return postConfirmRejectReservation(welletContext, {
        reservationId: reservation?.actualReservation?.id,
        isConfirm: openConfirm.mode === 'new',
        reason: data?.reason,
      });
    },
    onSuccess: (data) => {
      if (data.data) {
        handleOpenDetail(openConfirm.mode);
        callback();
      } else {
        handleError();
      }
    },
  });

  const handleModal = (mode) => {
    setOpenConfirm((prevState) => ({
      ...prevState,
      open: !prevState.open,
      mode: mode !== undefined ? mode : prevState.mode,
    }));
  };
  const handleOpenDetail = (newDetail) => {
    setOpenDetail((prevDetail) => (prevDetail !== newDetail ? newDetail : prevDetail));
  };
  const handleError = () => {
    setOpenError((prevState) => !prevState);
  };

  const handleCloseConfirm = () => {
    methods.reset({
      reason: null,
    });
    handleModal();
  };
  const handleClose = () => {
    handleOpenDetail('');
    handleError();
    onClose();
  };
  const onSubmit = (data) => {
    const body = {
      isConfirm: true,
      reason: data.reason,
    };

    return confirmMutation.mutate(body);
  };

  const lng = language === 'es' ? t('spanish') : t('english');
  return (
    <>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate  mr-3'}
        onClick={() => handleModal('actual')}
        size={'custom'}
        text={<>{t('keepActual')}</>}
      />
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
        onClick={() => handleModal('new')}
        size={'custom'}
        text={
          <>
            <span className='dot dot-green mr-2 d-inline-block'></span>
            {t('confirmNew')}
          </>
        }
      />
      <Show.When isTrue={openConfirm.open && openDetail === ''}>
        <FormProvider {...methods}>
          <Modal
            title={openConfirm.mode === 'new' ? t('confirmReservation') : t('keepReservation')}
            open={openConfirm.open && openDetail === ''}
            onClose={handleCloseConfirm}
            maxWidth='450px'
            closeIcon={true}
            handleSubmit={handleSubmit}
            closeOnDocumentClick={false}
            customButtons={
              <>
                <div className='row px-2 text-center'>
                  <Button
                    text={t('cancel')}
                    disabled={confirmMutation.isLoading}
                    className={'col btn-wellet-secondary'}
                    size={'medium'}
                    onClick={handleCloseConfirm}
                  />
                  <Button
                    text={`${t('yes')} ${openConfirm.mode === 'new' ? t('confirm') : t('keep')}`}
                    disabled={confirmMutation.isLoading}
                    className={'col ml-2 btn-wellet-primary'}
                    size={'medium'}
                    onClick={() => methods.handleSubmit(onSubmit)()}
                  />
                </div>
              </>
            }
          >
            <div className='text-left mt-2'>
              {`${t('areYouSure')}  ${openConfirm.mode === 'new' ? t('confirm') : t('keep')} ${t(
                'theReservation',
              )}`}
            </div>
            <div className='row'>
              <InputDescription
                maxLength={512}
                label={t('conciergeComment')}
                name={'reason'}
                height='75px'
                isDisabled={confirmMutation.isLoading}
                placeholder={`${t('conciergePlaceholder')} (${lng})`}
                isRequired={false}
                dropdown={true}
                minLength={0}
                className='col pt-2 pb-0'
              />
            </div>
          </Modal>
        </FormProvider>
      </Show.When>

      <Show.When isTrue={openDetail !== ''}>
        <Modal
          title={openDetail === 'new' ? t('confirmReservation') : t('keepReservation')}
          open={openDetail !== ''}
          onClose={handleClose}
          maxWidth='450px'
          closeIcon={true}
          handleSubmit={handleSubmit}
          closeOnDocumentClick={false}
          customButtons={
            <div className='w-100 d-flex justify-content-center'>
              <Button
                text={t('close')}
                onClick={handleClose}
                className={'btn-general btn-wellet-secondary'}
                size={'custom'}
                width={'60px'}
              />
            </div>
          }
        >
          <div className='my-3 text-center d-flex flex-column'>
            <span style={{ color: 'var(--color-text-sidebar)' }}>{t('reservation')}</span>
            <span
              className='font-weight-bold'
              style={{ fontSize: '26px', color: 'var(--color-text-sidebar)', lineHeight: 1 }}
            >
              {openDetail === 'new'
                ? reservation?.actualReservation.referenceCode
                : reservation?.oldReservation?.referenceCode}
            </span>
            <span style={{ color: 'var(--color-text-sidebar)' }}>
              {openDetail === 'new' ? t('modified') : t('notModified')}
            </span>
          </div>
        </Modal>
      </Show.When>

      <Show.When isTrue={openError}>
        <Toast
          message={t('error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default ModalModification;
