export const commissionStatus = (t) => [
  {
    value: 'PAYMENT_ACCREDITED',
    label: t('components.btnFilters.commissionStatus.payments'),
    isActive: false,
  },
  {
    value: 'PAYMENT_PENDING',
    label: t('components.btnFilters.commissionStatus.pendingsPayment'),
    isActive: false,
  },
  {
    value: 'PENDING_REVIEW',
    label: t('components.btnFilters.commissionStatus.pendingsAudit'),
    isActive: false,
  },
];
export const consumptionStatus = (t) => [
  {
    label: t('components.btnFilters.consumptionStatus.greater'),
    isActive: false,
    value: 'MAJOR',
  },
  { label: t('components.btnFilters.consumptionStatus.smaller'), isActive: false, value: 'MINOR' },
  { label: t('components.btnFilters.consumptionStatus.equal'), isActive: false, value: 'EQUALS' },
];
export const reservationStatus = (t) => [
  {
    value: 'PENDING',
    label: t('components.btnFilters.reservationStatus.pendings'),
    isActive: false,
  },
  {
    value: 'CONFIRMED',
    label: t('components.btnFilters.reservationStatus.confirm'),
    isActive: false,
  },
  {
    value: 'CANCELLED',
    label: t('components.btnFilters.reservationStatus.cancel'),
    isActive: false,
  },
  {
    value: 'REJECTED',
    label: t('components.btnFilters.reservationStatus.reject'),
    isActive: false,
  },
  {
    value: 'CHECKIN',
    label: t('components.btnFilters.reservationStatus.check'),
    isActive: false,
  },
  {
    value: 'PAYING',
    label: t('components.btnFilters.reservationStatus.paying'),
    isActive: false,
  },
  {
    value: 'CLOSED',
    label: t('components.btnFilters.reservationStatus.close'),
    isActive: false,
  },
  {
    value: ['NO_SHOW', 'NO_SHOW_MANUAL'],
    label: t('components.btnFilters.reservationStatus.missing'),
    isActive: false,
  },
  {
    value: 'WAITING_DEPOSIT',
    label: t('components.btnFilters.reservationStatus.deposit'),
    isActive: false,
  },
];
export const conciergeStatus = (t) => [
  {
    value: 'ENABLED',
    label: t('components.btnFilters.conciergesStatus.actives'),
    isActive: false,
  },
  {
    value: 'DISABLED',
    label: t('components.btnFilters.conciergesStatus.inactives'),
    isActive: false,
  },
  {
    value: 'DELETED',
    label: t('components.btnFilters.conciergesStatus.deletes'),
    isActive: false,
  },
];
export const pendingAssociatesStatus = (t) => [
  {
    value: 'APPROVED',
    label: t('components.btnFilters.pendingAssociatesStatus.approves'),
    isActive: false,
  },
  {
    value: 'REVALIDATION',
    label: t('components.btnFilters.pendingAssociatesStatus.revalidate'),
    isActive: false,
  },
  {
    value: 'SEND',
    label: t('components.btnFilters.pendingAssociatesStatus.notValidate'),
    isActive: false,
  },
];
export const conciergePaymentMethods = (t) => [
  {
    value: 'MERCADOPAGO',
    label: t('components.btnFilters.conciergePaymentMethods.mp'),
    isActive: false,
  },
  {
    value: 'CASH',
    label: t('components.btnFilters.conciergePaymentMethods.cash'),
    isActive: false,
  },
  {
    value: 'CERTIFICATE',
    label: t('components.btnFilters.conciergePaymentMethods.certificate'),
    isActive: false,
  },
];
export const paymentMethods = (t) => [
  {
    value: 'MERCADOPAGO',
    label: t('components.btnFilters.paymentMethods.mp'),
    isActive: false,
  },
  {
    value: 'CASH',
    label: t('components.btnFilters.paymentMethods.cash'),
    isActive: false,
  },
  {
    value: 'CERTIFICATE',
    label: t('components.btnFilters.paymentMethods.certificate'),
    isActive: false,
  },
  {
    value: 'WIRE_MEXICO',
    label: t('components.btnFilters.paymentMethods.wireMexico'),
    isActive: false,
  },
  {
    value: 'WIRE_INTERNATIONAL',
    label: t('components.btnFilters.paymentMethods.wireInternational'),
    isActive: false,
  },
  {
    value: 'NONE',
    label: t('components.btnFilters.paymentMethods.none'),
    isActive: false,
  },
];
export const reservationTypes = (t) => [
  { label: t('components.btnFilters.reservationTypes.vip'), vip: false, value: 'AREA' },
  {
    label: t('components.btnFilters.reservationTypes.consumerTable'),
    isActive: false,
    value: 'RESTAURANT',
  },
  {
    label: t('components.btnFilters.reservationTypes.groups'),
    isActive: false,
    value: 'GROUP',
  },
];
export const productStatus = (t) => [
  {
    value: 'ENABLED',
    label: t('components.btnFilters.productStatus.active'),
    isActive: false,
  },
  {
    value: 'DISABLED',
    label: t('components.btnFilters.productStatus.inactive'),
    isActive: false,
  },
];
export const brandsStatus = () => [
  {
    value: 'visa',
    label: 'VISA',
    isActive: false,
  },
  {
    value: 'amex',
    label: 'AMEX',
    isActive: false,
  },
  {
    value: 'MASTERCARD',
    label: 'MASTERCARD',
    isActive: false,
  },
];

export const getPaymentStatus = (t) => [
  {
    value: 'ACCREDITED',
    label: t('components.badgeStatus.paymentSummaryBadge.accredited'),
    isActive: false,
  },
  {
    value: 'ERROR',
    label: t('components.badgeStatus.paymentSummaryBadge.error'),
    isActive: false,
  },
  {
    value: 'IN_PROGRESS',
    label: t('components.badgeStatus.paymentSummaryBadge.inProgressWaitingAccountFund'),
    isActive: false,
  },
  {
    value: 'PENDING',
    label: t('components.badgeStatus.paymentSummaryBadge.pending'),
    isActive: false,
  },
];
