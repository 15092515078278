import React, { useContext, useState } from 'react';
import { Button, Modal, Toast } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { patchViewDeposit } from '../../services';
import { useTranslation } from 'react-i18next';
import { Show } from '../../components';

const ModalDeposit = ({ onClose, handleSubmit, reservation, text }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalDeposit' });
  const [openDeposit, setOpenDeposit] = useState('');

  // API Querys
  const depositMutation = useMutation({
    mutationFn: () => {
      return patchViewDeposit(welletContext, reservation?.id);
    },
  });

  // Functions
  const handleModal = () => {
    setOpenDeposit((prevState) => !prevState);
  };
  const handleClose = () => {
    onClose();
    handleModal();
  };
  const onSubmit = () => {
    return depositMutation.mutate();
  };

  return (
    <>
      {/* Toasts */}
      <Show.When isTrue={depositMutation.isSuccess}>
        <Toast message={t('toastSucces')} type={`SUCCESS`} onCallback={handleClose} />
      </Show.When>
      <Show.When isTrue={depositMutation.isError}>
        <Toast message={t('toastError')} type={`ERROR`} onCallback={handleClose} />
      </Show.When>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
        onClick={handleModal}
        size={'custom'}
        text={
          <>
            <span className='dot dot-green mr-2 d-inline-block'></span>
            {t('btnDeposit')}
          </>
        }
      />
      <Show.When isTrue={openDeposit}>
        <Modal
          title={t('title')}
          open={openDeposit}
          onClose={handleModal}
          maxWidth='450px'
          closeIcon={true}
          handleSubmit={handleSubmit}
          closeOnDocumentClick={false}
          customButtons={
            <>
              <div className='row px-2 text-center'>
                <Button
                  text={t('btnClose')}
                  disabled={depositMutation.isLoading || depositMutation.isSuccess}
                  className={'col btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                <Button
                  text={t('btnSettle')}
                  disabled={depositMutation.isLoading || depositMutation.isSuccess}
                  className={'col ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={() => onSubmit()}
                />
              </div>
            </>
          }
        >
          <div className='text-left mt-2 mb-3'>{t('ask')}</div>
        </Modal>
      </Show.When>
    </>
  );
};

export default ModalDeposit;
