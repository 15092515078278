import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../../components';
import { Modal } from '../../../../uiComponents/common';

const ShowRoles = ({ roles = [], wordsToShow = 2, name }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.showRoles' });
  const { t: translate } = useTranslation('translation', { keyPrefix: 'roles' });
  const [open, setOpen] = useState(false);

  const renderSelectedOptions = () => {
    if (!roles || roles.length === 0) return '-';
    if (roles.length <= wordsToShow) {
      return roles.map((role) => translate(role)).join(', ');
    } else {
      const lastLabel = roles[roles.length - 1];
      return (
        <div className=''>
          {lastLabel},{' '}
          <span
            className='p-0 m-0 option-selected text-truncate cursor-pointer'
            onClick={(e) => { e.stopPropagation(); setOpen(true) }}
          >
            +{roles.length - 1} {`${t('more')} ...`}
          </span>
        </div>
      );
    }
  };
  const rolePrettyNames = () => {
    if (!roles || roles.length === 0) return '-';

    return (
      <>
        {roles.map((item, index) => (
          <div key={index}>{translate(item)}</div>
        ))}
      </>
    );
  };

  return (
    <>
      {renderSelectedOptions()}
      <Show.When isTrue={open}>
        <Modal
          title={`${t('title')} ${name}`}
          open={open}
          onClose={() => setOpen(false)}
          closeIcon={true}
          maxWidth='500px'
          closeOnDocumentClick={true}
          backgroundBody=''
          customButtons={null}
          textCancel={t('close')}
        >
          {rolePrettyNames()}
        </Modal>
      </Show.When>
    </>
  );
};

export default ShowRoles;
