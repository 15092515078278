import React, { useContext, useState } from 'react';
import { Button, Modal, Toast, InputText, InputDescription } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { postConfirmRejectReservation } from '../../services';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Show } from '../../components';

const ModalRejection = ({ onClose, handleSubmit, reservation, language }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalRejection' });
  const welletContext = useContext(WelletContext);
  const [openCancel, setOpenCancel] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openError, setOpenError] = useState(false);

  const methods = useForm({
    defaultValues: {
      reason: null,
    },
  });

  // API Querys
  const confirmMutation = useMutation({
    mutationFn: (data) => {
      return postConfirmRejectReservation(welletContext, {
        reservationId: reservation?.id,
        isConfirm: data.isConfirm,
        reason: data?.reason,
      });
    },
    onSuccess: (data) => {
      if (data.data) {
        setOpenDetail(true);
      } else {
        setOpenError(true);
      }
    },
  });

  // Functions
  const handleModalCancel = () => {
    setOpenCancel((prevState) => !prevState);
  };
  const handleModalDetail = () => {
    setOpenDetail((prevState) => !prevState);
  };
  const handleToastError = () => {
    setOpenError((prevState) => !prevState);
  };
  const handleCloseReject = () => {
    handleModalCancel();
    methods.reset({
      reason: null,
    });
  };
  const handleClose = () => {
    handleModalDetail();
    onClose();
    methods.reset({
      reason: null,
    });
    handleCloseReject();
    handleToastError();
  };
  const onSubmit = (data) => {
    const body = {
      isConfirm: false,
      reason: data.reason,
    };

    return confirmMutation.mutate(body);
  };

  return (
    <>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate'}
        onClick={handleModalCancel}
        size={'custom'}
        text={t('mainbtnRejected')}
      />
      <Show.When isTrue={openCancel && !openDetail}>
        <FormProvider {...methods}>
          <Modal
            title={t('title')}
            open={openCancel && !openDetail}
            onClose={handleCloseReject}
            maxWidth='450px'
            closeIcon={true}
            handleSubmit={handleSubmit}
            closeOnDocumentClick={false}
            customButtons={
              <>
                <div className='row px-2 text-center'>
                  <Button
                    text={t('btnCancel')}
                    disabled={confirmMutation.isLoading}
                    className={'col btn-wellet-secondary'}
                    size={'medium'}
                    onClick={handleCloseReject}
                  />
                  <Button
                    text={t('btnRejected')}
                    disabled={confirmMutation.isLoading}
                    className={'col ml-2 btn-wellet-primary'}
                    size={'medium'}
                    onClick={() => methods.handleSubmit(onSubmit)()}
                  />
                </div>
              </>
            }
          >
            <div className='text-left mt-2'>{t('ask')}</div>
            <div className='row p-0'>
              <InputDescription
                maxLength={512}
                height='75px'
                label={t('label')}
                name={'reason'}
                isDisabled={confirmMutation.isLoading}
                placeholder={`${t('placesholder')} (${language})`}
                isRequired={false}
                minLength={0}
                dropdown={true}
                className='col pt-2 pb-0'
              />
            </div>
          </Modal>
        </FormProvider>
      </Show.When>

      <Show.When isTrue={openDetail}>
        <Modal
          title={t('titleRejected')}
          open={openDetail}
          onClose={handleClose}
          maxWidth='450px'
          closeIcon={true}
          handleSubmit={handleSubmit}
          closeOnDocumentClick={false}
          customButtons={
            <div className='w-100 d-flex justify-content-center'>
              <Button
                text={t('btnClose')}
                onClick={handleClose}
                className={'btn-general btn-wellet-secondary'}
                size={'custom'}
                width={'60px'}
              />
            </div>
          }
        >
          <div className='my-3 text-center d-flex flex-column'>
            <span style={{ color: 'var(--color-text-sidebar)' }}> {t('reservation')}</span>
            <span
              className='font-weight-bold'
              style={{ fontSize: '26px', color: 'var(--color-text-sidebar)', lineHeight: 1 }}
            >
              {reservation?.referenceCode}
            </span>
            <span style={{ color: 'var(--color-text-sidebar)' }}>{t('hasRejected')}</span>
          </div>
        </Modal>
      </Show.When>

      <Show.When isTrue={openError}>
        <Toast
          message={t('toast')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default ModalRejection;
