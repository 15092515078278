import React from 'react';
import { Table, TableRow, TableCell } from '../../components';
import { Badge, Loading, Money, Subtitle, WithDrawal } from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useGetPaymentList, usePagination } from '../../hooks';
import { format } from 'date-fns';

const PaymentList = () => {
  const organization = useSelector((state) => state.app.currentOrganization);

  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const batchQuery = useGetPaymentList(organization.marketId, limit, skip, {
    refetchOnMount: true,
    onSuccess: (data) => setTotalAndReset(data?.data?.totalRegisters),
  });

  const headerAdmin = [
    'Cód. de Referencia',
    'Afiliado',
    'Monto',
    'Fecha de Creación',
    'Método de Retiro',
    'Comentario',
    'Estado',
  ];

  return (
    <>
      <Subtitle
        border={false}
        text={<div className='mt-1 payment-title d-flex align-items-center'>Listado de Pagos</div>}
      />
      {batchQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            data={batchQuery?.data?.data?.items ?? []}
            hasPagination={false}
            totalP={totalItems}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            columns={headerAdmin}
            renderRow={(payment, index) => (
              <>
                <TableCell style={{ width: '180px' }}>{payment.externalPaymentId ?? '-'}</TableCell>
                <TableCell style={{ width: '180px' }}>{payment.conciergeName}</TableCell>
                <TableCell
                  className='ctm-text font-weight-bold'
                  style={{ width: '120px' }}
                  type={'primary'}
                >
                  <Money value={payment.amount} />
                </TableCell>
                <TableCell>
                  {payment.createdAtUtc
                    ? format(new Date(payment.createdAtUtc), 'MMM dd, yyyy, h:mm a')
                    : '-'}
                </TableCell>
                <TableCell>
                  {payment?.withdrawalMethod ? (
                    <WithDrawal
                      withdrawal={payment?.withdrawalMethod?.withdrawalMethod?.methodType}
                    />
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>{payment?.comments ?? '-'}</TableCell>
                <TableCell>
                  <Badge status={payment.status} />
                </TableCell>
              </>
            )}
          />
        </>
      )}
    </>
  );
};

export default PaymentList;
