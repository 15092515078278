import { Route } from 'react-router-dom';
import WelletOrganization from './welletOrganization';
import React from 'react';
import { paymentRoutesConfig } from '../data/withdrawals';

export const paymentRoutesComponent = (paymentMethods) => {
  const paymentRoutes = paymentMethods
    ?.map((method) => paymentRoutesConfig[method])
    ?.filter(Boolean);
  
  return (
    <>
      {paymentRoutes?.map(({ path, component }) => (
        <Route key={path} path={path} element={<WelletOrganization onlyMarket={true} />}>
          <Route index element={component()} />
        </Route>
      ))}
    </>
  );
};
