const ArrowIcon = ({ size = '16px', color = 'currentColor', customClass = null, rotate = 0 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        transform: `rotate(${rotate}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.67253 0.32903C10.0435 0.700028 10.0435 1.30153 9.67253 1.67253L3.29428 8.05078H24.0008C24.5255 8.05078 24.9508 8.47611 24.9508 9.00078C24.9508 9.52545 24.5255 9.95078 24.0008 9.95078H3.29428L9.67253 16.329C10.0435 16.7 10.0435 17.3015 9.67253 17.6725C9.30153 18.0435 8.70003 18.0435 8.32903 17.6725L0.32903 9.67253C-0.0419683 9.30153 -0.0419683 8.70003 0.32903 8.32903L8.32903 0.32903C8.70003 -0.0419683 9.30153 -0.0419683 9.67253 0.32903Z'
        fill={color}
      />
    </svg>
  );
};

export default ArrowIcon;
