import React from 'react';
import './toggleToken.css';
import { useFormContext, Controller } from 'react-hook-form';
const ToggleToken = ({ activeText, inactiveText, name, label, isRequired, onClick = () => {} }) => {
  const { control } = useFormContext();
  const validationRules = {};

  if (isRequired) {
    validationRules.required = 'Este campo es requerido.';
  }
  return (
    <Controller
      name={name}
      key={name}
      control={control}
      rules={validationRules}
      defaultValue=''
      render={({ field, formState }) => (
        <div>
          {label ? <label className='mb-1' style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
          <div className='d-flex cursor-pointer' style={{ width: 'fit-content', background: '#fff' }}>
            <div
              className={`status-active ${field.value ? 'active' : 'inactive'}`}
              onClick={() => {
                field.onChange(true);
                onClick(true);
              }}
            >
              {activeText}
            </div>
            <div
              className={`status-inactive ${field.value ? 'inactive' : 'active'}`}
              onClick={() => {
                field.onChange(false);
                onClick(false);
              }}
            >
              {inactiveText}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default ToggleToken;
