import React from 'react';
import { Money } from '../../../uiComponents/common';
import '../campaign.css';

const CampaignCard = ({
  title,
  className,
  mainvalue,
  value,
  isMoney = true,
  text,
  icon,
  valueText = null,
}) => {
  return (
    <div className={`small-card-campaign card-campaign card-campaign-padding ${className}`}>
      <div className='row'>
        <div className='col-9 pr-0 '>
          <div className='fw-600 '>{title}</div>
          <div className='fw-600 fs-25 d-flex align-items-center'>
            {isMoney ? <Money value={mainvalue} /> : mainvalue}
            {text ? <span className='fw-500 ml-1 fs-12 lh-1'>{text}</span> : null}
          </div>
          <div className='dark-grey fw-600 fs-16 d-flex  align-items-center'>
            {value ? (
              <>
                <Money value={value} />
                {valueText && <span className='fw-500 ml-1 fs-12 lh-1'>{valueText}</span>}
              </>
            ) : null}
          </div>
        </div>
        <div className='col-auto d-flex align-items-center justify-content-center'>{icon}</div>
      </div>
    </div>
  );
};

export default CampaignCard;
