import React, { useContext, useState } from 'react';
import {
  AffiliateCard,
  ButtonFilter,
  Input,
  Loading,
  Money,
  OrganizationCard,
  PageError,
} from '../../uiComponents/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  DateFilter,
  FilterCard,
  PageHeader,
  Show,
  Table,
  TableCell,
  TableRow,
} from '../../components';
import { SearchIcon } from '../../uiComponents/icons';
import { getDisputes } from '../../services/comissionsService';
import {
  useCreateBreadCrumbs,
  useDebounce,
  useGetRestaurants,
  useLocalStorage,
  usePagination,
} from '../../hooks';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import { commissionBadge } from '../../helpers/status';
import { filtersFormat, getPaymentMethods } from '../../helpers/format';
import { useTranslation } from 'react-i18next';
import mergeWithDefaults from '../../helpers/localstorage';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Disputes = () => {
  //const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.revision' });
  const paymentMethods = useSelector((state) => state.app.paymentMethods);
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-review', {
    location: [],
    filterPaymentMethods: getPaymentMethods(paymentMethods, translate, true),
    filterField: null,
    filterByUrl: null,
    page: 1,
  });
  const navigate = useNavigate();
  let location = useLocation();

  // Constant when is associate commissions
  const regex = /id=([a-fA-F0-9-]+)/;
  const associateId = location?.search
    ? location?.search?.match(regex)
      ? location?.search?.match(regex)[1]
      : null
    : null;

  const welletContext = useContext(WelletContext);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const organization = useSelector((state) => state.app.currentOrganization);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
  );
  const isMarket = organization.id === organization.marketId;

  const [filterRestaurante, setFilterRestaurante] = useState([]);
  const [reservationCode, setReservationCode] = useState(filterLocalStorage.filterField);

  const [filter, setFilter] = useState(filterLocalStorage.filterByUrl || '');
  const [filterAdjustment, setFilterAdjustment] = useState('');

  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const [filterPaymentMethods, setFilterPaymentMethods] = useState(
    mergeWithDefaults(
      filterLocalStorage.filterPaymentMethods,
      getPaymentMethods(paymentMethods, translate, true),
    ),
  );

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const breadcrumb = [{ name: t('title') }];
  const { getClientTranslation } = useGenericTranslation();

  // Functions
  const filterRestaurantes = (array) => {
    const filteredArray = array.filter((item) => item.isActive);

    const valuesArray = filteredArray.map((item) => item.value);

    return valuesArray;
  };
  const getPaymentsTypeFilterQuery = () => {
    const filteredArray = filterPaymentMethods.filter((item) => item.isActive);
    const valuesArray = filteredArray.map((item) => item.value);
    return valuesArray;
  };
  const handleFilter = (filter, adjustment) => {
    if (filter === null) {
      setFilterAdjustment(adjustment);
      setFilter(null);
    } else if (adjustment === null) {
      setFilter(filter);
      setFilterAdjustment(null);
    }
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, filterByUrl: filter, page: 1 });
  };
  const handleNavigate = (commission) => {
    navigate(`/under-review/${commission.reservationId}`, { state: 'disputes' });
  };

  //Data filter
  const organizationId = organization.id === organization.marketId ? null : Number(organization.id);
  const debouncedSearch = useDebounce(reservationCode, 500);
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };

  const disputesQuery = useQuery(
    [
      'disputes',
      organization.marketId,
      organizationId,
      filterRestaurantes(filterRestaurante),
      getPaymentsTypeFilterQuery(),
      debouncedSearch,
      selectedFormattedDates,
      limit,
      skip,
      associateId,
      filter,
      filterAdjustment,
    ],
    () =>
      getDisputes(
        welletContext, // wellet context
        organization.marketId, // market id
        organizationId, // organization id
        filterRestaurantes(filterRestaurante), // show id
        getPaymentsTypeFilterQuery(), // payment type
        null, // associate name filter
        debouncedSearch, // reservation code filter
        selectedFormattedDates, // date
        limit, // limit
        skip, // skip
        null, // oredrId
        associateId, // id
        filter, // status
        filterAdjustment, //operationTypes
      ),
    {
      onSuccess: (data) => {
        setFilterLocalStorage({
          ...filterLocalStorage,
          location: filterRestaurante,
          filterField: reservationCode,
          filterPaymentMethods,
          page: currentPage,
        });
        setTotalAndReset(data?.data?.commissions.totalRegisters);
      },
      ...defaultQueryOptions,
      keepPreviousData: true,
    },
  );

  const restaurantesQuery = useGetRestaurants(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((org) => ({
        label: org.name,
        value: org.id,
        isActive:
          filterLocalStorage.location.length > 0
            ? filtersFormat(filterLocalStorage.location).includes(org.id)
            : false,
      }));
      setFilterRestaurante(response);
    },
    keepPreviousData: true,
  });

  const headerAdmin = [
    { title: t('table.code'), value: '', filterable: false },
    { title: t('table.concierge'), value: '', filterable: false },
    { title: t('table.commission'), value: '', filterable: false },
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.schedule'), value: '', filterable: false, center: true },
    { title: t('table.consumption'), value: '', filterable: false },
    { title: t('table.client'), value: '', filterable: false },
    { title: t('table.dateRequest'), value: '', filterable: false },
    { title: t('table.state'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs(breadcrumb);

  const disputes = disputesQuery?.data?.data || [];

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={
          <div className='mt-1'>
            <DateFilter filterName={'Fecha'} />
          </div>
        }
      />
      <Show>
        <Show.When isTrue={disputesQuery.isError}>
          <PageError />
        </Show.When>
        <Show.When isTrue={disputesQuery.isLoading || restaurantesQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.Else>
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('cardFilter.total')}
              quantity={disputes.totalCount}
              onClick={() => handleFilter('', null)}
              className='reservations col'
              isSelected={filter === ''}
            />
            <FilterCard
              category={t('cardFilter.pendings')}
              quantity={disputes.disputedCount}
              onClick={() => handleFilter('DISPUTED', null)}
              isSelected={filter === 'DISPUTED'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.validates')}
              quantity={disputes.validCount}
              onClick={() => handleFilter('VALID', null)}
              isSelected={filter === 'VALID'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.invalidates')}
              quantity={disputes.rejectedCount}
              onClick={() => handleFilter('REJECTED', null)}
              className='reservations col'
              isSelected={filter === 'REJECTED'}
            />
          </div>
          <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
            <div className='col col-sm-auto justify-content-center'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                name={'search'}
                value={reservationCode}
                onChangeValue={(e) => setReservationCode(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            {isMarket && (
              <div className='col col-sm-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={getClientTranslation('title.plural')}
                  filterOptions={filterRestaurante}
                  error={restaurantesQuery.isError}
                  callback={setFilterRestaurante}
                />
              </div>
            )}
            <div className='col col-sm-auto justify-content-center'>
              <ButtonFilter
                isSearchable={false}
                filterName={t('buttonsFilter.byGateway')}
                filterOptions={filterPaymentMethods}
                callback={setFilterPaymentMethods}
              />
            </div>
          </div>
          <Show>
            <Show.When isTrue={disputesQuery.isPreviousData}>
              <Loading />
            </Show.When>
            <Show.Else>
              <>
                <Table
                  data={disputes?.commissions?.items}
                  currentPage={currentPage}
                  hasPagination={false}
                  onPageChange={handlePageChange}
                  totalP={totalItems}
                  columns={headerAdmin}
                  renderRow={(commission, index) => {
                    const { date, time } = formatDateTranslation(
                      commission?.reservationDateLocalTime,
                      language,
                      'extraSmall',
                    );
                    return (
                      <>
                        <>
                          <TableCell style={{ width: 'auto' }}>
                            {commission.referenceCode ? commission.referenceCode : '-'}
                          </TableCell>

                          <TableCell style={{ minWidth: '200px' }}>
                            {commission.isWellet ? (
                              commission?.userName
                            ) : (
                              <AffiliateCard
                                url={commission?.sellerProfilePicUrl}
                                name={commission?.sellerName}
                                isVerified={commission?.sellerIsVerified}
                                company={commission?.sellerCompany}
                              />
                            )}
                          </TableCell>

                          <TableCell
                            className='font-weight-bold'
                            style={{ width: 'auto' }}
                            type={'primary'}
                          >
                            {commission.commissionsSellers ? (
                              commission.adjustmentsSellers === 0 ? (
                                <Money value={commission.commissionsSellers} />
                              ) : (
                                <div>
                                  <div className='text-strikethrough'>
                                    <Money value={commission.commissionsSellers} />
                                  </div>
                                  <div>
                                    <Money
                                      value={
                                        commission.commissionsSellers +
                                        commission.adjustmentsSellers
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            ) : (
                              '-'
                            )}
                          </TableCell>

                          <TableCell style={{ minWidth: '200px' }}>
                            <OrganizationCard
                              name={commission?.restaurant?.name}
                              date={commission?.restaurant?.cityName}
                              size={40}
                              imageUrl={commission?.restaurant?.logo}
                              hightLightName={true}
                            />
                          </TableCell>
                          <TableCell style={{ width: 'auto' }} className={'fw-700 text-center'}>
                            <div>
                              <span className='sentences-capitalize container-label d-block fw-700 '>
                                {date}
                              </span>
                              <span className='fw-700 text-center'>{time}</span>
                            </div>
                          </TableCell>
                          <TableCell style={{ width: 'auto' }} type={'primary'}>
                            {commission.orderTotalAmount ? (
                              <Money value={commission.orderTotalAmount} />
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell style={{ width: 'auto' }}>
                            <div className='fw-500 text-capitalize'>{commission.customerName}</div>
                            <div className='dark-grey'>
                              {commission.paxs} {commission.paxs > 1 ? t('peoples') : t('people')}
                            </div>
                          </TableCell>

                          <TableCell className='text-capitalize' style={{ width: 'auto' }}>
                            {commission.reservationRequestDateLocalTime
                              ? format(
                                  new Date(commission.reservationRequestDateLocalTime),
                                  'MMM. dd, yyyy',
                                  { locale },
                                )
                              : '-'}
                          </TableCell>
                          <TableCell style={{ width: 'auto' }}>
                            {commission?.status
                              ? commissionBadge(commission?.status, translate)
                              : null}
                          </TableCell>
                        </>
                        {index === disputes?.commissions?.items.length - 1 && (
                          <TableRow>
                            <TableCell style={{ backgroundColor: '#eee' }}>Total</TableCell>
                            {<TableCell style={{ backgroundColor: '#eee' }}></TableCell>}
                            <TableCell
                              style={{ backgroundColor: '#eee' }}
                              className='font-weight-bold'
                            >
                              <Money value={disputes.totalCommissionsAndAdjustmentsAmount} />
                            </TableCell>
                            {<TableCell style={{ backgroundColor: '#eee' }}></TableCell>}
                            <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                            <TableCell style={{ backgroundColor: '#eee' }}>
                              <Money value={disputes.totalOrdersAmount} />
                            </TableCell>
                            <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                            <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                            <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  }}
                  onRowClick={(commission) => handleNavigate(commission)}
                />
              </>
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </>
  );
};

export default Disputes;
