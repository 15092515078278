import React from 'react';
import TableRow from './tableRow';
import TableCell from './tableCell';
import { AffiliateCard, UserImage } from '../../uiComponents/common';
import { getDashboardRow } from '../dashboard/unitsTable';
import Table from './table';

const UnitTable = ({
  selectedOption,
  headerAdmin,
  totalItems,
  handlePageChange,
  currentPage,
  data,
  mode = 'dashboard',
}) => {
  return (
    <Table
      borderTop={false}
      borderContainer={true}
      showPagination={true}
      totalP={totalItems}
      onPageChange={handlePageChange}
      currentPage={currentPage}
      pagination='normal'
      header='white'
      paddingTh={'padding-white-header'}
      columns={headerAdmin}
      showText={false}
      data={data}
      renderRow={(affiliate) => (
        <>
          <TableCell>
            <AffiliateCard
              name={affiliate.user.fullName}
              url={affiliate.user.profilePicUrl}
              isVerified={affiliate.user.isVerified}
              company={affiliate.user.company}
            />
          </TableCell>
          {selectedOption !== 'concierges' ? (
            <>
              <TableCell className='fw-500' type={'primary'}>
                {getDashboardRow(selectedOption, affiliate)}
              </TableCell>
              <TableCell className={'dark-grey'}>
                {mode === 'campaigns' ? affiliate.campaigns : affiliate.paxs}
              </TableCell>
            </>
          ) : (
            <>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </>
          )}
        </>
      )}
      rowClass='not-hover'
    />
  );
};

export default UnitTable;
