import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const TitleTab = ({ title = '' }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathParts = location.pathname.split('/').filter(Boolean)[0];
  const { titleWeb } = useSelector((state) => state.app);

  return (
    <Helmet>
      <title>
        {title ? title : t(`hooks.breadcrumbs.${pathParts}`)} - {titleWeb ?? 'Admin'}
      </title>
    </Helmet>
  );
};

export default TitleTab;
