export const getSidebarCounts = async (welletContext, marketId, body) => {
  const url = `api/info/${marketId}/count`;
  return await welletContext.apis.admin.post(url, {
    ReservationCommissionStatus: null,
    ReservationStatus: null,
    KYCStatus: null,
  });
};

export const getAllNotifications = async (welletContext, body) => {
  const url = `api/notifications/all`;
  return await welletContext.apis.admin.post(url, body);
};

export const postNotification = async (welletContext, ids) => {
  const url = `api/notifications/mark-as-viewed`;
  return await welletContext.apis.admin.post(url, { notificationIds: ids });
};
