import React from 'react';

const Certificate = ({ size = '16px', color = 'currentColor' }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2330 5109 c-628 -69 -998 -385 -1121 -958 -15 -70 -22 -150 -26
-296 l-6 -200 -44 -39 c-84 -73 -88 -90 -88 -336 0 -237 2 -248 69 -318 65
-70 90 -77 302 -80 l192 -4 6 -40 c11 -69 63 -190 118 -273 29 -44 101 -130
160 -191 l108 -111 0 -186 0 -185 -32 -11 c-18 -5 -251 -71 -518 -146 -580
-162 -611 -175 -741 -305 -70 -69 -94 -102 -128 -172 -23 -47 -48 -117 -56
-154 -7 -38 -39 -288 -71 -556 -54 -461 -56 -489 -40 -512 29 -45 102 -47 130
-3 9 14 34 192 71 507 31 267 62 511 70 542 39 155 151 287 300 351 63 27 569
167 576 159 4 -4 110 -163 237 -353 127 -190 238 -349 247 -353 45 -17 67 -4
250 149 l185 156 0 -289 c0 -273 1 -289 20 -309 27 -29 56 -37 89 -23 48 19
51 39 51 341 l0 279 186 -155 c182 -152 205 -166 249 -149 9 4 120 163 247
353 127 190 233 349 237 353 10 12 539 -140 611 -175 122 -60 230 -195 264
-333 8 -33 40 -277 71 -544 37 -315 62 -493 71 -507 28 -44 101 -42 130 3 16
23 14 51 -40 512 -32 268 -64 518 -71 556 -8 37 -33 107 -56 155 -34 70 -58
102 -128 172 -129 128 -163 143 -721 299 -256 72 -489 137 -517 146 l-53 15 0
186 0 187 88 85 c154 151 253 308 290 459 l17 70 190 4 c211 3 236 10 301 80
67 70 69 81 69 318 0 246 -4 263 -88 336 l-44 39 -6 200 c-6 214 -22 319 -73
471 -141 420 -462 681 -940 765 -138 24 -456 34 -594 18z m545 -173 c595 -100
905 -483 905 -1118 l0 -138 -63 0 -64 0 -6 113 c-17 294 -104 559 -242 736
-191 244 -518 365 -935 348 -336 -14 -562 -99 -738 -279 -80 -82 -121 -140
-170 -241 -75 -154 -123 -353 -138 -574 l-7 -103 -38 0 -39 0 0 138 c0 533
224 897 646 1051 94 34 248 69 359 81 113 12 423 4 530 -14z m-165 -226 c288
-36 478 -144 611 -348 78 -119 141 -309 154 -462 l6 -65 -26 49 c-35 64 -108
134 -172 163 -134 62 -278 47 -513 -50 -159 -67 -261 -67 -420 0 -233 97 -394
112 -518 49 -101 -51 -187 -175 -214 -308 -8 -38 -17 -58 -26 -58 -12 0 -14
15 -8 93 16 216 61 392 139 542 59 112 182 235 293 293 184 95 442 133 694
102z m-570 -807 c30 -8 90 -30 132 -49 204 -89 371 -89 574 0 111 48 185 68
263 69 111 2 181 -53 227 -179 16 -43 19 -89 22 -389 l3 -340 -183 -68 c-101
-37 -188 -67 -194 -67 -6 0 -17 7 -25 16 -7 8 -29 24 -48 34 -134 67 -295 -2
-336 -145 -69 -235 221 -407 393 -232 40 41 72 108 72 151 0 19 20 29 145 75
80 30 147 52 150 50 9 -9 -47 -127 -92 -194 -163 -239 -546 -494 -710 -471
-53 7 -165 54 -247 103 -278 168 -475 399 -516 608 -8 41 -10 179 -8 440 3
338 5 386 22 430 59 164 164 211 356 158z m-540 -623 l0 -240 -160 0 c-241 0
-240 -1 -240 242 0 240 -2 238 238 238 l162 0 0 -240z m2265 228 c48 -22 55
-52 55 -228 0 -240 0 -240 -240 -240 l-160 0 0 240 0 240 160 0 c102 0 168 -4
185 -12z m-1010 -733 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55 -15 -16 -36
-25 -55 -25 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16
36 25 55 25 19 0 40 -9 55 -25z m-654 -646 c53 -37 177 -89 265 -110 75 -18
96 -19 149 -10 87 15 164 44 261 95 l84 45 0 -137 0 -137 -200 -200 -200 -200
-200 200 -200 200 0 137 c0 76 3 138 6 138 3 0 19 -9 35 -21z m52 -577 l188
-188 -171 -143 c-95 -78 -173 -141 -174 -139 -2 1 -87 129 -190 283 l-186 280
163 47 c89 25 167 47 172 47 6 1 95 -84 198 -187z m978 141 c109 -30 166 -50
162 -57 -8 -12 -364 -549 -368 -554 -2 -2 -80 61 -174 140 l-171 143 188 188
c103 103 188 187 191 187 2 0 79 -21 172 -47z'
        />
        <path
          d='M2505 375 c-24 -23 -25 -28 -25 -175 0 -147 1 -152 25 -175 32 -33
78 -33 110 0 24 23 25 28 25 175 0 147 -1 152 -25 175 -15 16 -36 25 -55 25
-19 0 -40 -9 -55 -25z'
        />
      </g>
    </svg>
  );
};

export default Certificate;
