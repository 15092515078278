import React from 'react';

const OutlineUser = ({ size, color = 'currentColor' }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2420 5114 c-322 -40 -591 -171 -815 -398 -199 -201 -313 -415 -372
-696 -24 -118 -24 -382 0 -500 59 -281 174 -496 372 -696 201 -203 421 -322
705 -381 117 -24 380 -24 502 0 270 54 494 174 694 372 202 199 322 421 381
705 24 118 24 382 0 500 -39 185 -110 356 -212 510 -63 95 -258 291 -351 352
-161 107 -335 180 -506 213 -81 16 -328 28 -398 19z m380 -322 c198 -52 365
-146 501 -281 135 -135 226 -297 282 -501 29 -109 29 -371 0 -480 -56 -204
-147 -366 -282 -501 -135 -135 -297 -226 -501 -282 -109 -29 -371 -29 -480 0
-204 56 -366 147 -501 282 -386 387 -411 1000 -57 1421 153 182 404 323 638
359 98 15 311 6 400 -17z'
        />
        <path
          d='M2023 2104 c-560 -68 -1065 -385 -1390 -874 -191 -286 -300 -624
-319 -985 -6 -111 -5 -124 15 -163 14 -28 34 -48 61 -62 39 -20 54 -20 2170
-20 2116 0 2131 0 2170 20 27 14 47 34 61 62 20 39 21 52 15 163 -26 493 -214
925 -556 1279 -324 335 -742 539 -1199 586 -151 15 -889 11 -1028 -6z m1012
-294 c663 -76 1219 -543 1404 -1181 30 -101 61 -258 61 -305 l0 -24 -1940 0
-1940 0 0 29 c0 54 41 242 74 345 109 332 317 618 603 831 102 76 328 192 458
234 99 33 216 58 322 70 102 12 854 12 958 1z'
        />
      </g>
    </svg>
  );
};

export default OutlineUser;
