import React from 'react';
import { AffiliateCard, ContanierSection, Money, Subtitle } from '../../uiComponents/common';
import { getUserRole } from '../../helpers/roles';
import InfoRenderer from '../infoRenderer/infoRenderer';
import { useTranslation } from 'react-i18next';
import Show from '../show/show';
import { formatDateTranslation } from '../../helpers/dates';
import style from './conciergesDetails.module.css';

const ConciergeDetail = ({ data, language }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.conciergeDetail' });
  const { t: translate } = useTranslation();
  const { concierge, rp, boss } = data;

  const { date } = formatDateTranslation(concierge?.createdDate, language, 'small');

  return (
    <>
      <Subtitle text={t('title')} />
      <ContanierSection maxWidth='1200px'>
        <div className='row no-gutters'>
          <div className='col-12 col-md-6 border-right pr-2'>
            <div className='row no-gutters'>
              <div className={`col-12 col-md-6  container-data`}>
                <AffiliateCard
                  url={concierge?.profilePicUrl}
                  name={concierge?.fullName}
                  isVerified={concierge?.isVerified}
                  company={concierge?.company}
                />
              </div>
              <div className={`col-12 col-md  container-data`}>
                <div className='container-label'>{t('role')}</div>
                <InfoRenderer
                  content={getUserRole(concierge?.role, translate)}
                  className='dark-grey'
                />
              </div>
            </div>
            <div className='row no-gutters'>
              <div className={`col-12 col-md-6 container-data ${style.containerPadding56}`}>
                <div className='container-label'>{t('category')}</div>
                <InfoRenderer content={concierge?.category?.name} className='dark-grey' />
              </div>
              <div className={`col-12 col-md  container-data ${style.containerPadding56Mobile}`}>
                <div className='container-label'>{t('memberSince')}</div>
                <InfoRenderer content={date} className='dark-grey sentences-capitalize' />
              </div>
            </div>
            <div className='row no-gutters'>
              <div className={`col-12 col-md-6  container-data ${style.containerPadding56}`}>
                <div className='container-label'>{t('phone')}</div>
                <InfoRenderer content={concierge?.phoneNumber} className='dark-grey' />
              </div>
              <div className={`col-12 col-md  container-data ${style.containerPadding56Mobile}`}>
                <div className='container-label'>{t('mail')}</div>
                <InfoRenderer content={concierge?.email} className='dark-grey' />
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 '>
            <div className='row no-gutters'>
              <div className={`col-6 ${style.containerPadding56}`}>
                <div className='row no-gutters'>
                  <div className='col-auto container-data'>
                    <div className='container-label'>{t('sold30d')}</div>
                    {concierge?.last30Days ? (
                      <div className='dark-grey'>
                        <Money value={concierge?.last30Days} />{' '}
                      </div>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className='row no-gutters'>
                  <div className='col-auto container-data'>
                    <div className='container-label'>{t('people30d')}</div>
                    <InfoRenderer content={concierge?.totalPaxs} className='dark-grey' />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='row'>
                  <div className='col'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContanierSection>
      {/* TEAM */}
      <div className={'subtitle-border'}></div>
      <div className='fw-700 mt-3 mb-4' style={{ fontSize: '15px' }}>
        {t('team.title')}
      </div>
      <ContanierSection maxWidth='1200px'>
        <div className='row no-gutters justify-content-start'>
          <Show.When isTrue={boss !== null}>
            <div className='col-12 col-md-4 container-data'>
              <AffiliateCard
                url={boss?.profilePicUrl}
                name={boss?.fullName}
                company={getUserRole(boss?.role, translate)}
                isVerified={boss?.isVerified}
              />
            </div>
          </Show.When>
          <Show.When isTrue={rp !== null}>
            <div className='col-12 col-md-4 container-data'>
              <AffiliateCard
                url={rp?.profilePicUrl}
                name={rp?.fullName}
                company={getUserRole(rp?.role, translate)}
                isVerified={rp?.isVerified}
              />
            </div>
          </Show.When>

          <div className='col-12 col-md-4 container-data'>
            <AffiliateCard
              url={concierge?.profilePicUrl}
              name={concierge?.fullName}
              isVerified={concierge?.isVerified}
              company={concierge?.company}
            />
          </div>
        </div>
      </ContanierSection>
    </>
  );
};

export default ConciergeDetail;
