export const getAllPrices = async (
  welletContext,
  orgId,
  limit = null,
  skip = null,
  includeInactive = false,
) => {
  return await welletContext.apis.admin.post(`api/PriceRule/all/`, {
    limit,
    skip,
    includeInactive,
    organizationId: orgId,
  });
};
export const getPricesByIds = async (
  welletContext,
  orgId,
  limit = null,
  skip = null,
  includeInactive = false,
  priceRuleIds,
) => {
  return await welletContext.apis.admin.post(`api/PriceRule/ids`, {
    limit,
    skip,
    includeInactive,
    organizationId: orgId,
    priceRuleIds,
  });
};
export const createPrice = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/PriceRule`, body);
};
export const updatePrice = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/PriceRule`, body);
};
export const deletePrice = async (welletContext, ids) => {
  return await welletContext.apis.admin.delete(`api/PriceRule`, {
    data: ids,
  });
};