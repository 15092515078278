import React, { useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCreateBreadCrumbs, useUser } from '../../hooks';
import DisputeWarning from './components/disputeWarning';
import { WalletPayemntIcon } from '../../uiComponents/icons';
import {
  ContanierSection,
  Loading,
  Money,
  PageError,
  Subtitle,
} from '../../uiComponents/common';
import { getDisputedPayment } from '../../services';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../context/wellet/welletContext';
import { Show, Table, TableCell } from '../../components';
import { formatDateTranslation } from '../../helpers/dates';
import { disputesBadge } from '../../helpers/status';
import DisputeTimer from '../../components/disputeTimer/disputeTimer';
import { hasAllowedRoles } from '../../helpers/roles';
import { getAffectedPayments } from '../monitorDetail/services/monitorDetailServices';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';

const DisputeDetail = () => {
  const { paymentId } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.disputeWellet',
  });
  const { t: translate } = useTranslation();
  const location = useLocation();
  const language = useSelector((state) => state.app.language);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const paymentInfoQuery = useQuery({
    queryKey: ['payment-dispute', paymentId, organization.marketId],
    queryFn: () => getDisputedPayment(welletContext, organization.marketId, paymentId),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!paymentId,
  });
  const isFromAccount = location.pathname.includes('accounts/disputes')
  const { roles } = useUser();

  const paymentAffectedQuery = useQuery({
    queryKey: ['affected-payments', paymentId],
    queryFn: () => getAffectedPayments(welletContext, paymentId, true),
    ...defaultQueryOptions,
  });

  useCreateBreadCrumbs([
    {
      name: isFromAccount ? t('breadcrumbs.account') : t('breadcrumbs.prev'),
      path: isFromAccount ? '/accounts' : '/disputes',
    },
    {
      name: t('breadcrumbs.current'),
    },
  ]);
  const isWelletFinance = hasAllowedRoles(roles, ['wellet_finance']);
  const renderSubHeaderItem = (title, value) => (
    <div className='col-12 col-md-3'>
      <div className='dark-grey container-label'>{title}</div>
      <div className='fw-700 container-text'>{value}</div>
    </div>
  );

  return (
    <div className='container-page color-body'>
      <Show>
        <Show.When isTrue={paymentInfoQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={paymentInfoQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='container-header with-border'>
            <div className='row align-items-end'>
              <div className='col-auto'>
                <div className='light-grey  d-flex justify-content-start  align-items-center container-small-title'>
                  <WalletPayemntIcon size='14px' color='currentColor' customClass={'mr-2'} />
                  <div className=''>{t('title')}</div>
                </div>
                <div className='container-title'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <Money
                        value={paymentInfoQuery?.data?.data?.amount}
                        currencyCode={paymentInfoQuery?.data?.data?.currency}
                      />
                    </div>
                    <div className='ml-3'>
                      {paymentInfoQuery?.data?.data?.status === 'DISPUTED' &&
                      paymentInfoQuery?.data?.data?.dispute_Due_by ? (
                        <DisputeTimer expiresIn={paymentInfoQuery?.data?.data?.due_by_Seconds} />
                      ) : (
                        disputesBadge(
                          paymentInfoQuery?.data?.data?.status,
                          translate,
                          isWelletFinance,
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col my-auto'></div>
            </div>
          </div>
          <ContanierSection maxWidth='1200px'>
            <div className='row no-gutters container-data margin-reservation'>
              {renderSubHeaderItem(
                t('details.lastUpdate'),
                paymentInfoQuery?.data?.data?.lastUpdate
                  ? formatDateTranslation(
                      paymentInfoQuery?.data?.data?.lastUpdate,
                      language,
                      'small',
                    ).dateTime
                  : '-',
              )}
              {renderSubHeaderItem(t('details.customer'), paymentInfoQuery?.data?.data?.customer)}
              {renderSubHeaderItem(
                t('details.paymentMethod'),
                <>
                  <div className='d-flex align-items-center'>
                    {paymentInfoQuery?.data?.data?.brand ? (
                      <img
                        style={{ height: '30px' }}
                        src={
                          'https://cdn1.wellet.fun/images/credit-card/' +
                          paymentInfoQuery?.data?.data?.brand?.toLocaleLowerCase() +
                          '-logo.png'
                        }
                        className='mr-1'
                        alt=''
                      />
                    ) : (
                      '-'
                    )}
                    {paymentInfoQuery?.data?.data?.last4
                      ? ` •••• ${paymentInfoQuery?.data?.data.last4}`
                      : null}
                  </div>
                </>,
              )}
              {renderSubHeaderItem(
                t('details.order'),
                paymentInfoQuery?.data?.data?.reservationId ? (
                  <Link
                    className='custom-link-file'
                    to={`/disputes/reservation/${paymentInfoQuery?.data?.data?.reservationId}`}
                  >
                    {paymentInfoQuery?.data?.data.referenceCode}
                  </Link>
                ) : (
                  paymentInfoQuery?.data?.data.referenceCode
                ),
              )}
            </div>
          </ContanierSection>
          {paymentId ? (
            <div className='px-1 mt-3 mb-5'>
              <DisputeWarning
                paymentAffected={paymentAffectedQuery}
                paymentInfoQuery={paymentInfoQuery}
                paymentId={paymentId}
              />
            </div>
          ) : null}
          <Show.When isTrue={paymentAffectedQuery?.data?.data?.items?.length > 0}>
            <Subtitle text={t('paymentAffected.title')} />
            <ContanierSection maxWidth='1200px'>
              <div className='mb-3'>
                <Table
                  hasPagination={false}
                  borderTop={false}
                  showPagination={false}
                  columns={[
                    { title: t('paymentAffected.table.date') },
                    { title: t('paymentAffected.table.reservation') },
                    { title: t('paymentAffected.table.amount') },
                    { title: t('paymentAffected.table.title') },
                    { title: t('paymentAffected.table.description') },
                  ]}
                  pagination='normal'
                  borderContainer={true}
                  header='white'
                  showText={false}
                  data={paymentAffectedQuery?.data?.data?.items}
                  paddingTh={'padding-white-header'}
                  renderRow={(item) => {
                    const { dateTime } = formatDateTranslation(item.createdAt, language, 'small');
                    return (
                      <>
                        <TableCell>{dateTime}</TableCell>
                        <TableCell>
                          <div className='fw-600'>
                            {item?.restaurant?.name}{' '}
                            {item?.reservation?.referenceCode ? (
                              <>
                                -{' '}
                                {item.reservation?.id ? (
                                  <Link
                                    className='custom-link-file'
                                    to={`/disputes/reservation/${item.reservation?.id}`}
                                  >
                                    {item.reservation.referenceCode}
                                  </Link>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </TableCell>

                        <TableCell style={{ width: 'auto' }} type={'primary'}>
                          <div className='fw-600'>
                            {item.amount ? (
                              <>
                                {' '}
                                - <Money value={item.amount} />{' '}
                              </>
                            ) : (
                              '-'
                            )}
                          </div>
                        </TableCell>

                        <TableCell style={{ width: 'auto' }}>
                          {item.chargeId ? (
                            <div className='fw-600'>
                              <Link
                                className='custom-link-file'
                                to={`/transactions/${item.chargeId}`}
                              >
                                {item.chargeId}
                              </Link>
                            </div>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell
                          className={'text-wrap'}
                          style={{ maxWidth: '300px', minWidth: '220px' }}
                        >
                          {item.description}
                        </TableCell>
                      </>
                    );
                  }}
                  rowClass='not-hover'
                />
              </div>
            </ContanierSection>
          </Show.When>
        </Show.Else>
      </Show>
    </div>
  );
};

export default DisputeDetail;
