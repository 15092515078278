import { useTranslation } from 'react-i18next';
import { InfoRenderer, Show } from '../../../../components';
import { AffiliateCard, ContanierSection, Subtitle } from '../../../../uiComponents/common';
import { formatDateTranslation } from '../../../../helpers/dates';
import { getUserRole } from '../../../../helpers/roles';
import styles from '../wire.module.css';
import MercadoPago from '../../../../uiComponents/icons/mercadoPago';

function TimeLineWire({ data, language, mode, withdrawalMethod }) {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.wiresPaymentDetail.timeline' });
  const { t: translate } = useTranslation();
  const getReservationEvent = (event) => {
    const textPayementExecuted =
      mode === 'MERCADOPAGO'
        ? t('reservationEvent.paymentExecutedMp')
        : mode === 'CASH'
        ? t('reservationEvent.paymentExecutedCashier')
        : mode === 'CERTIFICATE'
        ? t('reservationEvent.paymentExecutedCertificate')
        : mode === 'WIRE_INTERNATIONAL'
        ? t('reservationEvent.paymentExecutedTI')
        : t('reservationEvent.paymentExecutedTN');
    switch (event) {
      case 'CREATED':
        return t('reservationEvent.paymentLote');
      case 'PAYMENT_AUDIT_APPROVED':
        return t('reservationEvent.paymentSended');
      case 'PAYMENT_PENDING':
        return t('reservationEvent.paymentPending');
      case 'PAYMENT_ERROR':
        return t('reservationEvent.paymentError');
      case 'PAYMENT_EXECUTED':
        return textPayementExecuted;
      default:
        return t('reservationEvent.paymentDefault');
    }
  };

  return (
    <>
      <Subtitle text={t('title')} className={'container-subtitle subtitle-border'} />
      <ContanierSection>
        <div className={`${styles.timeline} ${styles.wire}`}>
          {data.map((i, index) => {
            const { dateTime } = formatDateTranslation(i.dateLocalTime, language, 'small');
            return (
              <div className={styles.timelineItem} key={index}>
                <div className='row no-gutters'>
                  <div className='col-6 col-md-4 container-data'>
                    <div className='row no-gutters flex-nowrap'>
                      <div className='col-1'>
                        <div
                          className={`${styles.dot} ${
                            i.reservationEvent === 'PAYMENT_ERROR' && styles.alertDot
                          }`}
                        ></div>
                      </div>
                      <div className='col-auto '>
                        <InfoRenderer
                          content={getReservationEvent(i.reservationEvent)}
                          className='dark-grey container-label'
                        />
                        <Show.When
                          isTrue={
                            (mode === 'MERCADOPAGO' ||
                              mode === 'WIRE_INTERNATIONAL' ||
                              mode === 'WIRE_MEXICO') &&
                            i.reservationEvent === 'PAYMENT_EXECUTED'
                          }
                        >
                          <InfoRenderer
                            content={`${t('account')}: ${
                              mode === 'MERCADOPAGO'
                                ? withdrawalMethod?.email
                                : withdrawalMethod?.accountNumber
                            }`}
                            className='fw-400 dark-grey'
                          />
                        </Show.When>

                        <InfoRenderer
                          content={dateTime}
                          className='fw-400 dark-grey sentences-capitalize'
                        />
                        {/* Otros datos */}
                      </div>
                    </div>
                  </div>
                  <div className='col-6 col-md-4 container-data'>
                    {mode === 'MERCADOPAGO' &&
                    i.welletPayment &&
                    i.reservationEvent === 'PAYMENT_EXECUTED' ? (
                      <div className='d-flex align-items-center'>
                        <div style={{ marginRight: '1rem' }}>
                          <MercadoPago size={'40px'} />
                        </div>
                        <span className='fw-500 '>Mercado Pago</span>
                      </div>
                    ) : i.user === null ? null : (
                      <AffiliateCard
                        name={getUserRole(i.user?.role, translate)}
                        url={i.user?.profilePicUrl}
                        company={i.user?.fullName}
                        size='40px'
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ContanierSection>
    </>
  );
}

export default TimeLineWire;
