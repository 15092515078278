export default function mergeWithDefaults(localStorageData, defaultData) {
  return defaultData.map((newItem) => {
    const existingItem = localStorageData?.find((item) => item.value === newItem.value);
    if (existingItem) {
      return {
        ...existingItem,
        label: newItem.label,
      };
    } else {
      return newItem;
    }
  });
}

export const checkKeyInLocalStorage = (storageKey, objectKey) => {
  const item = localStorage.getItem(storageKey);
  if (item) {
    try {
      const parsedItem = JSON.parse(item);
      return parsedItem[objectKey] ? true : false;
    } catch (e) {
      console.error(`Error parsing ${storageKey} from localStorage`, e);
      return false;
    }
  }
  return false;
};
