export const getAllComissions = async (
  welletContext,
  marketId = null,
  orgId = null,
  showId = null,
  name = null,
  reservationCode = null,
  dates = {
    from: null,
    to: null,
  },
  limit = null,
  skip = null,
  orderId = null,
  assoicateId = null,
  status,
  operationTypes,
) => {
  const url = 'api/Wallet/commissions';
  const newUrl = assoicateId ? `${url}/${marketId}?id=${assoicateId}` : `${url}/${marketId}`;
  let showIdArray = showId.length === 0 ? null : showId;

  const body = {
    From: dates.from,
    To: dates.to,
    organizationId: orgId,
    userName: name,
    reservationCode: reservationCode,
    showId: showIdArray,
    orderId,
    limit,
    skip,
    status: status ? [status] : [],
    operationTypes: operationTypes ? [operationTypes] : [],
  };

  return await welletContext.apis.admin.post(newUrl, body);
};

export const getComissionStates = async (
  welletContext,
  marketId = null,
  orgId = null,
  showId = null,
  paymentTypes = null,
  fieldFilter = null,
  dates = {
    from: null,
    to: null,
  },
  limit = null,
  skip = null,
  orderId = null,
  assoicateId = null,
  status,
  operationTypes,
  RPId,
  bossIds,
  orderBy = null,
  orderType = null,
  reservationType = [],
  citiesIds = [],
) => {
  const url = '/api/Wallet/commissionStates/' + marketId;
  let showIdArray = showId.length === 0 ? null : showId;
  let paymentTypesArray = paymentTypes != null && paymentTypes.length === 0 ? null : paymentTypes;

  const body = {
    From: dates.from,
    To: dates.to,
    organizationId: orgId,
    fieldFilter,
    showId: showIdArray,
    paymentTypes: paymentTypesArray,
    orderId,
    limit,
    skip,
    status: status ? status : [],
    operationTypes: operationTypes ? [operationTypes] : [],
    userid: assoicateId ? assoicateId : null,
    RPId: RPId ? RPId : [],
    bossIds: bossIds ? bossIds : [],
    sortBy: orderBy,
    columnFilter: orderType,
    reservationType: reservationType.length > 0 ? reservationType : null,
    citiesIds: citiesIds ? citiesIds : [],
  };

  return await welletContext.apis.admin.post(url, body);
};

export const getCommissionsByOrderId = async (welletContext, marketId, orderId) => {
  const url = `/api/wallet/commissions/${marketId}/order/${orderId}`;

  return await welletContext.apis.admin.get(url);
};
export const getAllShows = async (welletContext, marketId = null, orgId = null) => {
  const url = `api/Activity/all/${marketId}/simple`;
  const body = {
    limit: null,
    skip: 0,
    organizationId: orgId,
    includeInactive: false,
    ActivityType: ['RESTAURANT'],
  };
  return await welletContext.apis.admin.post(url, body);
};
export const getComissionsDetail = async (welletContext, id) => {
  const url = `/api/wallet/commission/${id}/detail`;

  return await welletContext.apis.admin.get(url);
};
export const getDisputes = async (
  welletContext,
  marketId = null,
  orgId = null,
  showId = null,
  paymentTypes = null,
  name = null,
  reservationCode = null,
  dates = {
    from: null,
    to: null,
  },
  limit = null,
  skip = null,
  orderId = null,
  assoicateId = null,
  status,
  operationTypes,
) => {
  const url = '/api/Wallet/disputes';
  const newUrl = assoicateId ? `${url}/${marketId}?id=${assoicateId}` : `${url}/${marketId}`;
  let showIdArray = showId.length === 0 ? null : showId;
  let paymentTypesArray = paymentTypes != null && paymentTypes.length === 0 ? null : paymentTypes;

  const body = {
    From: dates.from,
    To: dates.to,
    organizationId: orgId,
    userName: name,
    reservationCode: reservationCode,
    showId: showIdArray,
    paymentTypes: paymentTypesArray,
    orderId,
    limit,
    skip,
    status: status ? [status] : [],
    operationTypes: operationTypes ? [operationTypes] : [],
  };

  return await welletContext.apis.admin.post(newUrl, body);
};
export const downloadCommissions = async (welletContext, marketId, body) => {
  return await welletContext.apis.admin.post(`api/excelreport/commissions/${marketId}`, body, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
