import React, { useContext, useEffect, useState } from 'react';
import { useCreateBreadCrumbs, usePagination } from '../../hooks';
import {
  AffiliateCard,
  ContanierSection,
  Loading,
  Money,
  PageError,
  Subtitle,
  Tabs,
} from '../../uiComponents/common';
import { DateFilter, InfoRenderer, Show, Table, TableCell } from '../../components';
import { Link, useParams } from 'react-router-dom';
import {
  formatDateTranslation,
  getDateFromOffset,
  getLocale,
  getWeekAgo,
} from '../../helpers/dates';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { CommentIcon } from '../../uiComponents/icons';
import { useTranslation } from 'react-i18next';
import { getCampaignDetail, getCampaignReservations } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { getOccasionText } from '../../utils/getOcassionIcon';
import { CampaignsStatisticsDetails } from '../../components/campaigns';
import { getReservationType } from '../../helpers/format';
import { useDispatch } from 'react-redux';
import { setSelectedDate } from '../../actions';

export const getCampaignIcon = (type) => {
  switch (type) {
    case 'estado':
      return (
        <div className='campaign-icon'>
          <CommentIcon size='24px' color='#fff' />
        </div>
      );
    default:
      return null;
  }
};

const CampaignDetail = () => {
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const offset = useSelector((state) => state.app.utcOffset);
  const dispatch = useDispatch();
  const todayDate = getDateFromOffset(offset);
  const sevenDaysAgo = getWeekAgo(todayDate);
  const { id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: `screens.campaignDetail` });
  const { t: translate } = useTranslation();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const [activeTab, setActiveTab] = useState('INFO');
  const tabs = [
    { isActive: activeTab === 'STATISTICS', title: t('tabs.stats'), type: 'STATISTICS' },
    { isActive: activeTab === 'INFO', title: t('tabs.info'), type: 'INFO' },
  ];
  const handleTabs = (type) => {
    setActiveTab(type);
  };
  useCreateBreadCrumbs([
    { name: t('campaigns'), path: '/campaigns-list' },
    { name: t('smallTitle') },
  ]);
  const campaignDetailQuery = useQuery({
    queryKey: ['campaign-detail', id],
    queryFn: () => getCampaignDetail(welletContext, organization.marketId, id),
    ...defaultQueryOptions,
    onSuccess: (data) => {
      if (data?.data?.fromDate) {
        dispatch(
          setSelectedDate({
            from: data?.data?.fromDate,
            to: data?.data?.toDate,
            formattedFrom: format(data?.data?.fromDate, 'yyyy-MM-dd'),
            formattedTo: format(data?.data?.toDate, 'yyyy-MM-dd'),
          }),
        );
      }
    },
  });

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  useEffect(() => {
    return () => {
      dispatch(
        setSelectedDate({
          from: format(sevenDaysAgo, 'yyyy-MM-dd') + 'T' + format(sevenDaysAgo, 'HH:mm:ss'),
          to: format(todayDate, 'yyyy-MM-dd') + 'T' + format(todayDate, 'HH:mm:ss'),
          formattedFrom: format(sevenDaysAgo, 'yyyy-MM-dd'),
          formattedTo: format(todayDate, 'yyyy-MM-dd'),
        }),
      );
    };
  }, []);

  const campaignReservationsQuery = useQuery({
    queryKey: ['campaign-reservations', limit, skip, id],
    queryFn: () => getCampaignReservations(welletContext, organization.marketId, id, limit, skip),
    onSuccess: (data) => setTotalAndReset(data?.data?.totalRegisters),
    ...defaultQueryOptions,
  });

  return (
    <>
      <Show>
        <Show.When isTrue={campaignDetailQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={campaignDetailQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='container-page'>
            <div className='container-header with-border mb-0'>
              <div className='row justify-content-between align-items-end'>
                <div className='col-auto'>
                  <div className='light-grey container-small-title text-uppercase'>
                    {t('smallTitle')}
                  </div>
                  <div className='container-title d-flex justify-content-start align-items-center'>
                    {campaignDetailQuery?.data?.data?.campaignName}
                  </div>
                </div>
                <Show.When isTrue={activeTab === 'STATISTICS'}>
                  <DateFilter
                    minDate={
                      campaignDetailQuery?.data?.data?.fromDate
                        ? new Date(campaignDetailQuery?.data?.data?.fromDate)
                        : undefined
                    }
                    maxDate={
                      campaignDetailQuery?.data?.data?.toDate
                        ? new Date(campaignDetailQuery?.data?.data?.toDate)
                        : undefined
                    }
                    showStaticRanges={campaignDetailQuery?.data?.data?.fromDate ? false : true}
                  />
                </Show.When>
              </div>
            </div>
            <Tabs options={tabs} onClick={handleTabs} />
            <Show.When isTrue={activeTab === 'INFO'}>
              <ContanierSection maxWidth='1200px'>
                <div className='row no-gutters mt-4'>
                  <div className='col-12 col-md-3'>
                    <div className='row no-gutters'>
                      <div className='col-12 col-md fw-500  container-data'>
                        <div className='dark-grey'>{t('restaurant')}</div>
                        <InfoRenderer content={campaignDetailQuery?.data?.data?.showName} />
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-3 fw-500  container-data'>
                    <div className='dark-grey'>{t('location')}</div>
                    <InfoRenderer content={campaignDetailQuery?.data?.data?.showCity} />
                  </div>
                  <div className='col-12 col-md fw-500  container-data'>
                    <div className='dark-grey'>{t('creationDate')}</div>
                    <InfoRenderer
                      content={
                        formatDateTranslation(
                          campaignDetailQuery?.data?.data?.creationDate,
                          language,
                          'small',
                        )?.dateTime
                      }
                    />
                  </div>
                </div>
                <div className='row no-gutters container-data mt-4'>
                  <div className='col-12 col-md-3'>
                    <div className='dark-grey fw-500'>{t('campaignName')}</div>
                    <div className='fw-600 fs-16 pr-1'>
                      <InfoRenderer content={campaignDetailQuery?.data?.data?.campaignName} />
                    </div>
                  </div>
                  <div className='col-12 col-md'>
                    <div className='dark-grey fw-500'>{t('description')}</div>
                    <div className='fw-600'>
                      <InfoRenderer content={campaignDetailQuery?.data?.data?.description} />
                    </div>
                  </div>
                </div>
                <div className='row no-gutters container-data mt-3'>
                  <div className='col-12 col-md-3'>
                    <div className='dark-grey fw-500'>{t('totalIncomes')}</div>
                    <div className='fw-700' style={{ fontSize: '20px' }}>
                      <Money value={campaignDetailQuery?.data?.data?.income} />
                    </div>
                  </div>
                  <div className='col-12 col-md'>
                    <div className='dark-grey fw-500'>{t('commissions')}</div>
                    <div className='fw-600 fs-16'>
                      {' '}
                      <Money value={campaignDetailQuery?.data?.data?.commission} />
                    </div>
                  </div>
                </div>
                <div className='row no-gutters container-data mt-3'>
                  <div className='col-12 col-md-3'>
                    <div className='dark-grey fw-500'>{t('multimedia')}</div>
                    <div className='fw-600 fs-16'>
                      {campaignDetailQuery?.data?.data?.media ? (
                        <Link
                          className='custom-link-file'
                          to={campaignDetailQuery?.data?.data?.media[0]?.mediaUrl}
                          target='_blank'
                        >
                          {t('click')}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                  <div className='col-12 col-md'>
                    <div className='dark-grey fw-500'>{t('duration')}</div>
                    <div className='fw-600 fs-16 text-capitalize'>
                      {campaignDetailQuery?.data?.data?.fromDate
                        ? `${format(
                            new Date(campaignDetailQuery?.data?.data?.fromDate),
                            'MMMM dd, yyyy',
                            { locale },
                          )} - ${
                            campaignDetailQuery?.data?.data?.toDate
                              ? format(
                                  new Date(campaignDetailQuery?.data?.data?.toDate),
                                  'MMMM dd, yyyy',
                                  { locale },
                                )
                              : t('noEnd')
                          }`
                        : t('withoutDate')}
                    </div>
                  </div>
                </div>
              </ContanierSection>
              <Subtitle text={t('reservations.title')} marginBottom='mb-3' />
              <Show>
                <Show.When isTrue={campaignReservationsQuery.isLoading}>
                  <Loading />
                </Show.When>
                <Show.Else>
                  <Table
                    data={
                      campaignReservationsQuery?.data?.data?.items
                        ? campaignReservationsQuery?.data?.data?.items
                        : []
                    }
                    columns={[
                      { title: t('reservations.table.code') },
                      { title: 'Concierge' },
                      { title: t('reservations.table.commission') },
                      { title: t('reservations.table.customer') },
                      { title: t('reservations.table.time'), center: true },
                      { title: t('reservations.table.type') },
                      { title: t('reservations.table.occasion') },
                      { title: t('reservations.table.people'), center: true },
                      { title: t('reservations.table.consumption') },
                    ]}
                    hasPagination={false}
                    totalP={totalItems}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    renderRow={(reservation, index) => {
                      const { date, time } = formatDateTranslation(
                        reservation?.date,
                        language,
                        'extraSmall',
                      );
                      return (
                        <>
                          <TableCell style={{ width: '150px' }}>
                            <Link
                              to={`/reservation-history/${reservation.id}`}
                              className='custom-link-file'
                            >
                              {reservation?.referenceCode}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <AffiliateCard
                              url={reservation?.seller?.profilePicUrl}
                              company={reservation?.seller?.company}
                              isVerified={reservation?.seller?.isVerified}
                              name={reservation?.seller?.fullName}
                            />
                          </TableCell>

                          <TableCell style={{ width: '200px' }} className='fw-700'>
                            {reservation?.commission ? (
                              <Money value={reservation?.commission} />
                            ) : (
                              '-'
                            )}
                          </TableCell>

                          <TableCell className={'fw-700'}>{reservation.customerName}</TableCell>

                          <TableCell className={'text-center'}>
                            <div>
                              <span className='sentences-capitalize container-label d-block'>
                                {date}
                              </span>
                              <span className='text-center'>{time}</span>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>{getReservationType(reservation?.reservationType, translate)}</div>
                          </TableCell>
                          <TableCell style={{ width: '150px' }} className={'dark-grey'}>
                            {reservation?.occasion
                              ? getOccasionText(reservation?.occasion, translate)
                              : '-'}
                          </TableCell>
                          <TableCell className={'fw-700 text-center'}>{reservation?.pax}</TableCell>
                          <TableCell className={'fw-700'}>
                            {reservation?.consumption ? (
                              <Money value={reservation?.consumption} />
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        </>
                      );
                    }}
                    rowClass='not-hover'
                  />
                </Show.Else>
              </Show>
            </Show.When>

            <Show.When isTrue={activeTab === 'STATISTICS'}>
              <CampaignsStatisticsDetails id={id} show={activeTab === 'STATISTICS'} />
            </Show.When>
          </div>
        </Show.Else>
      </Show>
    </>
  );
};

export default CampaignDetail;
