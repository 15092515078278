import React, { useContext, useState } from 'react';
import { Button, Modal, Toast } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { patchConfirmRejectReservation } from '../../services';
import { useTranslation } from 'react-i18next';
import { Show } from '../../components';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const ModalCancellation = ({ onClose, handleSubmit, reservation }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalCancellation' });
  const [openCancellation, setOpenCancellation] = useState(false);
  const { getClientTranslation } = useGenericTranslation()
  // API Querys
  const cancellationMutation = useMutation({
    mutationFn: (body) => {
      return patchConfirmRejectReservation(welletContext, reservation?.actualReservation?.id);
    },
  });

  // Functions
  const handleModal = () => {
    setOpenCancellation((prevState) => !prevState);
  };
  const handleClose = () => {
    onClose();
    handleModal();
  };
  const onSubmit = () => {
    return cancellationMutation.mutate();
  };

  return (
    <>
      <Show.When isTrue={cancellationMutation.isSuccess}>
        <Toast message={t('toastSucces')} type={`SUCCESS`} onCallback={handleClose} />
      </Show.When>
      <Show.When isTrue={cancellationMutation.isError}>
        <Toast message={t('toastError')} type={`ERROR`} onCallback={handleClose} />
      </Show.When>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate'}
        onClick={handleModal}
        size={'custom'}
        text={t('btnCancel')}
      />
      <Show.When isTrue={openCancellation}>
        <Modal
          title={getClientTranslation('cancelOpenTable')}
          open={openCancellation}
          onClose={handleClose}
          maxWidth='450px'
          closeIcon={true}
          handleSubmit={handleSubmit}
          closeOnDocumentClick={false}
          customButtons={
            <>
              <div className='row px-2 text-center'>
                <Button
                  text={t('btnClose')}
                  disabled={cancellationMutation.isLoading || cancellationMutation.isSuccess}
                  className={'col btn-general btn-wellet-secondary'}
                  size={'medium'}
                  onClick={handleModal}
                />
                <Button
                  text={t('btnConfirmCancel')}
                  disabled={cancellationMutation.isLoading || cancellationMutation.isSuccess}
                  className={'col ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={() => onSubmit()}
                />
              </div>
            </>
          }
        >
          <div className='text-left mt-2 mb-3'>{t('ask')}</div>
        </Modal>
      </Show.When>
    </>
  );
};

export default ModalCancellation;
