import React from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import World from 'fusioncharts/maps/fusioncharts.world';
import Maps from 'fusioncharts/fusioncharts.maps';
import Usa from 'fusioncharts/maps/fusioncharts.usa';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import Zoomline from 'fusioncharts/fusioncharts.zoomline';
import Zoomscatter from 'fusioncharts/fusioncharts.zoomscatter';
import Vml from 'fusioncharts/fusioncharts.vml';
import gantt from 'fusioncharts/fusioncharts.gantt';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Overlappedcolumn2d from 'fusioncharts/fusioncharts.overlappedcolumn2d';
import ReactFusioncharts from 'react-fusioncharts';

ReactFC.fcRoot(
  FusionCharts,
  Maps,
  World,
  Usa,
  Charts,
  gantt,
  Widgets,
  Vml,
  FusionTheme,
  Zoomline,
  Zoomscatter,
  Column2D,
  Overlappedcolumn2d,
);

const ColumnOverlapped = ({ categories, series, width, height }) => {
  // Creating the JSON object to store the chart configurations

  const dataSource = {
    chart: {
      theme: 'fusion',
      paletteColors: ' #675DFF,#EAECF0,',
      legendPosition: 'top-right',
      showYAxisValues: 0,
      plotToolText: '<b>$toolText</b>',
      legendIconSides: '1',
    },
    categories: [
      {
        category: categories,
      },
    ],
    dataset: series,
  };

  return (
    <ReactFusioncharts
      type='overlappedcolumn2d'
      width={width}
      height={height}
      dataFormat='JSON'
      dataSource={dataSource}
    />
  );
};

export default ColumnOverlapped;