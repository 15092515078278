import React, { useContext } from 'react';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useOrderBy, usePagination } from '../../../hooks';
import { useQuery } from 'react-query';
import { getTopCampaigns } from '../../../services';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';
import { Loading, Money, PageError, Subtitle } from '../../../uiComponents/common';
import Table from '../../table/table';
import TableRow from '../../table/tableRow';
import TableCell from '../../table/tableCell';
import Show from '../../show/show';

const SuccessfullCampaigns = ({ show }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.campaigns.statistics.tables.campaigns',
  });
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);

  //   Dates
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };
  //Sort Columns
  const { orderBy, orderType, handleColumnClick } = useOrderBy();

  //Pagination
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    1,
    10,
    false,
  );
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // API Query
  const topCampaignsQuery = useQuery({
    queryKey: [
      'get-top-campaigns',
      limit,
      skip,
      orderBy,
      orderType,
      selectedFormattedDates,
      organization,
    ],
    queryFn: () => {
      return getTopCampaigns(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
        limit,
        skip,
        orderBy ? orderBy : 'DESC',
        orderType ? orderType : 'totalIncome',
        selectedFormattedDates,
      );
    },
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.totalRegisters);
    },
    onError: (error) => {
      console.error('Tabla Campaigns', error);
    },
    ...defaultQueryOptions,
    enabled: show,
  });

  const header = [
    { title: t('socialNetwork'), value: '', filterable: false },
    { title: t('reservation'), value: '', filterable: false },
    { title: t('incomes'), value: 'totalIncome', filterable: true },
  ];

  return (
    <Show>
      <Show.When isTrue={topCampaignsQuery.isLoading}>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '400px' }}
        >
          <Loading global={false} />
        </div>
      </Show.When>
      <Show.When isTrue={topCampaignsQuery.isError}>
        <PageError globaly={false} />
      </Show.When>
      <Show.Else>
        <Subtitle text={<h3 className='mb-0'>{t('title')}</h3>} border={false} />
        <div className='mt-2'>
          <Table
            hasPagination={false}
            borderTop={false}
            showPagination={true}
            columns={header}
            totalP={totalItems}
            onColumnClick={handleColumnClick}
            activeColumn={orderType}
            sortOrder={orderBy}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagination='normal'
            borderContainer={true}
            header='white'
            showText={false}
            data={topCampaignsQuery?.data?.data?.items}
            paddingTh={'padding-white-header'}
            renderRow={(campaign) => (
              <>
                <TableCell>
                  <div className='row flex-nowrap'>
                    {/* <div className='col-auto pr-0'>
                      <img
                        src={campaign.networkLogo}
                        alt={campaign.name}
                        style={{ width: '38px', height: '38px', borderRadius: '50%' }}
                      />
                    </div> */}
                    <div className='col my-auto'>
                      <div className='fw-500'>{campaign.name}</div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={'dark-grey'}>{campaign.totalReservations}</TableCell>
                <TableCell className='fw-500' type={'primary'}>
                  <Money value={campaign.totalIncome} />
                </TableCell>
              </>
            )}
            rowClass='not-hover'
          />
        </div>
      </Show.Else>
    </Show>
  );
};

export default SuccessfullCampaigns;
