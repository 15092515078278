import { useContext, useEffect } from 'react';

import { BreadCrumbContext } from '../context/breadcrumbContext';
import { useSelector } from 'react-redux';

const useCreateBreadCrumbs = (initialCrumbs, update) => {
  const { breadcrumbs, updateBreadcrumbs } = useContext(BreadCrumbContext);
  const language = useSelector((state) => state.app.language)

  useEffect(() => {
    updateBreadcrumbs(initialCrumbs);
    return () => updateBreadcrumbs([]);
  }, [update, language]);

  return { breadcrumbs, updateBreadcrumbs };
};

export default useCreateBreadCrumbs;
