import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Pie2d, Column2d } from '../charts';
import { CardDashboard, Subtitle } from '../../uiComponents/common';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { getCityDetails, getWeekdaySummary } from '../../services';
import AffiliatesTable from './affiliatesTable';
import UnitsTable from './unitsTable';
import { useTranslation } from 'react-i18next';
import { translateDayToEnglish } from '../../helpers/dates';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const DashboardRestaurants = ({ selectedOption }) => {
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.dashboard' });
  const { getClientTranslation } = useGenericTranslation();
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };

  const dashboardQuery = useQuery({
    queryKey: ['dashboard-city', organization.id, selectedFormattedDates],
    queryFn: () =>
      getCityDetails(
        welletContext,
        organization.marketId,
        selectedFormattedDates,
        !organization.isMarket ? organization.id : null,
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const weekdayQuery = useQuery({
    queryKey: ['dashboard-weekday', organization.id, selectedFormattedDates],
    queryFn: () =>
      getWeekdaySummary(
        welletContext,
        organization.marketId,
        selectedFormattedDates,
        !organization.isMarket ? organization.id : null,
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const getDataForColumn = () => {
    const result = weekdayQuery.data?.data?.map((a) => {
      return {
        value: a.income,
        label: t(`graphics.dayOfWeek.weekday.${translateDayToEnglish(a.weekday)}`),
      };
    });
    return result;
  };

  const isLaptop = window.innerWidth < 1400;

  return (
    <>
      <div className='row mt-4'>
        <div className='col-12 col-md-7 pr-3'>
          <Subtitle text={<h3>{t('graphics.dayOfWeek.title')}</h3>} />
          <div className='mt-3'>
            <Column2d data={getDataForColumn()}  height={'500'} width={'100%'} />
          </div>
        </div>
        <div className='col-12 col-md-5 pl-3'>
          <Subtitle text={<h3>{t('graphics.cities.title')}</h3>} />
          <div className='mt-3'>
            <Pie2d
              legendPosition={isLaptop ? 'bottom' : 'right-top'}
              data={dashboardQuery.data?.data
                ?.sort((a, b) => b?.income - a?.income)
                ?.map((o) => ({
                  label: o.city,
                  value: `${o.income}`,
                }))}
            />
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12 col-md-6 pr-3'>
          <Subtitle text={<h3>{getClientTranslation('title.plural')}</h3>} border={false} />
          <CardDashboard>
            <UnitsTable selectedOption={selectedOption} />
          </CardDashboard>
        </div>
        <div className='col-12 col-md-6 pl-3'>
          <Subtitle text={<h3>{t('tables.tableConcierge.title')}</h3>} border={false} />
          <CardDashboard>
            <AffiliatesTable selectedOption={selectedOption} />
          </CardDashboard>
        </div>
      </div>
    </>
  );
};

export default DashboardRestaurants;
