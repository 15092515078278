import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  getCampaignById,
  postCampaign,
  putCampaign,
} from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useMutation, useQuery } from 'react-query';
import { useCreateBreadCrumbs, useGetRestaurants } from '../../../hooks';
import { MediaSelector, Show } from '../..';
import {
  FormButton,
  InputCheckbox,
  InputDate,
  InputDescription,
  InputMedia,
  InputText,
  Loading,
  PageError,
  Select,
  Subtitle,
  Tabs,
  Toast,
} from '../../../uiComponents/common';
import FormRow from '../formRow';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';
import { useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import useGenericTranslation from '../../../hooks/useGenericTranslation';

const mediaTypes = {
  video: 'VIDEO',
  photo: 'PHOTO',
  qr: 'QR',
};
const CampaignForm = () => {
  const organization = useSelector((state) => state.app.selectedOrganization);
  const user = useSelector((state) => state.user.user);
  const { id } = useParams();
  const methods = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'forms.campaigns' });
  const welletContext = useContext(WelletContext);
  const [isRedirect, setIsRedirect] = useState(null);
  const [activeTab, setActiveTab] = useState('UPLOAD');
  const [currentMedia, setCurrentMedia] = useState(null);
  const [mediaError, setMediaError] = useState(null);

  const { getClientTranslation } = useGenericTranslation();
  // API Queries
  // const socialNetworkQuery = useSocialNetworkQuery(
  //   {
  //     dataKeys: [organization.id, null, null, null, null, false],
  //     options: null,
  //   },
  //   false,
  // );
  const restaurantesQuery = useGetRestaurants(organization, {
    enabled: organization.isMarket && !id,
  });

  // Data Formatted
  // const formattedTypes = socialNetworkQuery?.data?.data?.items?.map((item) => ({
  //   label: item.name,
  //   value: item.id,
  // }));
  const formatedRestaurant = restaurantesQuery?.data?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  // Watching  for conditional render
  let fromDate = methods.watch('from');
  const currentRestaurant = methods.watch('restaurant');

  const tabs = [
    { isActive: activeTab === 'UPLOAD', title: t('rowMultimedia.tabs.upload'), type: 'UPLOAD' },
    {
      isActive: activeTab === 'EXISTING_FILE',
      title: t('rowMultimedia.tabs.selectedFile'),
      type: 'EXISTING_FILE',
      disabled: currentRestaurant?.value ? false : true,
    },
  ];

  // Main API Query
  const campaignQuery = useQuery({
    queryKey: ['get-campaign-id', id],
    queryFn: () => getCampaignById(welletContext, id),
    onSuccess: (data) => {
      let mediaId = data?.data?.mediaList[0]?.mediaId ?? null;

      let mediaData = mediaId
        ? setCurrentMedia({
            id: mediaId,
            isSelected: true,
            type: data?.data?.mediaList[0]?.mediaType,
          })
        : {
            base64: data?.data?.mediaList[0]?.mediaUrl,
            name: data?.data?.mediaList[0]?.mediaUrl,
          };
      const formattedData = {
        name: data?.data?.name,
        description: data?.data?.description,
        // networkCampaign: data?.data?.networkName
        //   ? { value: data?.data?.networkCampaignId, label: data?.data?.networkName }
        //   : null,
        from: data?.data?.fromDate,
        to: data?.data?.toDate,
        isActive: data?.data?.isActive,
        restaurant: { label: data?.data?.showName, value: data?.data?.showId },

        media: mediaData,
      };
      if (mediaId) {
        setActiveTab('EXISTING_FILE');
      }
      methods.reset(formattedData);
    },
    ...defaultQueryOptions,
    enabled: !!id && formatedRestaurant !== null,
  });

  // API Mutation
  const campaignMutation = useMutation({
    mutationFn: (body) => {
      if (!!id) {
        return putCampaign(welletContext, body);
      }
      return postCampaign(welletContext, body);
    },
  });

  // Functions
  const onSubmit = (data) => {
    // Check if user upload file or choosen exisitng
    let media;
    if (activeTab === 'EXISTING_FILE') {
      if (currentMedia === null) {
        setMediaError(true);
        return;
      } else {
        setMediaError(false);
      }
      media = {
        mediaId: currentMedia.id,
        mediaType: currentMedia.type,
        name: null,
        media: null,
      };
    } else {
      media = {
        mediaId: null,
        mediaType: getMediaType(data?.media),
        name: data?.media?.name,
        media: data?.media?.base64,
      };
    }
    const body = {
      id: !!id ? id : null,
      showId: data?.restaurant?.value,
      // networkCampaignId: data?.networkCampaign?.value,
      name: data?.name,
      description: data?.description,
      mediaList: [media],
      toDate: data.to === '' ? null : data.to,
      fromDate: data.from === '' ? null : data.from,
      userId: user.sid,
      marketSetupId: organization.marketId,
      isActive: !!id ? data?.isActive : true,
    };

    campaignMutation.mutate(body);
  };
  const handleToastCallback = () => {
    if (isRedirect && !id) {
      navigate(`/campaigns-list`);
    }
    campaignMutation.reset();
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
  };
  const clearForm = () => {
    navigate(`/campaigns-list`);
  };
  const getMediaType = (data) => {
    if (data) {
      if (data?.name?.endsWith('.mp4')) {
        return mediaTypes.video;
      } else {
        return mediaTypes.photo;
      }
    } else {
      return null;
    }
  };
  const handleTabs = (type) => {
    setActiveTab(type);
  };
  useCreateBreadCrumbs(
    [
      { name: t(`breadcrumbs.prev`), path: `/campaigns-list` },
      {
        name: !!id
          ? t(`breadcrumbs.edit`) +
            ' ' +
            (campaignQuery?.data?.data?.name === undefined ? '' : campaignQuery?.data?.data?.name)
          : t(`breadcrumbs.new`),
      },
    ],
    campaignQuery?.data?.data?.name,
  );

  return (
    <>
      <Show.When isTrue={campaignMutation.isError}>
        <Toast message={t('toastError')} type='ERROR' onCallback={() => campaignMutation.reset()} />
      </Show.When>
      <Show.When isTrue={campaignMutation.isSuccess}>
        <Toast
          message={!!id ? t(`toastSuccess.edit`) : t(`toastSuccess.new`)}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      </Show.When>
      <Show>
        <Show.When isTrue={campaignQuery.isFetching || campaignQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={campaignQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <FormProvider {...methods}>
            <form key={10} onSubmit={methods.handleSubmit(onSubmit)}>
              <Subtitle
                text={<h3>{t(`title`)}</h3>}
                endComponent={
                  <FormButton
                    mutation={campaignMutation}
                    handleRedirect={handleRedirect}
                    clearForm={clearForm}
                    all={false}
                    nextStep={true}
                    isDisabled={
                      campaignMutation.isSuccess ||
                      campaignMutation.isLoading ||
                      campaignMutation.isError
                    }
                  />
                }
              />

              <FormRow
                text={t('rowName.title')}
                description={t('rowName.description')}
                centerContent
                endComponent={
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-12'>
                        <InputText
                          name={'name'}
                          label={''}
                          className={'mb-0 w-100'}
                          placeholder={t('rowName.placeholder')}
                          isRequired={true}
                          maxLength={510}
                          minLength={1}
                        />
                      </div>
                    </div>
                  </div>
                }
              />

              <FormRow
                text={t('rowDescription.title')}
                description={t('rowDescription.description')}
                centerContent
                endComponent={
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-12'>
                        <InputDescription
                          name={'description'}
                          className=''
                          placeholder={t('rowDescription.placeholder')}
                          isRequired={true}
                          height={80}
                        />
                      </div>
                    </div>
                  </div>
                }
              />

              <FormRow
                text={getClientTranslation('title.singular')}
                description={t('rowRestaurant.description', {
                  title: getClientTranslation('title.singular').toLocaleLowerCase(),
                })}
                centerContent
                endComponent={
                  <div className='col-6 pr-0'>
                    <div className='row'>
                      <Select
                        options={formatedRestaurant}
                        label={''}
                        placeholder={t('rowRestaurant.placeholder', {
                          title: getClientTranslation('title.singular').toLocaleLowerCase(),
                        })}
                        className={'mb-3 col-12 pr-0'}
                        name={'restaurant'}
                        isRequired={true}
                        isDisabled={id}
                      />
                    </div>
                  </div>
                }
              />

              {/* <FormRow
                text={t('rowTypesNetworks.title')}
                description={t('rowTypesNetworks.description')}
                centerContent
                endComponent={
                  <div className='col-6 '>
                    <div className='row'>
                      <Select
                        options={formattedTypes}
                        placeholder={t('rowTypesNetworks.placeholder')}
                        className={'mb-3 col-12'}
                        name={'networkCampaign'}
                        isRequired={true}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        blurInputOnSelect={false}
                        hideSelectedOptions={false}
                      />
                    </div>
                  </div>
                }
              /> */}
              <FormRow
                text={t('rowDate.title')}
                description={t('rowDate.description')}
                centerContent
                endComponent={
                  <div className='col-6 '>
                    <div className='row'>
                      <div className='col-6'>
                        <InputDate
                          name={'from'}
                          placeholder={t('rowDate.placeholder.from')}
                          label={t('rowDate.label.from')}
                          minDate={new Date()}
                          clear
                          clearCallback={() => methods.resetField('to')}
                        />
                      </div>
                      <div className='col-6'>
                        <InputDate
                          name={'to'}
                          placeholder={t('rowDate.placeholder.to')}
                          label={t('rowDate.label.to')}
                          minDate={fromDate ? parseISO(fromDate) : null}
                          isDisabled={!fromDate}
                          cleanIfDisable={true}
                          isRequired={fromDate ? true : false}
                          clear
                        />
                      </div>
                    </div>
                  </div>
                }
              />

              <Show.When isTrue={!!id}>
                <FormRow
                  text={t('rowPost.title')}
                  description={t('rowPost.description')}
                  centerContent
                  endComponent={
                    <div className='col'>
                      <InputCheckbox name={'isActive'} label={t('rowPost.label')} />
                    </div>
                  }
                />
              </Show.When>

              <FormRow
                text={t('rowMultimedia.title')}
                description={t('rowMultimedia.description', {
                  title: getClientTranslation('title.singular')?.toLocaleLowerCase(),
                })}
                endComponent={
                  <div className='col-6'>
                    <Tabs options={tabs} onClick={handleTabs} />
                    <div className='row my-4'>
                      <Show.When isTrue={activeTab === 'UPLOAD'}>
                        <div className='col-6'>
                          <InputMedia
                            id={'media'}
                            name={'media'}
                            video={true}
                            maxSize={524288000}
                            isRequired={true}
                          />
                        </div>
                      </Show.When>
                      <Show.When isTrue={activeTab === 'EXISTING_FILE'}>
                        <div className='col-12'>
                          <MediaSelector
                            restaurantId={currentRestaurant?.value ?? null}
                            currentMedia={currentMedia}
                            setCurrentMedia={setCurrentMedia}
                            show={activeTab === 'EXISTING_FILE'}
                            error={mediaError}
                          />
                        </div>
                      </Show.When>
                    </div>
                  </div>
                }
              />
              <div className='d-flex justify-content-end'>
                <FormButton
                  mutation={campaignMutation}
                  handleRedirect={handleRedirect}
                  clearForm={clearForm}
                  all={false}
                  nextStep={true}
                  isDisabled={
                    campaignMutation.isSuccess ||
                    campaignMutation.isLoading ||
                    campaignMutation.isError
                  }
                />
              </div>
            </form>
          </FormProvider>
        </Show.Else>
      </Show>
    </>
  );
};

export default CampaignForm;
