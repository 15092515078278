import React, { useContext, useState } from 'react';
import { Show } from '../../components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { FormHeader, Loading, PageError } from '../../uiComponents/common';
import { FormProvider, useForm } from 'react-hook-form';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
// import { getMembershipLevel } from '../services';
import CardCheck from '../../uiComponents/common/cardCheck/cardCheck';
import { Modallevels, ModalDelete } from './components';
import FormRow from '../../components/forms/formRow';
import { getMembershipLevel } from '../../services';

const Loyalty = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.memberships.levels' });
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext, organization.marketId);
  const [level, setLevel] = useState({});
  const [openDelete, setOpenDelete] = useState(null);

  const levelsQuery = useQuery({
    queryKey: ['levels', organization.marketId],
    queryFn: () => getMembershipLevel(welletContext, organization.marketId),
    ...defaultQueryOptions,
  });
  const methods = useForm();

  return (
    <>
      {/* <PageHeader title={t('title')} /> */}
      <Show>
        <Show.When isTrue={levelsQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={levelsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <FormProvider {...methods}>
            <form key={'levels'}>
              <FormHeader title={t('form.title')} subtitle={t('form.subtitle')} border={true} />
              <FormRow
                text={t('form.inputLevel.title')}
                withBorder={false}
                description={t('form.inputLevel.description')}
                endComponent={
                  <>
                    <div className='col-12 col-md-6 mt-2'>
                      {levelsQuery?.data?.data?.map((level, index) => (
                        <CardCheck
                          className={index !== 0 ? 'mt-2' : ''}
                          key={level.id}
                          title={level.name}
                          check={level.isActive}
                          onClick={() => setLevel(level)}
                          subtitle={level.targetPoints}
                          showDelete={true}
                          onDeleteClick={() => setOpenDelete(level)}
                        />
                      ))}
                      <Modallevels
                        callback={levelsQuery.refetch}
                        level={level}
                        setLevel={setLevel}
                      />
                      <ModalDelete
                        refetchLevels={levelsQuery.refetch}
                        open={openDelete}
                        setOpen={setOpenDelete}
                      />
                    </div>
                  </>
                }
              />
            </form>
          </FormProvider>
        </Show.Else>
      </Show>
    </>
  );
};

export default Loyalty;
