import React, { useContext, useState } from 'react';
import { LawIcon, OutlineUserIcon } from '../../uiComponents/icons';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  InputDescription,
  InputMultipleFiles,
  InputText,
  Loading,
  Money,
  PageError,
  Toast,
} from '../../uiComponents/common';
import { useNavigate, useParams } from 'react-router-dom';
import { Show } from '../../components';
import styles from './refute.module.css';
import { useTranslation } from 'react-i18next';
import { useCreateBreadCrumbs } from '../../hooks';
import { getDisputedPayment, refutePayment } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { formatDateTranslation } from '../../helpers/dates';
import { getReasons } from '../../helpers/reasons';

const Refute = () => {
  const { id } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.refute',
  });
  const { t: translateRefute } = useTranslation();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const language = useSelector((state) => state.app.language);
  const [step, setStep] = useState(1);
  const [whyInFavor, setWhyInFavor] = useState('CARDHOLDER_WITHDREW_DISPUTE');
  const [showAdditional, setShowAdditional] = useState(false);
  const welletContext = useContext(WelletContext);

  const onOptionChange = (e, form) => {
    if (form === 'favor') {
      setWhyInFavor(e.target.value);
      if (e.target.value !== 'OTHER') methods.setValue('otherDescription', '');
    }
  };
  const navigate = useNavigate();

  const renderCheckBox = (state, text, className, name, type) => (
    <div className={`${className} d-flex`}>
      <input
        type='radio'
        name={name}
        value={state}
        className={'mr-2 cursor-pointer'}
        id={state}
        checked={type === state}
        onChange={(e) => onOptionChange(e, name)}
      />
      <label className='fw-500 cursor-pointer' htmlFor={state}>
        {text}
      </label>
    </div>
  );

  useCreateBreadCrumbs([
    {
      name: t('breadcrumbs.prev'),
      path: `/disputes/${id}`,
    },
    {
      name: t('breadcrumbs.current'),
    },
  ]);

  const paymentInfoQuery = useQuery({
    queryKey: ['payment-dispute', id, organization.marketId],
    queryFn: () => getDisputedPayment(welletContext, organization.marketId, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess: (data) => {
      methods.reset({
        customerMail: data?.data?.customerEmail,
        customerName: data?.data?.customer,
      });
    },
  });

  const methods = useForm();

  const refuteMutation = useMutation({
    mutationFn: (body) => {
      return refutePayment(welletContext, body);
    },
  });

  const onSubmit = (data) => {
    let body = {
      refutationType: whyInFavor,
      refutationDescription: data?.otherDescription,
      additionalInformation: data?.moreInfo,
      paymentId: id,
      documents: data.files,
    };
    refuteMutation.mutate(body);
  };

  const handleSuccessRefute = () => {
    refuteMutation.reset();
    navigate(`/disputes/${id}`);
  };

  return (
    <Show>
      <Show.When isTrue={paymentInfoQuery.isLoading}>
        <Loading />
      </Show.When>
      <Show.When isTrue={paymentInfoQuery.isError}>
        <PageError />
      </Show.When>
      <Show.Else>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='fw-600 fs-1 text-uppercase'>{t('step', { step, total: 2 })}</div>
            <div className='row'>
              <div className='col-8'>
                <Show.When isTrue={step === 1}>
                  <div className='fw-600 fs-25 mt-1'>{t('title')}</div>
                  <div className='mt-1'>{t('secondary')}</div>
                  <div className='mt-3'>
                    <div className='fw-600 fs-16 d-flex'>
                      <LawIcon size={'20px'} /> <span className='ml-1'>{t('why')}</span>
                    </div>
                    <div className='d-flex flex-column pl-3 mt-2'>
                      {renderCheckBox(
                        'CARDHOLDER_WITHDREW_DISPUTE',
                        t('CARDHOLDER_WITHDREW_DISPUTE'),
                        'mb-1',
                        'favor',
                        whyInFavor,
                      )}
                      {renderCheckBox(
                        'CARDHOLDER_RECEIVED_REFUND',
                        t('CARDHOLDER_RECEIVED_REFUND'),
                        'mb-1',
                        'favor',
                        whyInFavor,
                      )}
                      {renderCheckBox(
                        'TRANSACTION_NOT_REFUNDABLE',
                        t('TRANSACTION_NOT_REFUNDABLE'),
                        'mb-1',
                        'favor',
                        whyInFavor,
                      )}
                      {renderCheckBox(
                        'DISPUTE_FILED_AFTER_RETURN_CANCELLATION_DEADLINE',
                        t('DISPUTE_FILED_AFTER_RETURN_CANCELLATION_DEADLINE'),
                        'mb-1',
                        'favor',
                        whyInFavor,
                      )}
                      {renderCheckBox(
                        'CARDHOLDER_RECEIVED_CREDIT_OR_VOUCHER',
                        t('CARDHOLDER_RECEIVED_CREDIT_OR_VOUCHER'),
                        'mb-1',
                        'favor',
                        whyInFavor,
                      )}
                      {renderCheckBox(
                        'EVENT_CANCELLED_DUE_TO_GOVERNMENT_REGULATION',
                        t('EVENT_CANCELLED_DUE_TO_GOVERNMENT_REGULATION'),
                        'mb-1',
                        'favor',
                        whyInFavor,
                      )}
                      {renderCheckBox('OTHER', t('OTHER'), 'mb-1', 'favor', whyInFavor)}
                    </div>
                  </div>

                  {whyInFavor === 'OTHER' ? (
                    <div className='row mt-2 pl-3'>
                      <InputDescription
                        className='col-6'
                        name={'otherDescription'}
                        icon={null}
                        label={t('description')}
                        height='60px'
                      />
                    </div>
                  ) : null}
                  <div className='d-flex justify-content-center mt-3 mb-2'>
                    <Button
                      size={'medium'}
                      text={t('cancel')}
                      type='button'
                      onClick={() => navigate('/disputes')}
                      className={'btn-general btn-wellet-secondary mr-2'}
                    />
                    <Button
                      size={'medium'}
                      text={t('continue')}
                      type='button'
                      onClick={() => setStep(2)}
                      className={'btn-general btn-wellet-primary'}
                    />
                  </div>
                </Show.When>
                <Show.When isTrue={step === 2}>
                  <div className='fw-600 fs-25 mt-1'>{t('evidences')}</div>
                  <div className={styles.cardEvidence}>
                    <div className='fw-500'>{t('uploadEvidence')}</div>
                    <div className='dark-grey mt-2'>{t('textEvidence')}</div>
                    <div className='dark-grey mt-2 fs-12'>{t('uploadRecommendation')}</div>
                    <InputMultipleFiles name={'files'} isRequired={true} />
                  </div>
                  <div
                    className='cursor-pointer d-inline-flex mt-3 align-items-center text-primary-color'
                    onClick={() => setShowAdditional((prev) => !prev)}
                  >
                    <div className='fs-20 mr-1 d-inline'>{!showAdditional ? '+' : '-'}</div>
                    <div>{t('additionalInfo')}</div>
                  </div>
                  <div className={styles.clientContainer}>
                    {showAdditional ? (
                      <InputDescription
                        tooltip={t('additionalTooltip')}
                        label={t('additionalInfo')}
                        name={'moreInfo'}
                        height='60px'
                      />
                    ) : null}

                    <div className='fw-600 fs-16 d-flex align-items-center mt-3'>
                      <OutlineUserIcon size={'16px'} />{' '}
                      <span className='ml-1'>{t('customerInfo')}</span>
                    </div>
                    <div className={`my-2`}>
                      {methods.getValues('customerMail') ? (
                        <div className='row align-items-center'>
                          <InputText
                            className={'col'}
                            isDisabled={true}
                            name={'customerMail'}
                            label={t('customerMail')}
                          />
                        </div>
                      ) : null}
                      <div className='row align-items-center'>
                        <InputText
                          isDisabled={true}
                          className={'col'}
                          name={'customerName'}
                          label={t('customerName')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center my-4'>
                    <Button
                      size={'medium'}
                      text={t('goBack')}
                      onClick={() => setStep(1)}
                      disabled={refuteMutation.isLoading || refuteMutation.isSuccess}
                      className={'btn-general btn-wellet-secondary mr-2'}
                    />
                    <Button
                      size={'medium'}
                      text={t('confirm')}
                      onClick={() => setStep(2)}
                      disabled={refuteMutation.isLoading || refuteMutation.isSuccess}
                      className={'btn-general btn-wellet-primary'}
                    />
                  </div>
                </Show.When>
              </div>
              <div className='col pl-4'>
                <div className='text-uppercase dark-grey mt-1'>{t('disputeDetail')}</div>
                <div className='dark-grey fs-14 mt-2'>{t('dueDate')}</div>
                <div className='fs-14 text-capitalize'>
                  {paymentInfoQuery?.data?.data?.dispute_Due_by
                    ? formatDateTranslation(
                        new Date(paymentInfoQuery?.data?.data?.dispute_Due_by),
                        language,
                        'extraSmall',
                      ).date
                    : '-'}
                </div>
                <div className='dark-grey fs-14 mt-2'>{t('amount')}</div>
                <div className='fs-14 text-capitalize'>
                  {paymentInfoQuery?.data?.data?.disputeAmount ? (
                    <Money value={paymentInfoQuery?.data?.data?.disputeAmount} />
                  ) : (
                    '-'
                  )}
                </div>
                <div className='dark-grey fs-14 mt-2'>{t('reason')}</div>
                <div className='fs-14'>
                  {paymentInfoQuery?.data?.data?.disputeReason
                    ? getReasons(paymentInfoQuery?.data?.data?.disputeReason, translateRefute)
                    : '-'}
                </div>
                <div className='dark-grey fs-14 mt-2'>{t('method')}</div>
                <div className='fs-14 text-capitalize'>
                  {paymentInfoQuery?.data?.data?.brand ? (
                    <img
                      style={{ height: '25px' }}
                      src={
                        'https://cdn1.wellet.fun/images/credit-card/' +
                        paymentInfoQuery?.data?.data?.brand?.toLocaleLowerCase() +
                        '-logo.png'
                      }
                      className='mr-1'
                      alt='card'
                    />
                  ) : null}
                  {paymentInfoQuery?.data?.data?.last4
                    ? ` •••• ${paymentInfoQuery?.data?.data?.last4}`
                    : '-'}
                </div>
                <div className='dark-grey fs-14 mt-2'>{t('disputeId')}</div>
                <div className='fs-14'>
                  {paymentInfoQuery?.data?.data?.disputeId
                    ? paymentInfoQuery?.data?.data?.disputeId
                    : '-'}
                </div>
              </div>
            </div>
          </form>
          <Show.When isTrue={refuteMutation.isError}>
            <Toast
              message={t('toastError')}
              type='ERROR'
              onCallback={() => refuteMutation.reset()}
            />
          </Show.When>
          <Show.When isTrue={refuteMutation.isSuccess}>
            <Toast message={t(`toastSuccess`)} type='SUCCESS' onCallback={handleSuccessRefute} />
          </Show.When>
        </FormProvider>
      </Show.Else>
    </Show>
  );
};

export default Refute;
