import React, { useContext, useState } from 'react';
import { PageHeader, Table, FilterCard, TableCell, Show } from '../../components';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  AffiliateCard,
  Button,
  Loading,
  Money,
  Online,
  OrganizationCard,
  PageError
} from '../../uiComponents/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getCheckinHistory } from '../../services/reservationServices';
import { format } from 'date-fns';
import { useCreateBreadCrumbs, usePagination } from '../../hooks';
import useDebounce from '../../hooks/useDebounce';
import { getLocale } from '../../helpers/dates';
import { getQueryParam, setQueryParams } from '../../helpers/query';
import ModalChecker from './modalChecker';
import { reservationBadge } from '../../helpers/status';
import { useTranslation } from 'react-i18next';
import { getReservationType } from '../../helpers/format';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { getOccasionText } from '../../utils/getOcassionIcon';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Checker = () => {
  const organization = useSelector((state) => state.app.currentOrganization);
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.checkins' });
  const { t: translate } = useTranslation();
  const locale = getLocale(language);
  const location = useLocation();
  const navigate = useNavigate();
  const filterByUrl = getQueryParam('filterBy', location);
  const [filter, setFilter] = useState(filterByUrl || 'CONFIRMED');
  const [value, setValue] = useState('');
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const debouncedSearch = useDebounce(value, 700);
  const [open, setOpen] = useState(null);
  const { getClientTranslation } = useGenericTranslation();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // Main API Query
  const reservationsQuery = useQuery({
    queryKey: ['checkin-history', organization.id, filter, limit, skip, debouncedSearch],
    queryFn: () =>
      getCheckinHistory(
        welletContext,
        limit,
        skip,
        organization.marketId,
        filter,
        debouncedSearch,
        organization && !organization.isMarket ? organization.id : null,
      ),
    ...defaultQueryOptions,
    onSuccess: (data) => setTotalAndReset(data?.data.reservations?.totalRegisters),
    keepPreviousData: true,
    refetchInterval: 10000,
    enabled: filter !== null,
  });
  const headerAdmin = [
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.schedule'), value: '', filterable: false },
    { title: t('table.client'), value: '', filterable: false },
    { title: t('table.pax'), value: '', filterable: false },
    { title: t('table.code'), value: '', filterable: false },
    { title: t('table.deposit'), value: '', filterable: false },
    { title: t('table.concierge'), value: '', filterable: false },
    { title: t('table.type'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs([{ name: t('breadcrumbs') }]);

  const onFilterClick = (filter) => {
    setFilter(filter);
    handlePageChange(1);
    setQueryParams({ filterBy: filter, page: 1 }, navigate, location);
  };

  return (
    <>
      <PageHeader title={t('title')} />

      <Show>
        <Show.When isTrue={reservationsQuery.isLoading}>
          <Loading />
        </Show.When>

        <Show.When isTrue={reservationsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row px-1 mt-3 mb-2'>
            <FilterCard
              category={t('cardFilter.pendings')}
              quantity={reservationsQuery?.data?.data?.confirmedCount}
              onClick={() => onFilterClick('CONFIRMED')}
              isSelected={filter === 'CONFIRMED'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.total')}
              quantity={reservationsQuery?.data?.data?.totalCount}
              onClick={() => onFilterClick('')}
              className='reservations col'
              isSelected={filter === ''}
            />
            <FilterCard
              category={t('cardFilter.checkin')}
              quantity={reservationsQuery?.data?.data?.checkInCount}
              onClick={() => onFilterClick('CHECKIN')}
              className='reservations col'
              isSelected={filter === 'CHECKIN'}
            />
            <FilterCard
              category={t('cardFilter.noshow')}
              quantity={reservationsQuery?.data?.data?.noShowCount}
              onClick={() => onFilterClick('NO_SHOW_MANUAL')}
              className='reservations col'
              isSelected={filter === 'NO_SHOW_MANUAL'}
            />
            <FilterCard
              category={t('cardFilter.consumption')}
              quantity={reservationsQuery?.data?.data?.payingCount}
              onClick={() => onFilterClick('PAYING')}
              className='reservations col'
              isSelected={filter === 'PAYING'}
            />
            <FilterCard
              category={t('cardFilter.finaly')}
              quantity={reservationsQuery?.data?.data?.closedCount}
              onClick={() => onFilterClick('CLOSED')}
              className='reservations col'
              isSelected={filter === 'CLOSED'}
            />
          </div>
          <Show>
            <Show.When isTrue={reservationsQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <div className='mb-1'>
                <Online status={'green'} text={t('livefeed')} />
              </div>
              <Table
                data={reservationsQuery?.data?.data?.reservations?.items ?? []}
                hasPagination={false}
                totalP={totalItems}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                columns={headerAdmin}
                renderRow={(reservations, index) => (
                  <>
                    <TableCell>
                      <OrganizationCard
                        width='auto'
                        imageUrl={reservations.restaurant.logo}
                        name={reservations.restaurant.name}
                        date={reservations.restaurant.cityName}
                        hightLightName
                        size={40}
                      />
                    </TableCell>
                    <TableCell
                      className={'fw-700'}
                      style={{
                        fontSize: '20px',
                        color: `${reservations.canChangeStatus ? '#D21900' : '#101828'}`,
                      }}
                    >
                      {format(new Date(reservations.reservationDateLocalTime), 'HH:mm', { locale })}
                    </TableCell>
                    <TableCell>
                      <div className='fw-700 wrap-text text-capitalize'>
                        {reservations.customerName}
                      </div>
                      <div className='dark-grey text-capitalize wrap-text'>
                        {getOccasionText(reservations.occasion, translate)}
                      </div>
                    </TableCell>
                    <TableCell className={'fw-700'}>
                      <span className='pl-1'>{reservations.pax}</span>
                    </TableCell>
                    <TableCell className={'fw-700'}>{reservations.referenceCode}</TableCell>
                    <TableCell className={'text-left'}>
                      <div className='d-flex flex-column'>
                        <div className='fw-700'>
                          {reservations.depositAmount ? (
                            <Money value={reservations.depositAmount} />
                          ) : (
                            '-'
                          )}
                        </div>
                        {reservations.consumptionAmount && (
                          <div>
                            {t('credits')} <Money value={reservations.consumptionAmount} />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <AffiliateCard
                        company={reservations?.sellerCompany}
                        url={reservations?.profilePicUrl}
                        name={reservations?.sellerFullName}
                        isVerified={reservations?.sellerIsVerified}
                        size={'40px'}
                      />
                    </TableCell>
                    <TableCell>
                      <div>{getReservationType(reservations?.reservationType, translate)}</div>
                    </TableCell>
                    <TableCell>
                      {reservations.canChangeStatus ? (
                        <Button
                          className={'btn-wellet-secondary fw-600 bg-custom-light p-0 px-2 m-0'}
                          text={t('btnConfirm')}
                          size={'custom'}
                          heightConfig={'36px'}
                          fontConfig={'14px'}
                          onClick={() =>
                            reservations.canChangeStatus ? setOpen(reservations) : null
                          }
                        />
                      ) : (
                        reservationBadge(reservations.status, translate)
                      )}
                    </TableCell>
                  </>
                )}
                rowClass='not-hover'
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>

      <ModalChecker
        open={open !== null}
        onClose={() => setOpen(null)}
        reservation={open}
        onSuccess={reservationsQuery.refetch}
      />
    </>
  );
};

export default Checker;
