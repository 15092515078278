import { useCallback, useContext, useState } from 'react';
import SidebarContext from '../../context/sidebarContext';
import styles from './selector.module.css';
import { Dropdown, Loading } from '../../uiComponents/common';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentOrg, setSelectedOrg } from '../../actions/appActions';
import ItemDropdown from '../itemDropdown/itemDropdown';
import useUser from '../../hooks/useUser';
import { hasAllowedRoles } from '../../helpers/roles';
import { ChevronIcon } from '../../uiComponents/icons';
import { useTranslation } from 'react-i18next';

const MarketSelector = ({ isSelected = false, data }) => {
  const { isMouseOver, isSidebarResized } = useContext(SidebarContext);
  const { t } = useTranslation('translation', { keyPrefix: 'components.marketSelector' });
  const dispacth = useDispatch();
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(null);
  const { user, roles } = useUser();

  const organizationsFormatted =
    data.isSuccess &&
    data?.data?.markets?.map((item) => ({
      id: item.organizationId,
      name: item.marketName,
      isMarket: item.isMarket,
      currency: item.currency,
      currencySymbol: item.currencySymbol,
    }));

  const handleSubmenuToggle = useCallback((index) => {
    if (index === undefined) return;
    setActiveSubmenuIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);
  const selectMarket = (market) => {
    const reduxOrgData = {
      ...selectedOrganization,
      name: null,
      id: market.id,
      isMarket: market.isMarket,
      marketId: market.id,
      marketName: market.name,
      currency: market.currency,
      currencySymbol: market.currencySymbol,
    };

    dispacth(setSelectedOrg(reduxOrgData));
    dispacth(setCurrentOrg(reduxOrgData));
  };

  const isAllowed = hasAllowedRoles(roles, [
    'superadministrator',
    'auditor',
    'auditor-onlyview',
    'finance',
    'marketadmin',
    'dashboard',
    'reservationcenter',
    'reservationcenter-onlyview',
    'bosssales',
    'callcenterlead',
    'salesteamlead',
    'checker',
    'productloader',
    'productpriceloader',
    'wellet_finance',
    //'soldout',
  ]);

  // UI Component
  const selectorDropdown = (
    <div
      className={`${styles.selector} nav-item  justify-content-${
        isMouseOver || !isSidebarResized ? 'between' : 'center'
      } `}
    >
      <div className='w-100 d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center company-selector-dropdown'>
          {/* <div className={`dot dot-green mr-1`}></div> */}
          {selectedOrganization.marketName ? selectedOrganization.marketName : t('text')}
        </div>
        <div className={styles.companyIcon}>
          {isAllowed ? <ChevronIcon rotation={90} color='#667085' /> : null}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`${isSelected ? styles.menuSelected : ''} fw-500 justify-content-${
        isMouseOver || !isSidebarResized ? 'between' : 'center'
      } `}
    >
      {user.organization.isMarket && isAllowed ? (
        <Dropdown
          id={'marketSelector'}
          btn={selectorDropdown}
          isHover={false}
          customDropdwon={''}
          isMouseOver={isSidebarResized ? isMouseOver : undefined}
          callBack={handleSubmenuToggle}
          renderList={() => (
            <>
              {data.isFetching ? (
                <Loading global={false} />
              ) : (
                <>
                  {data.isSuccess &&
                    organizationsFormatted.map((org, idx) => (
                      <ItemDropdown
                        item={org}
                        key={idx}
                        show={idx === activeSubmenuIndex}
                        setActive={handleSubmenuToggle}
                        idx={idx}
                        selectOrganization={selectMarket}
                      />
                    ))}
                </>
              )}
            </>
          )}
        />
      ) : (
        selectorDropdown
      )}
    </div>
  );
};

export default MarketSelector;
