import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Toast } from '../../../uiComponents/common';
import { InputNumber, InputText } from '../../../uiComponents/common/inputs';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../components';
import { useSelector } from 'react-redux';
import { PlusIcon } from '../../../uiComponents/icons';
import { postMembershipLevel, putMembershipLevel } from '../../../services';

const ModalLevels = ({ callback, level, setLevel }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalsLevels' });
  const organization = useSelector((state) => state.app.selectedOrganization);

  const [open, setOpen] = useState('');

  const methods = useForm({
    defaultValues: {
      id: level?.id ? level?.id : '',
      name: level?.name ? level?.name : '',
      points: level?.targetPoints,
      defaultCommissionPercentage: level?.defaultCommissionPercentage
        ? level?.defaultCommissionPercentage
        : 0,
    },
  });
  const welletContext = useContext(WelletContext);
  const confirmMutation = useMutation({
    mutationFn: (body) => {
      if (level?.id) {
        return putMembershipLevel(welletContext, organization.marketId, {
          id: level?.id,
          name: body.name,
          targetPoints: body.points,
          defaultCommissionPercentage: body.defaultCommissionPercentage || 0,
          defaultCommissionPerPax: 0,
          maxPercentDiscount: 0,
        });
      }
      return postMembershipLevel(welletContext, organization.marketId, {
        name: body.name,
        targetPoints: body.points,
        defaultCommissionPercentage: body.defaultCommissionPercentage || 0,
        defaultCommissionPerPax: 0,
        maxPercentDiscount: 0,
      });
    },
    onSuccess: () => {
      callback();
    },
  });

  // Functions
  const handleClose = () => {
    if (level?.id) {
      setLevel({});
    }
    setOpen(false);
    methods.reset({
      name: null,
      points: null,
    });
    confirmMutation.reset();
  };
  const handleModal = () => {
    setLevel({});
    setOpen((prevState) => !prevState);
  };
  const onSubmit = (data) => {
    let body = {
      name: data?.name,
      points: data?.points,
      defaultCommissionPercentage: data?.defaultCommissionPercentage,
    };
    return confirmMutation.mutate(body);
  };

  useEffect(() => {
    if (level?.id) {
      methods.reset({
        id: level?.id ? level?.id : '',
        name: level?.name ? level?.name : '',
        points: level?.targetPoints,
        defaultCommissionPercentage: level?.defaultCommissionPercentage,
      });
    }
  }, [level]);

  return (
    <>
      <div
        className='dark-grey fw-600 cursor-pointer mt-3 d-flex align-items-center'
        onClick={handleModal}
      >
        <PlusIcon size={'12px'} />
        <span className='ml-1'>{t('button')}</span>
      </div>

      <FormProvider {...methods}>
        <Modal
          title={t('title')}
          open={open || !!level?.id}
          onClose={handleClose}
          closeIcon={false}
          maxWidth='550px'
          closeOnDocumentClick={false}
          customButtons={
            <>
              <div className='d-flex justify-content-end'>
                <Button
                  text={t('cancel')}
                  disabled={confirmMutation.isLoading || confirmMutation.isSuccess}
                  className={'btn-general btn-wellet-secondary white'}
                  size={'medium'}
                  onClick={handleClose}
                />
                <Button
                  text={t('confirm')}
                  disabled={confirmMutation.isLoading || confirmMutation.isSuccess}
                  className={'ml-2 btn-general btn-wellet-primary'}
                  size={'medium'}
                  onClick={() => methods.handleSubmit(onSubmit)()}
                />
              </div>
            </>
          }
        >
          <div className='row mt-3'>
            <div className='col-6'>
              <InputText
                className={''}
                name={'name'}
                label={t('inputName.label')}
                isRequired={true}
                placeholder={t('inputName.placeholder')}
              />
            </div>

            <div className='col-6'>
              <InputNumber
                name={'points'}
                placeholder={'0'}
                label={t('inputPoints.label')}
                minNumber={0}
                className=''
                showText={false}
                isRequired={true}
              />
            </div>

            <div className='col-6'>
              <InputNumber
                name={'defaultCommissionPercentage'}
                placeholder={'0'}
                label={t('inputCommissions.label')}
                minNumber={0}
                maxNumber={99}
                className=''
                showText={false}
                isRequired={true}
              />
            </div>
          </div>
        </Modal>
      </FormProvider>
      <Show.When isTrue={confirmMutation.isError}>
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
      <Show.When isTrue={confirmMutation.isSuccess}>
        <Toast
          message={level?.id ? t('toast.updated') : t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default ModalLevels;
