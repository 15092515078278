import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../button/button';
import { TrashIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import Arrow from '../../icons/arrowDownNew';

const InputMultipleFiles = ({
  id,
  name,
  label,
  className = '',
  maxSize = 5000000,
  extension = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf', 'mp4'],
  isRequired = false,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const fileInputRef = useRef(null);
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileInputChange = async (e) => {
    const newFiles = Array.from(e.target.files);
    const currentFiles = getValues(name) || [];

    // Convert files to base64 and map them to the desired structure
    const validFiles = newFiles.filter((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return extension.includes(fileExtension);
    });

    if (validFiles.length !== newFiles.length) {
      const extensionsList = extension.join(', ');
      setError(name, {
        type: 'manual',
        message: t('invalidFileType', { extensions: extensionsList }),
      });
      return;
    }

    const totalSize =
      validFiles.reduce((acc, file) => acc + file.size, 0) +
      currentFiles.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > maxSize) {
      setError(name, {
        type: 'manual',
        message: t('totalMaxSize'),
      });
      return;
    }

    const allFiles = await Promise.all(
      validFiles.map(async (file) => {
        const base64 = await getBase64(file);
        return {
          fileName: file.name,
          documentUrl: base64,
          size: file.size,
        };
      }),
    );

    clearErrors(name);
    setValue(name, [...currentFiles, ...allFiles]);
  };

  const handleRemoveFile = (index) => {
    const currentFiles = getValues(name) || [];
    const updatedFiles = currentFiles.filter((_, i) => i !== index);
    setValue(name, updatedFiles);
  };

  const validationRules = {};
  if (isRequired) {
    validationRules.required = t('required');
  }

  return (
    <div className={`input-file-container ${className}`}>
      <label style={{ color: 'var(--color-text)' }} className='mb-1'>
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={validationRules}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <div
                className='multiple-file mb-2'
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  const droppedFiles = e.dataTransfer.files;
                  handleFileInputChange({ target: { files: droppedFiles } });
                }}
              >
                <input
                  type='file'
                  id={id}
                  name={name}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileInputChange}
                  multiple
                />

                <Button
                  type='button'
                  size={'small'}
                  className={'btn-wellet-secondary m-2'}
                  text={
                    <div className='d-flex align-items-center'>
                      <Arrow size='12px' rotate={'180deg'} />{' '}
                      <span className='ml-1'>{t('inputMultipleButton')}</span>
                    </div>
                  }
                  onClick={handleFileInputClick}
                />
                <div>{t('inputMultipleDrag')}</div>
              </div>
              {value && value.length > 0 && (
                <>
                  {Array.from(value).map((file, index) => (
                    <div key={index} className='fs-14 pl-2 d-flex align-items-center'>
                      {file.fileName}
                      <span
                        className='ml-1 cursor-pointer fw-500'
                        onClick={() => handleRemoveFile(index)}
                      >
                        <TrashIcon size='12px' />
                      </span>
                    </div>
                  ))}
                </>
              )}

              {errors[name] && (
                <div className='d-flex align-items-center error-content'>
                  <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                  <p className='text-danger p-0 m-0'>{errors[name].message}</p>
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
};
export default InputMultipleFiles;
