import React, { useContext } from 'react';
import { Modal, Toast } from '../../../../uiComponents/common';
import { WelletContext } from '../../../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Show } from '../../../../components';
import { lostDisputePayment } from '../../../../services';

const ModalLostDispute = ({ callback, open, setOpen, paymentId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalLostDispute' });

  const welletContext = useContext(WelletContext);

  const confirmMutation = useMutation({
    mutationFn: (data) => {
      return lostDisputePayment(welletContext, paymentId);
    },
    onSuccess: () => {
      callback();
    },
  });

  // Functions
  const handleClose = () => {
    setOpen(false);
    confirmMutation.reset();
  };

  const onSubmit = () => {
    return confirmMutation.mutate();
  };

  return (
    <>
      <Modal
        title={t('title')}
        open={open}
        onClose={handleClose}
        closeIcon={true}
        maxWidth='450px'
        closeOnDocumentClick={false}
        handleSubmit={onSubmit}
        isLoading={confirmMutation.isLoading || confirmMutation.isSuccess}
        textCancel={t('cancel')}
        textSubmit={t('confirm')}
      >
        <div className='py-3'>{t('body')}</div>
      </Modal>

      <Show.When isTrue={confirmMutation.isError}>
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
      <Show.When isTrue={confirmMutation.isSuccess}>
        <Toast
          message={t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      </Show.When>
    </>
  );
};

export default ModalLostDispute;
