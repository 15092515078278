import React, { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/wellet/welletContext';
import {
  getAllRoles,
  getAllSalesTeamLead,
  getPaymentsMethods,
  getProfilePic,
  getQrLinkget,
  postUser,
} from '../../../services';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';

import { useGetCities, useGetCountries, useGetStates, useUser } from '../../../hooks';

import {
  Button,
  FormButton,
  FormHeader,
  Loading,
  PageError,
  Select,
  Toast,
} from '../../../uiComponents/common';
import {
  InputCheckbox,
  InputDate,
  InputDescription,
  InputEmail,
  InputLogo,
  InputNumber,
  InputPassword,
  InputPhone,
  InputText,
} from '../../../uiComponents/common/inputs';
import { Show } from '../../../components';
import { hasAllowedRoles } from '../../../helpers/roles';
import { useNavigate } from 'react-router-dom';
import { getUserById, putUser } from '../../../services';
import { getDocumentTypeIdAnalizer } from '../../../helpers/concierge';
import { requestStatus } from '../../../helpers/status';
import { removeTimezoneOffset } from '../../../helpers/dates';
import SelectConfig from './selectedConfig/selectedConfig';
import FormRow from '../../../components/forms/formRow';
import { formatPaymentMethod } from '../../../helpers/format';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import Banner from '../../../components/banners/banner';
import { CheckFile } from '../../../uiComponents/icons';
import { useDownloadPdfMutationNew } from '../../../hooks/mutations/useDownloadPdfMutationNew';

const Profile = ({ id = null, mode = '', setkycStatus }) => {
  const organization = useSelector((state) => state.app.selectedOrganization);
  const qrCodeSelector = useSelector((state) => state.app.qrCode);
  const tenantId = useSelector((state) => state.app.tenantId);
  const language = useSelector((state) => state.app.language);
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.profile',
  });
  const { t: translate } = useTranslation('translation', { keyPrefix: 'forms.userForm' });
  const { t: translatePayment } = useTranslation();
  const welletContext = useContext(WelletContext);
  const methods = useForm();
  const { roles } = useUser();
  const navigate = useNavigate();
  const [picUrl, setPicUrl] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const userQuery = useQuery({
    queryKey: ['user', id, organization.id],
    queryFn: async () => {
      const data = await getUserById(welletContext, id);
      methods.setValue('advantageNetworkStatus', data?.data?.advantageNetworkStatus);
      resetData(data?.data);
      setPicUrl(data?.data?.profilePic);
      return data;
    },
    onSuccess: (data) => {
      if (
        (data?.data?.organizationId !== organization.id && !organization.isMarket) ||
        (data?.data?.organization?.isMarket &&
          data?.data?.organization?.id !== organization.marketId)
      ) {
        if (mode === 'CONCIERGE') {
          navigate('/sellers');
        } else if (mode === 'USER') {
          navigate('/settings/team-and-security');
        }
      }
      setkycStatus(data?.data?.kycStatus);
    },
    enabled: !!id,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const isLaptop = window.innerWidth < 1430;
  const qrData = userQuery?.data?.data?.link;
  const qrUrl = `api/qr?format=1&logo=${qrCodeSelector}&url=${qrData}`;

  const userQrLinkGet = useQuery({
    queryKey: ['boxQrGet', qrUrl],
    queryFn: () => getQrLinkget(welletContext, qrUrl),
    ...defaultQueryOptions,
    enabled: mode === 'CONCIERGE',
  });

  const imageUrl = userQrLinkGet.isSuccess
    ? URL.createObjectURL(new Blob([userQrLinkGet?.data?.data], { type: 'image/jpeg' }))
    : null;

  const downloadPdfMutation = useDownloadPdfMutationNew(); // Instancia la mutación

  const printPDF = () => {
    downloadPdfMutation.mutate(id);
  };

  const rolesQuery = useQuery({
    queryKey: ['roles-dragaret'],
    queryFn: () => getAllRoles(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !roles.some((r) => r === 'affiliatecenter-onlyview') && mode !== 'PROFILE',
  });
  const rolesFormat = rolesQuery?.data?.data.map((r) => ({ label: r.prettyName, value: r.name }));

  const paymentsQuery = useQuery({
    queryKey: ['payments-methods'],
    queryFn: () => getPaymentsMethods(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: mode !== 'PROFILE',
  });
  const profilePic = useQuery({
    queryKey: ['profile-pic-ambassadors', picUrl, id],
    queryFn: () => getProfilePic(welletContext, picUrl?.slice(picUrl.indexOf('/users'))),
    onSuccess: async (data) => {
      // Crear un nuevo Blob a partir de los datos recibidos, especificando el tipo MIME como 'image/jpeg'
      const blob = new Blob([data.data], { type: 'image/jpeg' });

      // Función para convertir un Blob a una cadena base64
      const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader(); // Crear un nuevo objeto FileReader
          reader.readAsDataURL(blob); // Leer el Blob como una URL de datos (base64)
          reader.onloadend = () => {
            resolve(reader.result); // Resolver la promesa con el resultado en base64 cuando la lectura termine
          };
          reader.onerror = (error) => {
            reject(error); // Rechazar la promesa si ocurre un error durante la lectura
          };
        });
      };

      const base64 = await convertBlobToBase64(blob);

      const picProfile = {
        base64: base64,
        name: base64.split('/')[3] ?? '',
      };

      methods.setValue('profilePic', picProfile);
    },
    ...defaultQueryOptions,
    enabled: picUrl !== null,
  });
  const countriesQuery = useGetCountries(language);
  const statesQuery = useGetStates(methods?.watch('country')?.value, {
    enabled: !!methods?.watch('country')?.value,
  });
  const citiesQuery = useGetCities(
    methods?.watch('state')?.value,
    {
      enabled: !!methods?.watch('state')?.value,
    },
    true,
  );

  const bossQuery = useQuery({
    queryKey: ['sales-team-leader', organization.marketId],
    queryFn: () => getAllSalesTeamLead(welletContext, organization.marketId),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !roles.some((r) => r === 'affiliatecenter-onlyview') && mode !== 'PROFILE',
  });

  // Mutations
  const userMutation = useMutation({
    mutationFn: (body) => {
      if (id === false) return postUser(welletContext, body);
      return putUser(welletContext, body);
    },
    onSuccess: () => {
      setShowToast(true);
    },
    onError: () => {
      setShowToast(true);
    },
  });

  // Data Formatted
  const countries = countriesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const states = statesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const cities = citiesQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));
  const documentTypes = getDocumentTypeIdAnalizer(t);
  const advantageNetworkStatus = methods.watch('advantageNetworkStatus');
  const queryClient = useQueryClient();

  // Functions
  const resetData = (data) => {
    const body = {
      sid: data?.sid,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      sector: data?.sector, // Work Data
      jobTitle: data?.jobTitle, // Work Data
      company: data?.company, // Work Data
      companyId: data.organizationId,
      dateOfBirth: removeTimezoneOffset(data?.dateOfBirth, true),
      nationality: data?.nationality,
      documentType: documentTypes.find((item) => item.value === data?.documentType),
      documentNumber: data?.documentNumber,
      address: data?.address,
      country: { label: data?.country?.name, value: data?.country?.id },
      state: data?.city ? { label: data?.city?.state?.name, value: data?.city?.state?.id } : null,
      city: data?.city ? { label: data?.city?.name, value: data?.city?.id } : null,
      isActive: data?.isActive,
      advantageNetworkStatus: data?.advantageNetworkStatus,
      language: data?.language,
      gender: gender?.find((g) => g.value === data?.gender), // select
      internRef: data.internRef,
      link: data?.data?.link,
      withdrawalMethod: data?.withdrawalMethod
        ? {
            label: formatPaymentMethod(data?.withdrawalMethod, translatePayment),
            value: data?.withdrawalMethod,
          }
        : null,
      roles: data.roles.map((role, index) => ({
        label: data.prettyRoles[index],
        value: role,
      })),
      reportsToUserId: data.reportToUserId
        ? { label: data.reportToUserName, value: data.reportToUserId }
        : { label: t('withoutRp'), value: null },
      commissionPlusPercentage: data.commissionPlusPercentage,
      isCommissionDisabled: data.isCommissionDisabled,
      hasFamiliar: data?.hasFamilyWorking
        ? { label: translate('selectHasFamiliar.yes'), value: false }
        : { label: translate('selectHasFamiliar.no'), value: true },
      hasWorked: data?.hasWorkedPreviously
        ? { label: translate('selectHasWorked.yes'), value: false }
        : { label: translate('selectHasWorked.no'), value: true },
      corporateEmail: data?.corporateEmail,
      webCompany: data?.webCompany,
      socialNetworkCompany: data?.socialNetworkCompany,
      officeAddressCompany: data?.officeAddressCompany,
      notes: data?.notes,
    };
    methods.reset(body);
  };
  const onSubmit = (data) => {
    const body = {
      id: id === false ? null : data?.sid,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.phone,
      email: data?.email,
      profilePic: data?.profilePic?.base64,
      sector: data?.sector,
      jobTitle: data?.jobTitle,
      company: data?.company,
      nationality: data?.nationality,
      isActive: !id ? true : data?.isActive ? data?.isActive : false,
      documentNumber: data?.documentNumber,
      dateOfBirth: data?.dateOfBirth,
      documentType: data?.documentType?.value,
      countryId: data?.country?.value, // To Do: armar input
      cityId: data?.city?.value, // To Do: armar input
      address: data?.address,
      language: data?.language ?? 'es',
      advantageNetworkStatus: data?.advantageNetworkStatus,
      gender: data.gender?.value ?? null,
      internRef: data.internRef,
      WithdrawalMethodType: data?.withdrawalMethod?.value,
      roles: data.roles?.map((r) => r.value),
      reportsToUserId: data.reportsToUserId?.value || null,
      commissionPlusPercentage: Number(data.commissionPlusPercentage) ?? null,
      isCommissionDisabled: data.isCommissionDisabled ? data.isCommissionDisabled : false,
      companyId: data.companyId ? data.companyId : organization.id,
      corporateEmail: data?.corporateEmail,
      webCompany: data?.webCompany,
      socialNetworkCompany: data?.socialNetworkCompany,
      officeAddressCompany: data?.officeAddressCompany,
      notes: data?.notes,
    };
    if (!id) {
      body['password'] = data.password;
    }
    userMutation.mutate(body);
  };

  const refetchStatus = () => {
    userQuery.refetch().then(() => profilePic.refetch());
    queryClient.refetchQueries({ queryKey: ['count-sidebar', organization.marketId] });
  };

  const title =
    mode === 'CONCIERGE' ? t('title') : mode === 'USER' ? t('userTitle') : t('generalTitle');
  const text =
    mode === 'CONCIERGE' ? t('text') : mode === 'USER' ? t('generalUser') : t('generalText');

  const toastSuccess =
    mode === 'CONCIERGE'
      ? t('toast.success.concierge')
      : mode === 'PROFILE'
      ? t('toast.success.profile')
      : !id
      ? t('toast.success.usersCreate')
      : t('toast.success.users');

  const gender = [
    { label: translate('selects.gender.male'), value: 'M' },
    { label: translate('selects.gender.female'), value: 'F' },
  ];

  const goBackToSetting = () => {
    if (mode === 'CONCIERGE') {
      navigate('/sellers');
    }
    if (mode === 'PROFILE') {
      navigate('/');
    }
    if (mode === 'USER') {
      navigate('/settings/team-and-security');
    }
  };

  const showInfo = userQuery?.data?.data?.roles?.includes('associate', 'salesteamlead');
  const boss = bossQuery?.data?.data || [];
  const bossOptions = [
    { label: t('withoutRp'), value: null },
    ...boss.map((r) => ({
      label: `${r.firstName} ${r.lastName}`,
      value: r.id,
    })),
  ];

  return (
    <>
      <div className='mt-3'>
        <Show>
          <Show.When isTrue={userQuery.isLoading || userQuery.isFetching}>
            <Loading />
          </Show.When>
          <Show.When isTrue={userQuery.isError}>
            <PageError />
          </Show.When>

          <Show.Else>
            <FormProvider {...methods}>
              <Show.When isTrue={advantageNetworkStatus === 'PENDING' && mode === 'CONCIERGE'}>
                <Banner
                  id={id}
                  status={advantageNetworkStatus}
                  title={translate('banner.title')}
                  text={translate('banner.description', { name: organization.marketName })}
                  icon={<CheckFile size={20} />}
                  endComponent={
                    hasAllowedRoles(roles, [
                      'superadministrator',
                      'marketadmin',
                      'affiliatecenter',
                    ]) ? (
                      <div
                        className='d-flex align-items-center justify-content-center'
                        style={{ gap: '10px' }}
                      >
                        <SelectConfig
                          status={advantageNetworkStatus}
                          id={id}
                          usecase='request'
                          refetchStatus={refetchStatus}
                        />
                      </div>
                    ) : null
                  }
                />
              </Show.When>
              <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
                <FormHeader
                  title={title}
                  subtitle={text}
                  endComponent={
                    hasAllowedRoles(roles, [
                      'superadministrator',
                      'marketadmin',
                      'affiliatecenter',
                    ]) || mode !== 'CONCIERGE' ? (
                      <Show.When
                        isTrue={
                          advantageNetworkStatus === 'APPROVED' ||
                          hasAllowedRoles(roles, ['superadministrator'])
                        }
                      >
                        <FormButton
                          handleRedirect={() => {}}
                          clearForm={goBackToSetting}
                          all={false}
                          nextStep={true}
                          isDisabled={userMutation.isLoading || userMutation.isFetching}
                        />
                      </Show.When>
                    ) : null
                  }
                />

                <FormRow
                  text={t('rowPersonalInfo.title')}
                  description={''}
                  endComponent={
                    <div className='col-xl-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <InputText
                            name={'firstName'}
                            label={t('rowPersonalInfo.inputFirstName.label')}
                            placeholder={''}
                            isRequired={true}
                          />
                        </div>
                        <div className='col-5'>
                          <InputText
                            name={'lastName'}
                            label={t('rowPersonalInfo.inputLastName.label')}
                            placeholder={''}
                            isRequired={true}
                          />
                        </div>
                        <div className='col-10'>
                          <InputEmail
                            name={'email'}
                            label={t('rowPersonalInfo.inputEmail.label')}
                            placeholder={''}
                            isRequired={true}
                            isDisabled={!!id}
                          />
                        </div>
                        <div className='col-10'>
                          <InputPhone
                            name={'phone'}
                            label={t('rowPersonalInfo.inputPhone.label')}
                            placeholder={''}
                            isRequired={false}
                            isDisabled={userQuery?.data?.data?.phone}
                          />
                        </div>
                        {!id && (
                          <InputPassword
                            className={'col-5'}
                            name={'password'}
                            label={translate('inputPass.label')}
                            placeholder={''}
                            autocomplete={'new-password'}
                          />
                        )}
                      </div>
                    </div>
                  }
                />

                <FormRow
                  text={t('rowLaboralData.title')}
                  description={''}
                  endComponent={
                    <div className='col-xl-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <InputText
                            name={'sector'}
                            label={t('rowLaboralData.inputCategory.label')}
                            placeholder={t('rowLaboralData.inputCategory.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        <div className='col-5'>
                          <InputText
                            name={'company'}
                            label={t('rowLaboralData.inputCompany.label')}
                            placeholder={t('rowLaboralData.inputCompany.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        {process.env.REACT_APP_TENANT_GRN === tenantId && mode === 'CONCIERGE' ? (
                          <>
                            {' '}
                            <InputText
                              className={'col-5'}
                              name={'socialNetworkCompany'}
                              label={translate('rowSocialNetworkCompany.label')}
                              placeholder={translate('rowSocialNetworkCompany.placeholder')}
                              maxLength={510}
                            />
                            <InputText
                              className={'col-5'}
                              name={'webCompany'}
                              label={translate('rowWebCompany.label')}
                              placeholder={translate('rowWebCompany.placeholder')}
                              maxLength={510}
                            />
                            <InputText
                              className={'col-10'}
                              name={'officeAddressCompany'}
                              label={translate('rowOfficeAddressCompany.label')}
                              placeholder={translate('rowOfficeAddressCompany.placeholder')}
                              maxLength={510}
                            />
                            <InputEmail
                              name={'corporateEmail'}
                              label={translate('rowCorporateEmail.label')}
                              placeholder={''}
                              className={'col-10'}
                              isRequired={false}
                            />
                          </>
                        ) : null}
                        <div className='col-5'>
                          <InputText
                            name={'jobTitle'}
                            label={t('rowLaboralData.inputJob.label')}
                            placeholder={t('rowLaboralData.inputJob.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        <Select
                          options={bossOptions}
                          label={translate('inputReport.label')}
                          placeholder={translate('inputReport.placeholder')}
                          className={'col-5'}
                          name={'reportsToUserId'}
                          closeMenuOnSelect={true}
                          blurInputOnSelect={false}
                          hideSelectedOptions={false}
                          isDisabled={false}
                          //isDisabled={methods.watch('reportsToUserId')?.value !== null}
                        />
                        <InputText
                          className={'col-10'}
                          minLength={0}
                          maxLength={50}
                          name={'internRef'}
                          label={translate('inputReff.label')}
                          placeholder={translate('inputReff.placeholder')}
                          isRequired={false}
                        />
                        {process.env.REACT_APP_TENANT_GRN === tenantId && mode === 'CONCIERGE' ? (
                          <>
                            <Select
                              options={[]}
                              label={translate('selectHasWorked.label', {
                                name: organization.marketName,
                              })}
                              placeholder=''
                              className={isLaptop ? 'col-10' : 'col-5'}
                              name={'hasWorked'}
                              isDisabled={true}
                            />

                            <Select
                              options={[]}
                              label={translate('selectHasFamiliar.label', {
                                name: organization.marketName,
                              })}
                              className={isLaptop ? 'col-10' : 'col-5'}
                              name={'hasFamiliar'}
                              placeholder=''
                              isDisabled={true}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('rowIdentification.title')}
                  description={''}
                  endComponent={
                    <div className='col-xl-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <InputDate
                            name={'dateOfBirth'}
                            label={t('rowIdentification.inputDate.label')}
                            placeholder={t('rowIdentification.inputDate.placeholder')}
                          />
                        </div>
                        <div className='col-5'>
                          <InputText
                            name={'nationality'}
                            label={t('rowIdentification.inputNationality.label')}
                            placeholder={t('rowIdentification.inputNationality.placeholder')}
                            maxLength={510}
                          />
                        </div>

                        <div className='col-5'>
                          <Select
                            name={'documentType'}
                            options={documentTypes}
                            label={t('rowIdentification.inputTypeDocument.label')}
                            placeholder={t('rowIdentification.inputTypeDocument.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        <div className='col-5'>
                          <InputNumber
                            name={'documentNumber'}
                            label={t('rowIdentification.inputNumberDocument.label')}
                            placeholder={t('rowIdentification.inputNumberDocument.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        <Select
                          options={gender}
                          label={translate('inputGender.label')}
                          placeholder={translate('inputGender.placeholder')}
                          className={'col-5'}
                          name={'gender'}
                          closeMenuOnSelect={true}
                          blurInputOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('rowAddress.title')}
                  description={''}
                  endComponent={
                    <div className='col-xl-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <Select
                            name={'country'}
                            options={countries}
                            label={t('rowAddress.inputCountry.label')}
                            placeholder={t('rowAddress.inputCountry.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        <div className='col-5'>
                          <Select
                            name={'state'}
                            options={states}
                            label={t('rowAddress.inputState.label')}
                            placeholder={t('rowAddress.inputState.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        <div className='col-3'>
                          <Select
                            name={'city'}
                            options={cities}
                            label={t('rowAddress.inputCity.label')}
                            placeholder={t('rowAddress.inputCity.placeholder')}
                            maxLength={510}
                          />
                        </div>
                        <div className='col-7'>
                          <InputText
                            name={'address'}
                            label={t('rowAddress.inputAddress.label')}
                            placeholder={t('rowAddress.inputAddress.placeholder')}
                            maxLength={510}
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
                <FormRow
                  text={t('rowMultimedia.title')}
                  description={
                    mode === 'CONCIERGE'
                      ? t('rowMultimedia.description')
                      : t('rowMultimedia.descriptionProfile')
                  }
                  endComponent={
                    <div className='col-5'>
                      <InputLogo
                        name={'profilePic'}
                        isRequired={mode === 'CONCIERGE'}
                        maxSize={4096000}
                      />
                    </div>
                  }
                />
                <Show.When isTrue={mode !== 'PROFILE'}>
                  <FormRow
                    text={translate('inputRoles.label')}
                    description={''}
                    endComponent={
                      <div className='col-xl-6'>
                        <Select
                          options={rolesFormat}
                          placeholder={translate('inputRoles.placeholder')}
                          className={'col-10'}
                          name={'roles'}
                          components={{
                            Option: InputOption,
                          }}
                          isMulti={true}
                          closeMenuOnSelect={true}
                          blurInputOnSelect={false}
                          hideSelectedOptions={false}
                          isRequired={true}
                        />
                      </div>
                    }
                  />
                </Show.When>

                {id ? (
                  <FormRow
                    text={mode === 'CONCIERGE' ? t('rowPublic.title') : t('rowPublic.titleProfile')}
                    description={
                      mode === 'CONCIERGE'
                        ? t('rowPublic.description')
                        : t('rowPublic.descriptionProfile')
                    }
                    endComponent={
                      <div className='col-xl-6'>
                        <InputCheckbox label={t('rowPublic.input.label')} name={'isActive'} />
                      </div>
                    }
                  />
                ) : null}
                <Show.When isTrue={mode === 'CONCIERGE' && advantageNetworkStatus !== 'PENDING'}>
                  <FormRow
                    text={`${t('rowRequests.title')} ${organization.marketName}`}
                    description={t('rowRequests.description')}
                    endComponent={
                      <div className='col-xl-6 mb-3'>
                        <div className='row align-items-center'>
                          <div className='col-8 mt-3'>
                            <div className='d-flex align-items-center' style={{ gap: '20px' }}>
                              {requestStatus(
                                advantageNetworkStatus,
                                t(
                                  `rowRequests.badge.${advantageNetworkStatus?.toLowerCase()}.label`,
                                ),
                              )}
                              {hasAllowedRoles(roles, [
                                'superadministrator',
                                'marketadmin',
                                'affiliatecenter',
                              ]) ? (
                                <SelectConfig
                                  status={advantageNetworkStatus}
                                  id={id}
                                  mode='LINK'
                                  usecase='request'
                                  refetchStatus={refetchStatus}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Show.When>
                {showInfo && mode !== 'PROFILE' && (
                  <FormRow
                    text={t('paymentMethod.title')}
                    description={''}
                    endComponent={
                      <div className='col-3'>
                        <Select
                          options={paymentsQuery?.data?.data?.map((p) => ({
                            label: formatPaymentMethod(p, translatePayment),
                            value: p,
                          }))}
                          label={''}
                          name={'withdrawalMethod'}
                          className='col-8'
                          placeholder={t('paymentMethod.placeholder')}
                        />
                      </div>
                    }
                  />
                )}
                {mode === 'CONCIERGE' && (
                  <>
                    <FormRow
                      text={t('rowCommissions.title')}
                      description={''}
                      endComponent={
                        <div className='col-auto'>
                          <InputCheckbox
                            label={translate('inputModernSwithcer.label')}
                            name={'isCommissionDisabled'}
                          />
                        </div>
                      }
                    />
                    <FormRow
                      text={translate('inputComissionsPercentage.label')}
                      description={''}
                      endComponent={
                        <div className='col-2'>
                          <InputNumber
                            name={'commissionPlusPercentage'}
                            className='mb-3'
                            icon={'%'}
                            minNumber={0}
                            isDisabled={methods.watch('isCommissionDisabled')}
                            placeholder={translate('inputComissionsPercentage.placeholder')}
                          />
                        </div>
                      }
                    />
                    <FormRow
                      text={translate('linkQr.title')}
                      endComponent={
                        <div className='col-12 col-md-2 mt-1'>
                          <div className='mt-2'>
                            {imageUrl ? (
                              <>
                                <img alt='' src={imageUrl} width='180px' height='180px' />
                                <Button
                                  className={'btn-wellet-secondary text-truncate ml-2 mt-2'}
                                  text={translate('linkQr.download')}
                                  size={'medium'}
                                  onClick={printPDF}
                                  disabled={downloadPdfMutation.isLoading}
                                  loading={downloadPdfMutation.isLoading}
                                  type='button'
                                  heightConfig={'40px'}
                                  width={'160px'}
                                />
                              </>
                            ) : null}
                          </div>
                        </div>
                      }
                    />
                    {process.env.REACT_APP_TENANT_GRN === tenantId && mode === 'CONCIERGE' ? (
                      <FormRow
                        text={translate('notes.label')}
                        description={translate('notes.description')}
                        endComponent={
                          <div className='col-xl-6'>
                            <div className='row'>
                              <div className='col-10'>
                                <InputDescription
                                  name={'notes'}
                                  className=''
                                  placeholder={''}
                                  isRequired={false}
                                  height={80}
                                  maxLength={500}
                                />
                              </div>
                            </div>
                          </div>
                        }
                      />
                    ) : null}
                  </>
                )}
                <div className='d-flex justify-content-end mt-2'>
                  {hasAllowedRoles(roles, [
                    'superadministrator',
                    'marketadmin',
                    'affiliatecenter',
                  ]) || mode !== 'CONCIERGE' ? (
                    <FormButton
                      handleRedirect={() => {}}
                      clearForm={goBackToSetting}
                      all={false}
                      nextStep={true}
                      isDisabled={userMutation.isLoading || userMutation.isFetching}
                    />
                  ) : null}
                </div>
              </form>
            </FormProvider>
          </Show.Else>
        </Show>

        <Show.When isTrue={userMutation.isSuccess && showToast}>
          <Toast
            message={toastSuccess}
            type={`SUCCESS`}
            onCallback={() => {
              if (!id) navigate('/settings/team-and-security');
            }}
          />
        </Show.When>
        <Show.When isTrue={userMutation.isError && showToast}>
          <Toast
            message={mode === 'CONCIERGE' ? t('toast.errors') : t('toast.errorUser')}
            type={`ERROR`}
            onCallback={() => null}
          />
        </Show.When>
      </div>
    </>
  );
};

export default Profile;
