import React from 'react';
import { InfoRenderer, Show } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { ContanierSection, Subtitle } from '../../../../uiComponents/common';

const WirePaymentMethodDetail = ({ data = null, mode = null }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.wiresPaymentDetail',
  });
  const getName = (mode = null) => {
    switch (mode) {
      case 'WIRE_MEXICO':
        return t('breadcrumbs.wireMexico');
      case 'WIRE_INTERNATIONAL':
        return t('breadcrumbs.wireInternational');
      case 'CASH':
        return t('breadcrumbs.cash');
      case 'MERCADOPAGO':
        return t('breadcrumbs.mp');
      case 'CERTIFICATE':
        return t('breadcrumbs.certificates');
      case 'STRIPE':
        return 'Stripe';
      default:
        return mode;
    }
  };

  return (
    <>
      <Subtitle text={t('title')} className={'container-subtitle subtitle-border'} />
      <ContanierSection>
        <div className='row no-gutters'>
          <div className='col-12 my-2 font-weight-semibold container-data'>
            <div className='dark-grey container-label'>
              {t('paymentMethodDetail.paymentMethod')}
            </div>
            <InfoRenderer
              content={getName(data?.withdrawalMethod?.methodType)}
              className='container-text'
            />
          </div>
          <Show>
            <Show.When isTrue={mode === 'WIRE_MEXICO'}>
              <div className='col-12 col-md-4 font-weight-semibold container-data'>
                <div className='dark-grey container-label'>
                  {t('paymentMethodDetail.beneficiary')}
                </div>
                <InfoRenderer content={data?.recipientFullName} className='container-text' />
              </div>
              <div className='col-12 col-md-5 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.bank')}</div>
                <InfoRenderer content={data?.bank} className='container-text' />
              </div>
              <div className='col-12 col-md-4 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.key')}</div>
                <InfoRenderer content={data?.clabe} className='container-text' />
              </div>
              <div className='col-12 col-md-5 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.account')}</div>
                <InfoRenderer content={data?.accountNumber} className='container-text' />
              </div>
            </Show.When>
            <Show.When isTrue={mode === 'WIRE_INTERNATIONAL'}>
              <div className='col-12 col-md-4 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.country')}</div>
                <InfoRenderer content={data?.country} className='container-text' />
              </div>
              <div className='col-12 col-md-5 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>
                  {t('paymentMethodDetail.beneficiary')}
                </div>
                <InfoRenderer content={data?.recipientFullName} className='container-text' />
              </div>
              <div className='col-12 col-md-4 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.bank')}</div>
                <InfoRenderer content={data?.bank} className='container-text' />
              </div>
              <div className='col-12 col-md-5 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.currency')}</div>
                <InfoRenderer content={data?.currency} className='container-text' />
              </div>
              <div className='col-12 col-md-4 font-weight-semibold'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.swift')}</div>
                <InfoRenderer content={data?.swift} className='container-text' />
              </div>
              <div className='col-12 col-md-5 font-weight-semibold'>
                <div className='dark-grey container-label'>
                  {t('paymentMethodDetail.accountNumber')}
                </div>
                <InfoRenderer content={data?.accountNumber} className='container-text' />
              </div>
            </Show.When>
            <Show.When isTrue={mode === 'MERCADOPAGO'}>
              <div className='col-12 col-md-4 font-weight-semibold  container-data'>
                <div className='dark-grey container-label'>{t('paymentMethodDetail.account')}</div>
                <InfoRenderer content={data?.email} className='container-text' />
              </div>
            </Show.When>
          </Show>
        </div>
      </ContanierSection>
    </>
  );
};

export default WirePaymentMethodDetail;
