import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HomeIcon } from '../../icons';
import './breadcrumbs.css';
import { Helmet } from 'react-helmet-async';
import { Show } from '../../../components';
const BreadCrumbs = ({ crumbs }) => {
  const { titleWeb } = useSelector((state) => state.app);
  const currentOrganization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);

  const currentPage = crumbs.length === 0 ? 'Admin' : crumbs[0].name;
  return (
    <>
      <Helmet>
        <title>{`${currentPage} - ${titleWeb}`}</title>
      </Helmet>
      <Show.When isTrue={crumbs.length > 0}>
        <div className='breadcrumb d-flex align-items-center'>
          <Link className='breadcrumb-item' to={'/'}>
            <HomeIcon />
          </Link>
          {crumbs.map(({ name, path, onClick, disabled = false }, key) =>
            key + 1 === crumbs.length ? (
              <span className='breadcrumb-item active' href='/' key={key}>
                {name}
              </span>
            ) : (
              <Link
                className={`breadcrumb-item dark-grey  ${disabled ? 'active cursor-normal' : ''}`}
                key={key}
                to={path}
                onClick={onClick}
              >
                {name}
              </Link>
            ),
          )}
          {currentOrganization.id !== selectedOrganization.id ? (
            <span className='breadcrumb-item active cursor-normal' href='/'>
              {selectedOrganization.name}
            </span>
          ) : (
            <></>
          )}
        </div>
      </Show.When>
    </>
  );
};

export default BreadCrumbs;
