import { useContext } from 'react';
import { getAllIndividualPayments } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';

export const useGetPaymentList = (marketId, limit, skip, options) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery(
    ['payment-lists', marketId, limit, skip],
    () => getAllIndividualPayments(welletContext, marketId, limit, skip),
    queryOptions,
  );
};
