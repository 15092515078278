import React from 'react';
import { Money } from '../../../uiComponents/common';
import { TimeLine } from '../../charts';
import '../campaign.css';
import { Arrow, ArrowDownNew } from '../../../uiComponents/icons';
import { useSelector } from 'react-redux';

const SmallGraphicCard = ({
  data,
  title,
  beforeValue,
  className,
  value,
  isMoney = true,
  valueText,
  heightCard,
  beforePeriod,
}) => {
  const chekingData = data === null || data.length <= 1 ? [] : data;
  const currency = useSelector((state) => state.app.selectedOrganization);
  const dataFormatted = chekingData.map((c) => ({
    label: c.key,
    value: c.value,
    toolText: `${isMoney ? currency.currencySymbol : ''}${
      isMoney
        ? c.value?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        : c.value
    }`,
  }));
  function calculatePercentageDifference(currentValue, previousValue) {
    if (previousValue === 0 && currentValue === 0) {
      return 0;
    }
    
    if (previousValue === 0) {
      return 100;
    }

    let difference = currentValue - previousValue;
    let percentageDifference = (difference / previousValue) * 100;

    return percentageDifference;
  }
  let percentage = calculatePercentageDifference(value, beforeValue);

  return (
    <div className={`card-campaign card-campaign-padding ${className}`}>
      <div className='fw-600 '>{title}</div>
      <div className='row'>
        <div className='col-6 mt-3 pr-0 '>
          <div className='fw-600 fs-25 d-flex align-items-center'>
            {isMoney ? <Money value={value} /> : value}
            {valueText ? <span className='fw-500 ml-1 fs-12 lh-1'>{valueText}</span> : null}
          </div>
          <div className='dark-grey fw-500 fs-12 d-flex  align-items-center'>
            {percentage === 0 ? null : percentage > 0 ? (
              <Arrow color={'#079455'} size='12px' />
            ) : (
              <ArrowDownNew color='#F04438' size='12px' />
            )}
            <span
              className='fs-14'
              style={{
                color: percentage === 0 ? '#475467' : percentage > 0 ? '#079455' : '#F04438',
                paddingLeft: '2px',
              }}
            >
              {Math.abs(percentage?.toFixed(2))}%&nbsp;
            </span>
            {beforePeriod}
          </div>
        </div>
        <div className='col-6'>
          {/* To Do: ver como manipual cuando es un array de un solo item */}
          <TimeLine
            data={dataFormatted}
            chartConfig={{
              height: heightCard,
            }}
            chart={{
              numDivLines: 0,
              showLabels: 0,
              divLineColor: '#fff',
              chartRightMargin: 0,
              chartBottomMargin: 0,
              canvasPadding: 0,
              plotFillColor: percentage === 0 ? '#475467' : percentage > 0 ? '#079455' : '#D92D20',
              plotBorderColor:
                percentage === 0 ? '#475467' : percentage > 0 ? '#079455' : '#F04438',
              plotFillAngle: '90',
              plotFillAlpha: '20',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SmallGraphicCard;
