import React from 'react';

const PageHeader = ({ button, title, badge, endComponent, marginBottom = true, border = true }) => {
  return (
    <div
      className={`d-flex w-100 justify-content-between align-items-center ${
        marginBottom ? 'mb-3' : ''
      } ${border && 'border-table-bottom'}  text-subtitle`}
    >
      <div className='d-flex justify-content-start align-items-center mb-2'>
        <h1 className='ctm-title font-weight-bold m-0 mr-2 text-nowrap'>{title}</h1>
        {badge}
      </div>

      {button}
      <div className='mb-2'>{endComponent}</div>
    </div>
  );
};

export default PageHeader;
