import React from 'react';
import './modal.css';
import Popup from 'reactjs-popup';
import IconButton from '../iconButton/iconButton';
import { CloseIcon } from '../../icons';

const Modal = ({
  title,
  children,
  open = false,
  onClose,
  handleSubmit,
  handleClose,
  idModal,
  textCancel,
  textSubmit,
  btnType = 'button',
  customButtons = null,
  closeIcon = false,
  isLoading = false,
  maxWidth = '900px',
  btnJustify= 'end',
  ...others
}) => {
  const cancelButtonClasses = `btn-general btn-wellet-secondary ${btnJustify === 'center' ? 'col' : 'col-auto'}`;
  const submitButtonClasses = `btn-general btn-wellet-primary ml-2 ${btnJustify === 'center' ? 'col' : 'col-auto'}`;
  return (
    <Popup
      className='custom-popup'
      onClose={onClose}
      modal
      open={open}
      {...others}
      closeOnEscape={false}
    >
      <div
        className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
        style={{ maxWidth: maxWidth }}
      >
        <div className='modal-content p-3 pt-4'>
          {/* <div className='modal-header'> */}
          <div className='modal-custom-header'>
            <h5 className='modal-title'>{title}</h5>
            {closeIcon ? <div className='modal-custom-close'><IconButton icon={<CloseIcon color='#98A2B3'/>} onClick={handleClose ? handleClose : onClose} /></div> : null}
          </div>
          {/* </div> */}
          <div className='modal-custom-body'>{children}</div>
          <div className='modal-custom-footer mt-2'>
            {!customButtons && (
              <div className={`d-flex justify-content-${btnJustify}`}>
                <button
                  type='button'
                  onClick={onClose}
                  className={cancelButtonClasses}
                  data-dismiss='modal'
                  disabled={isLoading}
                >
                  {textCancel}
                </button>
                {textSubmit && (
                  <button
                    type={btnType}
                    disabled={isLoading}
                    onClick={handleSubmit}
                    className={submitButtonClasses}
                  >
                    {textSubmit}
                  </button>
                )}
              </div>
            )}
            {customButtons && customButtons}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default Modal;
