export const fetchOrganizations = async (
  welletContext,
  id = null,
  includeInactive = true,
  fieldFilter,
  limit,
  skip,
) => {
  const url = '/api/Organization/All';
  const body = {
    limit: limit,
    skip: skip,
    includeInactive: includeInactive,
    fieldFilter: fieldFilter,
  };

  const newUrl = `${url}/${id}`;
  const response = await welletContext.apis.admin.post(newUrl, body);
  return response;
};
export const fetchActivitiesType = async (
  welletContext,
  url = '/api/Organization/activitiesType',
) => {
  const response = await welletContext.apis.admin.get(url);
  return response;
};
export const postOrganization = async (welletContext, body, url = '/api/Organization ') => {
  const response = await welletContext.apis.admin.post(url, body);
  return response;
};
export const putOrganization = async (welletContext, body, url = '/api/Organization ') => {
  const response = await welletContext.apis.admin.put(url, body);
  return response;
};
export const getOrganizationById = async (welletContext, orgId, url = '/api/Organization') => {
  const newUrl = `${url}/${orgId}`;
  const response = await welletContext.apis.admin.get(newUrl);
  return response;
};
export const fetchTaxTypes = async (
  welletContext,
  countryId,
  url = '/api/Organization/taxtype',
) => {
  const newUrl = `${url}/${countryId}`;
  const response = welletContext.apis.admin.get(newUrl);
  return response;
};

export const fetchBusinessType = async (
  welletContext,
  body,
  url = '/api/Organization/businesstype',
) => {
  const response = welletContext.apis.admin.get(url, body);
  return response;
};
export const getCountriesByMarketId = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/location/countries/marketsetup/${id}`);
};
export const getSocialNetworks = async (welletContext) => {
  return await welletContext.apis.admin.get(`/api/Organization/socialnetworks`);
};
export const getContactInformation = async (welletContext) => {
  return await welletContext.apis.admin.get(`/api/Organization/contact`);
};
