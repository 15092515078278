import React from 'react';
import './status.css';

function setColor(status) {
  switch (status) {
    case false:
      return 'red';
    case true:
      return 'green';
    case 'PENDING':
      return 'yellow';
    default:
      return 'grey';
  }
}

const Status = ({ status, text, useDot = true }) => {
  const color = setColor(status);

  return (
    <div className='status-container' style={{ gap: '5px', width: '135px' }}>
      {useDot && <div className={`dot dot-${color}`}></div>}
      <div style={{ fontSize: '12px' }}>{text}</div>
    </div>
  );
};

export default Status;
