import React, { useContext, useEffect, useState } from 'react';
import {
  AffiliateCard,
  Button,
  ButtonFilter,
  Input,
  Loading,
  Money,
  OrganizationCard,
  PageError,
  Toast,
  UserImage,
} from '../../uiComponents/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { format } from 'date-fns';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  DateFilter,
  FilterCard,
  PageHeader,
  Show,
  Table,
  TableCell,
  TableRow,
} from '../../components';
import { SearchIcon } from '../../uiComponents/icons';
import { getComissionStates, downloadCommissions } from '../../services/comissionsService';
import {
  useCreateBreadCrumbs,
  useDebounce,
  useGetBosses,
  useGetCities,
  useGetRPs,
  useGetRestaurants,
  useGetSellers,
  useLocalStorage,
  useOrderBy,
  usePagination,
  useUser,
} from '../../hooks';
import { filtersFormat, getPaymentMethods } from '../../helpers/format';
import { commissionBadge } from '../../helpers/status';
import { setQueryParams } from '../../helpers/query';
import { commissionStatus, reservationTypes } from '../../data';
import downloadFileFromResponse from '../../utils/downloadFile';
import { useTranslation } from 'react-i18next';
import mergeWithDefaults from '../../helpers/localstorage';
import { hasAllowedRoles } from '../../helpers/roles';
import { defaultQueryOptions, defaultQueryOptionsFilter } from '../../utils/defaultQueryOptions';
import { formatDateTranslation } from '../../helpers/dates';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Commissions = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const language = useSelector((state) => state.app.language);
  const { t: translate } = useTranslation();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'screens.commissions' });
  const paymentMethods = useSelector((state) => state.app.paymentMethods);
  const { getClientTranslation } = useGenericTranslation();
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-commissions', {
    concierge: [],
    location: [],
    paymentMethods: getPaymentMethods(paymentMethods, translate, true),
    status: commissionStatus(translate),
    filterField: null,
    rp: [],
    boss: [],
    filterByUrl: null,
    reservationType: reservationTypes(translate),
    citiesIds: [],
    page: 1,
  });

  // Constant when is associate commissions
  const regex = /id=([a-fA-F0-9-]+)/;
  const associateId = location?.search
    ? location?.search?.match(regex)
      ? location?.search?.match(regex)[1]
      : null
    : null;

  const welletContext = useContext(WelletContext);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const organization = useSelector((state) => state.app.currentOrganization);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
  );
  const isMarket = organization.id === organization.marketId;

  const [isOpen, setIsOpen] = useState(false);
  const [filterReservationType, setFilterReservationType] = useState(
    filterLocalStorage?.reservationType?.length > 0
      ? mergeWithDefaults(filterLocalStorage.reservationType, reservationTypes(translate))
      : reservationTypes(translate),
  );
  const [filterCities, setFilterCities] = useState(filterLocalStorage.citiesIds ?? []);
  const [filterRestaurante, setFilterRestaurante] = useState(filterLocalStorage.location);
  const [filterField, setFilterField] = useState(filterLocalStorage.filterField);
  const [filterRP, setFilterRP] = useState(filterLocalStorage.rp);
  const [filterUser, setFilterUser] = useState(filterLocalStorage.concierge);
  const [filterAdjustment, setFilterAdjustment] = useState('');
  const [filterStatus, setFilterStatus] = useState(filterLocalStorage.status);
  const { orderBy, orderType, handleColumnClick } = useOrderBy();
  const showFilterCard =
    filtersFormat(filterStatus).length === 0 && filterLocalStorage.filterByUrl === null
      ? ''
      : filterLocalStorage.filterByUrl;
  const [filter, setFilter] = useState(showFilterCard || '');
  const [filterPaymentMethods, setFilterPaymentMethods] = useState(
    mergeWithDefaults(
      filterLocalStorage.filterPaymentMethods,
      getPaymentMethods(paymentMethods, translate, true),
    ),
  );
  const [filterBoss, setFilterBoss] = useState([]);
  const { roles } = useUser();

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  // Functions
  const getPaymentsTypeFilterQuery = () => {
    const filteredArray = filterPaymentMethods.filter((item) => item.isActive);
    const valuesArray = filteredArray.map((item) => item.value);
    return valuesArray;
  };
  const handleFilter = (filter, adjustment) => {
    if (filter === null) {
      setFilterAdjustment(adjustment);
      setFilter(null);
    } else if (adjustment === null) {
      setFilter(filter);
      setFilterAdjustment(null);
    }

    const filterStatusClean = filterStatus.map((f) => ({ ...f, isActive: false }));
    setFilterStatus(filterStatusClean);
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, filterByUrl: filter, page: 1 });
  };

  const handleNavigate = (commission) => {
    navigate(`/commissions-details/${commission.reservationId}`);
  };

  //Data filter
  const organizationId = organization.id === organization.marketId ? null : Number(organization.id);
  const debouncedSearch = useDebounce(filterField, 500);
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };

  // Main API Query
  const comissionesQuery = useQuery({
    queryKey: [
      'commissions',
      organization.marketId,
      organizationId,
      filtersFormat(filterRestaurante),
      getPaymentsTypeFilterQuery(),
      debouncedSearch, // input,
      selectedFormattedDates,
      limit,
      skip,
      associateId,
      filter,
      filterAdjustment,
      filtersFormat(filterUser),
      filtersFormat(filterStatus),
      filtersFormat(filterRP),
      filtersFormat(filterBoss),
      orderBy,
      orderType,
      filtersFormat(filterReservationType),
      filtersFormat(filterCities),
    ],
    queryFn: () =>
      getComissionStates(
        welletContext, // wellet context
        organization.marketId, // market id
        organizationId, // organization id
        filtersFormat(filterRestaurante), // show id
        getPaymentsTypeFilterQuery(), // payment type
        debouncedSearch, // input,
        selectedFormattedDates, // date
        limit, // limit
        skip, // skip
        null, // oredrId
        filtersFormat(filterUser), // id
        filtersFormat(filterStatus).length === 0
          ? filter
            ? [filter]
            : []
          : filtersFormat(filterStatus),
        filterAdjustment, //operationTypes
        filtersFormat(filterRP),
        filtersFormat(filterBoss),
        orderBy,
        orderType,
        filtersFormat(filterReservationType),
        filtersFormat(filterCities),
      ),
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.commissions?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        page: currentPage,
        filterField,
        location: filterRestaurante,
        paymentMethods: filterPaymentMethods,
        rp: filterRP,
        concierge: filterUser,
        status: filterStatus,
        boss: filterBoss,
        reservationType: filterReservationType,
        citiesIds: filterCities,
      });
    },
    ...defaultQueryOptions,
    keepPreviousData: true,
  });
  // Hooks Query Filters
  const bossQuery = useGetBosses(organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.map((user) => ({
        label: user.fullName,
        value: user.id,
        isActive:
          filterLocalStorage.boss.length > 0
            ? filtersFormat(filterLocalStorage.boss).includes(user.id)
            : false,
      }));
      setFilterBoss(response);
    },
  });
  const rpQuery = useGetRPs(organization.marketId, {
    onSuccess: (data) => {
      let response = data?.data?.map((user) => ({
        label: user.fullName,
        value: user.id,
        isActive:
          filterLocalStorage.rp.length > 0
            ? filtersFormat(filterLocalStorage.rp).includes(user.id)
            : false,
      }));
      setFilterRP(response);
    },
  });
  const sellersQuery = useGetSellers(organization.id, organization.marketId, {
    queryKey: ['sellers-commissions', organization.id, associateId], // Al ser diferente las queryKeys se la agregue aca.
    onSuccess: (data) => {
      let response = data?.data?.items?.map((user) => {
        return {
          label: user.name,
          value: user.sid,
          isActive:
            associateId !== null && associateId !== 'null'
              ? associateId === user.sid
              : filterLocalStorage.concierge.length > 0
              ? filtersFormat(filterLocalStorage.concierge).includes(user.sid)
              : false,
        };
      });
      setFilterUser(response);
    },
    ...defaultQueryOptionsFilter,
    enabled: (isOpen && filterUser.length === 0) || associateId !== null,
  });
  const restaurantesQuery = useGetRestaurants(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((org) => ({
        label: org.name,
        value: org.id,
        isActive:
          filterLocalStorage.location.length > 0
            ? filtersFormat(filterLocalStorage.location).includes(org.id)
            : false,
      }));
      setFilterRestaurante(response);
    },
    keepPreviousData: true,
  });
  const citiesQuery = useGetCities(organization, {
    onSuccess: (data) => {
      let response = data?.data?.map((city) => ({
        label: city.name,
        value: city.id,
        isActive:
          filterLocalStorage?.citiesIds?.length > 0
            ? filtersFormat(filterLocalStorage?.citiesIds).includes(city.id)
            : false,
      }));
      setFilterCities(response);
    },
  });

  // Query Mutation
  const downloadReservationMutation = useMutation({
    mutationFn: () => {
      const response = downloadCommissions(welletContext, organization.marketId, {
        From: selectedFormattedDates.from,
        To: selectedFormattedDates.to,
        organizationId: null,
        fieldFilter: filterField,
        showId:
          filtersFormat(filterRestaurante).length === 0 ? null : filtersFormat(filterRestaurante),
        paymentTypes:
          getPaymentsTypeFilterQuery().length === 0 ? null : getPaymentsTypeFilterQuery(),
        orderId: null,
        limit: null,
        skip: 0,
        status:
          filtersFormat(filterStatus).length === 0
            ? filter
              ? [filter]
              : null
            : filtersFormat(filterStatus),
        operationTypes: filterAdjustment ? [filterAdjustment] : null,
        userid: filtersFormat(filterUser).length === 0 ? null : filtersFormat(filterUser),
        RPId: filtersFormat(filterRP).length === 0 ? null : filtersFormat(filterRP),
        bossIds: filtersFormat(filterBoss).length === 0 ? null : filtersFormat(filterBoss),
        reservationType:
          filtersFormat(filterReservationType).length > 0
            ? filtersFormat(filterReservationType)
            : null,
        citiesIds: filtersFormat(filterCities).length > 0 ? filtersFormat(filterCities) : [],
      });

      return response;
    },
    onSuccess: (data) => downloadFileFromResponse(data, 'comisiones.xlsx'),
    onError: (error) => console.error(error),
  });

  const commissions = comissionesQuery?.data?.data || [];

  const headerAdmin = [
    { title: t('table.code'), value: '', filterable: false },
    { title: t('table.concierge'), value: '', filterable: false },
    { title: t('table.commission'), value: 'commissionsTotalAmount', filterable: true },
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.schedule'), value: 'reservationDateLocalTime', filterable: true },
    { title: t('table.consumption'), value: 'orderTotalAmount', filterable: true },
    { title: t('table.client'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs([{ name: t('title'), path: '/commissions' }]);
  useEffect(() => {
    setFilterPaymentMethods(
      mergeWithDefaults(
        filterLocalStorage.filterPaymentMethods,
        getPaymentMethods(paymentMethods, translate, true),
      ),
    );
    setFilterStatus(mergeWithDefaults(filterLocalStorage.status, commissionStatus(translate)));
    setFilterReservationType(
      mergeWithDefaults(filterLocalStorage.reservationType, reservationTypes(translate)),
    );
  }, [i18n.language]);

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={
          <div className='mt-1'>
            <DateFilter filterName={'Fecha'} />
          </div>
        }
      />
      <Show>
        <Show.When
          isTrue={
            comissionesQuery.isLoading ||
            restaurantesQuery.isLoading ||
            bossQuery.isLoading ||
            rpQuery.isLoading ||
            citiesQuery.isLoading ||
            bossQuery.isLoading ||
            (sellersQuery.isFetching && associateId !== null)
          }
        >
          <Loading />
        </Show.When>
        <Show.When isTrue={comissionesQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('cardFilter.total')}
              quantity={commissions.totalCount}
              onClick={() => handleFilter('', null)}
              className='reservations col'
              isSelected={filter === '' && filtersFormat(filterStatus).length === 0}
            />
            <FilterCard
              category={t('cardFilter.pendingAudit')}
              quantity={commissions.pendingReviewCount}
              onClick={() => handleFilter('PENDING_REVIEW', null)}
              isSelected={filter === 'PENDING_REVIEW'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.pendingPayment')}
              quantity={commissions.paymentPendingCount}
              onClick={() => handleFilter('PAYMENT_PENDING', null)}
              isSelected={filter === 'PAYMENT_PENDING'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.paid')}
              quantity={commissions.paymentAccreditedCount}
              onClick={() => handleFilter('PAYMENT_ACCREDITED', null)}
              className='reservations col'
              isSelected={filter === 'PAYMENT_ACCREDITED'}
            />
          </div>
          <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
            <div className='col col-sm-auto align-items-center justify-content-center'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                customClass='mb-0 mr-3'
                name={'search'}
                value={filterField}
                onChangeValue={(e) => setFilterField(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <div className='row'>
              {isMarket && (
                <div className='col-auto mb-1  pl-1 justify-content-center'>
                  <ButtonFilter
                    isSearchable={true}
                    filterName={getClientTranslation('title.plural')}
                    filterOptions={filterRestaurante}
                    callback={setFilterRestaurante}
                    error={restaurantesQuery.isError}
                  />
                </div>
              )}
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={false}
                  filterName={t('buttonsFilter.getaway')}
                  filterOptions={filterPaymentMethods}
                  callback={setFilterPaymentMethods}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonsFilter.rp')}
                  filterOptions={filterRP}
                  callback={setFilterRP}
                  error={rpQuery.isError}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonsFilter.bossSeller')}
                  filterOptions={filterBoss}
                  callback={setFilterBoss}
                  error={bossQuery.isError}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonsFilter.concierge')}
                  filterOptions={filterUser}
                  loading={sellersQuery.isLoading}
                  onClick={() => setIsOpen(true)}
                  error={sellersQuery.isError}
                  callback={(data) => {
                    setFilterUser(data);
                    setQueryParams({ id: null }, navigate, location); // limpiar por si cambia de pantalla
                  }}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.typeReserves')}
                  filterOptions={filterReservationType}
                  callback={(data) => {
                    setFilterReservationType(data);
                  }}
                />
              </div>
              <div className='mb-1 pl-1  col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.cities')}
                  filterOptions={filterCities}
                  callback={(data) => {
                    setFilterCities(data);
                  }}
                  error={citiesQuery.isError}
                />
              </div>
              <div className='mb-1 pl-1 col-auto justify-content-center'>
                <ButtonFilter
                  filterName={t('buttonsFilter.state')}
                  filterOptions={filterStatus}
                  callback={(filter) => {
                    handleFilter(null);
                    setFilterStatus(filter);
                  }}
                />
              </div>
            </div>
            <div className='col d-flex justify-content-end'>
              {hasAllowedRoles(roles, [
                'superadministrator',
                'admin',
                'marketadmin',
                'comercial',
                'finance',
                'auditor',
                'auditor-onlyview',
                'salesteamlead',
                'bosssales',
              ]) ? (
                <Button
                  className={'btn-wellet-secondary opacity justify-content-center'}
                  size={'small'}
                  text={t('download')}
                  width={'36px'}
                  disabled={downloadReservationMutation.isLoading}
                  loading={downloadReservationMutation.isLoading}
                  onClick={() => downloadReservationMutation.mutate()}
                />
              ) : null}
            </div>
          </div>
          <Show>
            <Show.When isTrue={comissionesQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <Table
                data={commissions?.commissions?.items}
                currentPage={currentPage}
                header='grey'
                hasPagination={false}
                onPageChange={(page) =>
                  handlePageChange(page, setFilterLocalStorage({ ...filterLocalStorage, page }))
                }
                totalP={totalItems}
                columns={headerAdmin}
                onColumnClick={handleColumnClick}
                activeColumn={orderType}
                sortOrder={orderBy}
                renderRow={(commission, index) => {
                  const { date, time } = formatDateTranslation(
                    commission.reservationDateLocalTime,
                    language,
                    'extraSmall',
                  );
                  return (
                    <>
                      <>
                        <TableCell style={{ width: 'auto' }}>
                          {commission.referenceCode ? commission.referenceCode : '-'}
                        </TableCell>

                        <TableCell style={{ minWidth: '200px' }}>
                          {commission.isWellet ? (
                            commission?.userName
                          ) : (
                            <AffiliateCard
                              url={commission?.sellerProfilePicUrl}
                              isVerified={commission?.sellerIsVerified}
                              name={commission?.sellerName}
                              company={commission?.sellerCompany}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          className='font-weight-bold'
                          style={{ width: 'auto' }}
                          type={'primary'}
                        >
                          {commission.commissionsSellers ? (
                            commission.adjustmentsSellers === 0 ? (
                              <Money value={commission.commissionsSellers} />
                            ) : (
                              <div>
                                <div className='text-strikethrough'>
                                  <Money value={commission.commissionsSellers} />
                                </div>
                                <div>
                                  <Money
                                    value={
                                      commission.commissionsSellers + commission.adjustmentsSellers
                                    }
                                  />
                                </div>
                              </div>
                            )
                          ) : (
                            '-'
                          )}
                        </TableCell>

                        <TableCell style={{ width: '250px' }}>
                          <OrganizationCard
                            name={commission?.restaurant?.name}
                            date={commission?.restaurant?.cityName}
                            size={40}
                            imageUrl={commission?.restaurant?.logo}
                            hightLightName={true}
                          />
                        </TableCell>
                        <TableCell style={{ width: 'auto' }} className={'fw-700'}>
                          <div className='d-flex flex-column'>
                            <span className='pl-1 text-capitalize'>{date}</span>
                            <span>{time}</span>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 'auto' }} type={'primary'}>
                          {commission.orderTotalAmount ? (
                            <Money value={commission.orderTotalAmount} />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell style={{ width: 'auto' }}>
                          <div className='fw-500 text-capitalize'>{commission.customerName}</div>
                          <div className='dark-grey'>
                            {commission.paxs} {commission.paxs > 1 ? t('people') : t('person')}
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 'auto' }}>
                          {commission?.status
                            ? commissionBadge(commission?.status, translate)
                            : null}
                        </TableCell>
                      </>
                      {index === commissions?.commissions?.items.length - 1 && (
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#eee' }}>Total</TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell
                            style={{ backgroundColor: '#eee' }}
                            className='font-weight-bold'
                          >
                            <Money value={commissions.totalCommissionsAndAdjustmentsAmount} />
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}>
                            <Money value={commissions.totalOrdersAmount} />
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}></TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                }}
                onRowClick={(commission) => handleNavigate(commission)}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>

      <Show.When isTrue={downloadReservationMutation?.isError}>
        <Toast
          message={
            downloadReservationMutation?.error?.response?.status === 413
              ? 'La cantidad de registros a descargar ha superado el límite.'
              : `Ocurrio un error`
          }
          type='ERROR'
          onCallback={() => {
            downloadReservationMutation.reset();
          }}
        />
      </Show.When>
    </>
  );
};

export default Commissions;
