import React from 'react';
import { InfoRenderer, TableCell, TableRow } from '../../components';
import {
  AffiliateCard,
  Badge,
  Button,
  Loading,
  OrganizationCard,
  Timer,
  UserImage,
} from '../../uiComponents/common';
import { format } from 'date-fns';
import { reservationBadge } from '../../helpers/status';
import { useExpirationTimer, useUser } from '../../hooks';
import { getOccasionText } from '../../utils/getOcassionIcon';
import { hasAllowedRoles } from '../../helpers/roles';
import { useTranslation } from 'react-i18next';
import { getReservationType } from '../../helpers/format';
import { formatDateTranslation } from '../../helpers/dates';

const ReservationRow = ({ reservations, index, language, onClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.requests' });
  const { t: translate } = useTranslation();
  const { color, expirationTime, loading } = useExpirationTimer(
    reservations.reservationExpireSeconds,
  );
  const { roles } = useUser();

  const isAllowed = hasAllowedRoles(roles, [
    'superadministrator',
    'admin',
    'marketadmin',
    'reservationcenter',
    'callcenterlead',
  ]);

  const { date, time } = formatDateTranslation(
    reservations.reservationDateLocalTime,
    language,
    'extraSmall',
  );

  return (
    <>
      <TableCell style={{ width: '120px' }} levelPadding={'low'}>
        {reservations.status === 'PENDING' ? (
          <div>
            {loading ? (
              <Loading global={false} />
            ) : (
              <Timer expirationTime={expirationTime} color={color} status={reservations.status} />
            )}
          </div>
        ) : null}
      </TableCell>

      <TableCell style={{ width: '200px' }} levelPadding={'low'}>
        <OrganizationCard
          width='auto'
          imageUrl={reservations?.restaurant?.logo}
          name={reservations?.restaurant?.name}
          date={reservations?.restaurant?.cityName}
          hightLightName
          size={40}
        />
      </TableCell>

      <TableCell className={'fw-700 text-center '} levelPadding={'low'}>
        <span className='sentences-capitalize d-block'>{date}</span>
        <span>{time}</span>
      </TableCell>
      <TableCell levelPadding={'low'}>
        <div className='fw-700 wrap-text text-capitalize'>{reservations.customerName}</div>
        <div className='dark-grey  wrap-text'>
          <InfoRenderer content={getOccasionText(reservations.occasion, translate)} />
        </div>
      </TableCell>
      <TableCell className={'fw-700'} levelPadding={'low'}>
        <span className='pl-1'>{reservations.pax}</span>
      </TableCell>
      <TableCell levelPadding={'low'}>
        <AffiliateCard
          company={reservations?.sellerCompany}
          url={reservations?.sellerProfilePicture}
          name={reservations?.sellerFullName}
          isVerified={reservations?.sellerIsVerified}
        />
      </TableCell>
      <TableCell levelPadding={'low'}>
        <div>{getReservationType(reservations?.reservationType, translate)}</div>
      </TableCell>
      <TableCell levelPadding={'low'}>
        {reservations.isReviewed ? (
          <Badge
            text={`${t('inReview')} ${reservations?.userReviewer?.fullName}`}
            type={'violet'}
          />
        ) : reservations.status !== 'REJECTED' ? (
          isAllowed ? (
            <Button
              className={'btn-wellet-secondary fw-600 bg-custom-light p-0 px-2 m-0'}
              text={reservations.status === 'CANCELLED' ? t('btnCancelRequest') : t('btnRequest')}
              size={'custom'}
              heightConfig={'36px'}
              fontConfig={'14px'}
              onClick={() => onClick(reservations)}
            />
          ) : null
        ) : (
          <Badge
            text={`${t('inReview')} ${reservations?.userReviewer?.fullName}`}
            type={'violet'}
          />
        )}
      </TableCell>
    </>
  );
};

export default ReservationRow;
