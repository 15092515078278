import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../loading/loading';

const ButtonDownload = ({ isLoading, onClick, className }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.buttonDownload',
  });
  return (
    <div className='d-flex justify-content-center'>
      {isLoading ? (
        <Loading global={false} />
      ) : (
        <button
          className={`btn-link ${className}`}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'var(--color-primary-blue)',
            textDecoration: 'none',
            cursor: 'pointer',
            padding: 0,
          }}
          onClick={onClick}
          disabled={isLoading}
        >
          {t('download')}
        </button>
      )}
    </div>
  );
};

export default ButtonDownload;
