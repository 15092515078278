import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputDescription,
  InputText,
  Loading,
  Modal,
  ModernSwitcher,
  Tabs,
  TextAlert,
  Toast,
} from '../../../uiComponents/common';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { WelletContext } from '../../../context/wellet/welletContext';
import { getFAQById, postFAQ, putFAQ } from '../../../services';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';

const FaqModal = ({ open, setOpen, refetchQuery, faq, setFaq }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.faqModal' });
  const methods = useForm();
  const welletContext = useContext(WelletContext);
  const [error, setError] = useState('');
  const validateFields = {
    default: {
      rule: (values) => {
        if (values.default === true) {
          if (values.title && values.description) {
            return true;
          } else return false;
        } else return true;
      },
      errorMsg: t('error.default'),
    },
    title: {
      rule: (values) => {
        if (!values.title && !values.description) {
          return true;
        } else if (values.title && values.title.trim() !== '') {
          return true;
        } else {
          return false;
        }
      },
      errorMsg: t('error.title'),
    },
    description: {
      rule: (values) => {
        if (!values.title && !values.description) {
          return true;
        } else if (values.description && values.description.trim() !== '') {
          return true;
        } else {
          return false;
        }
      },
      errorMsg: t('error.description'),
    },
  };

  const { languages, lngErrors, currentLng, changeLanguage, isLoading, resetLngErrors } =
    useLanguageSwitcher(validateFields, methods);

  const faqQuery = useQuery({
    queryKey: ['faq-id', faq?.id],
    queryFn: () => getFAQById(welletContext, faq?.id),
    enabled: !!faq?.id,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      methods.reset(formatFAQ(data?.data));
    },
  });

  const formatFAQ = (data) => {
    const formattedData = {};
    data?.langFAQ?.forEach((faq) => {
      const lang = faq.language;
      formattedData[`title_${lang}`] = faq.title;
      formattedData[`description_${lang}`] = faq.description;
      formattedData[`video_${lang}`] = faq.videoUrl;
    });
    changeLanguage(
      languages?.find((l) => l.shortForm === data?.defaultLanguage)?.title
        ? languages?.find((l) => l.shortForm === data?.defaultLanguage)?.title
        : languages[0]?.title,
    );
    formattedData[`default_${data.defaultLanguage}`] = true;

    return formattedData;
  };

  const resetMethod = () => {
    const formattedData = {};
    languages.forEach((lng) => {
      const lang = lng.shortForm;
      formattedData[`title_${lang}`] = null;
      formattedData[`description_${lang}`] = null;
      formattedData[`video_${lang}`] = null;
      formattedData[`default_${lang}`] = false;
    });
    return formattedData;
  };

  const faqMutation = useMutation({
    mutationFn: (data) => {
      if (faq?.id) {
        return putFAQ(welletContext, {
          FAQs: data?.faqs,
          defaultLanguage: data?.defaultLanguage,
          id: faq?.id,
        });
      }
      return postFAQ(welletContext, { FAQs: data?.faqs, defaultLanguage: data?.defaultLanguage });
    },
    onSuccess: () => {
      refetchQuery();
    },
  });

  const handleClose = () => {
    setOpen(false);
    faqMutation.reset();
    methods.reset();
  };

  const onSubmit = (data) => {
    const defaultLanguage = Object.keys(data)
      .find((key) => data[key] === true && key.startsWith('default_'))
      ?.split('_')[1];

    if (!defaultLanguage) {
      setError(t('error.language'));
      return;
    }

    let faqLangs = languages.map((lng) => {
      return {
        language: lng.shortForm,
        title: data[`title_${lng.shortForm}`],
        description: data[`description_${lng.shortForm}`],
        videoUrl: data[`video_${lng.shortForm}`],
      };
    });
    const removeEmptys = removeObjectsWithEmptyFields(faqLangs, ['title', 'description']);
    faqMutation.mutate({ faqs: removeEmptys, defaultLanguage });
  };

  return (
    <FormProvider {...methods}>
      <form key={'faq'}>
        <Modal
          textCancel={t('cancel')}
          textSubmit={faq?.id ? t('edit') : t('add')}
          idModal={'imageUploader'}
          title={faq?.id ? t('editTitle') : t('title')}
          btnType='submit'
          maxWidth='450px'
          onClose={() => {
            changeLanguage(languages[0]?.title);
            setOpen(false);
            setFaq(null);
            resetLngErrors();
            methods.reset(resetMethod());
          }}
          isLoading={faqMutation.isLoading || faqMutation.isSuccess || faqMutation.isError}
          handleSubmit={methods.handleSubmit(onSubmit)}
          open={open}
        >
          {isLoading || faqQuery.isLoading || faqQuery.isFetching ? (
            <div className='py-3'>
              <Loading />
            </div>
          ) : (
            <>
              <div className='my-2'>
                <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
                {Object.keys(lngErrors).length !== 0 && (
                  <div className='px-2'>
                    <TextAlert text={lngErrors.name} type={'danger'} />
                  </div>
                )}
              </div>
              <InputText
                name={'title_' + currentLng}
                label={t('inputTitle.label')}
                minLength={0}
                className={'col mt-3'}
                isRequired={true}
                placeholder={t('inputTitle.placeholder')}
              />
              <InputDescription
                className='col'
                height='80px'
                name={'description_' + currentLng}
                label={t('inputDescription.label')}
                isRequired={true}
                placeholder={t('inputDescription.placeholder')}
              />
              <InputText
                name={'video_' + currentLng}
                label={t('inputVideo.label')}
                minLength={0}
                className={'col'}
                placeholder={t('inputVideo.placeholder')}
              />
              <ModernSwitcher
                className={'col-auto'}
                text={t('defaultLanguage')}
                name={'default_' + currentLng}
                onClick={() => {
                  languages.forEach((l) => {
                    if (l.shortForm !== currentLng) {
                      methods.setValue('default_' + l.shortForm, false);
                    }
                  });
                  setError('');
                }}
              />
              {error ? (
                <div className='d-flex align-items-center error-content'>
                  <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                  <p className='text-danger p-0 m-0'>{error}</p>
                </div>
              ) : null}
            </>
          )}
        </Modal>
      </form>
      {faqMutation.isError && (
        <Toast
          message={t('toast.error')}
          type={`ERROR`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
      {faqMutation.isSuccess && (
        <Toast
          message={faq?.id ? t('toast.editSuccess') : t('toast.success')}
          type={`SUCCESS`}
          onCallback={() => {
            handleClose();
          }}
        />
      )}
    </FormProvider>
  );
};

export default FaqModal;
