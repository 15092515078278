import React, { useContext, useState } from 'react';
import {
  ButtonFilter,
  Loading,
  Money,
  OrganizationCard,
  PageError,
} from '../../uiComponents/common';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  DateFilter,
  FilterCard,
  InfoRenderer,
  PageHeader,
  Show,
  Table,
  TableCell,
} from '../../components';
import { useCreateBreadCrumbs, useLocalStorage, usePagination } from '../../hooks';
import { getRefunds } from './services/services';
import { filtersFormat, getErrorCode } from '../../helpers/format';
import { paymentLiveFeedBadge } from '../../helpers/status';
import { useTranslation } from 'react-i18next';
import { formatDateTranslation } from '../../helpers/dates';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import PaymentDetail from '../paymentDetail/paymentDetail';
import { brandsStatus } from '../../data';
import { useNavigate } from 'react-router-dom';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const Refunds = () => {
  /* ---------- Translations ---------- */
  const { t: translate } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.refunds' });
  const { getClientTranslation } = useGenericTranslation();
  /* ---------- Global states ---------- */
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const welletContext = useContext(WelletContext);
  const { language } = useSelector((state) => state.app);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const navigate = useNavigate();

  /* ---------- Local storage ---------- */
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-refunds', {
    refundFilter: 'BOTH',
    filterField: null,
    filterDate: null,

    page: 1,
    cardsHolder: brandsStatus(),
    livefeed: false,
  });

  /* ---------- Pagination ---------- */
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  /* ---------- Local states ---------- */
  const [filterCardsHolder, setCardsHolder] = useState(
    filterLocalStorage.cardsHolder ? filterLocalStorage.cardsHolder : brandsStatus(),
  );
  const [paymentInfo, setPaymentInfo] = useState({
    chargeId: null,
    id: null,
    destinationPaymentId: null,
  });

  const [filter, setFilter] = useState(filterLocalStorage?.refundFilter);

  /* ---------- Functions ---------- */
  const handleFilter = (filter, adjustment) => {
    if (filter === null) {
      setFilter(null);
    } else if (adjustment === null) {
      setFilter(filter);
    }
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, page: 1 });
  };
  const onModalClose = () => {
    setPaymentInfo({
      chargeId: null,
      id: null,
      destinationPaymentId: null,
    });
  };

  const selectedFormattedDates = {
    startDate: selectedDates.formattedFrom,
    endDate: selectedDates.formattedTo,
  };

  /* ---------- Api querys ---------- */
  const refundsQuery = useQuery({
    queryKey: [
      'refunds',
      limit,
      skip,
      filter,
      selectedFormattedDates,
      organization.marketId,
      filtersFormat(filterCardsHolder),
    ],
    queryFn: async () => {
      const body = {
        limit: limit,
        skip: skip,
        sortBy: 'ASC',
        columnFilter: '',
        includeInactive: true, // always true
        fromDate: selectedFormattedDates?.startDate ?? null,
        toDate: selectedFormattedDates?.endDate ?? null,

        cardType: filtersFormat(filterCardsHolder),
        statusType: null,
        refundFilter: filter ? filter : null,
      };

      let id = organization.isMarket ? organization.marketId : null;
      return await getRefunds(welletContext, id, body);
    },

    onSuccess: (data) => {
      setTotalAndReset(data?.data?.tableMonitorPayments?.totalRegisters);
      setFilterLocalStorage({
        page: currentPage,
        selectedFormattedDates,
        refundFilter: filter,
        cardsHolder: filterCardsHolder,
      });
    },
    ...defaultQueryOptions,
    // refetchInterval: paymentInfo.id === null ? 10000 : false,
    refetchInterval: false,
  });
  const refunds = refundsQuery?.data?.data || [];

  const entityHeader = [
    { title: t('table.date'), value: '', filterable: false, center: true },
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.amount'), value: '', filterable: false },
    { title: t('table.cardHolder'), value: '', filterable: false },
    { title: '', value: '', filterable: false },
    // { title: t('table.description'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs([{ name: t('title'), path: '/refunds' }]);

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={
          <div className='mt-1'>
            <DateFilter />
          </div>
        }
      />
      <Show>
        <Show.When isTrue={refundsQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={refundsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          {/* ---------- Filters ----------*/}
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('filters.cardFilters.total')}
              quantity={refunds?.totalAmount}
              onClick={() => handleFilter('BOTH', null)}
              className='col'
              isSelected={filter === 'BOTH'}
            />
            <FilterCard
              category={t('filters.cardFilters.devProcess')}
              quantity={refunds?.refundRequested}
              onClick={() => handleFilter('REFUND_REQUESTED', null)}
              className=' col'
              isSelected={filter === 'REFUND_REQUESTED'}
            />
            <FilterCard
              category={t('filters.cardFilters.devSuccess')}
              quantity={refunds?.refundedAmount}
              onClick={() => handleFilter('REFUNDED', null)}
              className=' col'
              isSelected={filter === 'REFUNDED'}
            />
          </div>
          <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
            <div className='row mt-1'>
              <div className='mb-1 pl-1  col-auto'>
                <ButtonFilter
                  filterName={t('filters.buttonFilters.cards')}
                  filterOptions={filterCardsHolder}
                  callback={(data) => {
                    setCardsHolder(data);
                  }}
                />
              </div>
            </div>
          </div>
          {/* ---------- Table ----------*/}
          <Show>
            <Show.When isTrue={refundsQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <Table
                data={refunds?.tableMonitorPayments?.items}
                currentPage={currentPage}
                header='white'
                hasPagination={false}
                borderTop={false}
                onPageChange={(page) =>
                  handlePageChange(page, setFilterLocalStorage({ ...filterLocalStorage, page }))
                }
                totalP={totalItems}
                columns={entityHeader}
                onRowClick={(item) => navigate(`/refunds/${item.chargeId}`)}
                renderRow={(payment) => {
                  const { date, time } = formatDateTranslation(
                    payment.paymentDateUTC,
                    language,
                    'small',
                  );
                  return (
                    <>
                      <TableCell style={{ width: '200px' }}>
                        <div className={'text-center'}>
                          <div className='sentences-capitalize'>{date}</div>
                          <div className=''>{time}</div>
                        </div>
                      </TableCell>

                      <TableCell>
                        <OrganizationCard
                          width='auto'
                          name={payment?.restaurant?.name}
                          date={payment?.restaurant?.cityName ?? '-'}
                          size={40}
                          imageUrl={payment?.restaurant?.logo}
                          hightLightName={true}
                        />
                      </TableCell>

                      <TableCell type={'primary'}>
                        <div className='fw-600'>
                          {payment.amount ? <Money value={payment.amount} /> : '-'}
                        </div>
                      </TableCell>
                      <TableCell style={{ width: '200px' }}>
                        {' '}
                        <div className='fw-600'>
                          <InfoRenderer content={payment.cardHolderName} />
                        </div>
                        <div className='fw-400 dark-grey'>
                          <InfoRenderer content={payment.cardHolderEmail} />
                        </div>
                      </TableCell>
                      <TableCell style={{ width: '100px' }}>
                        <div className='d-flex align-items-center'>
                          <Show.When isTrue={payment.cardBrand}>
                            <img
                              style={{ height: '35px' }}
                              src={
                                'https://cdn1.wellet.fun/images/credit-card/' +
                                payment.cardBrand?.toLocaleLowerCase() +
                                '-logo.png'
                              }
                              className='mr-2'
                              alt='card'
                            />
                          </Show.When>
                          <div className='fw-500  mr-2'>
                            <div className='fs-2xs'>
                              {payment?.cardLast4 ? `**** ${payment?.cardLast4} ` : null}
                            </div>
                            <div className='fs-3xs dark-grey'>
                              {payment?.expirationMonth
                                ? `${payment?.expirationMonth}/${payment?.expirationYear} `
                                : null}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      {/* <TableCell>{payment?.description ?? '-'}</TableCell> */}
                      <TableCell>
                        {payment?.statusCode === 'OK' && payment?.refundRequested
                          ? paymentLiveFeedBadge('REFUND_REQUESTED', translate)
                          : paymentLiveFeedBadge(payment?.statusCode, translate)}
                        <Show.When
                          isTrue={payment?.errorCodeType && payment?.errorCodeType !== 'NONE'}
                        >
                          <div className='fw-500 mt-1'>
                            {getErrorCode(payment.errorCodeType, translate)}
                          </div>
                        </Show.When>
                      </TableCell>
                    </>
                  );
                }}
              />
              <PaymentDetail
                id={paymentInfo.id}
                destinationPaymentId={paymentInfo.destinationPaymentId}
                chargeId={paymentInfo.chargeId}
                onClose={onModalClose}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </>
  );
};

export default Refunds;
