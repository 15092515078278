import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { WelletContext } from '../../../context/wellet/welletContext';
import { getProfilePic } from '../../../services';
import { NoPhotoIcon } from '../../icons';
import VerifiedIcon from '../../icons/verifiedIcon';
import { Show } from '../../../components';

const UserImage = ({
  url,
  name,
  size = '35px',
  className,
  isVerified = false,
  verifiedStyle,
  verifiedSize = '14px',
}) => {
  const welletContext = useContext(WelletContext);
  const retryNumber = +process.env.REACT_APP_RETRY_COUNT;
  const retryDelay = +process.env.REACT_APP_RETRY_COUNT_DELAY;
  const profileQuery = useQuery({
    queryKey: ['profile-pic', url],
    queryFn: () => getProfilePic(welletContext, url.slice(url.indexOf('/users'))),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!url,
    retry: retryNumber,
    retryDelay: retryDelay,
  });
  const imageUrl = useMemo(
    () =>
      profileQuery.isSuccess
        ? URL.createObjectURL(new Blob([profileQuery?.data?.data], { type: 'image/jpeg' }))
        : null,
    [url, profileQuery.isSuccess, profileQuery.data],
  );

  return (
    <>
      <div className='avatar-container' style={{ width: size }}>
        <Show>
          <Show.When isTrue={imageUrl}>
            <img
              className={className}
              style={{
                height: size,
                width: size,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={imageUrl}
              alt={`Foto de ${name}`}
            />
          </Show.When>
          <Show.Else>
            <NoPhotoIcon size={size} className={className} />
          </Show.Else>
        </Show>
        <Show.When isTrue={isVerified}>
          <div className='verified-avatar' style={verifiedStyle}>
            <VerifiedIcon size={verifiedSize} />
          </div>
        </Show.When>
      </div>
    </>
  );
};

export default UserImage;
