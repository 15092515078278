export const getPolicies = async (welletContext, marketId) => {
  let url = `/api/marketSetup/market/${marketId}/policy`;
  return await welletContext.apis.admin.get(url);
};

export const putPolicies = async (welletContext, body, marketId) => {
  let url = `/api/marketSetup/market/${marketId}/policy`;

  return await welletContext.apis.admin.put(url, body);
};
