import React from 'react';
import './inputs.css';

const InputBase = ({
  icon,
  label,
  value,
  onChange,
  type = 'text',
  border,
  field,
  errors,
  name,
  placeholder,
  onClick,
  endComponent,
  isDisabled,
  autocomplete = null,
  iconPadding = 'px-2',
  isRequired,
  copy,
  ...others
}) => {
  return (
    <div className=''>
      {label ? (
        <label className='mb-1 fw-500' style={{ color: 'var(--color-dark)' }}>
          {label}
          {/* {isRequired ? <span className='requiredLabel'>*</span> : ""} */}
        </label>
      ) : (
        <></>
      )}
      <div
        className={`d-flex  align-items-center input-base-container ${
          errors[name] ? 'input-error' : ''
        }`}
      >
        {icon ? (
          <span
            className={`input-group-text ${iconPadding} input-icon`}
            style={{
              color: 'var(--color-subtitle)',
              background: isDisabled ? 'var(--color-border-table)' : '#fff',
            }}
          >
            {icon}
          </span>
        ) : (
          <></>
        )}
        <input
          autoComplete={autocomplete}
          disabled={isDisabled}
          type={type}
          value={value}
          name={name}
          className={`input-style ${endComponent ? 'input-end' : ''} ${
            isDisabled ? 'input-disabled' : ''
          } ${icon ? 'with-icon' : ''} ${border ? 'border-left' : ""}`}
          placeholder={placeholder}
          onChange={onChange}
          onClick={onClick}
          {...others}
          {...field}
        />
        {copy ? (
          <span
            className={`input-group-text ${iconPadding}`}
            style={{
              color: 'var(--color-subtitle)',
              background: isDisabled ? 'var(--color-border-table)' : '#fff',
            }}
          >
            {copy}
          </span>
        ) : (
          <></>
        )}
        {endComponent}
      </div>
    </div>
  );
};

export default InputBase;
