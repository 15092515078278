import React, { useContext } from 'react';
import Table from '../table/table';
import { usePagination } from '../../hooks';
import { useQuery } from 'react-query';
import { getAffiliateSummary } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import TableRow from '../table/tableRow';
import TableCell from '../table/tableCell';
import { Loading, UserImage } from '../../uiComponents/common';
import { getDashboardRow } from './unitsTable';
import { useTranslation } from 'react-i18next';
import { getOrderBy } from '../../helpers/dashboard';
import UnitTable from '../table/unitTable';

const AffiliatesTable = ({ selectedOption }) => {
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    1,
    10,
    false,
  );
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.dashboard.tables.tableConcierge',
  });
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);
  const affiliateQuery = useQuery({
    queryKey: ['dashboard-affiliate', organization.id, selectedDates, limit, skip, selectedOption],
    queryFn: () =>
      getAffiliateSummary(
        welletContext,
        organization.marketId,
        selectedDates,
        limit,
        skip,
        !organization.isMarket ? organization.id : null,
        getOrderBy(selectedOption),
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => setTotalAndReset(data?.data?.totalRegisters),
  });

  const headerAdmin = [
    { title: t('headers.name'), value: '', filterable: false },
    {
      title:
        selectedOption === 'reservations'
          ? t('headers.reservations')
          : selectedOption === 'commissions'
          ? t('headers.commissions')
          : selectedOption === 'commission'
          ? t('headers.avgCommission')
          : selectedOption === 'concierges'
          ? ' '
          : t('headers.incomes'),
      value: '',
      filterable: false,
    },
    {
      title: selectedOption !== 'concierges' ? t('headers.pax') : ' ',
      value: '',
      filterable: false,
    },
  ];

  return (
    <div>
      {affiliateQuery.isLoading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '400px' }}
        >
          <Loading global={false} />
        </div>
      ) : (
        <UnitTable
          currentPage={currentPage}
          data={affiliateQuery.data?.data.items}
          totalItems={totalItems}
          selectedOption={selectedOption}
          headerAdmin={headerAdmin}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AffiliatesTable;