import React from 'react';

const UpGraphicIcon = ({ size = '16px', color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 37 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 0C3.10457 0 4 0.89543 4 2V31H33C34.1046 31 35 31.8954 35 33C35 34.1046 34.1046 35 33 35H2C0.89543 35 0 34.1046 0 33V2C0 0.89543 0.89543 0 2 0Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.577 8C24.577 6.89543 25.4724 6 26.577 6H34.7309C35.8354 6 36.7309 6.89543 36.7309 8V16.1538C36.7309 17.2584 35.8354 18.1538 34.7309 18.1538C33.6263 18.1538 32.7309 17.2584 32.7309 16.1538V10H26.577C25.4724 10 24.577 9.10457 24.577 8Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.1452 6.58579C36.9262 7.36684 36.9262 8.63316 36.1452 9.41421L24.6279 20.9315L24.6136 20.9456C24.0493 21.4988 23.2905 21.8087 22.5002 21.8087C21.7099 21.8087 20.9511 21.4988 20.3867 20.9456L20.3725 20.9315L16.3848 16.9438L9.64517 23.6834C8.86412 24.4645 7.59779 24.4645 6.81674 23.6834C6.03569 22.9024 6.03569 21.6361 6.81674 20.855L14.2571 13.4146L14.2713 13.4006C14.8357 12.8474 15.5945 12.5375 16.3848 12.5375C17.1751 12.5375 17.9339 12.8474 18.4983 13.4006L18.5125 13.4146L22.5002 17.4023L33.3167 6.58579C34.0978 5.80474 35.3641 5.80474 36.1452 6.58579Z'
        fill={color}
      />
    </svg>
  );
};

export default UpGraphicIcon;