import React from 'react';

const WarningModernIcon = ({ color, size = '14px' }) => (
  <svg width={size} height={size} viewBox='0 0 41 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M40.6127 33.2892L23.3528 1.68079C22.1295 -0.560263 18.8705 -0.560263 17.6461 1.68079L0.387296 33.2892C0.121671 33.7757 -0.0115009 34.3218 0.000778477 34.8742C0.0130579 35.4267 0.170369 35.9665 0.457358 36.4411C0.744346 36.9156 1.15121 37.3087 1.63822 37.5819C2.12524 37.8552 2.67577 37.9992 3.23609 38H37.7588C38.3196 38.0001 38.8708 37.8567 39.3585 37.5838C39.8462 37.3109 40.2538 36.9179 40.5413 36.4432C40.8289 35.9685 40.9867 35.4283 40.9992 34.8755C41.0117 34.3227 40.8785 33.7761 40.6127 33.2892ZM20.5 33.1064C20.0994 33.1064 19.7078 32.9893 19.3747 32.7698C19.0416 32.5503 18.782 32.2384 18.6287 31.8734C18.4754 31.5085 18.4353 31.1069 18.5135 30.7194C18.5916 30.3319 18.7845 29.976 19.0678 29.6967C19.3511 29.4174 19.712 29.2271 20.1049 29.1501C20.4978 29.073 20.905 29.1126 21.2751 29.2637C21.6452 29.4149 21.9615 29.6709 22.1841 29.9994C22.4067 30.3279 22.5254 30.714 22.5254 31.1091C22.5254 31.6388 22.3121 32.1468 21.9322 32.5214C21.5524 32.896 21.0372 33.1064 20.5 33.1064ZM22.6996 13.0179L22.1183 25.2018C22.1183 25.6256 21.9476 26.0321 21.6437 26.3317C21.3399 26.6314 20.9277 26.7997 20.498 26.7997C20.0682 26.7997 19.6561 26.6314 19.3522 26.3317C19.0483 26.0321 18.8776 25.6256 18.8776 25.2018L18.2963 13.0229C18.2832 12.7318 18.3297 12.4412 18.433 12.1683C18.5363 11.8953 18.6942 11.6457 18.8974 11.4342C19.1006 11.2228 19.3449 11.0538 19.6157 10.9374C19.8865 10.8211 20.1783 10.7596 20.4737 10.7568H20.4949C20.7923 10.7567 21.0867 10.816 21.3602 10.9311C21.6338 11.0462 21.8808 11.2148 22.0865 11.4266C22.2922 11.6385 22.4521 11.8892 22.5568 12.1638C22.6615 12.4383 22.7087 12.7309 22.6956 13.0239L22.6996 13.0179Z'
      fill={color}
    />
    <path
      d='M22.6994 13.0188L22.1181 25.2028C22.1181 25.6266 21.9474 26.033 21.6435 26.3327C21.3396 26.6324 20.9275 26.8007 20.4978 26.8007C20.068 26.8007 19.6559 26.6324 19.352 26.3327C19.0481 26.033 18.8774 25.6266 18.8774 25.2028L18.2961 13.0238C18.283 12.7328 18.3295 12.4422 18.4328 12.1692C18.5361 11.8963 18.694 11.6467 18.8972 11.4352C19.1004 11.2237 19.3446 11.0548 19.6155 10.9384C19.8863 10.822 20.1781 10.7606 20.4735 10.7578H20.4947C20.7921 10.7577 21.0865 10.8169 21.36 10.932C21.6336 11.0472 21.8806 11.2157 22.0863 11.4276C22.2919 11.6395 22.4519 11.8902 22.5566 12.1647C22.6613 12.4392 22.7085 12.7318 22.6954 13.0248L22.6994 13.0188Z'
      fill='white'
    />
    <path
      d='M20.4998 33.1074C20.0992 33.1074 19.7076 32.9903 19.3745 32.7708C19.0414 32.5513 18.7818 32.2394 18.6285 31.8744C18.4752 31.5094 18.4351 31.1078 18.5133 30.7204C18.5914 30.3329 18.7843 29.977 19.0676 29.6977C19.3508 29.4183 19.7117 29.2281 20.1046 29.151C20.4975 29.074 20.9048 29.1135 21.2749 29.2647C21.645 29.4159 21.9613 29.6719 22.1839 30.0004C22.4064 30.3288 22.5252 30.715 22.5252 31.11C22.5252 31.6398 22.3118 32.1478 21.932 32.5224C21.5521 32.897 21.037 33.1074 20.4998 33.1074Z'
      fill='white'
    />
  </svg>
);

export default WarningModernIcon;
