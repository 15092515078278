import React from 'react';

export default function TickIcon({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M4760 4415 c-47 -22 -270 -241 -1600 -1570 l-1545 -1545 -605 604
c-333 332 -621 612 -640 622 -49 26 -171 26 -223 0 -54 -27 -96 -72 -125 -135
-30 -65 -27 -142 8 -212 31 -62 1407 -1438 1469 -1469 25 -12 70 -25 102 -27
47 -5 66 -1 115 21 53 24 214 181 1706 1674 1493 1492 1650 1653 1674 1706 22
49 26 68 21 116 -12 132 -109 228 -242 237 -49 3 -70 0 -115 -22z'
        />
      </g>
    </svg>
  );
}
