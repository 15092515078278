import React from 'react';
import './timer.css';
import { ClockDanger, ClockNormal, ClockWarning, CircleIcon, CircleFullIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

const Timer = ({ expirationTime, color, status }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.timer' });
  const renderColor = () => {
    if (status === 'EXPIRED') return <CircleIcon size='20px' />;
    if (color === 'blue') return <ClockNormal size='20px' />;
    if (color === 'yellow') return <ClockWarning size='20px' />;
    if (color === 'yellow-light') return <ClockDanger size='20px' />;
    if (color === 'expired') return <CircleIcon size='20px' />;
    if (color === 'blue-full') return <CircleFullIcon size='20px' color='#1B6EA8' />;
  };

  const isExpired = status === 'EXPIRED';

  return (
    <div className={`expiration-container px-1 clock-${!isExpired ? color : ''}`}>
      <div className='row flex-nowrap align-items-center'>
        <div className='col-auto'>{renderColor()}</div>
        <div className={`row fw-600 pl-0 col-auto bold clock-text-${!isExpired ? color : ''}`}>
          <span className='col-12' style={{ fontSize: '12px' }}>
            {!isExpired ? expirationTime : '-- : --'}
          </span>
          <span className='col-12' style={{ fontSize: '10px' }}>
            {t('expiration')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Timer;
