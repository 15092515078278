export const getReservationStatus = (option, t) => {
  switch (option) {
    case 'RESERVATIONS_COMPLETED':
      return t('reservationStatus.reservationCompleted');
    case 'RESERVATIONS_INCOMPLETED':
      return t('reservationStatus.reservationIncompleted');
    default:
      return null;
  }
};
