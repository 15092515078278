import React, { useEffect, useState } from 'react';
import './buttonFilter.css';
import Button from '../button/button';
import Popup from 'reactjs-popup';
import { FwAddIcon, SearchIcon } from '../../icons';
import Input from '../input/input';
import Loading from '../loading/loading';
import { Show } from '../../../components';
import { useTranslation } from 'react-i18next';

const NameEmailFilter = ({
  filterName,
  filterOptions = [],
  callback,
  isSearchable = false,
  wordsToShow = 1,
  onClick,
  loading = false,
  parentsOptions = [],
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.buttonFilter' });
  const [localFilterOptions, setLocalFilterOptions] = useState([]);
  const [isApplying, setIsApplying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCheckboxChange = (option) => {
    const updatedOptions = localFilterOptions.map((item) => {
      if (item.value === option) {
        return { ...item, isActive: !item.isActive };
      }
      return item;
    });
    setLocalFilterOptions(updatedOptions);
  };
  const filterOptionsBySearchTerm = () => {
    return localFilterOptions.filter(
      (option) =>
        option.label?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        option.email?.toLowerCase().includes(searchTerm?.toLowerCase()),
    );
  };
  const renderSelectedOptions = () => {
    const selectedLabels = localFilterOptions
      .filter((option) => option.isActive)
      .map((option) => option.label);

    if (selectedLabels.length <= wordsToShow) {
      return selectedLabels.join(', ');
    } else {
      const lastLabel = selectedLabels[selectedLabels.length - 1];
      return `${lastLabel}, ${t('and')} ${selectedLabels.length - 1} ${t('more')}`;
    }
  };
  const handleApplyClick = (close) => {
    setIsApplying(true);
    callback && callback(localFilterOptions);
    close();
  };
  const handleClear = (close) => {
    const newLocalState = localFilterOptions.map((item) => ({ ...item, isActive: false }));
    setLocalFilterOptions(newLocalState);
    callback && callback(newLocalState);
    close();
  };
  const renderOptions = () => {
    const hasSelectedParents = parentsOptions.length > 0;

    // Check if any options are available
    const filteredOptions = filterOptionsBySearchTerm();

    // Check if any options are available after filtering
    if (filteredOptions.length === 0) {
      return <p>{t('noInfo')}</p>;
    }

    return filteredOptions.map((option, i) => {
      // Render the item if there are no selected parent options or if the option's category is included in the selected parent options
      if (!hasSelectedParents || (option?.category && parentsOptions.includes(option?.category))) {
        return (
          <label
            key={option.value}
            className={`d-flex align-items-center ${i === 0 ? '' : 'border-top'} py-1`}
          >
            <input
              type='checkbox'
              value={option.value}
              onChange={() => handleCheckboxChange(option.value)}
              checked={option.isActive}
              className='mr-1'
            />
            <div className='text-truncate ml-1'>
              <div>{option.label}</div>
              {option.email}
            </div>
          </label>
        );
      } else {
        return null;
      }
    });
  };

  const options = renderOptions();

  useEffect(() => {
    if (!isApplying && !isOpen) {
      setLocalFilterOptions([...filterOptions]);
    }
  }, [isApplying, isOpen]);
  useEffect(() => {
    setLocalFilterOptions(filterOptions);
  }, [filterOptions]);

  return (
    <>
      <Popup
        keepTooltipInside={'.App'}
        trigger={
          <div>
            <div className='btnFilterDashed text-nowrap' onClick={onClick}>
              <div
                className='d-flex mr-1 align-items-center justify-content-center'
                style={{
                  borderRadius: '50%',
                  border: '1px solid var(--color-border-table)',
                  paddingLeft: '2px',
                  width: '22px',
                  height: '22px',
                  background: '#F9FAFB',
                }}
              >
                <FwAddIcon color='#667085' fontSize='10px' />
              </div>
              <p className='p-0 m-0'>{filterName}</p>
              {localFilterOptions.some((option) => option.isActive) && (
                <span className='mx-1'>|</span>
              )}
              <p className='p-0 m-0 option-selected text-truncate'>{renderSelectedOptions()}</p>
            </div>
          </div>
        }
        nested
        arrow={false}
        onOpen={() => {
          setIsApplying(false);
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
          setSearchTerm('');
        }}
      >
        {(close) => (
          <div className={`filter-modal isOpen`} onClick={(e) => e.stopPropagation()}>
            <h4 className='ctm-text-medium'>{t('title')}</h4>
            <Show.When isTrue={isSearchable}>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                name={'search'}
                value={searchTerm}
                onChangeValue={(e) => setSearchTerm(e.target.value)}
                placeholder={t('search')}
                autoComplete='off'
              />
            </Show.When>
            <Show>
              <Show.When isTrue={loading}>
                <Loading global={false} />
              </Show.When>
              <Show.When isTrue={localFilterOptions.length > 0 && !loading}>
                <ul className='d-flex flex-column m-0 p-0 content-filter'>
                  {Array.isArray(options) && options.every((option) => option === null)
                    ? 'No hay informacion dispoible'
                    : renderOptions()}
                </ul>
                <div className='w-100 d-flex justify-content-center pt-2'>
                  <Button
                    size={'small'}
                    onClick={() => handleClear(close)}
                    className='btn-wellet-secondary'
                    text={t('delete')}
                  />
                  <Button
                    size={'small'}
                    onClick={() => handleApplyClick(close)}
                    className='btn-wellet-primary  ml-2'
                    text={t('apply')}
                  />
                </div>
              </Show.When>
              <Show.Else>{t('noInfo')}</Show.Else>
            </Show>
          </div>
        )}
      </Popup>
    </>
  );
};

export default NameEmailFilter;
