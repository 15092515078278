import React, { useState } from 'react';
import { ClipboardIcon } from '../../icons';
import './clipboard.css';
import { useTranslation } from 'react-i18next';

const CopyClipboard = ({ text, showCopy = true, showText = false, align = 'start', textStyle, textBox }) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'components.clipboard' });

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
    }
  };

  return (
    <div
      className={`d-flex justify-content-${align} flex-column align-items-${align} position-relative`}
    >
      <div
        className={`clipboard-container cursor-pointer ${copied ? 'copied' : ''}`}
        onClick={handleCopyClick}
      >
        {showText && (
          <div
            className='font-weight-bold mr-1'
            style={{ fontSize: '26px', color: 'var(--color-text-sidebar)', ...textBox }}
          >
            {text}
          </div>
        )}
        <div>
          <ClipboardIcon />
        </div>
      </div>
      {copied && showCopy && <div className='copied-text mt-1'>{t('copy')}</div>}
    </div>
  );
};

export default CopyClipboard;
