import { MenuItem } from '../../common/menuHeader/menuHeader';

import {
  SidebarDashboard,
  BookingCenter,
  AdvantageNetwork,
  SidebarSettingsData,
  SoldOut,
  Booking,
  Commissions,
  Reports,
  Disputes,
  Checkin,
  MoreSettings,
  LessSettings,
  PaymentSettings,
  PaymentLessSettings,
  PaymentCentral,
  Billing,
  ProcessorMoreSettings,
  ProcessorLessSettings,
  ProcessorData,
} from '../../../components/sideBar/sidebarData';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import useUser from '../../../hooks/useUser';
import { setSelectedOrg } from '../../../actions';
import { hasAllowedRoles } from '../../../helpers/roles';
import ToggleableMenu from '../togglableMenu/togglabeMenu';
import { useTranslation } from 'react-i18next';
import { paymentRoutesConfig } from '../../../data/withdrawals';
import { canAccessOrView } from '../../../helpers/permissions';
import useGenericTranslation from '../../../hooks/useGenericTranslation';
import { useQuery } from 'react-query';
import { getSidebarCounts } from '../../../services';
import { WelletContext } from '../../../context/wellet/welletContext';
import { defaultQueryOptions } from '../../../utils/defaultQueryOptions';

const SidebarSection = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.sideBar' });
  const { isSuperAdmin } = useUser();
  const organization = useSelector((state) => state.app.currentOrganization);
  const paymentMethods = useSelector((state) => state.app.paymentMethods);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const martketId = useSelector((state) => state.app.selectedOrganization.id);
  const { user } = useUser();
  const location = useLocation();
  const dispacth = useDispatch();
  const { getClientTranslation } = useGenericTranslation();
  const { hasMarketingCampaign, entityId } = useSelector((state) => state.app);
  const welletContext = useContext(WelletContext);

  const countsQuery = useQuery({
    queryKey: ['count-sidebar', organization.marketId],
    queryFn: () => getSidebarCounts(welletContext, organization.marketId),
    enabled: !!organization.marketId,
    refetchInterval: 300000,
    ...defaultQueryOptions,
  });

  const renderConfigurations = hasAllowedRoles(user.roles, [
    'superadministrator',
    'marketadmin',
    'productloader',
    'productpriceloader',
    'marketing'
  ]);
  const renderPayments =
    hasAllowedRoles(user.roles, [
      'superadministrator',
      'admin',
      'marketadmin',
      'auditor',
      'auditor-onlyview',
      'finance',
    ]) && organization.isMarket;
  const renderProcessor =
    hasAllowedRoles(user.roles, [
      'superadministrator',
      'marketadmin',
      'wellet_finance',
      'finance',
    ]) && organization.isMarket;

  const getCountsQuery = (item) => {
    switch (item) {
      case 'countAdvantageNetwork':
        return countsQuery.data?.data?.countAdvantageNetwork ?? false;
      default:
        return false;
    }
  };

  const renderMenuItems = (menuItems, disabledHover = false) => {
    return menuItems.map((item, index) => {
      const isMarket = organization.isMarket === item.market;
      const isOrg = organization.isMarket !== item.organization;

      if ((isMarket || isOrg) && canAccessOrView(user.roles, item.rol, item.title)) {
        if (item.hidden) {
          return null;
        }
        return (
          <MenuItem
            item={item}
            key={index}
            isCollapsable={false}
            title={item.isGeneric ? getClientTranslation(`${item.title}.plural`) : t(item.title)}
            path={item.path === '/reservations-schedule' ? item.path + `/${martketId}` : item.path}
            disabled={item.disabled}
            isSelected={item.path === location.pathname || location.pathname.includes(item.subpath)}
            icon={item.icon}
            disabledHover={disabledHover}
            notification={item.notification ? getCountsQuery(item.notification) : null}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (
      isSuperAdmin &&
      selectedOrganization &&
      selectedOrganization?.id !== organization.id &&
      location.pathname === '/'
    ) {
      dispacth(setSelectedOrg(organization));
    }
  }, [location.pathname]);

  const payments = paymentMethods
    .map((method) => ({
      ...paymentRoutesConfig[method],
      rol: ['superadministrator', 'admin', 'marketadmin', 'auditor', 'auditor-onlyview', 'finance'],
      market: true,
      organization: false,
    }))
    .filter(Boolean);

  return (
    <div className='sidebar-section sidebar-data-section'>
      <div className='nav nav-sidebar mt-3'>
        {/* DASHBOARD */}
        <div className='sidebar-space-y'>{renderMenuItems([SidebarDashboard])}</div>

        {/* RESERVAS */}
        <div className='sidebar-space-y'>
          {renderMenuItems([BookingCenter])}
          {renderMenuItems([Checkin])}
          {renderMenuItems([SoldOut])}
          {renderMenuItems([AdvantageNetwork])}
          {renderMenuItems([Booking])}
          {renderMenuItems([Commissions])}
          {renderMenuItems([Disputes])}
        </div>

        <div className='sidebar-space-y'>
          {renderMenuItems([PaymentCentral])}
          {renderPayments && payments?.length > 0 && (
            <ToggleableMenu
              title={renderMenuItems([PaymentSettings], true)}
              secondaryTitle={renderMenuItems([PaymentLessSettings], true)}
            >
              {renderMenuItems(payments)}
            </ToggleableMenu>
          )}
        </div>

        <div className='sidebar-space-y'>
          {renderProcessor && (
            <ToggleableMenu
              title={renderMenuItems([ProcessorMoreSettings], true)}
              secondaryTitle={renderMenuItems([ProcessorLessSettings], true)}
            >
              {renderMenuItems(ProcessorData)}
            </ToggleableMenu>
          )}
        </div>

        <div className='sidebar-space-y'>
          {renderMenuItems([Billing])}
          {renderMenuItems([Reports])}
          {renderConfigurations && (
            <ToggleableMenu
              title={renderMenuItems([MoreSettings], true)}
              secondaryTitle={renderMenuItems([LessSettings], true)}
            >
              {renderMenuItems(SidebarSettingsData(hasMarketingCampaign || entityId === 1), false)}
            </ToggleableMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarSection;
