import React from 'react';
import './table.css';

const CommissionTable = ({ data, columns, renderRow, renderHeader }) => {
  const dataToDisplay = data;

  return (
    <div className='w-100'>
      <div className='datatable-scroll '>
        <table
          className='table datatable-basic dataTable no-footer'
          role='grid'
          aria-describedby='DataTables_Table_0_info'
        >
          <thead className=''>
            <tr className={`row-header with-border`}>{renderHeader()}</tr>
          </thead>
          <tbody>
            {dataToDisplay && dataToDisplay?.length > 0 ? (
              dataToDisplay?.map((rowData, index) => renderRow(rowData, index))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className='text-center'
                  style={{ color: 'var(--color-subtitle)' }}
                >
                  No hay información disponible.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommissionTable;
