import React, { useContext, useState } from 'react';
import { FilterCard, PageHeader, Show, Table } from '../../components';
import { WelletContext } from '../../context/wellet/welletContext';
import { Loading, Modal, PageError } from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getCurrentReservationReviewing, getPendingReservations } from '../../services';
import { useCreateBreadCrumbs, usePagination, useUser } from '../../hooks';
import useDebounce from '../../hooks/useDebounce';
import { getLocale } from '../../helpers/dates';
import ReservationRow from './reservationRow';
import { getQueryParam, setQueryParams } from '../../helpers/query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { hasAllowedRoles } from '../../helpers/roles';
import useGenericTranslation from '../../hooks/useGenericTranslation';

// (04-16-24) To Do: Al inciar el componente ocurren 3 re-renders, y entre cada llamada a la API se producen 2 re-renders. Y cada N (12 api calls ejemplo) llamadas ocurren 3 re-renders.

// (15-05-24) To Do: Si el endpoint falla, sigue intentando por el "  refetchIntervalInBackground: true". Como controlarlo?

const ReservationsPending = () => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'screens.requests' });
  const organization = useSelector((state) => state.app.currentOrganization);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const navigate = useNavigate();
  const location = useLocation();
  const [reservation, setReservation] = useState(null);
  const [canView, setCanView] = useState('close');
  const [value, setValue] = useState('');
  const { getClientTranslation } = useGenericTranslation();
  const filterByUrl = getQueryParam('filterBy', location);
  const [filter, setFilter] = useState(filterByUrl ? filterByUrl.split('-') : ['PENDING']);

  const debouncedSearch = useDebounce(value, 700);
  const queryClient = useQueryClient();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  const { roles } = useUser();

  const isAllowed = hasAllowedRoles(roles, [
    'superadministrator',
    'admin',
    'marketadmin',
    'reservationcenter',
    'callcenterlead',
  ]);

  // Functions
  const onFilterClick = (filter) => {
    setFilter(filter);
    handlePageChange(1);
    const filterParams = filter.map((f) => `${f}`).join('-');
    setQueryParams({ filterBy: filterParams, page: 1 }, navigate, location);
  };
  const handleReservation = (reservation) => {
    setReservation(reservation);
    reviewingMutation.mutate({
      id: reservation.id,
      save: true,
      status: reservation.status,
    });
  };

  //onClick={() => ( onClick(reservations) : {})}

  // Main API Query
  const reservationsQuery = useQuery({
    queryKey: ['reservations-pending', organization.id, limit, skip, debouncedSearch, filter],
    queryFn: () =>
      getPendingReservations(
        welletContext,
        limit,
        skip,
        organization.marketId,
        Number(organization.id),
        { from: null, to: null },
        debouncedSearch.trim(),
        filter,
      ),
    onSuccess: (data) => {
      setTotalAndReset(data?.data.reservations?.totalRegisters);
      queryClient.setQueryData('counters', {
        cancelledCount: data?.data.cancelledCount,
        pendingCount: data?.data.pendingCount,
        unregisteredDeposit: data?.data.unregisteredDeposit,
        rejectedCount: data?.data.rejectedCount,
      });
    },
    ...defaultQueryOptions,
    keepPreviousData: true,
    refetchIntervalInBackground: true,
    refetchInterval: 5000,
  });
  // Query Mutation
  const reviewingMutation = useMutation({
    mutationFn: (data) => {
      return getCurrentReservationReviewing(welletContext, data.save, data.id);
    },
    onSuccess: (data, variables) => {
      const modalType = variables.status === 'CANCELLED' ? 'openCancelled' : 'openReview';
      if (data?.data) {
        navigate(`/reservations-pending/${variables.id}`);
      }
      setCanView(data?.data ? modalType : 'openPermission');
    },
  });

  const headerAdmin =
    filter[0] === 'CANCELLED' || filter[0] === 'CONFIRMED'
      ? [
          { title: '', value: '', filterable: false },
          { title: getClientTranslation('title.singular'), value: '', filterable: false },
          { title: t('table.schedule'), value: '', filterable: false, center: true },
          { title: t('table.client'), value: '', filterable: false },
          { title: t('table.pax'), value: '', filterable: false },
          { title: t('table.concierge'), value: '', filterable: false },
          { title: t('table.type'), value: '', filterable: false },
          { title: t('table.status'), value: '', filterable: false },
        ]
      : [
          { title: t('table.expiresIn'), value: '', filterable: false },
          { title:  getClientTranslation('title.singular'), value: '', filterable: false },
          { title: t('table.schedule'), value: '', filterable: false, center: true },
          { title: t('table.client'), value: '', filterable: false },
          { title: t('table.pax'), value: '', filterable: false },
          { title: t('table.concierge'), value: '', filterable: false },
          { title: t('table.type'), value: '', filterable: false },
          { title: t('table.status'), value: '', filterable: false },
        ];
  useCreateBreadCrumbs([{ name: t('breadcumbs') }]);

  return (
    <>
      <PageHeader title={t('title')} marginBottom={false} />
      <Show>
        <Show.When isTrue={reservationsQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={reservationsQuery.isError}>
          <PageError />
        </Show.When>

        <Show.Else>
          <div className='row px-1 my-3'>
            <FilterCard
              category={t('cardFilter.confirm')}
              quantity={queryClient?.getQueryData('counters')?.pendingCount}
              onClick={() => onFilterClick(['PENDING'])}
              isSelected={filter[0] === 'PENDING'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.cancel')}
              quantity={queryClient?.getQueryData('counters')?.cancelledCount}
              onClick={() => onFilterClick(['CANCELLED'])}
              isSelected={filter[0] === 'CANCELLED'}
              className='reservations col'
            />
            <FilterCard
              category={t('cardFilter.deposit')}
              quantity={queryClient?.getQueryData('counters')?.unregisteredDeposit}
              onClick={() => onFilterClick(['CONFIRMED'])}
              isSelected={filter[0] === 'CONFIRMED'}
              className='reservations col'
            />
          </div>

          <Show>
            <Show.When isTrue={reservationsQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <Table
                borderTop={false}
                data={reservationsQuery?.data?.data?.reservations?.items ?? []}
                hasPagination={false}
                totalP={totalItems}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                columns={headerAdmin}
                paddingTh={'padding-th-low'}
                renderRow={(reservations, index) => (
                  <ReservationRow
                    key={reservations.id}
                    index={index}
                    language={language}
                    reservations={reservations}
                    onClick={(reservations) => handleReservation(reservations)}
                  />
                )}
                onRowClick={(reservations) =>
                  reservations.status !== 'REJECTED' && isAllowed
                    ? handleReservation(reservations)
                    : {}
                }
                rowClass=''
              />

              <Show.When isTrue={canView === 'openPermission'}>
                <Modal
                  title={t('modal.title')}
                  open={canView === 'openPermission'}
                  onClose={() => {
                    setCanView('close');
                  }}
                  textCancel={t('modal.close')}
                >
                  <div className='py-4'>
                    {t('modal.firstText')} <strong>{reservation?.userReviewer?.fullName}</strong>{' '}
                    {t('modal.secondText')}
                  </div>
                </Modal>
              </Show.When>
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </>
  );
};

export default ReservationsPending;
