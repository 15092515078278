export const fetchActivities = async (
  welletContext,
  id = null,
  includeInactive = false,
  url = 'api/Activity/all',
) => {
  const body = {
    limit: null,
    skip: 0,
    organizationId: id,
    includeInactive,
  };
  try {
    const response = await welletContext.apis.admin.post(url, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};
export const getActivity = async (welletContext, id, url = `api/Activity`) => {
  try {
    const response = await welletContext.apis.admin.get(`${url}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};
export const postActivity = async (welletContext, organizationId, body) => {
  const url = `api/Activity`;
  return await welletContext.apis.admin.post(url, body);
};
export const putActivity = async (welletContext, body, id, url = 'api/Activity') => {
  return await welletContext.apis.admin.put(`${url}`, body);
};
export const getVenues = async (welletContext, organizationId, url = '/api/Venue/all') => {
  const body = {
    limit: null,
    skip: 0,
    organizationId: organizationId,
  };
  try {
    const response = await welletContext.apis.admin.post(url, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};
export const getTimeZones = async (welletContext, url = '/api/TimeZone/all') => {
  try {
    const response = await welletContext.apis.admin.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};
export const orderByPriority = async (
  welletContext,
  organizationId,
  body,
  url = '/api/Activity/priority',
) => {
  if (organizationId) {
    url += `/${organizationId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};
export const getTemplates = async (welletContext, url = 'api/Activity/purchaseTemplates') => {
  return await welletContext.apis.admin.get(`${url}`);
};
export const patchActivityStatus = async (welletContext, id, status) => {
  return await welletContext.apis.admin.patch(`api/Activity/${id}?isActive=${status}`);
};
export const validateSiteUrl = async (welletContext, id, siteUrl) => {
  return await welletContext.apis.admin.get(`api/Activity/${id}/siteUrl/exists?siteUrl=${siteUrl}`);
};
export const validateNameId = async (welletContext, name, id) => {
  let url = '';
  if (id) {
    url = `api/activity/validate-nameId?value=${name}&showId=${id}`;
  } else {
    url = `api/activity/validate-nameId?value=${name}`;
  }
  return await welletContext.apis.admin.get(url);
};
export const getSchedules = async (welletContext, id, type = 'RESTAURANT') => {
  const response = await welletContext.apis.admin.get(
    `/api/Schedule/${id}?reservationType=${type}`,
  );
  return response;
};

export const putSchedules = async (welletContext, id, body, url = '/api/Schedule/') => {
  const newUrl = url;
  const response = await welletContext.apis.admin.put(newUrl, body);
  return response;
};

export const getCallcenterSchedules = async (welletContext, id, url = `/api/callcenter/`) => {
  const newUrl = url + id;
  const response = await welletContext.apis.admin.get(newUrl);
  return response;
};

export const putCallcenterSchedules = async (welletContext, id, body, url = `/api/callcenter/`) => {
  const newUrl = url;
  const response = await welletContext.apis.admin.put(newUrl, body);
  return response;
};

export const uploadVideo = async (welletContext, body) => {
  const response = await welletContext.apis.admin.post(`api/activity/upload-video`, body);
  return response;
};
export const updateVideo = async (welletContext, body) => {
  const response = await welletContext.apis.admin.put(`api/activity/update-video`, body);
  return response;
};

export const deleteVideo = async (welletContext, id) => {
  const response = await welletContext.apis.admin.delete(`api/activity/delete-video/${id}`);
  return response;
};
