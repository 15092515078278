export const getMemberships = async (welletContext, id) => {
  try {
    const response = await welletContext.apis.admin.get(`api/salesCategory/${id}/config`);
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const putMemberships = async (welletContext, id, body) => {
  try {
    const response = await welletContext.apis.admin.put(`api/salesCategory/${id}/config`, body);
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const postMembershipLevel = async (welletContext, id, body) => {
  return await welletContext.apis.admin.post(`api/SalesCategory/market/${id}`, body);
};
export const putMembershipLevel = async (welletContext, id, body) => {
  return await welletContext.apis.admin.put(`api/SalesCategory/market/${id}`, body);
};
export const getMembershipLevel = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/SalesCategory/market/${id}`);
};
export const deleteLevel = async (welletContext, id, isSuper, marketId) => {
  let url = `/api/salescategory/${id}/market/${marketId}`;

  if (!isSuper) {
    url = `/api/salescategory/${id}`;
  }

  return await welletContext.apis.admin.delete(url);
};

export const hasAccounts = async (welletContext, id) => {
  let url = `api/mktEntity/any/${id}`;
  return await welletContext.apis.admin.get(url);
};
