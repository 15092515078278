import React, { useContext, useState } from 'react';
import { FilterCard, PageHeader, Show, Table, TableCell } from '../../components';
import { formatDateTranslation } from '../../helpers/dates';
import { Input, Loading, Money, PageError } from '../../uiComponents/common';
import {
  useCreateBreadCrumbs,
  useDebounce,
  useLocalStorage,
  usePagination,
  useUser,
} from '../../hooks';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getDisputeLiveFeed } from '../../services';
import { SearchIcon } from '../../uiComponents/icons';
import { useNavigate } from 'react-router-dom';
import { getReasons } from '../../helpers/reasons';
import { disputesBadge } from '../../helpers/status';
import DisputeTimer from '../../components/disputeTimer/disputeTimer';
import { hasAllowedRoles } from '../../helpers/roles';

const DisputesWellet = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.disputes' });
  const { t: translateReason } = useTranslation();
  const language = useSelector((state) => state.app.language);
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-disputes-wellet', {
    filterField: null,
    filter: ['DISPUTED'],
    page: 1,
  });
  const [filter, setFilter] = useState(filterLocalStorage.filter ?? ['DISPUTED']);
  const [filterField, setFilterField] = useState(filterLocalStorage.filterField);
  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);

  //Data filter
  const organizationId = organization.id === organization.marketId ? null : Number(organization.id);

  const selectedFormattedDates = {
    from: null,
    to: null,
  };
  const { roles } = useUser();
  const debouncedSearch = useDebounce(filterField, 500);
  // Main API Query
  const paymentsQuery = useQuery({
    queryKey: [
      'payments-livefeed',
      organization.marketId,
      selectedFormattedDates,
      limit,
      skip,
      debouncedSearch,
      filter,
    ],
    queryFn: () =>
      getDisputeLiveFeed(
        welletContext, // wellet context
        organization.marketId,
        organizationId, // organization id
        selectedFormattedDates,
        filter,
        limit, // limit
        skip, // skip
        debouncedSearch, // input,
        null,
        [], // id
        [],
        [], // cities
        [],
        [],
        [],
      ),

    onSuccess: (data) => {
      setTotalAndReset(data?.data?.customerPayments?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        page: currentPage,
        filterField,
      });
    },
    ...defaultQueryOptions,
    refetchInterval: false,
    keepPreviousData: true,
  });

  const payments = paymentsQuery?.data?.data || [];

  const headerAdmin = [
    { title: t('table.amount'), value: '', filterable: false },
    { title: t('table.status'), value: '', filterable: false },
    { title: t('table.reason'), value: '', filterable: false },
    { title: t('table.customer'), value: '', filterable: false },
    { title: t('table.sourceType'), value: '', filterable: false },
    { title: t('table.disputeDate'), value: '', filterable: false },
    { title: t('table.respondBy'), value: '', filterable: false },
  ];

  const handleFilter = (filter) => {
    setFilter(filter);
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, filter, page: 1 });
  };

  useCreateBreadCrumbs([{ name: t('title'), path: '/disputes' }]);

  const isWelletFinance = hasAllowedRoles(roles, ['wellet_finance']);

  return (
    <>
      <PageHeader title={t('title')} border={false} />
      <Show>
        <Show.When isTrue={paymentsQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={paymentsQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <Show>
            <Show.When isTrue={paymentsQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <div className='row px-1 my-3 '>
                <FilterCard
                  category={t('cardFilter.disputed')}
                  quantity={paymentsQuery?.data?.data?.disputedRegisters}
                  onClick={() => handleFilter(['DISPUTED'], null)}
                  className='reservations col'
                  isSelected={filter ? filter[0] === 'DISPUTED' : false}
                />
                <FilterCard
                  category={t('cardFilter.disputedNotAccepted')}
                  quantity={
                    isWelletFinance
                      ? paymentsQuery?.data?.data?.disputedNotAccepted
                      : paymentsQuery?.data?.data?.disputedNotAccepted +
                        paymentsQuery?.data?.data?.stripeReviewCount
                  }
                  onClick={() =>
                    isWelletFinance
                      ? handleFilter(['DISPUTED_NOT_ACCEPTED'])
                      : handleFilter(['DISPUTED_NOT_ACCEPTED', 'STRIPE_REVIEW'], null)
                  }
                  className='reservations col'
                  isSelected={filter ? filter[0] === 'DISPUTED_NOT_ACCEPTED' : false}
                />
                {isWelletFinance ? (
                  <FilterCard
                    category={t('cardFilter.disputedStripe')}
                    quantity={paymentsQuery?.data?.data?.stripeReviewCount}
                    onClick={() => handleFilter(['STRIPE_REVIEW'], null)}
                    className='reservations col'
                    isSelected={filter ? filter[0] === 'STRIPE_REVIEW' : false}
                  />
                ) : null}
                <FilterCard
                  category={t('cardFilter.disputedWinned')}
                  quantity={paymentsQuery?.data?.data?.disputedWinnedRegisters}
                  onClick={() => handleFilter(['DISPUTED_WINNED'], null)}
                  className='reservations col'
                  isSelected={filter ? filter[0] === 'DISPUTED_WINNED' : false}
                />
                <FilterCard
                  category={t('cardFilter.disputedLost')}
                  quantity={
                    paymentsQuery?.data?.data?.disputedLostRegister +
                    paymentsQuery?.data?.data?.deadLineCount
                  }
                  onClick={() => handleFilter(['DISPUTED_LOST', 'DEADLINE_MISSIED'], null)}
                  className='reservations col'
                  isSelected={filter ? filter[0] === 'DISPUTED_LOST' : false}
                />
              </div>
              <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
                <div className='col col-sm-auto align-items-center justify-content-center'>
                  <Input
                    icon={<SearchIcon color='currentColor' size='16px' />}
                    type={'search'}
                    customClass='mb-0'
                    name={'search'}
                    value={filterField}
                    onChangeValue={(e) => setFilterField(e.target.value)}
                    placeholder={t('search')}
                  />
                </div>
              </div>
              <Table
                data={payments?.customerPayments?.items}
                currentPage={currentPage}
                header='grey'
                hasPagination={false}
                onPageChange={(page) => handlePageChange(page)}
                totalP={totalItems}
                columns={headerAdmin}
                onRowClick={(item) => navigate(`/disputes/${item.id}`)}
                renderRow={(payment) => {
                  const { dateTime } = formatDateTranslation(
                    payment.dispute_due_by,
                    language,
                    'small',
                  );
                  const { date: disputeDate } = formatDateTranslation(
                    payment.disputeDate,
                    language,
                    'extraSmall',
                  );

                  return (
                    <>
                      <TableCell style={{ width: 'auto' }} type={'primary'}>
                        <div className='fw-600'>
                          {payment.transactionAmount ? (
                            <Money value={payment.transactionAmount} />
                          ) : (
                            '-'
                          )}
                        </div>
                      </TableCell>
                      <TableCell className={'fw-500 '}>
                        {payment.status === 'DISPUTED' && payment.dispute_due_by ? (
                          <DisputeTimer
                            showLoading
                            key={payment.id}
                            expiresIn={payment.due_By_Seconds}
                          />
                        ) : (
                          <>{disputesBadge(payment.status, translateReason, isWelletFinance)}</>
                        )}
                      </TableCell>
                      <TableCell className={'fw-500 text-wrap'} style={{ maxWidth: '200px' }}>
                        {payment.disputeDetail ? (
                          <div>{getReasons(payment.disputeDetail, translateReason)}</div>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell style={{ width: 'auto' }}>
                        {payment.customerEmail ? (
                          <div className='fw-500'>{payment.customerEmail}</div>
                        ) : null}
                        <div>{payment.customerName ?? '-'}</div>
                      </TableCell>
                      <TableCell style={{ width: 'auto' }}>
                        <div className='d-flex align-items-center'>
                          {payment.brand ? (
                            <img
                              style={{ height: '30px' }}
                              src={
                                'https://cdn1.wellet.fun/images/credit-card/' +
                                payment.brand?.toLocaleLowerCase() +
                                '-logo.png'
                              }
                              className='mr-1'
                              alt=''
                            />
                          ) : (
                            '-'
                          )}
                          {payment.last4 ? ` •••• ${payment.last4}` : null}
                          {payment.countryCode ? (
                            <div
                              className={`language-selector-flag language-selector-flag-${payment?.countryCode?.toLocaleLowerCase()}`}
                            ></div>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell className={'text-capitalize'} style={{ width: 'auto' }}>
                        {disputeDate ?? '-'}
                      </TableCell>
                      <TableCell style={{ width: 'auto' }}>
                        {payment.status === 'DISPUTED' ? dateTime ?? '-' : '-'}
                      </TableCell>
                    </>
                  );
                }}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </>
  );
};

export default DisputesWellet;
