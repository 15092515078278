import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  InputDate,
  Loading,
  PageError,
  Select,
  Tabs,
  Toast,
} from '../../uiComponents/common';
import { FwCalendarIcon, FwMasksIcon } from '../../uiComponents/icons';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation, useQuery } from 'react-query';
import {
  getAllShows,
  getProducts,
  getSoldoutSchedules,
  postProductAction,
  soldoutSingleSchedule,
} from '../../services';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useCreateBreadCrumbs, useGetRestaurants, useUser } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { PageHeader, Show } from '../../components';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const SoldOut = () => {
  const methods = useForm();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const organizationId = organization.id === organization.marketId ? null : Number(organization.id);
  const welletContext = useContext(WelletContext);
  const { user } = useUser();
  const { t } = useTranslation('translation', { keyPrefix: 'screens.soldOut' });
  const currentDate = methods.watch('activity-date');
  const currentRestaurant = methods.watch('restaurant');
  const { getClientTranslation } = useGenericTranslation();
  // Handle Tabs
  const tabTypes = {
    RESTAURANT: 'RESTAURANT',
    AREA: 'AREA',
    GROUP: 'GROUP',
  };
  const [activeTab, setActiveTab] = useState(tabTypes.RESTAURANT);
  const isRestaurant = user.roles.includes('restaurantvip');
  const isGroup = user.roles.includes('group');
  const tabsRestaurant = [
    {
      isActive: activeTab === tabTypes.RESTAURANT,
      title: getClientTranslation('title.singular'),
      type: tabTypes.RESTAURANT,
    },
    {
      isActive: activeTab === tabTypes.AREA,
      title: t('tabs.area'),
      type: tabTypes.AREA,
    },
  ];
  const tabGroup = [
    {
      isActive: activeTab === tabTypes.GROUP,
      title: t('tabs.group'),
      type: tabTypes.GROUP,
    },
  ];

  const tabs =
    isRestaurant && isGroup && (!isRestaurant || !isGroup)
      ? [...tabsRestaurant, ...tabGroup]
      : isRestaurant && !isGroup
      ? [...tabsRestaurant]
      : isGroup && !isRestaurant
      ? [{ ...tabGroup[0], isActive: true }]
      : [...tabsRestaurant, ...tabGroup];

  const activeFromArray = tabs?.find((t) => t.isActive === true)?.type;

  // Main API Query
  const schedulesQuery = useQuery({
    queryKey: ['restaurant', currentDate, currentRestaurant, activeFromArray],
    queryFn: () =>
      getSoldoutSchedules(welletContext, currentRestaurant?.value, currentDate, activeFromArray),
    ...defaultQueryOptions,
    refetchOnMount: true,
    enabled:
      !!currentDate &&
      !!currentRestaurant &&
      (activeFromArray === 'RESTAURANT' || activeFromArray === tabTypes.GROUP),
  });
  const getProductsQuery = useQuery({
    queryKey: ['productsShow', currentDate, currentRestaurant],
    queryFn: () => getProducts(welletContext, currentRestaurant?.value, currentDate),
    ...defaultQueryOptions,
    enabled: !!currentDate && !!currentRestaurant && activeTab === 'AREA',
  });
  // Hooks Query
  const restaurantsQuery = useGetRestaurants(organization, {
    queryKey: ['restaurantes', currentRestaurant, organization.marketId],
    queryFn: () => getAllShows(welletContext, organization.marketId, organizationId),
    keepPreviousData: true,
  });
  const restaurants = restaurantsQuery?.data?.data?.map((r) => ({ label: r.name, value: r.id }));

  // Query Mutation
  const soldoutAction = useMutation({
    mutationFn: (body) => {
      return soldoutSingleSchedule(welletContext, currentRestaurant?.value, body);
    },
    onError: (error) => {
      console.log('Desde on error', error);
    },
    onSuccess: () => {
      schedulesQuery.refetch();
    },
  });
  const soldoutProductAction = useMutation({
    mutationFn: (body) => {
      return postProductAction(welletContext, body);
    },
    onError: (error) => {
      console.log('Desde on error', error);
    },
    onSuccess: () => {
      getProductsQuery.refetch();
    },
  });

  const handleSoldout = (time) => {
    const body = {
      timeMinutesStart: time.timeMinutesStart,
      date: currentDate,
      reservationType: activeFromArray,
    };
    soldoutAction.mutate(body);
  };
  const handleTabs = (type) => {
    setActiveTab(type);
  };
  const handleSoldoutProduct = (product) => {
    const body = {
      productId: product.productId,
      date: currentDate,
      description: '',
    };
    soldoutProductAction.mutate(body);
  };

  useCreateBreadCrumbs([{ name: t('title') }]);

  return (
    <>
      <PageHeader title='Sold Out' marginBottom={false} />
      <Tabs onClick={handleTabs} options={tabs} />
      <FormProvider ider {...methods}>
        <form key={1}>
          <div className='row mt-3'>
            <div className='mx-0 col-6 col-md-4'>
              <Select
                options={restaurants}
                label={getClientTranslation('title.plural')}
                icon={<FwMasksIcon />}
                name={'restaurant'}
                placeholder={t('form.inputRestaurant.placeholder')}
              />
            </div>
            <div className='col-6 col-md-6 mb-3'>
              <InputDate
                label={t('form.inputDate.label')}
                name={'activity-date'}
                icon={<FwCalendarIcon />}
                placeholder={t('form.inputDate.placeholder')}
                minDate={new Date()}
                maxDate={new Date('01/01/2030')}
                dateText={true}
              />
            </div>
          </div>
          <div className='col-12 col-md-8 col-lg-10'>
            <Show>
              <Show.When isTrue={getProductsQuery.isLoading || schedulesQuery.isLoading}>
                <Loading />
              </Show.When>
              <Show.When isTrue={getProductsQuery.isError || schedulesQuery.isError}>
                <PageError />
              </Show.When>
              <Show>
                <Show.When
                  isTrue={
                    schedulesQuery?.data?.data?.length === 0 ||
                    getProductsQuery?.data?.data.length === 0
                  }
                >
                  <div style={{ color: '#000' }}>{t('noInfo')}</div>
                </Show.When>
                <Show.When
                  isTrue={
                    (activeTab === 'RESTAURANT' || activeTab === tabTypes.GROUP) &&
                    schedulesQuery?.data?.data !== null &&
                    schedulesQuery?.data?.data.length > 0
                  }
                >
                  {schedulesQuery?.data?.data.map((p) => (
                    <Button
                      key={p.timeMinutesStart + activeFromArray}
                      type='button'
                      size='medium'
                      onClick={() => handleSoldout(p)}
                      className={`p-1 m-2 btn-custom-white ${
                        p.isSoldOut ? 'btn-custom-soldout' : ''
                      }`}
                      style={{ minWidth: '80px' }}
                      text={p.timeStart}
                    >
                      {p.timeStart}
                    </Button>
                  ))}
                </Show.When>
                <Show.When
                  isTrue={
                    activeTab === 'AREA' &&
                    getProductsQuery?.data?.data !== null &&
                    getProductsQuery?.data?.data.length > 0
                  }
                >
                  {getProductsQuery?.data?.data.map((p) => (
                    <Button
                      key={p.productName + p.productId}
                      type='button'
                      size='medium'
                      onClick={() => handleSoldoutProduct(p)}
                      className={`p-1 m-2 btn-custom-white ${
                        p.hasSoldOut ? 'btn-custom-soldout' : ''
                      }`}
                      style={{ minWidth: '80px' }}
                      text={p.productName}
                    >
                      {p.productName}
                    </Button>
                  ))}
                </Show.When>
                <Show.When
                  isTrue={!getProductsQuery.isFetched && !schedulesQuery.isFetched}
                ></Show.When>
              </Show>
            </Show>
          </div>
        </form>
      </FormProvider>
      <Show.When isTrue={soldoutAction.isError || soldoutProductAction.isError}>
        <Toast type={'ERROR'} message={t('errors')} />
      </Show.When>
    </>
  );
};

export default SoldOut;
