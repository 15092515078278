export const getReasons = (reason, t) => {
  const reasons = {
    bank_cannot_process: t('functions.getReasons.bank_cannot_process'),
    check_returned: t('functions.getReasons.check_returned'),
    credit_not_processed: t('functions.getReasons.credit_not_processed'),
    customer_initiated: t('functions.getReasons.customer_initiated'),
    debit_not_authorized: t('functions.getReasons.debit_not_authorized'),
    duplicate: t('functions.getReasons.duplicate'),
    fraudulent: t('functions.getReasons.fraudulent'),
    general: t('functions.getReasons.general'),
    incorrect_account_details: t('functions.getReasons.incorrect_account_details'),
    insufficient_funds: t('functions.getReasons.insufficient_funds'),
    product_not_received: t('functions.getReasons.product_not_received'),
    product_unacceptable: t('functions.getReasons.product_unacceptable'),
    subscription_canceled: t('functions.getReasons.subscription_canceled'),
    unrecognized: t('functions.getReasons.unrecognized'),
  };
  return reasons[reason] || '-';
};
