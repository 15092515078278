import React, { useContext } from 'react';
import { Button, Modal } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { putConfirmPresent } from '../../services';
import { useTranslation } from 'react-i18next';

const ModalChecker = ({ open, onClose, handleSubmit, reservation, onSuccess }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalCheckers' });

  const confirmMutation = useMutation({
    mutationFn: () => {
      return putConfirmPresent(welletContext, reservation?.id);
    },
    onSuccess: () => onSuccess(),
  });

  const onSubmit = () => {
    onClose();
    return confirmMutation.mutate();
  };

  return (
    <>
      <Modal
        title={t('title')}
        open={open}
        onClose={onClose}
        closeOnDocumentClick={false}
        maxWidth='450px'
        closeIcon={true}
        handleSubmit={handleSubmit}
        customButtons={
          <>
            <div className='row justify-content-end px-2'>
              <Button
                text={t('cancel')}
                disabled={confirmMutation.isLoading}
                className={'col-auto btn-wellet-secondary'}
                size={'medium'}
                onClick={() => onClose()}
              />
              <Button
                text={t('confirm')}
                disabled={confirmMutation.isLoading}
                className={'col-auto ml-2 btn-wellet-primary'}
                size={'medium'}
                onClick={() => onSubmit()}
              />
            </div>
          </>
        }
      >
        <>
          <div className='text-left mt-2'>{t('ask')}</div>
        </>
      </Modal>
    </>
  );
};

export default ModalChecker;
