import React, { useContext, useState } from 'react';
import {
  AffiliateCard,
  Button,
  ButtonFilter,
  Input,
  Loading,
  PageError,
  Status,
} from '../../uiComponents/common';
import { FilterCard, PageHeader, Show, Table, TableCell } from '../../components';
import { useQuery } from 'react-query';
import { getAllConcierges, getAllRoles } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useUser from '../../hooks/useUser';
import useDebounce from '../../hooks/useDebounce';
import { SearchIcon } from '../../uiComponents/icons';
import { useCreateBreadCrumbs, useLocalStorage, usePagination } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { filtersFormat } from '../../helpers/format';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import ShowRoles from './components/showRoles/showRoles';

const Users = () => {
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'screens.users' });
  const { t: translateRoles } = useTranslation('translation', { keyPrefix: 'roles' });
  const { isSuperAdmin, isMarketAdmin } = useUser();
  const currentOrg = useSelector((state) => state.app.currentOrganization);

  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('filter-users', {
    filterStatus: [],
    filterField: '',
    roles: [],
    page: 1,
  });
  const [value, setValue] = useState(
    filterLocalStorage?.filterField ? filterLocalStorage?.filterField : '',
  );
  const [filterStatus, setFilterStatus] = useState(
    filterLocalStorage?.filterStatus ? filterLocalStorage.filterStatus : [],
  );

  const [filterRoles, setFilterRoles] = useState(
    filterLocalStorage?.roles ? filterLocalStorage?.roles : [],
  );

  const debouncedSearch = useDebounce(value, 500);

  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination();
  const location = useLocation();

  let limit = 10;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);
  const usersQuery = useQuery({
    queryKey: [
      'users',
      organization,
      currentOrg,
      debouncedSearch,
      limit,
      skip,
      filterStatus,
      filtersFormat(filterRoles),
    ],
    queryFn: () => {
      if (organization.isMarket && (isSuperAdmin || isMarketAdmin)) {
        return getAllConcierges(
          welletContext, // welletContext
          limit, // limit
          skip, // skip
          organization.marketId, // marketID
          organization.id, // Org ID
          debouncedSearch.trim(), //fieldFilter
          false, // includeInactive
          null, // kyc filter array
          false,
          filterStatus,
          filtersFormat(filterRoles),
        );
      } else if (isSuperAdmin) {
        return getAllConcierges(
          welletContext,
          limit,
          skip,
          organization.marketId,
          organization.id,
          debouncedSearch.trim(),
          false,
          null,
          false,
          filterStatus,
          filtersFormat(filterRoles),
        );
      } else {
        return getAllConcierges(
          welletContext,
          limit,
          skip,
          organization.marketId,
          organization.id,
          debouncedSearch,
          false,
          null,
          false,
          filterStatus,
          filtersFormat(filterRoles),
        );
      }
    },
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.users?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        filterStatus,
        filterField: value,
        page: currentPage,
        roles: filterRoles,
      });
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const rolesQuery = useQuery({
    queryKey: ['roles-security-team', i18n.language],
    queryFn: () => getAllRoles(welletContext),
    onSuccess: (data) => {
      let response = data?.data?.map((rol) => ({
        label: translateRoles(rol.prettyName),
        value: rol.name,
        isActive:
          filterLocalStorage?.roles?.length > 0
            ? filtersFormat(filterLocalStorage?.roles).includes(rol.name)
            : false,
      }));
      setFilterRoles(response);
    },
    ...defaultQueryOptions,
  });

  const navigateToProfile = (user) => {
    navigate(`edit/${user.sid}`, { state: location.pathname + location.search });
  };
  const headerAdmin = [
    { title: t('table.name'), value: '', filterable: false },
    { title: 'Roles', value: '', filterable: false },
    { title: t('table.phone'), value: '', filterable: false },
    { title: t('table.state'), value: '', filterable: false },
  ];

  useCreateBreadCrumbs([
    { name: t('breadcrumbs.prev'), path: '/settings' },
    { name: t('breadcrumbs.current') },
  ]);

  const handleFilter = (filter) => {
    setFilterStatus(filter);
    handlePageChange(1);
    setFilterLocalStorage({ ...filterLocalStorage, filterStatus: filter, page: 1 });
  };

  return (
    <>
      <PageHeader
        title={t('title')}
        endComponent={
          <Button
            size={'medium'}
            className={'btn-wellet-secondary'}
            text={`+ ${t('btnCreate')}`}
            onClick={() => navigate(`/settings/team-and-security/new-user`)}
          />
        }
      />
      <Show>
        <Show.When isTrue={usersQuery.isLoading}>
          <Loading />
        </Show.When>
        <Show.When isTrue={usersQuery.isError}>
          <PageError />
        </Show.When>
        <Show.Else>
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('cardFilter.total')}
              onClick={() => handleFilter([], null)}
              className='col'
              showQuantity={false}
              isSelected={filterStatus.length === 0}
            />
            <FilterCard
              category={t('cardFilter.actives')}
              onClick={() => handleFilter(['ENABLED'], null)}
              isSelected={filterStatus.length > 0 && filterStatus[0] === 'ENABLED'}
              showQuantity={false}
              className='col'
            />
            <FilterCard
              category={t('cardFilter.inactives')}
              onClick={() => handleFilter(['DISABLED'], null)}
              isSelected={filterStatus.length > 0 && filterStatus[0] === 'DISABLED'}
              className='col'
              showQuantity={false}
            />
            <FilterCard
              category={t('cardFilter.deleted')}
              onClick={() => handleFilter(['DELETED'], null)}
              isSelected={filterStatus.length > 0 && filterStatus[0] === 'DELETED'}
              className='col'
              showQuantity={false}
            />
          </div>
          <div className='d-flex justify-content-center justify-content-sm-start my-3 align-items-center'>
            <div className='col col-sm-auto'>
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                name={'search'}
                value={value}
                onChangeValue={(e) => setValue(e.target.value)}
                placeholder={t('search')}
              />
            </div>
            <div className='row'>
              <div className='col-auto mb-1  pl-3 justify-content-center'>
                <ButtonFilter
                  isSearchable={true}
                  filterName={t('buttonFilters.roles')}
                  filterOptions={filterRoles}
                  callback={setFilterRoles}
                  error={rolesQuery.isError}
                  loading={rolesQuery.isLoading}
                />
              </div>
            </div>
          </div>
          <Show>
            <Show.When isTrue={usersQuery.isPreviousData}>
              <div className='my-4 py-4'>
                <Loading global={false} />
              </div>
            </Show.When>
            <Show.Else>
              <Table
                data={usersQuery?.data?.data?.users?.items ?? []}
                hasPagination={false}
                totalP={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                columns={headerAdmin}
                renderRow={(user) => (
                  <>
                    <TableCell
                      style={{ width: 'auto', height: '100%' }}
                      className='d-flex flex-column'
                    >
                      <AffiliateCard
                        size={'40px'}
                        isVerified={user.kycStatus === 'APPROVED'}
                        url={user.profilePic}
                        name={user.name}
                        email={user.email}
                      />
                    </TableCell>
                    <TableCell style={{ width: 'auto' }}>
                      <ShowRoles roles={user?.prettyRoles ? user?.prettyRoles : []} name={user.name} />
                    </TableCell>
                    <TableCell type={'primary'}>{user.phone ?? '-'}</TableCell>
                    <TableCell>
                      <Status
                        status={user.isActive ? true : false}
                        text={user.isActive ? t('active') : t('inactive')}
                      />
                    </TableCell>
                  </>
                )}
                onRowClick={(user) => navigateToProfile(user)}
              />
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    </>
  );
};

export default Users;
