import React, { useContext, useEffect, useState } from 'react';
import { PageHeader, Show, Table, TableCell, TableRow } from '../../components';
import {
  Loading,
  Money,
  OrganizationCard,
  PageError,
  Status,
  Tabs,
} from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../context/wellet/welletContext';
import { useCreateBreadCrumbs, useLocalStorage, useOrderBy, usePagination } from '../../hooks';
import { useQuery } from 'react-query';
import {
  getDetailBillingByComission,
  getDetailBillingByRest,
} from '../../services/billingServices';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDateTranslation, getLocale } from '../../helpers/dates';
import useGenericTranslation from '../../hooks/useGenericTranslation';

const BillingSummary = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'screens.billingSummary' });
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const { id } = useParams();
  const tabelItems = 10;
  const [filterLocalStorage, setFilterLocalStorage] = useLocalStorage('billing-summary', {
    page: 1,
    pageRest: 1,
  });

  const { getClientTranslation } = useGenericTranslation();

  // Handle Tabs
  const tabTypes = {
    RESTAURANTS: 'RESTAURANTS',
    CONCIERGES: 'CONCIERGES',
  };
  const [activeTab, setActiveTab] = useState(tabTypes.CONCIERGES);
  const tabs = [
    {
      isActive: activeTab === tabTypes.CONCIERGES,
      title: t('table.commissions.title'),
      type: tabTypes.CONCIERGES,
    },
    {
      isActive: activeTab === tabTypes.RESTAURANTS,
      title: getClientTranslation('title.plural'),
      type: tabTypes.RESTAURANTS,
    },
  ];

  // Comissions Hooks
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    filterLocalStorage.page,
    tabelItems,
  );
  const { orderBy, orderType, handleColumnClick } = useOrderBy();
  let limit = tabelItems;
  let skip = 10 * ((currentPage ? currentPage : 1) - 1);
  // Restaurantes Hooks
  const {
    currentPage: currentPageRest,
    totalItems: totalItemsRest,
    handlePageChange: handlePageChangeRest,
    setTotalAndReset: setTotalAndResetRest,
  } = usePagination(filterLocalStorage.pageRest, tabelItems);
  const {
    orderBy: orderByRest,
    orderType: orderTypeRest,
    handleColumnClick: handleColumnClickRest,
  } = useOrderBy();
  let limitRest = tabelItems;
  let skipRest = 10 * ((currentPageRest ? currentPageRest : 1) - 1);

  // Main Query
  const billingComissionSummaryQuery = useQuery({
    queryKey: ['billing-comission', id, limit, skip, orderBy, orderType, activeTab],
    queryFn: () => getDetailBillingByComission(welletContext, id, limit, skip, orderBy, orderType),
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.list?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        page: currentPage,
      });
    },
    ...defaultQueryOptions,
    keepPreviousData: true,
    enabled: activeTab === 'CONCIERGES',
  });
  const billingRestauranteSummaryQuery = useQuery({
    queryKey: ['billing-rest', id, limitRest, skipRest, orderByRest, orderTypeRest, activeTab],
    queryFn: () =>
      getDetailBillingByRest(welletContext, id, limitRest, skipRest, orderByRest, orderTypeRest),
    onSuccess: (data) => {
      setTotalAndResetRest(data?.data?.list?.totalRegisters);
      setFilterLocalStorage({
        ...filterLocalStorage,
        pageRest: currentPageRest,
      });
    },
    ...defaultQueryOptions,
    enabled: activeTab === 'RESTAURANTS',
  });

  // Header
  const comissionsColumns = [
    { title: t('table.commissions.code'), value: '', filterable: false },
    { title: t('table.commissions.commission'), value: 'commission', filterable: true },
    { title: '-', value: '', filterable: false },
    { title: t('table.commissions.schedule'), value: 'date', filterable: true, center: true },
    { title: t('table.commissions.consumption'), value: 'consumptions', filterable: true },
    { title: t('table.commissions.client'), value: 'paxs', filterable: true },
  ];
  const restaurantesColumns = [
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.restaurants.commission'), value: 'amount', filterable: true },
  ];

  const handlePax = (pax) => {
    return pax === 0 || pax === 1 ? t('person') : t('people');
  };
  const handleTabs = (type) => {
    setActiveTab(type);
  };
  useCreateBreadCrumbs([{ name: t('prePage'), path: '/billing' }, { name: t('title') }]);

  return (
    <>
      <div className='container-page color-body font-weight-semibold'>
        <Show>
          <Show.When isTrue={billingComissionSummaryQuery.isLoading}>
            <Loading />
          </Show.When>
          <Show.When
            isTrue={billingComissionSummaryQuery.isError || billingRestauranteSummaryQuery.isError}
          >
            <PageError />
          </Show.When>
          <Show.Else>
            <div className='container-header with-border'>
              <div className='row align-items-end'>
                <div className='col-auto'>
                  <div className='light-grey container-small-title text-uppercase'>
                    {t('title')}: {billingComissionSummaryQuery?.data?.data?.refOrder}
                  </div>
                  <div className='container-title d-flex justify-content-start align-items-center'>
                    <Money
                      value={billingComissionSummaryQuery?.data?.data?.totalCommission}
                      currencyCode={'MXN'}
                    />

                    <div className='ml-2'>
                      <Status
                        status={
                          billingComissionSummaryQuery?.data?.data?.status === 'PENDING'
                            ? 'PENDING'
                            : true
                        }
                        text={
                          billingComissionSummaryQuery?.data?.data?.status === 'PENDING'
                            ? t('pending')
                            : t('payment')
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='col my-auto'></div>
                <div className='col-auto'></div>
              </div>
            </div>

            <Tabs onClick={handleTabs} options={tabs} />
            <Show>
              <Show.When
                isTrue={
                  billingRestauranteSummaryQuery.isLoading ||
                  billingComissionSummaryQuery.isFetching
                }
              >
                <div className='py-4 my-4'>
                  <Loading global={false} />
                </div>
              </Show.When>
              <Show.Else>
                <div className='mt-2'>
                  <Show.When isTrue={activeTab === 'RESTAURANTS'}>
                    <Table
                      borderTop={false}
                      perPage={tabelItems}
                      data={billingRestauranteSummaryQuery?.data?.data?.list?.items}
                      hasPagination={false}
                      totalP={totalItemsRest}
                      onPageChange={(page) =>
                        handlePageChangeRest(
                          page,
                          setFilterLocalStorage({ ...filterLocalStorage, pagePeople: page }),
                        )
                      }
                      currentPage={currentPageRest}
                      columns={restaurantesColumns}
                      onColumnClick={handleColumnClickRest}
                      activeColumn={orderTypeRest}
                      sortOrder={orderByRest}
                      paginationBorder={false}
                      renderRow={(billing, index) => (
                        <>
                          <>
                            <TableCell style={{ width: '200px' }}>
                              <OrganizationCard
                                imageUrl={billing?.logo}
                                name={billing?.restaurant}
                                date={billing?.city}
                                hightLightName={true}
                                size={'40px'}
                              />
                            </TableCell>
                            <TableCell className='fw-700'>
                              <Money value={billing?.amount} />
                            </TableCell>
                          </>
                        </>
                      )}
                      rowClass='not-hover'
                    />
                  </Show.When>
                  <Show.When isTrue={activeTab === 'CONCIERGES'}>
                    <Table
                      borderTop={false}
                      perPage={tabelItems}
                      data={billingComissionSummaryQuery?.data?.data?.list?.items}
                      hasPagination={false}
                      totalP={totalItems}
                      onPageChange={(page) =>
                        handlePageChange(
                          page,
                          setFilterLocalStorage({ ...filterLocalStorage, pagePeople: page }),
                        )
                      }
                      currentPage={currentPage}
                      columns={comissionsColumns}
                      onColumnClick={handleColumnClick}
                      activeColumn={orderType}
                      sortOrder={orderBy}
                      renderRow={(billing, index) => {
                        const { date, time } = formatDateTranslation(
                          billing?.date,
                          language,
                          'extraSmall',
                        );

                        return (
                          <>
                            <>
                              <TableCell style={{ width: '100px' }}>
                                <Link
                                  to={`/reservation-history/${billing.reservationId}`}
                                  className='custom-link-file'
                                >
                                  {billing?.referenceCode}
                                </Link>
                              </TableCell>
                              <TableCell className='fw-700'>
                                <Money value={billing?.commission} />
                              </TableCell>

                              <TableCell>
                                <OrganizationCard
                                  imageUrl={billing?.showPicture}
                                  name={billing?.show}
                                  date={billing?.city}
                                  hightLightName={true}
                                  size={'40px'}
                                />
                              </TableCell>
                              <TableCell className='fw-700 text-center'>
                                <div>
                                  <span className='sentences-capitalize container-label d-block fw-700 '>
                                    {date}
                                  </span>
                                  <span className='fw-700 text-center'>{time}</span>
                                </div>
                              </TableCell>
                              <TableCell className='fw-500'>
                                <Money value={billing?.consumptions} />
                              </TableCell>
                              <TableCell>
                                <span className='fw-700 text-capitalize d-block container-label'>
                                  {billing?.customer}
                                </span>
                                <span className='text-capitalize d-block fw-400'>
                                  {billing?.paxs} {handlePax(billing?.paxs)}
                                </span>
                              </TableCell>
                            </>
                          </>
                        );
                      }}
                      rowClass='not-hover'
                    />
                  </Show.When>
                </div>
              </Show.Else>
            </Show>
          </Show.Else>
        </Show>
      </div>
    </>
  );
};

export default BillingSummary;
