import React, { createContext } from 'react';
import axios from 'axios';

export const WelletContext = createContext({
  apis: {
    tickets: axios.create(),
    admin: axios.create()
  },
  app: {
    name: 'Wellet',
    logo: 'https://cdn1.wellet.fun/organizations/wellet/logo.png',
    language: 'es',
    currencies: {},
    scopeOrganization: -1,
  },
});
