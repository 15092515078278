import React from 'react';
import Money from '../money/money';
import { paymentSummaryBadge } from '../../../helpers/status';
import { useTranslation } from 'react-i18next';

const PaymentDetailHeader = ({ title, userName, amount, status, endComponent }) => {
  const { t } = useTranslation();
  return (
    <div className='container-header with-border'>
      <div className='row align-items-end'>
        <div className='col-auto'>
          <div className='light-grey text-uppercase container-small-title'>{title}</div>
          <div className='container-title'>
            <div className='row'>
              <div></div>
            </div>
            <Money value={amount} />
            <div className='d-inline'>- {userName}</div>
            <div className='d-inline ml-3'>{paymentSummaryBadge(status, null, t)}</div>
          </div>
        </div>

        <div className='col my-auto'></div>
        <div className='col-auto text-right'>
          <div className='row'>{endComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailHeader;
