import React, { useContext } from 'react';
import GraphicsGrid from './grapichsGrid/graphicsGrid';
import { Loading } from '../../uiComponents/common';
import { useQuery } from 'react-query';
import { defaultQueryOptions } from '../../utils/defaultQueryOptions';
import { WelletContext } from '../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  getAllSmallCards,
  getEvents,
  getOccassions,
  getSummaryCards,
  getTimeline,
  getTypeReservation,
} from '../../services';
import FilterCard from '../filterCard/filterCard';
import Show from '../show/show';
import { useTranslation } from 'react-i18next';

const CampaignsStatisticsDetails = ({ show, id }) => {
  const { t } = useTranslation('translation', { keyPrefix: `screens.campaigns.statistics` });
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);

  const selectedDates = useSelector((state) => state.app.selectedDates);
  const selectedFormattedDates = {
    from: selectedDates.formattedFrom,
    to: selectedDates.formattedTo,
  };

  const reservationTypeQuery = useQuery({
    queryKey: ['get-reservation-type-by-id', selectedFormattedDates, id],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      return getTypeReservation(welletContext, organization.marketId, null, body, id);
    },
    onError: (error) => {
      console.log('Grafico de barras Reservation', error);
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Reservation Type Graphics
  const occassionQuery = useQuery({
    queryKey: ['get-occasions-by-id', selectedFormattedDates, id],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      return getOccassions(welletContext, organization.marketId, null, body, id);
    },
    onError: (error) => {
      console.error('Grafico de Pie Ocasiones', error);
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Reservation Occasions Graphics
  const eventsQuery = useQuery({
    queryKey: ['get-events-by-id', selectedFormattedDates, id],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      return getEvents(welletContext, organization.marketId, null, body, id);
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Access through links  Graphics
  const timelineQuery = useQuery({
    queryKey: ['get-timeline-by-id', selectedFormattedDates, id],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
        citiesId: [],
        socialNetwork: '',
      };
      return getTimeline(welletContext, organization.marketId, null, body, id);
    },
    ...defaultQueryOptions,
    enabled: show && selectedDates !== null,
  }); // Timeline Graphics
  const summaryCardsQuery = useQuery({
    queryKey: ['get-summary-cards-by-id', selectedFormattedDates, id],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
      };
      return getSummaryCards(welletContext, organization.marketId, null, body, id);
    },
    onError: (error) => {
      console.log('Grafico Chico Ingreos', error);
    },
    ...defaultQueryOptions,
    enabled: show,
  }); // Summary Cards
  const allCardsQuery = useQuery({
    queryKey: ['get-all-small-cards-by-id', selectedFormattedDates, id],
    queryFn: () => {
      let body = {
        from: selectedFormattedDates.from,
        to: selectedFormattedDates.to,
      };
      return getAllSmallCards(welletContext, organization.marketId, body, id);
    },

    onError: (error) => {
      console.log('Grafico Chico Ingreos', error);
    },
    ...defaultQueryOptions,
    enabled: show,
  }); //Data small cards

  // Functions
  function calculatePercentageVisit(reservationsArray) {
    let completedReservations = 0;
    let incompletedReservations = 0;

    reservationsArray.forEach((item) => {
      if (item.key === 'RESERVATIONS_COMPLETED') {
        completedReservations = item.value;
      } else if (item.key === 'RESERVATIONS_INCOMPLETED') {
        incompletedReservations = item.value;
      }
    });

    let clickCounts = completedReservations + incompletedReservations;
    let percentageVisit = 0;

    if (clickCounts > 0) {
      percentageVisit = ((completedReservations / clickCounts) * 100).toFixed(3);
    }

    return `${parseFloat(percentageVisit)}%`;
  }
  return (
    <>
      <Show>
        <Show.When
          isTrue={
            reservationTypeQuery.isLoading ||
            occassionQuery.isLoading ||
            allCardsQuery.isLoading ||
            eventsQuery.isLoading ||
            timelineQuery.isLoading ||
            summaryCardsQuery.isLoading
          }
        >
          <Loading />
        </Show.When>
        <Show.Else>
          <div className='row px-1 my-3 '>
            <FilterCard
              category={t('cardsFilter.reservation')}
              quantity={summaryCardsQuery.data?.data?.totalReservations}
              onClick={() => null}
              className='reservations col'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.incomes')}
              isMoney
              quantity={summaryCardsQuery.data?.data?.totalIncomes}
              onClick={() => null}
              className='reservations col'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.commissions')}
              isMoney
              quantity={summaryCardsQuery.data?.data?.totalCommissions}
              onClick={() => null}
              className='reservations col'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.commissionsAverage')}
              quantity={
                summaryCardsQuery.data?.data?.totalAvgCommissions
                  ? summaryCardsQuery.data?.data?.totalAvgCommissions + '%'
                  : 0 + '%'
              }
              onClick={() => null}
              className='reservations col'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.clicks')}
              quantity={summaryCardsQuery.data?.data?.totalEvents}
              onClick={() => null}
              className='reservations col'
              isSelected={false}
            />
            <FilterCard
              category={t('cardsFilter.conversions')}
              quantity={calculatePercentageVisit(eventsQuery.data?.data ?? [])}
              onClick={() => null}
              className='reservations col'
              isSelected={false}
            />
          </div>
          <GraphicsGrid
            mode={'DETAIL'}
            barGraphicData={reservationTypeQuery.data?.data}
            occassionsPie={occassionQuery.data?.data}
            eventsPie={eventsQuery.data?.data}
            timeline={timelineQuery.data?.data}
            allCards={allCardsQuery.data?.data}
          />
        </Show.Else>
      </Show>
    </>
  );
};

export default CampaignsStatisticsDetails;
