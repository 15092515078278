import React from 'react';

const InfoRenderer = ({ content, className = '', styles = {} }) => {
  return (
    <div className={className} style={styles}>
      {content ? content : '-'}
    </div>
  );
};

export default InfoRenderer;
