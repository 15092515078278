import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Chart from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { CardDashboard } from '../../uiComponents/common';
import { useTranslation } from 'react-i18next';
ReactFC.fcRoot(FusionCharts, Chart, FusionTheme);

const TimeLine = ({ title, subtitle, data, xAxis, yAxis, parentClass, chartConfig, chart }) => {
  const { i18n } = useTranslation();
  // Creating the JSON object to store the chart configurations
  const chartConfigs = {
    type: 'area2d',
    width: '100%', // Width of the chart
    height: '400', // Height of the chart
    dataFormat: 'json', // Data type
    dataEmptyMessage:
      i18n.language === 'en' ? 'No information available.' : ' No hay información disponible.',
    dataSource: {
      chart: {
        baseFont: '"Inter", sans-serif',
        // Gradients
        usePlotGradientColor: 1,
        plotFillAngle: '90',
        plotFillAlpha: '30',
        plotFillColor: '#675DFF',
        // Line (border top) color
        plotBorderColor: '#675DFF',
        plotBorderAlpha: 100,
        caption: title,
        baseFontSize: '12',
        labelDisplay: 'none',
        subCaption: subtitle,
        xAxisName: xAxis,
        showYAxisValues: 0,
        drawFullAreaBorder: 0,
        showPlotBorder: 1,
        plotBorderThickness: 2,
        theme: 'fusion',
        ...chart,
      },

      data,
    },
    ...chartConfig,
  };
  return (
    <CardDashboard className={parentClass}>
      <ReactFC {...chartConfigs} />
    </CardDashboard>
  );
};

export default TimeLine;
