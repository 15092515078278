import React from 'react';

const MediaFileIcon = ({ size = '24px', color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.5 4.25H5.5C5.16848 4.25 4.85054 4.3817 4.61612 4.61612C4.3817 4.85054 4.25 5.16848 4.25 5.5V30.5C4.25 30.8315 4.3817 31.1495 4.61612 31.3839C4.85054 31.6183 5.16848 31.75 5.5 31.75H30.5C30.8315 31.75 31.1495 31.6183 31.3839 31.3839C31.6183 31.1495 31.75 30.8315 31.75 30.5V5.5C31.75 5.16848 31.6183 4.85054 31.3839 4.61612C31.1495 4.3817 30.8315 4.25 30.5 4.25ZM5.5 0.5C4.17392 0.5 2.90215 1.02678 1.96447 1.96447C1.02678 2.90215 0.5 4.17392 0.5 5.5V30.5C0.5 31.8261 1.02678 33.0979 1.96447 34.0355C2.90215 34.9732 4.17392 35.5 5.5 35.5H30.5C31.8261 35.5 33.0979 34.9732 34.0355 34.0355C34.9732 33.0979 35.5 31.8261 35.5 30.5V5.5C35.5 4.17392 34.9732 2.90215 34.0355 1.96447C33.0979 1.02678 31.8261 0.5 30.5 0.5H5.5ZM28 25.1425L21.75 18L15.56 25.075L11.75 20.5L8 25V28H28V25.1425ZM14.25 18C15.2446 18 16.1984 17.6049 16.9017 16.9017C17.6049 16.1984 18 15.2446 18 14.25C18 13.2554 17.6049 12.3016 16.9017 11.5983C16.1984 10.8951 15.2446 10.5 14.25 10.5C13.2554 10.5 12.3016 10.8951 11.5983 11.5983C10.8951 12.3016 10.5 13.2554 10.5 14.25C10.5 15.2446 10.8951 16.1984 11.5983 16.9017C12.3016 17.6049 13.2554 18 14.25 18Z'
        fill={color}
      />
    </svg>
  );
};

export default MediaFileIcon;
