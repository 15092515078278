import React from 'react';
import './online.css';

const Online = ({ text, status = 'green' }) => {
  return (
    <div className='d-flex justify-content-center align-items-center online'>
      <div className={`dot dot-${status} mr-1`}></div>
      <div className='fw-600'>{text}</div>
    </div>
  );
};

export default Online;
