import { useSelector } from 'react-redux';
import Tooltip from '../tooltip/tooltip';

const Money = ({
  value,
  currency = null,
  symbol = null,
  convert = true,
  currencyClass,
  minify = null,
  showCurrencyEnd = true
}) => {
  const currencySymbol = useSelector((state) => state.app.selectedOrganization);

  function verifyQuantity(quantity) {
    if (minify !== null && quantity >= minify) {
      let abbreviation;
      if (minify >= 1000000000) {
        abbreviation = (quantity / 1000000000).toFixed(1) + ' B'; // Billions
      } else if (minify >= 1000000) {
        abbreviation = (quantity / 1000000).toFixed(1) + ' M'; // Millions
      } else if (minify >= 1000) {
        abbreviation = (quantity / 1000).toFixed(1) + ' K'; // Thousands
      }
      return abbreviation;
    } else {
      return quantity.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (value === null || isNaN(value)) return;

  const valueInSelectedCurrency = convert ? value * 1 : value;
  const minifyNumber = verifyQuantity(valueInSelectedCurrency);

  if (symbol === null) {
    symbol = currencySymbol.currencySymbol;
  }

  const currencyCode = currencySymbol.currency;

  return (
    <div className='d-inline-flex align-items-center'>
      {!showCurrencyEnd ? (
        <span
          className={`${currencyClass ? currencyClass : 'text-light'}`}
          style={{ fontWeight: 400 }}
        >
          {currencyCode}&nbsp;
        </span>
      ) : null}
      {minify !== null && minify <= valueInSelectedCurrency ? (
        <Tooltip
          text={
            symbol +
            ' ' +
            valueInSelectedCurrency.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }
          marginT='-60px'
        >
          <div className={`${currencyClass && currencyClass} integer`}>
            {symbol + ' ' + minifyNumber}
          </div>
        </Tooltip>
      ) : (
        <div className={`${currencyClass && currencyClass} integer`}>
          {symbol + ' ' + minifyNumber}
        </div>
      )}

      {showCurrencyEnd ? (
        <span
          className={`${currencyClass ? currencyClass : 'text-light'}`}
          style={{ fontWeight: 400 }}
        >
          &nbsp;{currencyCode}
        </span>
      ) : null}
    </div>
  );
};

export default Money;
