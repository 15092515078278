import React from 'react';
import styles from './tabs.module.css';

const Tabs = ({ options, onClick, className = '', show = false }) => {
  return (
    <ul className={`nav nav-tabs nav-tabs-bottom border-table-bottom ${className}`}>
      {options.map((o, i) => (
        <li
          key={i}
          className={`nav-link cursor-pointer d-flex align-items-center fw-600 ${o.isActive ? 'active' : ''}`}
          onClick={() => (o.disabled ? null : onClick(o.type))}
        >
          {o.title}
          {o.notification ? (
            <div
              className={`${styles.notification} color-primary-dark d-flex align-items-center justify-content-center`}
            >
              {o.notification > 99 ? `+99` : o.notification}
            </div>
          ) : null}
              {o.show ? (
            <div
              className={`${styles.badgeNotification}`}
            >
            </div>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
