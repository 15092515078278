import { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getAllShows } from '../../services';
import { defaultQueryOptionsFilter } from '../../utils/defaultQueryOptions';

export const useGetRestaurants = (organization, options) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    ...defaultQueryOptionsFilter,
    ...options,
  };

  return useQuery(
    ['restaurantes', organization.id, organization.marketId],
    () =>
      getAllShows(
        welletContext,
        organization.marketId,
        organization.isMarket ? null : organization.id,
      ),
    queryOptions,
  );
};
