export const getRiskLevel = (level) => {
  switch (level) {
    case 'HIGH':
      return 'Alto';
    case 'LOW':
      return 'Bajo';
    case 'MEDIUM':
      return 'Medio';
    default:
      return '';
  }
};
export const getStatusMessage = (status) => {
  switch (status) {
    case 'APPROVED':
      return 'This payment is complete.';
    case 'PENDING':
      return 'The customer has not entered their payment method.';
    case 'ERROR':
      return 'This payment failed.';
    case 'EXPIRED':
      return 'This payment expired.';
    case 'REFUNDED':
      return 'This refund has been initiated';
    default:
      return '';
  }
};

export const getCurrentFilter = (filter, data, totalCount = false) => {
  let quantityToShow = 20;
  if (totalCount) {
    quantityToShow = data.totalCount;
  } else if (data) {
    if (filter === 'approved') {
      quantityToShow = data.approvedQuantity;
    } else if (filter === 'error') {
      quantityToShow = data.errorQuantity;
    } else if (filter === 'refunded') {
      quantityToShow = data.refundedQuantity;
    } else if (filter === 'expired') {
      quantityToShow = data.expiredQuantity;
    } else if (filter === 'pending') {
      quantityToShow = data.pendingQuantity;
    } else {
      quantityToShow = data.allQuantity;
    }
  }
  return quantityToShow;
};

export const getTimezoneFormatted = (data) =>
  data?.map((obj) => ({
    label: `${obj.name} (UTC ${obj.shift})`,
    value: obj.id,
  }));

export const formatTimeFromMinutes = (closeAfter) => {
  const hours = Math.floor(closeAfter / 60);
  const minutes = closeAfter % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const formatTimeToMinutes = (time) => {
  if (time) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  } else return 0;
};

export function formatMinutes(minutes, t) {
  if (minutes < 60) {
    return `${minutes} ${t('components.formatTimeToMinutes.minutes')}`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
      return `${hours} ${t('components.formatTimeToMinutes.hours')}${hours > 1 ? 's' : ''}`;
    } else {
      return `${hours} ${t('components.formatTimeToMinutes.hours')}${
        hours > 1 ? 's' : ''
      } y ${remainingMinutes} ${t('components.formatTimeToMinutes.minutes')}`;
    }
  }
}

export const paymentBatchErrors = {
  NOT_ALLOWED: 'No esta permitido.',
  USER_NOT_FOUND: 'Usuario no encontrado.',
  ORDER_NOT_FOUND: 'Orden no encontrada.',
  MISSING_USER_WITHDRAWAL_METHOD: 'El usuario no cuenta con un metodo de retiro.',
  NEGATIVE_AMOUNT_NOT_ALLOWED: 'El monto no está permitido',
  NEGATIVE_AMOUNT_HIGHER_THAN_PAYMENT: 'El monto no puede ser mayor al pago.',
  INVALID_ORDER: 'Orden inválida',
  INVALID_PAYMENT_BATCH: 'Lote de pago inválido',
  PAYMENT_BATCH_NOT_FOUND: 'Lote de pago no encontrado.',
};

export const filtersFormat = (array) => {
  const filteredArray = array?.filter((item) => item.isActive);
  const valuesArray = filteredArray.map((item) => item.value);

  return valuesArray;
};

export const getCancelledBy = (status) => {
  switch (status) {
    case 'concierge':
      return 'CANCELLED_CONCIERGE';
    case 'modify':
      return 'CANCELLED_MODIFICATION';
    case 'customer':
      return 'CANCELLED_CUSTOMER';
    default:
      return 'CANCELLED';
  }
};

export const getReservationType = (reservation, t) => {
  switch (reservation) {
    case 'AREA':
      return t('functions.getType.area');
    case 'GROUP':
      return t('functions.getType.group');
    case 'RESTAURANT':
      return t('functions.getType.restaurant');
    default:
      return t('functions.getType.default');
  }
};

export const formatPaymentMethod = (payment, t) => {
  return t(`paymentMethods.${payment}`);
};

export function getContactList(t) {
  return [
    {
      label: 'Email',
      value: 'EMAIL',
      type: 'EMAIL',
    },
    {
      label: t('contact.PRINCIPAL_PHONE'),
      value: 'PRINCIPAL_PHONE',
      type: 'PRINCIPAL_PHONE',
    },
    {
      label: t('contact.SUPPORT_PHONE_US'),
      value: 'SUPPORT_PHONE_US',
      type: 'SUPPORT_PHONE_US',
    },
    {
      label: t('contact.SUPPORT_PHONE_MX'),
      value: 'SUPPORT_PHONE_MX',
      type: 'SUPPORT_PHONE_MX',
    },
    {
      label: t('contact.SUPPORT_PHONE_AR'),
      value: 'SUPPORT_PHONE_AR',
      type: 'SUPPORT_PHONE_AR',
    },
    {
      label: 'Whatsapp',
      value: 'WHATSAPP',
      type: 'WHATSAPP',
    },
  ];
}

export const getErrorCode = (error, t) => {
  switch (error) {
    // case 'UNKNOWN':
    //   return t('error.UNKNOWN');
    // case 'CARD_DECLINED':
    //   return t('error.CARD_DECLINED');
    // case 'AUTHENTICATION_REQUIRED':
    //   return t('error.AUTHENTICATION_REQUIRED');
    // case 'CARD_NOT_SUPPORTED':
    //   return t('error.CARD_NOT_SUPPORTED');
    // case 'CARD_DISABLED':
    //   return t('error.CARD_DISABLED');
    // case 'CURRENCY_NOT_SUPPORTED':
    //   return t('error.CURRENCY_NOT_SUPPORTED');
    // case 'DUPLICATE_TRANSACTION':
    //   return t('error.DUPLICATE_TRANSACTION');
    // case 'FRAUDULENT':
    //   return t('error.FRAUDULENT');
    // case 'ISSUER_NOT_AVAILABLE':
    //   return t('error.ISSUER_NOT_AVAILABLE');
    // case 'INVALID_TRANSACTION':
    //   return t('error.INVALID_TRANSACTION');
    // case 'NOT_ALLOWED_TRANSACTION':
    //   return t('error.NOT_ALLOWED_TRANSACTION');
    // case 'NOT_ALLOWED_DEFERRED_PAYMENT':
    //   return t('error.NOT_ALLOWED_DEFERRED_PAYMENT');
    // case 'HOLD_CARD':
    //   return t('error.HOLD_CARD');
    // case 'INSUFFICIENT_FUNDS':
    //   return t('error.INSUFFICIENT_FUNDS');
    // case 'LOST_CARD':
    //   return t('error.LOST_CARD');
    // case 'STOLEN_CARD':
    //   return t('error.STOLEN_CARD');
    // case 'INCORRECT_CVC':
    //   return t('error.INCORRECT_CVC');
    // case 'INCORRECT_DATA':
    //   return t('error.INCORRECT_DATA');
    // case 'EXPIRED_CARD':
    //   return t('error.EXPIRED_CARD');
    // case 'API_ERROR':
    //   return t('error.API_ERROR');
    // case 'INVALID_CARD':
    //   return t('error.INVALID_CARD');
    // case 'REFUSE':
    //   return t('error.REFUSE');
    // case 'INSTALLMENT_UNAVAILABLE':
    //   return t('error.INSTALLMENT_UNAVAILABLE');
    // case 'INVALID_INSTALLMENT':
    //   return t('error.INVALID_INSTALLMENT');
    // case 'EXCEEDS_CARD_LIMIT':
    //   return t('error.EXCEEDS_CARD_LIMIT');
    // case 'HIGH_RISK_TRANSACTION':
    //   return t('error.HIGH_RISK_TRANSACTION');
    // case 'MAX_ATTEMPTS':
    //   return t('error.MAX_ATTEMPTS');
    // case 'CALL_CARD_ISSUER':
    //   return t('error.CALL_CARD_ISSUER');
    // case 'RETRY':
    //   return t('error.RETRY');
    // case 'THREEDS_CHALLENGE_REJECTED':
    // return t('error.THREEDS_CHALLENGE_REJECTED');
    default:
      return error;
  }
};

export const getPaymentMethods = (paymentMethods, t, includeNone = false) => {
  const methods = paymentMethods.map((method) => ({
    isActive: false,
    label: t(`paymentMethods.${method}`),
    value: method,
  }));

  if (includeNone) {
    methods.push({
      isActive: false,
      label: t('paymentMethods.NONE'),
      value: 'NONE',
    });
  }

  return methods;
};
