import React from 'react';
import { InputBase } from '../inputs';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const InputEmail = ({
  name,
  placeholder,
  icon,
  className,
  isRequired,
  label,
  isDisabled,
  autocomplete = null,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'inputs' });
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    <div className={`form-group ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        rules={{
          required: isRequired ? t('required') : '',
          validate: (value) => {
            if (value && !isValidEmail(value)) {
               return t('invalidEmail');
            }
            return true;
          },
        }}
        render={({ field }) => (
          <div>
            <InputBase
              autocomplete={autocomplete}
              type='email'
              field={field}
              errors={errors}
              name={name}
              placeholder={placeholder}
              icon={icon}
              isRequired={isRequired}
              isDisabled={isDisabled}
              label={label}
            />
            {errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default InputEmail;
