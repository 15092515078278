import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function OrganizationRoute({ checkSelectedOrg = true }) {
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrg = useSelector((state) => state.app.selectedOrganization);
  const isFromWellet = organization.id === 83 || organization.id === 371 || organization.id === 0;
  const selectedWellet = selectedOrg.id === 83 || selectedOrg.id === 371 || selectedOrg.id === 0;

  const shouldRedirect = () => {
    if (isFromWellet && checkSelectedOrg) {
      return selectedWellet;
    }

    return isFromWellet;
  };

  return shouldRedirect() ? <Navigate to={{ pathname: '/' }} replace /> : <Outlet />;
}
