import React from 'react';
import './table.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PaginationByItems from '../pagination/paginationByItems';
import PaginationByButtons from '../pagination/paginationByButton';
import { NewTooltip } from '../../uiComponents/common';
import { ArrowDown, ArrowUp, FilterIcon } from '../../uiComponents/icons';
import { useTranslation } from 'react-i18next';

const DragDropTable = ({
  data,
  columns,
  renderRow,
  handleDragEnd,
  totalP = data?.length,
  showText = true,
  hasPagination = true,
  perPage = 10,
  disableDrag = false,
  showPagination = true,
  borderTop = true,
  onPageChange,
  currentPage,
  borderContainer = false,
  onColumnClick,
  activeColumn,
  header = 'grey',
  sortOrder,
  paddingTh = null,
  pagination = 'range',

  paginationBorder = true,
}) => {
  const itemsPerPage = perPage;
  // const dataToDisplay = hasPagination ? data.slice(startIndex, endIndex) : data;
  const { t } = useTranslation('translation', { keyPrefix: 'components.tabel' });
  const Pagination = pagination === 'range' ? PaginationByItems : PaginationByButtons;

  const renderHeaders = (columns) => {
    return columns.map((h, index) => (
      <th
        key={index}
        className={`sorting text-left text-sidebar-link ctm-text-small ${
          h.center ? 'text-center' : ''
        } ${paddingTh}`}
        tabIndex='0'
        style={{ cursor: h.filterable ? 'pointer' : 'auto' }}
        aria-controls='DataTables_Table_0'
        rowSpan='1'
        colSpan='1'
        aria-label={h.title ? h.title : h}
        onClick={h.filterable ? () => onColumnClick(h.value) : null}
      >
        {h.filterable ? (
          <div
            className='d-flex align-items-center'
            style={{
              color: activeColumn === h.value ? '#6941C6 ' : 'var(--color-text)',
              fontWeight: activeColumn === h.value ? 700 : 500,
            }}
          >
            <span className='mr-2'>{h.title}</span>
            {activeColumn !== h.value && <FilterIcon size='12px' color='currentColor' />}
            {activeColumn === h.value && sortOrder === 'ASC' && (
              <ArrowUp size='12px' color='currentColor' />
            )}
            {activeColumn === h.value && sortOrder === 'DESC' && (
              <ArrowDown size='12px' color='currentColor' />
            )}
          </div>
        ) : (
          <span>{h.title}</span>
        )}
        {h.tooltip ? (
          <div style={{ display: 'inline-block', marginBottom: '-4px', marginLeft: '3px' }}>
            <NewTooltip tooltip={h.tooltip} />{' '}
          </div>
        ) : null}
      </th>
    ));
  };

  return (
    <div className='w-100'>
      <div className='datatable-scroll '>
        <DragDropContext onDragEnd={handleDragEnd}>
          <table
            className='table datatable-basic dataTable no-footer'
            role='grid'
            aria-describedby='DataTables_Table_0_info'
          >
            <thead className=''>
              <tr
                className={`row-header header-${header} ${borderTop ? 'with-border' : 'no-border'}`}
              >
                {renderHeaders(columns)}
              </tr>
            </thead>
            <Droppable droppableId='tbody'>
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {data && data?.length > 0 ? (
                    data.map((rowData, index) => (
                      <Draggable
                        index={index}
                        key={index}
                        draggableId={String(index)}
                        isDragDisabled={disableDrag}
                      >
                        {(provided) => renderRow(rowData, index, provided)}
                      </Draggable>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={columns.length}
                        className='text-center'
                        style={{ color: 'var(--color-subtitle)' }}
                      >
                        {t('noInfo')}
                      </td>
                    </tr>
                  )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
      {showPagination ? (
        <div className={`datatable-footer ${paginationBorder ? '' : 'without-border'}`}>
          <Pagination
            currentPage={currentPage}
            totalItems={totalP}
            itemsPerPage={itemsPerPage}
            onPageChange={onPageChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DragDropTable;
