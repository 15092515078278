import {
  AnniversaryIcon,
  BirthdayIcon,
  BusinessFood,
  DinnerIcon,
  SpecialEvent,
} from '../uiComponents/icons';

// To Do: Alinear con el nuevo valor Enum que viene del back.
export function getOcassionIcon(occasion, t) {
  let icono;
  let texto;

  switch (occasion) {
    case 'BIRTHDAY':
      icono = <BirthdayIcon size='16px' />;
      texto = t('functions.getOccasion.birthday');
      break;
    case 'ROMANTIC_DINNER':
      icono = <DinnerIcon size='16px' />;
      texto = t('functions.getOccasion.romantic');
      break;
    case 'SPECIAL_EVENT':
      icono = <SpecialEvent size='16px' />;
      texto = t('functions.getOccasion.event');
      break;
    case 'BUSSINES_MEAL':
      icono = <BusinessFood size='16px' />;
      texto = t('functions.getOccasion.business');
      break;
    case 'ANNIVERSARY':
      icono = <AnniversaryIcon size='16px' />;
      texto = t('functions.getOccasion.anniversary');
      break;
    default:
      icono = null;
      texto = '-';
      break;
  }

  return { text: texto, icon: icono };
}

export function getOccasionText(occasion, t) {
  switch (occasion) {
    case 'ANNIVERSARY':
      return t('functions.getOccasion.anniversary');
    case 'BIRTHDAY':
      return t('functions.getOccasion.birthday');
    case 'ROMANTIC_DINNER':
      return t('functions.getOccasion.romantic');
    case 'SPECIAL_EVENT':
      return t('functions.getOccasion.event');
    case 'BUSSINES_MEAL':
      return t('functions.getOccasion.business');
    default:
      return occasion;
  }
}
