import React, { useState } from 'react';
import { ButtonDownload, Subtitle } from '../../uiComponents/common';
import Table from '../table/table';
import TableRow from '../table/tableRow';
import TableCell from '../table/tableCell';
import { useOrderBy } from '../../hooks';
import { useDownloadPdfMutation } from '../../hooks/mutations/useDownloadPdfMutation';
import Show from '../show/show';
import { useTranslation } from 'react-i18next';
import { formatDateTranslation } from '../../helpers/dates';
import { useSelector } from 'react-redux';

const TablePdf = ({ data = [] }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.tablePdf',
  });
  const [currentUrl, setCurrentUrl] = useState(null);
  const language = useSelector((state) => state.app.language);

  // Sort Columns
  const { orderBy, orderType, handleColumnClick } = useOrderBy();
  const header = [
    { title: t('archive'), value: '', filterable: false },
    { title: t('date'), value: '', filterable: false },
  ];

  const downloadPdf = useDownloadPdfMutation();
  const download = (item) => {
    setCurrentUrl(item);
    downloadPdf.mutate(item.url);
  };

  return (
    <>
      <Subtitle text={t('title')} />

      <div className='mt-2' style={{ marginBottom: '150px' }}>
        <Show>
          <Show.When isTrue={data && data.length !== 0}>
            <Table
              hasPagination={false}
              borderTop={false}
              showPagination={false}
              columns={header}
              onColumnClick={handleColumnClick}
              activeColumn={orderType}
              sortOrder={orderBy}
              pagination='normal'
              borderContainer={true}
              header='white'
              showText={false}
              data={data}
              paddingTh={'padding-white-header'}
              renderRow={(item) => {
                const { dateTime } = formatDateTranslation(item.creationDate, language, 'small');
                return (
                  <>
                    <TableCell>
                      <div className='row flex-nowrap'>
                        <div className='col-auto pr-0'>
                          <svg
                            width='32'
                            height='40'
                            viewBox='0 0 32 40'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M28 38.5H4C2.61929 38.5 1.5 37.3807 1.5 36V4C1.5 2.61929 2.61929 1.5 4 1.5H19.25V8C19.25 10.6234 21.3766 12.75 24 12.75H30.5V36C30.5 37.3807 29.3807 38.5 28 38.5ZM29.6287 11.25L20.75 2.37132V8C20.75 9.79493 22.2051 11.25 24 11.25H29.6287ZM0 4C0 1.79086 1.79086 0 4 0H20C20.3201 0 20.6272 0.127177 20.8536 0.353553L31.6464 11.1464C31.8728 11.3728 32 11.6799 32 12V36C32 38.2091 30.2091 40 28 40H4C1.79086 40 0 38.2091 0 36V4Z'
                              fill='#D0D5DD'
                            />
                            <path
                              d='M21.7088 24.3438C20.586 24.3438 19.1808 24.5396 18.7211 24.6085C16.8184 22.6216 16.277 21.4922 16.1553 21.191C16.3203 20.7671 16.8944 19.1564 16.976 17.0878C17.0162 16.0521 16.7975 15.2782 16.3256 14.7876C15.8545 14.2979 15.2843 14.2598 15.1208 14.2598C14.5475 14.2598 13.5857 14.5497 13.5857 16.4911C13.5857 18.1756 14.3711 19.9631 14.5882 20.4249C13.4443 23.7554 12.2163 26.0353 11.9556 26.5042C7.35942 28.2347 7 29.9079 7 30.3821C7 31.2342 7.60689 31.743 8.62343 31.743C11.0932 31.743 13.347 27.5965 13.7197 26.8765C15.4738 26.1776 17.8216 25.7446 18.4184 25.6413C20.1304 27.2721 22.1104 27.7073 22.9326 27.7073C23.5512 27.7073 24.9999 27.7073 24.9999 26.2177C25 24.8345 23.2271 24.3438 21.7088 24.3438ZM21.5898 25.3216C22.9238 25.3216 23.2764 25.7628 23.2764 25.9961C23.2764 26.1424 23.2208 26.62 22.5058 26.62C21.8646 26.62 20.7575 26.2494 19.6683 25.4454C20.1225 25.3857 20.7947 25.3216 21.5898 25.3216ZM15.0508 15.2076C15.1724 15.2076 15.2525 15.2467 15.3185 15.3383C15.7022 15.8706 15.3928 17.6103 15.0163 18.9716C14.6528 17.8043 14.38 16.0133 14.7638 15.3831C14.8388 15.2602 14.9246 15.2076 15.0508 15.2076ZM14.4029 25.6275C14.8859 24.6517 15.4272 23.2297 15.722 22.4255C16.3121 23.4131 17.1057 24.3301 17.5647 24.8272C16.1356 25.1285 15.0543 25.4295 14.4029 25.6275ZM7.9594 30.5123C7.92758 30.4745 7.92288 30.395 7.94686 30.2994C7.99714 30.0993 8.38141 29.1072 11.1608 27.8639C10.7628 28.4907 10.1407 29.3864 9.45717 30.0556C8.97605 30.5059 8.60142 30.7343 8.34367 30.7343C8.25146 30.7343 8.12442 30.7092 7.9594 30.5123Z'
                              fill='#D92D20'
                            />
                          </svg>
                        </div>
                        <div className='col my-auto'>
                          <div className='fw-500'>{item.name}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={'dark-grey sentences-capitalize'}>{dateTime}</TableCell>
                    <TableCell className={'fw-600'}>
                      <ButtonDownload
                        onClick={() => download(item)}
                        className={'fw-600'}
                        isLoading={currentUrl?.url === item.url && downloadPdf?.isLoading}
                      />
                    </TableCell>
                  </>
                );
              }}
              rowClass='not-hover'
            />
          </Show.When>
          <Show.Else>
            <div className='col fw-600'>{t('noInfo')}</div>
          </Show.Else>
        </Show>
      </div>
    </>
  );
};

export default TablePdf;
