import React, { useContext, useState } from 'react';
import { Button, InputNumber, InputText, Modal, Select, Toast } from '../../uiComponents/common';
import { FormProvider, useForm } from 'react-hook-form';
import Show from '../show/show';
import { useMutation, useQueryClient } from 'react-query';
import { putAddCheck } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ModalConsumption = ({ reservationId }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation('translation', { keyPrefix: 'modals.modalConsumption' });
  const { id } = useParams();
  const [openConsumption, setOpenConsumption] = useState(false);
  const methods = useForm({
    defaultValues: {
      discount: { label: 'NO', value: 'no' },
    },
  });

  const consumptionMutation = useMutation({
    mutationFn: (data) => {
      const body = {
        reservationId: reservationId,
        amount: data?.subtotal,
        tableNumber: data?.table,
        paxs: data?.paxs != null && data?.paxs.length > 0 ? data?.paxs : 0,
        hasDiscount: data?.discount?.value === 'yes',
        discountName: data?.name,
        discountAmount: data?.amountDiscount !== null ? data?.amountDiscount : 0,
        checkNumber: data?.checkNumber,
      };
      return putAddCheck(welletContext, body);
    },
  });
  const queryClient = useQueryClient();

  const onSubmit = (data) => {
    consumptionMutation.mutate(data);
  };
  const handleClose = () => {
    methods.reset({
      table: null,
      subtotal: null,
      id: null,
      paxs: null,
      amountDiscount: null,
      name: null,
      discount: { label: 'NO', value: 'no' },
      checkNumber: null,
    });
    handleModal();
  };
  const handleModal = () => {
    setOpenConsumption((prevState) => !prevState);
  };
  const handleRefetch = () => {
    queryClient.refetchQueries({ queryKey: ['reservation', id] });
  };
  const handleToast = () => {
    consumptionMutation.reset();
    handleRefetch();
    handleClose();
  };

  return (
    <>
      <Button
        className={'btn-wellet-secondary bg-custom-light text-truncate btn-accept'}
        onClick={handleModal}
        size={'custom'}
        text={<>{t('button')}</>}
      />
      <Show.When isTrue={openConsumption}>
        <FormProvider {...methods}>
          <form key={'consumption'}>
            <Modal
              title={t('title')}
              open={openConsumption}
              onClose={handleClose}
              isLoading={consumptionMutation.isLoading || consumptionMutation.isSuccess}
              maxWidth='600px'
              handleSubmit={methods.handleSubmit(onSubmit)}
              customButtons={
                <>
                  <div className='d-flex justify-content-end'>
                    <Button
                      text={t('cancel')}
                      disabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                      className={'btn-general btn-wellet-secondary'}
                      size={'medium'}
                      onClick={handleClose}
                    />
                    <Button
                      text={t('submit')}
                      disabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                      className={'ml-2 btn-general btn-wellet-primary'}
                      size={'medium'}
                      onClick={() => methods.handleSubmit(onSubmit)()}
                    />
                  </div>
                </>
              }
            >
              <div className='row mt-2'>
                <InputNumber
                  name={'subtotal'}
                  label={t('inputSubtotal.label')}
                  placeholder={t('inputSubtotal.placeholder')}
                  isRequired={true}
                  acceptNegative={false}
                  isDisabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                  className='mb-2 col'
                  customValidation={{
                    validate: (value) => {
                      if (!value || parseFloat(value) < 0) {
                        return t('validations.equalsZero');
                      }
                      if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                        return t('validations.decimals');
                      }
                      return true;
                    },
                  }}
                  toFixed={true}
                />
                <InputText
                  maxLength={512}
                  label={t('inputCheq.label')}
                  placeholder={t('inputCheq.placeholder')}
                  name={'checkNumber'}
                  isDisabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                  isRequired={false}
                  minLength={0}
                  className='col'
                />
              </div>
              <div className='row'>
                <InputText
                  minLength={0}
                  maxLength={512}
                  label={t('inputTable.label')}
                  name={'table'}
                  isDisabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                  placeholder={t('inputTable.placeholder')}
                  isRequired={false}
                  className='col'
                />
                <InputNumber
                  name={'paxs'}
                  label={t('inputPax.label')}
                  placeholder={t('inputPax.placeholder')}
                  acceptNegative={false}
                  isDisabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                  className='col'
                />
              </div>
              <div className='row'>
                <Select
                  options={[
                    { label: t('inputDisccount.no'), value: 'no' },
                    { label: t('inputDisccount.yes'), value: 'yes' },
                  ]}
                  label={t('inputDisccount.label')}
                  name={'discount'}
                  isDisabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                  isRequired={false}
                  className='col'
                />
              </div>
              {methods.watch('discount')?.value === 'yes' ? (
                <div className='row'>
                  <InputText
                    minLength={0}
                    maxLength={512}
                    label={t('inputDisccountName.label')}
                    placeholder={t('inputDisccountName.placeholder')}
                    name={'name'}
                    isDisabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                    isRequired={false}
                    className='col'
                  />
                  <InputNumber
                    name={'amountDiscount'}
                    label={t('inputDisccountAmount.label')}
                    placeholder={t('inputDisccountAmount.placeholder')}
                    isRequired={false}
                    acceptNegative={false}
                    isDisabled={consumptionMutation.isLoading || consumptionMutation.isSuccess}
                    customValidation={{
                      validate: (value) => {
                        if (value && parseFloat(value) < 0) {
                          return t('validations.equalsZero');
                        }
                        if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
                          return t('validations.decimals');
                        }
                        return true;
                      },
                    }}
                    className='mb-2 col'
                  />
                </div>
              ) : null}
            </Modal>
          </form>
        </FormProvider>
      </Show.When>

      <Show.When isTrue={consumptionMutation.isError}>
        <Toast message={t('toast.error')} type='ERROR' onCallback={handleToast} />
      </Show.When>
      <Show.When isTrue={consumptionMutation.isSuccess}>
        <Toast message={t('toast.success')} type='SUCCESS' onCallback={handleToast} />
      </Show.When>
    </>
  );
};

export default ModalConsumption;
