import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loading, Money, OrganizationCard, Subtitle, Tabs } from '../../../../uiComponents/common';
import { Show, Table, TableCell, TableRow } from '../../../../components';
import { WelletContext } from '../../../../context/wellet/welletContext';
import { formatDateTranslation, getLocale } from '../../../../helpers/dates';
import { useOrderBy, usePagination } from '../../../../hooks';
import {
  getWireComissionsDetails,
  getWireOrdersDetails,
  getWireRestaurantsDetails,
} from '../../../../services';
import { Link } from 'react-router-dom';
import useGenericTranslation from '../../../../hooks/useGenericTranslation';
/*

To Do: Componetizar

*/
const TabTableWire = ({ paymentId = null, isWellet }) => {
  const welletContext = useContext(WelletContext);
  const tabelItems = 10;
  const { t } = useTranslation('translation', {
    keyPrefix: 'screens.wiresPaymentDetail.paymentDetail',
  });
  const { getClientTranslation } = useGenericTranslation();
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);

  // Comissions Custom Hooks
  const { orderBy, orderType, handleColumnClick, resetState } = useOrderBy();
  const { currentPage, totalItems, handlePageChange, setTotalAndReset } = usePagination(
    1,
    tabelItems,
  );
  let limit = tabelItems;
  let skip = tabelItems * ((currentPage ? currentPage : 1) - 1);

  // Restaurantes Custom Hooks
  const {
    currentPage: currentPageRest,
    totalItems: totalItemsRestaurant,
    handlePageChange: handlePageChangeRestaurant,
    setTotalAndReset: setTotalAndResetRestaurant,
  } = usePagination(1, tabelItems);
  const {
    orderBy: orderByRest,
    orderType: orderTypeRest,
    handleColumnClick: handleColumnClickRest,
    resetState: resetStateRest,
  } = useOrderBy();
  let limitRest = tabelItems;
  let skipRest = tabelItems * ((currentPageRest ? currentPageRest : 1) - 1);

  // Orders Custom Hooks
  const {
    currentPage: currentPageOrders,
    totalItems: totalItemsOrders,
    handlePageChange: handlePageChangeOrders,
    setTotalAndReset: setTotalAndResetOrders,
  } = usePagination(1, tabelItems);
  const {
    orderBy: orderByeOrders,
    orderType: orderTypeOrders,
    handleColumnClick: handleColumnClickOrders,
    resetState: resetStateOrders,
  } = useOrderBy();
  let limitOrders = tabelItems;
  let skipOrders = tabelItems * ((currentPageOrders ? currentPageOrders : 1) - 1);

  // Handle Tabs
  const tabTypes = {
    COMMISSIONS: 'COMMISSIONS',
    RESTAURANTS: 'RESTAURANTS',
    ORDERS: 'ORDERS',
  };
  const [activeTab, setActiveTab] = useState(tabTypes.COMMISSIONS);
  const tabs = isWellet
    ? [
        {
          isActive: activeTab === tabTypes.COMMISSIONS,
          title: t('table.commission.title'),
          type: tabTypes.COMMISSIONS,
        },
        {
          isActive: activeTab === tabTypes.RESTAURANTS,
          title: getClientTranslation('title.plural'),
          type: tabTypes.RESTAURANTS,
        },
        {
          isActive: activeTab === tabTypes.ORDERS,
          title: t('table.orders.title'),
          type: tabTypes.ORDERS,
        },
      ]
    : [
        {
          isActive: activeTab === tabTypes.COMMISSIONS,
          title: t('table.commission.title'),
          type: tabTypes.COMMISSIONS,
        },
        {
          isActive: activeTab === tabTypes.RESTAURANTS,
          title: getClientTranslation('title.plural'),
          type: tabTypes.RESTAURANTS,
        },
      ];

  // APIs Querys
  const wireDetailCommissionsQuery = useQuery({
    queryKey: ['wire-detail-commissions', paymentId, limit, skip, orderBy, orderType],
    queryFn: () =>
      getWireComissionsDetails(welletContext, paymentId, limit, skip, orderBy, orderType),
    enabled: tabs.some((tab) => tab.isActive && tab.type === 'COMMISSIONS'),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      setTotalAndReset(data?.data?.totalRegisters);
    },
  });
  const wireDetailRestaurantsQuery = useQuery({
    queryKey: [
      'wire-detail-restaurants',
      paymentId,
      limitRest,
      skipRest,
      orderByRest,
      orderTypeRest,
    ],
    queryFn: () =>
      getWireRestaurantsDetails(
        welletContext,
        paymentId,
        limitRest,
        skipRest,
        orderByRest,
        orderTypeRest,
      ),
    enabled: tabs.some((tab) => tab.isActive && tab.type === 'RESTAURANTS'),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      setTotalAndResetRestaurant(data?.data?.totalRegisters);
    },
  });
  const wireDetailOrdersQuery = useQuery({
    queryKey: [
      'wire-detail-restaurants',
      paymentId,
      limitOrders,
      skipOrders,
      orderByeOrders,
      orderTypeOrders,
    ],
    queryFn: () =>
      getWireOrdersDetails(
        welletContext,
        paymentId,
        limitOrders,
        skipOrders,
        orderByeOrders,
        orderTypeOrders,
      ),
    enabled: tabs.some((tab) => tab.isActive && tab.type === 'ORDERS') && isWellet,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      setTotalAndResetOrders(data?.data?.list?.totalRegisters);
    },
  });
  // Functions
  const handleTabs = (type) => {
    setActiveTab(type);
    switch (type) {
      case tabTypes.COMMISSIONS:
        resetState();
        break;
      case tabTypes.RESTAURANTS:
        resetStateRest();
        break;
      case tabTypes.ORDERS:
        resetStateOrders();
        break;
      default:
        resetState();
    }
  };
  const handlePax = (pax) => {
    return pax === 0 || pax === 1 ? t('table.commission.person') : t('table.commission.people');
  };

  // Header Table
  const comissionsHeader = [
    { title: t('table.commission.code'), value: '', filterable: false },
    { title: t('table.commission.commission'), value: 'amount', filterable: true },
    { title: '-', value: '', filterable: false },
    {
      title: t('table.commission.schedule'),
      value: 'reservationDateLocalTime',
      filterable: true,
      center: true,
    },
    { title: t('table.commission.consumption'), value: 'totalAmount', filterable: true },
    { title: t('table.commission.client'), value: '', filterable: false },
  ];
  const restaurantHeader = [
    { title: getClientTranslation('title.singular'), value: '', filterable: false },
    { title: t('table.restaurant.commission'), value: 'amount', filterable: true },
  ];
  const ordersHeader = [
    { title: t('table.orders.date'), value: 'date', filterable: true },
    { title: t('table.orders.order'), value: '', filterable: false },
    { title: t('table.orders.amount'), value: 'amount', filterable: true },
  ];

  return (
    <div className='my-3'>
      <Subtitle text={t('title')} className={'container-subtitle subtitle-border'} />
      <div className='mb-3'>
        <Tabs options={tabs} onClick={handleTabs} />
        <Show>
          <Show.When
            isTrue={
              wireDetailOrdersQuery.isFetching ||
              wireDetailOrdersQuery.isLoading ||
              wireDetailCommissionsQuery.isFetching ||
              wireDetailCommissionsQuery.isLoading ||
              wireDetailRestaurantsQuery.isFetching ||
              wireDetailRestaurantsQuery.isLoading
            }
          >
            <div className='my-4 py-4'>
              <Loading global={false} />
            </div>
          </Show.When>

          <Show.Else>
            <Show.When isTrue={activeTab === 'COMMISSIONS'}>
              <div className='mt-2'>
                <Table
                  data={wireDetailCommissionsQuery?.data?.data?.items ?? []}
                  hasPagination={false}
                  totalP={totalItems}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  columns={comissionsHeader}
                  onColumnClick={handleColumnClick}
                  activeColumn={orderType}
                  sortOrder={orderBy}
                  borderTop={false}
                  perPage={tabelItems}
                  renderRow={(commission, index) => {
                    const { date, time } = formatDateTranslation(
                      commission.date,
                      language,
                      'extraSmall',
                    );
                    return (
                      <>
                        <TableCell className={'100px'} style={{ width: '' }}>
                          <Link
                            to={`/commissions-details/${commission.id}`}
                            className='custom-link-file'
                          >
                            {commission?.referenceCode}
                          </Link>
                        </TableCell>
                        <TableCell className={' font-weight-bold'} style={{ width: 'auto' }}>
                          <Money value={commission?.commission} />
                        </TableCell>
                        <TableCell className={''} style={{ width: 'auto' }}>
                          <OrganizationCard
                            imageUrl={commission?.restaurant?.logo}
                            name={commission?.restaurant?.name}
                            date={commission?.restaurant?.cityName}
                            size={'40px'}
                            hightLightName={true}
                          />
                        </TableCell>
                        <TableCell className={'text-center'} style={{ width: '100px' }}>
                          <span className='sentences-capitalize container-label d-block fw-700 '>
                            {date}
                          </span>
                          <span className='fw-700 '>{time}</span>
                        </TableCell>
                        <TableCell style={{ width: 'auto' }}>
                          <Money value={commission?.consumption} />
                        </TableCell>
                        <TableCell>
                          <span className='fw-700 text-capitalize d-block container-label'>
                            {commission?.customerName}
                          </span>
                          <span className='text-capitalize d-block'>
                            {commission?.pax} {handlePax(commission?.pax)}
                          </span>
                        </TableCell>
                      </>
                    );
                  }}
                />
              </div>
            </Show.When>
            <Show.When isTrue={activeTab === 'ORDERS' && isWellet}>
              <div className='mt-2'>
                <Table
                  perPage={tabelItems}
                  data={wireDetailOrdersQuery?.data?.data?.list?.items ?? []}
                  hasPagination={false}
                  totalP={totalItemsOrders}
                  onPageChange={handlePageChangeOrders}
                  currentPage={currentPageOrders}
                  columns={ordersHeader}
                  onColumnClick={handleColumnClickOrders}
                  activeColumn={orderTypeOrders}
                  sortOrder={orderByeOrders}
                  borderTop={false}
                  renderRow={(order, index) => (
                    <>
                      <TableCell style={{ width: 'auto' }}>
                        {formatDateTranslation(order?.date, language, 'extraSmall').date}
                      </TableCell>
                      <TableCell style={{ width: 'auto' }}>
                        <Link to={`/billing/summary/${order?.id}`} className='link-underline'>
                          {order?.refOrder}
                        </Link>
                      </TableCell>
                      <TableCell className='fw-700' style={{ width: 'auto' }}>
                        <Money value={order?.amount} />
                      </TableCell>
                    </>
                  )}
                />
              </div>
            </Show.When>
            <Show.When isTrue={activeTab === 'RESTAURANTS'}>
              <div className='mt-2'>
                <Table
                  perPage={tabelItems}
                  data={wireDetailRestaurantsQuery?.data?.data?.items ?? []}
                  hasPagination={false}
                  totalP={totalItemsRestaurant}
                  onPageChange={handlePageChangeRestaurant}
                  currentPage={currentPageRest}
                  columns={restaurantHeader}
                  onColumnClick={handleColumnClickRest}
                  activeColumn={orderTypeRest}
                  sortOrder={orderByRest}
                  borderTop={false}
                  renderRow={(restaurante, index) => (
                    <>
                      <TableCell className={'dark-grey'} style={{ width: '200px' }}>
                        <OrganizationCard
                          imageUrl={restaurante?.restaurant?.logo}
                          name={restaurante?.restaurant?.name}
                          date={restaurante?.restaurant?.cityName}
                          size={'40px'}
                          hightLightName={true}
                        />
                      </TableCell>
                      <TableCell className={'dark-grey fw-700'} style={{ width: 'auto' }}>
                        <Money value={restaurante?.commission} />
                      </TableCell>
                    </>
                  )}
                />
              </div>
            </Show.When>
          </Show.Else>
        </Show>
      </div>
    </div>
  );
};

export default TabTableWire;
