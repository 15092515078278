import React, { useMemo } from 'react';
import { WelletContext } from './welletContext';
import { useSelector } from 'react-redux';
import axios from 'axios';

function redirectUnauthorizedNetworkCalls(api, redirectPath) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location.href = redirectPath;
      }
      return Promise.reject(error);
    },
  );
}

function welletApis(apiUrls, user, app, redirectPathWhenUnauthorized) {
  const headers = {};

  if (user) {
    if (user.jwt) {
      headers['Authorization'] = `Bearer ${user.jwt}`;
    }
  }

  const tickets = axios.create({
    baseURL: process.env.REACT_APP_SALES_API_URL,
    headers,
  });

  const admin = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_URL,
    headers,
  });

  if (redirectPathWhenUnauthorized) {
    //redirectUnauthorizedNetworkCalls(tickets, redirectPathWhenUnauthorized);
  }

  return {
    tickets,
    admin,
  };
}

export function WelletProvider({
  apiUrls,
  user,
  app,
  children,
  redirectPathWhenUnauthorized = '',
}) {
  const value = useMemo(() => {
    const apis = welletApis(apiUrls, user, app, redirectPathWhenUnauthorized);
    return {
      apis,
      user,
      app,
    };
  }, [user, apiUrls, app]);

  return <WelletContext.Provider value={value}>{children}</WelletContext.Provider>;
}
