import { useState, useEffect } from 'react';

const usePagination = (initialPage = 1, defaultLimit = 10, keepState = true) => {
  const [currentPage, setCurrentPage] = useState(Math.max(1, initialPage));
  const [totalItems, setTotalItems] = useState(0);

  const handlePageChange = (newPage, callback = () => {}) => {
    setCurrentPage(newPage);
    callback();
  };

  const setTotalAndReset = (newTotalItems) => {
    setTotalItems(newTotalItems);
    if (currentPage <= Math.ceil(newTotalItems / defaultLimit)) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const totalPages = Math.ceil(totalItems / defaultLimit);
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(1);
    }
  }, [currentPage, totalItems]);

  return {
    currentPage,
    totalItems,
    handlePageChange,
    setTotalAndReset,
    defaultLimit,
  };
};

export default usePagination;
